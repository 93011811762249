import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, styled } from '@mui/material';
import Tab from '@mui/material/Tab';
import { StyledTabs } from './tab-bar.styled';
import SearchTab from './search-tab';
import ErrorIcon from '@mui/icons-material/Error';

function TabPanel(props) {
  const { children, value, index, padding = 3, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: padding }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabBar(props) {
  const {
    tabPanels,
    onTabChange = () => {},
    currentTab = 0,
    searchTabs,
    disableCreate,
    errorTabNames,
    onSearchChange,
    tabSearchList,
    searchTabWidth,
    isScroll = false,
  } = props;
  const allowedTabs = tabPanels.filter((tab) => !tab.hide);
  const [value, setValue] = useState(currentTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  useEffect(() => {
    handleChange(false, currentTab);
  }, [currentTab]);

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'neutral.light100',
        boxShadow: '0px 2px 4px rgba(51, 51, 51, 0.15)',
      }}
    >
      <Box
        borderRadius={1}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          bgcolor: 'neutral.light80',
          // overflow:'scroll'
        }}
      >
        <Box display="flex" overflow={isScroll ? 'scroll' : 'none'}>
          {searchTabs && (
            <SearchTab
              list={tabSearchList || allowedTabs}
              onChange={onSearchChange}
              width={searchTabWidth}
            />
          )}

          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
          >
            {allowedTabs.map((tab, index) => {
              const { label, name, disabled } = tab;
              return (
                <Tab
                  key={name}
                  label={
                    <Box>
                      {label}
                      <ErrorIcon
                        sx={{
                          height: 16,
                          width: 16,
                          marginBottom: '-2px',
                          marginLeft: '4px',
                          color: 'red',
                          display: `${
                            errorTabNames?.includes(name)
                              ? 'inline-block'
                              : 'none'
                          }`,
                        }}
                      />
                    </Box>
                  }
                  variant="scrollable"
                  {...a11yProps(index)}
                  disabled={disabled}
                />
              );
            })}
          </StyledTabs>
        </Box>
        {!disableCreate && (
          <Box
            pt={'14px'}
            pr={3}
            color={'neutral.dark40'}
            sx={{ whiteSpace: 'nowrap' }}
          >
            Create new tab +
          </Box>
        )}
      </Box>
      {allowedTabs.map((tabPanel, index) => {
        const { name, panel, padding } = tabPanel;
        return (
          <TabPanel key={name} value={value} index={index} padding={padding}>
            {panel}
          </TabPanel>
        );
      })}
    </Box>
  );
}
