import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import { FETCH_JOB_FORM_REQUEST, FETCH_JOB_FORM_FAILURE, FETCH_JOB_FORM_SUCCESS, FETCH_CANDIDATE_FORM_REQUEST, FETCH_CANDIDATE_FORM_SUCCESS, FETCH_CANDIDATE_FORM_FAILURE } from './actionType';

const initialState = {
  jobForm: [],
  candidateForm:[],
  requestProcessing: {
    [APIRequestStatus.FETCH_JOB_FORM]: LoadingStatus.Loading,
    [APIRequestStatus.FETCH_CANDIDATE_FORM]: LoadingStatus.Loading
  },
};

const formConfigReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_JOB_FORM_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_JOB_FORM]: LoadingStatus.Loading
        }
      };
    }

    case FETCH_JOB_FORM_SUCCESS: {
      const formConfig = action.payload;
      return {
        ...state,
        jobForm: formConfig,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_JOB_FORM]: LoadingStatus.Success
        }
      };
    }

    case FETCH_JOB_FORM_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_JOB_FORM]: LoadingStatus.Failure
        }
      };
    }

    case FETCH_CANDIDATE_FORM_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_CANDIDATE_FORM]: LoadingStatus.Loading
        }
      };
    }

    case FETCH_CANDIDATE_FORM_SUCCESS: {
      const formConfig = action.payload;
      return {
        ...state,
        candidateForm: formConfig,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_CANDIDATE_FORM]: LoadingStatus.Success
        }
      };
    }

    case FETCH_CANDIDATE_FORM_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_CANDIDATE_FORM]: LoadingStatus.Failure
        }
      };
    }
    default: return state;
  }
};

export default formConfigReducer;
