import React from 'react';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';

export const RHSBoxAction = (props) => {
  const { onClickAdd, onClickDelete, disableDelete, onClickCopy } = props;
  return (
    <>
      <Box position='absolute' right={-75} top={40}>
        <Box
          width={30}
          height={30}
          borderRadius={1}
          border='1px solid'
          borderColor='primary.main'
          mb={2}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ cursor: 'pointer' }}
        >
          <IconButton onClick={onClickDelete} disabled={disableDelete}>
            <DeleteIcon sx={{ color: 'primary.main' }} />
          </IconButton>
        </Box>
        <Box
          width={30}
          height={30}
          borderRadius={1}
          border='1px solid'
          borderColor='primary.main'
          mb={2}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ cursor: 'pointer' }}
        >
          <IconButton onClick={onClickCopy}>
          <ContentCopyIcon sx={{ color: 'primary.main' }} />
          </IconButton>
        </Box>
        <Box
          width={30}
          height={30}
          borderRadius={1}
          border='1px solid'
          borderColor='primary.main'
          mb={2}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ cursor: 'pointer' }}
        >
          <IconButton onClick={onClickAdd}>
            <AddIcon
              sx={{ color: 'primary.main' }}
            />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};