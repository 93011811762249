import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PayrollView from '../PayrollView';
import PayrollEdit from '../PayrollEdit';
import {
  getPayrollDetails,
  resetPayroll,
} from '../../../../../redux/payroll/action';
import {
  APIRequestStatus,
  LoadingStatus,
} from '../../../../../constants/common';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import CurrencyFormatter from '../../../../../atoms/CurrencyFormater/CurrencyFormater';
import { PayrollPermissions } from '../../../Constant/Constant';
import TryAgain from '../../../../molecules/ErrorState/error.component';
import { permissionsObj } from '../../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';

const AddPayroll = (props) => {
  const { viewMode, setViewMode } = props;

  const { currentUserRoleId } = useSelector((state) => state.main);
  const {
    additionComponents,
    deductionComponents,
    base,
    stdHrs,
    period,
    prlRegisterId,
    cpfEmployeeAmt,
    cpfEmployerAmt,
    employee,
    sdf,
    directFunds,
    cpfApplicable,
    payDate,
    overtimeInfo,
    otApplicable,
    requestProcessing,
  } = useSelector((state) => state.payroll) || {};
  const { [APIRequestStatus.GET_PAYROLL_DETAILS]: detailsLoadingStatus } =
    requestProcessing || {};

  const { payrollPermission } = useSelector((state) => state.modulePermissions);

  const dispatch = useDispatch();
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const idForPayrollDetails = pathNames[pathNames.length - 1];
  const currencySymbol = JSON.parse(
    localStorage.getItem('orgInfo')
  )?.currencySymbol;

  useEffect(() => {
    if (currentUserRoleId && payrollPermission) {
      if (!isNaN(idForPayrollDetails)) {
        dispatch(
          getPayrollDetails(
            currentUserRoleId,
            idForPayrollDetails,
            payrollPermission?.[PayrollPermissions.ACCESS_ALL_PAYROLL]?.id,
            payrollPermission?.[PayrollPermissions.APPROVER_DASHBOARD]?.[
              permissionsObj.allowView
            ]
          )
        );
        setViewMode(true);
      }
    }
    return () => {
      setViewMode(false);
      dispatch(resetPayroll());
    };
  }, [idForPayrollDetails, currentUserRoleId, payrollPermission]);

  if (detailsLoadingStatus === LoadingStatus.Failure) {
    return <TryAgain />;
  }

  return (
    <>
      <CircularLoader show={detailsLoadingStatus === LoadingStatus.Loading} />
      {detailsLoadingStatus === LoadingStatus.Success && viewMode ? (
        <PayrollView
          additionComponents={additionComponents}
          deductionComponents={deductionComponents}
          setViewMode={setViewMode}
          base={base}
          stdHrs={stdHrs}
          period={period}
          cpfEmployeeAmt={cpfEmployeeAmt}
          cpfEmployerAmt={cpfEmployerAmt}
          prlRegisterId={prlRegisterId}
          currencySymbol={currencySymbol}
          employee={employee}
          sdf={sdf}
          directFunds={directFunds}
          cpfApplicable={cpfApplicable}
          payDate={payDate}
          overtimeInfo={overtimeInfo}
          otApplicable={otApplicable}
        />
      ) : (
        <PayrollEdit
          setViewMode={setViewMode}
          additionComponents={additionComponents}
          deductionComponents={deductionComponents}
          base={base}
          stdHrs={stdHrs}
          period={period}
          cpfEmployeeAmt={cpfEmployeeAmt}
          cpfEmployerAmt={cpfEmployerAmt}
          prlRegisterId={prlRegisterId}
          currencySymbol={currencySymbol}
          employee={employee}
          sdf={sdf}
          directFunds={directFunds}
          cpfApplicable={cpfApplicable}
          payDate={payDate}
          overtimeInfo={overtimeInfo}
          otApplicable={otApplicable}
        />
      )}
    </>
  );
};

export default AddPayroll;
