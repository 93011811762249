// export const MsalConfig = {
//   appId: 'a6867d33-3bf3-4b8b-bbf4-0eeb108dfb76',
//   redirectUri: 'https://vepeopleplus-staging.azurewebsites.net/dashboard',
//   scopes: ['user.read'],
//   authority:
//     'https://login.microsoftonline.com/e6fdbcc7-dcec-409f-8d1c-0eec6fa68f44',
// };


export const MsalConfig = {
  appId: '25bb0c61-3e74-4e5e-b391-f3a06b3a21a2',
  redirectUri: 'https://ve-peopleplus-staging-fe.azurewebsites.net/dashboard',
  scopes: ['user.read'],
  authority:
    'https://www.okta.com/saml2/service-provider/spfwavnfctvqcmunzmen',
};