import { Button, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { Box } from '@mui/material';
import ModalView from '../../modal-component/modal-view.component';
import { getIcon } from '../../helper/helper-utlis';

const SidebarItem = (props) => {
  const { label, fieldType } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        style={{
          backgroundColor: 'white',
          margin: 5,
          padding: '8px 0px',
          // maxWidth: 250,
          justifyContent: 'normal',
        }}
        onClick={() => setShowModal(true)}
      >
        <Box pl={2} display="flex" justifyContent="center" alignItems="center">
          {getIcon(fieldType)}
          <span
            style={{
              textTransform: 'none',
              fontSize: 20,
              fontWeight: 'bold',
              marginLeft: 5,
            }}
          >
            {label}
          </span>
        </Box>
      </Button>
      <ModalView
        showModal={showModal}
        setShowModal={setShowModal}
        fieldType={fieldType}
      />
    </>
  );
};

export default SidebarItem;
