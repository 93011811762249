import { Box, Button, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { approvalWorkflowServices } from '../../../../../services/approvalWokflowService';
import { showToast } from '../../../../../utils/common.util';
import { ToastThemes } from '../../../../../constants/common';
import { CLAIM_STATUS } from '../../../../claim-view/claim-audit-log';
export default function ApprovalWorkflowFooter(props) {
  const {
    handleApproveClick,
    handleRejectClick,
    recordId,
    moduleId,
    currentUserRoleId,
    userId,
    editButtonRoute,
    setDeleteModalOpen,
    claimStatus,
  } = props;
  const navigate = useNavigate();
  const [isAuthorized, setisAuthorized] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const getApprovalWorkflowPermission = async () => {
    await new approvalWorkflowServices()
      .getApprovalPermissions(
        moduleId,
        recordId,
        currentUserRoleId,
        userId,
        'claims'
      )
      .then((res) => {
        if (res.edit) {
          setIsEditable(res.edit);
        }
        if (res.authorised) {
          setisAuthorized(res.authorised);
        }
      })
      .catch((err) => {
        showToast(err, ToastThemes.error);
      });
  };
  useEffect(() => {
    getApprovalWorkflowPermission();
  }, []);
  return (
    <Paper sx={{ p: 2, mt: 2, mb: 4 }}>
      <Box display="flex" gap="10px" justifyContent="flex-end">
        {isEditable && (
          <>
            <Button
              size="medium"
              sx={{
                bgcolor: 'neutral.light80',
                color: 'neutral.dark80',
                px: 2,
                textTransform: 'capitalize',
              }}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            {claimStatus === CLAIM_STATUS.DRAFT && (
              <Button
                variant="outlined"
                size="medium"
                onClick={() => setDeleteModalOpen(true)}
              >
                Delete
              </Button>
            )}
            <Button
              variant="contained"
              size="medium"
              onClick={() => navigate(editButtonRoute)}
            >
              Edit
            </Button>
          </>
        )}
        {isAuthorized && (
          <>
            <Button
              size="medium"
              sx={{
                bgcolor: 'neutral.light80',
                color: 'neutral.dark80',
                px: 2,
                textTransform: 'capitalize',
              }}
              onClick={() => navigate(-1)}
            >
              Review Later
            </Button>
            <Button
              variant="outlined"
              size="medium"
              onClick={handleRejectClick}
            >
              Send Back
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={handleApproveClick}
            >
              Approve
            </Button>
          </>
        )}
        {!isAuthorized && !isEditable && (
          <Button
            size="medium"
            sx={{
              bgcolor: 'neutral.light80',
              color: 'neutral.dark80',
              px: 2,
              textTransform: 'capitalize',
            }}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        )}
      </Box>
    </Paper>
  );
}
