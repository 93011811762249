import React from 'react';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Collapse, List, ListItemButton, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';

const RHSMenuListItem = (props) => {
	const { title, content, parentPath, onClickListItem } = props;
	const [isActive, setIsActive] = useState(true);
	const navigate = useNavigate();
	const location = useLocation();
	const currentPath = location.pathname;

	const handleItemClick = (path) => {
		navigate(path);
		onClickListItem();
	};
	
	return (
		<Box>
			<ListItemButton
				onClick={() => setIsActive(!isActive)}
				sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}
			>
				<Typography
					variant='body1Light'
					color={isActive ? 'accentTertiary.accent60' : 'inherit'}
					pl={3}
				>
					{title}
				</Typography>
				{title ? 
				isActive ? (
					<RemoveIcon
						sx={{ color: isActive ? 'accentTertiary.accent60' : 'inherit' }}
					/>
				) : (
					<AddIcon />
				):''}
			</ListItemButton>
			{content.map((item, index) => {
				const { label, path } = item;
				return (
					<Collapse key={index} in={isActive} timeout='auto' unmountOnExit onClick={() => handleItemClick(path)}>
						<List component='div' sx={{ pl: 4, pr: 2 }}>
							<ListItemButton 
							sx={{ borderRadius: 1 }}
							selected={path === currentPath}
							>
								<Typography variant='buttonRegular'>{label}</Typography>
							</ListItemButton>
						</List>
					</Collapse>);
			})}
		</Box>
	);
};

export default RHSMenuListItem;