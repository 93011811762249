import { InfoOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListView from '../../../atoms/DataList/ListView';
import {
  getAllBenefitsList,
  resetBenefits,
} from '../../../redux/benefits/action';
import { APIRequestStatus, LoadingStatus } from '../../../constants/common';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import TryAgain from '../../molecules/ErrorState/error.component';
import ModalMapBenefit from './modal-map-benefit/modal-map-benefit.component';

const BenefitsDashBoard = (props) => {
  const [checkedItems, setCheckedItems] = useState([]);

  const { currentUserRoleId } = useSelector((state) => state.main);
  const {
    benefitsList,
    requestProcessing: {
      [APIRequestStatus.GET_ALL_BENEFITS_LIST]: listLoading,
    },
  } = useSelector((state) => state.benefits);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUserRoleId) {
      dispatch(getAllBenefitsList(currentUserRoleId));
    }
  }, [currentUserRoleId]);

  useEffect(() => {
    setCheckedItems([]);
  }, [benefitsList]);

  const handleAddClick = () => {
    navigate('create-benefit');
    dispatch(resetBenefits());
  };

  const rowData = benefitsList?.map((items) => {
    const { id, name } = items;
    return {
      name: name,
      action: (
        <IconButton onClick={() => navigate({ pathname: `${id}` })}>
          <InfoOutlined sx={{ color: 'primary.main80' }} />
        </IconButton>
      ),
      id: {
        value: { id, name },
        hide: true,
      },
    };
  });

  if (listLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }

  return (
    <>
      <CircularLoader show={listLoading === LoadingStatus.Loading} />
      <Box sx={{ backgroundColor: 'white' }}>
        <Box display="flex" justifyContent="flex-end" gap={2} p={2}>
          <Box>
            <ModalMapBenefit
              checkedItems={checkedItems}
              currentUserRoleId={currentUserRoleId}
            />
          </Box>
        </Box>
        <ListView
          headCells={[{ id: 'name', label: 'Name' }]}
          rowsData={rowData || []}
          showSerialNo={true}
          showCheckbox={true}
          setCheckedItems={setCheckedItems}
          checkedItems={checkedItems}
          allowSelectAll={true}
          onAddClick={handleAddClick}
          showAddIcon={true}
        />
      </Box>
    </>
  );
};

export default BenefitsDashBoard;
