import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import AccordionTabs from '../../../../../../../atoms/Accordian/Accordian';
import CandidateAssessmentView from './CandidateAssessmentView';
import { FormHeader } from '../../../../../../OnBoarding/common/formHeader';
import InterviewFeedbackForm from '../InterviewSchedule/Feedback/InterviewFeedbackForm';
import { APIRequestStatus, LoadingStatus } from '../../../../../../../constants/common';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getCandidateAssessment, resetCandidate } from '../../../../../../../redux/candidateDetails/action';
import { CandidateFormFieldNames } from '../../../../../Constant/constant';
import CircularLoader from '../../../../../../../atoms/CircularLoader/circular-loader';
import TryAgain from '../../../../../../molecules/ErrorState/error.component';

const CandidateAssessment = () => {
	const { interviewConfig, feedbackConfig, interviewInfo, candidateInfo,
		requestProcessing: { [APIRequestStatus.GET_CANDIDATE_ASSESSMENT]: configLoadingStatus }
	} = useSelector((state) => state.candidate);

	const dispatch = useDispatch();
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const pathNames = location.pathname.split('/');
	const idForCandidateDetails = pathNames[pathNames.length - 1];
	const candidateName = candidateInfo?.fkCandidate?.[CandidateFormFieldNames.Name];
	const candidateCode = candidateInfo?.fkCandidate?.[CandidateFormFieldNames.Code];
	const candidateJobId = searchParams.get('cjid');

	useEffect(() => {
		if (candidateJobId) {
			dispatch(getCandidateAssessment(candidateJobId));
		} else {
			dispatch(getCandidateAssessment());
		}
		return (() => {
			dispatch(resetCandidate());
		});
	}, [idForCandidateDetails, candidateJobId]);

	if (configLoadingStatus === LoadingStatus.Failure) {
		return <TryAgain />;
	}
	return (
		<Box>
			<CircularLoader show={configLoadingStatus === LoadingStatus.Loading} />
			<FormHeader title={candidateName || 'Interview History'} labelCode={candidateCode || ''} />
			{interviewInfo.map((item, index) => {
				const { competencies, skills, InterviewInfo, overall } = item;
				return (
					<div key={index}>
						<AccordionTabs
							key={index}
							title={InterviewInfo?.fkInterviewtype?.name || 'New Round'}
							tabDetails={
								<Box>
									<CandidateAssessmentView formConfig={interviewConfig} formData={InterviewInfo} />
									<InterviewFeedbackForm interviewFeedbackFormConfig={feedbackConfig} competencies={competencies}
										skills={skills} interviewFeedbackFormInfo={overall} viewMode={true} />
								</Box>
							}
						/>
					</div>
				);
			})}
		</Box>
	);
};

export default CandidateAssessment;