import React from 'react';
import AddAppraisal from './AddAppraisal';
import { Box } from '@mui/material';
import TabBar from '../../../atoms/Tabs/TabBar';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getAppraisalDetails, resetGoals } from '../../../redux/goals/action';
import { useDispatch } from 'react-redux';

const ManageAppraisal = () => {
  
    const dataTabs = [
      {
        name: 'appraisal',
        label: 'Appraisal',
        title: 'Appraisal',
        hide: false,
        panel: <AddAppraisal
        />
      }
    ];
  
    return (
      <Box>
          <TabBar
            tabPanels={dataTabs}
            searchTabs
            disableCreate />
         {/* <ModalDialog
        //   title='KRA library'
        //   description={<LibraryList/>}
        //   primaryAction='Add'
        //   secondaryAction='Cancel'
        //   showModal={showModal}
        //   onClickClose={() => setshowModal(false)}
        //   maxWidth={'md'}
        // /> */}
      </Box>
    );
};

export default ManageAppraisal;