import React from 'react';
import { Box } from '@mui/material';
import OnboardingHeader from '../common/Header/onboarding.header';
import { useState } from 'react';
import { Outlet } from 'react-router';
import DynamicBreadcrumb from '../../../atoms/Breadcrumbs/dynamicBreadcrumb';
import RHSSlideOut from '../RHSMenu/RHSSlideOut';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchCompanyOnboardingPermissions, getAllUsers } from '../../../redux/onboarding/action';
import { addUser, myProfile, onboarding, userOnboarding, userProfile } from '../Organization/constants/onboarding.constants';
import { fetchMyProfilePermissions, fetchUserOnboardingPermissions } from '../../../redux/modulePermissions/action';
import { APIRequestStatus, LoadingStatus } from '../../../constants/common';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import { useLocation } from 'react-router';
import TryAgain from '../../molecules/ErrorState/error.component';

export default function UserProfile() {

  const dispatch = useDispatch();
  const { currentUserRoleId, appPermissions } = useSelector((state) => state.main);
  const { requestProcessing: { [APIRequestStatus.FETCH_USER_PERMISSIONS]: userPermissionsLoading,
    [APIRequestStatus.FETCH_MY_PROFILE_PERMISSIONS]: myProfilePermissionsLoading
  } } = useSelector((state) => state.modulePermissions);
  const [viewMode, setViewMode] = useState(false);

  const location = useLocation();
  const paths = location.pathname.split('/');

  useEffect(() => {
    if (appPermissions && currentUserRoleId) {
      if (appPermissions[onboarding]) {
        dispatch(fetchCompanyOnboardingPermissions(currentUserRoleId, appPermissions[onboarding].id));
      }
      if (appPermissions[userProfile]) {
        dispatch(fetchUserOnboardingPermissions(currentUserRoleId, appPermissions[userProfile].id));
      }
      if (appPermissions[myProfile]) {
        dispatch(fetchMyProfilePermissions(currentUserRoleId, appPermissions[myProfile].id));
      }
    }
  }, [currentUserRoleId, appPermissions]);

  if (userPermissionsLoading === LoadingStatus.Failure || myProfilePermissionsLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <Box m={4} pl={4} pr={10}>
      <CircularLoader show={userPermissionsLoading === LoadingStatus.Loading ||
        myProfilePermissionsLoading === LoadingStatus.Loading} />
      <OnboardingHeader
        breadCrumb={<DynamicBreadcrumb />}
      />
      {userPermissionsLoading === LoadingStatus.Success && myProfilePermissionsLoading === LoadingStatus.Success &&
        <Box>
          <Outlet context={[viewMode, setViewMode]} />
          <Box>
            <RHSSlideOut />
          </Box>
        </Box>
      }
    </Box>
  );
}

