import API from './api';
export class PayrollAssignment extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL + '/payroll/pay-structure/' || '';
  constructor() {
    super(PayrollAssignment.baseURL);
  }

  getComponentsConfig = async (salaryStructureId) => (
    this.responsify(await this.instance.get(`components/${salaryStructureId}`))
  );

  getComponentsDetails = async (payStructureId, employeeId, isUpcoming) => (
    this.responsify(await this.instance.get('employee', { params: { payStructureId, employeeId, isUpcoming } }))
  );

  postComponentsInfo = async (body) => (
    this.responsify(await this.instance.post('assign-pay-structure', body))
  );

  updateComponentsInfo = async (body) => (
    this.responsify(await this.instance.put('employee/update', body))
  );
}