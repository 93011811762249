import { FormControl, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { StyledSelect } from '../../form-builder/sub-header/sub-header.style.component';
import SearchBar from '../../../atoms/SearchBar/SearchBar';

const SubHeader = (props) => {
  const { tabsData } = props;
  const [value, setValue] = useState([]);
  const navigate = useNavigate();

  const handleChange = (event) => {
    navigate(`/new-claims/${event.target.value}`);
  };

  return (
    <Box sx={{ display: 'flex', gap: 7 }}>
      <FormControl variant="standard" sx={{ width: 150, border: 0, mt: 1 }}>
        <StyledSelect
          displayEmpty
          value={value}
          onChange={handleChange}
          renderValue={(selected) => {
            if (selected) {
              return 'Claims';
            }
          }}
        >
          {tabsData.map((item) => {
            const { id, name } = item;
            return (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            );
          })}
        </StyledSelect>
      </FormControl>

      <SearchBar />
    </Box>
  );
};

export default SubHeader;
