import React from 'react';
import { Box } from '@mui/material';
import TabBar from '../../../atoms/Tabs/TabBar';
import { useOutletContext } from 'react-router-dom';
import AddGoals from './AddGoals';

export default function ManageGoals() {
  const [viewMode, setViewMode] = useOutletContext();

  const dataTabs = [
    {
      name: 'goal',
      label: 'Goals',
      title: 'Goals',
      hide: false,
      panel: <AddGoals
        viewMode={viewMode}
        setViewMode={setViewMode}
      />
    }
  ];

  return (
    <Box>
        <TabBar
          tabPanels={dataTabs}
          searchTabs
          disableCreate />
    </Box>
  );
}


