import API from './api';

export class ClaimServices extends API {
    static baseURL = API.env.REACT_APP_BASE_API_LOCAL || '';
    constructor() {
    super( ClaimServices.baseURL);
    }

    getClaimDetails = async (claimId,claimTypeId,roleId,formState)=>{
        return await this.instance.get(`claims/main/details/${claimId}`,{ params: { claimTypeId,roleId,formState }});
    };

    getAllClaimsType = async ()=>{
        return await this.instance.get('claims/main/claim_types');
    };

    
    getAllClaimsTypeByCategories = async (id)=>{
        return await this.instance.get(`claims/main/claim_types/${id}`);
    };

    getAllCategories = async ()=>{
        return await this.instance.get('claims/main/categories');
    };
    

    getClaimsByClaimsType = async (claimTypeId,roleId)=>{
        return await this.instance.get(`claims/main/claims_by_claim_type/${claimTypeId}?role=${roleId}`);
    };

    getClaimConfigurationSchema = async (claimsTypeId)=>{
        return await this.instance.get(`/claims/main/claims_form_meta/${claimsTypeId}`);
    };
    getCurrencyExchangeRate = async ()=>{
        return await this.instance.get(
          '/claims/main/currency_exchange_rate');
    };

    getClaimConfigurationSchemaByFormId = async (formId)=>{
        return await this.instance.get(`/claims/main/claims_form_meta/form/${formId}`);
    };

    approveOrRejectClaims = async (body,claimTypeId,currentUserRoleId) => {
        const { claims, status, remark, reasons, ...rest } = body;
        console.log('body', body);
        return await this.instance.post('/claims/main/approve-or-reject-claims',
            {
               ...rest, claims, action:status, status,
                remark, reasons,
                params:{claimTypeId,currentUserRoleId} 
            });
    };
    getEmployeeDetails = async ()=>{
        return await this.instance.get('/auth/self-details');
    };

    postCreateFormData = async (formData,currentUserRoleId) => {

        let responseStatusCode = null;
      
        console.log(formData);
      
        try {
          const resCode = this.instance.post('/claims/main/create-claims', formData,{ params: { currentUserRoleId }},
            ).then((res) => {
            responseStatusCode = res?.data.status;
            return {code: responseStatusCode};
          }).catch(err => {
            // redirectToLogin(err);
            console.log(err);
            return {code: err.response?.status || 500, message: err.response?.data?.message};
            // return (responseStatusCode = 'Same travel dates AND departure timings OR receipt numbers are not allowed !');
          });
            
          console.log('res', resCode);
          return resCode;
    
        } catch (error) {
          console.log(error);
          return (responseStatusCode = error.response?.data?.message);
        }
      };
    
    postEditFormData = async (formData,currentUserRoleId) => {

        let responseStatusCode = null;
      
        try {
          return await this.instance.post('/claims/main/edit_claim', formData,{params:{currentUserRoleId}}).then((res) => {
            responseStatusCode = res?.data.status;
            return responseStatusCode;
          }).catch(err => {
            // redirectToLogin(err);
            console.log(err);
            return (responseStatusCode = 'Same travel dates AND departure timings OR receipt numbers are not allowed !');
          });
        } catch (error) {
          console.log(error);
          return (responseStatusCode = error.response?.data?.message);
        }
      };
}

export const claimServices = new ClaimServices();