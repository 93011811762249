export const userBasedRole =[
    {id:'HOD',name:'HOD'},
    {id:'HR-OPS',name:'HR-OPS'},
];
export const reportingLevels =[
    {id:'1',name:'1'},
    {id:'2',name:'2'},
    {id:'3',name:'3'},
    {id:'4',name:'4'},
    {id:'5',name:'5'},
];

export const approverTypes ={
    directBased:'Direct Based',
    roleBased:'Role Based',
    formInputBased:'Form Input Based',
    reportingManger:'Reporting Manger',
    userBased:'User Based',
    counterSignOfficer:'Counter Sign Officer'
};

export const approverTypesIds ={
    directBased:1,
    roleBased:2,
    formInputBased:3,
    reportingManger:4,
    userBased:5
};