import React from 'react';
import AppWorkEditForm from './AppWorkFlowFlowFormEdit/AppWorkEditForm';
import AppWorkViewForm from './AppWorkFlowFlowFormEdit/AppWorkViewForm';
import { FormFieldTypes } from '../../../../../constants/common';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import { APIRequestStatus, LoadingStatus } from '../../../../../constants/common';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getApprovalWorkflowConfig, resetApprovalWorkflowStructure } from '../../../../../redux/approvalFlow/action';
const AppWorkStr = (props) => {
  const { viewMode, setViewMode, strucutreId, setShowForm,outsideBuilderCall=false,outsideBuilderData={},approvalLibFormState,setApprovalLibFormState,outsideSave,
  approvalErrorsLib,
  setApprovalErrorsLib,
  approvalErrorsSteps,
  setApprovalErrorsSteps} = props;

  const { currentUserRoleId } = useSelector((state) => state.main);
  const dispatch = useDispatch();
  const { approvalWorkflowConfigLib, approvalWorkflowConfigSteps, approvalWorkflowFormDataLib, approvalWorkflowFormDataSteps, approvalWorkflowFormDataStepsDeleted,
    requestProcessing: { [APIRequestStatus.GET_APPROVAL_WORKFLOW_STRUCTURE_CONFIG]: configLoadingStatus }
  } = useSelector((state) => state.approvalWorkflow);
  useEffect(() => {
    if (!outsideBuilderCall){
      if (currentUserRoleId) {
        if (strucutreId) {
          dispatch(getApprovalWorkflowConfig(currentUserRoleId, strucutreId));
          setViewMode(true);
        } else {
          dispatch(getApprovalWorkflowConfig(currentUserRoleId));
        }
      }
      return (() => {
        setViewMode(false);
        dispatch(resetApprovalWorkflowStructure());
      });
    }
  }, [strucutreId, currentUserRoleId]);

  return (
    <>
      <CircularLoader show={configLoadingStatus === LoadingStatus.Loading} />
      {
        configLoadingStatus === LoadingStatus.Success &&
          viewMode ?
          <AppWorkViewForm
            formConfig={approvalWorkflowConfigLib}
            setViewMode={setViewMode}
            approvalWorkflowFormDataLib={approvalWorkflowFormDataLib}
            approvalWorkflowFormDataSteps={approvalWorkflowFormDataSteps}
            setShowForm={setShowForm}
            formId={strucutreId}
            steps={approvalWorkflowConfigSteps[0]} /> :
          <AppWorkEditForm
            formConfig={outsideBuilderCall?outsideBuilderData.approvalWorkflowConfigLib:approvalWorkflowConfigLib}
            setViewMode={setViewMode}
            isNew={!strucutreId}
            approvalWorkflowFormDataLib={outsideBuilderCall?outsideBuilderData.approvalWorkflowFormDataLib:approvalWorkflowFormDataLib}
            approvalWorkflowFormDataSteps={outsideBuilderCall?outsideBuilderData.approvalWorkflowFormDataSteps:approvalWorkflowFormDataSteps}
            setShowForm={setShowForm} 
            steps={outsideBuilderCall?outsideBuilderData.approvalWorkflowConfigSteps:approvalWorkflowConfigSteps}
            formId={strucutreId}
            stepsDeletedForm={outsideBuilderCall?outsideBuilderData.approvalWorkflowFormDataStepsDeleted:approvalWorkflowFormDataStepsDeleted}
            outsideBuilderCall={outsideBuilderCall}
            approvalLibFormState={approvalLibFormState}
            setApprovalLibFormState={setApprovalLibFormState}
            outsideSave={outsideSave}
            approvalErrorsLib={approvalErrorsLib}
            setApprovalErrorsLib={setApprovalErrorsLib}
            approvalErrorsSteps={approvalErrorsSteps}
            setApprovalErrorsSteps={setApprovalErrorsSteps}
          />
            
      }
    </>

  );
};

export default AppWorkStr;