import { AssignmentInd } from '@mui/icons-material';
import React from 'react';
import BtnDropDown from '../../../../atoms/Buttons/BtnDropDown';

const DropDownBenefit = (props) => {
  const { handleAssignTo, checkedItems } = props;

  const DropDownBtnOptions = [
    {
      id: 2,
      title: 'Assign to',
      icon: <AssignmentInd sx={{ width: 20, height: 20 }} />,
      handleClick: handleAssignTo,
      disabled: checkedItems.length !== 1,
    },
  ];
  return (
    <BtnDropDown
      BtnOptions={DropDownBtnOptions}
      selectedOption={0}
      disabled={!checkedItems.length}
    />
  );
};

export default DropDownBenefit;
