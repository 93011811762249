import React from 'react';
import FormView from '../../../../../OnBoarding/common/Form/FormView';
import { FormFieldTypes } from '../../../../../../constants/common';
import TargetFormView from './TargetForm/TargetFormView';
import PerformanceFormEdit from './KRAPerformance/PerformanceFormEdit';
import KraPerformance from '../KRAPerformanceModule/KraPerformance';
import { useState } from 'react';
import { KRAFormFieldNames } from '../../../../Utlis/goals.util';

const KRAFormView = (props) => {
  const { formConfig, formData, kraIndex, isAppraisal, viewMode,managerAcess } = props;
  const {[KRAFormFieldNames.TargetPoint]: targetPoints, ...kraData } = formData;

  return (
        <>
          <FormView
            list={formConfig}
            info={kraData}
          />
          <TargetFormView info={targetPoints}/>
          {isAppraisal &&
          <KraPerformance kraData={formData} kraIndex={kraIndex} viewMode={viewMode}managerAcess={managerAcess}/>
          }
          </>
      );
};

export default React.memo(KRAFormView);