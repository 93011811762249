import { Checkbox, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
// import DashBoardImage from '../../assets/LandingPage.png';
import './LandingPage.css';
import CardUI from '../../shared/components/ui-elements/CardUI';
import Calender from '../../shared/components/Calender';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CalenderIcon from '../../assets/calendar.svg';
import AddIcon from '../../assets/add-icon.svg';
import { useSelector } from 'react-redux';

const LandingPage = () => {
    const [fullName, setFullName] = useState('');

  // useEffect(() => {
  //   const userData = JSON.parse(localStorage.getItem('userData'));
  //   setFullName(userData.userDetails.firstName);
  // });
  return (
    <React.Fragment>
      <Grid container>
        <Grid lg={12} style={{ padding: '3em' }} className='main-wrap-bg' >
          <h1 className='user-name'>
            Hey <span> {fullName && fullName}</span>,
          </h1>
          <h1 className='user-name'>Welcome back!</h1>
          {/* <img
            src={DashBoardImage}
            alt={DashBoardImage}
            style={{ height: 'auto', width: '100%' }}
          /> */}
          <div className="new-ui-links">
            <div className="first-circle">
              <div className="one" >
                <img src={CalenderIcon} alt="Calender" />
              </div>
              <div className="two">
              <img src={AddIcon} alt="Add Icon" />
              <img src={CalenderIcon} alt="Calender" />
              {/* <img src={AddIcon} alt="Add Icon" />
              <img src={CalenderIcon} alt="Calender" />
              <img src={AddIcon} alt="Add Icon" />
              <img src={CalenderIcon} alt="Calender" /> */}
                
              </div>
            </div>
          </div>
        </Grid>
        <Grid lg={6} className='main-wrapper'>
          <CardUI background='#32333C' height='37em'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '0.5em',
              }}
            >
              <CardUI
                background='#EBCAC4'
                margin='10px'
                width='160px'
                height='170px'
                boxShadow='none'
              >
                <div className='card-titles'>Urgent</div>
                <div className='card-titles'>To dos</div>
                <div className='check-wrap'>
                  <Checkbox style={{ color: '#FFFFFF' }} />
                  <span
                    style={{
                      font: 'italic normal normal 15px/22px Plus Jakarta Sans',
                      color: '#FFFFFF',
                    }}
                  >
                    Timesheet
                  </span>
                </div>
                <div className='check-wrap'>
                  <Checkbox style={{ color: '#FFFFFF' }} />
                  <span
                    style={{
                      font: 'italic normal normal 15px/22px Plus Jakarta Sans',
                      color: '#FFFFFF',
                    }}
                  >
                    Leave Application
                  </span>
                </div>
              </CardUI>
              <CardUI
                background='#B2D5E3'
                width='160px'
                margin='10px'
                boxShadow='none'
              >
                <div
                  className='card-titles'
                >
                  Travel
                </div>
                <div
                 className='card-titles'
                >
                  Claim
                </div>
                <div className='card-subtitles'>
                  <span
                    
                  >
                    Raise a Ticket
                  </span>
                </div>
              </CardUI>
            </div>
            <div className='calender-ui'>
              <Calender />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <div className='links-wrap'>
                <div
                 className='link-wrap-two'
                >
                   Go To Dashboard &nbsp;<ArrowForwardIcon textAlign='center' />
                </div>
              </div>
            </div>
          </CardUI>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default LandingPage;
