import API from './api';

export class CommonSevice extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL || '';
  constructor() {
    super(CommonSevice.baseURL);
  }
  getDropDown = async (fieldName) =>
    this.responsify(
      await this.instance.post(`common/utils/dropdown/${fieldName}`)
    );

  postDropDown = async (fieldName, benifitId) =>
    this.responsify(
      await this.instance.post(`common/utils/dropdown/${fieldName}`, {
        'searchIds': [benifitId],
      })
    );
}
