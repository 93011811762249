import React from 'react';
import BtnDropDown from '../../../../../../atoms/Buttons/BtnDropDown';
import { TaskAltOutlined } from '@mui/icons-material';

const DropDownOptionForPayStr = (props) => {
  const { checkedItems, handlePublish } = props;

  const btnOptions = [
    {
      title: 'Publish',
      icon: <TaskAltOutlined sx={{ width: 20, height: 20 }} />,
      handleClick: handlePublish,
      disabled: false
    }
  ];

  return (
    <BtnDropDown BtnOptions={btnOptions} selectedOption={0}
      disabled={!checkedItems.length}
    />
  );
};


export default DropDownOptionForPayStr;