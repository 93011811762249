import {
  Box,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CardUI from '../../../shared/components/ui-elements/CardUI';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import TimePicker from 'rc-time-picker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import Footer from '../../footer/Footer';
import PersonIcon from '@mui/icons-material/Person';
import { useLocation } from 'react-router-dom';
import Modal from '../../../shared/components/ui-elements/Modal';
import ButtonUI from '../../../shared/components/ui-elements/ButtonUI';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  generateCurrentTimeStamp,
  generateFormData,
  disablePrevDates,
  enableListedDatesOnly,
} from '../common-services/functions';
import { getEligibility, getPublicHolidays, postFilesData, postFormData } from '../common-services/services';
import UserProfile from '../../../shared/components/claim-forms/UserProfile';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ReportingDetails from '../../../shared/components/claim-forms/ReportingDetails';

let mappedArr = new Map();
const OvertimeClaim = () => {
  const uniqueNum = Math.floor(Math.random() * 10000000000) + 10000000000;
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showImgName, setShowImgName] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');
  // const [time, setTime] = useState({
  //   hours:0,
  //   minutes: 0
  // });
// useEffect(()=>{
// },[time]);
// const handleTimeChange =()=>{
  
//   const hours = time.hours * 60*60*1000;
//   const minutes = time.minutes*60*1000;
//   const add = hours + minutes;
//   if(add>0)
//   setInputs({...inputs,mealBreak:add});
// }

  const navigate = useNavigate();
  const [inputs, setInputs] = useState([
    {
      reportingOfficer: 'Kelvin Lionel',
      hod: 'Benjamin Koh',
      costCenter: 'Singapore Labour',
      claimReferenceNumber: uniqueNum,
      submissionDate: generateCurrentTimeStamp(),
      claimsMonth: null,
      description: '',
      numberOfHoursClaim: '',
      reasonsForWorkingOutside: '',
      overTimeDate: null,
      timeFrom: null,
      timeTo: null,
      mealHours:0,
      mealMinutes:0,
      overtimeType: '',
      numberOfRestDays: '',
      numberOfShifts: '',
    },
  ]);
  const params = useLocation();

  const [type] = useState(params.state.type);
  const overtimeTypeOptions = ['Duty Allowance', 'Overtime', 'Public Holiday', 'Rest Day', 'Off Day'];
  const hours = [0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const minutes = [0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];
  let userData = JSON.parse(localStorage.getItem('userData'));
  let asProfile = localStorage.getItem('asProfile');

  const [holidays, setHolidays] = useState([]);
  const [publicHolidays, setPublicHolidays] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [eligibility, setEligibility] = useState({
    ND: false,
    PH: false,
    RD: false,
    DA: false,
    final: true});

  const handleChangeInput = (i, e, key = null) => {
    const values = [...inputs];
    if (key) values[i][key] = e;
    else values[i][e.target.name] = e.target.value;
    setInputs(values);
  };

  let addFormFields = (claimReferenceNumber) => {
    setInputs([
      ...inputs,
      {
        reportingOfficer: 'Kelvin Lionel',
        hod: 'Benjamin Koh',
        costCenter: 'Singapore Labour',
        claimReferenceNumber: claimReferenceNumber + 1,
        submissionDate: generateCurrentTimeStamp(),
        // claimAmount: '',
        claimsMonth: null,
        description: '',
        numberOfHoursClaim: '',
        reasonsForWorkingOutside: '',
        overTimeDate: null,
        timeFrom: null,
        timeTo: null,
        mealBreak: null,
        overtimeType: '',
        numberOfRestDays: '',
        numberOfShifts: '',
        mealHours:0,
        mealMinutes:0,
      },
    ]);
  };

  const copyForm = (i, input, claimReferenceNumber) => {
    let inputFinals = {
      ...input,
      claimReferenceNumber: claimReferenceNumber + 1,
    };

    setInputs([...inputs, inputFinals]);
  };

  const cardData = [
    {
      post: 'Reporting Officer',
      name: 'Kelvin Leionel',
    },
    {
      post: 'HOD',
      name: 'Benjamin Koh',
    },
    {
      post: 'Cost Center',
      name: 'Singapore Labour F..',
    },
  ];
  const deleteForm = (i) => {
    if (inputs.length > 1) {
      const newArray = [...inputs];
      newArray.splice(i, 1);
      setInputs(newArray);
    }
  };
  const format = 'hh:mm a';

  const validateFields = () => {
    for (let i = 0; i <= inputs.length - 1; i++) {
      if (
        inputs[i].overTimeDate == undefined ||
        inputs[i].overTimeDate == null ||
        inputs[i].overTimeDate == ''
      ) {
        window.alert('Please Enter Overtime Date !');
        return false;
      }
      if (
        inputs[i].description == undefined ||
        inputs[i].description == null ||
        inputs[i].description == ''
      ) {
        window.alert('Please Enter Reasons For Overtime !');
        return false;
      }
      
      if (
        inputs[i].overtimeType == undefined ||
        inputs[i].overtimeType == null ||
        inputs[i].overtimeType == ''
      ) {
        window.alert('Please Enter Payment Hours!');
        return false;
      }
      if (
        inputs[i].overtimeType == 'OD' ||
        inputs[i].overtimeType == 'OT' ||
        inputs[i].overtimeType == 'RD'
      ) {
        if (
          inputs[i].numberOfHoursClaim == undefined ||
          inputs[i].numberOfHoursClaim == null ||
          inputs[i].numberOfHoursClaim == ''
        ) {
          window.alert('Please Enter Number Of Hours Claim !');
          return false;
        }
      }
      if (
        inputs[i].timeFrom == undefined ||
        inputs[i].timeFrom == null ||
        inputs[i].timeFrom == ''
      ) {
        window.alert('Please Enter A Valid Time From !');
        return false;
      }
      if (
        inputs[i].timeTo == undefined ||
        inputs[i].timeTo == null ||
        inputs[i].timeTo == ''
      ) {
        window.alert('Please Enter A Time To !');
        return false;
      }
     
      if (
        inputs[i].overtimeType == undefined ||
        inputs[i].overtimeType == null ||
        inputs[i].overtimeType == ''
      ) {
        window.alert('Please Enter A Payment Hours !');
        return false;
      }
      
      if (inputs[i].overtimeType == 'RD') {
        if (
          inputs[i].numberOfRestDays == undefined ||
          inputs[i].numberOfRestDays == null ||
          inputs[i].numberOfRestDays == ''
        ) {
          window.alert('Please Enter Number Of Rest Days!');
          return false;
        }
      }
    }
    return true;
  };
  console.log(inputs,'inputtt');

  const generateDataForClaimRequest = (
    inputs,
    userData,
    dateTime,
    callType
  ) => {
    let finalFormData = [];
    let updateFormObject = {};
    let callForPost = callType;
    

    // console.log('updateFormObject', updateFormObject, finalFormData);
    if (inputs) {
      return (finalFormData = inputs.map((input) => {
        console.log(Number(input.numberOfHoursClaim),typeof input.numberOfHoursClaim, 'inititit');
        return (updateFormObject = {
          typeId: 3,
          employee: userData && userData.userDetails.id,
          claimReferenceNumber: input.claimReferenceNumber,
          claimMonth: input.claimsMonth,
          from: moment(input.timeFrom).format('hh:mm:ss'),
          to: moment(input.timeTo).format('hh:mm:ss'),
          // amount: Number(input.claimAmount),
          mealBreak: input.mealBreak,
          overTimeDate: input.overTimeDate,
          reasonsForWorkingOutside: input.reasonsForWorkingOutside,
          description: input.description,
          numberOfHoursClaim: (input.numberOfHoursClaim),
          overtimeType: input.overtimeType,
          submissionDate: dateTime,
          filePaths: mappedArr.get(input.claimReferenceNumber),
          status: callForPost,
          numberOfRestDays: Number(input.numberOfRestDays),
          numberOfShifts: Number(input.numberOfShifts),
          mealHours:(input.mealHours),
          mealMinutes:(input.mealMinutes),
        });
      }));
    }
  };

  const submitData = async (callType) => {
    const dateTime = generateCurrentTimeStamp();
    setFormSubmitted(true);
    setButtonClicked(callType);
    let validate = validateFields();

    if (!validate) {
      setFormSubmitted(false);
      return;
    }

    let finalFormData = generateDataForClaimRequest(
      inputs,
      userData,
      dateTime,
      callType
    );

    // POST call for submit form data.
    let businessTravelsResponse = await postFormData(finalFormData);

    if (businessTravelsResponse === 201) {
      let successMessage = '';
      if (callType === 'PENDING') {
        successMessage = 'Claim request submitted successfully !';
      } else {
        successMessage = 'Draft saved successfully !';
      }
      setFormSubmitted(false);
      openSuccessPopUp(successMessage);
    } else {
      setFormSubmitted(false);
      window.alert('Something went wrong');
    }
  };

  // On file select (from the pop up)
  const onFileChange = async (e, refNumber, typeId = 3) => {
    // check size for mapped array start
    if (e.target.files.length > 2) {
      alert('Only Two Attachements Are Allowed');
      return;
    }
    let res = mappedArr.get(refNumber);
    if (res && res.length) {
      if (res.length === 2) {
        alert('Only Two Attachements Are Allowed');
        return false;
      }
      if (res.length === 1 && e.target.files.length >= 2) {
        alert('Only Two Attachements Are Allowed');
        return false;
      }
    }
    // check size for mapped array end

    // Generates form data for file upload.
    let formData = await generateFormData(e, refNumber, typeId);

    // Send form data for file upload.
    if (formData) {
      let updatedPaths = await postFilesData(formData);
      // Sets updated paths received from POST api call.
      if (!Array.isArray(mappedArr.get(refNumber))) {
        mappedArr.set(refNumber, updatedPaths);
      } else {
        let temp = mappedArr.get(refNumber);
        temp = [...temp, ...updatedPaths];
        mappedArr.set(refNumber, temp);
      }
      setShowImgName(showImgName + 1);
    }
  };

  // remove attachment from claims data start
  const removeAttachement = (i, refNumber) => {
    const res = mappedArr.get(refNumber);
    if (Array.isArray(res)) {
      res.splice(i, 1);
    }
    setShowImgName(showImgName + 1);
  };
  // remove attachment from claims data end

  useEffect(() => {
    let list = [];
    let StartDate = moment(startDate);
    while(new Date() >= StartDate.toDate()){ 
      list.push(StartDate.format('YYYY-MM-DD'));
      StartDate.add(1,'day');
    }
    setHolidays(list);
  }, [startDate]);

  const calculateStartDate = ()=>{
    const today = moment();
    const day = today.date();
    if(day > 5){
      setStartDate(today.startOf('month').toDate());
    }else{
      setStartDate(today.subtract(1,'month').startOf('month').toDate());
    }
  };

  const getPublicHolidayOfMonth = ()=>{
    return publicHolidays.filter(holiday=>
       holidays.includes(holiday)
    );
  };

  useEffect(()=>{
    getEligibility().then(response =>{
      console.log('response --->', response);
      setEligibility(response);
      if(response.final){
        getPublicHolidays().then(data=> setPublicHolidays(data.holidayList ? data.holidayList : []));
        calculateStartDate();
      } else {
        // fire modal
      }
    });
   
  },[]);

  /** Close the modal */
  const closeBackdropHandler = () => {
    setIsBackdropOpen(false);
    navigate('/dashboard', {
      state: {
        claimType: '3',
      },
    });
  };

  // Open Modal When api response succesfully for(Draft/Submit)
  const openSuccessPopUp = (successMessage) => {
    setSuccessMessage(successMessage);
    setIsBackdropOpen(true);
  };

  // Overtime calculation pay logic
  const calculateOvertimePay = (i,e,type) => {
          let values = [...inputs];
          console.log(values,'value');
          let newFromTIme = new Date(values[0].timeFrom);
          let newTOtime = new Date(values[0].timeTo);
          const hours = values[0].mealHours * 60*60*1000;
            const minutes = values[0].mealMinutes*60*1000;
            const add = hours + minutes;
          let mealBreak = new Date(values[0].mealBreak);
          var diff = ((newTOtime).getTime()-(newFromTIme).getTime() -add);
          console.log(diff, newFromTIme.toLocaleTimeString(), newTOtime.getTime() , (mealBreak),diff,'time');
          
          if(values[0].overtimeType =='Duty Allowance'){

          

          if(diff>0){

            var msec = diff;
            var hh = Math.floor(msec / 1000 / 60 / 60);
            msec -= hh * 1000 * 60 * 60;
            var mm = Math.floor(msec / 1000 / 60);
            msec -= mm * 1000 * 60;
            
            console.log( (hh )+ ':' + (mm) );
            const total = hh*60 + mm;
            
            values[i].numberOfHoursClaim=Number(total/60).toFixed(2);
            values[i].mealBreak = Number(((values[0].mealHours * 60)+values[0].mealMinutes)/60).toFixed(2);
        }
        else{
          values[i].numberOfHoursClaim=0.00;
            
          }
          }
          else if (values[0].overtimeType == 'Overtime'){
            
            if(diff>0){

               msec = diff;
               hh = Math.floor(msec / 1000 / 60 / 60);
              msec -= hh * 1000 * 60 * 60;
               mm = Math.floor(msec / 1000 / 60);
              msec -= mm * 1000 * 60;
              
              console.log( (hh )+ ':' + (mm) );
              const total = hh*60 + mm;
              
              values[i].numberOfHoursClaim=Number((total/60)*1.5).toFixed(2);
              values[i].mealBreak = Number(((values[0].mealHours * 60)+values[0].mealMinutes)/60).toFixed(2);
          }
          else{
            values[i].numberOfHoursClaim=0.00;
              
            }
          }
          
  

    
  };
  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }} className='mar-top'>
        <Container>
          <Grid container>
              <ReportingDetails cardData={cardData} />
              <UserProfile
                name={userData && userData.userDetails.firstName}
                id={userData && userData.userDetails.id}
                designation={userData.userDetails?.jobTitle?.name}
                department={userData.userDetails?.department?.name}
              />
            <Grid xs={11}>
              <Box>
                {inputs &&
                  inputs.map((input, i) => (
                    <Box
                      key={i}
                      className='form-main'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '1em',
                        marginBottom: '4em',
                      }}
                    >
                      <Grid xs={11}>
                        <CardUI className='form-heading-wrap'>
                          
                          <Grid
                            xs={12}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Grid xs={6}>
                              <h4>Overtime Claim</h4>
                            </Grid>
                            <Grid
                              xs={6}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <h4>
                                Claim Reference Number :
                                {input.claimReferenceNumber}
                              </h4>
                            </Grid>
                          </Grid>
                        </CardUI>
                        <CardUI className='form-content-wrap'>
                          <Grid
                            container
                            spacing={2}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                             <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Overtime Type *</label>
                              <FormControl
                                sx={{ minWidth: 120 }}
                                fullWidth
                                size='small'
                              >
                                <Select
                                  value={input.overtimeType}
                                  onChange={(e) => {
                                    handleChangeInput(i, e);
                                    calculateOvertimePay(i, e);
                                  }}
                                  displayEmpty
                                  name='overtimeType'
                                  inputProps={{
                                    'aria-label': 'Without label',
                                  }}
                                  variant='outlined'
                                  autoWidth
                                >
                                  {overtimeTypeOptions &&
                                    overtimeTypeOptions.map((each, i) => (
                                      <MenuItem key={i} value={each}>
                                        {each}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            
                            {/* <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Claim Month *</label>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  variant='inline'
                                  inputFormat='MM/yyyy'
                                  disableFuture
                                  views={['year', 'month']}
                                  margin='normal'
                                  id='date-picker-inline'
                                  value={input.claimsMonth}
                                  onChange={(e) =>
                                    handleChangeInput(i, e, 'claimsMonth')
                                  }
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      size='small'
                                      fullWidth
                                      variant='outlined'
                                      {...params}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid> */}
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''> Reasons For Overtime *</label>
                              <TextField
                                required
                                variant='outlined'
                                color='primary'
                                size='small'
                                value={input.description}
                                name='description'
                                fullWidth
                                onChange={(e) => handleChangeInput(i, e)}
                              />
                            </Grid>
                            {/* <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>
                                Reasons For Overtime *
                              </label>
                              <TextField
                                required
                                variant='outlined'
                                color='primary'
                                size='small'
                                value={input.reasonsForWorkingOutside}
                                name='reasonsForWorkingOutside'
                                fullWidth
                                onChange={(e) => handleChangeInput(i, e)}
                              />
                            </Grid> */}
                           
                           
                             <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Over Time Date *</label>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  // shouldDisableDate={disablePrevDates()}
                                  shouldDisableDate={inputs[i].overtimeType === 'Public Holiday' ? enableListedDatesOnly(getPublicHolidayOfMonth()) : enableListedDatesOnly(holidays)}
                                  disableFuture
                                  value={input.overTimeDate}
                                  onChange={(e) =>
                                    handleChangeInput(i, e, 'overTimeDate')
                                  }
                                  
                                  renderInput={(params) => (
                                    <TextField
                                      size='small'
                                      fullWidth
                                      variant='outlined'
                                      {...params}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>From *</label>
                              
                                <TimePicker
                                  showSecond={false}
                                  value={input.timeFrom}
                                  onChange={(e) =>{
                                    handleChangeInput(i, e, 'timeFrom');
                                    calculateOvertimePay(i, e,'timeFrom');
                                  }
                                  }
                                  format={format}
                                  use12Hours
                                  inputReadOnly
                                />
                            </Grid>
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>To *</label>
                              
                                <TimePicker
                                  value={input.timeTo}
                                  onChange={(e) =>{
                                    handleChangeInput(i, e, 'timeTo');
                                    calculateOvertimePay(i, e, 'timeTo');}
                                  }
                                  showSecond={false}
                                  format={format}
                                  use12Hours
                                  inputReadOnly
                                />
                            </Grid>
                            <Grid item xs={12} lg={2} md={2} sm={2}>
                              <label htmlFor=''>Meal Break Hours</label>
                              <FormControl
                                sx={{ minWidth: 120 }}
                                fullWidth
                                size='small'
                              >
                                <Select
                                  value={input.mealHours}
                                  onChange={(e) => {
                                    handleChangeInput(i, e);
                                  
                                    calculateOvertimePay(i, e);
                                  }}
                                  displayEmpty
                                  name='mealHours'
                                  inputProps={{
                                    'aria-label': 'Without label',
                                  }}
                                  variant='outlined'
                                  autoWidth
                                >
                                  {hours &&
                                    hours.map((each, i) => (
                                      <MenuItem key={i} value={each}>
                                        {each}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                                {/* <TimePicker
                                  ampm={false}
                                  openTo='hours'
                                  views={['hours', 'minutes']}
                                  inputFormat='HH:mm'
                                  mask='__:__:__'
                                  value={input.mealBreak}
                                  onChange={(e) =>{
                                    handleChangeInput(i, e, 'mealBreak');
                                    calculateOvertimePay(i, e, 'mealBreak');}
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      size='small'
                                      fullWidth
                                      variant='outlined'
                                      {...params}
                                    />
                                  )}
                                /> */}
                              
                            </Grid>
                            <Grid item xs={12} lg={2} md={2} sm={2}>
                              <label htmlFor=''>Meal Break Min</label>
                              <FormControl
                                sx={{ minWidth: 120 }}
                                fullWidth
                                size='small'
                              >
                                <Select
                                  value={input.mealMinutes}
                                  onChange={(e) => {
                                    handleChangeInput(i, e);
                                    calculateOvertimePay(i, e);
                                    
                                  }}
                                  displayEmpty
                                  name='mealMinutes'
                                  inputProps={{
                                    'aria-label': 'Without label',
                                  }}
                                  variant='outlined'
                                  autoWidth
                                >
                                  {minutes &&
                                    minutes.map((each, i) => (
                                      <MenuItem key={i} value={each}>
                                        {each}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                                {/* <TimePicker
                                  ampm={false}
                                  openTo='hours'
                                  views={['hours', 'minutes']}
                                  inputFormat='HH:mm'
                                  mask='__:__:__'
                                  value={input.mealBreak}
                                  onChange={(e) =>{
                                    handleChangeInput(i, e, 'mealBreak');
                                    calculateOvertimePay(i, e, 'mealBreak');}
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      size='small'
                                      fullWidth
                                      variant='outlined'
                                      {...params}
                                    />
                                  )}
                                /> */}
                              
                            </Grid>
{/* 
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Claim Amount *</label>
                              <TextField
                                disabled
                                required
                                variant='outlined'
                                color='primary'
                                size='small'
                                value={input.claimAmount}
                                name='claimAmount'
                                fullWidth
                              />
                            </Grid> */}
                             {
                              input.overtimeType !== 'Public Holiday' &&input.overtimeType !== 'Rest Day' && (
                                <Grid item xs={12} lg={4} md={4} sm={4}>
                                  <label htmlFor=''>
                                    Number Of Hours Payable *
                                  </label>
                                  <TextField
                                    type='text'
                                    required
                                    variant='outlined'
                                    color='primary'
                                    size='small'
                                    disabled
                                    value={input.numberOfHoursClaim}
                                    name='numberOfHoursClaim'
                                    fullWidth
                                    onChange={(e) => {
                                      handleChangeInput(i, e);
                                      calculateOvertimePay(i, e);
                                    }}
                                  />
                                </Grid>
                              )}
                            {input.overtimeType == 'Rest Day' && (
                              <Grid item xs={12} lg={4} md={4} sm={4}>
                                <label htmlFor=''>Number Of Rest Days *</label>
                                <TextField
                                  type='number'
                                  required
                                  variant='outlined'
                                  color='primary'
                                  size='small'
                                  value={input.numberOfRestDays}
                                  name='numberOfRestDays'
                                  fullWidth
                                  onChange={(e) => {
                                    handleChangeInput(i, e);
                                    calculateOvertimePay(i, e);
                                  }}
                                />
                              </Grid>
                            )}
                            {/* {input.overtimeType == 'DA' && (
                              <Grid item xs={12} lg={4} md={4} sm={4}>
                                <label htmlFor=''>Number Of Shifts *</label>
                                <TextField
                                  type='number'
                                  required
                                  variant='outlined'
                                  color='primary'
                                  size='small'
                                  value={input.numberOfShifts}
                                  name='numberOfShifts'
                                  fullWidth
                                  onChange={(e) => {
                                    handleChangeInput(i, e);
                                    calculateOvertimePay(i, e);
                                  }}
                                />
                              </Grid>
                            )} */}
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor='icon-button-file'>
                                Attachment
                              </label>
                              <input
                                multiple
                                accept='image/pdf*'
                                type='file'
                                onChange={(e) =>
                                  onFileChange(e, input.claimReferenceNumber)
                                }
                              />
                              {showImgName > 0 &&
                                [...mappedArr].map(([key, value]) => {
                                  // console.log(value);
                                  return (
                                    <span key={key}>
                                      {input.claimReferenceNumber === key &&
                                        value &&
                                        value.map((each, i) => (
                                          <div
                                            key={i}
                                            className='file-path-wrap'
                                          >
                                            <h6>{input.claimReferenceNumber}_{i+1}</h6>
                                            <IconButton
                                              onClick={() =>
                                                removeAttachement(
                                                  i,
                                                  input.claimReferenceNumber
                                                )
                                              }
                                            >
                                              <CancelRoundedIcon />
                                            </IconButton>
                                          </div>
                                        ))}
                                    </span>
                                  );
                                })}
                            </Grid>

                            <Grid item xs={12} lg={4} md={4} sm={4}></Grid>
                          </Grid>
                        </CardUI>
                      </Grid>
                      <Grid
                        xs={1}
                        className='form-main-actions'
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <Grid>
                          <IconButton onClick={() => deleteForm(i)}>
                            <DeleteIcon
                              style={{
                                border: '1px solid #7db77d',
                                padding: '0.3em',
                                borderRadius: '8px',
                                color: '#7db77d',
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid>
                          <IconButton
                            onClick={() =>
                              copyForm(i, input, input.claimReferenceNumber)
                            }
                          >
                            <ContentCopyIcon
                              style={{
                                border: '1px solid #7db77d',
                                padding: '0.3em',
                                borderRadius: '8px',
                                color: '#7db77d',
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid>
                          <IconButton>
                            <AddIcon
                              onClick={() =>
                                addFormFields(input.claimReferenceNumber)
                              }
                              style={{
                                border: '1px solid #7db77d',
                                padding: '0.3em',
                                borderRadius: '8px',
                                color: '#7db77d',
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                <Grid style={{ position: 'relative' }}>
                  <Footer
                    type={type}
                    onclick={() => submitData('PENDING')}
                    onSaveDraft={() => submitData('DRAFT')}
                    disabledSubmit={formSubmitted}
                    buttonClicked = {buttonClicked}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Modal
        show={!eligibility.final}
        // onCancel={closeBackdropHandler}
        header={<h1>Oops!</h1>}
        contentClass='modal-content'
        footerClass='footer-content'
        submitButton={
          <ButtonUI
            backgroundColor='#00AC94'
            color='#ffffff'
            size='small'
            padding='3px 20px'
            onclick={closeBackdropHandler}
          >
            Ok
          </ButtonUI>
        }
      >
        <h4>Please check your eligibility for this claim.</h4>
      </Modal>

      <Modal
        show={isBackdropOpen}
        onCancel={closeBackdropHandler}
        header={<h1>Success</h1>}
        contentClass='modal-content'
        footerClass='footer-content'
        submitButton={
          <ButtonUI
            backgroundColor='#00AC94'
            color='#ffffff'
            size='small'
            padding='3px 20px'
            onclick={closeBackdropHandler}
          >
            Ok
          </ButtonUI>
        }
      >
        <h4>{successMessage}</h4>
      </Modal>
    </React.Fragment>
  );
};
export default OvertimeClaim;
