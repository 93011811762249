import { Box } from '@mui/system';
import React from 'react';
import ActionHeader from '../../../ActionHeader/ActionHeader';
import FormView from '../../../../common/Form/FormView';
import { PayStrFormField } from '../../../constants/onboarding.constants';

const PayStrViewForm = (props) => {
  const { setViewMode, formConfig, salaryStructureFormData, setShowForm } = props;
  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            labelText={salaryStructureFormData?.[PayStrFormField.Name]}
            editButtonText='Edit'
            onEditClick={() => setViewMode(false)}
            showSave={true}
            showBackButton
            onBackClick={()=> setShowForm(false)}
          />
        </Box>
        <FormView
          list={formConfig}
          info={salaryStructureFormData}
        />
      </Box>
    </Box>
  );
};

export default PayStrViewForm;