import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FormEdit from '../../../common/Form/FormEdit';
import { RHSBoxAction } from '../../../../Performance/Goals/RHSBoxAction/RHSBoxAction';
import { addEducationTab, deleteEducationTab, duplicateEducationTab } from '../../../../../redux/userOnboarding/action';
import FormView from '../../../common/Form/FormView';

const EducationForm = (props) => {
  const { formConfig, tabIndex, numberOfAccordion, viewMode, setEduValidationErrors,
     eduValidationErrors, formData, setEduData, getDropdownOptions, handleDropdownClick } = props;
  const dispatch = useDispatch();

  const [formInfo, setFormInfo] = useState(formData);
  const [validationErrors, setValidationErrors] = useState({});


  useEffect(() => {
    setValidationErrors(eduValidationErrors);
  }, [eduValidationErrors]);

  useEffect(() => {
    setEduData((prev) => {
      const newData = [...prev];
      newData[tabIndex] = { ...formInfo };
      return newData;
    });
  }, [formInfo]);

  const handleAdd = () => {
    setEduValidationErrors((prev) => [...prev, {}]);
    dispatch(addEducationTab());
  };
  const handleDelete = () => {
    setEduValidationErrors((prev) => {
      const newError = [...prev];
      newError.splice(tabIndex, 1);
      return newError;
    });
    setEduData((prev) => {
      const newEmpData = [...prev];
      newEmpData.splice(tabIndex, 1);
      return newEmpData;
    });
    dispatch(deleteEducationTab(tabIndex));
  };
  const handleDuplicate = () => {
    setEduValidationErrors((prev) => [...prev, {}]);
    dispatch(duplicateEducationTab(tabIndex));
    const {id,...rest} = formData;
    setEduData((prev) => [...prev, rest]);
  };
  return viewMode ? (<FormView list={formConfig} info={formData} />) :
    (<Box position='relative'
    >
      <FormEdit
        list={formConfig}
        formInfo={formInfo}
        setFormData={setFormInfo}
        errors={validationErrors}
        setErrors={setValidationErrors}
        getDropdownOptions={getDropdownOptions}
        handleDropdownClick={handleDropdownClick}
      />
      <RHSBoxAction
        disableDelete={numberOfAccordion === 1}
        onClickAdd={handleAdd}
        onClickDelete={handleDelete}
        onClickCopy={handleDuplicate}
      />
    </Box>);
};

export default EducationForm;