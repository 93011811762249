import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Typography, styled } from '@mui/material';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import {
  FileDownloadOutlined,
  InfoOutlined,
  SettingsBackupRestoreOutlined,
} from '@mui/icons-material';
import CandidateModal from '../ModalMap/CandidateModal';
import {
  APIRequestStatus,
  DebounceTimer,
  LoadingStatus,
  ToastThemes,
} from '../../../../../constants/common';
import {
  getAllCandidateList,
  resetCandidate,
} from '../../../../../redux/candidateDetails/action';
import { candidateHeadCells } from '../../../utils/recruitingUtils';
import ListView from '../../../../../atoms/DataList/ListView';
import StatusOption from './StatusOption';
import {
  AllCandidate,
  CandidateDetails,
  clearFilterMessage,
} from '../../../Constant/constant';
import { permissionsObj } from '../../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import NavTabBar from '../../../../../atoms/Tabs/nav-tab-bar';
import { getAllowedTabs } from '../../../utils/recruiting.permission.util';
import { recruitingTabs } from '../../../utils/tabs';
import TryAgain from '../../../../molecules/ErrorState/error.component';
import { showToast } from '../../../../../utils/common.util';
import {
  currentDate,
  getFormattedValues,
  noOfmonthfromCurrent,
} from '../../../../../utils/formatter';
import { useDebounce } from 'usehooks-ts';
import { DateRangePro } from '../../../../molecules/DateRange/DateRangePro';

function AllCandidates() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    candidateList,
    requestProcessing: {
      [APIRequestStatus.GET_ALL_CANDIDATE_LIST]: candidateListLoading,
    },
  } = useSelector((state) => state.candidate);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const { logoImage } = useSelector((state) => state && state.onBoarding);
  const defaultMonths = logoImage?.defaultMonths;

  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState([
    noOfmonthfromCurrent(defaultMonths),
    currentDate,
  ]);
  const { recrutingPermissions } = useSelector(
    (state) => state.modulePermissions
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredList, setFilteredList] = useState(candidateList);
  const jId = searchParams.get('jId');
  const candidateNameParams = searchParams.get('cName');
  const searchQuery = searchParams?.get('q');
  const debouncedVal = useDebounce(searchQuery, DebounceTimer);

  const handleClear = (event) => {
    event.stopPropagation();
    searchParams.delete('jId');
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (currentUserRoleId) {
      dispatch(
        getAllCandidateList(
          currentUserRoleId,
          searchQuery,
          getFormattedValues('YYYY-MM-DD', selectedDate[0]),
          getFormattedValues('YYYY-MM-DD', selectedDate[1]),
          candidateNameParams
        )
      );
    }
  }, [currentUserRoleId, selectedDate, candidateNameParams, debouncedVal]);

  useEffect(() => {
    setCheckedItems([]);
  }, [filteredList]);

  useEffect(() => {
    if (jId) {
      const newList = candidateList.filter((item) => {
        const { jobId } = item;
        return Number(jId) === jobId;
      });
      setFilteredList(newList);
    } else {
      setFilteredList(candidateList);
    }
  }, [jId, candidateList]);

  const rowData = filteredList.map((item) => {
    const {
      id,
      name,
      candidateCode,
      candidateEmail,
      candidateMobileNumber,
      createdUtc,
      fkDocument,
      statusCode,
      isPublished,
      position,
      recruiter,
      jobId,
      interviewerName,
      interviewTimeUtc,
      fkCandidateJobMapping,
      interviewStatusCode,
    } = item;
    return {
      canidateId: candidateCode,
      name: name,
      rName: recruiter || '',
      position: position || '',
      email: jId ? { hide: true } : candidateEmail,
      contact: jId ? { hide: true } : candidateMobileNumber,
      interviewer: jId ? interviewerName : { hide: true },
      interviewDate: jId ? (
        <div>
          {interviewTimeUtc
            ? moment(interviewTimeUtc, 'YYYY-MM-DD HH:mm')
                .utc(interviewTimeUtc, 'YYYY-MM-DD HH:mm')
                .local()
                .format('YYYY/MM/DD')
            : ''}
          <br />
          {interviewTimeUtc
            ? moment(interviewTimeUtc, 'YYYY-MM-DD HH:mm')
                .utc(interviewTimeUtc, 'YYYY-MM-DD HH:mm')
                .local()
                .format('hh:mm a')
            : ''}
        </div>
      ) : (
        { hide: true }
      ),
      interviewStatus: jId ? interviewStatusCode?.name : { hide: true },
      status: jId ? (
        { hide: true }
      ) : (
        <StatusOption
          id={id}
          value={statusCode}
          isDisabled={!jobId}
          jobId={jobId}
          searchInput={searchQuery}
          selectedDate={selectedDate}
          candidateNameParams={candidateNameParams}
        />
      ),
      createdOn: moment(createdUtc, 'YYYY-MM-DD').format('YYYY/MM/DD'),
      download: (
        <a href={fkDocument?.url} download={fkDocument?.filename}>
          <StyledButton
            variant="outlined"
            size="medium"
            disabled={fkDocument?.url ? false : true}
          >
            <FileDownloadOutlined
              sx={{
                color: fkDocument?.url ? 'neutral.dark80' : 'neutral.dark40',
              }}
            />
          </StyledButton>
        </a>
      ),
      action: (
        <IconButton
          onClick={() =>
            navigate({
              pathname: `${id}`,
              search: `?cjid=${fkCandidateJobMapping}`,
            })
          }
        >
          <InfoOutlined sx={{ color: 'primary.main80' }} />
        </IconButton>
      ),
      id: {
        hide: true,
        value: id,
      },
      isPublished: {
        hide: true,
        value: isPublished,
      },
    };
  });

  const handleAddClick = () => {
    navigate('add-candidate');
    dispatch(resetCandidate());
  };

  const isDisabledCheckbox = (item) => {
    return !item.isPublished.value;
  };

  const isDisabledReset = () => {
    return (
      !candidateNameParams &&
      selectedDate[0].isSame(noOfmonthfromCurrent(defaultMonths)) &&
      selectedDate[1].isSame(currentDate)
    );
  };

  const resetFilters = () => {
    setSelectedDate([noOfmonthfromCurrent(defaultMonths), currentDate]);
    searchParams.delete('cName');
    setSearchParams(searchParams);
  };

  const handleNameClear = (event) => {
    event.stopPropagation();
    searchParams.delete('cName');
    setSearchParams(searchParams);
  };

  const allowedTabs = getAllowedTabs(recrutingPermissions, recruitingTabs);
  if (candidateListLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <NavTabBar list={allowedTabs} />
      {recrutingPermissions?.[CandidateDetails]?.[permissionsObj.allowView] ||
      recrutingPermissions?.[AllCandidate]?.[permissionsObj.allowView] ? (
        <>
          <CircularLoader
            show={candidateListLoading === LoadingStatus.Loading}
          />
          <Box sx={{ background: 'white' }}>
            <Box display="flex" justifyContent="flex-end" gap={2} mb={1} p={2}>
              <DateRangePro
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
              <Button
                startIcon={<SettingsBackupRestoreOutlined />}
                sx={{ textTransform: 'capitalize' }}
                disabled={isDisabledReset()}
                onClick={resetFilters}
              >
                Clear
              </Button>
              <Box>
                <CandidateModal
                  checkedItems={checkedItems}
                  jobId={jId}
                  candidateList={filteredList}
                  searchInput={searchQuery}
                  selectedDate={selectedDate}
                  candidateNameParams={candidateNameParams}
                />
              </Box>
            </Box>
            <ListView
              headCells={candidateHeadCells(
                jId,
                handleClear,
                candidateNameParams,
                handleNameClear
              )}
              rowsData={rowData}
              showCheckbox
              onAddClick={handleAddClick}
              setCheckedItems={setCheckedItems}
              checkedItems={checkedItems}
              showSerialNo={true}
              showAddIcon={
                recrutingPermissions?.[CandidateDetails]?.[
                  permissionsObj.allowAdd
                ] ||
                recrutingPermissions?.[AllCandidate]?.[permissionsObj.allowAdd]
              }
              isDisabledCheckbox={(item) => isDisabledCheckbox(item)}
            />
          </Box>
        </>
      ) : (
        <Navigate to={allowedTabs[0].path} />
      )}
    </>
  );
}
export default React.memo(AllCandidates);

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutral.dark40,
  borderColor: theme.palette.neutral.dark60,
  textTransform: 'capitalize',
  px: 2,
  '&:hover': {
    borderColor: theme.palette.neutral.light40,
  },
}));
