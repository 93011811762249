import React from 'react';
import BtnDropDown from '../../../../atoms/Buttons/BtnDropDown';
import { DownloadOutlined, } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { AllowPublish, PayrollPermissions, giroFileDashboard, } from '../../Constant/Constant';
import { permissionsObj } from '../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';

const DropDownActionForGiroFile = (props) => {
  const { checkedItems, handlePublish} = props;
  const { payrollPermission } = useSelector((state) => state.modulePermissions);

  const payrollBtnOption = [
    {
      id: 1,
      title: 'Download Giro File',
      icon: <DownloadOutlined sx={{ width: 20, height: 20 }} />,
      handleClick: handlePublish,
      disabled: !checkedItems.length,
      hide: !payrollPermission?.[PayrollPermissions.ALLOW_GIRO_FILE]?.[permissionsObj.allowView],
    },
  ];
  if (payrollBtnOption.filter((item) => !item.hide).length > 0) {
    return (
      <BtnDropDown
        BtnOptions={payrollBtnOption.filter((item) => !item.hide)}
        selectedOption={0}
      />
    );
  }
  return true;
};
export default DropDownActionForGiroFile;
