import React, { useEffect, useState } from 'react';
import FormEdit from '../../../OnBoarding/common/Form/FormEdit';
import { FileValidationFormConfig, allowedFiles } from '../../utils/validation.utils';

const FileUploadValidations = (props) => {
  const { setFormInfo, fileUploadValidations } = props;
  const [form, setForm] = useState(fileUploadValidations || {});

  useEffect(() => {
    setFormInfo((prev) => ({
      ...prev,
      fileUploadValidations: form,
    }));
  }, [form]);

  const getDropdownOptions = (name) => {
    switch (name) {
      case 'allowedFiles': {
        return allowedFiles;
      }
    }
  };

  return (
    <>
      <FormEdit
        list={FileValidationFormConfig}
        padding={0}
        setFormData={setForm}
        formInfo={form}
        getDropdownOptions={getDropdownOptions}
        hideFields={false}
      />
    </>
  );
};

export default FileUploadValidations;
