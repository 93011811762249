import React from 'react';
import { getIcon } from '../../../../helper/helper-utlis';
import { Box, Button } from '@mui/material';

const FunctionPicker = (props) => {
  const { handleClick, height = '255px' } = props;
  const list = [
    { label: 'Show Holidays', refName: 'showHoliday' },
    { label: 'Show Off/Sunday', refName: 'showSunday' },
    { label: 'Show Rest/Saturday', refName: 'showSaturday' },
  ];

  return (
    <>
      <Box
        border="1px solid #efefef"
        borderLeft="none"
        height={height}
        sx={{ overflowY: 'scroll' }}
        width="280px"
      >
        {list.map((item, index) => {
          return (
            <Box
              key={index}
              borderBottom="1px solid #efefef"
              display="flex"
              alignItems="center"
              onClick={() => handleClick(item)}
              padding="3px 10px 5px 12px"
              lineHeight="30px"
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(167, 236, 226, 0.3)',
                },
              }}
            >
              {item.label}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default FunctionPicker;
