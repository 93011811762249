import { Box } from '@mui/material';
import React from 'react';
import CalculatorButton from './calculator-button.component';
import { BackspaceOutlined } from '@mui/icons-material';

const Calculator = (props) => {
  const { handleDigitClick, handleBackClick } = props;
  const calculatorConfig = [
    '(',
    ')',
    '/',
    '*',
    7,
    8,
    9,
    '-',
    4,
    5,
    6,
    '+',
    1,
    2,
    3,
    '.',
  ];
  return (
    <Box
      // maxWidth={240}
      display="grid"
      gridTemplateColumns="repeat(4, 40px)"
      gridTemplateRows="repeat(5, 40px)"
      gap="10px 10px"
      padding={1}
    >
      {calculatorConfig.map((item, index) => {
        return (
          <CalculatorButton
            key={index}
            digit={item}
            handleDigitClick={handleDigitClick}
            borderRadius={isNaN(item) ? '4px' : '20px'}
          />
        );
      })}
      <button
        className="ve-calc-btn"
        onClick={() => handleDigitClick(0)}
        style={{
          padding: '5px 5px',
          border: '1px solid #ccc',
          fontSize: '13px',
          background: 'white',
          borderRadius: '20px',
          gridColumn: 'span 2',
        }}
      >
        {0}
      </button>
      <button
        className="ve-calc-btn"
        onClick={() => handleDigitClick(',')}
        style={{
          padding: '5px 5px',
          border: '1px solid #ccc',
          fontSize: '13px',
          background: 'white',
          borderRadius: '4px',
        }}
      >
        ,
      </button>
      <button
        onClick={handleBackClick}
        className="ve-calc-btn"
        style={{
          padding: '5px 5px',
          border: '1px solid #ccc',
          fontSize: '13px',
          background: 'white',
          borderRadius: '4px',
        }}
      >
        <BackspaceOutlined sx={{ width: '17px' }} />
      </button>
    </Box>
  );
};

export default Calculator;
