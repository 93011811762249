export const GET_BENEFITS_DETAILS_REQUEST = 'GET_BENEFITS_DETAILS_REQUEST';
export const GET_BENEFITS_DETAILS_SUCCESS = 'GET_BENEFITS_DETAILS_SUCCESS';
export const GET_BENEFITS_DETAILS_FAILURE = 'GET_BENEFITS_DETAILS_FAILURE';

export const GET_ALL_BENEFITS_LIST_REQUEST = 'GET_ALL_BENEFITS_LIST_REQUEST';
export const GET_ALL_BENEFITS_LIST_SUCCESS = 'GET_ALL_BENEFITS_LIST_SUCCESS';
export const GET_ALL_BENEFITS_LIST_FAILURE = 'GET_ALL_BENEFITS_LIST_FAILURE';

export const GET_ALL_USER_BENEFITS_LIST_REQUEST = 'GET_ALL_USER_BENEFITS_LIST_REQUEST';
export const GET_ALL_USER_BENEFITS_LIST_SUCCESS = 'GET_ALL_USER_BENEFITS_LIST_SUCCESS';
export const GET_ALL_USER_BENEFITS_LIST_FAILURE = 'GET_ALL_USER_BENEFITS_LIST_FAILURE';

export const RESET_BENEFITS = 'RESET_BENEFITS';
