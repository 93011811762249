import { Box, Typography } from '@mui/material';
import React from 'react';
import AttendeesModalHeader from './atteendees-header-component';
import TextAttendees from './text-attendees.component';
import { formatCurrency } from '../../../../../../utils/common.util';

const AttendeesModal = (props) => {
  const { list = [] } = props;
  let totalAmount = 0;
  return (
    <>
      <Box border={'1px solid gray'} p={3} height={'30vh'} overflow={'scroll'}>
        <AttendeesModalHeader />
        <Box
          display="grid"
          gridTemplateColumns="repeat(4, 200px)"
          gridTemplateRows={`repeat(${list.length}, 3px)`}
          gap="30px 30px"
          padding={1}
        >
          {list.map((item, index) => {
            totalAmount += Number(item?.amountUsed);
            return (
              <>
                <Box width="200px">
                  <TextAttendees item={item?.name} />
                </Box>
                <Box width="200px">
                  <TextAttendees item={item?.departmentName} />
                </Box>
                <Box width="200px">
                  <TextAttendees
                    textAlign="right"
                    item={formatCurrency(Number(item?.entitlementBalanceAmt))}
                  />
                </Box>
                <Box width="200px">
                  <TextAttendees
                    textAlign="right"
                    item={formatCurrency(Number(item?.amountUsed))}
                  />
                </Box>
              </>
            );
          })}
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" mr={3} mt={1}>
        <Typography textAlign="right" variant="h5">
          TOTAL AMOUNT:
        </Typography>
        <Typography textAlign="right" ml={1}>
          {formatCurrency(Number(totalAmount))}
        </Typography>
      </Box>
    </>
  );
};

export default AttendeesModal;
