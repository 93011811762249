import { OnboardingServices } from '../../services/onboarding';
import {
  GET_ALL_CITIES_FAILURE, GET_ALL_CITIES_REQUEST, GET_ALL_CITIES_SUCCESS,
} from './actionTypes';


const getAllCitiesRequest = () => (
  {
    type: GET_ALL_CITIES_REQUEST
  }
);

const getAllCitiesSuccess = (payload) => (
  {
    type: GET_ALL_CITIES_SUCCESS,
    payload
  }
);

const getAllCitiesFailure = () => (
  {
    type: GET_ALL_CITIES_FAILURE
  }
);

export const getAllCities = () => async (dispatch) => {
  dispatch(getAllCitiesRequest());
  return new OnboardingServices().getAllCities().then((res) => {
    dispatch(getAllCitiesSuccess(res));
    return true;
  }).catch(() => {
    dispatch(getAllCitiesFailure());
    return false;
  });
};  