import { ToastMessages, stringSubstitute } from '../../components/OnBoarding/Organization/constants/onboarding.constants';
import { getFormBody } from '../../components/OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { OnboardingServices } from '../../services/onboarding';
import {
	POST_USER_INFO_FAILURE, POST_USER_INFO_REQUEST, POST_USER_INFO_SUCCESS,
	UPDATE_USER_INFO_FAILURE, UPDATE_USER_INFO_REQUEST, UPDATE_USER_INFO_SUCCESS, PUBLISH_USER_REQUEST,
	PUBLISH_USER_SUCCESS, PUBLISH_USER_FAILURE, GET_USER_DETAILS_REQUEST, GET_USER_DETAILS_SUCCESS, GET_USER_DETAILS_FAILURE, RESET_USER_INFO,
	GET_USER_DOCUMENT_LIST_FAILURE, GET_USER_DOCUMENT_LIST_REQUEST, GET_USER_DOCUMENT_LIST_SUCCESS,
	GET_USER_BLOOD_GROUP_FAILURE, GET_USER_BLOOD_GROUP_REQUEST, GET_USER_BLOOD_GROUP_SUCCESS,
	GET_USER_GENDER_FAILURE, GET_USER_GENDER_REQUEST, GET_USER_GENDER_SUCCESS,
	GET_USER_WORK_STATUS_FAILURE, GET_USER_WORK_STATUS_REQUEST, GET_USER_WORK_STATUS_SUCCESS,
	GET_USER_DESIGNATION_FAILURE, GET_USER_DESIGNATION_REQUEST, GET_USER_DESIGNATION_SUCCESS,
	GET_USER_ONBOARDING_MENU_REQUEST, GET_USER_ONBOARDING_MENU_SUCCESS, GET_USER_ONBOARDING_MENU_FAILURE,
	UPDATE_USER_STATUS_REQUEST, UPDATE_USER_STATUS_SUCCESS, UPDATE_USER_STATUS_FAILURE,
	GET_USER_COST_CENTRE_FAILURE, GET_USER_COST_CENTRE_REQUEST, GET_USER_COST_CENTRE_SUCCESS,
	GET_BUSINESS_UNITS_REQUEST, GET_BUSINESS_UNITS_SUCCESS, GET_BUSINESS_UNITS_FAILURE,
	GET_EMPLOYMENT_DETAILS_REQUEST, GET_EMPLOYMENT_DETAILS_SUCCESS, GET_EMPLOYMENT_DETAILS_FAILURE,
	GET_ORG_DIVISIONS_REQUEST, GET_ORG_DIVISIONS_SUCCESS, GET_ORG_DIVISIONS_FAILURE,
	GET_USER_NATIONALITY_FAILURE, GET_USER_NATIONALITY_REQUEST, GET_USER_NATIONALITY_SUCCESS,
	GET_USER_RACE_FAILURE, GET_USER_RACE_REQUEST, GET_USER_RACE_SUCCESS,
	GET_USER_RELIGION_FAILURE, GET_USER_RELIGION_REQUEST, GET_USER_RELIGION_SUCCESS,
	GET_USER_CATEGORY_FAILURE, GET_USER_CATEGORY_REQUEST, GET_USER_CATEGORY_SUCCESS,
	GET_USER_FUNCTION_FAILURE, GET_USER_FUNCTION_REQUEST, GET_USER_FUNCTION_SUCCESS,
	GET_USER_WORK_AUTHORIZATION_FAILURE, GET_USER_WORK_AUTHORIZATION_REQUEST, GET_USER_WORK_AUTHORIZATION_SUCCESS, RESET_WORK_AUTH, UPDATE_DOC_LIST, ADD_EMPLOYEE_TAB, DELETE_EMPLOYEE_TAB, ADD_EDUCATION_TAB, DELETE_EDUCATION_TAB, DUPLICATE_EDUCATION_TAB, DUPLICATE_EMPLOYEE_TAB, GET_EMPLOYEE_CONFIG_REQUEST, GET_EMPLOYEE_CONFIG_SUCCESS, GET_EMPLOYEE_CONFIG_FAILURE, GET_EDUCATION_CONFIG_REQUEST, GET_EDUCATION_CONFIG_SUCCESS, GET_EDUCATION_CONFIG_FAILURE, POST_EMPLOYEE_INFO_REQUEST, POST_EMPLOYEE_INFO_SUCCESS, POST_EMPLOYEE_INFO_FAILURE, POST_EDUCATION_INFO_REQUEST, POST_EDUCATION_INFO_SUCCESS, POST_EDUCATION_INFO_FAILURE,
} from './actionTypes';

export const postUserInfoRequest = () => (
	{
		type: POST_USER_INFO_REQUEST
	}
);

export const postUserInfoSuccess = (payload) => (
	{
		type: POST_USER_INFO_SUCCESS,
		payload
	}
);

export const postUserInfoFailure = () => (
	{
		type: POST_USER_INFO_FAILURE
	}
);

export const updateUserInfoRequest = () => (
	{
		type: UPDATE_USER_INFO_REQUEST
	}
);

export const updateUserInfoSuccess = (payload) => (
	{
		type: UPDATE_USER_INFO_SUCCESS,
		payload
	}
);

export const updateUserInfoFailure = () => (
	{
		type: UPDATE_USER_INFO_FAILURE
	}
);

export const publishUserRequest = () => (
	{
		type: PUBLISH_USER_REQUEST
	}
);

export const publishUserSuccess = () => (
	{
		type: PUBLISH_USER_SUCCESS
	}
);

export const publishUserFailure = () => (
	{
		type: PUBLISH_USER_FAILURE
	}
);

export const getUserDetailsRequest = () => (
	{
		type: GET_USER_DETAILS_REQUEST
	}
);

export const getUserDetailsSuccess = (payload) => (
	{
		type: GET_USER_DETAILS_SUCCESS,
		payload
	}
);

export const getUserDetailsFailure = () => (
	{
		type: GET_USER_DETAILS_FAILURE
	}
);

export const resetUserInfo = () => (
	{
		type: RESET_USER_INFO
	}
);

export const getUserGenderRequest = () => (
	{
		type: GET_USER_GENDER_REQUEST
	}
);

export const getUserGenderSuccess = (payload) => (
	{
		type: GET_USER_GENDER_SUCCESS,
		payload
	}
);

export const getUserGenderFailure = () => (
	{
		type: GET_USER_GENDER_FAILURE
	}
);

export const getUserBloodGroupRequest = () => (
	{
		type: GET_USER_BLOOD_GROUP_REQUEST
	}
);

export const getUserBloodGroupSuccess = (payload) => (
	{
		type: GET_USER_BLOOD_GROUP_SUCCESS,
		payload
	}
);

export const getUserBloodGroupFailure = () => (
	{
		type: GET_USER_BLOOD_GROUP_FAILURE
	}
);

export const getUserWorkStatusRequest = () => (
	{
		type: GET_USER_WORK_STATUS_REQUEST
	}
);

export const getUserWorkStatusSuccess = (payload) => (
	{
		type: GET_USER_WORK_STATUS_SUCCESS,
		payload
	}
);

export const getUserWorkStatusFailure = () => (
	{
		type: GET_USER_WORK_STATUS_FAILURE
	}
);

export const getUserDesignationRequest = () => (
	{
		type: GET_USER_DESIGNATION_REQUEST
	}
);

export const getUserDesignationSuccess = (payload) => (
	{
		type: GET_USER_DESIGNATION_SUCCESS,
		payload
	}
);

export const getUserDesignationFailure = () => (
	{
		type: GET_USER_DESIGNATION_FAILURE
	}
);

export const getUserDocumentsListRequest = () => (
	{
		type: GET_USER_DOCUMENT_LIST_REQUEST
	}
);

export const getUserDocumentsListSuccess = (payload) => (
	{
		type: GET_USER_DOCUMENT_LIST_SUCCESS,
		payload
	}
);

export const getUserDocumentsListFailure = () => (
	{
		type: GET_USER_DOCUMENT_LIST_FAILURE
	}
);

export const getUserOnboardingMenuRequest = () => (
	{
		type: GET_USER_ONBOARDING_MENU_REQUEST
	}
);

export const getUserOnboardingMenuSuccess = (payload) => (
	{
		type: GET_USER_ONBOARDING_MENU_SUCCESS,
		payload
	}
);

export const getUserOnboardingMenuFailure = () => (
	{
		type: GET_USER_ONBOARDING_MENU_FAILURE
	}
);

export const updateUserStatusRequest = () => (
	{
		type: UPDATE_USER_STATUS_REQUEST
	}
);

export const updateUserStatusSuccess = () => (
	{
		type: UPDATE_USER_STATUS_SUCCESS,
	}
);

export const updateUserStatusFailure = () => (
	{
		type: UPDATE_USER_STATUS_FAILURE
	}
);

export const getUserCostCentreRequest = () => (
	{
		type: GET_USER_COST_CENTRE_REQUEST
	}
);

export const getUserCostCentreSuccess = (payload) => (
	{
		type: GET_USER_COST_CENTRE_SUCCESS,
		payload
	}
);

export const getUserCostCentreFailure = () => (
	{
		type: GET_USER_COST_CENTRE_FAILURE
	}
);

export const getBusinessUnitsRequest = () => (
	{
		type: GET_BUSINESS_UNITS_REQUEST
	}
);

export const getBusinessUnitsSuccess = (payload) => (
	{
		type: GET_BUSINESS_UNITS_SUCCESS,
		payload
	}
);

export const getBusinessUnitsFailure = () => (
	{
		type: GET_BUSINESS_UNITS_FAILURE
	}
);

export const getEmploymentTypesRequest = () => (
	{
		type: GET_EMPLOYMENT_DETAILS_REQUEST
	}
);

export const getEmploymentTypesSuccess = (payload) => (
	{
		type: GET_EMPLOYMENT_DETAILS_SUCCESS,
		payload
	}
);

export const getEmploymentTypesFailure = () => (
	{
		type: GET_EMPLOYMENT_DETAILS_FAILURE
	}
);

export const getOrgDivisionsRequest = () => (
	{
		type: GET_ORG_DIVISIONS_REQUEST
	}
);

export const getOrgDivisionsSuccess = (payload) => (
	{
		type: GET_ORG_DIVISIONS_SUCCESS,
		payload
	}
);

export const getOrgDivisionsFailure = () => (
	{
		type: GET_ORG_DIVISIONS_FAILURE
	}
);

export const getUserNationalityRequest = () => (
	{
		type: GET_USER_NATIONALITY_REQUEST
	}
);

export const getUserNationalitySuccess = (payload) => (
	{
		type: GET_USER_NATIONALITY_SUCCESS,
		payload
	}
);

export const getUserNationalityFailure = () => (
	{
		type: GET_USER_NATIONALITY_FAILURE
	}
);

export const getUserReligionRequest = () => (
	{
		type: GET_USER_RELIGION_REQUEST
	}
);

export const getUserReligionSuccess = (payload) => (
	{
		type: GET_USER_RELIGION_SUCCESS,
		payload
	}
);

export const getUserReligionFailure = () => (
	{
		type: GET_USER_RELIGION_FAILURE
	}
);

export const getUserRaceRequest = () => (
	{
		type: GET_USER_RACE_REQUEST
	}
);

export const getUserRaceSuccess = (payload) => (
	{
		type: GET_USER_RACE_SUCCESS,
		payload
	}
);

export const getUserRaceFailure = () => (
	{
		type: GET_USER_RACE_FAILURE
	}
);

export const getUserFunctionRequest = () => (
	{
		type: GET_USER_FUNCTION_REQUEST
	}
);

export const getUserFunctionSuccess = (payload) => (
	{
		type: GET_USER_FUNCTION_SUCCESS,
		payload
	}
);

export const getUserFunctionFailure = () => (
	{
		type: GET_USER_FUNCTION_FAILURE
	}
);

export const getUserCategoryRequest = () => (
	{
		type: GET_USER_CATEGORY_REQUEST
	}
);

export const getUserCategorySuccess = (payload) => (
	{
		type: GET_USER_CATEGORY_SUCCESS,
		payload
	}
);

export const getUserCategoryFailure = () => (
	{
		type: GET_USER_CATEGORY_FAILURE
	}
);

export const getUserWorkAuthorizationRequest = () => (
	{
		type: GET_USER_WORK_AUTHORIZATION_REQUEST
	}
);

export const getUserWorkAuthorizationSuccess = (payload) => (
	{
		type: GET_USER_WORK_AUTHORIZATION_SUCCESS,
		payload
	}
);

export const getUserWorkAuthorizationFailure = () => (
	{
		type: GET_USER_WORK_AUTHORIZATION_FAILURE
	}
);

export const resetWorkAuth = () => (
	{
		type: RESET_WORK_AUTH
	}
);

export const updateDocList = (payload) => (
	{
		type: UPDATE_DOC_LIST,
		payload
	}
);

export const addEmployeeTab = () => (
	{
		type: ADD_EMPLOYEE_TAB
	}
);

export const deleteEmployeeTab = (payload) => (
	{
		type: DELETE_EMPLOYEE_TAB,
		payload
	}
);

export const duplicateEmployeeTab = (payload) => (
	{
		type: DUPLICATE_EMPLOYEE_TAB,
		payload
	}
);

export const addEducationTab = () => (
	{
		type: ADD_EDUCATION_TAB
	}
);

export const deleteEducationTab = (payload) => (
	{
		type: DELETE_EDUCATION_TAB,
		payload
	}
);

export const duplicateEducationTab = (payload) => (
	{
		type: DUPLICATE_EDUCATION_TAB,
		payload
	}
);

const getEmployeeFormConfigRequest = () => (
	{
		type: GET_EMPLOYEE_CONFIG_REQUEST
	}
);

const getEmployeeFormConfigSuccess = (payload) => (
	{
		type: GET_EMPLOYEE_CONFIG_SUCCESS,
		payload
	}
);

const getEmployeeFormConfigFailure = () => (
	{
		type: GET_EMPLOYEE_CONFIG_FAILURE
	}
);

const getEducationFormConfigRequest = () => (
	{
		type: GET_EDUCATION_CONFIG_REQUEST
	}
);

const getEducationFormConfigSuccess = (payload) => (
	{
		type: GET_EDUCATION_CONFIG_SUCCESS,
		payload
	}
);

const getEducationFormConfigFailure = () => (
	{
		type: GET_EDUCATION_CONFIG_FAILURE
	}
);

const postEmployeeInfoRequest = () => (
	{
		type: POST_EMPLOYEE_INFO_REQUEST
	}
);

const postEmployeeInfoSuccess = (payload) => (
	{
		type: POST_EMPLOYEE_INFO_SUCCESS,
		payload
	}
);

const postEmployeeInfoFailure = () => (
	{
		type: POST_EMPLOYEE_INFO_FAILURE
	}
);

const postEducationInfoRequest = () => (
	{
		type: POST_EDUCATION_INFO_REQUEST
	}
);

const postEducationInfoSuccess = (payload) => (
	{
		type: POST_EDUCATION_INFO_SUCCESS,
		payload
	}
);

const postEducationInfoFailure = () => (
	{
		type: POST_EDUCATION_INFO_FAILURE
	}
);

// apiCalls

export const postUserInfo = (info) => async (dispatch) => {
	dispatch(postUserInfoRequest());
	const formInfo = getFormBody(info);
	return new OnboardingServices().postUserInfo(formInfo).then((res) => {
		dispatch(postUserInfoSuccess({ info, id: res.id }));
		return res.id;
	}
	).catch((err) => {
		dispatch(postUserInfoFailure());
		throw err?.response?.data?.message;
	});
};

export const updateUserInfo = (id, info) => async (dispatch) => {
	dispatch(updateUserInfoRequest());
	const formInfo = getFormBody(info);
	return new OnboardingServices().updateUserInfo(id, formInfo).then(() => {
		dispatch(updateUserInfoSuccess(info));
		return true;
	}
	).catch((err) => {
		dispatch(updateUserInfoFailure());
		throw err?.response?.data?.message || 'Error Saving';
	});
};

export const publishUser = (id) => async (dispatch) => {
	dispatch(publishUserRequest());
	return new OnboardingServices().publishUser(id).then(() => {
		dispatch(publishUserSuccess());
		return true;
	}).catch(() => {
		dispatch(publishUserFailure());
		return false;
	});
};

export const getUserDetails = (id, roleId, currentUserId) => async (dispatch) => {
	dispatch(getUserDetailsRequest());
	return new OnboardingServices().getUserDetails(id, roleId, currentUserId).then((res) => {
		dispatch(getUserDetailsSuccess(res));
		return true;
	}).catch(() => {
		dispatch(getUserDetailsFailure());
		return false;
	});
};

export const getUserDocumentsList = (id) => async (dispatch) => {
	dispatch(getUserDocumentsListRequest());
	return new OnboardingServices().getUserDocumentsList(id).then((res) => {
		dispatch(getUserDocumentsListSuccess(res));
		return true;
	}).catch(() => {
		dispatch(getUserDocumentsListFailure());
		return false;
	});
};

export const getUserGender = () => async (dispatch) => {
	dispatch(getUserGenderRequest());
	return new OnboardingServices().getUserGender().then((res) => {
		dispatch(getUserGenderSuccess(res));
		return true;
	}).catch(() => {
		dispatch(getUserGenderFailure());
		return false;
	});
};

export const getUserBloodGroup = () => async (dispatch) => {
	dispatch(getUserBloodGroupRequest());
	return new OnboardingServices().getUserBloodGroup().then((res) => {
		dispatch(getUserBloodGroupSuccess(res));
		return true;
	}).catch(() => {
		dispatch(getUserBloodGroupFailure());
		return false;
	});
};

export const getUserWorkStatus = () => async (dispatch) => {
	dispatch(getUserWorkStatusRequest());
	return new OnboardingServices().getUserWorkStatus().then((res) => {
		dispatch(getUserWorkStatusSuccess(res));
		return true;
	}).catch(() => {
		dispatch(getUserWorkStatusFailure());
		return false;
	});
};

export const getUserDesignation = () => async (dispatch) => {
	dispatch(getUserDesignationRequest());
	return new OnboardingServices().getUserDesignation().then((res) => {
		dispatch(getUserDesignationSuccess(res));
		return true;
	}).catch(() => {
		dispatch(getUserDesignationFailure());
		return false;
	});
};

export const getUserOnboardingMenu = () => async (dispatch) => {
	dispatch(getUserOnboardingMenuRequest());
	return new OnboardingServices().getUserOnboardingMenu().then((res) => {
		dispatch(getUserOnboardingMenuSuccess(res));
	}).catch(() => {
		dispatch(getUserOnboardingMenuFailure());
	});
};

export const updateUserStatus = (info) => async (dispatch) => {
	dispatch(updateUserStatusRequest());
	return new OnboardingServices().updateUserStatus(info).then(() => {
		dispatch(updateUserStatusRequest());
		return true;
	}).catch(() => {
		dispatch(updateUserStatusFailure());
		throw `${ToastMessages.failed.replace(stringSubstitute, 'Update')}`;
	});
};

export const getUserCostCentre = () => async (dispatch) => {
	dispatch(getUserCostCentreRequest());
	return new OnboardingServices().getUserCostCentre().then((res) => {
		dispatch(getUserCostCentreSuccess(res));
		return true;
	}).catch(() => {
		dispatch(getUserCostCentreFailure());
		return false;
	});
};

export const getUserEmploymentTypes = () => async (dispatch) => {
	dispatch(getEmploymentTypesRequest());
	return new OnboardingServices().getUserEmploymentTypes().then((res) => {
		dispatch(getEmploymentTypesSuccess(res));
		return true;
	}).catch(() => {
		dispatch(getEmploymentTypesFailure());
		return false;
	});
};

export const getUserOrgDivisions = () => async (dispatch) => {
	dispatch(getOrgDivisionsRequest());
	return new OnboardingServices().getUserOrgDivisions().then((res) => {
		dispatch(getOrgDivisionsSuccess(res));
		return true;
	}).catch(() => {
		dispatch(getOrgDivisionsFailure());
		return false;
	});
};

export const getUserBusinessUnits = () => async (dispatch) => {
	dispatch(getBusinessUnitsRequest());
	return new OnboardingServices().getUserBusinessUnits().then((res) => {
		dispatch(getBusinessUnitsSuccess(res));
		return true;
	}).catch(() => {
		dispatch(getBusinessUnitsFailure());
		return false;
	});
};

export const getUserNationality = () => async (dispatch) => {
	dispatch(getUserNationalityRequest());
	return new OnboardingServices().getUserNationality().then((res) => {
		dispatch(getUserNationalitySuccess(res));
		return true;
	}).catch(() => {
		dispatch(getUserNationalityFailure());
		return false;
	});
};

export const getUserReligion = () => async (dispatch) => {
	dispatch(getUserReligionRequest());
	return new OnboardingServices().getUserReligion().then((res) => {
		dispatch(getUserReligionSuccess(res));
		return true;
	}).catch(() => {
		dispatch(getUserReligionFailure());
		return false;
	});
};

export const getUserRace = () => async (dispatch) => {
	dispatch(getUserRaceRequest());
	return new OnboardingServices().getUserRace().then((res) => {
		dispatch(getUserRaceSuccess(res));
		return true;
	}).catch(() => {
		dispatch(getUserRaceFailure());
		return false;
	});
};

export const getUserFunction = () => async (dispatch) => {
	dispatch(getUserFunctionRequest());
	return new OnboardingServices().getUserFunction().then((res) => {
		dispatch(getUserFunctionSuccess(res));
		return true;
	}).catch(() => {
		dispatch(getUserFunctionFailure());
		return false;
	});
};

export const getUserCategory = () => async (dispatch) => {
	dispatch(getUserCategoryRequest());
	return new OnboardingServices().getUserCategory().then((res) => {
		dispatch(getUserCategorySuccess(res));
		return true;
	}).catch(() => {
		dispatch(getUserCategoryFailure());
		return false;
	});
};

export const getUserWorkAuthorization = (nationalityId) => async (dispatch) => {
	dispatch(getUserWorkAuthorizationRequest());
	return new OnboardingServices().getUserWorkAuthorization(nationalityId).then((res) => {
		dispatch(getUserWorkAuthorizationSuccess(res));
		return true;
	}).catch(() => {
		dispatch(getUserWorkAuthorizationFailure());
		return false;
	});
};

export const getEmployeeFormConfig = (id) => async (dispatch) => {
	dispatch(getEmployeeFormConfigRequest());
	return new OnboardingServices().getEmployeeFormConfig(id).then((res) => {
		dispatch(getEmployeeFormConfigSuccess({ id, res }));
		return true;
	}).catch((err) => {
		dispatch(getEmployeeFormConfigFailure());
		return false;
	});
};

export const getEducationFormConfig = (id) => async (dispatch) => {
	dispatch(getEducationFormConfigRequest());
	return new OnboardingServices().getEducationFormConfig(id).then((res) => {
		dispatch(getEducationFormConfigSuccess({ id, res }));
		return true;
	}).catch((err) => {
		dispatch(getEducationFormConfigFailure());
		return false;
	});
};

export const postEmployeeInfo = (id, info) => async (dispatch) => {
	dispatch(postEmployeeInfoRequest());
	const empData = info.map((formData) => {
		return getFormBody(formData);
	});
	return new OnboardingServices().postEmployeeInfo({ id, employmentHistory: empData }).then((res) => {
		dispatch(postEmployeeInfoSuccess(info));
		return true;
	}
	).catch((err) => {
		dispatch(postEmployeeInfoFailure());
		throw err?.response?.data?.message;
	});
};

export const postEducationInfo = (id, info) => async (dispatch) => {
	dispatch(postEducationInfoRequest());
	const eduData = info.map((formData) => {
		return getFormBody(formData);
	});
	return new OnboardingServices().postEducationInfo({ id, educationHistory: eduData }).then((res) => {
		dispatch(postEducationInfoSuccess(info));
		return true;
	}
	).catch((err) => {
		dispatch(postEducationInfoFailure());
		throw err?.response?.data?.message;
	});
};


