import { FormFieldTypes } from '../../../constants/common';
import { formConditionsMap } from '../../OnBoarding/common/Form/util/form.util';

export const conditionalActionTypes = [
  { id: 'isHidden', name: 'Show This Field' },
  { id: 'isDisabled', name: 'Enable This Field' },
];

export const conjunctionOptions = [
  { id: 'all', name: 'When All Condtions Are Met' },
  { id: 'any', name: 'When Any Condtion is Met' },
];

export const operatorTypesForDate = [
  {
    id: formConditionsMap.isEqual,
    name: 'Is Equal To',
    fieldType: FormFieldTypes.Date,
  },
  {
    id: formConditionsMap.isNotEqual,
    name: 'Is Not Equal To',
    fieldType: FormFieldTypes.Date,
  },
  {
    id: formConditionsMap.between,
    name: 'Is Between',
    fieldType: FormFieldTypes.DateRange,
  },
  {
    id: formConditionsMap.isAfter,
    name: 'Is After',
    fieldType: FormFieldTypes.Date,
  },
  {
    id: formConditionsMap.isBefore,
    name: 'Is Before',
    fieldType: FormFieldTypes.Date,
  },
  {
    id: formConditionsMap.isAnyOfDays,
    name: 'Is Any Of Days',
    fieldType: FormFieldTypes.MutliSelect,
  },
  {
    id: formConditionsMap.isPublicHoliday,
    name: 'Is Public Holiday ',
    fieldType: FormFieldTypes.Date,
  },
];

export const operatorTypesForTime = [
  { id: formConditionsMap.isEqual, name: 'Is Equal To' },
  { id: formConditionsMap.isNotEqual, name: 'Is Not Equal To' },
  { id: formConditionsMap.isAfter, name: 'Is After' },
  { id: formConditionsMap.isBefore, name: 'Is Before' },
];

export const operatorTypesForDateTime = [
  {
    id: formConditionsMap.isEqual,
    name: 'Is Equal To',
    fieldType: FormFieldTypes.DateTime,
  },
  {
    id: formConditionsMap.isNotEqual,
    name: 'Is Not Equal To',
    fieldType: FormFieldTypes.DateTime,
  },
  {
    id: formConditionsMap.isAfter,
    name: 'Is After',
    fieldType: FormFieldTypes.DateTime,
  },
  {
    id: formConditionsMap.isBefore,
    name: 'Is Before',
    fieldType: FormFieldTypes.DateTime,
  },
  {
    id: formConditionsMap.timeIsAfter,
    name: 'Time Is After',
    fieldType: FormFieldTypes.Time,
  },
  {
    id: formConditionsMap.timeIsBefore,
    name: 'Time Is Before',
    fieldType: FormFieldTypes.Time,
  },
  {
    id: formConditionsMap.dateIs,
    name: 'Date Is',
    fieldType: FormFieldTypes.Date,
  },
  {
    id: formConditionsMap.isNotEmtpy,
    name: 'Is Not Empty',
    fieldType: FormFieldTypes.DateTime,
  },
  {
    id: formConditionsMap.isAnyOfDays,
    name: 'Is Any Of Days',
    fieldType: FormFieldTypes.MutliSelect,
  },
  {
    id: formConditionsMap.isPublicHoliday,
    name: 'Is Public Holiday ',
    fieldType: FormFieldTypes.DateTime,
  },
];

export const operatorTypesForNumber = [
  { id: formConditionsMap.isEqual, name: 'Is Equal To' },
  { id: formConditionsMap.isNotEqual, name: 'Is Not Equal To' },
  { id: formConditionsMap.isGreaterThan, name: 'Is Greater Than' },
  {
    id: formConditionsMap.isGreaterThanAndEqual,
    name: 'Is Greater Than and Equal',
  },
  { id: formConditionsMap.isLessThan, name: 'Is Less Than' },
  { id: formConditionsMap.isLessThanAndEqual, name: 'Is Less Than and Equal' },
];

export const operatorTypes = [
  { id: formConditionsMap.isEqual, name: 'Is Equal To' },
  { id: formConditionsMap.isNotEqual, name: 'Is Not Equal To' },
];

export const conditionInitialVal = {
  component: '',
  value: '',
  operator: '',
};
export const getConditionFormFields = (onChangeFn) => {
  return [
    {
      id: 1,
      fieldLabel: 'When:',
      placeholderText: 'Select',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: false,
      fieldRefName: 'component',
      onChange: onChangeFn[ConditionFormFieldsName.Component],
      size: 'small',
      fullWidth: true,
      fieldSize: 4,
    },
    {
      id: 1,
      fieldLabel: 'Is:',
      placeholderText: 'Select',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: false,
      fieldRefName: 'operator',
      onChange: onChangeFn[ConditionFormFieldsName.Operator],
      size: 'small',
      fullWidth: true,
      fieldSize: 4,
    },
    {
      id: 1,
      fieldLabel: 'Value:',
      placeholderText: 'Type',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'value',
      size: 'small',
      fullWidth: true,
      fieldSize: 4,
    },
  ];
};

export const ConditionFormFieldsName = {
  Component: 'component',
  Operator: 'operator',
  Value: 'value',
};

export const ruleConjunctionOptions = [
  { id: 'all', name: 'ALL' },
  { id: 'any', name: 'ANY' },
];

export const DaysList = [
  {
    id: 0,
    name: 'Sunday',
  },
  {
    id: 1,
    name: 'Monday',
  },
  {
    id: 2,
    name: 'Tuesday',
  },
  {
    id: 3,
    name: 'Wednesday',
  },
  {
    id: 4,
    name: 'Thursday',
  },
  {
    id: 5,
    name: 'Friday',
  },
  {
    id: 6,
    name: 'Saturday',
  },
];
