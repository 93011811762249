import './Success.css';

import {  Grid, Paper, Typography } from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import SuccessIcon from '../../assets/SuccessIcon.svg';
import SuccessList from '../../assets/SuccessList.svg';
import SuccessUserAvatar from '../../assets/SuccessUserAvatar.png';
import ButtonUI from '../../shared/components/ui-elements/ButtonUI';


const Success = () => {

  const [fullName, setFullName] = useState('');
  const [employeeId, setEmployeeId] = useState('');

  const params = useLocation();
  

  let navigate = useNavigate();
  useEffect (() => {
    // const userData = JSON.parse(localStorage.getItem('userData'));
    setEmployeeId(params.state.employeeId);
    setFullName(params.state.employeeName);
  });

  return (
    <Grid container maxWidth='lg' className='success-card-main'>
      <Grid lg={6} className='success-card-wrapper'>
        <Grid className='user-info'>
          <Grid lg={3} className='user-info-avatar'>
            <img src={SuccessUserAvatar} alt='SuccessUserAvatar' />
          </Grid>
          <Grid lg={9} className='user-details'>
            <Grid lg={10}>
              <Typography className='user-name'>
                From:
                <Typography style={{ color: '#282E45', fontWeight: '600',textTransform:'capitalize' }}>
                 {fullName}
                </Typography>
              </Typography>
              <Typography className='user-name'>
                Employee ID:
                <Typography style={{ color: '#282E45', fontWeight: '600' }}>
                  {' '}
                  {employeeId}
                </Typography>
              </Typography>
            </Grid>
            <Grid lg={2}>
              <img src={SuccessIcon} alt='SuccessIcon' />
            </Grid>
          </Grid>
        </Grid>
        <Grid className='user-request-info'>
          <Grid xs={12} sm={6} className='user-request-info-type'>
            <Typography
              variant='h6'
              style={{ color: '#282E45', fontWeight: 'bold', textTransform:'capitalize', textAlign:'center' }}
            >
              {params.state.claimType}
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} className='user-request-info-reference'>
            <Typography style={{ color: '#707070' }}>
              Claim Reference Number: {params.state.claimReferenceNumber} 
            </Typography>
            <Typography
              variant='h6'
              style={{ color: '#282E45', fontWeight: 'bold' }}
            >
              Amount: ${params.state.amount}
            </Typography>
          </Grid>
        </Grid>
        <Grid className='user-request-status'>
          <Paper className='user-status-paper'>
            <img src={SuccessList} alt='SuccessList' />
            <Typography
              variant='h6'
              style={{ color: '#00AC94', marginTop: '10px', textTransform:'uppercase' }}
            >
              Successfully Approved !
            </Typography>
            <ButtonUI
                  variant='contained'
                  backgroundColor='#00AC94'
                  size='small'
                  color='#ffffff'
                  onclick={() => navigate('/claims')}
                  margin='10px 0 0 0'
                >
                  Okay
                </ButtonUI>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Success;
