import { Box } from '@mui/material';
import React from 'react';
import ActionHeader from '../../../ActionHeader/ActionHeader';
import FormView from '../../../../common/Form/FormView';

const CurrencyFormView = (props) => {
  const { setViewMode, formConfig, formData, setShowForm } = props;
  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            labelText={formData?.currency?.name|| 'Currency Conversion'}
            editButtonText="Edit"
            onEditClick={() => setViewMode(false)}
            showSave={true}
            showBackButton
            onBackClick={() => setShowForm(false)}
          />
        </Box>
        <FormView list={formConfig} info={formData} />
      </Box>
    </Box>
  );
};

export default CurrencyFormView;
