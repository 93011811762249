import React from 'react';
import './Sidebar.css';

const Sidebar = (props) => {
  return (
    <div id='sidenav' className='sidenav-wrap' onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export default Sidebar;
