import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalDialog from '../../../../atoms/Modal/ModalDialog';
import {
  FormFieldTypes,
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../../../constants/common';
import { Payroll } from '../../../../services/payroll';
import { getAllPayroll } from '../../../../redux/payroll/action';
import { getFormattedValues } from '../../../../utils/formatter';
import { showToast } from '../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../OnBoarding/Organization/constants/onboarding.constants';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import { PayrollPermissions, PayrollStatus } from '../../Constant/Constant';
import FormEdit from '../../../OnBoarding/common/Form/FormEdit';
import { isEmpty } from 'lodash';
import { getFormBody } from '../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import DropDownActionForGiroFile from './DropDownActionForGiroFile';
import { DownloadAPIResponse } from '../../../../services/downloadAPIService';

const ModalMapForGiroFile = (props) => {
  const { checkedItems } = props;
  const [showModal, setshowModal] = useState(false);
  const [context, setContext] = useState('');
  const [postActionLoading, setPostActionLoading] = useState(-1);
  const { currentUserRoleId } = useSelector((state) => state && state.main);
  const handlePublish = () => {
    setContext('publish');
    setshowModal(true);
  };
  const handlePaid = () => {
    setContext('paid');
    setshowModal(true);
  };
  console.log(checkedItems);
  const handlePublishClick = async () => {
    for (const item of checkedItems) {
      const { bankGiroFileUrl, payMonth } = item;
      if (bankGiroFileUrl) {
        try {
          const response = await fetch(bankGiroFileUrl);
          if (!response.ok) {
            throw new Error(`Failed to download file: ${bankGiroFileUrl}`);
          }
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${payMonth}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        } catch (error) {
          console.log(`Error downloading file: ${payMonth}-`, error);
          return;
        }
      } else {
        showToast('No File To Download', ToastThemes.error);
        return;
      }
    }
    new Payroll().updatePrlPayrollRuns({
      ids: checkedItems.map((item) => item?.id),
    });
  };

  const modalMap = {
    publish: {
      title: 'Download',
      description: 'Are you sure you want to Download?',
      primaryAction: 'Download',
      onClickConfirm: () => handlePublishClick(PayrollStatus.Publish),
      severity: 'success',
      maxWidth: 'md',
      fullWidth: true,
      actionButtonDisabled: false,
    },
  };
  return (
    <>
      <CircularLoader show={postActionLoading === LoadingStatus.Loading} />
      <DropDownActionForGiroFile
        checkedItems={checkedItems}
        handlePublish={handlePublish}
        handlePaid={handlePaid}
      />
      <ModalDialog
        severity={modalMap[context]?.severity}
        title={modalMap[context]?.title}
        listComponent={modalMap[context]?.listComponent}
        description={modalMap[context]?.description}
        primaryAction={modalMap[context]?.primaryAction}
        secondaryAction="Cancel"
        showModal={showModal}
        onClickClose={() => setshowModal(false)}
        onClickConfirm={modalMap[context]?.onClickConfirm}
        maxWidth={modalMap[context]?.maxWidth}
        fullWidth={modalMap[context]?.fullWidth}
        primaryButtonDisabled={modalMap[context]?.actionButtonDisabled}
        lhsActionButton={modalMap[context]?.lhsActionButton}
      />
    </>
  );
};

export default ModalMapForGiroFile;
