import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography, Link, IconButton } from '@mui/material';
import CardView from '../../../atoms/DataList/CardView';
import ListView from '../../../atoms/DataList/ListView';
import { FormHeader } from '../common/formHeader';
import { getDesignation, deleteDesignation, postDesignation } from '../../../redux/onboarding/action';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { designationListHeader, isNotEmptyString } from './utils/onboarding.util';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModalDialog from '../../../atoms/Modal/ModalDialog';
import { getHelperTextOnInputInvalid, scrollToNewRow } from './utils/onboarding.util';
import { APIRequestStatus, LoadingStatus, ToastThemes, toastMessage } from '../../../constants/common';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import DesignationConfigure from './DesignationConfigure';
import { showToast } from '../../../utils/common.util';
import { ToastMessages, stringSubstitute } from './constants/onboarding.constants';
import { designations as designationConst } from './constants/onboarding.constants';
import TryAgain from '../../molecules/ErrorState/error.component';


export default function Designations() {

  const [showConfigure, setShowConfigure] = useState(false);
  const [shortName, setShortName] = useState('');
  const [id, setId] = useState('');
  const [longName, setLongName] = useState('');
  const [gradeLevel, setGradeLevel] = useState('');
  const [configureIndex, setConfigureIndex] = useState(-1);
  const [errors, setErrors] = useState();
  const [showModal, setshowModal] = useState(false);
  const [context, setContext] = useState();
  const [allowAdd, setAllowAdd] = useState(true);
  const [tableRef, setTableRef] = useState();

  const onClickConfigure = (id, shortName, longName, gradeLevel, index) => {
    setShowConfigure(true);
    setId(id);
    setShortName(shortName);
    setLongName(longName);
    setGradeLevel(gradeLevel);
    setConfigureIndex(index);
  };
  const dispatch = useDispatch();


  const { designations, permissions, requestProcessing: { [APIRequestStatus.POST_DESIGNATION]: postLoadingStatus,
    [APIRequestStatus.GET_DESIGNATION]: getLoading
  } } = useSelector((state) => state.onBoarding);
  useEffect(() => {
    if (isEmpty(designations))
      dispatch(getDesignation());
  }, []);

  useEffect(() => {
    if (postLoadingStatus === LoadingStatus.Success) {
      scrollToNewRow(tableRef);
    }
  }, [postLoadingStatus]);

  const handleAddDesignation = () => {
    if (isNotEmptyString(shortName)) {
      dispatch(postDesignation(shortName.trim())).then((success) => {
        if (success) {
          setShortName('');
          showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.success), ToastThemes.success);
        }
        else {
          showToast(ToastMessages.failed.replace(stringSubstitute, toastMessage.error), ToastThemes.error);
        }
      });
    }
    else {
      setAllowAdd(false);
      setErrors(errors);
    }
  };

  const handleDelete = (id, index) => {
    setshowModal(true);
    setContext('delete');
    setId(id);
    setConfigureIndex(index);
  };

  const onClickAddIcon = () => {
    setshowModal(true);
    setContext('add');
  };
  const onChange = (event) => {
    const name = event.target.value;
    if (!designations.find((item) => item.shortName === name) && (isNotEmptyString(name))) {
      setAllowAdd(true);
    }
    else {
      setAllowAdd(false);
    }
    setShortName(event.target.value);
  };

  const onModalClose = () => {
    setshowModal(false);
    setAllowAdd(true);
    setShortName('');
  };

  const renderLayout = () => {
    if (showConfigure) {
      return (
        <Box>
          <DesignationConfigure
            shortName={shortName}
            id={id}
            longName={longName}
            gradeLevel={gradeLevel}
            index={configureIndex}
            viewDesignation={() => setShowConfigure(false)}
            setShortName={setShortName}
          />
        </Box>
      );
    }
    return (
      <Box>
        <FormHeader title='Designations' />
        {designations?.length < 9 ?
          <Grid container rowSpacing={3} columnSpacing={10}>
            {designations?.map((item, index) => {
              const { id, shortName, longName, fkGrade } = item;
              return (
                <Grid key={id} item xs={6} md={4}>
                  <CardView
                    title={shortName}
                    buttonText='Configure'
                    onClick={() => onClickConfigure(id, shortName, longName, fkGrade, index)}
                    count={id}
                    countBox={false}
                    onClickDelete={() => handleDelete(id, index)}
                    primaryButtonDisabled={!permissions[designationConst]?.allowEdit}
                    deleteButtonDisabled={!permissions[designationConst]?.allowEdit}
                  />
                </Grid>
              );
            })}
            {permissions[designationConst]?.allowAdd && (
              <Grid item xs={6} md={4}>
                <CardView
                  buttonText='Add Designation'
                  onClick={() => handleAddDesignation()}
                  count={0}
                  editable={true}
                  onChange={onChange}
                  value={shortName}
                  placeholder="Add Short Name"
                  helperText={getHelperTextOnInputInvalid(allowAdd)}
                  primaryButtonDisabled={!allowAdd}
                  countBox={false}
                />
              </Grid>
            )}
          </Grid>
          : <ListView
            headCells={designationListHeader}
            rowsData={designationList}
            onAddClick={() => onClickAddIcon()}
            showSerialNo={true}
            setTableRef={setTableRef}
            showAddIcon={permissions[designationConst]?.allowAdd}
            addDisabled={!permissions[designationConst]?.allowAdd}
          />
        }
      </Box>
    );
  };
  const designationList = designations?.map((item, index) => {
    const { id, shortName, longName, fkGrade } = item;
    if (!permissions[designationConst]?.allowEdit) {
      return {
        shortName: shortName || '',
        longName: longName || '',
        gradeLevel: fkGrade?.name || '',
      };
    }
    return {
      shortName: shortName || '',
      longName: longName || '',
      gradeLevel: fkGrade?.name || '',
      actions: <>
        <IconButton onClick={() => onClickConfigure(id, shortName, longName, fkGrade, index)}
          disabled={!permissions[designationConst]?.allowEdit}>
          <EditOutlinedIcon />
        </IconButton>
        <IconButton onClick={() => handleDelete(id, index)}
          disabled={!permissions[designationConst]?.allowEdit}>
          <DeleteOutlineOutlinedIcon /></IconButton>
      </>
    };
  });

  const modalMap = {
    delete: {
      title: 'Delete Designation',
      description: 'Are you sure you want to Delete Designation?',
      primaryAction: 'Delete',
      onClickConfirm: () => dispatch(deleteDesignation(id, configureIndex)),
      severity: 'error'
    },
    add: {
      title: 'Add Designation',
      componentProps: {
        onInputChange: onChange,
        inputValue: shortName,
        inputPlaceHolder: 'Add Designation',
        helperText: getHelperTextOnInputInvalid(allowAdd),
        countBox: false
      },
      primaryAction: 'Add Designation',
      onClickConfirm: () => handleAddDesignation(),
      severity: 'success'
    }
  };
  if (getLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  } else {
    return (
      <Box>
        <CircularLoader show={getLoading === LoadingStatus.Loading} />
        {renderLayout()}
        <ModalDialog
          severity={modalMap[context]?.severity}
          title={modalMap[context]?.title}
          description={modalMap[context]?.description}
          primaryAction={modalMap[context]?.primaryAction}
          secondaryAction="Cancel"
          showModal={showModal}
          onClickClose={() => onModalClose()}
          onClickConfirm={modalMap[context]?.onClickConfirm}
          primaryButtonDisabled={!allowAdd}
          component={context !== 'delete'}
          componentProps={modalMap[context]?.componentProps}
        />
      </Box>
    );
  }
}

