export const GET_SALARY_STRUCTURE_CONFIG_REQUEST = 'GET_SALARY_STRUCTURE_CONFIG_REQUEST';
export const GET_SALARY_STRUCTURE_CONFIG_SUCCESS = 'GET_SALARY_STRUCTURE_CONFIG_SUCCESS';
export const GET_SALARY_STRUCTURE_CONFIG_FAILURE = 'GET_SALARY_STRUCTURE_CONFIG_FAILURE';

export const POST_SALARY_STRUCTURE_INFO_REQUEST = 'POST_SALARY_STRUCTURE_INFO_REQUEST';
export const POST_SALARY_STRUCTURE_INFO_SUCCESS = 'POST_SALARY_STRUCTURE_INFO_SUCCESS';
export const POST_SALARY_STRUCTURE_INFO_FAILURE = 'POST_SALARY_STRUCTURE_INFO_FAILURE';

export const GET_ALL_SALARY_STRUCTURE_LIST_REQUEST = 'GET_ALL_SALARY_STRUCTURE_LIST_REQUEST';
export const GET_ALL_SALARY_STRUCTURE_LIST_SUCCESS = 'GET_ALL_SALARY_STRUCTURE_LIST_SUCCESS';
export const GET_ALL_SALARY_STRUCTURE_LIST_FAILURE = 'GET_ALL_SALARY_STRUCTURE_LIST_FAILURE';

export const RESET_SALARY_STRUCTURE = 'RESET_SALARY_STRUCTURE';
