import { FormFieldTypes } from '../../../constants/common';

export const approvalConditionaFields = {
  stepNumber: 'step_number',
  access: 'access',
  reportingLevels:'reporting_level'
};

export const approvalAccessTypes = {
  EDIT_VIEW: 1,
  VIEW: 2,
  HIDDEN: 3,
};
export const approvalAccessTypesDropDown = [
  { id: 1, name: 'Edit & View' },
  { id: 2, name: 'View' },
  { id: 3, name: 'Hidden' },
];

export const fileSizes = {
  six:6,
  three:3
};
export const getApprovalConditionsFormFields = [
  {
    id: 1,
    fieldLabel: 'Step Number',
    placeholderText: 'Select',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: false,
    fieldRefName: 'step_number',
    size: 'small',
    fullWidth: true,
    fieldSize: 6,
  },
  {
    id: 1,
    fieldLabel: 'Access',
    placeholderText: 'Select',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: false,
    fieldRefName: 'access',
    size: 'small',
    fullWidth: true,
    fieldSize: 6,
  },
  {
    id: 1,
    fieldLabel: 'Reporting Levels',
    placeholderText: 'Select',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: false,
    isHidden:true,
    fieldRefName: 'reporting_level',
    size: 'small',
    fullWidth: true,
    fieldSize: 3,
  },
];

export const approvalConditionInitialVal = {
  step_number: '',
  access: '',
  reporting_level:''
};
