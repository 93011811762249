import { BuilderServices } from '../../services/builder';
import { NewClaimsService } from '../../services/newClaims';
import {
  GET_ALL_CLAIM_LIST_FAILURE,
  GET_ALL_CLAIM_LIST_REQUEST,
  GET_ALL_CLAIM_LIST_SUCCESS,
  GET_ALL_CLAIM_TAB_FAILURE,
  GET_ALL_CLAIM_TAB_REQUEST,
  GET_ALL_CLAIM_TAB_SUCCESS,
  RESET_CLAIMS,
  RESET_UNERGIZER_ERRORS,
  SET_UNERGIZER_ERROR,
} from './actionTypes';

const getClaimTabRequest = () => ({
  type: GET_ALL_CLAIM_TAB_REQUEST,
});

const getClaimTabSuccess = (payload) => ({
  type: GET_ALL_CLAIM_TAB_SUCCESS,
  payload,
});

const getClaimTabFailure = () => ({
  type: GET_ALL_CLAIM_TAB_FAILURE,
});

const getClaimListRequest = () => ({
  type: GET_ALL_CLAIM_LIST_REQUEST,
});

const getClaimListSuccess = (payload) => ({
  type: GET_ALL_CLAIM_LIST_SUCCESS,
  payload,
});

const getClaimListFailure = () => ({
  type: GET_ALL_CLAIM_LIST_FAILURE,
});

export const resetNewClaims = () => ({
  type: RESET_CLAIMS,
});

export const setUnergizerErros = (payload) => ({
  type: SET_UNERGIZER_ERROR,
  payload
});

export const resetUnergizerErros = () => ({
  type: RESET_UNERGIZER_ERRORS
});

export const getClaimTab = (roleId) => async (dispatch) => {
  dispatch(getClaimTabRequest());
  return new NewClaimsService()
    .getCliamType(roleId)
    .then((res) => {
      dispatch(getClaimTabSuccess(res));
      return true;
    })
    .catch((err) => {
      dispatch(getClaimTabFailure());
      throw err?.response?.data?.message || 'ERROR';
    });
};

export const getClaimList =
  (id, roleId, searchParam, startDate, endDate) => async (dispatch) => {
    dispatch(getClaimListRequest());
    return new BuilderServices()
      .getAllClaimsList(id, roleId, searchParam, startDate, endDate)
      .then((res) => {
        dispatch(getClaimListSuccess(res));
        return true;
      })
      .catch((err) => {
        dispatch(getClaimListFailure());
        throw err?.response?.data?.message || 'Access Denied';
      });
  };
