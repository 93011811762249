import React from 'react';
import FieldView from '../../../../molecules/FieldView/FieldView';
import { Box } from '@mui/material';
import CompetencyAppraisalFormEdit from './Rating/CompetencyAppraisalFormEdit';
import CompetencyAppraisalFormView from './Rating/CompetencyAppraisalFormView';

const CompetencyItem = (props) => {
    const { heading, weightageValue, ratingDropdown, setData = () => { },
        itemIndex, isAppraisal, viewMode,
        setCompetencyError, competencyError, rating, remarks,appraiserRemarksCompetency,setAppraiserRemarksCompetency,competencyId,managerAcess } = props;

    const renderCompetencyAppraisalForm = () => {
        return (
            !viewMode ?
                <CompetencyAppraisalFormEdit
                    dropDownList={ratingDropdown}
                    setData={setData}
                    itemIndex={itemIndex}
                    rating={rating}
                    weightageValue={weightageValue}
                    isAppraisal={isAppraisal}
                    remarks={remarks}
                    competencyError={competencyError}
                    setCompetencyError={setCompetencyError}
                    appraiserRemarksCompetency={appraiserRemarksCompetency}
                    setAppraiserRemarksCompetency={setAppraiserRemarksCompetency}
                    competencyId={competencyId}
                    managerAcess={managerAcess}
                /> : <CompetencyAppraisalFormView
                    itemIndex={itemIndex}
                    rating={rating}
                    weightageValue={weightageValue}
                    remarks={remarks}
                />
        );
    };

    return (
        <>
            <Box mb={2}>
                <FieldView
                    labelText={heading}
                />
            </Box>
            {isAppraisal ? renderCompetencyAppraisalForm() :
                <FieldView
                    labelText={'Weightage'}
                    labelValue={weightageValue}
                />
            }
        </>
    );
};

export default React.memo(CompetencyItem);