import { SET_CURRENT_USER_INFORMATION, GET_REIMBURSEMENT_DATA, SET_CLAIM_DATA } from '../../actionTypes';

/* Name: getReimbursementData
Description: Gets the list of all available reimbursement data.
Author: Sagar */



export const getReimbursementData = (data) => {

  return {
    type: GET_REIMBURSEMENT_DATA,
    payload: data,
  };
};

export const setCurrentUser = (data) => {

  return {
    type: SET_CURRENT_USER_INFORMATION,
    payload: data,
  };
};


export const setClaimData = (data,wholeObject,refNumber) => {
let finalData = {
  singleData: data,
  wholeData: wholeObject,
  refNumber: refNumber 
};
  return {
    type: SET_CLAIM_DATA,
    payload: finalData,
  };
};
