import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const EmpDataCard = (props) => {
    const {name, email, url} = props; 
    return (
      <Box display='flex' py={2}>
        <Box mr={2}>
          <Avatar
            alt='Remy Sharp'
            src={url}
            sx={{ width: 48, height: 48 }}
          >
           {(url)?'':<PersonIcon />}
          </Avatar>
        </Box>
        <Box>
          <Typography variant='body1' color={'neutral.dark100'} mb={0}>
            {name}
          </Typography>
          <Typography variant='body3' color={'neutral.dark80'}>
            {email}
          </Typography>
        </Box>
      </Box>
    );
  };
export default EmpDataCard;
