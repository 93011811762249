
import { getFormBody } from '../../components/OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { InterviewServices } from '../../services/interviewServices';
import { getUtcToLocal } from '../../utils/formatter';
import { GET_INTERVIEW_CONFIG_FAILURE, GET_INTERVIEW_CONFIG_REQUEST, GET_INTERVIEW_CONFIG_SUCCESS, GET_INTERVIEW_FEEDBACK_CONFIG_FAILURE, GET_INTERVIEW_FEEDBACK_CONFIG_REQUEST, GET_INTERVIEW_FEEDBACK_CONFIG_SUCCESS, POST_INTERVIEW_INFO_FAILURE, POST_INTERVIEW_INFO_REQUEST, POST_INTERVIEW_INFO_SUCCESS } from './actionTypes';


const getInterviewFormConfigRequest = () => (
  {
    type: GET_INTERVIEW_CONFIG_REQUEST
  }
);

const getInterviewFormConfigSuccess = (payload) => (
  {
    type: GET_INTERVIEW_CONFIG_SUCCESS,
    payload
  }
);

const getInterviewFormConfigFailure = () => (
  {
    type: GET_INTERVIEW_CONFIG_FAILURE
  }
);

const postInterviewInfoRequest = () => (
  {
    type: POST_INTERVIEW_INFO_REQUEST
  }
);

const postInterviewInfoSuccess = (payload) => (
  {
    type: POST_INTERVIEW_INFO_SUCCESS,
    payload
  }
);

const postInterviewInfoFailure = () => (
  {
    type: POST_INTERVIEW_INFO_FAILURE
  }
);

const getInterviewFeedbackFormRequest = () => (
  {
    type: GET_INTERVIEW_FEEDBACK_CONFIG_REQUEST
  }
);

const getInterviewFeedbackFormSuccess = (payload) => (
  {
    type: GET_INTERVIEW_FEEDBACK_CONFIG_SUCCESS,
    payload
  }
);

const getInterviewFeedbackFormFailure = () => (
  {
    type: GET_INTERVIEW_FEEDBACK_CONFIG_FAILURE
  }
);

export const getInterviewConfig = (currentUserRoleId, candidateJobId) => async (dispatch) => {
  dispatch(getInterviewFormConfigRequest());
  return new InterviewServices().getInterviewDetails(currentUserRoleId, candidateJobId).then((res) => {
    dispatch(getInterviewFormConfigSuccess(res));
    return true;
  }).catch(() => {
    dispatch(getInterviewFormConfigFailure());
    return false;
  });
};

export const postInterviewInfo = (candidateJobId, interviewFormInfo) => async (dispatch) => {
  dispatch(postInterviewInfoRequest());
  const interviewFormData = getFormBody(interviewFormInfo);
  const {candidateCode, name, statusCode, ...rest} = interviewFormData;
  const interviewDate = getUtcToLocal(rest.interviewTimeUtc, 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD hh:mm a');
  return new InterviewServices().postInterviewInfo({fkCandidateJobMapping:candidateJobId,interviewDate, ...rest}).then(() => {
    dispatch(postInterviewInfoSuccess(interviewFormInfo));
    return true;
  }).catch((err) => {
    dispatch(postInterviewInfoFailure());
    throw err?.response?.data?.message || 'Error Saving';
  });
};

export const getInterviewFeedbackConfig = (currentUserRoleId, interviewId) => async (dispatch) => {
  dispatch(getInterviewFeedbackFormRequest());
  return new InterviewServices().getInterviewFeedbackDetails(currentUserRoleId, interviewId).then((res) => {
    dispatch(getInterviewFeedbackFormSuccess(res));
    return true;
  }).catch(() => {
    dispatch(getInterviewFeedbackFormFailure());
    return false;
  });
};

