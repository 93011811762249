import React from 'react';
import SuccessUserAvatar from '../../../assets/SuccessUserAvatar.png';
import { Grid, Typography, Container } from '@mui/material';
// import CardUI from '../ui-elements/CardUI';
import './UserDetails.css';

const UserDetails = (props) => {
  return (
    <Container maxWidth="xl" className="user-details-wrap-for-view">
      <Grid lg={9} md={8} sm={12} xs={12} className="success-card-wrapper-2">
        <Grid xs={12} style={{ marginBottom: '1em', padding: '15px 0' }}>
          {/* <CardUI boxShadow='none !important'> */}
          <Grid container className="inner-grid">
            {/* <Grid lg={1}></Grid> */}
            <Grid className="user-info-avatar">
              <img
                src={SuccessUserAvatar}
                alt="SuccessUserAvatar"
                style={{ padding: '0.5em' }}
              />
            </Grid>
            <Grid lg={6} md={8} sm={12} className="user-details">
              <Grid lg={12}>
                <Typography className="user-name">
                  From : &nbsp;
                  <Typography className="user-value">
                    {props.fullName}
                  </Typography>
                </Typography>
                <Typography className="user-name">
                  Employee ID :&nbsp;
                  <Typography className="user-value">
                    {props.employeeId}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={12}>
            <hr className="std-hr" />
          </Grid>
          <Grid
            continer
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
            lg={12}
          >
            <Grid lg={2} md={4} sm={6} xs={12}>
              <p className="sub-details">CLAIM TYPE</p>
              <p className="sub-details-value claim-type-wrap user-value">
                {props.claimType}
              </p>
            </Grid>
            <Grid lg={2} md={4} sm={6} xs={12}>
              <p className="sub-details">STATUS</p>
              <p className="sub-details-value user-value">{props.status}</p>
            </Grid>
          </Grid>
          {/* </CardUI> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserDetails;
