import React, { useEffect, useRef, useState } from 'react';
import { Box, FormHelperText, Grid, IconButton, Typography, styled } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { generateFormData } from '../../components/forms/common-services/functions';
import { postFilesData } from '../../components/forms/common-services/services';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const rawFn = () => { };
export default function FilesUploader({ label, refNumber, required = false, callback = rawFn, onUploadingStart = rawFn, onUploadEnd = rawFn, startUploading = false, numberOfFiles = null, value = [], error = false, helperText = '', inputStyle = {}, ...rest }) {
  const [files, setFiles] = useState([]);
  const [uploadingCompleted, setUploadingCompleted] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const fileSelector = useRef();
  // upload
  const selectFiles = () => {
    fileSelector.current.click();
  };

  useEffect(() => {
    setUploadedFiles([...value]);
  }, [value]);
  const onFileChange = async (e, typeId) => {
    // check size for mapped array start
    setUploading(true);
    if (e.target.files.length > 2) {
      alert('Only Two Attachements Are Allowed');
      setUploading(false);
      return;
    }

    if (uploadedFiles && uploadedFiles.length) {
      const exceedMessage = `You have exceeded the number of files upload. Limit: ${numberOfFiles}`;
      if (numberOfFiles && (uploadedFiles.length === numberOfFiles)) {
        alert(exceedMessage);
        setUploading(false);
        return false;
      }
      if (numberOfFiles && (uploadedFiles.length + e.target.files.length >= numberOfFiles)) {
        alert(exceedMessage);
        setUploading(false);
        return false;
      }
    }
    // check size for mapped array end

    // Generates form data for file upload.
    let formData = await generateFormData(e, refNumber, typeId);

    // Send form data for file upload.
    if (formData) {
      let updatedPaths = await postFilesData(formData).catch(() => setUploading(false));
      const temp = [...uploadedFiles, ...updatedPaths];
      setUploadedFiles(() => temp);
      callback(temp);
    }
    setUploading(false);
  };

  const removeAttachement = (url) => {
    const newUploadeFiles = uploadedFiles.filter(file => file !== url);
    setUploadedFiles(newUploadeFiles);
    callback(newUploadeFiles);
  };

  return (
    <>
      <Grid item xs={12}>
        {label && <Typography variant="body2" color={'neutral.dark80'} mb={1}>{label} {required && <Box color="accentSecondary.main" sx={{ display: 'inline-block' }}>*</Box>}</Typography>}
        <UploadBox>
          <button className={error && 'error-input'} style={inputStyle} onClick={selectFiles}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2" color={'neutral.dark40'}>{label}</Typography>
              <FileUploadOutlinedIcon sx={{ height: '18px', mt: '3px' }} />
            </Box>
          </button>
          <input type="file" multiple="multiple" ref={fileSelector} disabled={uploading} onChange={onFileChange} />
        </UploadBox>
      </Grid>
      {error && (<Grid xs={12}>
        <FormHelperText className='text-danger' >{helperText}</FormHelperText>
      </Grid>)}
      <Grid xs={12} >
        {uploadedFiles.length > 0 &&
          [...uploadedFiles].map((url, key) => {
            return (
              <div
                key={key}
                className="file-path-wrap"
              >
                <h6>File_{key + 1}</h6>
                <IconButton
                  onClick={() =>
                    removeAttachement(
                      url
                    )
                  }
                >
                  <CancelRoundedIcon />
                </IconButton>
              </div>
            );
          })}
      </Grid>

    </>
  );
}

const UploadBox = styled(Box)({
  position: 'relative',
  overflow: 'hidden',

  '& input[type=file]': {
    position: 'absolute',
    left: '0',
    top: '0',
    right: '0',
    bottom: '0',
    width: '100%',
    opacity: '0',
    cursor: 'pointer',
  },
  '& button': {
    border: '1px solid #95A09E',
    borderRadius: '4px',
    background: 'none',
    padding: '8px 12px',
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer',
  },
});
