import { permissionsObj } from '../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import { AllJob, JobRequisition, JobStatusCode } from '../Constant/constant';

export const getJobsTabs = [
  {
    name: 'job-requisition',
    label: 'Job Requisition',
    title: 'Job Requisition',
    show: true,
  },
  {
    name: 'candidate-details',
    label: 'Candidate Details',
    title: 'Candidate Details',
    show: true,
  },
  // {
  //   name: 'offer-approval',
  //   label: 'Offer Approval',
  //   title: 'Offer Approval',
  //   show: true
  // }
];

export const jobHeadCells = (jName, handleClearParams) => {
  return [
    {
      id: 'code',
      numeric: false,
      disablePadding: true,
      label: 'Job Code',
    },
    {
      id: 'title',
      numeric: false,
      disablePadding: false,
      label: 'Job Title',
      showFilterIcon: jName ? true : false,
      clear: (event) => handleClearParams(event),
    },
    {
      id: 'location',
      numeric: false,
      disablePadding: false,
      label: 'Location',
    },
    {
      id: 'openings',
      numeric: false,
      disablePadding: false,
      label: 'Openings',
    },
    {
      id: 'applicant',
      numeric: false,
      disablePadding: false,
      label: 'Applicants',
    },
    {
      id: 'recruiter',
      numeric: false,
      disablePadding: false,
      label: 'Recruiter',
    },
    {
      id: 'hm',
      numeric: false,
      disablePadding: false,
      label: 'Hiring Manager',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'createdOn',
      numeric: false,
      disablePadding: false,
      label: 'Created On',
    },
  ];
};

export const candidateHeadCells = (
  jobId,
  handleClearParams,
  candidateName,
  handleNameClear
) => {
  return [
    {
      id: 'canidateId',
      numeric: false,
      disablePadding: true,
      label: 'Candidate Code',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Candidate Name',
      showFilterIcon: candidateName ? true : false,
      clear: (event) => handleNameClear(event),
    },
    {
      id: 'rName',
      numeric: false,
      disablePadding: false,
      label: 'Recruiter Name',
    },
    {
      id: 'position',
      numeric: false,
      disablePadding: false,
      label: 'Position',
      showFilterIcon: jobId ? true : false,
      clear: (event) => handleClearParams(event),
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
      hide: !!jobId,
    },
    {
      id: 'contact',
      numeric: false,
      disablePadding: false,
      label: 'Contact',
      hide: !!jobId,
    },
    {
      id: 'interviewer',
      numeric: false,
      disablePadding: false,
      label: 'Interviewer',
      hide: !jobId,
    },
    {
      id: 'interviewDate',
      numeric: false,
      disablePadding: false,
      label: 'Interview Date',
      hide: !jobId,
    },
    {
      id: 'interviewStatus',
      numeric: false,
      disablePadding: false,
      label: 'Interview Status',
      hide: !jobId,
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      hide: !!jobId,
    },
    {
      id: 'createdOn',
      numeric: false,
      disablePadding: false,
      label: 'Added On',
    },
    {
      id: 'download',
      numeric: false,
      disablePadding: true,
      label: 'CV/Resume',
    },
  ];
};

export const getEditOnStatus = (recrutingPermissions, statusCode, isEdit) => {
  if (
    recrutingPermissions?.[JobRequisition]?.[permissionsObj.allowEdit] ||
    recrutingPermissions?.[AllJob]?.[permissionsObj.allowEdit]
  ) {
    if (isEdit) return true;
    switch (statusCode) {
      case JobStatusCode.Draft:
      case JobStatusCode.Save:
      case JobStatusCode.SendBack: {
        return true;
      }
      default: {
        return false;
      }
    }
  }
  return false;
};
