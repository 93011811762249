import React, { useEffect } from 'react';
import PayStrEditForm from './PayStrFormEdit/PayStrEditForm';
import PayStrViewForm from './PayStrFormEdit/PayStrViewForm';
import { APIRequestStatus, LoadingStatus } from '../../../../../constants/common';
import { useDispatch, useSelector } from 'react-redux';
import TryAgain from '../../../../molecules/ErrorState/error.component';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import { getSalaryStructureConfig, resetSalaryStructure } from '../../../../../redux/salaryStructure/action';

const AddPayStr = (props) => {
  const { viewMode, setViewMode, strucutreId, setShowForm } = props;
  const { currentUserRoleId } = useSelector((state) => state.main);

  const dispatch = useDispatch();
  const { salaryStructureConfig, salaryStructureFormData,
    requestProcessing: { [APIRequestStatus.GET_SALARY_STRUCTURE_CONFIG]: configLoadingStatus }
  } = useSelector((state) => state.salaryStructure);

  useEffect(() => {
    if (currentUserRoleId) {
      if (strucutreId) {
        dispatch(getSalaryStructureConfig(currentUserRoleId, strucutreId));
        setViewMode(true);
      } else {
        dispatch(getSalaryStructureConfig(currentUserRoleId));
      }
    }
    return (() => {
      setViewMode(false);
      dispatch(resetSalaryStructure());
    });
  }, [strucutreId, currentUserRoleId]);

  if (configLoadingStatus === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={configLoadingStatus === LoadingStatus.Loading} />
      {
        configLoadingStatus === LoadingStatus.Success &&
          viewMode ?
          <PayStrViewForm formConfig={salaryStructureConfig} setViewMode={setViewMode}
            salaryStructureFormData={salaryStructureFormData} setShowForm={setShowForm} /> :
          <PayStrEditForm formConfig={salaryStructureConfig} setViewMode={setViewMode} isNew={!strucutreId}
            salaryStructureFormData={salaryStructureFormData} setShowForm={setShowForm} />
      }
    </>
  );
};

export default AddPayStr;