import React, { useEffect, useState } from 'react';
import { Box, Typography, styled, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SearchBar from '../../atoms/SearchBar/SearchBar';
import { getJobsTabs } from './utils/recruitingUtils';
import { getAllowedTabs } from './utils/recruiting.permission.util';
import { recruitingTabs } from './utils/tabs';

export default function SubHeader() {
  const [value, setValue] = useState([]);
  const navigate = useNavigate();
  const { recrutingPermissions } = useSelector((state) => state.modulePermissions);


  const handleChange = (event) => {
    navigate(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex', gap: 7 }}>
      <FormControl variant='standard' sx={{ width: 150, border: 0, mt: 1 }}>
        <StyledSelect
          displayEmpty
          value={value}
          onChange={handleChange}
          renderValue={(selected) => {
            if (selected) {
              return 'Recruiting';
            }
          }}
        >
         {getAllowedTabs(recrutingPermissions, recruitingTabs).map((item)=> {
            const {name, label, path} = item ;
            return(
              <MenuItem key={name} value={path}>{label}</MenuItem>
            );
          })    } 
        </StyledSelect>
      </FormControl>

      <SearchBar />
    </Box>
  );
}

const StyledSelect = styled(Select)`
  height: 36px;
  &.MuiInput-underline:before,
  &.MuiInput-underline:after {
    display: none;
  }
`;
