export const GET_ALL_APPROVAL_WORKFLOW_LIST_REQUEST = 'GET_ALL_APPROVAL_WORKFLOW_LIST_REQUEST';
export const GET_ALL_APPROVAL_WORKFLOW_LIST_SUCCESS = 'GET_ALL_APPROVAL_WORKFLOW_LIST_SUCCESS';
export const GET_ALL_APPROVAL_WORKFLOW_LIST_FAILURE = 'GET_ALL_APPROVAL_WORKFLOW_LIST_FAILURE';

export const GET_APPROVAL_WORKFLOW_CONFIG_REQUEST = 'GET_APPROVAL_WORKFLOW_CONFIG_REQUEST';
export const GET_APPROVAL_WORKFLOW_CONFIG_SUCCESS = 'GET_APPROVAL_WORKFLOW_CONFIG_SUCCESS';
export const GET_APPROVAL_WORKFLOW_CONFIG_FAILURE = 'GET_APPROVAL_WORKFLOW_CONFIG_FAILURE';

export const RESET_APPROVAL_WORKFLOW_STRUCTURE = 'RESET_APPROVAL_WORKFLOW_STRUCTURE';

export const RESET_APPROVAL_WORKFLOW_DATA = 'RESET_APPROVAL_WORKFLOW_DATA';

export const POST_APPROVAL_WORKFLOW_INFO_REQUEST = 'POST_APPROVAL_WORKFLOW_INFO_REQUEST';
export const POST_APPROVAL_WORKFLOW_INFO_SUCCESS = 'POST_APPROVAL_WORKFLOW_INFO_SUCCESS';
export const POST_APPROVAL_WORKFLOW_INFO_FAILURE = 'POST_APPROVAL_WORKFLOW_INFO_FAILURE';

export const ADD_STEPS_TAB = 'ADD_STEPS_TAB';
export const DELETE_STEPS_TAB = 'DELETE_STEPS_TAB';
export const DUPLICATE_STEPS_TAB = 'DUPLICATE_STEPS_TAB';
export const UPDATE_STEPS_TAB = 'UPDATE_STEPS_TAB';