import React, { useState } from 'react';
import { PaymentDate } from '../../shared/components/claim-forms';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Box, Button, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DateField from '../../atoms/DateField/DateField';
import BtnDropDown from '../../atoms/Buttons/BtnDropDown';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import SwipeLeftOutlinedIcon from '@mui/icons-material/SwipeLeftOutlined';
export default function ClaimsActionHeader({ buttonOptions }) {
  console.log(buttonOptions);
  const [payDate, setPayDate] = useState();
  const [enableActionOnForm, setEnableActionOnForm] = useState(true);
  const [openDatePicker, setOpenDatePicker] = useState(true);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [firstChange, setFirstChange] = useState('');

  const handleDownloadFiles = () => { };
  const handleApproveRequest = () => { };
  const openBackdropHandler = () => { };
  const resetSelectedDate = () => { };

  const typeOfChange = { select: 'SELECT', date: 'DATE' };

  const filter = (text = '') => {
    let data = [];
    // if(firstChange === typeOfChange.select){
    //   // if text is the first change.
    //   if(dateRange.start && dateRange.end) {
    //     // if date is selected but firstChange is text; 
    //     data = filterOnBaseOfText(getClaimsInDateRange(),text);
    //   }else{
    //     // if date is not selected and want to filter on the base of text;
    //     data = filterOnBaseOfText(copyFinalData,text);
    //   }
    // }else{
    //   // if date is the first change
    //   if(text){
    //     // if date is selected and want to search by text;
    //     data = filterOnBaseOfText(getClaimsInDateRange(),text);
    //   }else{
    //     // only filter by date range
    //     data = getClaimsInDateRange();
    //   }
    // }
    // setFinalData(data);
    // if date is selected but not want of search on the base of text;
  };
  const handleDateRangeSelect = (value, position) => {
    // if(!searchValue) {
    //   setFirstChange(()=>typeOfChange.date);
    // }
    setDateRange(() => ({ ...dateRange, [position]: value }));

    filter();
  };


  return (
    <Box mb={3}
      display={'flex'}
      justifyContent={'flex-end'}>
      <BtnDropDown
        BtnOptions={buttonOptions}
        selectedOption={0} />
    </Box>);
}
