import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Grid, styled, Button } from '@mui/material';
import FieldView from '../molecules/FieldView/FieldView';
import EventCard from '../molecules/DataCards/EventCard';
import ViewFieldsBox from '../molecules/DataCards/ViewFieldsBox';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ClaimServices } from '../../services/claims';
import moment from 'moment';
import ClaimedEmployeeDetails from './claimed-employee-details';
import ClaimAuditLog, { CLAIM_STATUS } from './claim-audit-log';
import { useSelector } from 'react-redux';
import { ROUTES } from '../../constants/routes'; 
import ReasonIssueList from '../../constants/Reasons';
import ClaimRejectModal from '../claim-reject/claimRejectModal';
import Loading from '../../atoms/Loading/loading';
import ModalDialog from '../../atoms/Modal/ModalDialog';
import SubmitClaim from './submit-claim';
import ApprovalWorkflowAuditLog from '../OnBoarding/Organization/ApprovalFlow/ApprovalWorkflowUtilsCommon/ApprovalWorkflowAuditTrail';
import ApprovalWorkflowFooter from '../OnBoarding/Organization/ApprovalFlow/ApprovalWorkflowUtilsCommon/ApprovalWorkFlowFooter';
import { FORM_STATE } from '../claims/constants/claimsConstants';
import { approvalWorkflowServices } from '../../services/approvalWokflowService';
import { showToast } from '../../utils/common.util';
import { ToastMessages, stringSubstitute } from '../OnBoarding/Organization/constants/onboarding.constants';
import { ToastThemes, toastMessage } from '../../constants/common';


export default function ClaimView() {
    const claimService = new ClaimServices();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const claimTypeId = searchParams.get('ctId');
    const {currentUserRoleId} = useSelector((state) => state.main);
    const {claimId} = useParams();
    const [claimDetails, setClaimDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [claimDetailsView, setClaimDetailsView] = useState([]);
    const [rejectModalOpen, setRejectModalOpen] = useState(false);
    const { appPermissions, currentUserRoleId:currentRole } = useSelector((state) => state.main);
    const userId = useSelector((state) => state?.main?.userInfo?.id);
    const isEmployee = (appPermissions && appPermissions?.['raise-claims']?.allowEdit ? true: false);
    const isApprover = (appPermissions && appPermissions['approve-claims']? true: false);
    const [paymentDate, setPaymentDate] = useState(claimDetails.paymentDate || new Date());
    const [formId, setFormId] = useState(null);
    // Success Model 
    const [openModel, setOpenModel] = useState(false);
    const [deleteModalOpen,setDeleteModalOpen] = useState(false);
    const [modelTitle, setModelTitle] = useState('');
    const [modelDescription, setModelDescription] = useState('');

    // Get Claims Details Function
    const getClaimsDetails = async ()=>{
      setLoading(true);
        try {
            if(!isNaN(+claimId)){
                const response = await claimService.getClaimDetails(claimId,claimTypeId,currentUserRoleId,FORM_STATE.VIEW);
                const claimData = response.data?.data;
                if(claimData){
                    setClaimDetails(claimData);
                    if(response.data.data.claimDetailsView && response.data.data.claimDetailsView.views ){
                        setClaimDetailsView(response.data.data.claimDetailsView.views?.sort((fieldA, fieldB)=> fieldA.priority - fieldB.priority));
                    }
                    if(claimData?.approvals?.steps[claimData?.currentLevel && claimData?.currentLevel -1 ]?.form)
                    setFormId(claimData?.approvals?.steps[claimData?.currentLevel && claimData?.currentLevel -1 ]?.form);
                }
            }
            setLoading(false);
        } catch (error) {
          setLoading(false);
            console.log(error);
        }
    };


    const handleRejectClick = ()=>{
      setRejectModalOpen(true);
    };

    const handleCloseModel = ()=>{
      setOpenModel(false);
      setModelTitle('');
      setModelDescription('');
      getClaimsDetails();
    };

    const handleCloseDeleteModal = ()=>{
      setDeleteModalOpen(false);
    };
    const handleDeleteDraftclaim = async()=>{
      setDeleteModalOpen(false);
      setLoading(true);
      const approvalService = new approvalWorkflowServices();  
      await approvalService.deleteDraftClaims(claimDetails?.id,claimTypeId,'claim_master').then(async () => {
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.delete),
          ToastThemes.success
        );
        navigate(-1);
      }).catch((err) => {
        showToast(
          ToastMessages.failed.replace(stringSubstitute, toastMessage.delete),
          ToastThemes.error
        );
      });
      setLoading(false);
    };

    const onSubmit = async ({claims,remark,
    reasons,
    status, ...rest})=>{
      try {
        console.log('calling api');
        const response = await claimService.approveOrRejectClaims({...rest,claims,remark, reasons,approverRoleId: currentRole, status},claimTypeId,currentUserRoleId);
        setOpenModel(true);
        setModelTitle('Success');
        setModelDescription(response.data.message);
        return ({success: response.data.message,error: null});
      // eslint-disable-next-line no-unreachable
      } catch (error){
        setOpenModel(true);
        setModelTitle('Error');
        setModelDescription(error?.response?.data?.message || error.message);
        return({success:false, error: error?.response?.data?.message || error.message});
      }
    };

    const handleApproveClick = async ()=>{
      setLoading(true);
      await onSubmit({claims:[claimDetails.id], status: 'APPROVED', paymentDate: paymentDate});
      setLoading(false);
    };

    const handleSubmitClaim = async (value)=>{
     
      onSubmit({claims:[claimDetails.id], status: 'APPROVED', ...value});
    };
    function snakeCaseToTitleCase(inputString) {
      const words = inputString.split('_');
      const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      return capitalizedWords.join(' ');
    }

useEffect(()=>{
    getClaimsDetails();
},[]);

const fieldValuParser = (field)=>{
    const helperFn = { moment: moment };
    if(field.fn){
        const fn = eval(field.fn);
        return fn(claimDetails[field.key], helperFn);
    }
    return claimDetails[field.key];
};
  return (
    <Loading loading={loading}>
      <Box>
        <ClaimedEmployeeDetails employee={claimDetails.employee} />
        {/* Claims Details */}
        <Paper>
          <Grid container rowSpacing={3} columnSpacing={3}>
            {/* Claim Details Data */}
            <Grid item md={7}>
              <Box bgcolor='primary.main20' p={4}>
                <Typography variant='subtitle2' color={'neutral.dark100'} mb={0}>
                  Summary
                </Typography>
                <Box display='flex' mt={2}>
                  <Typography
                    variant='body1'
                    color={'neutral.dark80'}
                    mb={0}
                    mr={'4px'}
                  >
                    Reference number :
                  </Typography>
                  <Typography variant='body1' color={'neutral.dark100'} mb={0}>
                    {claimDetails.referenceNumber || 'N/A'}
                  </Typography>
                </Box>
                <Box display='flex' mt={2}>
                  <Typography
                    variant='body1'
                    color={'neutral.dark80'}
                    mb={0}
                    mr={'4px'}
                  >
                    Amount :
                  </Typography>
                  <Typography
                    variant='body1'
                    color={'neutral.dark100'}
                    mb={0}
                    mr={'4px'}
                  >
                    {claimDetails.amount}
                  </Typography>
                </Box>
                <Box display='flex' mt={2}>
                  <Typography
                    variant='body1'
                    color={'neutral.dark80'}
                    mb={0}
                    mr={'4px'}
                  >
                    Claim type :
                  </Typography>
                  <Typography variant='body1' color={'neutral.dark100'} mb={0}>
                    {claimDetails.claimType?.name || 'N/A'}
                  </Typography>
                </Box>
              </Box>

              <Box p={4}>
                <Grid container rowSpacing={3} columnSpacing={8} pr={8}>
                  <Grid item md={12}>
                    <Typography
                      variant='subtitle2'
                      color={'neutral.dark100'}
                      mb={0}
                    >
                      Details
                    </Typography>
                  </Grid>

                  {claimDetailsView.map(function (data, i) {
                    const value = fieldValuParser(data);
                    if (data.hideOnNull && !value) {
                      return (<span key={i}></span>);
                    } else{
                      if(data.type === 'files'){ 
                        return (<Grid key={i} item md={6}>
                        {value.map((file, k) => (
                        <Button key={k} href={file} variant='outlined' size='medium'>
                        {data.label} {k+1}
                        </Button>))}
                      </Grid>); }
                      return (
                        <Grid key={i} item md={6}>
                          <FieldView
                            labelText={data.label}
                            labelValue={value}
                            required={false}
                          />
                        </Grid>
                      );
                    }
                  })}

                  {claimDetails.other?.filter((otherItem)=>{
                    return 'value' in otherItem;
                  })?.map((otherItem, i)=>(
                    <Grid key={i} item md={6}>
                    <FieldView
                      labelText={otherItem.label || snakeCaseToTitleCase(otherItem.key)}
                      labelValue={otherItem.value}
                      required={false}
                    />
                  </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            {/* Audit Trail */}
            <Grid item md={5}>
              <Box
                ml={6}
                pl={6}
                sx={{ borderLeft: 'solid 1px', borderColor: 'neutral.dark40' }}
                mb={3}
              >
                <Typography variant='subtitle2' color={'neutral.dark80'} mb={3}>
                  Audit Trail
                </Typography>

                <Box>
                  {claimDetails.approvalWorkflowAttached?<ApprovalWorkflowAuditLog auditTrail={claimDetails.auditTrail}/>:<ClaimAuditLog claimDetails={claimDetails} />}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>

            {formId && (currentRole === claimDetails?.nextApproval?.id) && <SubmitClaim formId={formId} onSubmit={handleSubmitClaim}/>}

        {/* Footer */}
        {(!claimDetails.approvalWorkflowAttached) && !(formId && (currentRole === claimDetails?.nextApproval?.id)) && 
        (<Paper sx={{ p: 2, mt: 2, mb: 4 }}>
          <Box display='flex' gap='10px' justifyContent='flex-end'>
            {isApprover && (claimDetails?.status === CLAIM_STATUS.PENDING) && (currentRole === claimDetails?.nextApproval?.id) ? (
              <>
                <Button
                  size='medium'
                  sx={{
                    bgcolor: 'neutral.light80',
                    color: 'neutral.dark80',
                    px: 2,
                    textTransform: 'capitalize',
                  }}
                  onClick={()=>navigate(-1)}
                >
                  Review Later
                </Button>
                <Button variant='outlined' size='medium' onClick={handleRejectClick}>
                  Send Back
                </Button>
                <Button variant='contained' size='medium' onClick={handleApproveClick}>
                  Approve
                </Button>
              </>
            ) : isApprover && (<>
              <Button
                size='medium'
                sx={{
                  bgcolor: 'neutral.light80',
                  color: 'neutral.dark80',
                  px: 2,
                  textTransform: 'capitalize',
                }}
                onClick={()=>navigate(-1)}
              >
                Back
              </Button>
            </>)}
            {isEmployee && ((claimDetails?.status === CLAIM_STATUS.RE_SUBMIT || claimDetails?.status === CLAIM_STATUS.DRAFT) ? (
              <>
                <Button
                  size='medium'
                  sx={{
                    bgcolor: 'neutral.light80',
                    color: 'neutral.dark80',
                    px: 2,
                    textTransform: 'capitalize',
                  }}
                  onClick={()=>navigate(-1)}
                >
                  Back
                </Button>
                {claimDetails?.status === CLAIM_STATUS.DRAFT && (
                  <Button variant='outlined' size='medium' onClick={ ()=> setDeleteModalOpen(true)}>
                    Delete
                  </Button>)
                }
                <Button variant='contained' size='medium' onClick={() => navigate(`${ROUTES.CLAIM_EDIT(claimId)}?ctId=${claimTypeId}`)}>
                  Edit
                </Button>
              </>
            ) : (<>
              <Button
                size='medium'
                sx={{
                  bgcolor: 'neutral.light80',
                  color: 'neutral.dark80',
                  px: 2,
                  textTransform: 'capitalize',
                }}
                onClick={()=>navigate(-1)}
              >
                Back
              </Button>
            </>))}
          </Box>
        </Paper>)}
        {claimDetails.approvalWorkflowAttached && <ApprovalWorkflowFooter 
          handleRejectClick={handleRejectClick}
          handleApproveClick={handleApproveClick}
          recordId={claimId}
          moduleId={claimTypeId}
          currentUserRoleId={currentUserRoleId}
          claimStatus={claimDetails?.status}
          setDeleteModalOpen={setDeleteModalOpen}
          userId={userId}
          editButtonRoute={`${ROUTES.CLAIM_EDIT(claimId)}?ctId=${claimTypeId}`}
        />}
        <ClaimRejectModal
          open={rejectModalOpen}
          setOpen={setRejectModalOpen}
          claimDetails={claimDetails}
          handleSubmit={onSubmit}
          onSuccessClose={getClaimsDetails}
        />
        <ModalDialog
            showModal={openModel}
            onClickClose={handleCloseModel}
            primaryAction={'Close'}
            onClickConfirm={handleCloseModel}
            title={modelTitle}
            description={modelDescription}
          />
          <ModalDialog
            showModal={deleteModalOpen}
            onClickConfirm={handleDeleteDraftclaim}
            onClickClose={handleCloseDeleteModal}
            description={'Are you sure want to delete the claim?'}
            title={'Delete Claim'}
            primaryAction={'Delete'}
            secondaryAction={'Cancel'}
          />
      </Box>
    </Loading>
  );
}

const DataFields = [
    {
      id: 1,
      label: 'Status',
      value: 'Pending',
      required: false,
    },
    {
      id: 2,
      label: 'Created on',
      value: '27-03-2023',
      required: false,
    },
    {
      id: 3,
      label: 'Item',
      value: 'Company Info',
      required: false,
    },
    {
      id: 4,
      label: 'Priority',
      value: 'Medium',
      required: false,
    },
    {
      id: 5,
      label: 'Attachments',
      value: 'None',
      required: false,
    },
  ];
  