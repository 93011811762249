import React, { useState } from 'react';
import DropDownActionForTime from './DropDownActionForTime';
import ModalDialog from '../../../../atoms/Modal/ModalDialog';
import FileUPload from '../../../../atoms/FileUpload/FileUPload';
import { FileDownloadOutlined } from '@mui/icons-material';
import { LoadingStatus, ToastThemes, toastMessage } from '../../../../constants/common';
import { ToastMessages, stringSubstitute } from '../../../OnBoarding/Organization/constants/onboarding.constants';
import { showToast } from '../../../../utils/common.util';
import { useDispatch } from 'react-redux';
import { fileUpload } from '../../../../redux/main/action';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import { TimeManagementServices } from '../../../../services/timeManagementServices';
import { getAllTimeManagement } from '../../../../redux/timeManagement/action';
import moment from 'moment';
import DownloadButton from '../../../../atoms/DownloadButton/DownloadButton';
import { TmtStatus } from '../../Constant/Constant';
import { DownloadAPIResponse } from '../../../../services/downloadAPIService';
import { currentDate } from '../../../../utils/formatter';

const ModalMapForTime = (props) => {
	const { checkedItems, searchQuery, currentUserRoleId, empIdParams, selectedDate } = props;
	const [showModal, setshowModal] = useState(false);
	const [context, setContext] = useState('');
	const [fileLoading, setFileLoading] = useState(-1);
	const [fileRes, setFileRes] = useState();
	const [postActionLoading, setPostActionLoading] = useState(-1);
	const [saveDisabled, setSaveDisbaled] = useState(true);

	const dispatch = useDispatch();

	const handleApprove = () => {
		setContext('approve');
		setshowModal(true);
	};

	const handleReject = () => {
		setContext('reject');
		setshowModal(true);
	};

	const handleImport = () => {
		setContext('import');
		setshowModal(true);
		setFileRes();
		setSaveDisbaled(true);
	};

	const handleActionBtnClick = (statusCode) => {
		setPostActionLoading(LoadingStatus.Loading);
		new TimeManagementServices().updateStatus({
			statusCode: statusCode,
			timeManagementIds: checkedItems,
			currentDate:  currentDate.startOf('day').format('YYYY-MM-DD')
		},
			currentUserRoleId).then(() => {
				setPostActionLoading(LoadingStatus.Success);
				dispatch(getAllTimeManagement(currentUserRoleId, searchQuery,
					moment(selectedDate[0]).format('YYYYMM'),
					moment(selectedDate[1]).format('YYYYMM'),
					empIdParams));
				showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.update), ToastThemes.success);
			}).catch((err) => {
				setPostActionLoading(LoadingStatus.Failure);
				showToast(err?.response?.data?.message, ToastThemes.error);
			});
	};
	
	const handleFileChange = (event) => {
		const val = event.target.files[0];
		setFileLoading(LoadingStatus.Loading);
		dispatch(fileUpload(val)).then((res) => {
			setFileLoading(LoadingStatus.Success);
			setFileRes(res);
			setPostActionLoading(LoadingStatus.Loading);
			new DownloadAPIResponse().validImport(res.id).then(() => {
				showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.update), ToastThemes.success);
				setSaveDisbaled(false);
				setPostActionLoading(LoadingStatus.Success);
			}).catch((err) => {
				setPostActionLoading(LoadingStatus.Failure);
				showToast(err, ToastThemes.error);
			});
		}).catch((err) => {
			showToast(err, ToastThemes.error);
			setFileLoading(LoadingStatus.Failure);
		});

	};
	const handleSaveClick = () => {
		setPostActionLoading(LoadingStatus.Loading);
		new TimeManagementServices().postImportData({ docId: fileRes.id }).then(() => {
			setPostActionLoading(LoadingStatus.Success);
			dispatch(getAllTimeManagement(currentUserRoleId, searchQuery,
				moment(selectedDate[0]).format('YYYYMM'),
				moment(selectedDate[1]).format('YYYYMM'),
				empIdParams));
			showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.update), ToastThemes.success);
		}).catch((err) => {
			setPostActionLoading(LoadingStatus.Failure);
			showToast(err?.response?.data?.message, ToastThemes.error);
		});
	};

	const modalMap = {
		approve: {
			title: 'Approve',
			description: 'Are you sure you want to Approve?',
			primaryAction: 'Approve',
			onClickConfirm: () => handleActionBtnClick(TmtStatus.Approve),
			severity: 'success',
			maxWidth: 'md',
			fullWidth: true,
			actionButtonDisabled: false
		},
		reject: {
			title: 'Reject',
			description: 'Are you sure you want to Reject/Send-Back?',
			primaryAction: 'Send Back',
			onClickConfirm: () => handleActionBtnClick(TmtStatus.Reject),
			severity: 'error',
			maxWidth: 'md',
			fullWidth: true,
			actionButtonDisabled: false
		},
		import: {
			title: 'Import Pay Day Data',
			description: <FileUPload placeholder='Import' allowedImageType='text/csv' onUpload={handleFileChange}
				fileRes={fileRes} />,
			primaryAction: 'Save',
			onClickConfirm: () => handleSaveClick(),
			severity: 'success',
			maxWidth: 'lg',
			fullWidth: true,
			actionButtonDisabled: saveDisabled,
			lhsActionButton: <DownloadButton lhsActionButton={<>Download Template  <FileDownloadOutlined /></>}
				href='/templates/pay-day-data-template.csv' fileName='Pay Day Data Template.csv' />
		},
	};
	return (
		<>
			<CircularLoader show={fileLoading === LoadingStatus.Loading || postActionLoading === LoadingStatus.Loading} />
			<DropDownActionForTime checkedItems={checkedItems} handleApprove={handleApprove} handleReject={handleReject}
				handleImport={handleImport} />
			<ModalDialog
				severity={modalMap[context]?.severity}
				title={modalMap[context]?.title}
				listComponent={modalMap[context]?.listComponent}
				description={modalMap[context]?.description}
				primaryAction={modalMap[context]?.primaryAction}
				secondaryAction="Cancel"
				showModal={showModal}
				onClickClose={() => setshowModal(false)}
				onClickConfirm={modalMap[context]?.onClickConfirm}
				maxWidth={modalMap[context]?.maxWidth}
				fullWidth={modalMap[context]?.fullWidth}
				primaryButtonDisabled={modalMap[context]?.actionButtonDisabled}
				lhsActionButton={modalMap[context]?.lhsActionButton}
			/>
		</>
	);
};

export default ModalMapForTime;