import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Modal from '../../../shared/components/ui-elements/Modal';
import ButtonUI from '../../../shared/components/ui-elements/ButtonUI';
import 'rc-time-picker/assets/index.css';
import DynamicForm from '../../dynami-form/dynamic-form';
import ClaimedEmployeeDetails from '../../claim-view/claimed-employee-details';

const TransportClaim = () => {
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { claimType } = useParams();
  const [showImgName, setShowImgName] = useState(0);
  const uniqueNum = Math.floor(Math.random() * 10000000000) + 10000000000;
  /* eslint-disable */
  const [successMessage, setSuccessMessage] = useState('');
  let userData = { userDetails: {} };
  const HOD = userData.userDetails.HOD;
  const cardData = [
    {
      post: 'Reporting Officer',
      name:
        userData.userDetails?.reportingTo?.firstName +
        ' ' +
        userData.userDetails?.reportingTo?.lastName,
    },
    {
      post: 'HOD',
      name: HOD,
    },
    {
      post: 'Cost Center',
      name: userData.userDetails.costCenter,
    },
  ];
  const closeBackdropHandler = () => {
    setIsBackdropOpen(false);
    navigate('/claims', {
      state: {
        claimType: '1',
      },
    });
  };

  const openSuccessPopUp = (successMessage) => {
    setSuccessMessage(successMessage);
    setIsBackdropOpen(true);
  };

  useEffect(() => {
    if (isNaN(claimType)) {
      navigate('/claims');
    } else {
      // call api and set the form schema.
      // set formSchema and if not found the redirect to it's home page

    }
  }, []);

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Container>
          <Grid container>
            <ClaimedEmployeeDetails />
            <DynamicForm openSuccessPopUp={openSuccessPopUp} />
          </Grid>
        </Container>
      </Box>
      <Modal
        show={isBackdropOpen}
        onCancel={closeBackdropHandler}
        header={<h1>Success</h1>}
        contentClass="modal-content"
        footerClass="footer-content"
        submitButton={
          <ButtonUI
            backgroundColor="#00AC94"
            color="#ffffff"
            size="small"
            padding="3px 20px"
            onclick={closeBackdropHandler}
          >
            Ok
          </ButtonUI>
        }
      >
        <h4>{successMessage}</h4>
      </Modal>
    </React.Fragment>
  );
};
export default TransportClaim;
