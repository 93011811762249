import React, { useEffect, useState } from 'react';
import AuditLogApprovalWorkflow from './Audit_log/AuditLogApprovalWorkflow';
import { CLAIM_STATUS } from '../../../../claim-view/claim-audit-log';

export default function ApprovalWorkflowAuditLog(props) {
  const { auditTrail, defaultTimezone } = props;
  const rejectedRecord = auditTrail.findIndex(
    (item) => item.status === CLAIM_STATUS.REJECTED
  );
  return (
    <>
      {auditTrail?.map((log, i) => (
        <AuditLogApprovalWorkflow
          key={i}
          index={i}
          log={log}
          rejectedRecord={rejectedRecord}
          defaultTimezone={defaultTimezone}
        />
      ))}
    </>
  );
}
