import { Box, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SubHeader from './SubHeader';
import DynamicBreadcrumb from '../../atoms/Breadcrumbs/dynamicBreadcrumb';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import CircularLoader from '../../atoms/CircularLoader/circular-loader';
import { AppLevelPermissions } from '../OnBoarding/Organization/constants/onboarding.constants';
import { fetchRecruitingPermissions } from '../../redux/modulePermissions/action';
import { CandidateDetails, CandidateFormFieldNames, JobFormFieldNames, JobRequisition } from './Constant/constant';
import TryAgain from '../molecules/ErrorState/error.component';

export default function Recruiting() {

  const dispatch = useDispatch();

  const { currentUserRoleId, appPermissions } = useSelector((state) => state.main);
  const { requestProcessing: { [APIRequestStatus.FETCH_RECRUITING_PERMISSIONS]: recruitingPermissionsLoading,
  } } = useSelector((state) => state.modulePermissions);
  const { jobIdPropertiesMap } = useSelector((state) => state.job);
  const { candidateIdPropertiesMap } = useSelector((state) => state.candidate);
  const { logoImage } = useSelector((state) => state && state.onBoarding);

  const location = useLocation();
  const paths = location.pathname.split('/');
  const currentPath = paths[paths.length - 1];
  const [idNameMap, setIdNameMap] = useState({});

  useEffect(() => {
    switch (currentPath) {
      case JobRequisition: {
        const simplifiedMap = jobIdPropertiesMap && Object.keys(jobIdPropertiesMap).reduce((acc, id) => {
          acc[id] = jobIdPropertiesMap[id]?.[JobFormFieldNames.Title]?.longName;
          return acc;
        }, {});
        setIdNameMap(simplifiedMap);
        break;
      }
      case CandidateDetails: {
        const simplifiedMap = candidateIdPropertiesMap && Object.keys(candidateIdPropertiesMap).reduce((acc, id) => {
          acc[id] = candidateIdPropertiesMap[id]?.[CandidateFormFieldNames.Name];
          return acc;
        }, {});
        setIdNameMap(simplifiedMap);
        break;
      }
    }
  }, [candidateIdPropertiesMap, jobIdPropertiesMap]);

  useEffect(() => {
    if (appPermissions && currentUserRoleId) {
      if (appPermissions[AppLevelPermissions.Job]) {
        dispatch(fetchRecruitingPermissions(currentUserRoleId, appPermissions[AppLevelPermissions.Job].id));
      }
    }
  }, [currentUserRoleId, appPermissions]);


  if (recruitingPermissionsLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <Box m={4} pl={4}>
      <CircularLoader show={recruitingPermissionsLoading === LoadingStatus.Loading} />
      <Box>
        <SubHeader />
        <Box my={3}>
          <DynamicBreadcrumb idNameMap={idNameMap} />
          <Box mt={1}>
            <Divider />
          </Box>
        </Box>
      </Box>
      {recruitingPermissionsLoading === LoadingStatus.Success && logoImage &&
        <Box>
          <Outlet />
        </Box>
      }
    </Box>
  );
}
