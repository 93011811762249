import React from 'react';
import { Box, TextField, Typography, styled } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LightTooltip } from '../Tooltip/LightTooltip';
import FieldView from '../../components/molecules/FieldView/FieldView';
import { FormFieldTypes } from '../../constants/common';

export default function InputField({
  id,
  defaultValue,
  placeholder,
  labelText,
  size,
  hiddenLabel,
  fullWidth,
  multiline,
  required,
  onChange,
  name,
  error,
  helperText,
  type,
  variant,
  label,
  value,
  disabled,
  textTransform,
  infoIcon,
  rhsIcon,
  max,
  min,
  isReadOnly,
  onKeyDown,
  onBlur,
  dataInputFormat,
  range,
  maxLength,
  ...rest
}) {
  if (isReadOnly) {
    return (
      <FieldView
        labelText={labelText}
        labelValue={
          type === FormFieldTypes.NumberString && dataInputFormat
            ? parseFloat(value)?.toFixed(dataInputFormat)
            : value || null
        }
      />
    );
  }
  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'}>
        {labelText && (
          <Typography
            variant="body2"
            color={`${disabled ? 'neutral.light40' : 'neutral.dark80'}`}
            mb={1}
          >
            {labelText}
            {required && (
              <Box
                color="accentSecondary.main"
                sx={{ display: 'inline-block' }}
              >
                *
              </Box>
            )}
            {infoIcon && (
              <LightTooltip title={infoIcon} placement="top" arrow>
                <Box color="primary.main" sx={{ display: 'inline-block' }}>
                  <InfoOutlinedIcon
                    sx={{ width: 14, height: 14, ml: '4px', cursor: 'pointer' }}
                  />
                </Box>
              </LightTooltip>
            )}
          </Typography>
        )}
        <Box>{rhsIcon}</Box>
      </Box>
      <TextField
        id={id}
        defaultValue={defaultValue}
        placeholder={placeholder}
        size={size}
        hiddenLabel={hiddenLabel}
        fullWidth={fullWidth}
        multiline={multiline}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        error={error}
        helperText={helperText}
        type={type}
        variant={variant}
        label={label}
        value={value}
        disabled={disabled}
        onKeyDown={onKeyDown}
        InputProps={{
          inputProps:
            type === FormFieldTypes.NumberString
              ? { min: range?.min || 0, max: range?.max }
              : { style: { textTransform: textTransform }, maxLength: maxLength },
        }}
      />
    </>
  );
}
