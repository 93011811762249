import API from './api';
export class CandidateServices extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL + '/recruiting/candidate-details/' || '';
  constructor() {
    super(CandidateServices.baseURL);
  }

  getCandidateDetails = async (roleId, candidateId) => (
    this.responsify(await this.instance.get('details', {
      params: {
        id: candidateId,
        roleId
      }
    }))
  );

  postCandidateInfo = async (candidateFormInfo) => (
    this.responsify(await this.instance.put('save', candidateFormInfo)));

  getAllCandidateList = async (roleId, searchQuery, startDate, endDate, title) => (
    this.responsify(await this.instance.get('list-all', { params: { roleId, searchQuery, startDate, endDate, title } })));

  getDropdownOptions = async (fieldName) => (
    this.responsify(await this.instance.get('lookup-details', { params: { fieldName } }))
  );

  postCandidateInfoDraft = async (candidateFormInfo) => (
    this.responsify(await this.instance.post('draft', candidateFormInfo)));

  getStatusDropDown = async () => (
    this.responsify(await this.instance.get('list-status')));

  getAllJobList = async () => (
    this.responsify(await this.instance.get('list-jobs')));

  postAssignJob = async (assignJobTOCandidate) => (
    this.responsify(await this.instance.post('add-to-job', assignJobTOCandidate)));

  updateCandidateStatus = async (candidateStatusInfo) => (
    this.responsify(await this.instance.put('update-status', candidateStatusInfo)));

  getCandidateAssessment = async (candidateJobId) => (
    this.responsify(await this.instance.get(`assessment/${candidateJobId}`)));
}