import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { SeparationServices } from '../../../services/separation';
import { useSelector } from 'react-redux';
import {
  FormFieldNames,
  SeparationRejectReasons,
  seperation,
} from '../Organization/constants/onboarding.constants';
import FormView from '../common/Form/FormView';
import {
  getFormBody,
  myJourneyFormViewFields,
} from '../Organization/utils/userOnboarding/userOnboarding.util';
import ApprovalWorkflowAuditLog from '../Organization/ApprovalFlow/ApprovalWorkflowUtilsCommon/ApprovalWorkflowAuditTrail';
import ApprovalWorkflowFooterCommon from '../Organization/ApprovalFlow/Footer/ApprovalWorkflowFooterCommon';
import { FormFieldTypes, LoadingStatus, ToastThemes } from '../../../constants/common';
import { showToast } from '../../../utils/common.util';
import { currentDate } from '../../../utils/formatter';
import { OnboardingServices } from '../../../services/onboarding';
import ActionHeader from '../Organization/ActionHeader/ActionHeader';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';

const MyJourney = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const idForUserDetails = pathNames[pathNames.length - 1];

  const { userInfo: loggedInUserDetails, currentUserRoleId } = useSelector(
    (state) => state.main
  );
  const { userProfilePermissions } = useSelector(
    (state) => state.modulePermissions
  );
  const [response, setResponse] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [isAuthorized, setisAuthorized] = useState(false);
  const { logs = [], details = {} } = response;
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(-1);

  useEffect(async () => {
    if (!isNaN(idForUserDetails)) {
      try {
        setLoading(LoadingStatus.Loading);
        const response = await new OnboardingServices().getUserDetails(
          idForUserDetails,
          currentUserRoleId,
          loggedInUserDetails?.id
        ).finally(()=> {
          setLoading(LoadingStatus.Success);
        });
        setUserData(response || {});
      } catch (error) {
        console.log(error);
        setLoading(LoadingStatus.Failure);
      }
    }
  }, [idForUserDetails]);

  const getDetails = useCallback(async () => {
    const separationServices = new SeparationServices();
    try {
      const response = await separationServices.getUserResignDetails({
        userId: idForUserDetails,
      });
      setResponse(response || []);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getDetails();
  }, []);

  const saveDetails = (action, data, isLastApproval) => {
    const newDate = getFormBody(data);
    new SeparationServices()
      .saveDetails({
        action,
        isLastApproval,
        resignationId: details?.id,
        empId: Number(idForUserDetails),
        ...newDate,
      })
      .catch((err) => {
        showToast(err?.response?.data?.message, ToastThemes.error);
      });
  };

  return (
    <Box m={4} mt={6} pl={4}>
      <CircularLoader show={loading === LoadingStatus.Loading} />
      <Box p={2} bgcolor='white'>
        <ActionHeader
          labelText={userData[FormFieldNames.DisplayName]}
          showPublish={false}
          showBackButton
          showPublishModal={false}
          onBackClick={() => navigate(-1)}
          showSave={false}
          labelCode={userData[FormFieldNames.EmployeeId] || ''}
        />
        <Box
          sx={{
            display: 'flex',
            mt: 3,
          }}
        >
          <Box maxWidth={1000}>
            <FormView list={myJourneyFormViewFields} info={userData} />
          </Box>
          <Box pl={30}>
            <Box
              ml={6}
              pl={6}
              sx={{ borderLeft: 'solid 1px', borderColor: 'neutral.dark40' }}
              mb={3}
            >
              <Typography variant="subtitle2" color={'neutral.dark80'} mb={3}>
                Audit Trail
              </Typography>
              <ApprovalWorkflowAuditLog auditTrail={logs} />
            </Box>
          </Box>
        </Box>
        <Box mb={3} mt={1}>
          {loggedInUserDetails?.id == userData?.id && isEditable && (
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                navigate('/separation/resubmit');
              }}
            >
              Re-Submit
            </Button>
          )}
        </Box>
        <ApprovalWorkflowFooterCommon
          recordId={details?.id}
          moduleId={userProfilePermissions?.[seperation]?.id}
          saveDetails={saveDetails}
          auditLogs={logs}
          getDetails={getDetails}
          reasons={SeparationRejectReasons}
          refId={userData[FormFieldNames.EmployeeId]}
          setIsEditable={setIsEditable}
          list={[
            {
              fieldLabel: 'Last Working Date',
              placeholderText: 'dd/mm/yyyy',
              fieldType: FormFieldTypes.Date,
              isRequired: false,
              fieldRefName: 'lastWorkingDate',
              fieldSize: 13,
              minDate: currentDate,
            },
          ]}
          isAdditionalInfo={true}
          moduleName={seperation}
          isAuthorized={isAuthorized}
          setisAuthorized={setisAuthorized}
        />
      </Box>
    </Box>
  );
};

export default MyJourney;
