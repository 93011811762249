
import { clone, cloneDeep } from 'lodash';
import moment from 'moment';
import { DateFormatters } from '../../constants/dateConstants';
import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import {
  GET_USER_DETAILS_FAILURE, GET_USER_DETAILS_REQUEST, GET_USER_DETAILS_SUCCESS, POST_USER_INFO_FAILURE, POST_USER_INFO_REQUEST, POST_USER_INFO_SUCCESS,
  PUBLISH_USER_FAILURE, PUBLISH_USER_REQUEST, PUBLISH_USER_SUCCESS, RESET_USER_INFO, SET_USER_INFO,
  UPDATE_USER_INFO_FAILURE, UPDATE_USER_INFO_REQUEST, UPDATE_USER_INFO_SUCCESS,
  GET_USER_DOCUMENT_LIST_FAILURE, GET_USER_DOCUMENT_LIST_REQUEST, GET_USER_DOCUMENT_LIST_SUCCESS,
  GET_USER_BLOOD_GROUP_FAILURE, GET_USER_BLOOD_GROUP_REQUEST, GET_USER_BLOOD_GROUP_SUCCESS,
  GET_USER_GENDER_FAILURE, GET_USER_GENDER_REQUEST, GET_USER_GENDER_SUCCESS,
  GET_USER_WORK_STATUS_FAILURE, GET_USER_WORK_STATUS_REQUEST, GET_USER_WORK_STATUS_SUCCESS,
  GET_USER_DESIGNATION_FAILURE, GET_USER_DESIGNATION_REQUEST, GET_USER_DESIGNATION_SUCCESS,
  GET_USER_ONBOARDING_MENU_REQUEST, GET_USER_ONBOARDING_MENU_SUCCESS, GET_USER_ONBOARDING_MENU_FAILURE,
  UPDATE_USER_STATUS_REQUEST, UPDATE_USER_STATUS_SUCCESS, UPDATE_USER_STATUS_FAILURE,
  GET_USER_COST_CENTRE_FAILURE, GET_USER_COST_CENTRE_REQUEST, GET_USER_COST_CENTRE_SUCCESS,
  GET_BUSINESS_UNITS_REQUEST, GET_BUSINESS_UNITS_SUCCESS, GET_BUSINESS_UNITS_FAILURE,
  GET_EMPLOYMENT_DETAILS_REQUEST, GET_EMPLOYMENT_DETAILS_SUCCESS, GET_EMPLOYMENT_DETAILS_FAILURE,
  GET_ORG_DIVISIONS_REQUEST, GET_ORG_DIVISIONS_SUCCESS, GET_ORG_DIVISIONS_FAILURE,
  GET_USER_NATIONALITY_FAILURE, GET_USER_NATIONALITY_REQUEST, GET_USER_NATIONALITY_SUCCESS,
  GET_USER_RACE_FAILURE, GET_USER_RACE_REQUEST, GET_USER_RACE_SUCCESS,
  GET_USER_RELIGION_FAILURE, GET_USER_RELIGION_REQUEST, GET_USER_RELIGION_SUCCESS,
  GET_USER_CATEGORY_FAILURE, GET_USER_CATEGORY_REQUEST, GET_USER_CATEGORY_SUCCESS,
  GET_USER_FUNCTION_FAILURE, GET_USER_FUNCTION_REQUEST, GET_USER_FUNCTION_SUCCESS, GET_USER_WORK_AUTHORIZATION_REQUEST, GET_USER_WORK_AUTHORIZATION_SUCCESS, GET_USER_WORK_AUTHORIZATION_FAILURE, RESET_WORK_AUTH, UPDATE_DOC_LIST, ADD_EMPLOYEE_TAB, DELETE_EMPLOYEE_TAB, ADD_EDUCATION_TAB, DELETE_EDUCATION_TAB, DUPLICATE_EDUCATION_TAB, DUPLICATE_EMPLOYEE_TAB,
  GET_EMPLOYEE_CONFIG_REQUEST, GET_EMPLOYEE_CONFIG_SUCCESS, GET_EMPLOYEE_CONFIG_FAILURE, GET_EDUCATION_CONFIG_REQUEST, GET_EDUCATION_CONFIG_SUCCESS, GET_EDUCATION_CONFIG_FAILURE, POST_EMPLOYEE_INFO_REQUEST, POST_EMPLOYEE_INFO_SUCCESS, POST_EMPLOYEE_INFO_FAILURE, POST_EDUCATION_INFO_REQUEST, POST_EDUCATION_INFO_SUCCESS, POST_EDUCATION_INFO_FAILURE
} from './actionTypes';

const initialState = {
  userInfo: {
    id: '',
    data: {},
    isPublished: false
  },
  requestProcessing: {
    [APIRequestStatus.POST_USER_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.UPDATE_USER_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.PUBLISH_USER]: LoadingStatus.Loading,
    [APIRequestStatus.GET_USER_DETAILS]: LoadingStatus.Loading,
    [APIRequestStatus.GET_USER_DOCUMENT_LIST]: LoadingStatus.Loading,
    [APIRequestStatus.GET_USER_GENDER]: LoadingStatus.Loading,
    [APIRequestStatus.GET_USER_BLOOD_GROUP]: LoadingStatus.Loading,
    [APIRequestStatus.GET_USER_WORK_STATUS]: LoadingStatus.Loading,
    [APIRequestStatus.GET_USER_DESIGNATION]: LoadingStatus.Loading,
    [APIRequestStatus.GET_USER_ONBOARDING_MENU]: LoadingStatus.Loading,
    [APIRequestStatus.UPDATE_USER_STATUS]: LoadingStatus.Loading,
    [APIRequestStatus.GET_USER_COST_CENTRE]: LoadingStatus.Loading,
    [APIRequestStatus.GET_BUSINESS_UNITS]: LoadingStatus.Loading,
    [APIRequestStatus.GET_ORG_DIVISIONS]: LoadingStatus.Loading,
    [APIRequestStatus.GET_EMPLOYMENT_TYPES]: LoadingStatus.Loading,
    [APIRequestStatus.GET_USER_NATIONALITY]: LoadingStatus.Loading,
    [APIRequestStatus.GET_USER_RELIGION]: LoadingStatus.Loading,
    [APIRequestStatus.GET_USER_RACE]: LoadingStatus.Loading,
    [APIRequestStatus.GET_USER_CATEGORY]: LoadingStatus.Loading,
    [APIRequestStatus.GET_USER_FUNCTION]: LoadingStatus.Loading,
    [APIRequestStatus.GET_USER_WORK_AUTHORIZATION]: LoadingStatus.Loading,
    [APIRequestStatus.GET_EMPLOYEE_CONFIG]: LoadingStatus.Loading,
    [APIRequestStatus.POST_EMPLOYEE_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.POST_EDUCATION_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.GET_EDUCATION_CONFIG]: LoadingStatus.Loading,
  },
  userDocumentsList: [],
  userGender: [],
  userBloodGroup: [],
  userWorkStatus: [],
  userDesignation: [],
  onboardingUserMenu: [],
  userCostCentre: [],
  businessUnits: [],
  orgDivisions: [],
  employmentTypes: [],
  userNationality: [],
  userReligion: [],
  userRace: [],
  userFunction: [],
  userCategory: [],
  userWorkAuthorization: [],
  employeeFormConfig: [],
  eduFormConfig: [],
  empFormData: [],
  eduFormData: [],
};

const userOnboardingReducer = (state = initialState, action) => {
  switch (action.type) {

    case POST_USER_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_USER_INFO]: LoadingStatus.Loading
        }
      };
    }

    case POST_USER_INFO_SUCCESS: {
      const { info, id } = action.payload;
      return {
        ...state,
        userInfo: {
          id: id,
          data: info
        },
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_USER_INFO]: LoadingStatus.Success
        }
      };
    }

    case POST_USER_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_USER_INFO]: LoadingStatus.Failure
        }
      };
    }

    case UPDATE_USER_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_USER_INFO]: LoadingStatus.Loading
        }
      };
    }

    case UPDATE_USER_INFO_SUCCESS: {
      const info = action.payload;
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: info
        },
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_USER_INFO]: LoadingStatus.Success
        }
      };
    }

    case UPDATE_USER_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_USER_INFO]: LoadingStatus.Failure
        }
      };
    }

    case PUBLISH_USER_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.PUBLISH_USER]: LoadingStatus.Loading
        }
      };
    }

    case PUBLISH_USER_SUCCESS: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          isPublished: true
        },
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.PUBLISH_USER]: LoadingStatus.Success
        }
      };
    }

    case PUBLISH_USER_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.PUBLISH_USER]: LoadingStatus.Failure
        }
      };
    }

    case GET_USER_DETAILS_REQUEST: {
      return initialState;
    }

    case GET_USER_DETAILS_SUCCESS: {
      const { id, isPublished, ...rest } = action.payload;
      return {
        ...state,
        userInfo: {
          id: id,
          isPublished: isPublished,
          data: rest
        },
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_DETAILS]: LoadingStatus.Success
        }
      };
    }

    case GET_USER_DETAILS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_DETAILS]: LoadingStatus.Failure
        }
      };
    }

    case RESET_USER_INFO: {
      return initialState;
    }
    default: {
      return state;
    }

    case GET_USER_DOCUMENT_LIST_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_DOCUMENT_LIST]: LoadingStatus.Loading
        }
      };
    }

    case GET_USER_DOCUMENT_LIST_SUCCESS: {
      const userDocumentsList = action.payload;
      return {
        ...state,
        userDocumentsList: userDocumentsList,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_DOCUMENT_LIST]: LoadingStatus.Success
        }
      };
    }

    case GET_USER_DOCUMENT_LIST_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_DOCUMENT_LIST]: LoadingStatus.Failure
        }
      };
    }

    case GET_USER_GENDER_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_GENDER]: LoadingStatus.Loading
        }
      };
    }

    case GET_USER_GENDER_SUCCESS: {
      const userGender = action.payload;
      return {
        ...state,
        userGender: userGender,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_GENDER]: LoadingStatus.Success
        }
      };
    }

    case GET_USER_GENDER_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_GENDER]: LoadingStatus.Failure
        }
      };
    }

    case GET_USER_BLOOD_GROUP_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_BLOOD_GROUP]: LoadingStatus.Loading
        }
      };
    }

    case GET_USER_BLOOD_GROUP_SUCCESS: {
      const userBloodGroup = action.payload;
      return {
        ...state,
        userBloodGroup: userBloodGroup,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_BLOOD_GROUP]: LoadingStatus.Success
        }
      };
    }

    case GET_USER_BLOOD_GROUP_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_BLOOD_GROUP]: LoadingStatus.Failure
        }
      };
    }

    case GET_USER_WORK_STATUS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_WORK_STATUS]: LoadingStatus.Loading
        }
      };
    }

    case GET_USER_WORK_STATUS_SUCCESS: {
      const userWorkStatus = action.payload;
      return {
        ...state,
        userWorkStatus: userWorkStatus,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_WORK_STATUS]: LoadingStatus.Success
        }
      };
    }

    case GET_USER_WORK_STATUS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_WORK_STATUS]: LoadingStatus.Failure
        }
      };
    }

    case GET_USER_DESIGNATION_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_DESIGNATION]: LoadingStatus.Loading
        }
      };
    }

    case GET_USER_DESIGNATION_SUCCESS: {
      const userDesignation = action.payload;
      userDesignation.sort(function (a, b) {
        if (a.name < b.name)
          return -1;
        if (a.name > b.name)
          return 1;
        return 0;
      });
      return {
        ...state,
        userDesignation: userDesignation,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_DESIGNATION]: LoadingStatus.Success
        }
      };
    }

    case GET_USER_DESIGNATION_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_DESIGNATION]: LoadingStatus.Failure
        }
      };
    }

    case GET_USER_ONBOARDING_MENU_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_ONBOARDING_MENU]: LoadingStatus.Failure
        }
      };
    }

    case GET_USER_ONBOARDING_MENU_SUCCESS: {
      const list = action.payload;

      return {
        ...state,
        onboardingUserMenu: list,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_ONBOARDING_MENU]: LoadingStatus.Success
        }
      };
    }

    case GET_USER_ONBOARDING_MENU_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_ONBOARDING_MENU]: LoadingStatus.Failure
        }
      };
    }

    case UPDATE_USER_STATUS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_USER_STATUS]: LoadingStatus.Failure
        }
      };
    }

    case UPDATE_USER_STATUS_SUCCESS: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_USER_STATUS]: LoadingStatus.Success
        }
      };
    }

    case UPDATE_USER_STATUS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_USER_STATUS]: LoadingStatus.Failure
        }
      };
    }

    case GET_USER_COST_CENTRE_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_COST_CENTRE]: LoadingStatus.Loading
        }
      };
    }

    case GET_USER_COST_CENTRE_SUCCESS: {
      const userCostCentre = action.payload;
      userCostCentre.sort(function (a, b) {
        if (a.name < b.name)
          return -1;
        if (a.name > b.name)
          return 1;
        return 0;
      });
      return {
        ...state,
        userCostCentre: userCostCentre,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_COST_CENTRE]: LoadingStatus.Success
        }
      };
    }

    case GET_USER_COST_CENTRE_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_COST_CENTRE]: LoadingStatus.Failure
        }
      };
    }

    case GET_BUSINESS_UNITS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_BUSINESS_UNITS]: LoadingStatus.Loading
        }
      };
    }

    case GET_BUSINESS_UNITS_SUCCESS: {
      const businessUnits = action.payload;
      return {
        ...state,
        businessUnits: businessUnits,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_BUSINESS_UNITS]: LoadingStatus.Success
        }
      };
    }

    case GET_BUSINESS_UNITS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_BUSINESS_UNITS]: LoadingStatus.Failure
        }
      };
    }

    case GET_EMPLOYMENT_DETAILS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_EMPLOYMENT_TYPES]: LoadingStatus.Loading
        }
      };
    }

    case GET_EMPLOYMENT_DETAILS_SUCCESS: {
      const employmentTypes = action.payload;
      return {
        ...state,
        employmentTypes: employmentTypes,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_EMPLOYMENT_TYPES]: LoadingStatus.Success
        }
      };
    }

    case GET_EMPLOYMENT_DETAILS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_EMPLOYMENT_TYPES]: LoadingStatus.Failure
        }
      };
    }

    case GET_ORG_DIVISIONS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ORG_DIVISIONS]: LoadingStatus.Loading
        }
      };
    }

    case GET_ORG_DIVISIONS_SUCCESS: {
      const divisions = action.payload;
      return {
        ...state,
        orgDivisions: divisions,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ORG_DIVISIONS]: LoadingStatus.Success
        }
      };
    }

    case GET_ORG_DIVISIONS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ORG_DIVISIONS]: LoadingStatus.Failure
        }
      };
    }

    case GET_USER_NATIONALITY_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_NATIONALITY]: LoadingStatus.Loading
        }
      };
    }

    case GET_USER_NATIONALITY_SUCCESS: {
      const userNationality = action.payload;
      return {
        ...state,
        userNationality: userNationality,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_NATIONALITY]: LoadingStatus.Success
        }
      };
    }

    case GET_USER_NATIONALITY_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_NATIONALITY]: LoadingStatus.Failure
        }
      };
    }

    case GET_USER_RELIGION_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_RELIGION]: LoadingStatus.Loading
        }
      };
    }

    case GET_USER_RELIGION_SUCCESS: {
      const userReligion = action.payload;
      return {
        ...state,
        userReligion: userReligion,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_RELIGION]: LoadingStatus.Success
        }
      };
    }

    case GET_USER_RELIGION_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_RELIGION]: LoadingStatus.Failure
        }
      };
    }

    case GET_USER_RACE_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_RACE]: LoadingStatus.Loading
        }
      };
    }

    case GET_USER_RACE_SUCCESS: {
      const userRace = action.payload;
      return {
        ...state,
        userRace: userRace,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_RACE]: LoadingStatus.Success
        }
      };
    }

    case GET_USER_RACE_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_RACE]: LoadingStatus.Failure
        }
      };
    }

    case GET_USER_FUNCTION_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_FUNCTION]: LoadingStatus.Loading
        }
      };
    }

    case GET_USER_FUNCTION_SUCCESS: {
      const userFunction = action.payload;
      return {
        ...state,
        userFunction: userFunction,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_FUNCTION]: LoadingStatus.Success
        }
      };
    }

    case GET_USER_FUNCTION_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_FUNCTION]: LoadingStatus.Failure
        }
      };
    }

    case GET_USER_CATEGORY_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_CATEGORY]: LoadingStatus.Loading
        }
      };
    }

    case GET_USER_CATEGORY_SUCCESS: {
      const userCategory = action.payload;
      return {
        ...state,
        userCategory: userCategory,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_CATEGORY]: LoadingStatus.Success
        }
      };
    }

    case GET_USER_CATEGORY_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_CATEGORY]: LoadingStatus.Failure
        }
      };
    }

    case GET_USER_WORK_AUTHORIZATION_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_WORK_AUTHORIZATION]: LoadingStatus.Loading
        }
      };
    }

    case GET_USER_WORK_AUTHORIZATION_SUCCESS: {
      const userWorkAuthorization = action.payload;
      return {
        ...state,
        userWorkAuthorization: userWorkAuthorization,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_WORK_AUTHORIZATION]: LoadingStatus.Success
        }
      };
    }

    case GET_USER_WORK_AUTHORIZATION_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_USER_WORK_AUTHORIZATION]: LoadingStatus.Failure
        }
      };
    }

    case RESET_WORK_AUTH: {
      return {
        ...state,
        userWorkAuthorization: []
      };
    }

    case UPDATE_DOC_LIST: {
      const { res, index } = action.payload;
      return getStateOnDocumentUpdate(state, res, index);
    }

    case ADD_EMPLOYEE_TAB: {
      return {
        ...state,
        employeeFormConfig: [...state.employeeFormConfig, state.employeeFormConfig[0]],
      };
    }

    case DELETE_EMPLOYEE_TAB: {
      const index = action.payload;
      const newEmployeeConfig = [...state.employeeFormConfig];
      newEmployeeConfig.splice(index, 1);
      return {
        ...state,
        employeeFormConfig: newEmployeeConfig,
      };
    }

    case DUPLICATE_EMPLOYEE_TAB: {
      return {
        ...state,
        employeeFormConfig: [...state.employeeFormConfig, state.employeeFormConfig[0]],
      };
    }

    case ADD_EDUCATION_TAB: {
      return {
        ...state,
        eduFormConfig: [...state.eduFormConfig, state.eduFormConfig[0]],
      };
    }

    case DELETE_EDUCATION_TAB: {
      const index = action.payload;
      const newEduConfig = [...state.eduFormConfig];
      newEduConfig.splice(index, 1);
      return {
        ...state,
        eduFormConfig: newEduConfig,
      };
    }

    case DUPLICATE_EDUCATION_TAB: {
      return {
        ...state,
        eduFormConfig: [...state.eduFormConfig, state.eduFormConfig[0]],
      };
    }

    case GET_EMPLOYEE_CONFIG_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_EMPLOYEE_CONFIG]: LoadingStatus.Loading
        }
      };
    }

    case GET_EMPLOYEE_CONFIG_SUCCESS: {
      const  {id,res}  = action.payload;
      const { employmentHistory, formConfig } = res;
      const newFormConfig = [formConfig];
      if(employmentHistory.length){
        for(let i =1 ; i < employmentHistory.length ; i++){
          newFormConfig.push(formConfig);
        }
    }
      return {
        ...state,
        employeeFormConfig: newFormConfig,
        empFormData: employmentHistory,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_EMPLOYEE_CONFIG]: LoadingStatus.Success
        },
      };
    }

    case GET_EMPLOYEE_CONFIG_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_EMPLOYEE_CONFIG]: LoadingStatus.Failure
        }
      };
    }

    case GET_EDUCATION_CONFIG_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_EDUCATION_CONFIG]: LoadingStatus.Loading
        }
      };
    }

    case GET_EDUCATION_CONFIG_SUCCESS: {
      const  {id,res}  = action.payload;
      const { educationHistory, formConfig } = res;
      const newFormConfig = [formConfig];
      if(id){
        for(let i =1 ; i < educationHistory.length ; i++){
          newFormConfig.push(formConfig);
        }
    }
      return {
        ...state,
        eduFormConfig: newFormConfig,
        eduFormData: educationHistory,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_EDUCATION_CONFIG]: LoadingStatus.Success
        },
      };
    }

    case GET_EDUCATION_CONFIG_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_EDUCATION_CONFIG]: LoadingStatus.Failure
        }
      };
    }

    case POST_EMPLOYEE_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_EMPLOYEE_INFO]: LoadingStatus.Loading
        }
      };
    }

    case POST_EMPLOYEE_INFO_SUCCESS: {
      const  info  = action.payload;
      return {
        ...state,
        empFormData: info,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_EMPLOYEE_INFO]: LoadingStatus.Success
        },
      };
    }

    case POST_EMPLOYEE_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_EMPLOYEE_INFO]: LoadingStatus.Failure
        }
      };
    }

    case POST_EDUCATION_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_EDUCATION_INFO]: LoadingStatus.Loading
        }
      };
    }

    case POST_EDUCATION_INFO_SUCCESS: {
      const  info  = action.payload;
      return {
        ...state,
        eduFormData: info,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_EDUCATION_INFO]: LoadingStatus.Success
        },
      };
    }

    case POST_EDUCATION_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_EDUCATION_INFO]: LoadingStatus.Failure
        }
      };
    }

  }
};
export default userOnboardingReducer;

const getStateOnDocumentUpdate = (state, res, index) => {
  const newState = clone(state);
  const newDocList = newState.userDocumentsList.documentsMapped;
  newDocList[index] = {
    ...newDocList[index],
    documentId: {
      fileName: res?.fileName,
      url: res?.url
    },
    createdAt: moment().format(DateFormatters.ddmmyyyy)
  };
  newState.userDocumentsList.documentsMapped = newDocList;
  return newState;
};