import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import RatingInput from '../../../../../../../../../../atoms/Rating/RatingInput';

const CompetencySkillsItem = (props) => {
    const { id, label, rating, itemIndex, setValues, competencyRatingScale } = props;
    const [ratingValue, setRatingValue] = useState(rating);
    const handleChange = (event) => {
        const value = event.target.value;
        setRatingValue(value);
        setValues((prev) => {
            const updatedValues = [...prev];
            updatedValues[itemIndex].rating = value; 
            return updatedValues;
        });

    };
    return (
        <Box m={1}>
            <RatingInput
                label={label}
                onChange={handleChange}
                value={ratingValue}
                max={competencyRatingScale || 5}
            >
            </RatingInput>
        </Box>
    );
};

export default CompetencySkillsItem;