import React from 'react';
import ReactLoading from 'react-loading';

export default function Loading({
  loading = false,
  type = 'bubbles',
  color = '#3d3d3d',
  children=''
}) {
  return loading ? (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: '#fff',
        opacity: '0.5',
        zIndex: 1000
      }}
    >
      <ReactLoading type={type} color={color} />
    </div>
  ) : (
    children
  );
}
