import React, { useMemo, useState } from 'react';
import FormEdit from '../../common/Form/FormEdit';
import { Box, Divider, Grid, Typography } from '@mui/material';
import ComponentFormConfig from './ComponentForm/ComponentFormConfig';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SalaryStructureServices } from '../../../../services/salaryServices';
import { FormFieldNames } from '../../Organization/constants/onboarding.constants';
import BasicForm from './ComponentForm/BasicForm';
import PayrollAssignview from './PayrollAssignview';
import { getComponentsDetails } from '../../../../redux/payrollAssignment/action';
import { resetComponentsOnStructureChange } from '../../../../redux/payrollAssignment/action';
import { isEmpty } from 'lodash';
import StyledTypography from '../../../../atoms/StyledTypography/StyledTypography';
import TotalCalculationView from './ComponentForm/TotalCalculationView';
import { ComponentInputType } from '../../../../constants/common';
import ActionHeader from '../../Organization/ActionHeader/ActionHeader';
import { getSaveButtonText } from '../../Organization/utils/onboarding.util';
import BenefitsView from './ManagePayrollTab/BenefitsView';

const PayrollAssign = (props) => {
  const {
    formConfig,
    payStrInfo,
    setPayStrInfo,
    setAdditionComponents,
    setDeductionComponents,
    viewMode,
    setBasicComponents,
    basicComponents,
    userId,
    additionComponents,
    deductionComponents,
    totalAdditions,
    totalDeductions,
    payrollEditDisabled,
    payrollError,
    setPayrollError,
    setIsPayrollChange,
    employeeBenifits,

    isPublished,
    onEditClick,
    onPublishClick,
    isEditDisabled,
    handleDiscard,
    disabledSave,
    labelText,
  } = props;
  const [libraryOptions, setLibraryOptions] = useState([]);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { basicErrors, additionErrors, deductionErrors } = payrollError;

  const handleDropdownClick = (name) => {
    switch (name) {
      case FormFieldNames.Assignpaystr: {
        return new SalaryStructureServices()
          .getAllSalaryStructureList(
            currentUserRoleId,
            searchParams.get('q'),
            true,
            true
          )
          .then((res) => {
            setLibraryOptions(res);
            return true;
          });
      }
    }
  };
  const getDropdownOptions = (name) => {
    switch (name) {
      case FormFieldNames.Assignpaystr: {
        return libraryOptions;
      }
    }
  };

  const handleChangeAssignment = (event) => {
    const value = event.target.value;
    const key = event.target.name;
    dispatch(resetComponentsOnStructureChange());
    dispatch(getComponentsDetails(value.id, userId));
    setPayStrInfo((prev) => ({
      ...prev,
      [key]: value,
    }));
    setIsPayrollChange(true);
  };

  const formFields = useMemo(() => {
    return formConfig.reduce((acc, item) => {
      const { fieldRefName } = item;
      let temp = { ...item };
      switch (fieldRefName) {
        case FormFieldNames.Assignpaystr: {
          temp = { ...temp, onChange: handleChangeAssignment };
          break;
        }
        default: {
          break;
        }
      }
      acc.push(temp);
      return acc;
    }, []);
  }, [formConfig]);
  return (
    <>
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          editButtonText={getSaveButtonText(viewMode, isPublished)}
          onEditClick={onEditClick}
          showPublish={!isPublished && !isEditDisabled}
          onPublishClick={onPublishClick}
          showDiscard={!viewMode && !isEditDisabled}
          onClickDiscard={handleDiscard}
          disabledSave={disabledSave}
          labelText={labelText}
          showSave={!isEditDisabled}
        />
      </Box>
      {!viewMode ? (
        <>
          <FormEdit
            list={formFields}
            formInfo={isEmpty(payStrInfo) ? {} : payStrInfo}
            setFormData={setPayStrInfo}
            handleDropdownClick={handleDropdownClick}
            getDropdownOptions={getDropdownOptions}
            hideFields={false}
          />
          <Divider sx={{ mt: 3 }} />
          {!isEmpty(basicComponents) && (
            <TotalCalculationView
              totalDeductions={totalDeductions}
              totalAdditions={totalAdditions}
              basicComponents={basicComponents}
              setBasicComponents={setBasicComponents}
              viewMode={false}
              payrollEditDisabled={payrollEditDisabled}
              basicErrors={basicErrors}
              setPayrollError={setPayrollError}
              setIsPayrollChange={setIsPayrollChange}
            />
          )}
          <Divider sx={{ mt: 3, mb: 1 }} />
          <Grid
            container
            rowSpacing={3}
            columnSpacing={8}
            pr={8}
            justifyContent="center"
          >
            {additionComponents?.length ? (
              <Grid item md={6} xs={12}>
                <Typography mt={2} variant="subtitle2">
                  Addition Components
                </Typography>
                {additionComponents?.map((item, index) => {
                  const errorForComponent = additionErrors?.find(
                    (error) => error.id === item.id
                  );
                  return (
                    <ComponentFormConfig
                      setFormInfo={setAdditionComponents}
                      formInfo={additionComponents[index]}
                      key={index}
                      index={index}
                      payrollEditDisabled={payrollEditDisabled}
                      readOnly={
                        item?.inputSourceCode !== ComponentInputType.Onetime
                      }
                      errors={errorForComponent}
                      setPayrollError={setPayrollError}
                      setIsPayrollChange={setIsPayrollChange}
                    />
                  );
                })}
              </Grid>
            ) : (
              <Grid item md={6} xs={12}>
                {''}
              </Grid>
            )}
            {deductionComponents?.length ? (
              <Grid item md={6} xs={12}>
                <Typography mt={2} variant="subtitle2">
                  Deduction Components
                </Typography>
                {deductionComponents?.map((item, index) => {
                  const errorForComponent = deductionErrors?.find(
                    (error) => error.id === item.id
                  );
                  return (
                    <ComponentFormConfig
                      setFormInfo={setDeductionComponents}
                      formInfo={deductionComponents[index]}
                      key={index}
                      index={index}
                      payrollEditDisabled={payrollEditDisabled}
                      readOnly={
                        item?.inputSourceCode !== ComponentInputType.Onetime
                      }
                      errors={errorForComponent}
                      setPayrollError={setPayrollError}
                      setIsPayrollChange={setIsPayrollChange}
                    />
                  );
                })}
              </Grid>
            ) : (
              <Grid item md={6} xs={12}>
                {''}
              </Grid>
            )}
          </Grid>
          <Box mt={2}>
            {employeeBenifits?.length ? (
              <BenefitsView employeeBenifits={employeeBenifits} />
            ) : (
              ''
            )}
          </Box>
        </>
      ) : (
        <PayrollAssignview
          formFields={formFields}
          payStrInfo={payStrInfo}
          basicComponents={basicComponents}
          additionComponents={additionComponents}
          deductionComponents={deductionComponents}
          totalAdditions={totalAdditions}
          totalDeductions={totalDeductions}
          employeeBenifits={employeeBenifits}
        />
      )}
    </>
  );
};

export default PayrollAssign;
