import React from 'react';
import {
  HighlightOff,
  NoteAddOutlined,
  FactCheckOutlined,
  ScheduleOutlined,
  InsertDriveFileOutlined,
} from '@mui/icons-material';
import BtnDropDown from '../../../../../atoms/Buttons/BtnDropDown';
import { useSelector } from 'react-redux';
import { permissionsObj } from '../../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import { InterviewSchedule, InterviewStatusCode, JobInterviewStatus, JobStatusCode } from '../../../Constant/constant';

const DropDownActionForCandidate = (props) => {

  const { checkedItems, handleAddJob, handleInterview, jobId, handleInterviewFeedback, isInterviewer,
    interviewStatusCode, jobInterviewStatus, jobStatusCode:jobStatusCodeReceived } = props;
  const { recrutingPermissions } = useSelector((state) => state.modulePermissions);
  const candidateBtnOption = [
    {
      title: 'Add to Job',
      icon: <NoteAddOutlined sx={{ width: 20, height: 20 }} />,
      handleClick: handleAddJob,
      disabled: checkedItems.length > 1
    },
    {
      title: 'Schedule Interview',
      icon: <ScheduleOutlined sx={{ width: 20, height: 20 }} />,
      handleClick: handleInterview,
      disabled: !(jobId && checkedItems.length === 1 &&
         recrutingPermissions?.[InterviewSchedule]?.[permissionsObj.allowView] &&
         !isInterviewer &&
         (interviewStatusCode ? (interviewStatusCode === null || interviewStatusCode === InterviewStatusCode.NextRound || interviewStatusCode === InterviewStatusCode.OnHold) : true) &&
         jobStatusCodeReceived!==JobStatusCode.Closed)
    },
    {
      title: 'Interview Feedback',
      icon: <ScheduleOutlined sx={{ width: 20, height: 20 }} />,
      handleClick: handleInterviewFeedback,
      disabled: !(jobId && checkedItems.length === 1 && isInterviewer && jobInterviewStatus === JobInterviewStatus.InterviewSchedule
        && !interviewStatusCode)
    },
    {
      title: 'Approve Offer (Coming soon)',
      icon: <FactCheckOutlined sx={{ width: 20, height: 20 }} />,
      disabled: true
    },
    {
      title: 'Release Offer (Coming soon)',
      icon: <FactCheckOutlined sx={{ width: 20, height: 20 }} />,
      disabled: true
    },
    {
      title: 'Request Documents (Coming soon)',
      icon: <InsertDriveFileOutlined sx={{ width: 20, height: 20 }} />,
      disabled: true
    },
    {
      title: 'Discard (Coming soon)',
      icon: <HighlightOff sx={{ width: 20, height: 20 }} />,
      disabled: true
    }
  ];
  return (
    <BtnDropDown BtnOptions={candidateBtnOption} selectedOption={0} disabled={!checkedItems.length}
    />
  );
};

export default DropDownActionForCandidate;