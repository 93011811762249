import { Box, Typography } from '@mui/material';
import React from 'react';

const CompetencySkillsFormView = (props) => {
	const { heading, values } = props;

	return (
		<>
			<Typography style={{ fontWeight: 800 }} mt={2}>{heading}</Typography>
			<Box>
				{values.map((item, index) => {
					const { rating, name, id } = item;
					return (
						<Box key={index} display='flex' justifyContent={'space-between'} alignItems='center'>
							{name && (
								<Typography variant='body2' color={'neutral.dark60'}>
									{name}
								</Typography>
							)}
							{rating && (
								<Typography variant='body2' color={'neutral.dark100'} mt={1} >
									{rating}
								</Typography>
							)}
						</Box>
					);
				})}
			</Box>
		</>

	);
};


export default CompetencySkillsFormView;