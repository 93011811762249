import React from 'react';
import { Chip } from '@mui/material';

const ChipTag = (props) => {
    const { values } = props;
    return (
        values?.map((item, index) => <Chip key={index} label={item?.name} sx={{ marginLeft: 1, marginBottom: 1 }} />) || ''
    );
};

export default ChipTag;