import { createContext } from 'react';

export const AuthContext = createContext({
  isLoggedIn: false,
  login: (loginData) => {
    return loginData;
  },
  logout: () => {
    localStorage.setItem('userData', '');
    localStorage.setItem('asProfile', '');
  },
});
