import React, { useState } from 'react';
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import RoleConfigurationItem from './roleConfigurationItem';
import ClaimModalComponent from '../ClaimConfig/PermissionType/ClaimConfigModel/claim-modal.component';
import PerformanceConfigModal from '../performance-config/performance-config-modal.component';

export const SubModuleConfigure = (props) => {
  const {
    content,
    setModulePermissions,
    parentName,
    roleId,
    modulePermissions,
    moduleId,
    roleName,
  } = props;

  switch (parentName) {
    case 'claims-3.2': {
      return (
        <ClaimModalComponent
          roleId={roleId}
          moduleId={moduleId}
          roleName={roleName}
          content={content}
        />
      );
    }
    case 'performance-app': {
      return (
        <PerformanceConfigModal
          roleId={roleId}
          moduleId={moduleId}
          roleName={roleName}
          content={content}
        />
      );
    }
    default: {
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2Bold">Module</Typography>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <Box
                    width={200}
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <Typography variant="body2Bold">
                      Create, Edit And View Access
                    </Typography>
                  </Box>
                  <Box
                    width={200}
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <Typography variant="body2Bold">
                      Edit And View Access
                    </Typography>
                  </Box>
                  <Box
                    width={200}
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <Typography variant="body2Bold">View Access</Typography>
                  </Box>
                  <Box
                    width={200}
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <Typography variant="body2Bold">Disabled</Typography>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          {content?.map((item) => {
            const { name, id, permissions, displayName } = item;
            return (
              <RoleConfigurationItem
                key={id}
                id={id}
                optionName={displayName}
                permissions={permissions}
                setModulePermissions={setModulePermissions}
                parentName={parentName}
                roleId={roleId}
                modulePermissions={modulePermissions}
                childName={name}
              />
            );
          })}
        </Table>
      );
    }
  }
};
