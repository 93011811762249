import Joi from 'joi-browser';
import { companyInfoLabels } from '../components/OnBoarding/Organization/utils/onboarding.util';
export const validateForm = (inputs,schema) => {
    const result = Joi.validate(inputs, 
      schema, { abortEarly: false });
    const { error } = result;
    if (!error) {
      return false;
    } else {
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        const message = item.message;
        errorData[name] = message.replace(name, companyInfoLabels[name]) ;
      }
      
      return errorData;
    }
  };

  export const validateEmail = (email) => {
    const regex =  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };

// export const validateInputs = (required, type) => {
  
// }