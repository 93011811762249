import React from 'react';
import { FormFieldTypes } from '../../../constants/common';
import {
  AccessTimeOutlined,
  AddBoxOutlined,
  AutofpsSelectOutlined,
  CalendarMonthOutlined,
  CalendarTodayOutlined,
  CheckBoxOutlined,
  DateRangeOutlined,
  DriveFolderUploadOutlined,
  EventNoteOutlined,
  FileUploadOutlined,
  FormatAlignJustifyOutlined,
  FormatColorTextOutlined,
  FormatListBulletedOutlined,
  FunctionsOutlined,
  InsertInvitationOutlined,
  NumbersOutlined,
  PlaylistAddOutlined,
  PreviewOutlined,
  ThreePOutlined,
  AttachMoneyOutlined
} from '@mui/icons-material';
import { grey } from '@mui/material/colors';

export const getIcon = (fieldType, style = 'none') => {
  switch (fieldType) {
    case FormFieldTypes.Text: {
      return <FormatColorTextOutlined />;
    }
    case FormFieldTypes.MutliSelect: 
    case FormFieldTypes.TRF: {
      return <AddBoxOutlined />;
    }
    case FormFieldTypes.SingleSelect: {
      return <FormatListBulletedOutlined />;
    }
    case FormFieldTypes.Date: {
      return <CalendarTodayOutlined />;
    }
    case FormFieldTypes.DateTime: {
      return <InsertInvitationOutlined />;
    }
    case FormFieldTypes.Time: {
      return <AccessTimeOutlined />;
    }
    case FormFieldTypes.File: {
      return <FileUploadOutlined />;
    }
    case FormFieldTypes.CheckBox: {
      return <CheckBoxOutlined />;
    }
    case FormFieldTypes.Number: {
      return <NumbersOutlined sx={style} />;
    }
    case FormFieldTypes.MultiLineText: {
      return <FormatAlignJustifyOutlined />;
    }
    case FormFieldTypes.Formula: {
      return <FunctionsOutlined sx={style} />;
    }
    case FormFieldTypes.DateRange: {
      return <DateRangeOutlined />;
    }
    case FormFieldTypes.AutoPopulate:
    case FormFieldTypes.ProRateField: {
      return <PreviewOutlined />;
    }
    case FormFieldTypes.MultiFileUpload: {
      return <DriveFolderUploadOutlined />;
    }
    case FormFieldTypes.Month: {
      return <CalendarMonthOutlined />;
    }
    case FormFieldTypes.Year: {
      return <CalendarMonthOutlined />;
    }
    case FormFieldTypes.UserBasedSSL: {
      return <PlaylistAddOutlined />;
    }
    case FormFieldTypes.UserBasedField: {
      return <ThreePOutlined />;
    }
    case FormFieldTypes.EntitlementAutoPopulate: {
      return <AutofpsSelectOutlined />;
    }
    case FormFieldTypes.CurrencyExchangeField: {
      return <AttachMoneyOutlined />;
    }
  }
};
