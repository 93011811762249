import { Box, Grid } from '@mui/material';
import React, { useState, forwardRef } from 'react';
import AccordionTabs from '../../../../../../atoms/Accordian/Accordian';
import { ApprovalWorkflowHeaders } from '../../../../../Performance/Goals/common/constants';
import AppWorkStep from './AppWorkStep';
const ApprovalWorkflowSteps = (props) => {
  const {
    steps,
    handleDropdownClick,
    getDropdownOptions,
    formInfo,
    formStepsValidationErrors,
    setformStepsValidationErrors,
    formId
  } = props;
  return (
    <Grid item md={11}>
      {steps.map((step, index) => (
        <div key={index}>
          <AccordionTabs
            key={index}
            title={`${ApprovalWorkflowHeaders.Steps} ${index + 1}`}
            tabDetails={
              <Box>
                <AppWorkStep
                  formConfigSteps={step}
                  stepsLength={steps.length}
                  index={index}
                  handleDropdownClick={handleDropdownClick}
                  getDropdownOptions={getDropdownOptions}
                  formInfo={formInfo[index]}
                  formStepsValidationErrors={formStepsValidationErrors[index]}
                  setformStepsValidationErrors={setformStepsValidationErrors}
                  formId={formId}
                />
              </Box>
            }
          />
        </div>
      ))}
    </Grid>
  );
};

export default React.memo(forwardRef(ApprovalWorkflowSteps));
