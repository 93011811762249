import { ReportsModuleId } from '../../components/OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import { OnboardingServices } from '../../services/onboarding';
import { RoleService } from '../../services/roles';
import {
  GET_ROLES_CONFIG_REQUEST,
  GET_ROLES_CONFIG_SUCCESS,
  RESET_ROLES_CONFIG,
  UPDATE_ROLE_PERMISSIONS_FAILURE,
  UPDATE_ROLE_PERMISSIONS_REQUEST,
  UPDATE_ROLE_PERMISSIONS_SUCCESS,
} from './actionTypes';

const getRolesConfigRequest = () => ({
  type: GET_ROLES_CONFIG_REQUEST,
});

const getRolesConfigSuccess = (payload) => ({
  type: GET_ROLES_CONFIG_SUCCESS,
  payload,
});

const getRolesConfigFailure = () => ({
  type: GET_ROLES_CONFIG_SUCCESS,
});

export const resetRolesConfig = () => ({
  type: RESET_ROLES_CONFIG,
});

const updateRolePermissionsRequest = () => ({
  type: UPDATE_ROLE_PERMISSIONS_REQUEST,
});

const updateRolePermissionsSuccess = () => ({
  type: UPDATE_ROLE_PERMISSIONS_SUCCESS,
});

const updateRolePermissionsFailure = () => ({
  type: UPDATE_ROLE_PERMISSIONS_FAILURE,
});

export const getRolesConfig = (roleId) => async (dispatch) => {
  dispatch(getRolesConfigRequest());
  new OnboardingServices()
    .getRolesConfig(roleId)
    .then((res) => {
      dispatch(getRolesConfigSuccess(res));
    })
    .catch(() => {
      dispatch(getRolesConfigFailure());
    });
};

export const updateRolePermissions =
  (roleId, permissions, role_name) => async (dispatch) => {
    dispatch(updateRolePermissionsRequest());
    const shouldSetNewData = !permissions.some(
      (permission) => permission.moduleId === ReportsModuleId && permission.allowView
    );
    if (permissions.some(
      (permission) => permission.moduleId === ReportsModuleId) && shouldSetNewData) {
      new RoleService().saveReports({ reports: [], roleId });
    }
    new OnboardingServices()
      .updateRolePermissions({ roleId, permissions, role_name })
      .then(() => {
        dispatch(updateRolePermissionsSuccess());
      })
      .catch(() => {
        dispatch(updateRolePermissionsFailure());
      });
  };
