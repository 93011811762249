import React, { useEffect, useMemo, useState } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useDebounce } from 'usehooks-ts';
import useDidMountEffect from '../../customHooks/useDidMountEffect';
import { useSearchParams } from 'react-router-dom';
import { DebounceTimer } from '../../constants/common';

export default function SearchBar(props) {

  const { handleSearch = () => null } = props;
  const [searchInput, setSearchInput] = useState('');

  const debouncedVal = useDebounce(searchInput, DebounceTimer);
  const [searchParam, setSearchParam] = useSearchParams();

  const handleChange = (event) => {
    setSearchInput(event.target.value);
    setSearchParam((prev) => {
      prev.set('q', event.target.value);
      return prev;
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch(searchParam.get('q'));
    }
  };

  const handleClear = () => {
    searchParam.delete('q');
    setSearchParam(searchParam);
    setSearchInput('');
  };

  useDidMountEffect(() => {
    handleSearch(searchParam.get('q'));
  }, [debouncedVal]);

  useEffect(()=>{
    if(!searchParam.get('q')){
      setSearchInput('');
    }
  },[searchParam]);

  return (
    <Paper
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <IconButton sx={{ p: '10px' }} aria-label="menu" onClick={handleSearch}>
        <SearchIcon />
      </IconButton>
      <InputBase
        onKeyDown={handleKeyPress}
        sx={{ ml: 1, flex: 1 }}
        placeholder='Search'
        inputProps={{ 'aria-label': 'search google maps' }}
        value={searchInput}
        onChange={handleChange}
        name="searchName"
      />
      <IconButton type='button' sx={{ p: '10px' }} aria-label='search' onClick={handleClear}>
        <CloseIcon />
      </IconButton>
    </Paper>
  );
}

