import { Box, Typography } from '@mui/material';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React from 'react';
import { LightTooltip } from '../../../atoms/Tooltip/LightTooltip';
import { InfoOutlined } from '@mui/icons-material';
import { getDate, getFormattedDate, shortcutsItems } from '../../../utils/formatter';
import FieldView from '../FieldView/FieldView';
import moment from 'moment';

const DateRangeForm = (props) => {
  const {
    id,
    defaultValue,
    placeholder,
    labelText,
    size,
    hiddenLabel,
    fullWidth,
    required,
    onChange,
    name,
    value,
    errorMessage,
    maxDate,
    minDate,
    disabled,
    copyTo,
    isReadOnly,
    infoIcon,
    disablePast,
    disableFuture,
  } = props;

  const createQuarterShortcut = (year, quarter) => {
    const label = `Q${quarter} ${year}`;
    const getValue = () => {
      const startOfQuarter = moment().year(year).quarter(quarter).startOf('quarter');
      const endOfQuarter = moment().year(year).quarter(quarter).endOf('quarter');
      return [startOfQuarter, endOfQuarter];
    };
    return { label, getValue };
  };

  const today = moment();
  const currentYear = today.year();
  const currentQuarter = today.quarter();
  
  const shortcutsItems = [];

  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    for (let quarter = 4; quarter >= 1; quarter--) {
      if (year === currentYear && quarter > currentQuarter) continue;
      shortcutsItems.push(createQuarterShortcut(year, quarter));
    }
  }

  if (isReadOnly) {
    return (
      <FieldView
        labelText={labelText}
        labelValue={
          value?.length > 0
              ? 
                `${getFormattedDate(value?.[0], 'YYYY-MM-DD', 'DD/MM/YYYY')} - 
                ${getFormattedDate(value?.[1], 'YYYY-MM-DD', 'DD/MM/YYYY')}`
                
              : [null, null]
        }
      />
    );
  }

  return (
    <>
      {labelText && (
        <Typography
          variant="body2"
          color={`${disabled ? 'neutral.light40' : 'neutral.dark80'}`}
          mb={1}
        >
          {labelText}
          {required && (
            <Box color="accentSecondary.main" sx={{ display: 'inline-block' }}>
              *
            </Box>
          )}
          {infoIcon && (
            <LightTooltip title={infoIcon} placement="top" arrow>
              <Box color="primary.main" sx={{ display: 'inline-block' }}>
                <InfoOutlined
                  sx={{ width: 14, height: 14, ml: '4px', cursor: 'pointer' }}
                />
              </Box>
            </LightTooltip>
          )}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
        <DateRangePicker
          format="DD/MM/YYYY"
          disabled={disabled}
          disableFuture={disableFuture}
          disablePast={disablePast}
          maxDate={maxDate}
          minDate={minDate}
          value={
            value?.length > 0
              ? [
                  getDate(value?.[0], 'YYYY-MM-DD'),
                  getDate(value?.[1], 'YYYY-MM-DD'),
                ]
              : [null, null]
          }
          onChange={(value) => onChange(value, name)}
          calendars={0}
          localeText={{ start: 'Start Date', end: 'End Date' }}
          slotProps={{
            actionBar: { actions: [] },
            textField: {
              size: 'small',
              variant: 'outlined',
              onKeyDown: (event) => event.preventDefault(),
              error: errorMessage,
              helperText: errorMessage,
            },
            shortcuts: {
              items: shortcutsItems
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
};

export default DateRangeForm;
