export const GET_COMPONENTS_CONFIG_REQUEST = 'GET_COMPONENTS_CONFIG_REQUEST';
export const GET_COMPONENTS_CONFIG_SUCCESS = 'GET_COMPONENTS_CONFIG_SUCCESS';
export const GET_COMPONENTS_CONFIG_FAILURE = 'GET_COMPONENTS_CONFIG_FAILURE';

export const GET_COMPONENTS_DETAILS_REQUEST = 'GET_COMPONENTS_DETAILS_REQUEST';
export const GET_COMPONENTS_DETAILS_SUCCESS = 'GET_COMPONENTS_DETAILS_SUCCESS';
export const GET_COMPONENTS_DETAILS_FAILURE = 'GET_COMPONENTS_DETAILS_FAILURE';

export const RESET_COMPONENTS = 'RESET_COMPONENTS';
export const RESET_COMPONENTS_ON_STRUCTURE_CHANGE = 'RESET_COMPONENTS_ON_STRUCTURE_CHANGE';


export const POST_COMPONENTS_INFO_REQUEST = 'POST_COMPONENTS_INFO_REQUEST';
export const POST_COMPONENTS_INFO_SUCCESS = 'POST_COMPONENTS_INFO_SUCCESS';
export const POST_COMPONENTS_INFO_FAILURE = 'POST_COMPONENTS_INFO_FAILURE';

export const UPDATE_COMPONENTS_INFO_REQUEST = 'UPDATE_COMPONENTS_INFO_REQUEST';
export const UPDATE_COMPONENTS_INFO_SUCCESS = 'UPDATE_COMPONENTS_INFO_SUCCESS';
export const UPDATE_COMPONENTS_INFO_FAILURE = 'UPDATE_COMPONENTS_INFO_FAILURE';

export const GET_UPCOMING_COMPONENTS_DETAILS_REQUEST = 'GET_UPCOMING_COMPONENTS_DETAILS_REQUEST';
export const GET_UPCOMING_COMPONENTS_DETAILS_SUCCESS = 'GET_UPCOMING_COMPONENTS_DETAILS_SUCCESS';
export const GET_UPCOMING_COMPONENTS_DETAILS_FAILURE = 'GET_UPCOMING_COMPONENTS_DETAILS_FAILURE';