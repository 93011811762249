import API from './api';

export class IntegrationService extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL + '/external-integration/' || '';
  constructor() {
    super(IntegrationService.baseURL);
  }
  getEntity = async () => (
    this.responsify(await this.instance.get('get-entity'))
  );

   getTableColumn = async (fkEntityId) => (
    this.responsify(await this.instance.get('get-table',{params:{fkEntityId}}))
  );

  getImportMappingColumns = async () => (
    this.responsify(await this.instance.get('get-mapping-columns'))
  );

  getListAll = async (roleId) => (
    this.responsify(await this.instance.get('list-all', {params: {roleId}}))
  );

  updateMapping = async (body) => (
    this.responsify(await this.instance.put('update-mapping', body))
  );
  
  getDetails = async (fkEntityId) => (
    this.responsify(await this.instance.get('get-details',{params:{fkEntityId}}))
  );

  getImportValueById = async (pkId) => (
    this.responsify(await this.instance.get('get-import-value', {params: {pkId}}))
  );
}