import { Box, styled } from '@mui/material';

export const UploadBox = styled(Box)(({ theme, errorMessage }) => ({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  border: errorMessage ? `1px solid ${theme.palette.error.main}` : '1px solid #95A09E',

  '& input[type=file]': {
    position: 'absolute',
    left: '0',
    top: '0',
    right: '0',
    bottom: '0',
    width: '100%',
    opacity: '0',
    cursor: 'pointer',
  },
  '& button': {
    border: 'none',
    borderRadius: '4px',
    background: 'none',
    padding: '8px 12px',
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer',
  },
}));
