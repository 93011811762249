import { Box, Grid, Paper } from '@mui/material';
import React from 'react';
import FieldView from '../../../../molecules/FieldView/FieldView';
import { getTeamBuildingFields } from '../../../../form-builder/utils/team-building-utils';
import StyledButton from '../../../../OnBoarding/Organization/ActionHeader/ActionHeader.styled';
import { CalculateOutlined } from '@mui/icons-material';
import { formatCurrency } from '../../../../../utils/common.util';

const TeamBuildingView = (props) => {
  const { departementFomdata, viewDistribution } = props;
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ flexGrow: 1, p: 4, my: 1 }}>
        <Grid
          item
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <StyledButton
            color="success"
            sx={{ textTransform: 'capitalize' }}
            onClick={viewDistribution}
            className="ve-action-btn"
          >
            <CalculateOutlined
              sx={{ width: '24px', height: '24px', mr: '5px' }}
            />
            View Distribution
          </StyledButton>
        </Grid>
        {departementFomdata?.map((item) => {
          return (
            <>
              <Grid container rowSpacing={3} columnSpacing={12} pr={8} my={1}>
                {getTeamBuildingFields().map(function (data) {
                  return (
                    <>
                      <Grid item md={3}>
                        <FieldView
                          labelText={data.fieldLabel}
                          labelValue={
                            item?.[data.fieldRefName]?.id
                              ? item?.[data.fieldRefName]?.name
                              : data?.prefix
                              ? `${data?.prefix} ${
                                  item?.[data.fieldRefName]
                                    ? formatCurrency(Number(item?.[data.fieldRefName]))
                                    : '0'
                                }`
                              : item?.[data.fieldRefName]
                          }
                          required={data.isRequired}
                        />
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </>
          );
        })}
      </Paper>
    </Box>
  );
};

export default TeamBuildingView;
