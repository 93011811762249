import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import ModalDialog from '../../../../atoms/Modal/ModalDialog';
import BtnDropDown from '../../../../atoms/Buttons/BtnDropDown';
import {
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../../../constants/common';
import {
  currentDate,
  getFormattedValues,
  isDateBefore,
} from '../../../../utils/formatter';
import { showToast } from '../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../OnBoarding/Organization/constants/onboarding.constants';
import { getAllUserBenefitsList } from '../../../../redux/benefits/action';
import { CompBenServices } from '../../../../services/compBen';
import { CurrencyExchangeOutlined } from '@mui/icons-material';
import { CommonSevice } from '../../../../services/common';

const UserBenefitModalMap = (props) => {
  const {
    checkedItems,
    searchQuery,
    currentUserRoleId,
    benefitId,
    selectedDate,
  } = props;
  const [showModal, setshowModal] = useState(false);
  const [context, setContext] = useState('');
  const [postActionLoading, setPostActionLoading] = useState(-1);
  const [optionList, setOptionList] = useState([]);
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const dispatch = useDispatch();

  useEffect(async () => {
    await new CommonSevice()
      .getDropDown('userBenefitsDashboard')
      .then((res) => {
        setOptionList(res);
      });
  }, []);

  const handleFundClick = () => {
    setContext('fund');
    setshowModal(true);
  };

  const handleSaveClick = () => {
    setPostActionLoading(LoadingStatus.Loading);
    new CompBenServices()
      .postRemainingBalance(
        {
          userEntitlementIds: checkedItems.map((item) => Number(item.id)),
        },
        currentUserRoleId
      )
      .then(() => {
        setPostActionLoading(LoadingStatus.Success);
        dispatch(
          getAllUserBenefitsList(
            currentUserRoleId,
            benefitId,
            selectedDate[0]
              ? getFormattedValues('YYYY-MM-DD', selectedDate[0])
              : null,
            selectedDate[1]
              ? getFormattedValues('YYYY-MM-DD', selectedDate[1])
              : null,
            searchQuery
          )
        );
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.update),
          ToastThemes.success
        );
      })
      .catch((err) => {
        setPostActionLoading(LoadingStatus.Failure);
        showToast(err?.response?.data?.message, ToastThemes.error);
      });
  };

  const modalMap = {
    fund: {
      title: title,
      description: desc,
      primaryAction: 'Save',
      onClickConfirm: () => handleSaveClick(),
      severity: 'success',
      maxWidth: 'md',
      fullWidth: true,
    },
  };

  const DropDownOption = useMemo(() => {
    return optionList.map((item) => {
      const { id, name, modalTitle, modalDescription } = item;
      switch (id) {
        case 'FUND': {
          setTitle(modalTitle);
          setDesc(modalDescription);
          return {
            id: 1,
            title: name,
            icon: <CurrencyExchangeOutlined sx={{ width: 20, height: 20 }} />,
            handleClick: handleFundClick,
            disabled:
              !checkedItems.length ||
              checkedItems.some(
                (item) => item.entitlementActionStatus !== null
              ) ||
              !checkedItems.every((item) =>
                isDateBefore(item.endDate, currentDate)
              ),
          };
        }
      }
    });
  }, [optionList, checkedItems]);

  return (
    <>
      <CircularLoader show={postActionLoading === LoadingStatus.Loading} />
      <BtnDropDown
        BtnOptions={DropDownOption}
        selectedOption={0}
        disabled={!checkedItems.length}
      />
      <ModalDialog
        severity={modalMap[context]?.severity}
        title={modalMap[context]?.title}
        listComponent={modalMap[context]?.listComponent}
        description={modalMap[context]?.description}
        primaryAction={modalMap[context]?.primaryAction}
        secondaryAction="Cancel"
        showModal={showModal}
        onClickClose={() => setshowModal(false)}
        onClickConfirm={modalMap[context]?.onClickConfirm}
        maxWidth={modalMap[context]?.maxWidth}
        fullWidth={modalMap[context]?.fullWidth}
      />
    </>
  );
};

export default UserBenefitModalMap;
