export const GoalFormHeaders = {
    GoalWeightage: 'Goals Weightage:',
    Competency: 'Competency:',
    KRA: 'MBO:',
    Skills: 'Skills'
};

export const stringSubstitute = '{substitute}';
export const GoalToastMessages = {
    target: `Total weightage is ${stringSubstitute}.`,
    note: 'Total KRA Weightage cannot be less than or more than 100.',
    competencyNote: 'Total Competency Weightage cannot be less than or more than 100.'

};
export const GoalHeader = 'Goal: ';
export const AccessAllGoals = 'access-all-goals';
export const PerformanceDashBoard = 'performance';
export const CreateGoal = 'add-new-goal';
export const ViewGoal = 'goals';
export const Appraisal = 'appraisal';
export const PerformanceChild = 'performance-goals-and-appraisal';


export const AppraisalStatus = {
    Approved: 6,
    Activated: 3,
    Submitted: 4,
    ReSubmitted: 5,
    Rejected: 8,
    SendBack: 9,
    Pending: 10,
    Deactivated:11,
};

export const ApprovalWorkflowHeaders = {
    Steps:'Step'
};
