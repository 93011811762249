import { CandidateDetails, JobRequisition, OfferApprover } from '../Constant/constant';

export const recruitingTabs =  [
    {
      name: JobRequisition,
      path: `/recruiting/${JobRequisition}`,
      label: 'Job Requisition',
      title: 'Job Requisition',
      show: false
    },
    {
      name: CandidateDetails,
      path: `/recruiting/${CandidateDetails}`,
      label: 'Candidate Details',
      title: 'Candidate Details',
      show: false
    },
    // {
    //   name: OfferApprover,
    //   path: `/recruiting/${OfferApprover}`,
    //   label: 'Offer Approval',
    //   title: 'Offer Approval',
    //   show: false
    // }
];