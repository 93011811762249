import {
  GET_REIMBURSEMENT_DATA,
  SET_CLAIM_DATA,
  SET_CURRENT_USER_INFORMATION,
} from '../../actionTypes';

const initialState = {
  counter: 0,
  reimbursementList: [],
  currentUserType: 'Employee',
  claimData:{}
};

const claimsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REIMBURSEMENT_DATA:
      return {
        ...state,
        reimbursementList: action.payload,
      };

    case SET_CURRENT_USER_INFORMATION:
      return {
        ...state,
        currentUserType: action.payload,
      };

      case SET_CLAIM_DATA:
      return {
        ...state,
        claimData: action.payload,
      };

    default:
      return state;
  }
};

export default claimsReducer;
