import { isEmpty } from 'lodash';
import { APIRequestStatus, ComponentInputType, ComponentType, LoadingStatus } from '../../constants/common';
import { GET_COMPONENTS_DETAILS_FAILURE, GET_UPCOMING_COMPONENTS_DETAILS_FAILURE, GET_UPCOMING_COMPONENTS_DETAILS_REQUEST, GET_UPCOMING_COMPONENTS_DETAILS_SUCCESS } from './actionTypes';
import { POST_COMPONENTS_INFO_REQUEST } from './actionTypes';
import { POST_COMPONENTS_INFO_FAILURE } from './actionTypes';
import { UPDATE_COMPONENTS_INFO_SUCCESS } from './actionTypes';
import { UPDATE_COMPONENTS_INFO_FAILURE } from './actionTypes';
import { UPDATE_COMPONENTS_INFO_REQUEST } from './actionTypes';
import { POST_COMPONENTS_INFO_SUCCESS } from './actionTypes';
import { RESET_COMPONENTS } from './actionTypes';
import { GET_COMPONENTS_DETAILS_SUCCESS } from './actionTypes';
import { GET_COMPONENTS_DETAILS_REQUEST } from './actionTypes';
import { GET_COMPONENTS_CONFIG_FAILURE, GET_COMPONENTS_CONFIG_REQUEST, GET_COMPONENTS_CONFIG_SUCCESS } from './actionTypes';
import { RESET_COMPONENTS_ON_STRUCTURE_CHANGE } from './actionTypes';

const initialState = {
  requestProcessing: {
    [APIRequestStatus.GET_COMPONENTS_CONFIG]: LoadingStatus.Loading,
    [APIRequestStatus.GET_COMPONENTS_DETAILS]: LoadingStatus.Loading,
    [APIRequestStatus.POST_COMPONENTS_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.UPDATE_COMPONENTS_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.GET_UPCOMING_COMPONENTS_DETAILS]: LoadingStatus.Loading,
  },
  additionComponents: [],
  deductionComponents: [],
  salaryType: {},
  payStrData: {},
  isEmptyData: false,
  upcomingAdditionComponents: [],
  upcomingDeductionComponents: [],
  upcomingSalaryType: {},
  upcomingPayStrData: {},
  employeeBenifits: [],
  upcomingEmployeeBenifits: []
};
export const payrollAssignmentReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_COMPONENTS_CONFIG_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_COMPONENTS_CONFIG]: LoadingStatus.Loading
        }
      };
    }

    case GET_COMPONENTS_CONFIG_SUCCESS: {
      const res = action.payload;
      const { additionComponents, deductionComponents, fkSalaryTypeCode } = res;
      return {
        ...state,
        additionComponents: additionComponents,
        deductionComponents: deductionComponents,
        salaryType: fkSalaryTypeCode,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_COMPONENTS_CONFIG]: LoadingStatus.Success
        }
      };
    }

    case GET_COMPONENTS_CONFIG_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_COMPONENTS_CONFIG]: LoadingStatus.Failure
        }
      };
    }

    case GET_COMPONENTS_DETAILS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_COMPONENTS_DETAILS]: LoadingStatus.Loading
        }
      };
    }

    case GET_COMPONENTS_DETAILS_SUCCESS: {
      const res = action.payload;
      const { payStrInfo, fkSalaryTypeCode, payStructure, effectiveDate, employeeBenifits } = res;
      const additionComponents = payStrInfo?.filter((item) => item.componentTxnTypeCode === ComponentType.Additions) || [];
      const deductionComponents = payStrInfo?.filter((item) => item.componentTxnTypeCode === ComponentType.Deductions) || [];
      sortComponents(additionComponents);
      sortComponents(deductionComponents);
      if (isEmpty(payStructure)) {
        return {
          ...state,
          additionComponents: additionComponents,
          deductionComponents: deductionComponents,
          salaryType: fkSalaryTypeCode || {},
          isEmptyData: true,
          employeeBenifits: employeeBenifits,
          requestProcessing: {
            ...state.requestProcessing,
            [APIRequestStatus.GET_COMPONENTS_DETAILS]: LoadingStatus.Success
          }
        };
      }
      return {
        ...state,
        additionComponents: additionComponents,
        deductionComponents: deductionComponents,
        salaryType: fkSalaryTypeCode || {},
        payStrData: { payStructure, effectiveDate } || {},
        isEmptyData: false,
        employeeBenifits: employeeBenifits,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_COMPONENTS_DETAILS]: LoadingStatus.Success
        }
      };
    }

    case GET_COMPONENTS_DETAILS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_COMPONENTS_DETAILS]: LoadingStatus.Failure
        }
      };
    }

    case POST_COMPONENTS_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_COMPONENTS_INFO]: LoadingStatus.Loading
        }
      };
    }

    case POST_COMPONENTS_INFO_SUCCESS: {
      return {
        ...state,
        payStrData: {},
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_COMPONENTS_INFO]: LoadingStatus.Success
        }
      };
    }

    case POST_COMPONENTS_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_COMPONENTS_INFO]: LoadingStatus.Failure
        }
      };
    }

    case UPDATE_COMPONENTS_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_COMPONENTS_INFO]: LoadingStatus.Loading
        }
      };
    }

    case UPDATE_COMPONENTS_INFO_SUCCESS: {
      return {
        ...state,
        payStrData: {},
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_COMPONENTS_INFO]: LoadingStatus.Success
        }
      };
    }

    case UPDATE_COMPONENTS_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_COMPONENTS_INFO]: LoadingStatus.Failure
        }
      };
    }

    case RESET_COMPONENTS: {
      return {
        initialState
      };
    }

    case RESET_COMPONENTS_ON_STRUCTURE_CHANGE: {
      return {
        ...state,
        additionComponents: [],
        deductionComponents: [],
        salaryType: {},
      };
    }

    case GET_UPCOMING_COMPONENTS_DETAILS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_UPCOMING_COMPONENTS_DETAILS]: LoadingStatus.Loading
        }
      };
    }

    case GET_UPCOMING_COMPONENTS_DETAILS_SUCCESS: {
      const res = action.payload;
      const { payStrInfo, fkSalaryTypeCode, payStructure, effectiveDate, employeeBenifits } = res;
      const additionComponents = payStrInfo.filter((item) => item.componentTxnTypeCode === ComponentType.Additions);
      const deductionComponents = payStrInfo.filter((item) => item.componentTxnTypeCode === ComponentType.Deductions);
      sortComponents(additionComponents);
      sortComponents(deductionComponents);
      return {
        ...state,
        upcomingAdditionComponents: additionComponents,
        upcomingDeductionComponents: deductionComponents,
        upcomingSalaryType: fkSalaryTypeCode,
        upcomingPayStrData: { payStructure, effectiveDate },
        upcomingEmployeeBenifits: employeeBenifits,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_UPCOMING_COMPONENTS_DETAILS]: LoadingStatus.Success
        }
      };
    }

    case GET_UPCOMING_COMPONENTS_DETAILS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_COMPONENTS_DETAILS]: LoadingStatus.Failure
        }
      };
    }

    default: {
      return state;
    }
  }
};

const sortComponents = (sortedArray) => {

  return (sortedArray.sort((a, b) => {
    const sourceCodeA = a.inputSourceCode || '';
    const sourceCodeB = b.inputSourceCode || '';

    if (sourceCodeA === ComponentInputType.Onetime && sourceCodeB !== ComponentInputType.Onetime) {
      return -1;
    } else if (sourceCodeA !== ComponentInputType.Onetime && sourceCodeB === ComponentInputType.Onetime) {
      return 1;
    } else {
      return 0;
    }
  })
  );
};

