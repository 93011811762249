import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyOnboardingPermissions, getDesignation } from '../../redux/onboarding/action';
import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import Organization from './Organization/Organization';
import OnboardingHeader from './common/Header/onboarding.header';
import { getDepartmentInfo, getLocationInfo, getRolesList } from '../../redux/onboarding/action';
import { onboarding, userProfile } from './Organization/constants/onboarding.constants';
import { useLocation } from 'react-router';
import RHSSlideOut from './RHSMenu/RHSSlideOut';
import CircularLoader from '../../atoms/CircularLoader/circular-loader';
import { fetchUserOnboardingPermissions } from '../../redux/modulePermissions/action';
import TryAgain from '../molecules/ErrorState/error.component';
import { getAllSalaryStructureList } from '../../redux/salaryStructure/action';

export default function OnBoarding() {
  const dispatch = useDispatch();
  const { currentUserRoleId, appPermissions } = useSelector((state) => state.main);
  const { requestProcessing } = useSelector((state) => state.onBoarding);
  const fetchPermissionsLoading = requestProcessing[APIRequestStatus.FETCH_PERMISSIONS];


  useEffect(() => {
    if (appPermissions && currentUserRoleId) {
      if (appPermissions[onboarding]) {
        dispatch(fetchCompanyOnboardingPermissions(currentUserRoleId, appPermissions[onboarding].id));
      }
      if (appPermissions[userProfile]) {
        dispatch(fetchUserOnboardingPermissions(currentUserRoleId, appPermissions[userProfile].id));
      }
    }
  }, [currentUserRoleId, appPermissions]);

  const location = useLocation();
  const paths = location.pathname.split('/');
  const currentPath = paths[paths.length - 1];

  const handleSearch = (searchInput) => {
    switch (currentPath) {
      case 'roles-and-permissions':
        dispatch(getRolesList(searchInput));
        break;
      case 'departments':
        dispatch(getDepartmentInfo(currentUserRoleId, searchInput));
        break;
      case 'locations':
        dispatch(getLocationInfo(searchInput));
        break;
      case 'designations':
        dispatch(getDesignation(searchInput));
        break;
      case 'salary-structure':
        dispatch(getAllSalaryStructureList(currentUserRoleId, searchInput));
        break;
      default: break;
    }
  };

  if (fetchPermissionsLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <Box m={4} pl={4} pr={10}>
      <CircularLoader show={fetchPermissionsLoading === LoadingStatus.Loading} />
      <OnboardingHeader
        handleSearch={handleSearch}
      />
      {fetchPermissionsLoading === LoadingStatus.Success &&
        <Box mt={4}>
          <Organization />
        </Box>
      }
      <Box>
        <RHSSlideOut />
      </Box>
    </Box>
  );
}
