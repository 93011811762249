import React, { useEffect, useState } from 'react';
import CardUI from '../../shared/components/ui-elements/CardUI';
import { ClaimServices } from '../../services/claims';
import { FormControl, Grid, MenuItem, Select } from '@mui/material';
import Modal from '../../shared/components/ui-elements/Modal';
import ButtonUI from '../../shared/components/ui-elements/ButtonUI';
import { useNavigate } from 'react-router-dom';
import ICON from '../../assets/OvertimeClaims.svg';


export default function ClaimRHS() {
  const claimServices = new ClaimServices();
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedClaimType, setSelectedClaimType] = useState(null);
    const [claimTypesInCategory, setClaimTypesInCategory] = useState([]);
    const [claimTypesFetched, setClaimTypesFetched] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const openModelForClaimTypeSelect = (categoryId)=>{
            setClaimTypesInCategory([]);
            setSelectedClaimType(null);
            setSelectedCategory(categoryId);
            fetchClaimTypes(categoryId.id);
            setIsMenuOpen(true);
    };

    // Fetch categories
    const fetchCategories = async ()=>{
        const response = await claimServices.getAllCategories();
        if(response?.data?.data?.length){
            setCategories(response.data.data);
        }
    };

    // Fetch claimType selected Categories
    const fetchClaimTypes = async (categoryId)=>{
        const response = await claimServices.getAllClaimsTypeByCategories(categoryId);
        if(response?.data?.data?.length){
            setClaimTypesInCategory(response.data.data);
        }
    };

    // on select claim type
    const onSelectClaimType = async (e)=>{
        const selectedClaimType =  e.target.value;
        setSelectedClaimType(selectedClaimType);
        if(selectedClaimType.id){
            navigate(`/claims/create/${selectedClaimType.id}`);
        }
    };

    useEffect(() => {
      fetchCategories();
    }, []);
    

  return (
    <>
    <div className="right-content">
            <CardUI background="#32333C">
              <div className="create-new-claim-links">
                <h3>Raise A Claim</h3>
                <div className="links-wrap">
                  {categories &&
                    categories.map((category) => {
                      return (
                        <div
                          className="link-wrap"
                          key={category.id}
                          onClick={() => openModelForClaimTypeSelect(category)}
                        >
                          <div className="link-icon-wrap">
                            <span>
                              <img
                                src={ICON}
                                alt={category.name}
                              />
                            </span>
                          </div>
                          <div className="link-title-wrap">
                            <span>{category.name}</span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </CardUI>
    </div>
    <Modal
        show={isMenuOpen}
        onCancel={()=>setIsMenuOpen(false)}
        header={<h2>Choose {selectedCategory?.name} Type</h2>}
        contentClass="modal-content"
        footerClass="footer-content"
        submitButton={
          <ButtonUI
            backgroundColor="#00AC94"
            color="#ffffff"
            size="small"
            padding="3px 20px"
            onclick={()=> setIsMenuOpen(false)}
          >
            Close
          </ButtonUI>
        }
      >
        <Grid item xs={12} >
          <label htmlFor="">Select</label>
          <FormControl fullWidth size="small">
            <Select
             fullWidth={true}
              value={selectedClaimType || ''}
              onChange={onSelectClaimType}
              name="select-claim-type"
              displayEmpty
              inputProps={{
                'aria-label': 'Without label',
              }}
              variant="outlined"
              autoWidth
            >
              {claimTypesInCategory.length &&
                claimTypesInCategory.map((claimType, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={claimType}
                        >
                          {claimType.name}
                        </MenuItem>
                      );
                  
                })}
            </Select>
          </FormControl>
        </Grid>
      </Modal>
    </>
  );
}
