import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, FormHelperText, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FORM } from '../../constants/constants';
import { disablePrevDateForThreeMonth } from '../../components/forms/common-services/functions';
import { makeStyles } from '@material-ui/core/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const useStyles = makeStyles({
  datePickerOutline: {
    border: '1px solid red', // Set your desired outline color
    borderRadius: '4px', // Add border radius if desired
  },
});

const assectFunction = {
  disablePrevDateForThreeMonth,
};
export default function FormDate({
  required = false,
  label,
  handleChange,
  helperText = '',
  error,
  shouldDisableDate = 'disablePrevDateForThreeMonth',
  value,
  date_format = FORM.DATE_FORMAT,
  disableFuture = false,
  variant = 'outlined',
  size = 'small',
  ...rest
}) {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body2" color={'neutral.dark80'} mb={1}>
        {label}{' '}
        {required && (
          <Box color="accentSecondary.main" sx={{ display: 'inline-block' }}>
            *
          </Box>
        )}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          format={date_format}
          value={value}
          onChange={(e) => {
            handleChange(e);
          }}
          // shouldDisableDate={assectFunction[shouldDisableDate]}
          disableFuture={disableFuture}
          InputProps={{ className: error ? classes.datePickerOutline : '' }}
          {...rest}
          // maxDate={moment().subtract(1,'day').toDate()}
          slotProps={{
            textField: {
              error: error,
              helperText: helperText,
              size: size,
              variant: variant,
              onKeyDown: (event) => event.preventDefault(),
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
}
