import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SelectTag from '../../../../../atoms/Select/SelectTag';
import {
  conditionInitialVal,
  ruleConjunctionOptions,
} from '../../../utils/conditional.util';
import AddConditions from '../../conditional/addConditions.component';
import { getFormBody } from '../../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { DeleteOutline } from '@mui/icons-material';
import PerformCalculations from './expression-editor/perform-calculation.component';

const ExpressionAccordian = (props) => {
  const { setFormulaRules, data, index, noOfRules } = props;
  const { conditions: fromInfo, ...rest } = data;

  const [conditions, setConditions] = useState(
    fromInfo || [conditionInitialVal]
  );
  const [ruleFormState, setRuleFormState] = useState(rest || {});

  useEffect(() => {
    setFormulaRules((prev) => {
      const newData = [...prev];
      newData[index] = { ...ruleFormState };
      return newData;
    });
  }, [ruleFormState]);

  useEffect(() => {
    setRuleFormState((prev) => ({
      ...prev,
      conditions: conditions.map((item) => getFormBody(item)),
    }));
  }, [conditions]);

  const handleDropDown = (event) => {
    const { value, name } = event.target;
    setRuleFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFieldClick = (field) => {
    const { fieldRefName } = field;
    setRuleFormState((prev) => {
      return {
        ...prev,
        expression: `${prev.expression || ''} data.${fieldRefName}`
      };
    });
  };

  const handleFunctionClick = (field) => {
    const { refName } = field;
    setRuleFormState((prev) => {
      return {
        ...prev,
        expression: `${prev.expression || ''} ${refName}`
      };
    });
  };

  const handleCalculatorClick = (digit) => {
    setRuleFormState((prev) => {
      const {expression =''} = prev;
      const lastChar = expression.charAt(expression.length-1);
      const allowSpace =  ((isNaN(lastChar) && lastChar !== '.') || isNaN(digit) && digit!=='.');
      const newExpr = `${prev.expression ||''}${allowSpace ? ` ${digit}`:  `${digit}`}`;
      return {
        ...prev,
        expression: newExpr,
      };
    });
  };

  const handleBackClick = () => {
    setRuleFormState((prev) => {
      return { ...prev, expression: '' };
    });
  };

  const handleDelete = () => {
    if (noOfRules === 1) {
      setRuleFormState({});
    } else {
      setFormulaRules((prev) => {
        const newConditions = [...prev];
        newConditions.splice(index, 1);
        setRuleFormState(newConditions[index]);
        return newConditions;
      });
    }
  };

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={8}>
        <Grid item xs={12} md={12} display="flex">
          <SelectTag
            name="conjunction"
            dropDownList={ruleConjunctionOptions}
            size="small"
            fullWidth
            value={ruleFormState?.conjunction}
            onchange={handleDropDown}
          />
          <Typography display="flex" alignItems="center">
            {' '}
            &nbsp; of the conditions are met
          </Typography>
          <Box display="flex" alignItems="center"></Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper sx={{ p: 2 }}>
            {conditions.map((item, index) => {
              return (
                <AddConditions
                  key={index}
                  index={index}
                  setConditions={setConditions}
                  condition={item}
                  noOfConditions={conditions.length}
                  setFormState={setRuleFormState}
                />
              );
            })}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <PerformCalculations
            handleFieldClick={handleFieldClick}
            handleCalculatorClick={handleCalculatorClick}
            expressionVal={ruleFormState.expression}
            handleBackClick={handleBackClick}
            handleFunctionClick={handleFunctionClick}
          />
        </Grid>
        <Grid item xs={12} md={12} display="flex" justifyContent="flex-end">
          <Button
            color="error"
            variant="outlined"
            sx={{
              borderColor: 'white',
              borderRadius: '20px',
              textTransform: 'capitalize',
            }}
            onClick={handleDelete}
          >
            <DeleteOutline sx={{ width: '24px', height: '24px', mr: '5px' }} />
            Delete
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ExpressionAccordian;
