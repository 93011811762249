import { Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';

export default function ClaimFormActionButtons({onAdd=()=>{},onDelete=()=>{}, onCopy=()=>{}}) {
  return (
    <Grid
    xs={1}
    className="form-main-actions"
    style={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <Grid>
      <IconButton onClick={onDelete}>
        <DeleteIcon
          style={{
            border: '1px solid #7db77d',
            padding: '0.3em',
            borderRadius: '8px',
            color: '#7db77d',
          }}
        />
      </IconButton>
    </Grid>
    <Grid>
      <IconButton
        onClick={onCopy}
      >
        <ContentCopyIcon
          style={{
            border: '1px solid #7db77d',
            padding: '0.3em',
            borderRadius: '8px',
            color: '#7db77d',
          }}
        />
      </IconButton>
    </Grid>
    <Grid>
      <IconButton>
        <AddIcon
          onClick={onAdd}
          style={{
            border: '1px solid #7db77d',
            padding: '0.3em',
            borderRadius: '8px',
            color: '#7db77d',
          }}
        />
      </IconButton>
    </Grid>
  </Grid>
  );
}
