import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import ModalCardView from '../../../../common/modalCardView';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, severity, ...other } = props;

  const severitySuccess = props.severity === 'success';
  const severityError = props.severity === 'error';

  return (
    <DialogTitle
      sx={{
        mt: 1,
        p: 2,
        pl: 5,
        bgcolor: severitySuccess ? 'primary.main20' : severityError ? 'accentSecondary.accent40' : 'accentSecondary.accent40',
        color: 'neutral.dark80',
        fontSize: 24,
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 24,
            color: (theme) => theme.palette.neutral.dark80,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ApprovalWorkflowApproveModal(props) {
  const { showModal, onClickClose, onClickConfirm, title, description, listComponent, confirmClose = true,
    secondaryAction, primaryAction, primaryButtonDisabled, component, componentProps, tertiaryAction, onClickTertiary, maxWidth } = props;
  const { onInputChange, inputValue, inputPlaceHolder, helperText, inputId, countBox } = componentProps || {};

  const hanldleConfirm = () => {
    onClickConfirm();
    if (confirmClose) {
      onClickClose();
    }
  };

  const handleTertiaryAction = () => {
    // onClickClose();
    onClickTertiary();
  };

  return (
    showModal ? (
      <Box>
        <BootstrapDialog
          onClose={onClickClose}
          aria-labelledby="customized-dialog-title"
          open={showModal}
          maxWidth={maxWidth}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={onClickClose}
            severity={props.severity}
          >
            {title}
          </BootstrapDialogTitle>
          <DialogContent>
            <Box p={3}>
              <Typography color='neutral.dark80'>{description}</Typography>
              {component && <ModalCardView
                inputId={inputId}
                onInputChange={onInputChange}
                inputValue={inputValue}
                inputPlaceHolder={inputPlaceHolder}
                helperText={helperText}
                countBox={countBox}
              />}
              {listComponent}
            </Box>
          </DialogContent>
          <DialogActions>
            <Box p={2}>
              {secondaryAction && (
                <Button
                  onClick={onClickClose}
                  sx={{
                    bgcolor: 'neutral.light80',
                    color: 'neutral.dark80',
                    mr: 2,
                    px: 2,
                    textTransform: 'capitalize',
                  }}
                >
                  {secondaryAction}
                </Button>
              )}
              {primaryAction && (
                <Button
                  variant="contained"
                  onClick={hanldleConfirm}
                  sx={{
                    textTransform: 'capitalize',
                    boxShadow: 'none',
                    mr: 2,
                    px: 2,
                  }}
                  disabled={primaryButtonDisabled}
                >
                  {primaryAction}
                </Button>
              )}
              {tertiaryAction && (
                <Button
                  variant="contained"
                  onClick={handleTertiaryAction}
                  sx={{ textTransform: 'capitalize', boxShadow: 'none' }}
                >
                  {tertiaryAction}
                </Button>
              )}
            </Box>
          </DialogActions>
        </BootstrapDialog>
      </Box>
    ) : null
  );
}
