import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, Typography } from '@mui/material';
import SelectTag from '../../../../atoms/Select/SelectTag';
import { AllowedDate } from '../../utils/validation.utils';
import RangeComponent from './range.component';
import { RangeValueTypes } from '../../../OnBoarding/common/Form/util/form.util';
import CheckBoxInput from '../../../../atoms/checkBox/checkBox';
import RangeComponentResubmit from './range.component.resubmit';

const DateValidation = (props) => {
  const { setFormInfo, range, fieldType } = props;
  const [form, setForm] = useState(range || { min: null, max: null, newmin: null, newmax: null });

  useEffect(() => {
    setFormInfo((prev) => ({
      ...prev,
      range: { ...form, fieldType },
    }));
  }, [form]);

  const handleDropDown = (event, type) => {
    const { value, name } = event.target;
    if (type === 'min' || type === 'max') {
      setForm((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          [name]: value,
        },
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }));
      if (value !== '3') {
        setForm((prev) => ({
          ...prev,
          min: null,
          max: null,
          newmin: null,
          newmax: null,
        }));
      }
    }
  };

  const handleResubmiitedDropDown = (event, type) => {
    const { value, name } = event.target;
    if (type === 'min' || type === 'max') {
      setForm((prev) => ({
        ...prev,
        [`new${type}`]: {
          ...prev[`new${type}`],
          [name]: value,
        },
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }));
      if (value !== '3') {
        setForm((prev) => ({
          ...prev,
          min: null,
          max: null,
          newmin: null,
          newmax: null,
        }));
      }
    }
  };

  const handleCheckboxChange = (event) => {
    const key = event.target.name;
    const val = event.target.checked;

    setForm((prev) => {
      const newForm = { ...prev };
      newForm[key] = val;
      if (!val) {
        newForm['newmin'] = null;
        newForm['newmax'] = null;
      }
      return newForm;
    });
  };

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={8}>
        <Grid
          item
          xs={12}
          md={12}
          display="flex"
          justifyContent="flex-end"
          style={{ paddingTop: 2 }}
        >
          <Button onClick={() => setForm({ min: {}, max: {} })}>Clear</Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          paddingTop={0}
          margin={0}
          style={{ paddingTop: 0 }}
        >
          <SelectTag
            labelText="Allowed Date"
            name="type"
            dropDownList={AllowedDate}
            size="small"
            fullWidth
            value={form.type}
            onchange={handleDropDown}
            placeholder="Select"
          />
        </Grid>
        <Divider />
        {form?.type === RangeValueTypes.Range && (
          <>
            <RangeComponent
              label="Min Date"
              form={form}
              onChange={handleDropDown}
              min={true}
              fieldType={fieldType}
            />
            <RangeComponent
              label="Max Date"
              form={form}
              onChange={handleDropDown}
              fieldType={fieldType}
            />
          </>
        )}
        <Grid
          item
          xs={12}
          md={12}
          paddingTop={2}
          marginTop={2}
          style={{ paddingTop: 0 }}
        >
        <Divider />
          <CheckBoxInput
            label="On Resubmission"
            name="isResubmitted"
            value={form?.isResubmitted ? true : false}
            onChange={handleCheckboxChange}
          />
        </Grid>
        {form?.type === RangeValueTypes.Range && form?.isResubmitted && (
          <>
            <RangeComponentResubmit
              label="Resubmitted Min Date"
              form={form}
              onChange={handleResubmiitedDropDown}
              min={true}
              fieldType={fieldType}
            />
            <RangeComponentResubmit
              label="Resubmitted Max Date"
              form={form}
              onChange={handleResubmiitedDropDown}
              fieldType={fieldType}
            />
          </>
        )}
      </Grid>
    </>
  );
};

export default DateValidation;
