import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function CustomBreadcrumbs(props) {
  const { tabs } = props;

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize='small' />}
        aria-label='breadcrumb'
        fontSize='small'
      >
        {tabs.map((tab, index) => {
          const { tabName, url } = tab;
          if(index === tabs.length -1) {
            return (
            <Typography fontSize="small" key={tabName} color="primary.main">
              {tabName}
            </Typography>);
          }
          return (
              <Link
                underline="hover"
                key={tabName}
                fontSize="small"
                color="neutral.dark60"
                to={url}
              >
                {tabName}
              </Link>
          );
        })}
      </Breadcrumbs>
    </Stack>
  );
}
