import { access, defaultPermissions, permissionsObj } from '../constants/roles-permissions.constants';


export const getRadioValue = (permissions) => {
    if(permissions[permissionsObj.allowAdd]) {
        return access.createAccess;
    } if (permissions[permissionsObj.allowEdit]) {
        return access.editAccess;
    } if (permissions[permissionsObj.allowView]) {
        return access.viewAccess;
    } return access.disabled;
};

export const getPermissions = (value) => {
    switch (value) {
        case access.createAccess: {
            return {
                ...defaultPermissions,
                [permissionsObj.allowAdd]: true,
                [permissionsObj.allowEdit]: true,
                [permissionsObj.allowView]: true,
            };
        }
        case access.editAccess: {
            return {
                ...defaultPermissions,
                [permissionsObj.allowEdit]: true,
                [permissionsObj.allowView]: true,
            };
        }
        case access.viewAccess: {
            return {
                ...defaultPermissions,
                [permissionsObj.allowView]: true,
            };
        }
        default: return defaultPermissions;
    }
};