import { Box, Radio, RadioGroup, TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReportsModuleId, access, configureRoleOptions } from '../constants/roles-permissions.constants';
import { getPermissions, getRadioValue } from '../util/configureRole.util';
import ReportsConfigration from './reportsConfigure/reports-configration.component';
import BenefitTypesConfigration from './benefitTypesConfiguration/benefit-type-configuration.component';
import UserBenefitConfig from './benefitTypesConfiguration/user-benefit-config.component';

const RoleConfigurationItem = (props) => {
  const {
    id,
    optionName,
    permissions,
    setModulePermissions,
    parentName,
    roleId,
    modulePermissions,
    childName
  } = props;
  const [value, setValue] = useState(getRadioValue(permissions));
  const [reportIds, setReportIds] = useState({});

  const handleChange = (event) => {
    setValue(event.target.value);
    const permissions = getPermissions(event.target.value);
    setModulePermissions((prev) => {
      const existingPermissions = prev.findIndex(
        (item) => item.moduleId === id
      );
      if (existingPermissions !== -1) {
        Object.assign(prev[existingPermissions], permissions);
        return [...prev];
      }
      const newElem = {};
      Object.assign(newElem, { moduleId: id }, permissions);
      return [...prev, newElem];
    });
  };

  useEffect(() => {
    if (reportIds && reportIds.reports && reportIds.reports.length === 0) {
      const newData = {
        moduleId: ReportsModuleId,
        allowView: false,
        allowAdd: false,
        allowEdit: false,
        allowDelete: false,
      };
      const shouldSetNewData = modulePermissions.some(
        (permission) => permission.moduleId === ReportsModuleId && permission.allowView
      );
      let updatedPermissions = [];

      if (shouldSetNewData) {
        updatedPermissions = modulePermissions.filter(
          (permission) => !(permission.moduleId === ReportsModuleId && permission.allowView)
        );
      }
      setValue(access.disabled);
      setModulePermissions([...updatedPermissions, newData]);
    }
  }, [reportIds]);

  return (
    <>
      <TableRow key={id}>
        <TableCell width={200}>{optionName}</TableCell>
        <TableCell>
          <RadioGroup
            sx={{
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
            name={optionName}
            value={value}
            onChange={handleChange}
          >
            {configureRoleOptions.map((item) => (
              <Box
                width={200}
                display="flex"
                justifyContent="center"
                key={item.name}
              >
                <Radio value={item.name} />
              </Box>
            ))}
          </RadioGroup>
        </TableCell>
      </TableRow>
      {parentName === 'reports' && value.trim() !== access.disabled && (
        <ReportsConfigration
          roleId={roleId}
          reportIds={reportIds}
          setReportIds={setReportIds}
        />
      )}
      {parentName === 'user-profile'  && childName ==='user-entitlement' && value.trim() !== access.disabled &&   (
        <BenefitTypesConfigration
          roleId={roleId}
        />
      )}
      {parentName === 'comp-ben-app'  && childName ==='ben-dashboard' && value.trim() !== access.disabled &&   (
        <UserBenefitConfig
          roleId={roleId}
        />
      )}
    </>
  );
};

export default RoleConfigurationItem;
