import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import DynamicBreadcrumb from '../../atoms/Breadcrumbs/dynamicBreadcrumb';
import { Divider } from '@mui/material';
import { useLocation } from 'react-router';
import SubHeader from './SubHeader/SubHeader';
import { useDispatch, useSelector } from 'react-redux';
import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import { AppLevelPermissions } from '../OnBoarding/Organization/constants/onboarding.constants';
import { fetchTimeManagementPermissions } from '../../redux/modulePermissions/action';
import TryAgain from '../molecules/ErrorState/error.component';
import CircularLoader from '../../atoms/CircularLoader/circular-loader';
import { TimeManagement as TimeManagementConstant } from './Constant/Constant';
import { getAllTimeManagement } from '../../redux/timeManagement/action';
import TimeManagementDashBoard from './TimeManagementOutlet/TimeManagementDashBoard';

const TimeManagement = () => {
	const { currentUserRoleId, appPermissions } = useSelector((state) => state.main);
	const dispatch = useDispatch();
	const { requestProcessing: { [APIRequestStatus.FETCH_TIME_MANAGEMENT_PERMISSIONS]: permissionsLoading,
	} } = useSelector((state) => state.modulePermissions);
	const location = useLocation();
	const paths = location.pathname.split('/');
	const currentPath = paths[paths.length - 1];

	useEffect(() => {
		if (appPermissions && currentUserRoleId) {
			if (appPermissions[AppLevelPermissions.TimeManagement]) {
				dispatch(fetchTimeManagementPermissions(currentUserRoleId, appPermissions[AppLevelPermissions.TimeManagement].id));
			}
		}
	}, [currentUserRoleId, appPermissions]);
	
	if (permissionsLoading === LoadingStatus.Failure) {
		return <TryAgain />;
	}
	return (
		<Box m={4} pl={4}>
			<CircularLoader show={permissionsLoading === LoadingStatus.Loading} />
			<Box>
				<SubHeader />
				<Box my={3}>
					<DynamicBreadcrumb />
					<Box mt={1}>
						<Divider />
					</Box>
				</Box>
			</Box>
			<TimeManagementDashBoard />
		</Box>
	);
};

export default TimeManagement;