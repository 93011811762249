import { Box, Divider, Grid, IconButton, MenuItem, Rating, Select, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import FieldView from '../../../molecules/FieldView/FieldView';
import InputField from '../../../../atoms/Input/Input';
import { GoalsServices } from '../../../../services/goals';
import { CompetencyFormFieldNames } from '../../Utlis/goals.util';
import { DeleteOutline } from '@mui/icons-material';

const CompetencyList = (props) => {
  const { formConfig, data, setData } = props;
  const [competencyOptions, setCompetencyOptions] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClickCompetency = () => {
    if (!competencyOptions.length) {
      new GoalsServices().getAllCompetencies().then((res) => {
        setCompetencyOptions(() => {
          const newOptions =  [...res.map((option) => {
              return ({...option, isDisabled: !!data.find((item) => item[CompetencyFormFieldNames.Competency].id === option.id)});
            })];
            return newOptions;
          });
        setOpen(true);
      });
    } else { 
      setOpen(true);
    }
  };

  useEffect(() => {
    setCompetencyOptions((prev) => {
    const newOptions =  [...prev.map((option) => {
        return ({...option, isDisabled: !!data.find((item) => item[CompetencyFormFieldNames.Competency].id === option.id)});
      })];
      return newOptions;
    });
  }, [data]);

  const handleSelect = (event) => {
    const { value, name } = event.target;
    const { id, name: dropdownLabel } = value;
    setData((prev) => {
      const newData = [...prev];
      newData.push({
        [name]: {
          name: dropdownLabel,
          id,
        }, [CompetencyFormFieldNames.Weightage]: 0
      });
      return newData;
    });
  };

  const handleChangeWeightage = (event, index) => {
    const { value } = event.target;
    setData((prev) => {
      const newData = [...prev];
      newData[index].goalCompetencyWeightage = value > 100 ? 100 : value;
      return newData;
    });
  };

  const handleDelete = (index) => {
    setData((prev) => {
      const newData = [...prev];
      newData.splice(index, 1);
      return newData;
    });
  };
  return (
    <>
      <Box position={'relative'}>
        <Box maxWidth={615}>
          <Select
            displayEmpty
            onChange={handleSelect}
            name={formConfig.fieldRefName}
            inputProps={{ 'aria-label': 'Without label' }}
            size='small'
            sx={{ width: '100%' }}
            placeholder='Select Option'
            onOpen={handleClickCompetency}
            onClose={() => setOpen(false)}
            open={open}
            renderValue={() => <Box sx={{ color: 'neutral.dark40' }}>{formConfig?.placeholderText}</Box>}
          >{competencyOptions.map((item) => {
            const { id, name, isDisabled } = item;
            return <MenuItem key={id} value={item} disabled={isDisabled}>{name}</MenuItem>;
          })}
          </Select>
        </Box>
        <Box mt={3} mr={7}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item md={11}>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant='body2Bold'
                    color={'neutral.dark100'}
                    mb={0}
                  >
                    Competency Assesment
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider sx={{ mt: 1 }} />
                </Grid>

                <Grid item xs={12} md={6}>
                  {data.map((item, index) => {
                    const { fkCompetency, goalCompetencyWeightage } = item;
                    return (
                      <Box key={index} mb={2}>
                        <FieldView
                          labelText={fkCompetency.name}
                        />
                        <Box display='flex'>
                          <Box>
                            <InputField
                              labelText={'Weightage'}
                              size={'small'}
                              value={goalCompetencyWeightage}
                              type='number'
                              onChange={(event) => handleChangeWeightage(event, index)}
                            />
                          </Box>
                          <IconButton
                            color='error'
                            onClick={() => handleDelete(index)}
                            sx={{ alignItems: 'center', justifyContent: 'center', mt: 3, ml: 2 }}>
                            <DeleteOutline sx={{ width: '24px', height: '24px', mr: '5px' }} />
                          </IconButton>
                        </Box>
                      </Box>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box mt={1}>
            <Typography variant='caption' color={'neutral.dark80'}>
              Note: Total Weightage cannot be less than or more than 100.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(CompetencyList);