import React, { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import { useSearchParams } from 'react-router-dom';
import { MARKETPLACE_USER_TOKEN, PROFILE } from '../../constants/constants';
import Skeleton from 'react-loading-skeleton';

const decodeJWT = (token)=>{
    try {
        const payload = jwt(token);
        return {success: true, payload, error:null};
    } catch (error) {
        console.log('error', error);
        return {success: false, payload: null, error};
    }
};


export default function MarketPlaceAuth(props) {
/**
 * Get Token and verify is this valid token or not.
 * if valid then get the userDetails link email and user name;
 * store that details in the local storage.
 * after that call the props.login function.
 */
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const redirectToLogin = ()=>{
        setIsLoading(false);
        return window.location.replace(`${process.env.REACT_APP_MARKET_AUTH_URL}`);
    };
    const getInformation = ()=>{
        const token = searchParams.get('token');
        if (!token) {
            // return redirectToLogin();
        }
        const {success,payload, error} = decodeJWT(token);
        console.log(error);
        if(!success){
            // return redirectToLogin();
        }
        localStorage.setItem('asProfile', PROFILE.MARKETPLACE_USER);
        localStorage.setItem(PROFILE.MARKETPLACE_USER, JSON.stringify(payload));
        localStorage.setItem(MARKETPLACE_USER_TOKEN,token);
        props.login();
        setIsLoading(false);

    };

    useEffect(() => {
        getInformation();
    }, []);
    

  return (
    <div>{isLoading ? (<Skeleton count={2} height={300} style={{margin:'20px'}}/>): <>Please Login Again!</>}</div>
  );
}
