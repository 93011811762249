import { getFormBody } from '../../components/OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { approvalWorkflowServices } from '../../services/approvalWokflowService';
import {
  GET_ALL_APPROVAL_WORKFLOW_LIST_FAILURE,
  GET_ALL_APPROVAL_WORKFLOW_LIST_REQUEST,
  GET_ALL_APPROVAL_WORKFLOW_LIST_SUCCESS,
  GET_APPROVAL_WORKFLOW_CONFIG_REQUEST,
  GET_APPROVAL_WORKFLOW_CONFIG_SUCCESS,
  GET_APPROVAL_WORKFLOW_CONFIG_FAILURE,
  RESET_APPROVAL_WORKFLOW_STRUCTURE,
  POST_APPROVAL_WORKFLOW_INFO_FAILURE,
  POST_APPROVAL_WORKFLOW_INFO_SUCCESS,
  POST_APPROVAL_WORKFLOW_INFO_REQUEST,
  ADD_STEPS_TAB,
  DELETE_STEPS_TAB,
  DUPLICATE_STEPS_TAB,
  UPDATE_STEPS_TAB,
  RESET_APPROVAL_WORKFLOW_DATA
} from './actionTypes';

const getAllApprovalWorkflowListRequest = () => ({
  type: GET_ALL_APPROVAL_WORKFLOW_LIST_REQUEST,
});

const getAllApprovalWorkflowListSuccess = (payload) => ({
  type: GET_ALL_APPROVAL_WORKFLOW_LIST_SUCCESS,
  payload,
});

const getAllApprovalWorkflowListFailure = () => ({
  type: GET_ALL_APPROVAL_WORKFLOW_LIST_FAILURE,
});

const getApprovalWorkflowFormConfigRequest = () => ({
  type: GET_APPROVAL_WORKFLOW_CONFIG_REQUEST,
});

const getApprovalWorkflowFormConfigSuccess = (payload) => ({
  type: GET_APPROVAL_WORKFLOW_CONFIG_SUCCESS,
  payload,
});

const getApprovalWorkflowFormFormConfigFailure = () => ({
  type: GET_APPROVAL_WORKFLOW_CONFIG_FAILURE,
});
export const resetApprovalWorkflowStructure = () => ({
  type: RESET_APPROVAL_WORKFLOW_STRUCTURE,
});

export const resetApprovalWorkflowData = () => ({
  type: RESET_APPROVAL_WORKFLOW_DATA,
});

export const addStepsTabAppWork = () =>({
  type: ADD_STEPS_TAB,
});
export const deleteStepsTabAppWork = (payload) =>({
  type: DELETE_STEPS_TAB,
  payload
});
export const duplicateStepsTabAppWork = (payload) =>({
  type: DUPLICATE_STEPS_TAB,
  payload
});
const postApprovalWokflowInfoRequest = () => (
  {
    type: POST_APPROVAL_WORKFLOW_INFO_REQUEST
  }
);
const postApprovalWokflowInfoSuccess = (payload) => (
  {
    type: POST_APPROVAL_WORKFLOW_INFO_SUCCESS,
    payload
  }
);
export const updateStepsTabAppWork = (payload) => (
  {
    type: UPDATE_STEPS_TAB,
    payload
  }
);
const postApprovalWokflowInfoFailure = () => (
  {
    type: POST_APPROVAL_WORKFLOW_INFO_FAILURE
  }
);
export const getApprovalWorkflowConfig =
  (currentUserRoleId, approvalWorkflowStructureId, fromLib, newReq) =>
  async (dispatch) => {
    dispatch(getApprovalWorkflowFormConfigRequest());
    new approvalWorkflowServices()
      .getApprovalWorkflowStructureDetails(currentUserRoleId, approvalWorkflowStructureId)
      .then((res) => {
        dispatch(
          getApprovalWorkflowFormConfigSuccess({ res, fromLib, newReq })
        );
      })
      .catch(() => {
        dispatch(getApprovalWorkflowFormFormConfigFailure());
      });
  };
export const getAllApprovalWorkflowList =
  (currentUserRoleId, searchInput) => async (dispatch) => {
    dispatch(getAllApprovalWorkflowListRequest());
    new approvalWorkflowServices()
      .getAllapprovalWorkflowList(currentUserRoleId, searchInput)
      .then((res) => {
        dispatch(getAllApprovalWorkflowListSuccess(res));
      })
      .catch((err) => {
        dispatch(getAllApprovalWorkflowListFailure());
      });
  };
  export const postApprovalWorkflowInfo = (currentUserRoleId, formState,formStateSteps,flag,newFormFlag,formID,stepsDeletedForm) => async (dispatch) => {
    dispatch(postApprovalWokflowInfoRequest());
    const formSateStepsBody = formStateSteps.map(obj => (getFormBody(obj)));
    return new approvalWorkflowServices().postApprovalWorkflowInfo(
      currentUserRoleId, getFormBody(formState),formSateStepsBody,flag,newFormFlag,formID,stepsDeletedForm).then((res) => {
      dispatch(postApprovalWokflowInfoSuccess());
      return res;
    }).catch((err) => {
      dispatch(postApprovalWokflowInfoFailure());
      throw err?.response?.data?.message || 'Error Saving';
    });
  };
