import React from 'react';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

export const DateRangeProFields = (props) => {
  const { selectedDate, setSelectedDate } = props;

  const handleDateChange = (date) => {
    if (date[0].isBefore(date[1]) || date[0].isSame(date[1])) {
      setSelectedDate(date);
    }
  };

  const shortcutsItems = [
    {
      label: 'Last Week',
      getValue: () => {
        const prevWeek = () => moment().subtract(7, 'day');
        return [prevWeek().startOf('week'), prevWeek().endOf('week')];
      },
    },
    {
      label: 'Current Month',
      getValue: () => {
        const today = () => moment();
        return [today().startOf('month'), today().endOf('month')];
      },
    },
    {
      label: 'Next Month',
      getValue: () => {
        const today = () => moment();
        const startOfNextMonth = () => today().endOf('month').add(1, 'day');
        return [startOfNextMonth(), startOfNextMonth().endOf('month')];
      },
    },
    {
      label: 'Last Month',
      getValue: () => {
        const today = () => moment();
        const endOfLastMonth = () =>
          today().startOf('month').subtract(1, 'day');
        return [endOfLastMonth().startOf('month'), endOfLastMonth()];
      },
    },
    {
      label: 'Last Three Months',
      getValue: () => {
        const today = () => moment();
        const startOfLastThreeMonths = () =>
          today().startOf('month').subtract(3, 'months');
        const endOfLastMonth = () =>
          today().startOf('month').subtract(1, 'day');
        return [startOfLastThreeMonths(), endOfLastMonth()];
      },
    },
    {
      label: 'Last Six Months',
      getValue: () => {
        const today = () => moment();
        const startOfLastSixMonths = () =>
          today().startOf('month').subtract(6, 'months');
        const endOfLastMonth = () =>
          today().startOf('month').subtract(1, 'day');
        return [startOfLastSixMonths(), endOfLastMonth()];
      },
    },
    {
      label: 'Current Year',
      getValue: () => {
        const today = () => moment();
        return [today().startOf('year'), today().endOf('year')];
      },
    },
    {
      label: 'Last Year',
      getValue: () => {
        const today = () => moment();
        const startOfLastYear = () =>
          today().startOf('year').subtract(1, 'years');
        const endOfLastYear = () => today().endOf('year').subtract(1, 'year');
        return [startOfLastYear(), endOfLastYear()];
      },
    },
    {
      label: 'Reset',
      getValue: () => [moment().startOf('month'), moment().endOf('month')],
    },
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
      <DateRangePicker
        format="DD/MM/YYYY"
        value={selectedDate}
        onChange={(date) => handleDateChange(date)}
        calendars={2}
        localeText={{ start: 'Start Date', end: 'End Date' }}
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
          },
          actionBar: { actions: [] },
          textField: {
            size: 'small',
            variant: 'outlined',
            onKeyDown: (event) => event.preventDefault(),
          },
        }}
      />
    </LocalizationProvider>
  );
};