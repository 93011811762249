import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import {
  GET_ALL_CURRENCY_CONVERSION_LIST_FAILURE,
  GET_ALL_CURRENCY_CONVERSION_LIST_REQUEST,
  GET_ALL_CURRENCY_CONVERSION_LIST_SUCCESS,
  GET_CURRENCY_CONVERSION_FORM_DATA_FAILURE,
  GET_CURRENCY_CONVERSION_FORM_DATA_REQUEST,
  GET_CURRENCY_CONVERSION_FORM_DATA_SUCCESS,
} from './actionTypes';

const initialState = {
  requestProcessing: {
    [APIRequestStatus.GET_ALL_CURRENCY_CONVERSION_LIST]: LoadingStatus.Loading,
    [APIRequestStatus.GET_CURRENCY_CONVERSION_FORM_DATA]: LoadingStatus.Loading,
  },
  currencyConversionListAll: [],
  currencyFormData: {},
  currencyMap :{}
};

export const currencyCoversionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CURRENCY_CONVERSION_LIST_REQUEST: {
      return {
        ...state,
        currencyFormData:{},
        currencyMap:{},
        currencyConversionListAll: [],
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_CURRENCY_CONVERSION_LIST]:
            LoadingStatus.Loading,
        },
      };
    }

    case GET_ALL_CURRENCY_CONVERSION_LIST_SUCCESS: {
      const { payload } = action;
      const {list,currencyMap} = payload;
      return {
        ...state,
        currencyFormData:{},
        currencyConversionListAll: list,
        currencyMap:currencyMap,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_CURRENCY_CONVERSION_LIST]:
            LoadingStatus.Success,
        },
      };
    }

    case GET_ALL_CURRENCY_CONVERSION_LIST_FAILURE: {
      return {
        ...state,
        currencyFormData:{},
        currencyConversionListAll: [],
        currencyMap:{},
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_CURRENCY_CONVERSION_LIST]:
            LoadingStatus.Failure,
        },
      };
    }
    case GET_CURRENCY_CONVERSION_FORM_DATA_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CURRENCY_CONVERSION_FORM_DATA]:
            LoadingStatus.Loading,
        },
      };
    }
    case GET_CURRENCY_CONVERSION_FORM_DATA_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        currencyFormData: payload,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CURRENCY_CONVERSION_FORM_DATA]:
            LoadingStatus.Success,
        },
      };
    }
    case GET_CURRENCY_CONVERSION_FORM_DATA_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CURRENCY_CONVERSION_FORM_DATA]:
            LoadingStatus.Failure,
        },
      };
    }
    default: {
      return state;
    }
  }
};
