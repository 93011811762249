import { permissionsObj } from '../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import { AllCandidate, AllJob, CandidateDetails, JobApprover, JobRequisition, OfferApprover } from '../Constant/constant';

export const getAllowedTabs = (permissions, recruitingTabs) => {
    return recruitingTabs.reduce((acc, item) => {
        const {name} = item;
        switch(name) {
            case JobRequisition: {
                 if(permissions?.[JobRequisition]?.[permissionsObj.allowView] || 
                    permissions?.[AllJob]?.[permissionsObj.allowView] || 
                    permissions?.[JobApprover]?.[permissionsObj.allowView]) {
                        acc.push(item);
                    }
                break; 
                }
            
            case CandidateDetails: {
                if (permissions?.[CandidateDetails]?.[permissionsObj.allowView] ||
                    permissions?.[AllCandidate]?.[permissionsObj.allowView]
                    ){
                        acc.push(item);
                    }
                    break;
            }
            case OfferApprover: {
                if( permissions?.[OfferApprover]?.[permissionsObj.allowView]) {
                    acc.push(item);
                }
                break;
            }
            default: {
                break;
            }
        }
        return acc;
    }, []);
};
