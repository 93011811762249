// React library imports
import React from 'react';
import ReactDOM from 'react-dom';

// Third-Party imports

// Local imports
import './index.css';
import App from './components/App';
import './i18n';
import { Provider } from 'react-redux';
import store from './redux/store';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MATERIAL_UI_LICENSE_KEY);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
