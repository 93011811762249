import {
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import CardUI from '../../shared/components/ui-elements/CardUI';
import { FIELD_TYPES } from '../../constants/constants';
import Footer from '../footer/Footer';
import Joi, { disallow, number, options } from 'joi';
import InputField from '../../atoms/Input/Input';
import FormDate from '../../atoms/Date/Date';
import TimePicker from '../../atoms/time/timepicker';
import FilesUploader from '../../atoms/FileUpload/FilesUploader';
import FormSelect from '../../atoms/Select/Select';
import { postCreateFormData } from '../forms/common-services/services';
import moment from 'moment';
import ClaimFormActionButtons from '../molecules/ClaimFormActionButtons/ClaimFormActionButtons';
import Loading from '../../atoms/Loading/loading';
import ClaimedEmployeeDetails from '../claim-view/claimed-employee-details';
import { useNavigate, useParams } from 'react-router-dom';
import { ClaimServices } from '../../services/claims';
import _ from 'lodash';
import ModalDialog from '../../atoms/Modal/ModalDialog';
import { showToast } from '../../utils/common.util';
import { ToastThemes } from '../../constants/common';
import { useSelector } from 'react-redux';
const DEFAULT_VALUES = {
  [FIELD_TYPES.NUMBER]: 0,
  [FIELD_TYPES.BOOLEAN]: null,
  [FIELD_TYPES.TEXT]: '',
  [FIELD_TYPES.DATE]: false,
  [FIELD_TYPES.TIME]: null,
  [FIELD_TYPES.FILES]: [],
  [FIELD_TYPES.SELECT]: null,
};
// eslint-disable-next-line react/display-name
const FormContainer = memo((props) => (
  <Grid item xs={12} lg={props.field_size} md={4} sm={4}>
    <FormControl fullWidth>{props.children}</FormControl>
  </Grid>
));

function FieldsRender({
  formSchema,
  openSuccessPopUp,
  currencyExchangeRate,
  entitlements,
}) {
  const { claimType } = useParams();
  const [inputs, setInputs] = useState([{ claimType: claimType }]);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const [initialSetupDone, setInitialSetupDone] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentEntitlement, setCurrentEntitlement] = useState({});
  const navigate = useNavigate();
  const claimService = new ClaimServices();
  // Value parser;
  const valueParser = (value, field) => {
    switch (field.type) {
      case FIELD_TYPES.NUMBER:
        return Number(value || 0);
      case FIELD_TYPES.BOOLEAN:
        return Boolean(value);
      case FIELD_TYPES.TEXT:
        return value ? String(value) : '';
      case FIELD_TYPES.DATE:
        return value;
      case FIELD_TYPES.TIME:
        return value;
      case FIELD_TYPES.FILES:
        return [...value];
      case FIELD_TYPES.SELECT:
        return valueParser(value, { type: field.data_type });
      default:
        return value;
    }
  };
  const resetFormFields = useCallback(() => {
    const fields = {};
    formSchema.form_meta?.forEach((field) => {
      if (field.type !== FIELD_TYPES.TIME) {
        fields[field.name] = DEFAULT_VALUES[field.type];
      }
      // if(field.type === FIELD_TYPES.FILES){
      //     fields[field.name]= ['https://vepeopleplus.blob.core.windows.net/dev-vexecution/83281697-5946-4af7-a4a8-f3f28636eb34.png'];
      // }
    });
    return fields;
  }, [formSchema.form_meta]);
  const getFieldIsRequiredOrNot = (input, field, formInputs) => {
    let disabled = field.disabled || false;
    let required = field.is_required;
    if (field.dependent_on) {
      const parent = field.dependent_on;
      const parentValue = input[parent];
      const matchParentValue = field.renderOnParentValueEqual;
      if (matchParentValue && !matchParentValue.includes(parentValue)) {
        if (field.is_visble) {
          disabled = true;
        } else {
          return <span></span>;
        }
      }
    }
    if (field.required_on) {
      required = false;
      field.required_on?.forEach((condition) => {
        const key = condition.key;
        const listValue = condition.values;
        if (key in formInputs && listValue.includes(formInputs[key])) {
          required = true;
          disabled = false;
        }
      });
      if (field.disableOnNotRequired && !required) {
        disabled = true;
      }
    }
    return { required, disabled };
  };
  const validatorFn = {
    [FIELD_TYPES.NUMBER]: (value, field, formInputs) => {
      const { required, disabled } = getFieldIsRequiredOrNot(
        value,
        field,
        formInputs
      );
      let fn = Joi.number().label(field.label);
      if (disabled ? false : required) {
        if ('min' in field) fn = fn.min(field.min);
        if ('max' in field) fn = fn.max(field.max);
        fn = fn.required();
      }
      // fn = fn.precision(2);
      return fn;
    },
    [FIELD_TYPES.BOOLEAN]: (value, field, formInputs) => {
      const { required, disabled } = getFieldIsRequiredOrNot(
        value,
        field,
        formInputs
      );
      const fn = Joi.boolean().label(field.label);
      if (disabled ? false : required) {
        if (value === undefined || value === null) return fn.required();
      }
      return fn;
    },
    [FIELD_TYPES.TEXT]: (value, field, formInputs) => {
      const { required, disabled } = getFieldIsRequiredOrNot(
        value,
        field,
        formInputs
      );
      let fn = Joi.string().label(field.label);
      if (disabled ? false : required) {
        if (field.min) fn = fn.min(field.min);
        if (field.max) fn = fn.max(field.max);
        fn = fn.required();
      }
      return fn;
    },
    [FIELD_TYPES.SELECT]: (value, field, formInputs) => {
      const { required, disabled } = getFieldIsRequiredOrNot(
        value,
        field,
        formInputs
      );
      return validatorFn[field.data_type]?.(
        value,
        { ...field, type: field.data_type },
        formInputs
      );
      // if (disabled ? false : required) {
      //     if (!value) return helperText.error(`${field.label} is required`);

      //     if (field?.options?.includes(value)) {
      //         return helperText.error(`Please select the valid value for ${field.label}`);
      //     }
      // }
      // return value;
    },
    [FIELD_TYPES.DATE]: (value, field, formInputs) => {
      const { required, disabled } = getFieldIsRequiredOrNot(
        value,
        field,
        formInputs
      );
      let fn = Joi.date().label(field.label);
      if (disabled ? false : required) {
        if (field.min) fn = fn.min(field.min);
        if (field.max) fn = fn.max(field.max);
        fn = fn.required();
      }
      return fn;
    },
    [FIELD_TYPES.TIME]: (value, field, formInputs) => {
      const { required, disabled } = getFieldIsRequiredOrNot(
        value,
        field,
        formInputs
      );
      let fn = Joi.date().label(field.label);
      if (disabled ? false : required) {
        if (field.min) fn = fn.min(field.min);
        if (field.max) fn = fn.max(field.max);
        fn = fn.required();
      }
      return fn;
    },
    [FIELD_TYPES.FILES]: (value, field, formInputs) => {
      const { required, disabled } = getFieldIsRequiredOrNot(
        value,
        field,
        formInputs
      );
      let fn = Joi.array().label(field.label).items(required?Joi.string().required():Joi.string().optional());
      if (disabled ? false : required) {
        if (field.min_files) fn = fn.min(fn.min_files);
        if (field.max_files) fn = fn.max(field.max_files);
        if (field?.options) {
          fn = fn
            .valid(...field.options)
            .message({ 'string.valid': `${field.label} is not a valid value` });
        }
        fn = fn.required();
      }
      return fn;
    },
  };
  const wrapParentCondition = (value, field, formIndex, formInputs) => {
    let validation = null;
    if (field.dependent_on) {
      const parentValue = inputs[formIndex][field.dependent_on];
      const conditionValue = field.renderOnParentValueEqual;
      let required = field.is_required;
      if (field.required_on) {
        field.required_on?.forEach((condition) => {
          const key = condition.key;
          const listValue = condition.values;

          const dependency_value = inputs[formIndex][key];
          if (listValue.includes(dependency_value)) {
            required = true;
          }
        });
      }
      if (conditionValue && conditionValue.includes(parentValue)) {
        validation =
          validatorFn[field.type] &&
          validatorFn[field.type](
            value,
            { ...field, is_required: required },
            formInputs
          );
        // return helperText.error(`Please enter valid value for ${field.label}`);
      }
    } else {
      validation =
        validatorFn[field.type] &&
        validatorFn[field.type](value, field, formInputs);
    }
    return validation;
  };
  const customValidator = (field, formIndex, formInputs) => {
    const value = inputs[formIndex] ?? inputs[formIndex][field.name];
    return wrapParentCondition(value, field, formIndex, formInputs);
  };
  const getValidatorForField = (field, formIndex, formInputs) => {
    const JoiValidators = {
      [FIELD_TYPES.NUMBER]: customValidator(field, formIndex, formInputs),
      [FIELD_TYPES.BOOLEAN]: customValidator(field, formIndex, formInputs),
      [FIELD_TYPES.TEXT]: customValidator(field, formIndex, formInputs),
      [FIELD_TYPES.DATE]: customValidator(field, formIndex, formInputs),
      [FIELD_TYPES.TIME]: customValidator(field, formIndex, formInputs),
      [FIELD_TYPES.FILES]: customValidator(field, formIndex, formInputs),
      // [FIELD_TYPES.NUMBER]: Joi.number().label(field.label).custom(customValidator(field, formIndex, formInputs)),
      // [FIELD_TYPES.BOOLEAN]: Joi.boolean().label(field.label).custom(customValidator(field, formIndex, formInputs)),
      // [FIELD_TYPES.TEXT]: Joi.string().label(field.label).custom(customValidator(field, formIndex, formInputs)),
      // [FIELD_TYPES.DATE]: Joi.date().label(field.label).custom(customValidator(field, formIndex, formInputs)),
      // [FIELD_TYPES.TIME]: Joi.date().label(field.label).custom(customValidator(field, formIndex, formInputs)),
      // [FIELD_TYPES.FILES]: Joi.array().label(field.label).custom(customValidator(field, formIndex, formInputs)),
    };
    switch (field.type) {
      case FIELD_TYPES.NUMBER:
        return JoiValidators[field.type];
      case FIELD_TYPES.BOOLEAN:
        return JoiValidators[field.type];
      case FIELD_TYPES.TEXT:
        return JoiValidators[field.type];
      case FIELD_TYPES.DATE:
        return JoiValidators[field.type];
      case FIELD_TYPES.TIME:
        return JoiValidators[field.type];
      case FIELD_TYPES.FILES:
        return JoiValidators[field.type];
      case FIELD_TYPES.SELECT:
        return JoiValidators[field.data_type];
    }
  };
  const handleSubmit = async (status = 'PENDING') => {
    const errors = [];
    const values = [{}];
    setIsSubmitted(true);
    setLoading(true);
    if (formSchema.has_entitlement) {
      if (
        parseFloat(formSchema.entitlements[0]['entitlement_balance_amt']) == 0
      ) {
        errors.push('Entitlement Balance is 0');
        showToast('Entitlement Balance is 0', ToastThemes.error);
      }
    }
    // generate validation schema.
    const validationSchema = inputs.map((inpt, i) => {
      const validator = {};
      let input = {};
      if (values[i]) {
        input = values[i];
      }
      formSchema.form_meta.forEach((field) => {
        if (field.dependent_on) {
          const renderOnParentValueEqual = inputs[i][field.dependent_on];
          if (field.renderOnParentValueEqual) {
            if (
              field.renderOnParentValueEqual.includes(renderOnParentValueEqual)
            ) {
              validator[field.name] = getValidatorForField(field, i, inputs[i]);
              input[field.name] = inputs[i][field.name];
            }
          } else {
            validator[field.name] = getValidatorForField(field, i, inputs[i]);
            input[field.name] = inputs[i][field.name];
          }
        } else {
          validator[field.name] = getValidatorForField(field, i, inputs[i]);
          input[field.name] = inputs[i][field.name];
        }
      });
      values[i] = input;
      console.log(validator);
      return validator;
      // (
      //   formSchema.form_meta.reduce((previous={},field)=>(
      //   previous[field.name]= getValidatorForField(field,i)
      //   ),{})
      // )
    });
    console.log(validationSchema);
    validationSchema.forEach((formValidationSchema, i) => {
      const { value, error } = Joi.object({ ...formValidationSchema }).validate(
        values[i],
        { abortEarly: false }
      );
      const err = {};
      if (error) {
        error?.details?.forEach((item) => {
          err[item.context.key] = item.message;
        });

        errors[i] = err;
      }
    });
    if (status === 'DRAFT') {
      setErrorMessage([]);
    } else {
      setErrorMessage(errors);
    }
    if ((!errors.length && status === 'PENDING') || status === 'DRAFT') {
      // validate the fields for the empty form.
      const emptyFrom = values.filter(
        (claim) => Object.keys(claim).length === 0
      );
      if (emptyFrom.length) {
        setError('Claim From Can\' be empty');
        setIsSubmitted(false);
        setLoading(false);
        return;
      }
      // submit the form.
      const formDatas = values.map((claim) => ({
        ...claim,
        claimType,
        status,
      }));
      const finalData = await claimService.postCreateFormData(
        formDatas,
        currentUserRoleId
      );
      if (finalData.code === 201 || finalData.code === 200) {
        let successMessage = '';
        if (status === 'PENDING') {
          successMessage = 'Claim request submitted successfully !';
        } else {
          successMessage = 'Draft saved successfully !';
        }
        setIsSubmitted(false);
        openSuccessPopUp(successMessage);
      } else {
        setError(finalData.message);
      }
    }
    setIsSubmitted(false);
    setLoading(false);
  };
  const renderFields = (field, formIndex, inputIndex) => {
    let disabled = field.disabled || false;
    let required = field.is_required;
    let all_hours = field.all_hours || false;
    let field_size = field.field_size || 4;
    if (field.dependent_on) {
      const parent = field.dependent_on;
      const parentValue = inputs[formIndex][parent];
      const matchParentValue = field.renderOnParentValueEqual;
      if (matchParentValue && !matchParentValue.includes(parentValue)) {
        if (field.is_visble) {
          disabled = true;
        } else {
          return <span key={inputIndex}></span>;
        }
      }
    }
    if (field.required_on) {
      required = false;
      field.required_on?.forEach((condition) => {
        const key = condition.key;
        const listValue = condition.values;

        const dependency_value = inputs[formIndex][key];
        if (listValue.includes(dependency_value)) {
          required = true;
        }
      });
      if (field.disableOnNotRequired && !required) {
        disabled = true;
      }
    }
    switch (field.type) {
      case FIELD_TYPES.NUMBER:
        return (
          <FormContainer>
            <InputField
              name={field.name}
              labelText={field.label}
              onChange={(e) => handleChangeInput(formIndex, e, field)}
              required={disabled ? false : required}
              value={inputs[formIndex][field.name]}
              error={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
                  ? true
                  : false
              }
              onBlur={(event) => handleInputBlur(field, event, formIndex)}
              disabled={disabled}
              helperText={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
              }
            />
          </FormContainer>
        );

      case FIELD_TYPES.TEXT:
        return (
          <FormContainer field_size={field_size}>
            <InputField
              name={field.name}
              labelText={field.label}
              onChange={(e) => handleChangeInput(formIndex, e, field)}
              required={disabled ? false : field.is_required}
              value={inputs[formIndex][field.name]}
              error={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
                  ? true
                  : false
              }
              helperText={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
              }
              disabled={disabled}
            />
          </FormContainer>
        );

      case FIELD_TYPES.SELECT:
        return (
          <FormContainer>
            <FormSelect
              name={field.name}
              options={field.options}
              labelId={field.name}
              label={field.label}
              disabled={disabled}
              handleChange={(e) => handleChangeInput(formIndex, e, field)}
              required={field.is_required}
              value={inputs[formIndex][field.name]}
              error={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
                  ? true
                  : false
              }
              helperText={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
              }
            />
          </FormContainer>
        );
      case FIELD_TYPES.DATE:
        return (
          <FormContainer>
            <FormDate
              name={field.name}
              labelId={field.name}
              label={field.label}
              size="medium"
              handleChange={(e) => {
                handleChangeInput(
                  formIndex,
                  {
                    target: {
                      name: field.name,
                      value: moment(e).format().split('T')[0]
                    },
                  },
                  field
                );
              }}
              disabled={disabled}
              disablePast={field.disablePast || false}
              disableFuture={field.disableFuture || false}
              value={
                inputs[formIndex][field.name]
                  ? moment(inputs[formIndex][field.name])
                  : null
              }
              minDate={
                inputs[formIndex][field.min_date]
                  ? moment(inputs[formIndex][field.min_date])
                  : null
              }
              required={field.is_required}
              error={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
                  ? true
                  : false
              }
              helperText={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
              }
            />
          </FormContainer>
        );
      case FIELD_TYPES.TIME:
        return (
          <FormContainer>
            <TimePicker
              name={field.name}
              required={field.is_required}
              dependent_on={inputs[formIndex][field.dependent_on_value]}
              label={field.label}
              value={
                inputs[formIndex][field.name]
                  ? moment(inputs[formIndex][field.name])
                  : inputs[formIndex][field.name]
              }
              handleChange={(e) =>
                handleChangeInput(
                  formIndex,
                  { target: { name: field.name, value: moment(e).toDate() } },
                  field
                )
              }
              disabled={disabled}
              error={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
                  ? true
                  : false
              }
              all_hours={all_hours}
              helperText={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
              }
            />
          </FormContainer>
        );
      case FIELD_TYPES.FILES:
        return (
          <FormContainer>
            <FilesUploader
              inputStyle={{ padding: '16px' }}
              name={field.name}
              label={field.label}
              required={disabled ? false : required}
              value={inputs[formIndex][field.name] || []}
              disabled={disabled}
              callback={(values) =>
                handleChangeInput(
                  formIndex,
                  { target: { name: field.name, value: values } },
                  field
                )
              }
              error={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
                  ? true
                  : false
              }
              helperText={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
              }
            />
          </FormContainer>
        );
    }
  };

  const formTrigger = (formIndex, input, field, formInputsValue) => {
    // Write Trigger
    let updatedValue = formInputsValue;
    formSchema.trigger.forEach((trigger) => {
      const fireTrigger = trigger.fields.includes(field.name);
      if (fireTrigger && trigger.fn) {
        const evelFn = eval(`(${trigger.fn})`);
        updatedValue = evelFn(updatedValue, {
          moment: moment,
          resetFormFields,
          currencyExchangeRate,
        });
      }
    });
    return updatedValue;
  };

  // const calculateTranspoartClaims = (updatedValue,helperFns)=>{
  //     const mileage = Number(updatedValue['mileage']);
  //     const departureTime = updatedValue['departureTime'];
  //     const modeOfTransport = updatedValue['modeOfTransport'];
  //     const isBefore6_30AM = departureTime ? helperFns.moment(departureTime).isBefore(helperFns.moment('06:30:00 AM','hh:mm:ss a')): false;
  //     const isAfter9PM = departureTime ? helperFns.moment(departureTime).isAfter(helperFns.moment('09:00:00 PM','hh:mm:ss a')): false;
  //     const toFromHome = updatedValue['toFromHome'];

  //         if(modeOfTransport === 1 || modeOfTransport === 3){
  //             let difference = mileage - (toFromHome ? (!(isBefore6_30AM || isAfter9PM) ? +updatedValue['homeToOfficeDist'] : 0): 0);
  //             let cal = difference > 0 ? difference * (modeOfTransport === 1 ? 0.6: 0.25) : 0;
  //             let totalamt = Number(updatedValue['parkingFee']) + Number(updatedValue['tollFee']) + Number(cal);
  //             updatedValue['computedAmount'] = Number(cal).toFixed(2) < 0 ? 0 : Number(cal).toFixed(2);
  //             updatedValue['amount'] = totalamt.toFixed(2) < 0 ? 0 : totalamt.toFixed(2);
  //         } else {
  //             let difference = updatedValue['receiptAmount'] - (!(isBefore6_30AM || isAfter9PM) ? updatedValue['homeOfficeAmt'] : 0) ;
  //             updatedValue['amount'] = difference;
  //         }
  //     return updatedValue;
  // };

  const handleInputBlur = (field, event, formIndex) => {
    if ('dataInputFormat'in field){
        const val = event.target.value;
        const name = event.target.name;
        const preinputs = [...inputs];
        preinputs[formIndex][name] = parseFloat(val).toFixed(field.dataInputFormat);
        setInputs(preinputs);
    }
  };
  const handleChangeInput = (formIndex, event, field) => {
    console.log('event----', event);
    const preinputs = [...inputs];
    const input = { ...inputs[formIndex] };

    const name = event.target.name;
    const value = event.target.value;

    input[name] = value;
    preinputs[formIndex] = input;
    // setInputs(preinputs);
    const schema = getValidatorForField(field, formIndex, input);
    const { error } = schema.validate(value);
    const errorsM = [...errorMessage];
    if (error) {
      errorsM[formIndex] = {
        ...errorsM[formIndex],
        [field.name]: error.message,
      };
      setErrorMessage(errorsM);
    } else {
      if (errorsM[formIndex] && errorsM[formIndex][field.name]) {
        delete errorsM[formIndex][field.name];
        setErrorMessage(errorsM);
      }
    }

    // Trigger call here.
    preinputs[formIndex] = formTrigger(
      formIndex,
      input,
      field,
      preinputs[formIndex]
    );
    setInputs(preinputs);
  };
  // Effects
  useEffect(() => {
    if (!initialSetupDone) {
      const fields = resetFormFields();
      setInitialSetupDone(true);
      setInputs(() => [fields]);
      // if(formSchema.has_entitlement){
      //     const entitlement = formSchema.entitlements ? formSchema.entitlements[0] :{};
      //     setCurrentEntitlement(()=> entitlement);
      // }
    }
  }, [resetFormFields]);

  useEffect(() => {
    setCurrentEntitlement(entitlements[0]);
  }, [entitlements.length]);

  const onAdd = useCallback(
    (index) => {
      setInputs([...inputs, { claimType: claimType }]);
    },
    [inputs]
  );

  const onDelete = useCallback(
    (index) => {
      if (inputs.length > 1) {
        setInputs((inputsList) => inputsList.filter((item, i) => index !== i));
        setErrorMessage((prev) => prev.filter((item, i) => index !== i));
      }
    },
    [inputs]
  );

  const onCopy = useCallback(
    (index) => {
      const copyInput = _.cloneDeep({ ...inputs[index] });
      setInputs([...inputs, copyInput]);
    },
    [inputs]
  );

  return (
    <>
      <Loading loading={loading}>
        {formSchema && (
          <Grid
            container
            alignItems={'center'}
            style={{ marginBottom: '100px' }}
          >
            {inputs.map((input, formIndex) => (
              <Grid container alignItems={'center'} mb={2} key={formIndex}>
                <Grid xs={11}>
                  <Box>
                    <CardUI className="form-heading-wrap">
                      <Grid
                        xs={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Grid xs={6}>
                          <h4>{formSchema.name}</h4>
                        </Grid>
                      </Grid>
                    </CardUI>
                  </Box>
                  <Paper>
                    {formSchema.has_entitlement && (
                      <Box p={3}>
                        <Grid container spacing={1} key={formIndex}>
                          <Grid item xs={3}>
                            <Typography variant="body1" m={1}>
                              {' '}
                              <b>Start Date</b>{' '}
                            </Typography>
                            <Typography variant="body2" mx={1}>
                              {' '}
                              {moment(currentEntitlement?.start_date).format(
                                'YYYY-MM-DD'
                              )}{' '}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" m={1}>
                              {' '}
                              <b>End Date</b>{' '}
                            </Typography>
                            <Typography variant="body2" mx={1}>
                              {' '}
                              {moment(currentEntitlement?.end_date).format(
                                'YYYY-MM-DD'
                              )}{' '}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" m={1}>
                              {' '}
                              <b>Entitlement</b>{' '}
                            </Typography>
                            <Typography variant="body2" mx={1}>
                              {' '}
                              $ {currentEntitlement?.entitlement_amt}{' '}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1" m={1}>
                              {' '}
                              <b>Balance</b>{' '}
                            </Typography>
                            <Typography variant="body2" mx={1}>
                              $ {currentEntitlement?.entitlement_balance_amt}{' '}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    <Box p={3}>
                      <Grid container spacing={1} key={formIndex}>
                        {formSchema.form_meta?.map((feild, inputIndex) =>
                          renderFields(feild, formIndex, inputIndex)
                        )}
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
                <Grid xs={1}>
                  <ClaimFormActionButtons
                    onAdd={() => onAdd(formIndex)}
                    onDelete={() => onDelete(formIndex)}
                    onCopy={() => onCopy(formIndex)}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid xs={11}>
              <Paper sx={{ p: 2, mt: 2, mb: 4 }}>
                <Box display="flex" gap="10px" justifyContent="flex-end">
                  {
                    <>
                      <Button
                        size="medium"
                        sx={{
                          bgcolor: 'neutral.light80',
                          color: 'neutral.dark80',
                          px: 2,
                          textTransform: 'capitalize',
                        }}
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={() => handleSubmit('DRAFT')}
                      >
                        Save Draft
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        onClick={() => handleSubmit()}
                      >
                        Save
                      </Button>
                    </>
                  }
                </Box>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Loading>
      <ModalDialog
        showModal={error ? true : false}
        primaryAction={'Close'}
        onClickClose={() => {}}
        onClickConfirm={() => setError(null)}
        title={'Error'}
        description={error}
      />
    </>
  );
}

export default FieldsRender;
