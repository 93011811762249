import React from 'react';
import { useOutletContext } from 'react-router-dom';
import CreateBenefitsComponent from './create-benefit/create-benefits.component';
import { Box } from '@mui/material';
import TabBar from '../../../atoms/Tabs/TabBar';
import CompBenHeader from '../comp-ben-header/comp-ben-header.component';

const ManageCompBenFormTabComponent = () => {
  const [viewMode, setViewMode] = useOutletContext();

  const payrollTabs = [
    {
      name: 'comp-ben',
      label: 'Benefits',
      title: 'Benefits',
      hide: false,
      panel: (
        <CreateBenefitsComponent
          viewMode={viewMode}
          setViewMode={setViewMode}
        />
      ),
    },
  ];

  return (
    <Box mt={4}>
      <CompBenHeader />
      <TabBar tabPanels={payrollTabs} searchTabs disableCreate />
    </Box>
  );
};

export default ManageCompBenFormTabComponent;
