import React, { useEffect, useState } from 'react';
import FormEdit from '../../../../../../../OnBoarding/common/Form/FormEdit';
import { CandidateFormFieldNames, InterviewFormFields } from '../../../../../../Constant/constant';
import { InterviewServices } from '../../../../../../../../services/interviewServices';

const InterviewForm = (props) => {
  const { formConfig, viewMode, setInterviewForm, interviewFormInfo, error, setError = () => { } } = props;
  const [formInfo, setFormInfo] = useState(interviewFormInfo);
  const [interviewerDropDownOption, setInterviewerDropDownOption] = useState([]);
  const [modeOfInterviewOption, setModeOfInterviewOption] = useState([]);
  const [interviewTypeOption, setInterviewTypeOption] = useState([]);
  useEffect(() => {
    setInterviewForm(formInfo);
  }, [formInfo]);

  const handleDropdownClick = (name) => {
    switch (name) {
      case InterviewFormFields.Interviewer: {
        return new InterviewServices().getDropdownOptions(name).then((res) => {
          setInterviewerDropDownOption(res);
          return true;
        });
      }
      case InterviewFormFields.InterviewType: {
        return new InterviewServices().getDropdownOptions(name).then((res) => {
          setInterviewTypeOption(res);
          return true;
        });
      }
      case InterviewFormFields.modeOfInterview: {
        return new InterviewServices().getDropdownOptions(name).then((res) => {
          setModeOfInterviewOption(res);
          return true;
        });
      }
      default : break;
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case InterviewFormFields.Interviewer: {
        return interviewerDropDownOption;
      }
      case InterviewFormFields.InterviewType: {
        return interviewTypeOption;
      }
      case InterviewFormFields.modeOfInterview: {
        return modeOfInterviewOption;
      }
    }
  };

  return (
        <FormEdit list={formConfig} formInfo={formInfo} errors={error} setErrors={setError}
          padding={0} setFormData={setFormInfo} handleDropdownClick={handleDropdownClick} getDropdownOptions={getDropdownOptions} />
      
    );
};

export default React.memo(InterviewForm);