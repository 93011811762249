import { TextField, styled } from '@mui/material';
import Tabs from '@mui/material/Tabs';

export const StyledTabs = styled(Tabs)`
  .MuiButtonBase-root {
    text-transform: capitalize;
    font-size: 16px; 
    font-weight: 400;
  }
  .Mui-selected {
    background-color: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.neutral.light} !important;
    border-radius: 8px 8px 0 0;
    font-weight: 600;
  }
`;

export const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      paddingTop: '2px',
      paddingBottom: '0px',
    },
  
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0',
    },
  
    '& .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
  });