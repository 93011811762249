import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { FormHeader } from '../../../common/formHeader';
import ListView from '../../../../../atoms/DataList/ListView';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton } from '@mui/material';
import {
  InfoOutlined,
  SettingsBackupRestoreOutlined,
} from '@mui/icons-material';
import TryAgain from '../../../../molecules/ErrorState/error.component';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import { permissionsObj } from '../../RolesAndPermissions/constants/roles-permissions.constants';
import AddCurrency from '../currency-form/AddCurrency';
import { currencyConversion } from '../../constants/onboarding.constants';
import { currencyConversionDashboardHeaders } from '../utils/currencyConversion.utils';
import { getAllCurrencyConversionList } from '../../../../../redux/currency-conversion/action';
import {
  APIRequestStatus,
  LoadingStatus,
} from '../../../../../constants/common';
import { DateRangePro } from '../../../../molecules/DateRange/DateRangePro';
import {
  firstDateOfCurrentMonth,
  lastDateOfCurrentMonth,
} from '../../../../../utils/formatter';
import moment from 'moment';

const AllCurrency = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [viewMode, setViewMode] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [currencyId, setCurrencyId] = useState();
  const dispatch = useDispatch();
  const { logoImage } = useSelector((state) => state && state.onBoarding);
  const [selectedDate, setSelectedDate] = useState([
    firstDateOfCurrentMonth,
    lastDateOfCurrentMonth,
  ]);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const {
    currencyConversionListAll,
    currencyMap,
    requestProcessing: {
      [APIRequestStatus.GET_ALL_CURRENCY_CONVERSION_LIST]: loadingStatus,
    },
  } = useSelector((state) => state.currencyConversion);

  const { permissions } = useSelector((state) => state.onBoarding);

  useEffect(() => {
    if (currentUserRoleId && !showForm) {
      dispatch(
        getAllCurrencyConversionList(
          currentUserRoleId,
          moment(selectedDate[0]).month() + 1,
          moment(selectedDate[0]).year(),
          moment(selectedDate[1]).month() + 1,
          moment(selectedDate[1]).year()
        )
      );
    }
  }, [currentUserRoleId, showForm, selectedDate]);

  useEffect(() => {
    setCheckedItems([]);
  }, [currencyConversionListAll]);

  const onClickAddIcon = () => {
    setShowForm(true);
    setViewMode(false);
    setCurrencyId('');
  };

  const onDetailsClick = (id) => {
    setShowForm(true);
    setViewMode(true);
    setCurrencyId(id);
  };

  const isDisabledReset = () => {
    return (
      selectedDate[0].isSame(firstDateOfCurrentMonth) &&
      selectedDate[1].isSame(lastDateOfCurrentMonth)
    );
  };

  const resetFilters = () => {
    setSelectedDate([firstDateOfCurrentMonth, lastDateOfCurrentMonth]);
  };

  const rowData = currencyConversionListAll?.map((items) => {
    const { id, month, year, conversion_rate, name } = items;
    return {
      name: currencyMap?.[name] || '',
      currencyCode: name,
      monthYear: moment().year(year).month(month-1).format('YYYY-MM'),
      conversion_rate,
      action: !permissions?.[currencyConversion]?.[permissionsObj.allowEdit] ? (
        { hide: true }
      ) : (
        <IconButton
          onClick={() => onDetailsClick(id)}
          disabled={
            !permissions?.[currencyConversion]?.[permissionsObj.allowEdit]
          }
        >
          <InfoOutlined sx={{ color: 'primary.main80' }} />
        </IconButton>
      ),
      id: {
        hide: true,
        value: { id },
      },
    };
  });
  if (loadingStatus === LoadingStatus.Failure) {
    return <TryAgain />;
  }

  return (
    <>
      <CircularLoader show={loadingStatus === LoadingStatus.Loading} />
      {showForm ? (
        <AddCurrency
          viewMode={viewMode}
          setViewMode={setViewMode}
          currencyId={currencyId}
          setShowForm={setShowForm}
          setCurrencyId={setCurrencyId}
        />
      ) : (
        <Box>
          <Box display="flex" justifyContent="space-between">
            <FormHeader title="Currency Conversion Rates" />
            <Box display="flex" justifyContent="flex-end" gap={2} mb={1} p={2}>
              <DateRangePro
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
              <Button
                startIcon={<SettingsBackupRestoreOutlined />}
                sx={{ textTransform: 'capitalize', mt: -1.5 }}
                disabled={isDisabledReset()}
                onClick={resetFilters}
              >
                Clear
              </Button>
            </Box>
          </Box>
          <ListView
            headCells={currencyConversionDashboardHeaders}
            rowsData={rowData}
            showSerialNo={true}
            showCheckbox={true}
            setCheckedItems={setCheckedItems}
            checkedItems={checkedItems}
            showAddIcon={
              permissions?.[currencyConversion]?.[permissionsObj.allowAdd]
            }
            onAddClick={() => onClickAddIcon()}
			emptyDashboardMsg={'Conversion rates not available for selected month'}
          />
        </Box>
      )}
    </>
  );
};

export default AllCurrency;
