import React, { useEffect, useState } from 'react';
import * as models from 'powerbi-models';
import { PowerBiService } from '../../services/powerBi';
import * as powerbi from 'powerbi-client';
import ButtonUI from '../../shared/components/ui-elements/ButtonUI';
import TryAgain from '../molecules/ErrorState/error.component';

const PowerBIEmbed = (props) => {
  const { setShowReport, reportData: { reportId, datasetId } } = props;
  const [embedToken, setEmbedToken] = useState();
  const [embedTokenExpiration, setEmbedTokenExpiration] = useState();
  const [report, setReport] = useState(null);
  const [timeInterval, setTimeInterval] = useState(null);
  const [error, setError] = useState(false);

  const workspaceId = process.env.REACT_APP_POWER_BI_WORKSPCAE_ID;
  const MINUTES_BEFORE_EXPIRATION = 5;
  const INTERVAL_TIME = 30000;

  useEffect(async () => {
    await checkTokenAndUpdate();
  }, []);

  useEffect(() => {
    return () => clearInterval(timeInterval);
  }, [timeInterval]);

  useEffect(async () => {
    if (timeInterval) {
      clearInterval(timeInterval);
      setTimeInterval(null);
    }
    const interval = setInterval(async () => {
      await checkTokenAndUpdate();
    }, INTERVAL_TIME);
    setTimeInterval(interval);
    return () => {
      clearInterval(timeInterval);
      report?.off('loaded');
    };
  }, [embedTokenExpiration]);

  useEffect(async () => {
    try {
      if (embedToken && reportId && workspaceId) {
        const config = {
          type: 'report',
          tokenType: models.TokenType.Embed,
          embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${workspaceId}`,
          accessToken: embedToken,
          id: reportId,
          settings: {
            filterPaneEnabled: true,
            navContentPaneEnabled: true,
          },
        };
        const embedContainer = document.getElementById('embedContainer');
        if (!report) {
          setReport(window.powerbi.embed(embedContainer, config));
          report?.on('loaded', () => {
            console.log('Report loaded');
          });
        } else {
          await report.setAccessToken(embedToken);
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  }, [reportId, workspaceId, embedToken]);

  const checkTokenAndUpdate = async () => {
    const currentTime = Date.now();
    const expirationTime = Date.parse(embedTokenExpiration) || 0;
    const timeUntilExpiration = expirationTime - currentTime;
    const timeToUpdate = MINUTES_BEFORE_EXPIRATION * 60 * 1000;

    if (timeUntilExpiration <= timeToUpdate) {
      const accessTokenDetails = await new PowerBiService().getReport(reportId, datasetId).catch(() => {
        clearInterval(timeInterval);
        setError(true);
      });
      const { expiration, token } = accessTokenDetails;
      setEmbedToken(token);
      setEmbedTokenExpiration(expiration);

      if (report) {
        await report.setAccessToken(token);
      }
    }
  };

  const handleGoBack = () => {
    setShowReport(false);
    clearInterval(timeInterval);
  };

  if (error) {
    return (
      <TryAgain handleCloseClick={handleGoBack} />
    );
  }

  return (
    <>
      <ButtonUI
        variant="contained"
        size="small"
        width="auto"
        backgroundColor="#00AC94"
        color="#ffffff"
        onclick={handleGoBack}
        margin='20px 0 0 10px'
      >
        Go Back
      </ButtonUI>
      <div id="embedContainer" style={{ height: '85vh', marginTop: 1 }} />
    </>
  );
};

export default PowerBIEmbed;

