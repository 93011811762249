import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import {
  FETCH_COMP_BEN_PERMISSIONS_FAILURE,
  FETCH_COMP_BEN_PERMISSIONS_REQUEST,
  FETCH_COMP_BEN_PERMISSIONS_SUCCESS,
  FETCH_MY_PROFILE_PERMISSIONS_FAILURE, FETCH_MY_PROFILE_PERMISSIONS_REQUEST, FETCH_MY_PROFILE_PERMISSIONS_SUCCESS,
  FETCH_PAYROLL_PERMISSIONS_FAILURE,
  FETCH_PAYROLL_PERMISSIONS_REQUEST,
  FETCH_PAYROLL_PERMISSIONS_SUCCESS,
  FETCH_PERFORMANCE_PERMISSIONS_FAILURE, FETCH_PERFORMANCE_PERMISSIONS_REQUEST, FETCH_PERFORMANCE_PERMISSIONS_SUCCESS,
  FETCH_RECRUITING_PERMISSIONS_FAILURE, FETCH_RECRUITING_PERMISSIONS_REQUEST, FETCH_RECRUITING_PERMISSIONS_SUCCESS,
  FETCH_TIME_MANAGEMENT_PERMISSIONS_FAILURE,
  FETCH_TIME_MANAGEMENT_PERMISSIONS_REQUEST,
  FETCH_TIME_MANAGEMENT_PERMISSIONS_SUCCESS,
  FETCH_USER_ONBOARDING_PERMISSIONS_FAILURE, FETCH_USER_ONBOARDING_PERMISSIONS_REQUEST, FETCH_USER_ONBOARDING_PERMISSIONS_SUCCESS,
  RESET_MODULE_PERMISSIONS
} from './actionTypes';

const initialState = {
  userProfilePermissions: null,
  myProfilePermissions: null,
  recrutingPermissions: null,
  performancePermission: null,
  timeManagementPermission: null,
  payrollPermission: null,
  compBenPermission: null,
  requestProcessing: {
    [APIRequestStatus.FETCH_USER_PERMISSIONS]: LoadingStatus.Loading,
    [APIRequestStatus.FETCH_MY_PROFILE_PERMISSIONS]: LoadingStatus.Loading,
    [APIRequestStatus.FETCH_RECRUITING_PERMISSIONS]: LoadingStatus.Loading,
    [APIRequestStatus.FETCH_PERFORMANCE_PERMISSIONS]: LoadingStatus.Loading,
    [APIRequestStatus.FETCH_TIME_MANAGEMENT_PERMISSIONS]: LoadingStatus.Loading,
    [APIRequestStatus.FETCH_PAYROLL_PERMISSIONS]: LoadingStatus.Loading,
    [APIRequestStatus.FETCH_COMP_BEN_PERMISSIONS]: LoadingStatus.Loading,
  },
};

const modulePermissions = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_ONBOARDING_PERMISSIONS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_USER_PERMISSIONS]: LoadingStatus.Loading
        }
      };
    }

    case FETCH_USER_ONBOARDING_PERMISSIONS_SUCCESS: {
      const permissions = action.payload;
      return {
        ...state,
        userProfilePermissions: permissions,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_USER_PERMISSIONS]: LoadingStatus.Success
        }
      };
    }

    case FETCH_USER_ONBOARDING_PERMISSIONS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_USER_PERMISSIONS]: LoadingStatus.Failure
        }
      };
    }

    case FETCH_MY_PROFILE_PERMISSIONS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_MY_PROFILE_PERMISSIONS]: LoadingStatus.Loading
        }
      };
    }

    case FETCH_MY_PROFILE_PERMISSIONS_SUCCESS: {
      const permissions = action.payload;
      return {
        ...state,
        myProfilePermissions: permissions,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_MY_PROFILE_PERMISSIONS]: LoadingStatus.Success
        }
      };
    }

    case FETCH_MY_PROFILE_PERMISSIONS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_MY_PROFILE_PERMISSIONS]: LoadingStatus.Failure
        }
      };
    }

    case RESET_MODULE_PERMISSIONS: {
      return initialState;
    }

    case FETCH_RECRUITING_PERMISSIONS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_RECRUITING_PERMISSIONS]: LoadingStatus.Loading
        }
      };
    }

    case FETCH_RECRUITING_PERMISSIONS_SUCCESS: {
      const permissions = action.payload;
      return {
        ...state,
        recrutingPermissions: permissions,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_RECRUITING_PERMISSIONS]: LoadingStatus.Success
        }
      };
    }

    case FETCH_RECRUITING_PERMISSIONS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_RECRUITING_PERMISSIONS]: LoadingStatus.Failure
        }
      };
    }

    case FETCH_PERFORMANCE_PERMISSIONS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_PERFORMANCE_PERMISSIONS]: LoadingStatus.Loading
        }
      };
    }

    case FETCH_PERFORMANCE_PERMISSIONS_SUCCESS: {
      const permissions = action.payload;
      return {
        ...state,
        performancePermission: permissions,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_PERFORMANCE_PERMISSIONS]: LoadingStatus.Success
        }
      };
    }

    case FETCH_PERFORMANCE_PERMISSIONS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_PERFORMANCE_PERMISSIONS]: LoadingStatus.Failure
        }
      };
    }

    case FETCH_TIME_MANAGEMENT_PERMISSIONS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_TIME_MANAGEMENT_PERMISSIONS]: LoadingStatus.Loading
        }
      };
    }

    case FETCH_TIME_MANAGEMENT_PERMISSIONS_SUCCESS: {
      const permissions = action.payload;
      return {
        ...state,
        timeManagementPermission: permissions,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_TIME_MANAGEMENT_PERMISSIONS]: LoadingStatus.Success
        }
      };
    }

    case FETCH_TIME_MANAGEMENT_PERMISSIONS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_TIME_MANAGEMENT_PERMISSIONS]: LoadingStatus.Failure
        }
      };
    }

    case FETCH_PAYROLL_PERMISSIONS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_PAYROLL_PERMISSIONS]: LoadingStatus.Loading
        }
      };
    }

    case FETCH_PAYROLL_PERMISSIONS_SUCCESS: {
      const permissions = action.payload;
      return {
        ...state,
        payrollPermission: permissions,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_PAYROLL_PERMISSIONS]: LoadingStatus.Success
        }
      };
    }

    case FETCH_PAYROLL_PERMISSIONS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_PAYROLL_PERMISSIONS]: LoadingStatus.Failure
        }
      };
    }

    case FETCH_COMP_BEN_PERMISSIONS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_COMP_BEN_PERMISSIONS]: LoadingStatus.Loading
        }
      };
    }

    case FETCH_COMP_BEN_PERMISSIONS_SUCCESS: {
      const permissions = action.payload;
      return {
        ...state,
        compBenPermission: permissions,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_COMP_BEN_PERMISSIONS]: LoadingStatus.Success
        }
      };
    }

    case FETCH_COMP_BEN_PERMISSIONS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_COMP_BEN_PERMISSIONS]: LoadingStatus.Failure
        }
      };
    }

    default: return state;
  }
};

export default modulePermissions;