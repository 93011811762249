import { Grid, Typography } from '@mui/material';
import React from 'react';
import SelectTag from '../../../../atoms/Select/SelectTag';
import { useSelector } from 'react-redux';
import InputField from '../../../../atoms/Input/Input';
import { FormFieldTypes } from '../../../../constants/common';

const RangeComponent = (props) => {
  const { label, form, onChange, min = false, fieldType } = props;

  const { list } = useSelector((state) => state.formBuilder);

  const getfieldRefName = () => {
    return list
      .filter((item) =>
        fieldType == FormFieldTypes.Date
          ? item.fieldType === FormFieldTypes.AutoPopulate ||
            item.fieldType === FormFieldTypes.UserBasedField ||
            item.fieldType === FormFieldTypes.ProRateField ||
            item.fieldType === fieldType
          : item.fieldType === FormFieldTypes.AutoPopulate ||
            item.fieldType === fieldType
      )
      .map((item) => {
        const { fieldRefName, fieldLabel } = item;
        return { ...item, id: fieldRefName, name: fieldLabel };
      });
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Typography variant="h5">{label}</Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectTag
          labelText="Field Name"
          name="name"
          dropDownList={[{ id: 'today', name: 'Today' }, ...getfieldRefName()]}
          size="small"
          fullWidth
          value={min ? form.min?.name : form.max?.name}
          onchange={(event) => onChange(event, min ? 'min' : 'max')}
          placeholder="Select"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectTag
          labelText="Operator"
          name="operator"
          dropDownList={[
            { id: '-', name: 'Minus' },
            { id: '+', name: 'Plus' },
          ]}
          size="small"
          fullWidth
          value={min ? form.min?.operator : form.max?.operator}
          onchange={(event) => onChange(event, min ? 'min' : 'max')}
          placeholder="Select"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectTag
          labelText="Type"
          name="type"
          dropDownList={[
            { id: 'days', name: 'Day' },
            { id: 'months', name: 'Month' },
          ]}
          size="small"
          fullWidth
          value={min ? form.min?.type : form.max?.type}
          onchange={(event) => onChange(event, min ? 'min' : 'max')}
          placeholder="Select"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InputField
          labelText={`No of ${
            (min ? form.min?.type : form.max?.type) ?? 'Days/Months'
          }`}
          name="value"
          size="small"
          fullWidth
          value={min ? form.min?.value : form.max?.value}
          onChange={(event) => onChange(event, min ? 'min' : 'max')}
          placeholder="Enter Number"
        />
      </Grid>
    </>
  );
};

export default RangeComponent;
