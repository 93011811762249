import { Button, styled } from '@mui/material';

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginRight: '8px', 
    border: '1px solid transparent',
    textTransform: 'capitalize',
    borderRadius: '20px',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  }));

export default StyledButton;