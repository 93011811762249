import React from 'react';
import { Grid, Typography } from '@mui/material';
import CardUI from '../ui-elements/CardUI';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ClaimBasicDetails = ({ claimType, employeeClaimData, claimName }) => {
  return (
    <React.Fragment>
      <CardUI>
        <Grid container>
          <Grid item lg={8} xs={12}>
            {/* {claimType == 2 &&
              `Destination : ${employeeClaimData[0].fromDest}`} */}
          </Grid>
          <Grid item lg={4} xs={12}>
            <Typography className="claim-ref">
              {employeeClaimData.referenceNumber ? (
                `Claim Reference Number :${employeeClaimData.referenceNumber}`
              ) : (
                <Skeleton />
              )}
            </Typography>
          </Grid>
        </Grid>
      </CardUI>
      <Grid className="user-request-info">
        <Grid xs={6} className="user-request-info-type">
          <Typography
            variant="h6"
            className="claim-type"
            style={{ textTransform: 'capitalize' }}
          >
            {claimName}
          </Typography>

          {claimType == 2 && (
            <Typography className="claim-dest">
              Destination :{' '}
              {`${employeeClaimData.fromDestination} - ${employeeClaimData.toDestination}`}
            </Typography>
          )}
        </Grid>
        <Grid xs={6} className="claim-ref user-request-info-reference">
          <Typography className="claim-amt">
            {employeeClaimData.amount ? (
              `Amount: $${Number(employeeClaimData.amount).toFixed(2)}`
            ) : (
              <Skeleton />
            )}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ClaimBasicDetails;
