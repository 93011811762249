import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import CurrencyFormEdit from './currency-form/currency-form-edit';
import CurrencyFormView from './currency-form/currency-form-view';
import { fieldsForCurrencyConversion } from '../utils/currencyConversion.utils';
import {
  APIRequestStatus,
  LoadingStatus,
} from '../../../../../constants/common';
import TryAgain from '../../../../molecules/ErrorState/error.component';
import { getCurrencyConversionDetails } from '../../../../../redux/currency-conversion/action';

const AddCurrency = (props) => {
  const { viewMode, setViewMode, currencyId, setShowForm, setCurrencyId } =
    props;
  const { currentUserRoleId } = useSelector((state) => state.main);

  const dispatch = useDispatch();
  const {
    currencyFormData,
    requestProcessing: {
      [APIRequestStatus.GET_CURRENCY_CONVERSION_FORM_DATA]: configLoadingStatus,
    },
  } = useSelector((state) => state.currencyConversion);

  useEffect(() => {
    if (currentUserRoleId) {
      if (currencyId) {
        dispatch(getCurrencyConversionDetails(currentUserRoleId, currencyId));
        setViewMode(true);
      }
    }
    return () => {
      setViewMode(false);
    };
  }, [currencyId, currentUserRoleId]);

  if (configLoadingStatus === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader
        show={configLoadingStatus === LoadingStatus.Loading && currencyId}
      />
      {configLoadingStatus === LoadingStatus.Success && viewMode ? (
        <CurrencyFormView
          formConfig={fieldsForCurrencyConversion}
          setViewMode={setViewMode}
          isNew={!currencyId}
          formData={currencyFormData}
          setShowForm={setShowForm}
        />
      ) : (
        <CurrencyFormEdit
          formConfig={fieldsForCurrencyConversion}
          setViewMode={setViewMode}
          formData={currencyFormData}
          isNew={!currencyId}
          setShowForm={setShowForm}
          currencyId={currencyId}
          setCurrencyId={setCurrencyId}
        />
      )}
    </>
  );
};

export default AddCurrency;
