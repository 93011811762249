import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import { getDate } from '../../../utils/formatter';
import { DateFormatters } from '../../../constants/dateConstants';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


export function DateRangeFields(props) {

  const { startDate, endDate, setStartDate, setEndDate, placeholder } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='en-gb'>
      <Box display='flex' alignItems={'center'} gap={2}>
        <Typography variant='body2' color={'neutral.dark80'}>
          Select Range From
        </Typography>
        <Box width={200} display='flex' alignItems={'center'} gap={2}>
          <DatePicker
            format='DD/MM/YYYY'
            onChange={(value) => setStartDate(value)}
            value={startDate ? getDate(startDate, DateFormatters.ddmmyyyy) : null}
            maxDate={endDate}
            slotProps={{
              textField: {
                size: 'small',
                variant: 'outlined',
                onKeyDown: (event) => event.preventDefault(),
              },
            }}
          />
        </Box>
        <Typography variant='body2' color={'neutral.dark80'}>
          To
        </Typography>
        <Box width={200} display='flex' alignItems={'center'} gap={2}>
          <DatePicker
            format='DD/MM/YYYY'
            onChange={(value) => setEndDate(value)}
            value={endDate ? getDate(endDate, DateFormatters.ddmmyyyy) : null}
            minDate={startDate}
            slotProps={{
              textField: {
                size: 'small',
                variant: 'outlined',
                onKeyDown: (event) => event.preventDefault(),
              },
            }}
          />
        </Box>
        <Box>
          <Button
            startIcon={<SettingsBackupRestoreOutlinedIcon />}
            sx={{ textTransform: 'capitalize' }}
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
            }
            }
          >
            Clear
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  );
}


export default function DateRange(props) {

  const { startDate, endDate, setStartDate, setEndDate, placeholder } = props;
  const [showRangeFields, setshowRangeFields] = useState(false);

  const handleClick = () => {
    setshowRangeFields(true);
  };
  return (
    <>
      {showRangeFields ? (
        <DateRangeFields
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate} setEndDate={setEndDate} />
      ) : (
        <Box>
          <FilterAltOutlinedIcon
            onClick={handleClick}
            sx={{ color: 'neutral.dark80', cursor: 'pointer', mt: '6px' }}
          />
        </Box>
      )}
    </>
  );
}
