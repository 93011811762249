import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Tab,
  Tabs,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import CardUI from '../../shared/components/ui-elements/CardUI';
import { useNavigate, useLocation } from 'react-router-dom';
import ApprovedIcon from '../../assets/approved.svg';
import ApprovalIcon from '../../assets/approval.svg';
import PaidIcon from '../../assets/paid.svg';
import InprocessIcon from '../../assets/inprocess.svg';
import Modal from '../../shared/components/ui-elements/Modal';
import ButtonUI from '../../shared/components/ui-elements/ButtonUI';

import DashboardTable from '../../components/dashboard-data-table/DashboardTable';

import axios from 'axios';

import { useSelector } from 'react-redux';

import './Dashboard.css';
import { CLAIM_LINKS, MAIN_CLAIMS } from '../../constants/constants';
import { getAuthToken } from '../forms/common-services/services';
import { redirectToLogin } from '../forms/common-services/functions';

const Dashboard = () => {
  const hostname = window.location.hostname;
  const tenant = hostname.split('.')[0] === 'localhost'? process.env.REACT_APP_LOCALHOST_CLIENT_ID : hostname.split('.')[0];
  const [value, setValue] = React.useState('1');
  const [dashboardCard, setDashboardCard] = useState([]);
  // const [isManager, setIsManager] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedClaimType, setSelectedClaimType] = useState('Select');
  const [claimTypeForPopup, setClaimTypeForPopup] = useState('');
  const [claimTypeNameForPopup, setClaimTypeNameForPopup] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // States for sorting functionality
  const [orderDirection, setOrderDirection] = useState('asc');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  // Sorting states ends

  const navigate = useNavigate();
  const params = useLocation();

  const currentUserType = useSelector(
    (state) => state && state.claims.currentUserType
  );

  const [tableData, setTableData] = useState([]);

  const cardData = [
    {
      status: 'In Process',
      count: dashboardCard?.InProcess,
      icon: InprocessIcon,
    },
    {
      status: 'Approval Pending',
      count: dashboardCard?.approvalPending ? dashboardCard.approvalPending : 0,
      icon: ApprovalIcon,
    },
    {
      status: 'Approved',
      count: dashboardCard?.approved,
      icon: ApprovedIcon,
    },
    {
      status: 'Paid Claims',
      count: dashboardCard?.paidClaims,
      icon: PaidIcon,
    },
  ];

  // Functions for sorting
  const handleRequestSort = (Event, property, toggle) => {
    //  console.log(property, 'propstoggle');
    const isAscending = valueToOrderBy === property && orderDirection === 'asc';
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? 'desc' : 'asc');
  };

  function descendingComparator(a, b, orderBy) {
    // console.log(b,'orderBy');
    if(orderBy!=='amount'){
    if (b[orderBy] < a[orderBy]) {
     
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  else{
    
    if (parseInt(b[orderBy]) < parseInt(a[orderBy])) {
      
      return -1;
    }
    if (parseInt(b[orderBy]) > parseInt(a[orderBy])) {
      return 1;
    }
    return 0;
  }
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const sortedRowInformation = (rowArray, comparator) => {
    const stablizedRowArray =
      rowArray && rowArray.map((el, index) => [el, index]);
    stablizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stablizedRowArray.map((el) => el[0]);
  };
  // Sorting functions ends

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Navigate By Raise A Claim
  function _navigateToPage(linkName, path, id) {
    let userData = JSON.parse(localStorage.getItem('userData'));
    var today = new Date();
    var birthDate = new Date(userData.userDetails?.DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    // console.log(age,'ageee');
    let dateOfJoining = userData && userData.userDetails.dateOfJoining;
    let d1 = new Date(dateOfJoining);
    let d2 = new Date();

    let diff = (d2.getTime() - d1.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    let finalDiff = Math.abs(Math.round(diff / 365.25));

    const type = linkName;

    let formIds = [
      '7',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
    ];
    let value = new RegExp(formIds.join('|')).test(id);

    if (path == 'study-loan' && finalDiff <= 2) {
      alert('Atleast 2 years completed in NTUC-ARU');
      return false;
    }
    if (path == 'retirement' && age <= 63) {
      alert('Retirement Gift is applicable to 63 years and above age');
      closeBackdropHandler();
      return false;
    }
    // console.log(selectedClaimType,'alert');
    if (value) {
      let updatedLinkName = type.toLowerCase().replaceAll(' ', '-');
      navigate(`/employee-portal/expenses/${updatedLinkName}`, {
        state: {
          type,
          id,
        },
      });
    } else {
      navigate(`/employee-portal/${path}`, {
        state: {
          type,
          id,
        },
      });
    }
    return;
  }

  // Gets client ID from localStorage
  const getClientID = () => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    return userData.userDetails.client.id;
  };

  // Filters the latest raised claim data.
  const getLatestRaisedClaim = (tableData) => {
    let latestClaim = Math.max(...tableData.map((o) => o.id));
    let latestClaimData = tableData.filter((claim) => {
      return claim.id == latestClaim;
    });
    if (latestClaimData.length > 0) {
      setValue(latestClaimData[0].type.id.toString());
    }
  };

  const getDataForEmployee = (userId) => {
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_API_LOCAL
        }/business-travels?empID=${userId}&clientID=${getClientID()}`,
        {
          headers: { Authorization: `Bearer ${getAuthToken()}`,
          'client_id': tenant },
        }
      )
      .then((res) => {
        // console.log(res.data.payload, 'pdpdpdpdd');
        setTableData(res.data.payload);
      })
      .catch((err) => {
        redirectToLogin(err);
      });
    getCardData(false);
  };
  // console.log(tableData,'tab;e');

  // const getDataForManager = async (userId) => {
  //   axios
  //     .get(
  //       // `${process.env.REACT_APP_BASE_API_LOCAL}/manager-claims?empID=${userId}&clientID=1`,
  //       `${process.env.REACT_APP_BASE_API_LOCAL}/manager-claims?role=Manager&typeId=2`,
  //       {
  //         headers: { Authorization: `Bearer ${getAuthToken()}` },
  //       }
  //     )
  //     .then((res) => {
  //       setTableData(res.data.payload);
  //       getLatestRaisedClaim(res.data.payload);
  //     })
  //     .catch((err) => {
  //       redirectToLogin(err);
  //     });
  //   getCardData(true);
  // };
  const getDataForRole = async () => {
    axios
      .get(
        // `${process.env.REACT_APP_BASE_API_LOCAL}/manager-claims?empID=${userId}&clientID=1`,
        `${process.env.REACT_APP_BASE_API_LOCAL}/manager-claims?role=${currentUserType}&typeId=${value}`,
        {
          headers: { Authorization: `Bearer ${getAuthToken()}`, 'client_id': tenant },
        }
      )
      .then((res) => {
        setTableData(res.data.payload);
        getLatestRaisedClaim(res.data.payload);
      })
      .catch((err) => {
        redirectToLogin(err);
      });
    getCardData(currentUserType !== 'Employee');
  };

  const getCardData = async (isManager) => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    let manager = localStorage.getItem('asProfile');
    if (manager !== 'Employee') {
      // setIsManager(true);
    }
    if (manager && userData && userData.userDetails) {
      try {
        const resp = await axios
          .get(
            `${
              process.env.REACT_APP_BASE_API_LOCAL
            }/manager-claims/stats?userID=${
              userData.userDetails.id
            }&isManager=${isManager}&typeId=${value}&clientID=${getClientID()}&role=${currentUserType}`,
            {
              headers: { Authorization: `Bearer ${getAuthToken()}`,
              'client_id': tenant },
            }
          )
          .catch((err) => {
            redirectToLogin(err);
          });
        setDashboardCard(resp.data);
      } catch (err) {
        console.error(err);
      }
    }
  };
  useEffect(() => {
    if (params && params.state) {
      if (params.state.claimType) {
        setValue(params.state.claimType.toString());
      } else {
        setValue('1');
      }
    }
  }, [params]);

  useEffect(() => {
    // let userData = JSON.parse(localStorage.getItem('userData');

    // if (currentUserType == 'Employee') {
    //   getDataForEmployee(userData.userDetails.id);
    // } else {
    //   getDataForRole();
    //   // getDataForManager(userData.userDetails.id);
    // }
  }, [currentUserType, value]);

  // useEffect(() => {
  //   setTableData(tableData);
  // }, [params, value]);

  // Create a claim links and popup handler
  const openClaimsMenuHandler = (linkData) => {
    if (linkData.key == 'EXP' || linkData.key == 'BFS') {
      setIsMenuOpen(true);
      setSelectedClaimType('Select');
      setClaimTypeForPopup(linkData.key);
      setClaimTypeNameForPopup(linkData.linkName);
    } else {
      _navigateToPage(linkData.linkName, linkData.path, linkData.id);
    }
  };
  const closeBackdropHandler = () => {
    setIsMenuOpen(false);
  };

  const handleChangeInput = (e) => {
    setSelectedClaimType(e.target.value);
  };

  // Mobile cliams link handler effect
  useEffect(() => {
    let forMobile = document.getElementById('for-mobile');
    let mobileLink = document.getElementById('mobile-link');

    if (forMobile !== null && mobileLink !== null) {
      if (isMobileMenuOpen) {
        forMobile.style.display = 'block';
        mobileLink.style.display = 'none';
      } else {
        forMobile.style.display = 'none';
        mobileLink.style.display = 'block';
      }
    }
  }, [isMobileMenuOpen]);

  // Mobile cliams link handler
  const openMobileMenuHandler = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <React.Fragment>
      <div className="main-dashboard-wrap">
        <div
          className={`left-content ${
            currentUserType !== 'Employee' ? 'active-dashboard' : ''
          }`}
        >
          <div className="left-content-overview">
            <div className="month-selector">
              <div className="month-data">
                {/* <p>June</p>
                <h1>Q2</h1> */}
              </div>
            </div>

            <div className="overview">
              <Grid className="kpi-main-wrap" container item xs={12}>
                {cardData &&
                  cardData.map((each, i) => (
                    <Grid
                      key={i}
                      xs={12}
                      sm={3}
                      md={3}
                      className="card-outer-wrap"
                    >
                      <CardUI
                        className="kpi-card"
                        padding="0 0.7em 0 0.7em"
                        height="132px"
                        bo
                      >
                        <div className="card-title">
                          <span>{each.status}</span>
                        </div>
                        <div className="card-data">
                          <div className="div-count">
                            <span className="card-count">
                              <h2>{each.count || 0}</h2>
                            </span>
                          </div>
                          <div className="icon-wrap">
                            <span>
                              <img src={each.icon} alt="Approval Pending" />
                            </span>
                          </div>
                        </div>
                      </CardUI>
                    </Grid>
                  ))}
              </Grid>
            </div>
          </div>

          <div className="tabs-main-wrap">
            <Box
              sx={{
                width: 'auto',
                typography: 'body1',
                marginTop: '15px',
                borderRadius: '10px',
              }}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  background: '#E7ECEA',
                  borderRadius: '10px',
                  overflow: 'hidden',
                }}
              >
                <TabContext value={value}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    indicatorColor="#000000"
                    scrollButtons="auto"
                    variant="scrollable"
                  >
                    {CLAIM_LINKS.map((claim) => {
                      return (
                        <Tab
                          key={claim.id}
                          sx={{
                            background:
                              claim.id == value ? '#ffffff' : '#E7ECEA',
                            color: '#777B81',
                          }}
                          // label={claim.linkName}
                          value={claim.id}
                          label={
                            <span
                              style={{
                                color:
                                  claim.id == value ? '#00AC94' : '#777B81',
                                textTransform: 'capitalize',
                                fontSize: '14px',
                              }}
                            >
                              {claim.id == value ? (
                                <b>{claim.linkName}</b>
                              ) : (
                                claim.linkName
                              )}
                            </span>
                          }
                        />
                      );
                    })}
                  </Tabs>
                  <div className="tab-data">
                    {CLAIM_LINKS.map((claim) => {
                      return (
                        <TabPanel key={claim.id} value={claim.id}>
                          <DashboardTable
                            id={Number(claim.id)}
                            tableData={tableData?.length > 0 ? tableData : []}
                            valueToOrderBy={valueToOrderBy}
                            orderDirection={orderDirection}
                            handleRequestSort={handleRequestSort}
                            sortedRowInformation={sortedRowInformation}
                            getComparator={getComparator}
                            currentUserType={currentUserType}
                            getDataForManager={getDataForRole}
                          />
                        </TabPanel>
                      );
                    })}
                  </div>
                </TabContext>
              </Box>
            </Box>
          </div>
        </div>

        {currentUserType === 'Employee' && (
          <div className="right-content">
            <CardUI background="#32333C">
              <div className="create-new-claim-links">
                <h3>Raise A Claim</h3>
                <div className="links-wrap">
                  {MAIN_CLAIMS &&
                    MAIN_CLAIMS.map((linkData) => {
                      return (
                        <div
                          className="link-wrap"
                          key={linkData.id}
                          onClick={() => openClaimsMenuHandler(linkData)}
                        >
                          <div className="link-icon-wrap">
                            <span>
                              <img
                                src={linkData.icon}
                                alt={linkData.linkName}
                              />
                            </span>
                          </div>
                          <div className="link-title-wrap">
                            <span>{linkData.linkName}</span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </CardUI>
          </div>
        )}
      </div>

      {currentUserType === 'Employee' && (
        <React.Fragment>
          <div
            id="for-mobile"
            className="for-mobile right-content"
            onClick={openMobileMenuHandler}
          >
            <CardUI className="right-links-card" background="#32333C">
              <div className="create-new-claim-links">
                <h3>Raise A Claim</h3>
                <div className="links-wrap">
                  {MAIN_CLAIMS &&
                    MAIN_CLAIMS.map((linkData) => {
                      return (
                        <div
                          className="link-wrap"
                          key={linkData.id}
                          onClick={() => openClaimsMenuHandler(linkData)}
                        >
                          <div className="link-icon-wrap">
                            <span>
                              <img
                                src={linkData.icon}
                                alt={linkData.linkName}
                              />
                            </span>
                          </div>
                          <div className="link-title-wrap">
                            <span>{linkData.linkName}</span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </CardUI>
          </div>
          <div
            id="mobile-link"
            className="mobile-links"
            onClick={openMobileMenuHandler}
          >
            <p>Raise A Claim</p>
          </div>
        </React.Fragment>
      )}

      <Modal
        show={isMenuOpen}
        onCancel={closeBackdropHandler}
        header={<h2>Choose {claimTypeNameForPopup} Type</h2>}
        contentClass="modal-content"
        footerClass="footer-content"
        submitButton={
          <ButtonUI
            backgroundColor="#00AC94"
            color="#ffffff"
            size="small"
            padding="3px 20px"
            onclick={closeBackdropHandler}
          >
            Close
          </ButtonUI>
        }
      >
        <Grid item xs={12} lg={4} md={4} sm={4}>
          <label htmlFor="">Select</label>
          <FormControl sx={{ minWidth: 120 }} fullWidth size="small">
            <Select
              value={selectedClaimType}
              onChange={(e) => handleChangeInput(e)}
              name="select-claim-type"
              displayEmpty
              inputProps={{
                'aria-label': 'Without label',
              }}
              variant="outlined"
              autoWidth
            >
              {CLAIM_LINKS &&
                CLAIM_LINKS.map((linkData, i) => {
                  if (linkData.key) {
                    if (linkData.key == claimTypeForPopup) {
                      return (
                        <MenuItem
                          key={i}
                          value={linkData.linkName}
                          onClick={() =>
                            _navigateToPage(
                              linkData.linkName,
                              linkData.path,
                              linkData.id
                            )
                          }
                        >
                          {linkData.linkName}
                        </MenuItem>
                      );
                    }
                  }
                })}
            </Select>
          </FormControl>
        </Grid>
      </Modal>
    </React.Fragment>
  );
};

export default Dashboard;
