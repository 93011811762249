import { clone } from 'lodash';
import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import {
  GET_ALL_CITIES_FAILURE, GET_ALL_CITIES_REQUEST, GET_ALL_CITIES_SUCCESS,
} from './actionTypes';

const initialState = {
  requestProcessing: {
    [APIRequestStatus.GET_ALL_CITIES_LIST]: LoadingStatus.Loading,
    [APIRequestStatus.GET_ALL_COUNTRIES_LIST]: LoadingStatus.Loading
  },
  countries: [],
  cities: [],
  states: [],
  callingCodes: []
};

const countriesAndCitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CITIES_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_CITIES_LIST]: LoadingStatus.Loading
        }
      };
    }

    case GET_ALL_CITIES_SUCCESS: {
      const res = action.payload;
      return getStateOnCitiesListSuccess(state, res);
    }

    case GET_ALL_CITIES_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_CITIES_LIST]: LoadingStatus.Failure
        }
      };
    }

    default:
      return state;
  }
};

const getStateOnCitiesListSuccess = (state, res) => {
  const uniqueCitiesSet = new Set();
  const uniqueStatesSet = new Set();
  const uniqueCountriesSet = new Set();
  const uniquecallingCodeSet = new Set();
  const newCountries = [];
  const newcallingCode = [];
  const newCities = [];
  const newStates = [];
 
  res.map((key) => {
    const { id, cityName, stateName, countryId, countryName, callingCode, isDefault } = key;

    const cityKey = getKey(key, ['id']);
    if (!uniqueCitiesSet.has(cityKey)) {
      newCities.push({ id, cityName, stateName, countryId, countryName });
      uniqueCitiesSet.add(cityKey);
    }

    const stateKey = getKey(key, ['id', 'stateName']);
    if (!uniqueStatesSet.has(stateKey)) {
      newStates.push({ id,stateName });
      uniqueStatesSet.add(stateKey);
    }

    const countryKey = getKey(key, ['countryId']);
    if (!uniqueCountriesSet.has(countryKey)) {
      newCountries.push({ id: countryId, countryName, isDefault });
      uniqueCountriesSet.add(countryKey);
    }

    const callingCodeKey = getKey(key, ['countryId', 'callingCode']);
    if (!uniquecallingCodeSet.has(callingCodeKey)) {
      newcallingCode.push({ id: countryId, callingCode: `${countryName} (${callingCode})` , isDefault});
      uniquecallingCodeSet.add(callingCodeKey);
    }


  });

  newCities.sort(function (a, b) {
    if (a.cityName < b.cityName)
      return -1;
    if (a.cityName > b.cityName)
      return 1;
    return 0;
  });

  newStates.sort(function (a, b) {
    if (a.stateName < b.stateName)
      return -1;
    if (a.stateName > b.stateName)
      return 1;
    return 0;
  });

  newcallingCode.sort(function (a, b) {
    if (a.callingCode < b.callingCode)
      return -1;
    if (a.callingCode > b.callingCode)
      return 1;
    return 0;
  });

  newCountries.sort(function (a, b) {
    if (a.countryName < b.countryName)
      return -1;
    if (a.countryName > b.countryName)
      return 1;
    return 0;
  });

  const newState = {
    ...state,
    cities: newCities,
    states: newStates,
    countries: newCountries,
    callingCodes: newcallingCode
  };

  newState.requestProcessing[APIRequestStatus.GET_ALL_CITIES_LIST] = LoadingStatus.Success;
  return newState;
};

function getKey(obj, props) {
  return props.map(prop => obj[prop]).join('|');
}

export default countriesAndCitiesReducer;

