import API from './api';
export class Payroll extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL + '/payroll/' || '';
  constructor() {
    super(Payroll.baseURL);
  }

  getPayrollDetails = async (roleId, payrollId, moduleId, isApprover = false) => (
    this.responsify(await this.instance.get('details', { params: { payrollId, roleId, moduleId, isApprover } }))
  );

  updatePayrollInfo = async (body) => (
    this.responsify(await this.instance.post('save', body))
  );

  getAllPayroll = async (roleId, searchQuery, startDate, endDate, employeeId, appModuleId, moduleId) => (
    this.responsify(await this.instance.get('list', {
      params:
        { roleId, searchQuery, startDate, endDate, employeeId, appModuleId, moduleId }
    })));
  
  getAllPayrollRunDates = async (roleId,startDate,endDate) => (
      this.responsify(await this.instance.get('payroll_run_dates_list',{params:{roleId,startDate,endDate}})));

  updateStatus = async (payrollIds) => (
    this.responsify(await this.instance.post('update-status', payrollIds)));
    updatePrlPayrollRuns = async (body) => (
      this.responsify(await this.instance.post('update-prl-payroll-run', body)));
}
