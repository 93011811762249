import React from 'react';
import { Box } from '@mui/material';
import ReportsDashboard from './report-dashboard/reports-dashboard.component';

const Reports = () => {
  return (
      <ReportsDashboard />
  );
};

export default Reports;
