import { Box } from '@mui/material';
import React from 'react';
import TabBar from '../../../../../atoms/Tabs/TabBar';
import PayrollAssign from '../PayrollAssign';
import UpcomingPayrollView from './UpcomingPayrollView';

const ManagePayroll = (props) => {
  const {
    viewMode,
    isPublished,
    onEditClick,
    onPublishClick,
    isEditDisabled,
    handleDiscard,
    disabledSave,
    labelText,
    formConfig,
    payStrInfo,
    setPayStrInfo,
    setAdditionComponents,
    setDeductionComponents,
    setBasicComponents,
    basicComponents,
    userId,
    additionComponents,
    deductionComponents,
    totalAdditions,
    totalDeductions,
    payrollEditDisabled,
    payrollError,
    setPayrollError,
    setIsPayrollChange,
    employeeBenifits
  } = props;
  const payrollTabs = [
    {
      name: 'current-payroll',
      label: 'Current',
      title: 'Current',
      hide: false,
      panel: (
        <PayrollAssign
          formConfig={formConfig}
          payStrInfo={payStrInfo}
          setPayStrInfo={setPayStrInfo}
          additionComponents={additionComponents}
          setAdditionComponents={setAdditionComponents}
          deductionComponents={deductionComponents}
          setDeductionComponents={setDeductionComponents}
          viewMode={viewMode}
          basicComponents={basicComponents}
          setBasicComponents={setBasicComponents}
          userId={userId}
          totalAdditions={totalAdditions}
          totalDeductions={totalDeductions}
          payrollEditDisabled={payrollEditDisabled}
          payrollError={payrollError}
          setPayrollError={setPayrollError}
          setIsPayrollChange={setIsPayrollChange}
          isPublished={isPublished}
          onEditClick={onEditClick}
          onPublishClick={onPublishClick}
          isEditDisabled={isEditDisabled}
          handleDiscard={handleDiscard}
          disabledSave={disabledSave}
          labelText={labelText}
          employeeBenifits={employeeBenifits}
        />
      ),
    },
    {
      name: 'future-payroll',
      label: 'Upcoming',
      title: 'Upcoming',
      hide: false,
      panel: (
        <UpcomingPayrollView
          formConfig={formConfig}
          payStrInfo={payStrInfo}
          additionComponents={additionComponents}
          deductionComponents={deductionComponents}
          viewMode={viewMode}
          basicComponents={basicComponents}
          userId={userId}
          totalAdditions={totalAdditions}
          totalDeductions={totalDeductions}
          isPublished={isPublished}
          onEditClick={onEditClick}
          onPublishClick={onPublishClick}
          isEditDisabled={isEditDisabled}
          handleDiscard={handleDiscard}
          disabledSave={disabledSave}
          labelText={labelText}
        />
      ),
    },
  ];

  return (
    <Box>
      <TabBar tabPanels={payrollTabs} disableCreate />
    </Box>
  );
};

export default ManagePayroll;
