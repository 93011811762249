import { FormFieldTypes } from '../../../constants/common';

export const AllowedDate = [
  { id: 3, name: 'Set Range' },
  { id: 2, name: 'Disable Past' },
  { id: 1, name: 'Disable Future' },
];

export const FileValidationFormConfig = [
  {
    fieldLabel: 'Maximum Number of Files',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.Text,
    dataType: FormFieldTypes.Number,
    isRequired: false,
    fieldRefName: 'maxFiles',
    tooltipText: 'By default maximum 3 files are allowed',
  },
  {
    fieldLabel: 'Maximum Size (in MB)',
    placeholderText: 'Type here',
    fieldType: FormFieldTypes.Text,
    dataType: FormFieldTypes.Number,
    isRequired: false,
    fieldRefName: 'maxSize',
    tooltipText: 'By default max size is 5mb are allowed',
  },
  {
    fieldLabel: 'Allowed Files Formats',
    placeholderText: 'Select',
    fieldType: FormFieldTypes.MutliSelect,
    isRequired: false,
    fieldRefName: 'allowedFiles',
    tooltipText: 'By default jpeg/png/pdf are allowed',
  },
];

export const allowedFiles = [
  {
    id: 'image/jpg',
    name: 'JPG',
  },
  {
    id: 'image/jpeg',
    name: 'JPEG',
  },
  {
    id: 'image/png',
    name: 'PNG',
  },
  {
    id: 'application/pdf',
    name: 'PDF',
  },
  {
    id: 'text/csv',
    name: 'CSV',
  },
];
