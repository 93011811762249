import { Box, Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { DateRangePro } from '../../../molecules/DateRange/DateRangePro';
import ModalMapForPayroll from '../DropButtonActionButton/ModalMapForPayroll';
import ListView from '../../../../atoms/DataList/ListView';
import { GiroFileDashHeaders, payrollHeaderCells } from '../Utils/PayrollUtils';
import { useDebounce } from 'usehooks-ts';
import {
  APIRequestStatus,
  DebounceTimer,
  LoadingStatus,
} from '../../../../constants/common';
import { getAllPayrollRunDates } from '../../../../redux/payroll/action';
import TryAgain from '../../../molecules/ErrorState/error.component';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';

import {
  getFormattedStartOrEndDateForAnyPastMonthFromCurrent,
  getFormattedValues,
} from '../../../../utils/formatter';
import { PayrollPermissions } from '../../Constant/Constant';
import { AppLevelPermissions } from '../../../OnBoarding/Organization/constants/onboarding.constants';
import ModalMapForGiroFile from '../DropDownActionGiroFile/ModalMapForGiroFile';
import { SettingsBackupRestoreOutlined } from '@mui/icons-material';

const BankGiroFileDashboard = () => {
  const { currentUserRoleId, appPermissions } = useSelector(
    (state) => state.main
  );
  const { payrollPermission } = useSelector((state) => state.modulePermissions);
  const { payrollRunList, requestProcessing } =
    useSelector((state) => state.payroll) || {};
  const { [APIRequestStatus.GET_ALL_PAYROLL_RUNS]: listLoading } =
    requestProcessing || {};

  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState([
    getFormattedStartOrEndDateForAnyPastMonthFromCurrent(1, 'month', true),
    getFormattedStartOrEndDateForAnyPastMonthFromCurrent(1, 'month', false),
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUserRoleId) {
      dispatch(
        getAllPayrollRunDates(
          currentUserRoleId,
          selectedDate[0],
          selectedDate[1]
        )
      );
    }
  }, [currentUserRoleId, selectedDate]);

  useEffect(() => {
    setCheckedItems([]);
  }, [payrollRunList]);

  const resetFilters = () => {
    setSelectedDate([
      getFormattedStartOrEndDateForAnyPastMonthFromCurrent(1, 'month', true),
      getFormattedStartOrEndDateForAnyPastMonthFromCurrent(1, 'month', false),
    ]);
  };

  const isDisabledReset = () => {
    return (
      selectedDate[0].isSame(
        getFormattedStartOrEndDateForAnyPastMonthFromCurrent(1, 'month', true)
      ) &&
      selectedDate[1].isSame(
        getFormattedStartOrEndDateForAnyPastMonthFromCurrent(1, 'month', false)
      )
    );
  };
  const rowData = payrollRunList?.map((items) => {
    const {
      id,
      payrollRunTypeCode,
      payrollRunDate,
      payMonth,
      employeeCount,
      bankGiroFileUrl,
      bankGiroFileDownloadedUtc,
      bankGiroFileDownloadedBy,
    } = items;
    return {
      payrollRunDate: getFormattedValues('YYYY-MM-DD', payrollRunDate),
      payMonth,
      employeeCount,
      payrollRunTypeCode,
      bankFileDownload: bankGiroFileUrl ? 'Available' : 'Not Available',
      bankGiroFileDownloadedUtc: bankGiroFileDownloadedUtc ? getFormattedValues(
        'YYYY-MM-DD',
        bankGiroFileDownloadedUtc
      ): '',
      bankGiroFileDownloadedBy: bankGiroFileDownloadedBy?.displayName,
      id: {
        hide: true,
        value: {
          id,
          bankGiroFileUrl,
          payMonth
        },
      },
    };
  });

  if (listLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }

  return (
    <>
      <CircularLoader show={listLoading === LoadingStatus.Loading} />
      <Box display="flex" justifyContent="flex-end" gap={2} mb={2} pr={2}>
        <DateRangePro
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <Button
          startIcon={<SettingsBackupRestoreOutlined />}
          sx={{ textTransform: 'capitalize' }}
          disabled={isDisabledReset()}
          onClick={resetFilters}
        >
          Clear
        </Button>
        <Box>
          <ModalMapForGiroFile
            checkedItems={checkedItems}
            currentUserRoleId={currentUserRoleId}
            selectedDate={selectedDate}
            appModuleId={appPermissions?.[AppLevelPermissions.Payroll]?.id}
            moduleId={
              payrollPermission?.[PayrollPermissions.ALLOW_GIRO_FILE]?.id
            }
          />
        </Box>
      </Box>
      <ListView
        headCells={GiroFileDashHeaders}
        rowsData={rowData || []}
        showSerialNo={true}
        showCheckbox={true}
        setCheckedItems={setCheckedItems}
        checkedItems={checkedItems}
        allowSelectAll={true}
      />
    </>
  );
};

export default BankGiroFileDashboard;
