import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography, Link, IconButton } from '@mui/material';
import CardView from '../../../atoms/DataList/CardView';
import { getLocationInfo, postLocation, deleteLocation, updateLocation } from '../../../redux/onboarding/action';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { isNotEmptyString } from './utils/onboarding.util';
import { FormHeader } from '../common/formHeader';
import { locationListHeader } from './utils/onboarding.util';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ListView from '../../../atoms/DataList/ListView';
import ModalDialog from '../../../atoms/Modal/ModalDialog';
import { doesNameAlreadyExists, getHelperTextOnInputInvalid, scrollToNewRow } from './utils/onboarding.util';
import { APIRequestStatus, LoadingStatus } from '../../../constants/common';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import { locations as locationtConst } from './constants/onboarding.constants';
import TryAgain from '../../molecules/ErrorState/error.component';


export default function Locations() {

  const [locationName, setLocationName] = useState('');
  const [errors, setErrors] = useState({});
  const [name, setName] = useState();
  const [id, setId] = useState('');
  const [configureIndex, setConfigureIndex] = useState(-1);
  const [context, setContext] = useState();
  const [showModal, setshowModal] = useState(false);
  const [allowAdd, setAllowAdd] = useState(true);
  const [tableRef, setTableRef] = useState();

  const dispatch = useDispatch();
  const { locations, permissions, requestProcessing: { [APIRequestStatus.POST_LOCATION_INFO]: postLocLoadingStatus,
    [APIRequestStatus.GET_LOCATION_INFO]: getLocationsLoading
  } } = useSelector((state) => {
    return state.onBoarding;
  });
  useEffect(() => {
    if (isEmpty(locations))
      dispatch(getLocationInfo());
  }, []);

  useEffect(() => {
    if (postLocLoadingStatus === LoadingStatus.Success) {
      scrollToNewRow(tableRef);
    }
  }, [postLocLoadingStatus]);

  const handleAddLocation = () => {
    if (isNotEmptyString(locationName)) {
      dispatch(postLocation(locationName.trim()));
      setLocationName('');
    }
    else {
      setAllowAdd(false);
      setErrors(errors);
    }
  };
  const onClickEdit = (id, name, index) => {
    setshowModal(true);
    setContext('edit');
    setId(id);
    setName(name);
    setConfigureIndex(index);
  };
  const handleUpdateLocation = () => {
    dispatch(updateLocation(name, id, configureIndex));
    setshowModal(false);
  };
  const handleDelete = (id, index) => {
    setshowModal(true);
    setContext('delete');
    setId(id);
    setConfigureIndex(index);
  };
  const onClickAddIcon = () => {
    setshowModal(true);
    setContext('add');
  };

  const onChange = (event) => {
    const name = event.target.value;
    if (!doesNameAlreadyExists(locations, name) && (isNotEmptyString(name))) {
      setAllowAdd(true);
    }
    else {
      setAllowAdd(false);
    }
    setLocationName(event.target.value);
    setName(event.target.value);
  };

  const onModalClose = () => {
    setshowModal(false);
    setAllowAdd(true);
    setLocationName('');
  };


  const renderLayout = () => {
    return (
      <Box>
        <FormHeader title='Locations' />
        {locations?.length < 9 ?
          <Grid container rowSpacing={3} columnSpacing={10}>
            {locations?.map((item, index) => {
              const { id, name } = item;
              return (
                <Grid key={id} item xs={6} md={4}>
                  <CardView
                    title={name}
                    buttonText='Edit'
                    onClick={() => onClickEdit(id, name, index)}
                    onClickDelete={() => handleDelete(id, index)}
                    primaryButtonDisabled={!permissions[locationtConst]?.allowEdit}
                    deleteButtonDisabled={!permissions[locationtConst]?.allowEdit}
                  />
                </Grid>
              );
            })}
            {permissions[locationtConst]?.allowAdd && (
              <Grid item xs={6} md={4}>
                <CardView
                  buttonText='Add Location'
                  onClick={() => handleAddLocation()}
                  editable={true}
                  onChange={onChange}
                  name="name"
                  value={locationName}
                  placeholder="Add Location"
                  helperText={getHelperTextOnInputInvalid(allowAdd)}
                  primaryButtonDisabled={!allowAdd}
                />
              </Grid>
            )}
          </Grid>
          : <ListView
            headCells={locationListHeader}
            rowsData={locationList}
            onAddClick={() => onClickAddIcon()}
            showSerialNo={true}
            setTableRef={setTableRef}
            showAddIcon={permissions[locationtConst]?.allowAdd}
            addDisabled={!permissions[locationtConst]?.allowAdd}
          />
        }
      </Box>
    );

  };
  const locationList = locations?.map((loc, index) => {
    const { id, name } = loc;
    if (!permissions[locationtConst]?.allowEdit) {
      return {
        name: name || '',
      };
    }
    return {
      name: name || '',
      actions: <>
        <IconButton onClick={() => onClickEdit(id, name, index)}
          disabled={!permissions[locationtConst]?.allowEdit}>
          <EditOutlinedIcon /></IconButton>
        <IconButton onClick={() => handleDelete(id, index)}
          disabled={!permissions[locationtConst]?.allowEdit}>
          <DeleteOutlineOutlinedIcon /></IconButton>
      </>
    };
  });

  const modalMap = {
    delete: {
      title: 'Delete Location',
      description: 'Are you sure you want to Delete Location?',
      primaryAction: 'Delete',
      onClickConfirm: () => dispatch(deleteLocation(id, configureIndex)),
      severity: 'error'
    },
    add: {
      title: 'Add Location',
      componentProps: {
        onInputChange: onChange,
        inputValue: locationName,
        inputPlaceHolder: 'Add Location',
        helperText: getHelperTextOnInputInvalid(allowAdd),
        countBox: false
      },
      primaryAction: 'Add',
      onClickConfirm: () => handleAddLocation(),
      severity: 'success'
    },
    edit: {
      title: 'Edit Location',
      componentProps: {
        inputId: id,
        onInputChange: onChange,
        inputValue: name,
        inputPlaceHolder: 'Add Location',
        helperText: getHelperTextOnInputInvalid(allowAdd),
        countBox: false
      },
      primaryAction: 'Update',
      onClickConfirm: () => handleUpdateLocation(),
      severity: 'success'
    }
  };
  if (getLocationsLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  } else {
    return (
      <Box>
        <CircularLoader show={getLocationsLoading === LoadingStatus.Loading} />
        {renderLayout()}
        <ModalDialog
          severity={modalMap[context]?.severity}
          title={modalMap[context]?.title}
          description={modalMap[context]?.description}
          primaryAction={modalMap[context]?.primaryAction}
          secondaryAction="Cancel"
          showModal={showModal}
          onClickClose={() => onModalClose()}
          onClickConfirm={modalMap[context]?.onClickConfirm}
          primaryButtonDisabled={!allowAdd}
          component={context !== 'delete'}
          componentProps={modalMap[context]?.componentProps}
        />
      </Box>
    );
  }
}
