import { TimeManagementServices } from '../../services/timeManagementServices';
import { GET_ALL_TIME_MANAGEMENT_FAILURE, GET_ALL_TIME_MANAGEMENT_REQUEST, GET_ALL_TIME_MANAGEMENT_SUCCESS } from './actionTypes';

const getAllTimeManagementRequest = () => (
  {
    type: GET_ALL_TIME_MANAGEMENT_REQUEST
  }
);

const getAllTimeManagementSuccess = (payload) => (
  {
    type: GET_ALL_TIME_MANAGEMENT_SUCCESS,
    payload
  }
);

const getAllTimeManagementFailure = () => (
  {
    type: GET_ALL_TIME_MANAGEMENT_FAILURE
  }
);

export const getAllTimeManagement = (currentUserRoleId, searchInput, startDate, endDate, employeeId) => async (dispatch) => {
  dispatch(getAllTimeManagementRequest());
  return new TimeManagementServices().getAllTimeManagement(currentUserRoleId, searchInput, startDate, endDate, employeeId).then((res) => {
    dispatch(getAllTimeManagementSuccess(res));
    return true;
  }).catch((err) => {
    dispatch(getAllTimeManagementFailure());
    return false;
  });
};

