import React from 'react';
import { useSelector } from 'react-redux';


export const goalsHeadCells = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'Goal',
  },
  {
    id: 'goalStartDate',
    numeric: false,
    disablePadding: true,
    label: 'Start Date',
  },
  {
    id: 'goalEndDate',
    numeric: false,
    disablePadding: false,
    label: 'End Date',
  },
  {
    id: 'assignTo',
    numeric: false,
    disablePadding: false,
    label: 'Assigned to'
  },
  {
    id: 'assignBy',
    numeric: false,
    disablePadding: false,
    label: 'Assigned by'
  },
  {
    id: 'priority',
    numeric: false,
    disablePadding: false,
    label: 'Priority',
  },
  {
    id: 'createdOn',
    numeric: false,
    disablePadding: false,
    label: 'Created On',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
];

export const appraisalEmpHeadCells = [
  {
    id: 'goals',
    numeric: false,
    disablePadding: true,
    label: 'Goal',
  },
  {
    id: 'emp',
    numeric: false,
    disablePadding: true,
    label: 'Assigned by',
  },
  {
    id: 'assignTo',
    numeric: false,
    disablePadding: true,
    label: 'Employee',
  },
  {
    id: 'goalStartDate',
    numeric: false,
    disablePadding: true,
    label: <div style={{textAlign:'center'}}>Goal<br/>Start Date</div>,
  },
  {
    id: 'goalEndDate',
    numeric: false,
    disablePadding: false,
    label: <div style={{textAlign:'center'}}>Goal<br/>End Date</div>,
  },
  {
    id: 'sDate',
    numeric: false,
    disablePadding: false,
    label: <div style={{textAlign:'center'}}>Appraisal<br/>Start Date</div>,
  },
  {
    id: 'eDate',
    numeric: false,
    disablePadding: false,
    label: <div style={{textAlign:'center'}}>Appraisal<br/>End Date</div>,
  },
  {
    id: 'createdOn',
    numeric: false,
    disablePadding: false,
    label: 'Created On',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'rating',
    numeric: false,
    disablePadding: false,
    label: 'Rating',
  },
];

export const appraisalApproveHeadCells =[
  {
    id: 'emp',
    numeric: false,
    disablePadding: true,
    label: 'Employee',
  },
  {
    id: 'assignBy',
    numeric: false,
    disablePadding: true,
    label: 'Assigned By',
  },
  {
    id: 'goals',
    numeric: false,
    disablePadding: true,
    label: 'Goal',
  },
  {
    id: 'goalStartDate',
    numeric: false,
    disablePadding: true,
    label: <div style={{textAlign:'center'}}>Goal<br/>Start Date</div>,
  },
  {
    id: 'goalEndDate',
    numeric: false,
    disablePadding: false,
    label: <div style={{textAlign:'center'}}>Goal<br/>End Date</div>,
  },
  {
    id: 'sDate',
    numeric: false,
    disablePadding: false,
    label: <div style={{textAlign:'center'}}>Appraisal<br/>Start Date</div>,
  },
  {
    id: 'eDate',
    numeric: false,
    disablePadding: false,
    label: <div style={{textAlign:'center'}}>Appraisal<br/>End Date</div>,
  },
  {
    id: 'createdOn',
    numeric: false,
    disablePadding: false,
    label: 'Created On',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'rating',
    numeric: false,
    disablePadding: false,
    label: 'Rating',
  },
];