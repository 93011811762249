import axios from 'axios';
import React from 'react';

const downloadWithCustomName = (url='', name, promise = false) => {
  const hostname = window.location.hostname;
  const tenant = hostname.split('.')[0] === 'localhost'? process.env.REACT_APP_LOCALHOST_CLIENT_ID : hostname.split('.')[0];

  let extension = '';
  let Name = name;
  if(typeof url === 'string'){
    const urlArr = url.split('.');
    extension = urlArr[urlArr.length-1];
    if(extension) {
      Name = `${name}.${extension}`;
    }
  }

  if(!promise){
    axios({
      method:'GET',
      url:`${url}`,
      // url:`${process.env.REACT_APP_DOCUMENT_PATH}${url}`,
      headers: {
        'Content-Type': 'application/octet-stream',
        'client_id': tenant
      },
      responseType: 'blob'
    }
    )
      .then(response => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(response.data);
        console.log('name', url);
        a.href = url;
        a.download = Name;
        a.click();
      })
      .catch(err => {
        console.log('error', err, process);
      });
  }else{
    return new Promise((res,rej)=>{
      axios({
        method:'GET',
        url:`${url}`,
        // url:`${process.env.REACT_APP_DOCUMENT_PATH}${url}`,
        headers: {
          'Content-Type': 'application/octet-stream',
          'client_id': tenant
        },
        responseType: 'blob'
      }
      )
        .then(response => {
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(response.data);
          console.log('name', url);
          a.href = url;
          a.download = Name;
          a.click();
          res(true);
        })
        .catch(err => {
          console.log('error', err);
          res(true);
        });

    });
  }
  
};

const Downloader = ()=> {

  return (
    <div style={{display:'none'}}>
      {/* {files.map((fileName,i)=>
        // eslint-disable-next-line react/jsx-key
        (<iframe key={fileName} src={baseUrl + fileName} title={i}></iframe>)
        // (<iframe src={baseUrl+fileName} />)
      )} */}
    </div>
  );
};

export default Downloader;

export {downloadWithCustomName};