import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import {
  GET_ALL_BENEFITS_LIST_FAILURE,
  GET_ALL_BENEFITS_LIST_REQUEST,
  GET_ALL_BENEFITS_LIST_SUCCESS,
  GET_ALL_USER_BENEFITS_LIST_FAILURE,
  GET_ALL_USER_BENEFITS_LIST_REQUEST,
  GET_ALL_USER_BENEFITS_LIST_SUCCESS,
  GET_BENEFITS_DETAILS_FAILURE,
  GET_BENEFITS_DETAILS_REQUEST,
  GET_BENEFITS_DETAILS_SUCCESS,
  RESET_BENEFITS,
} from './actionTypes';

const initialState = {
  benefitsList: [],
  userBenefitsList: [],
  benefitsData: {},
  idPropertiesMap: null,
  requestProcessing: {
    [APIRequestStatus.GET_ALL_BENEFITS_LIST]: LoadingStatus.Loading,
    [APIRequestStatus.GET_BENEFITS_DETAILS]: LoadingStatus.Loading,
    [APIRequestStatus.GET_ALL_USER_BENEFITS_LIST]: LoadingStatus.Loading
  },
};

export const benefitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BENEFITS_LIST_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_BENEFITS_LIST]: LoadingStatus.Loading,
        },
      };
    }

    case GET_ALL_BENEFITS_LIST_SUCCESS: {
      const benefitsList = action.payload;
      const idPropertiesMap = {};
      benefitsList.forEach((item) => {
        const { id, name } = item;
        Object.assign(idPropertiesMap, { [id]: name });
      });
      return {
        ...state,
        benefitsList: benefitsList,
        idPropertiesMap: idPropertiesMap,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_BENEFITS_LIST]: LoadingStatus.Success,
        },
      };
    }

    case GET_ALL_BENEFITS_LIST_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_BENEFITS_LIST]: LoadingStatus.Failure,
        },
      };
    }

    case GET_BENEFITS_DETAILS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_BENEFITS_DETAILS]: LoadingStatus.Loading,
        },
      };
    }

    case GET_BENEFITS_DETAILS_SUCCESS: {
      const res = action.payload;
      return {
        ...state,
        benefitsData: res,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_BENEFITS_DETAILS]: LoadingStatus.Success,
        },
      };
    }

    case GET_BENEFITS_DETAILS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_BENEFITS_DETAILS]: LoadingStatus.Failure,
        },
      };
    }

    case GET_ALL_USER_BENEFITS_LIST_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_USER_BENEFITS_LIST]: LoadingStatus.Loading,
        },
      };
    }

    case GET_ALL_USER_BENEFITS_LIST_SUCCESS: {
      const userBenefitsList = action.payload;
      return {
        ...state,
        userBenefitsList: userBenefitsList,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_USER_BENEFITS_LIST]: LoadingStatus.Success,
        },
      };
    }

    case GET_ALL_USER_BENEFITS_LIST_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_USER_BENEFITS_LIST]: LoadingStatus.Failure,
        },
      };
    }

    case RESET_BENEFITS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
