import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import CurrencyFormatter from '../../../../../atoms/CurrencyFormater/CurrencyFormater';
import { LightTooltip } from '../../../../../atoms/Tooltip/LightTooltip';
import { InfoOutlined } from '@mui/icons-material';

const Total = (props) => {
  const { label, total, toolTip } = props;

  return (
    <>
      <Divider sx={{ padding: 1 }} />
      <Grid container rowSpacing={3} columnSpacing={8} pr={8}>
        <Grid item md={6} xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Typography mt={2} variant="subtitle2">
              {label}
              {toolTip && (
                <LightTooltip title={toolTip} placement="top" arrow>
                  <Box color="primary.main" sx={{ display: 'inline-block' }}>
                    <InfoOutlined
                      sx={{
                        width: 14,
                        height: 14,
                        ml: '4px',
                        cursor: 'pointer',
                      }}
                    />
                  </Box>
                </LightTooltip>
              )}
            </Typography>
            <Typography mt={2} variant="subtitle2">
              <CurrencyFormatter value={total} />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Total;
