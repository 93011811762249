import { CompBenServices } from '../../services/compBen';
import {
  GET_ALL_BENEFITS_LIST_FAILURE,
  GET_ALL_BENEFITS_LIST_REQUEST,
  GET_ALL_BENEFITS_LIST_SUCCESS,
  GET_ALL_USER_BENEFITS_LIST_FAILURE,
  GET_ALL_USER_BENEFITS_LIST_REQUEST,
  GET_ALL_USER_BENEFITS_LIST_SUCCESS,
  GET_BENEFITS_DETAILS_FAILURE,
  GET_BENEFITS_DETAILS_REQUEST,
  GET_BENEFITS_DETAILS_SUCCESS,
  RESET_BENEFITS,
} from './actionTypes';

const getAllBenefitsListRequest = () => ({
  type: GET_ALL_BENEFITS_LIST_REQUEST,
});

const getAllBenefitsListSuccess = (payload) => ({
  type: GET_ALL_BENEFITS_LIST_SUCCESS,
  payload,
});

const getAllBenefitsListFailure = () => ({
  type: GET_ALL_BENEFITS_LIST_FAILURE,
});

export const resetBenefits = () => ({
  type: RESET_BENEFITS,
});

const getBenefitsDetailsRequest = () => ({
  type: GET_BENEFITS_DETAILS_REQUEST,
});

const getBenefitsDetailsSuccess = (payload) => ({
  type: GET_BENEFITS_DETAILS_SUCCESS,
  payload,
});

const getBenefitsDetailsFailure = () => ({
  type: GET_BENEFITS_DETAILS_FAILURE,
});

const getAllUserBenefitsListRequest = () => ({
  type: GET_ALL_USER_BENEFITS_LIST_REQUEST,
});

const getAllUserBenefitsListSuccess = (payload) => ({
  type: GET_ALL_USER_BENEFITS_LIST_SUCCESS,
  payload,
});

const getAllUserBenefitsListFailure = () => ({
  type: GET_ALL_USER_BENEFITS_LIST_FAILURE,
});

export const getBenefitsDetails = (benefitId, roleId) => async (dispatch) => {
  dispatch(getBenefitsDetailsRequest());
  new CompBenServices()
    .getBenefitsDetails(benefitId, roleId)
    .then((res) => {
      dispatch(getBenefitsDetailsSuccess(res));
    })
    .catch(() => {
      dispatch(getBenefitsDetailsFailure());
    });
};

export const getAllBenefitsList = (roleId) => async (dispatch) => {
  dispatch(getAllBenefitsListRequest());
  new CompBenServices()
    .getAllBenefitsList(roleId)
    .then((res) => {
      dispatch(getAllBenefitsListSuccess(res));
    })
    .catch((err) => {
      dispatch(getAllBenefitsListFailure());
    });
};

export const getAllUserBenefitsList = (roleId, benefitId, startDate, endDate, search) => async (dispatch) => {
  dispatch(getAllUserBenefitsListRequest());
  new CompBenServices()
    .getAllUserBenefitsList(roleId, benefitId, startDate, endDate, search)
    .then((res) => {
      dispatch(getAllUserBenefitsListSuccess(res));
    })
    .catch((err) => {
      dispatch(getAllUserBenefitsListFailure());
    });
};
