import { FormFieldTypes } from '../../../../../../constants/common';

export const allowedKeys = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'Backspace',
  'Enter',
  'Tab',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Delete',
  'Shift',
  'Control',
];

export const preventKeyPressWithoutDecimal = (event) => {
  if (!allowedKeys.includes(event.key)) {
    event.preventDefault();
  }
};

export const UnergizerFieldConfig = [
  {
    id: 1,
    fieldLabel: 'Month',
    placeholderText: 'Select',
    fieldType: FormFieldTypes.Month,
    isRequired: true,
    fieldRefName: 'month',
    size: 'small',
    fullWidth: true,
    fieldSize: 5,
    disableFuture: true
  },
  {
    id: 2,
    fieldLabel: 'Number of Employees',
    placeholderText: 'Type Here',
    fieldType: FormFieldTypes.Text,
    dataType: FormFieldTypes.Number,
    isRequired: true,
    fieldRefName: 'numberOfEmployees',
    size: 'small',
    fullWidth: true,
    fieldSize: 5,
    onKeyDown: preventKeyPressWithoutDecimal
  },
  {
    id: 3,
    fieldLabel: 'Entitlement',
    fieldType: FormFieldTypes.Formula,
    isRequired: false,
    fieldRefName: 'limit',
    size: 'small',
    fullWidth: true,
    fieldSize: 2,
    percisionNumber: 0,
  },
];

export const initialVal = {
  month: '',
  numberOfEmployees: '',
  limit: '',
};

export const totalForLimit = (componentValues) => {
  if (!componentValues || componentValues.length === 0) {
    return 0;
  }
  return componentValues.reduce((accumulator, formInfo) => {
    return accumulator + Number(formInfo.limit);
  }, 0);
};

export const disableSelectedMonths = (array) =>  {
  let selectedMonths = [];
  for (let i = 0; i < array.length; i++) {
      if (array?.[i]?.month) {
          selectedMonths.push(array?.[i]?.month);
      }
  }

  return selectedMonths;
};

export const UnergizerFormConfig = { 
  Limit: 'limit',
  Month: 'month'
};
