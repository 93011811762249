import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import ActionHeader from '../../../ActionHeader/ActionHeader';
import {
  getFieldTypeString,
  isValidDate,
  showToast,
} from '../../../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../constants/onboarding.constants';
import {
  FormFieldTypes,
  toastMessage,
  ToastThemes,
} from '../../../../../../constants/common';
import { Divider, FormHelperText, Grid } from '@mui/material';
import ApiSelectTag from '../../../../../../atoms/Select/ApiSelectTag';
import { IntegrationService } from '../../../../../../services/externalIntegration';

const IntegrationEditForm = (props) => {
  const { formData, setShowForm, integrationId, integrationList } = props;

  const [mappings, setMappings] = useState({});
  const [fieldMapped, setFieldMapped] = useState({});
  const [entityColumns, setEntityColumns] = useState([]);
  const [formInfo, setFormInfo] = useState({});
  const [entityOption, setEntityOption] = useState([]);
  const [importOption, setImportOption] = useState([]);
  const [importTable, setImportTable] = useState([]);
  const [fieldMappingError, setFieldMappingError] = useState({});
  const [fieldMappingMsg, setFieldMappingMsg] = useState({});
  useEffect(() => {
    if (formData) {
      const { appColumns, importColumns, mappedData } = formData;
      const { fkEntity, fkImport, ...rest } = mappedData || {};
      setFieldMapped(transformData(rest));
      setEntityColumns(appColumns || []);
      setFormInfo({ entity: fkEntity, import: fkImport });
      setImportOption(importColumns || []);
    }
  }, [formData]);

  useEffect(() => {
    if (formInfo?.entity?.id) {
      handleDropdownClick('importCol');
    }
  }, [formInfo?.entity?.id]);

  const handleDiscard = () => {
    setFormInfo({});
    setShowForm(false);
  };

  const handleDropdownClick = (name) => {
    switch (name) {
      case 'entity': {
        return new IntegrationService().getEntity().then((res) => {
          const filteredEntity = res.filter(
            (ent) => !integrationList.some((lst) => lst.id === ent.id)
          );
          setEntityOption(filteredEntity);
          return true;
        });
      }
      case 'importCol': {
        return new IntegrationService()
          .getImportMappingColumns()
          .then((res) => {
            setImportOption([{ id: null, name: 'Select Option' }, ...res]);
            return true;
          })
          .catch((err) => {
            showToast(err?.response?.data?.message, ToastThemes.error);
            throw err?.response?.data?.message;
          });
      }
      default:
        break;
    }
  };

  const handleEntityChange = async (event) => {
    const key = event.target.name;
    const val = event.target.value;
    if (val) {
      try {
        const response = await new IntegrationService().getTableColumn(val?.id);
        setFormInfo((prev) => ({
          ...prev,
          [key]: val,
        }));
        setEntityColumns(response);
      } catch (err) {
        showToast(err?.response?.data?.message, ToastThemes.error);
        throw err?.response?.data?.message;
      }
    }
  };
  const handleMappingChange = async (event, columnName, fieldType) => {
    const key = event.target.name;
    const val = event.target.value;
    let isValidType = false;
    let response = '';
    if (val.id) {
      response = await new IntegrationService().getImportValueById(val.id);
    } else {
      setFieldMappingError((prev) => ({
        ...prev,
        [key]: '',
      }));
      setFieldMappingMsg((prev) => ({
        ...prev,
        [key]: '',
      }));
    }

    setFieldMapped((prev) => ({
      ...prev,
      [key]: { val, data: response },
    }));

    switch (fieldType?.trim()) {
      case FormFieldTypes.Date: {
        isValidType = isValidDate(response);
        break;
      }
      case FormFieldTypes.Number: {
        const num = Number(response);
        isValidType = !isNaN(num) && isFinite(num);
        break;
      }
      default: {
        isValidType = true;
        break;
      }
    }

    const setMapping = () => {
      return setMappings((prevMappings) => {
        const newMappings = { ...prevMappings };

        newMappings[columnName] = val.id;

        return newMappings;
      });
    };
    if (isValidType && response) {
      setMapping();
      setFieldMappingError((prev) => ({
        ...prev,
        [key]: '',
      }));
      setFieldMappingMsg((prev) => ({
        ...prev,
        [key]: '',
      }));
    } else if (!isValidType && response) {
      setFieldMappingError((prev) => ({
        ...prev,
        [key]: `Error! Cannot Map ${getFieldTypeString(
          fieldType
        )} Type Field. Please Select Other Field`,
      }));
      setFieldMappingMsg((prev) => ({
        ...prev,
        [key]: '',
      }));
    } else {
      if (val?.id) {
        setFieldMappingMsg((prev) => ({
          ...prev,
          [key]: 'Please Cross Check Field Type Before Mapping',
        }));
        setFieldMappingError((prev) => ({
          ...prev,
          [key]: '',
        }));
      }
      setMapping();
    }
  };

  const transformData = (data) => {
    const output = {};

    for (const key in data) {
      const item = data[key];
      output[key] = {
        val: {
          id: item.id,
          name: item.name,
        },
        data: null,
      };
    }

    return output;
  };

  const isEmptyFieldMappingError = (obj) => {
    return (
      Object.keys(obj).length === 0 ||
      Object.values(obj).every((value) => value === '')
    );
  };

  const handleSave = async () => {
    if (isEmptyFieldMappingError(fieldMappingError)) {
      await new IntegrationService()
        .updateMapping({
          ...mappings,
          fkEntity: formInfo?.entity?.id,
          fkImport: formInfo?.import?.id,
        })
        .then(() => {
          showToast(
            ToastMessages.success.replace(
              stringSubstitute,
              toastMessage.update
            ),
            ToastThemes.success
          );
        })
        .catch((err) => {
          showToast(err?.response?.data?.message, ToastThemes.error);
          throw err?.response?.data?.message;
        });
    } else {
      showToast(
        ToastMessages.failed.replace(
          stringSubstitute,
          toastMessage.errorupdate
        ),
        ToastThemes.error
      );
    }
  };

  const getAvailableFile2Columns = () => {
    const usedColumns = Object.values(fieldMapped).map(
      (mapping) => mapping.val.id
    );
    return importOption.filter((file2Col) => {
      return file2Col.id === null || !usedColumns.includes(file2Col.id);
    });
  };

  return (
    <Box>
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={formInfo?.entity?.name || 'New Integration'}
          showDiscard
          showPublish
          showPublishModal={false}
          publishButtonText="Save"
          onPublishClick={handleSave}
          onClickDiscard={handleDiscard}
          showSave={false}
        />
      </Box>
      <Grid container rowSpacing={3} columnSpacing={8} pr={[0, 0, 0]}>
        <Grid item xs={12} md={6}>
          <ApiSelectTag
            labelText={'Select Entity'}
            onOpen={() => handleDropdownClick('entity')}
            value={formInfo?.entity}
            dropDownList={entityOption}
            onchange={handleEntityChange}
            name="entity"
            size="small"
            fullWidth
            defaultValue="Select Option"
            isReadOnly={integrationId ? true : false}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider sx={{ mb: 2 }} />
          {entityColumns && entityColumns?.length > 0 && (
            <table style={{ width: '100%' }}>
              <tbody>
                {entityColumns?.map((item, index) => {
                  const { dataDictionaries } = item;
                  return dataDictionaries?.map((col, colIndex) => {
                    const {
                      pkId,
                      columnName,
                      isMandatory,
                      dataType,
                      columnDisplayName,
                    } = col;
                    return (
                      <tr key={`${index}-${colIndex}`}>
                        <td style={{ width: '50%' }}>
                          <Box display={'flex'}>
                            {columnName && (
                              <>
                                {columnDisplayName
                                  ? `${columnDisplayName} (${columnName})`
                                  : columnName}
                                {isMandatory && (
                                  <Box
                                    color="accentSecondary.main"
                                    sx={{ display: 'inline-block' }}
                                  >
                                    *
                                  </Box>
                                )}
                              </>
                            )}
                          </Box>
                        </td>
                        <td style={{ width: '50%' }}>
                          <table style={{ width: '100%' }}>
                            <tbody>
                              <tr>
                                <td style={{ width: '50%' }}>
                                  <div>
                                    <ApiSelectTag
                                      name={pkId}
                                      size="small"
                                      fullWidth
                                      defaultValue="Select Option"
                                      onchange={(event) =>
                                        handleMappingChange(
                                          event,
                                          pkId,
                                          dataType
                                        )
                                      }
                                      dropDownList={getAvailableFile2Columns()}
                                      value={fieldMapped[pkId]?.val || null}
                                      onOpen={() =>
                                        handleDropdownClick('importCol')
                                      }
                                      errorMessage={fieldMappingError[pkId]}
                                    />
                                    {fieldMappingMsg[pkId] && (
                                      <FormHelperText
                                        style={{ color: '#d32f2f' }}
                                      >
                                        {fieldMappingMsg[pkId]}
                                      </FormHelperText>
                                    )}
                                  </div>
                                </td>
                                <td style={{ width: '50%' }}>
                                  {fieldMapped[pkId]?.data}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    );
                  });
                })}
              </tbody>
            </table>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default IntegrationEditForm;
