
import { getFormBody } from '../../components/OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { CompetencyFormFieldNames, GoalFormFieldNames } from '../../components/Performance/Utlis/goals.util';
import { GoalsServices } from '../../services/goals';
import {
  GET_GOALS_CONFIG_FAILURE, GET_GOALS_CONFIG_REQUEST, GET_GOALS_CONFIG_SUCCESS,
  POST_GOALS_INFO_FAILURE, POST_GOALS_INFO_REQUEST, POST_GOALS_INFO_SUCCESS,
  GET_ALL_GOALS_LIST_FAILURE, GET_ALL_GOALS_LIST_REQUEST, GET_ALL_GOALS_LIST_SUCCESS, RESET_GOALS, ADD_KRA_TAB, DELETE_KRA_TAB, UPDATE_KRA_DATA, DISCARD_DATA, UPDATE_KRA_DATA_PERFORMANCE, CALCULATE_TOTAL_SCORE,
  GET_ALL_APPRAISAL_LIST_REQUEST, GET_ALL_APPRAISAL_LIST_SUCCESS, GET_ALL_APPRAISAL_LIST_FAILURE, GET_APPRAISAL_DETAILS_REQUEST, GET_APPRAISAL_DETAILS_SUCCESS, GET_APPRAISAL_DETAILS_FAILURE, POST_APPRAISAL_DETAILS_REQUEST, POST_APPRAISAL_DETAILS_SUCCESS, POST_APPRAISAL_DETAILS_FAILURE, DUPLICATE_KRA_TAB,
  MANGER_ACCES_READ_ONLY,
  SCORE_GRADE
} from './actionTypes';


const getGoalsFormConfigRequest = () => (
  {
    type: GET_GOALS_CONFIG_REQUEST
  }
);

const getGoalsFormConfigSuccess = (payload) => (
  {
    type: GET_GOALS_CONFIG_SUCCESS,
    payload
  }
);

const getGoalsFormConfigFailure = () => (
  {
    type: GET_GOALS_CONFIG_FAILURE
  }
);

const postGoalsInfoRequest = () => (
  {
    type: POST_GOALS_INFO_REQUEST
  }
);

const postGoalsInfoSuccess = (payload) => (
  {
    type: POST_GOALS_INFO_SUCCESS,
    payload
  }
);

const postGoalsInfoFailure = () => (
  {
    type: POST_GOALS_INFO_FAILURE
  }
);

const getAllGoalsListRequest = () => (
  {
    type: GET_ALL_GOALS_LIST_REQUEST
  }
);

const getAllGoalsListSuccess = (payload) => (
  {
    type: GET_ALL_GOALS_LIST_SUCCESS,
    payload
  }
);

const getAllGoalsListFailure = () => (
  {
    type: GET_ALL_GOALS_LIST_FAILURE
  }
);

export const resetGoals = () => (
  {
    type: RESET_GOALS
  }
);

export const addKraTab = () => (
  {
    type: ADD_KRA_TAB
  }
);

export const deleteKraTab = (payload) => (
  {
    type: DELETE_KRA_TAB,
    payload
  }
);

export const updateKraData = (payload) => (
  {
    type: UPDATE_KRA_DATA,
    payload
  }
);

export const discardData = () => (
  {
    type: DISCARD_DATA,
  }
);

export const updateKraDataPerformance = (payload) => (
  {
    type: UPDATE_KRA_DATA_PERFORMANCE,
    payload
  }
);

export const calculateTotalScore = (payload) => (
  {
    type: CALCULATE_TOTAL_SCORE,
    payload
  }
);

export const managerAccessReadOnly = (payload) => (
  {
    type: MANGER_ACCES_READ_ONLY,
    payload
  }
);


const getAllAppraisalListRequest = () => (
  {
    type: GET_ALL_APPRAISAL_LIST_REQUEST
  }
);

const getAllAppraisalListSuccess = (payload) => (
  {
    type: GET_ALL_APPRAISAL_LIST_SUCCESS,
    payload
  }
);

const getAllAppraisalListFailure = () => (
  {
    type: GET_ALL_APPRAISAL_LIST_FAILURE
  }
);


const getAppraisalDetailsListRequest = () => (
  {
    type: GET_APPRAISAL_DETAILS_REQUEST
  }
);

const getAppraisalDetailsListSuccess = (payload) => (
  {
    type: GET_APPRAISAL_DETAILS_SUCCESS,
    payload
  }
);

const getAppraisalDetailsListFailure = () => (
  {
    type: GET_APPRAISAL_DETAILS_FAILURE
  }
);

const postAppraisalDetailsRequest = () => (
  {
    type: POST_APPRAISAL_DETAILS_REQUEST
  }
);

const postAppraisalDetailsSuccess = (payload) => (
  {
    type: POST_APPRAISAL_DETAILS_SUCCESS,
    payload
  }
);

const postAppraisalDetailsFailure = () => (
  {
    type: POST_APPRAISAL_DETAILS_FAILURE
  }
);

export const duplicateKraTab = (payload) => (
  {
    type: DUPLICATE_KRA_TAB,
    payload
  }
);

export const scoreGrade = (payload) => (
  {
    type: SCORE_GRADE,
    payload
  }
);

export const getGoalsConfig = (currentUserRoleId, ratingScales, goalId,moduleId) => async (dispatch) => {
  dispatch(getGoalsFormConfigRequest());
  new GoalsServices().getGoalsConfig(currentUserRoleId, goalId,moduleId).then((res) => {
    dispatch(getGoalsFormConfigSuccess({ goalId, form: res, ratingScales }));
  }).catch((err) => {
    dispatch(getGoalsFormConfigFailure());
  });
};

export const postGoalsInfo = (goalFormInfo, kraFormInfo, competencyFormInfo) => async (dispatch) => {
  dispatch(postGoalsInfoRequest());
  const goalsFormData = getFormBody(goalFormInfo);
  delete goalsFormData[GoalFormFieldNames.GoalStatus];
  const kraData = kraFormInfo.map((formData) => {
    return getFormBody(formData);
  });

  const competencyData = competencyFormInfo.map((formData) => {
    const newData = { ...formData };
    newData[CompetencyFormFieldNames.Competency] = formData[CompetencyFormFieldNames.Competency].id;
    return newData;
  });
  const goalFormBody = {
    goal: goalsFormData,
    kra: kraData,
    goalCompetencies: competencyData
  };
  return new GoalsServices().postGoalsInfo(goalFormBody).then((res) => {
    dispatch(postGoalsInfoSuccess({ goalFormInfo, kraFormInfo, competencyFormInfo, id: res.id }));
    return res;
  }).catch((err) => {
    dispatch(postGoalsInfoFailure());
    throw err?.response?.data?.message || 'Error Saving';
  });
};

export const getAllGoalsList = (roleId, searchInput,moduleId ) => async (dispatch) => {
  dispatch(getAllGoalsListRequest());
  new GoalsServices().getAllGoalsList(roleId, searchInput,moduleId).then((res) => {
    dispatch(getAllGoalsListSuccess(res));
  }).catch(() => {
    dispatch(getAllGoalsListFailure());
  });
};

export const getAllAppraisalList = (roleId, searchInput,moduleId) => async (dispatch) => {
  dispatch(getAllAppraisalListRequest());
  new GoalsServices().getAllAppraisalList(roleId, searchInput,moduleId).then((res) => {
    dispatch(getAllAppraisalListSuccess(res));
  }).catch(() => {
    dispatch(getAllAppraisalListFailure());
  });
};

export const getAppraisalDetails = (currentRoleId, ratingScales, goalId,moduleId) => async (dispatch) => {
  dispatch(getAppraisalDetailsListRequest());
  new GoalsServices().getAppraisalDetails(goalId, currentRoleId,moduleId).then((res) => {
    dispatch(getAppraisalDetailsListSuccess({ goalId, form: res, ratingScales }));
  }).catch((err) => {
    dispatch(getAppraisalDetailsListFailure());
  });
};

export const postAppraisalDetails = (kraData, competencyData, totalScore, id,currentUserRoleId,moduleId) => async (dispatch) => {
  const payload = {};
  const kraSubmissions = kraData.map((data) => {
    return { id: data.id, performance: data.kraPerformance };
  });
  const competencySubmissions = competencyData.map((data) => {
    return {
      id: data.id,
      [CompetencyFormFieldNames.Rating]: data[CompetencyFormFieldNames.Submission]?.[CompetencyFormFieldNames.Rating] || 0,
      [CompetencyFormFieldNames.Remarks]: data[CompetencyFormFieldNames.Submission]?.[CompetencyFormFieldNames.Remarks]
    };
  });
  const {overallGrade, overallStatus, ...rest } = totalScore;
  Object.assign(payload, { kraSubmissions }, { competencySubmissions }, { id ,currentUserRoleId,moduleId}, { totalScore:rest });
  dispatch(postAppraisalDetailsRequest());
  return new GoalsServices().postAppraisalDetails(payload).then(() => {
    dispatch(postAppraisalDetailsSuccess({ competencyData, kraData }));
    return true;
  }).catch((err) => {
    dispatch(postAppraisalDetailsFailure());
    throw err?.response?.data?.message || 'Error Saving';
  });
};

