import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ModalDialog from '../../../../../../atoms/Modal/ModalDialog';
import FormEdit from '../../../../common/Form/FormEdit';
import {
  FormFieldTypes,
  ToastThemes,
  toastMessage,
} from '../../../../../../constants/common';
import { PowerBiService } from '../../../../../../services/powerBi';
import { showToast } from '../../../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../constants/onboarding.constants';
import { useSelector } from 'react-redux';
import { RoleService } from '../../../../../../services/roles';
import { FormFieldNameForReports } from '../../constants/roles-permissions.constants';

const ReportsConfigration = (props) => {
  const { roleId, setReportIds, reportIds } = props;

  const [showModal, setShowModal] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const [option, setOption] = useState([]);
  const { currentUserRoleId } = useSelector((state) => state && state.main);

  useEffect(() => {
    if (showModal === true) {
      setConfirmClose(false);
    }
  }, [showModal]);

  const handleSaveClick = () => {
    new RoleService()
      .saveReports({
        reports: reportIds?.reports.map((item) => Number(item.id)),
        roleId,
      })
      .then(() => {
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.update),
          ToastThemes.success
        );
        setConfirmClose(true);
        setShowModal(false);
      })
      .catch(() => {
        setConfirmClose(false);
        setShowModal(true);
      });
  };

  const buttonClick = async () => {
    setShowModal(true);
    if (!reportIds?.reports ?? true) {
      const reportsIds = await new PowerBiService().getReportList(roleId);
      setReportIds({ reports: reportsIds });
    }
  };

  const handleDropdownClick = (name) => {
    switch (name) {
      case FormFieldNameForReports.Reports: {
        return new PowerBiService().getReportList().then((res) => {
          setOption(res);
          return true;
        });
      }
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case FormFieldNameForReports.Reports: {
        return option;
      }
    }
  };

  return (
    <>
      <Button onClick={buttonClick}>Configure Reports</Button>
      <ModalDialog
        severity="success"
        title="Configure Reports"
        listComponent={
          <>
            <br />
            <FormEdit
              list={[
                {
                  fieldLabel: 'Reports List',
                  placeholderText: 'Select',
                  fieldType: FormFieldTypes.MutliSelect,
                  isRequired: false,
                  fieldRefName: 'reports',
                  fieldSize: 13,
                },
              ]}
              padding={0}
              setFormData={setReportIds}
              formInfo={reportIds}
              handleDropdownClick={handleDropdownClick}
              getDropdownOptions={getDropdownOptions}
            />
          </>
        }
        primaryAction="save"
        secondaryAction="Cancel"
        showModal={showModal}
        onClickClose={() => {
          setShowModal(false);
          setConfirmClose(false);
        }}
        onClickConfirm={handleSaveClick}
        maxWidth={'md'}
        fullWidth
        confirmClose={confirmClose}
      />
    </>
  );
};

export default ReportsConfigration;
