import { ToastMessages, stringSubstitute } from '../../components/OnBoarding/Organization/constants/onboarding.constants';
import { getFormBody } from '../../components/OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { OnboardingServices } from '../../services/onboarding';
import {
  GET_COMPANY_INFO_FAILURE, GET_COMPANY_INFO_REQUEST, GET_COMPANY_INFO_SUCCESS,
  POST_COMPANY_INFO_REQUEST, POST_COMPANY_INFO_SUCCESS, POST_COMPANY_INFO_FAILURE,
  UPDATE_COMPANY_INFO_REQUEST, UPDATE_COMPANY_INFO_SUCCESS, UPDATE_COMPANY_INFO_FAILURE,
  GET_ROLES_LIST_REQUEST, GET_ROLES_LIST_SUCCESS, GET_ROLES_LIST_FAILURE,
  FETCH_COMPANY_ONBOARDING_PERMISSIONS_REQUEST,
  FETCH_COMPANY_ONBOARDING_PERMISSIONS_SUCCESS, FETCH_COMPANY_ONBOARDING_PERMISSIONS_FAILURE, PUBLISH_COMPANY_INFO_REQUEST,
  PUBLISH_COMPANY_INFO_SUCCESS, PUBLISH_COMPANY_INFO_FAILURE, RESET_ONBOARDING_STATE,
  GET_DEPARTMENT_INFO_FAILURE, GET_DEPARTMENT_INFO_REQUEST, GET_DEPARTMENT_INFO_SUCCESS,
  GET_SUB_DEPARTMENT_INFO_FAILURE, GET_SUB_DEPARTMENT_INFO_REQUEST, GET_SUB_DEPARTMENT_INFO_SUCCESS,
  POST_DEPARTMENT_SAVE_FAILURE, POST_DEPARTMENT_SAVE_REQUEST, POST_DEPARTMENT_SAVE_SUCCESS,
  GET_LOCATION_INFO_FAILURE, GET_LOCATION_INFO_SUCCESS, GET_LOCATION_INFO_REQUEST,
  POST_LOCATION_SAVE_FAILURE, POST_LOCATION_SAVE_REQUEST, POST_LOCATION_SAVE_SUCCESS,
  FETCH_EMPLOYEE_INFO_FAILURE, FETCH_EMPLOYEE_INFO_REQUEST, FETCH_EMPLOYEE_INFO_SUCCESS,
  UPDATE_DEPARTMENT_INFO_FAILURE, UPDATE_DEPARTMENT_INFO_REQUEST, UPDATE_DEPARTMENT_INFO_SUCCESS,
  GET_ALL_USERS_REQUEST, GET_ALL_USERS_SUCCESS, GET_ALL_USERS_FAILURE,
  DELETE_DEPARTMENT_FAILURE, DELETE_DEPARTMENT_REQUEST, DELETE_DEPARTMENT_SUCCESS,
  UPDATE_LOCATION_FAILURE, UPDATE_LOCATION_REQUEST, UPDATE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE, DELETE_LOCATION_REQUEST, DELETE_LOCATION_SUCCESS, CREATE_ROLE_REQUEST, CREATE_ROLE_SUCCESS, CREATE_ROLE_FAILURE,
  GET_DESIGNATION_FAILURE, GET_DESIGNATION_REQUEST, GET_DESIGNATION_SUCCESS, POST_DESIGNATION_FAILURE, POST_DESIGNATION_REQUEST, POST_DESIGNATION_SUCCESS,
  UPDATE_DESIGNATION_FAILURE, UPDATE_DESIGNATION_REQUEST, UPDATE_DESIGNATION_SUCCESS, DELETE_DESIGNATION_FAILURE, DELETE_DESIGNATION_REQUEST, DELETE_DESIGNATION_SUCCESS, FETCH_LOGO_IMAGE_REQUEST, FETCH_LOGO_IMAGE_SUCCESS, FETCH_LOGO_IMAGE_FAILURE
} from './actionTypes';

const getCompanyInfoRequest = () => (
  {
    type: GET_COMPANY_INFO_REQUEST
  }
);

const getCompanyInfoSuccess = (payload) => (
  {
    type: GET_COMPANY_INFO_SUCCESS,
    payload
  }
);

const getCompanyInfoFailure = () => (
  {
    type: GET_COMPANY_INFO_FAILURE
  }
);

const getDepartmentInfoRequest = () => (
  {
    type: GET_DEPARTMENT_INFO_REQUEST
  }
);

const getDepartmentInfoSuccess = (payload) => (
  {
    type: GET_DEPARTMENT_INFO_SUCCESS,
    payload
  }
);

const getDepartmentInfoFailure = () => (
  {
    type: GET_DEPARTMENT_INFO_FAILURE
  }
);
const getSubDepartmentInfoRequest = () => (
  {
    type: GET_SUB_DEPARTMENT_INFO_REQUEST
  }
);

const getSubDepartmentInfoSuccess = (payload) => (
  {
    type: GET_SUB_DEPARTMENT_INFO_SUCCESS,
    payload
  }
);

const getSubDepartmentInfoFailure = () => (
  {
    type: GET_SUB_DEPARTMENT_INFO_FAILURE
  }
);

const postDepartmentSaveRequest = () => (
  {
    type: POST_DEPARTMENT_SAVE_REQUEST
  }
);
const postDepartmentSaveSuccess = (payload) => (
  {
    type: POST_DEPARTMENT_SAVE_SUCCESS,
    payload
  }
);
const postDepartmentSaveFailure = () => (
  {
    type: POST_DEPARTMENT_SAVE_FAILURE
  }
);

const postCompanyInfoRequest = () => (
  {
    type: POST_COMPANY_INFO_REQUEST
  }
);

const postCompanyInfoSuccess = (payload) => (
  {
    type: POST_COMPANY_INFO_SUCCESS,
    payload
  }
);

const postCompanyInfoFailure = () => (
  {
    type: POST_COMPANY_INFO_FAILURE
  }
);

const updateCompanyInfoRequest = () => (
  {
    type: UPDATE_COMPANY_INFO_REQUEST
  }
);

const updateCompanyInfoSuccess = (payload) => (
  {
    type: UPDATE_COMPANY_INFO_SUCCESS,
    payload
  }
);

const updateCompanyInfoFailure = () => (
  {
    type: UPDATE_COMPANY_INFO_FAILURE
  }
);

const getLocationInfoRequest = () => (
  {
    type: GET_LOCATION_INFO_REQUEST
  }
);

const getLocationInfoSuccess = (payload) => (
  {
    type: GET_LOCATION_INFO_SUCCESS,
    payload
  }
);

const getLocationInfoFailure = () => (
  {
    type: GET_LOCATION_INFO_FAILURE
  }
);

const postLocationSaveRequest = () => (
  {
    type: POST_LOCATION_SAVE_REQUEST
  }
);
const postLocationSaveSuccess = (payload) => (
  {
    type: POST_LOCATION_SAVE_SUCCESS,
    payload
  }
);
const postLocationSaveFailure = () => (
  {
    type: POST_LOCATION_SAVE_FAILURE
  }
);

const fetchEmployeeInfoRequest = () => (
  {
    type: FETCH_EMPLOYEE_INFO_REQUEST
  }
);

const fetchEmployeeInfoSuccess = (payload) => (
  {
    type: FETCH_EMPLOYEE_INFO_SUCCESS,
    payload
  }
);

const getRolesListRequest = () => (
  {
    type: GET_ROLES_LIST_REQUEST
  }
);

const getRolesListSuccess = (payload) => (
  {
    type: GET_ROLES_LIST_SUCCESS,
    payload
  }
);

const getRolesListFailure = () => (
  {
    type: GET_ROLES_LIST_FAILURE
  }
);

const fetchEmployeeInfoFailure = () => (
  {
    type: FETCH_EMPLOYEE_INFO_FAILURE
  }
);

const updateDepartmentInfoRequest = () => (
  {
    type: UPDATE_DEPARTMENT_INFO_REQUEST
  }
);

const updateDepartmentInfoSuccess = (payload) => (
  {
    type: UPDATE_DEPARTMENT_INFO_SUCCESS,
    payload
  }
);

const updateDepartmentInfoFailure = () => (
  {
    type: UPDATE_DEPARTMENT_INFO_FAILURE
  }
);

const fetchCompanyOnboardingPermissionsRequest = () => (
  {
    type: FETCH_COMPANY_ONBOARDING_PERMISSIONS_REQUEST
  }
);

const fetchCompanyOnboardingPermissionsSuccess = (payload) => (
  {
    type: FETCH_COMPANY_ONBOARDING_PERMISSIONS_SUCCESS,
    payload
  }
);

const fetchCompanyOnboardingPermissionsFailure = () => (
  {
    type: FETCH_COMPANY_ONBOARDING_PERMISSIONS_FAILURE
  }
);

const publishCompanyInfoRequest = () => (
  {
    type: PUBLISH_COMPANY_INFO_REQUEST
  }
);

const publishCompanyInfoSuccess = () => (
  {
    type: PUBLISH_COMPANY_INFO_SUCCESS,
  }
);

const publishCompanyInfoFailure = () => (
  {
    type: PUBLISH_COMPANY_INFO_FAILURE
  }
);

const getAllUsersRequest = () => (
  {
    type: GET_ALL_USERS_REQUEST
  }
);

const getAllUsersSuccess = (payload) => (
  {
    type: GET_ALL_USERS_SUCCESS,
    payload
  }
);

const getAllUsersFailure = () => (
  {
    type: GET_ALL_USERS_FAILURE
  }
);

export const resetOnboardingState = () => (
  {
    type: RESET_ONBOARDING_STATE
  }
);

const updateLocationRequest = () => (
  {
    type: UPDATE_LOCATION_REQUEST
  }
);

const updateLocationSuccess = (payload) => (
  {
    type: UPDATE_LOCATION_SUCCESS,
    payload
  }
);

const updateLocationFailure = () => (
  {
    type: UPDATE_LOCATION_FAILURE
  }
);

const deleteDepartmentRequest = () => (
  {
    type: DELETE_DEPARTMENT_REQUEST
  }
);

const deleteDepartmentSuccess = (payload) => (
  {
    type: DELETE_DEPARTMENT_SUCCESS,
    payload
  }
);

const deleteDepartmentFailure = () => (
  {
    type: DELETE_DEPARTMENT_FAILURE
  }
);

const deleteLocationRequest = () => (
  {
    type: DELETE_LOCATION_REQUEST
  }
);

const deleteLocationSuccess = (payload) => (
  {
    type: DELETE_LOCATION_SUCCESS,
    payload
  }
);

const deleteLocationFailure = () => (
  {
    type: DELETE_LOCATION_FAILURE
  }
);

const createRoleRequest = () => (
  {
    type: CREATE_ROLE_REQUEST
  }
);

const createRoleSuccess = (payload) => (
  {
    type: CREATE_ROLE_SUCCESS,
    payload
  }
);

const createRoleFailure = () => (
  {
    type: CREATE_ROLE_FAILURE
  }
);

const getDesignationRequest = () => (
  {
    type: GET_DESIGNATION_REQUEST
  }
);

const getDesignationSuccess = (payload) => (
  {
    type: GET_DESIGNATION_SUCCESS,
    payload
  }
);

const getDesignationFailure = () => (
  {
    type: GET_DESIGNATION_FAILURE
  }
);

const postDesignationRequest = () => (
  {
    type: POST_DESIGNATION_REQUEST
  }
);
const postDesignationSuccess = (payload) => (
  {
    type: POST_DESIGNATION_SUCCESS,
    payload
  }
);
const postDesignationFailure = () => (
  {
    type: POST_DESIGNATION_FAILURE
  }
);
const updateDesignationRequest = () => (
  {
    type: UPDATE_DESIGNATION_REQUEST
  }
);

const updateDesignationSuccess = (payload) => (
  {
    type: UPDATE_DESIGNATION_SUCCESS,
    payload
  }
);

const updateDesignationFailure = () => (
  {
    type: UPDATE_DESIGNATION_FAILURE
  }
);
const deleteDesignationRequest = () => (
  {
    type: DELETE_DESIGNATION_REQUEST
  }
);

const deleteDesignationSuccess = (payload) => (
  {
    type: DELETE_DESIGNATION_SUCCESS,
    payload
  }
);

const deleteDesignationFailure = () => (
  {
    type: DELETE_DESIGNATION_FAILURE
  }
);

const fetchLogoRequest = () => (
  {
    type: FETCH_LOGO_IMAGE_REQUEST
  }
);

const fetchLogoSuccess = (payload) => (
  {
    type: FETCH_LOGO_IMAGE_SUCCESS,
    payload
  }
);

const fetchLogoFailure = () => (
  {
    type: FETCH_LOGO_IMAGE_FAILURE
  }
);


// api calls

export const getCompanyInfo = (roleId) => async (dispatch) => {
  dispatch(getCompanyInfoRequest());
  new OnboardingServices().getCompanyInfo(roleId).then((res) => {
    dispatch(getCompanyInfoSuccess(res));
  }).catch(() => {
    dispatch(getCompanyInfoFailure());
  });
};
export const getDepartmentInfo = (roleId,deptName) => async (dispatch) => {
  dispatch(getDepartmentInfoRequest());
  return new OnboardingServices().getDepartmentInfo(roleId,deptName).then((res) => {
    dispatch(getDepartmentInfoSuccess(res));
    return true;
  }).catch(() => {
    dispatch(getDepartmentInfoFailure());
    return false;
  });
};

export const getSubDepartmentInfo = () => async (dispatch) => {
  dispatch(getSubDepartmentInfoRequest());
  return new OnboardingServices().getSubDepartmentInfo().then((res) => {
    dispatch(getSubDepartmentInfoSuccess(res));
    return true;
  }).catch(() => {
    dispatch(getSubDepartmentInfoFailure());
    return false;
  });
};

export const postDepartment = (roleId,departmentName) => async (dispatch) => {
  dispatch(postDepartmentSaveRequest());
  new OnboardingServices().postDepartment(roleId, departmentName).then((res) => {
    dispatch(postDepartmentSaveSuccess({ name: departmentName, res }));
  }).catch(() => {
    dispatch(postDepartmentSaveFailure());
  });
};

export const postCompanyInfo = (companyInfo, roleId) => async (dispatch) => {
  dispatch(postCompanyInfoRequest());
  return new OnboardingServices().postCompanyInfo(companyInfo, roleId).then((res) => {
    dispatch(postCompanyInfoSuccess({ companyInfo, id: res.id }));
    return true;
  }).catch(() => {
    dispatch(postCompanyInfoFailure());
    return false;
  });
};

export const updateCompanyInfo = (companyInfo, roleId) => async (dispatch) => {
  dispatch(updateCompanyInfoRequest());
  return new OnboardingServices().updateCompanyInfo(companyInfo, roleId).then(() => {
    dispatch(updateCompanyInfoSuccess(companyInfo));
    return true;
  }).catch(() => {
    dispatch(updateCompanyInfoFailure());
    return false;
  });
};
export const getLocationInfo = (locName) => async (dispatch) => {
  dispatch(getLocationInfoRequest());
  return new OnboardingServices().getLocationInfo(locName).then((res) => {
    dispatch(getLocationInfoSuccess(res));
    return true;
  }).catch(() => {
    dispatch(getLocationInfoFailure());
    return false;
  });
};

export const postLocation = (locationName) => async (dispatch) => {
  dispatch(postLocationSaveRequest());
  new OnboardingServices().postLocation(locationName).then((res) => {
    dispatch(postLocationSaveSuccess({ name: locationName, res }));
  }).catch(() => {
    dispatch(postLocationSaveFailure());
  });
};

export const fetchEmployeeInfo = (id,roleId) => async (dispatch) => {
  dispatch(fetchEmployeeInfoRequest());
  new OnboardingServices().fetchEmployeeInfo(id,roleId).then((res) => {
    dispatch(fetchEmployeeInfoSuccess(res));
  }).catch(() => {
    dispatch(fetchEmployeeInfoFailure());
  });
};

export const updateDepartmentInfo = (departmentData, id, index, roleId) => async (dispatch) => {
  dispatch(updateDepartmentInfoRequest());
  new OnboardingServices().updateDepartmentInfo(departmentData, id, roleId).then(() =>{
    dispatch(updateDepartmentInfoSuccess({ departmentData, index }));
  }).catch(() => {
    dispatch(updateDepartmentInfoFailure());
  });
};

export const getRolesList = (roleName) => async (dispatch) => {
  dispatch(getRolesListRequest());
  return new OnboardingServices().getRolesList(roleName).then((res) => {
    dispatch(getRolesListSuccess(res));
    return true;
  }).catch(() => {
    dispatch(getRolesListFailure());
    return false;
  });
};

export const fetchCompanyOnboardingPermissions = (roleId, moduleId) => async (dispatch) => {
  dispatch(fetchCompanyOnboardingPermissionsRequest());
  new OnboardingServices().fetchCompanyOnboardingPermissions(roleId, moduleId).then((res) => {
    dispatch(fetchCompanyOnboardingPermissionsSuccess(res));
  }).catch(() => {
    dispatch(fetchCompanyOnboardingPermissionsFailure());
  });
};

export const publishCompanyInfo = (roleId) => async (dispatch) => {
  dispatch(publishCompanyInfoRequest());
  return new OnboardingServices().publishCompanyInfo(roleId).then(() => {
    dispatch(publishCompanyInfoSuccess());
    return true;
  }).catch(() => {
    dispatch(publishCompanyInfoFailure());
    return false;
  });
};

export const getAllUsers = (roleId, searchQuery, startDate, endDate) => async (dispatch) => {
  dispatch(getAllUsersRequest());
  return new OnboardingServices().getUsersList(roleId, searchQuery, startDate, endDate).then((res) => {
    dispatch(getAllUsersSuccess(res));
    return true;
  }).catch((err) => {
    dispatch(getAllUsersFailure());
    return false;
  });
};


export const deleteDepartment = (roleId, deptId, index) => async (dispatch) => {
  dispatch(deleteDepartmentRequest());
  new OnboardingServices().deleteDepartment(roleId, deptId).then(() => {
    dispatch(deleteDepartmentSuccess(index));
  }).catch(() => {
    dispatch(deleteDepartmentFailure());
  });
};

export const deleteLocation = (locId, index) => async (dispatch) => {
  dispatch(deleteLocationRequest());
  new OnboardingServices().deleteLocation(locId).then(() => {
    dispatch(deleteLocationSuccess(index));
  }).catch(() => {
    dispatch(deleteLocationFailure());
  });
};

export const updateLocation = (name, id, index) => async (dispatch) => {
  dispatch(updateLocationRequest());
  new OnboardingServices().updateLocation(name, id).then(() =>{
    dispatch(updateLocationSuccess({ name, index }));
  }).catch(() => {
    dispatch(updateLocationFailure());
  });
};

export const addNewRole = (name) => async (dispatch) => {
  dispatch(createRoleRequest());
  return new OnboardingServices().createRole(name).then((res) => {
    dispatch(createRoleSuccess({id: res.id, name}));
    return res.id;
  }).catch(() => {
    dispatch(createRoleFailure());
    throw ToastMessages.failed.replace(stringSubstitute, 'create');
  });
};

export const getDesignation = (designationName) => async (dispatch) => {
  dispatch(getDesignationRequest());
  return new OnboardingServices().getDesignation(designationName).then((res) => {
    dispatch(getDesignationSuccess(res));
    return true;
  }).catch(() => {
    dispatch(getDesignationFailure());
    return false;
  });
};

export const updateDesignation = (designationData, id, index) => async (dispatch) => {
  dispatch(updateDesignationRequest());
  const formBody = getFormBody(designationData);
  return new OnboardingServices().updateDesignation(formBody, id).then(() =>{
    dispatch(updateDesignationSuccess({ designationData, index }));
    return true;
  }).catch(() => {
    dispatch(updateDesignationFailure());
    return false;
  });
};

export const deleteDesignation = (designationId, index) => async (dispatch) => {
  dispatch(deleteDesignationRequest());
  new OnboardingServices().deleteDesignation(designationId).then(() => {
    dispatch(deleteDesignationSuccess(index));
  }).catch(() => {
    dispatch(deleteDesignationFailure());
  });
};

export const postDesignation = (designationName) => async (dispatch) => {
  dispatch(postDesignationRequest());
  return new OnboardingServices().postDesignation(designationName).then((res) => {
    dispatch(postDesignationSuccess({ name: designationName, res }));
    return true;
  }).catch(() => {
    dispatch(postDesignationFailure());
    return false;
  });
};

export const fetchLogo = () => async (dispatch) => {
  dispatch(fetchLogoRequest());
  new OnboardingServices().fetchLogo().then((res) => {
    dispatch(fetchLogoSuccess(res));
  }).catch(() => {
    dispatch(fetchLogoFailure());
  });
};
