import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import FormEdit from '../../../../../../../OnBoarding/common/Form/FormEdit';
import { InterviewFormFields } from '../../../../../../Constant/constant';
import { InterviewServices } from '../../../../../../../../services/interviewServices';

const OverallRemarks = (props) => {
  const { formConfig,
    formInfo,
    setFormInfo } = props;

  const [interviewStatusOption, setInterviewStatusOption] = useState([]);


  const handleDropdownClick = (name) => {
    switch (name) {
      case InterviewFormFields.InterviewStatus: {
        return new InterviewServices().getDropdownOptions(name).then((res) => {
          setInterviewStatusOption(res);
          return true;
        });
      }
      default: break;
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case InterviewFormFields.InterviewStatus: {
        return interviewStatusOption;
      }
    }
  };

  return (
    <>
      <Box mb={3} />
      <FormEdit list={formConfig} formInfo={formInfo} setFormData={setFormInfo} padding={1}
        handleDropdownClick={handleDropdownClick} getDropdownOptions={getDropdownOptions} />
    </>
  );
};

export default OverallRemarks;