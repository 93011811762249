export const LoadingStatus = {
    Loading: 0,
    Success: 1,
    Failure: 2
};

export const APIRequestStatus = {
    GET_COMPANY_INFO: 'GET_COMPANY_INFO',
    UPDATE_COMPANY_INFO: 'UPDATE_COMPANY_INFO',
    GET_DEPARTMENT_INFO: 'GET_DEPARTMENT_INFO',
    GET_SUB_DEPARTMENT_INFO: 'GET_SUB_DEPARTMENT_INFO',
    POST_DEPARTMENT_SAVE: 'POST_DEPARTMENT_SAVE',
    POST_COMPANY_INFO: 'POST_COMPANY_INFO',
    POST_LOCATION_INFO: 'POST_LOCATION_INFO',
    GET_LOCATION_INFO: 'GET_LOCATION_INFO',
    FETCH_EMPLOYEE_INFO: 'FETCH_EMPLOYEE_INFO',
    UPDATE_DEPARTMENT_INFO: 'UPDATE_DEPARTMENT_INFO',
    GET_ROLES_LIST: 'GET_ROLES_LIST',
    FETCH_PERMISSIONS: 'FETCH_PERMISSIONS',
    PUBLISH_COMPANY_INFO: 'PUBLISH_COMPANY_INFO',
    FETCH_APP_PERMISSIONS: 'FETCH_APP_PERMISSIONS',
    POST_USER_INFO: 'POST_USER_INFO',
    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
    GET_ALL_USERS: 'GET_ALL_USERS',
    PUBLISH_USER: 'PUBLISH_USER',
    DELETE_DEPARTMENT: 'DELETE_DEPARTMENT',
    DELETE_LOCATION: 'DELETE_LOCATION',
    UPDATE_LOCATION: 'UPDATE_LOCATION',
    GET_USER_DETAILS: 'GET_USER_DETAILS',
    GET_ROLE_CONFIG: 'GET_ROLE_CONFIG',
    FILE_UPLOAD: 'FILE_UPLOAD',
    GET_USER_DOCUMENT_LIST: 'GET_USER_DOCUMENT_LIST',
    GET_ALL_CITIES_LIST: 'GET_ALL_CITIES_LIST',
    GET_ALL_COUNTRIES_LIST: 'GET_ALL_COUNTRIES_LIST',
    UPDATE_ROLE_PERMISSIONS: 'UPDATE_ROLE_PERMISSIONS',
    CREATE_ROLE: 'CREATE_ROLE',
    GET_USER_GENDER: 'GET_USER_GENDER',
    GET_USER_BLOOD_GROUP: 'GET_USER_BLOOD_GROUP',
    GET_USER_WORK_STATUS: 'GET_USER_WORK_STATUS',
    GET_USER_DESIGNATION: 'GET_USER_DESIGNATION',
    GET_USER_ONBOARDING_MENU: 'GET_USER_ONBOARDING_MENU',
    UPDATE_USER_STATUS: 'UPDATE_USER_STATUS',
    FETCH_USER_PERMISSIONS: 'FETCH_USER_PERMISSIONS',
    GET_DESIGNATION: 'GET_DESIGNATION',
    POST_DESIGNATION: 'POST_DESIGNATION',
    UPDATE_DESIGNATION: 'UPDATE_DESIGNATION',
    DELETE_DESIGNATION: 'DELETE_DESIGNATION',
    GET_USER_COST_CENTRE: 'GET_USER_COST_CENTRE',
    GET_BUSINESS_UNITS: 'GET_BUSINESS_UNITS',
    GET_ORG_DIVISIONS: 'GET_ORG_DIVISIONS',
    GET_EMPLOYMENT_TYPES: 'GET_EMPLOYMENT_TYPES',
    FETCH_MY_PROFILE_PERMISSIONS: 'FETCH_MY_PROFILE_PERMISSIONS',
    GET_USER_NATIONALITY: 'GET_USER_NATIONALITY',
    GET_USER_RELIGION: ' GET_USER_RELIGION',
    GET_USER_RACE: 'GET_USER_RACE',
    FETCH_LOGO: 'FETCH_LOGO',
    GET_USER_FUNCTION: 'GET_USER_FUNCTION',
    GET_USER_CATEGORY: 'GET_USER_CATEGORY',
    GET_USER_WORK_AUTHORIZATION: 'GET_USER_WORK_AUTHORIZATION',
    FETCH_JOB_FORM: 'FETCH_JOB_FORM',
    GET_GOALS_CONFIG: 'GET_GOALS_CONFIG',
    GET_JOB_CONFIG: 'GET_JOB_CONFIG',
    POST_GOALS_INFO: 'POST_GOALS_INFO',
    GET_ALL_GOALS_LIST: 'GET_ALL_GOALS_LIST',
    POST_JOB_INFO: 'POST_JOB_INFO',
    GET_ALL_JOB_LIST: 'GET_ALL_JOB_LIST',
    FETCH_CANDIDATE_FORM: 'FETCH_CANDIDATE_FORM',
    GET_ALL_APPRAISAL_LIST: 'GET_ALL_APPRAISAL_LIST',
    GET_IS_MANAGER: 'GET_IS_MANAGER',
    GET_APPRAISAL_DETAILS: 'GET_APPRAISAL_DETAILS',
    POST_APPRAISAL_DETAILS: 'POST_APPRAISAL_DETAILS',
    POST_JOB_INFO_DRAFT: 'POST_JOB_INFO_DRAFT',
    FETCH_RECRUITING_PERMISSIONS: 'FETCH_RECRUITING_PERMISSIONS',
    GET_CANDIDATE_CONFIG: 'GET_CANDIDATE_CONFIG',
    POST_CANDIDATE_INFO: 'POST_CANDIDATE_INFO',
    GET_ALL_CANDIDATE_LIST: 'GET_ALL_CANDIDATE_LIST',
    POST_CANDIDATE_INFO_DRAFT: 'POST_CANDIDATE_INFO_DRAFT',
    FETCH_PERFORMANCE_PERMISSIONS: 'FETCH_PERFORMANCE_PERMISSIONS',
    GET_EMPLOYEE_CONFIG: 'GET_EMPLOYEE_CONFIG',
    GET_EDUCATION_CONFIG: 'GET_EDUCATION_CONFIG',
    POST_EMPLOYEE_INFO: 'POST_EMPLOYEE_INFO',
    POST_EDUCATION_INFO: 'POST_EDUCATION_INFO',
    GET_INTERVIEW_CONFIG: 'GET_INTERVIEW_CONFIG',
    POST_INTERVIEW_INFO: 'POST_INTERVIEW_INFO',
    GET_INTERVIEW_FEEDBACK_CONFIG: 'GET_INTERVIEW_FEEDBACK_CONFIG',
    GET_CANDIDATE_ASSESSMENT: 'GET_CANDIDATE_ASSESSMENT',
    FETCH_TIME_MANAGEMENT_PERMISSIONS: 'FETCH_TIME_MANAGEMENT_PERMISSIONS',
    GET_ALL_TIME_MANAGEMENT: 'GET_ALL_TIME_MANAGEMENT',
    GET_SALARY_STRUCTURE_CONFIG: 'GET_SALARY_STRUCTURE_CONFIG',
    POST_SALARY_STRUCTURE_INFO: 'POST_SALARY_STRUCTURE_INFO',
    GET_ALL_SALARY_STRUCTURE_LIST: 'GET_ALL_SALARY_STRUCTURE_LIST',
    FETCH_PAYROLL_PERMISSIONS: 'FETCH_PAYROLL_PERMISSIONS',
    GET_ALL_APPROVAL_WORKFLOW_LIST: 'GET_ALL_APPROVAL_WORKFLOW_LIST',
    GET_APPROVAL_WORKFLOW_STRUCTURE_CONFIG: 'GET_APPROVAL_WORKFLOW_STRUCTURE_CONFIG',
    POST_APPROVAL_WORKFLOW_INFO: 'POST_APPROVAL_WORKFLOW_INFO',
    GET_COMPONENTS_CONFIG: 'GET_COMPONENTS_CONFIG',
    GET_COMPONENTS_DETAILS: 'GET_COMPONENTS_DETAILS',
    POST_COMPONENTS_INFO: 'POST_COMPONENTS_INFO',
    UPDATE_COMPONENTS_INFO: 'UPDATE_COMPONENTS_INFO',
    GET_PAYROLL_DETAILS: 'GET_PAYROLL_DETAILS',
    UPDATE_PAYROLL_INFO: 'UPDATE_PAYROLL_INFO',
    GET_ALL_PAYROLL: 'GET_ALL_PAYROLL',
    GET_ALL_PAYROLL_RUNS:'GET_ALL_PAYROLL_RUNS',
    GET_UPCOMING_COMPONENTS_DETAILS: 'GET_UPCOMING_COMPONENTS_DETAILS',
    POST_CONFIG_INFO: 'POST_CONFIG_INFO',
    GET_CLAIM_FORM_CONFIG: 'GET_CLAIM_FORM_CONFIG',
    GET_ALL_FORMS_LIST: 'GET_ALL_FORMS_LIST',
    POST_CLAIM_INFO: 'POST_CLAIM_INFO',
    GET_CLAIMS_DETAILS: 'GET_CLAIMS_DETAILS',
    GET_CLAIMS_TAB: 'GET_CLAIMS_TAB',
    GET_CLAIMS_LIST: 'GET_CLAIMS_LIST',
    GET_ALL_BENEFITS_LIST: 'GET_ALL_BENEFITS_LIST',
    GET_BENEFITS_DETAILS: 'GET_BENEFITS_DETAILS',
    FETCH_COMP_BEN_PERMISSIONS: 'FETCH_COMP_BEN_PERMISSIONS',
    GET_ALL_USER_BENEFITS_LIST: 'GET_ALL_USER_BENEFITS_LIST',
    GET_ALL_CURRENCY_CONVERSION_LIST:'GET_ALL_CURRENCY_CONVERSION_LIST',
    GET_CURRENCY_CONVERSION_FORM_DATA:'GET_CURRENCY_CONVERSION_FORM_DATA',
    GET_MULTI_TRF_CLAIMS_DETAILS: 'GET_MULTI_TRF_CLAIMS_DETAILS'
};

export const toastTimer = 2000;

export const ToastThemes = {
    success: 'success',
    error: 'error'
};

export const toastMessage = {
    success: 'Saved',
    error: 'save',
    publish: 'Published',
    publishError: 'Publish',
    update: 'Updated',
    errorupdate: 'Update',
    upload: 'Uploaded',
    download: 'Downloaded',
    delete:'Delete'
};

export const fileUploadFailure = 'Unable to Upload';

export const FormFieldTypes = {
    MutliSelect: 'MSL',
    SingleSelect: 'SSL',
    Date: 'DT',
    Number: 'NUM',
    Text: 'TXT',
    File: 'FL',
    MultiLineText: 'MLX',
    SearchMultiSelect: 'SMS',
    Email: 'EML',
    Time: 'TM',
    Rating: 'RTG',
    DateTime: 'DTM',
    CheckBox:'CHB',
    Button:'BTN',
    Formula: 'FRM',
    DateRange: 'RNG',
    AutoPopulate: 'AP',
    ProRateField: 'PRF',
    MultiFileUpload: 'MFL',
    Month: 'MP',
    NumberString: 'number',
    UserBasedSSL: 'USD',
    UserBasedField: 'USF',
    EntitlementAutoPopulate: 'EAP',
    CurrencyExchangeField:'CEF',
    Year: 'YP',
    TRF: 'TRF'
};

export const DebounceTimer = 500;

export const ComponentType = {
    Additions: 'A',
    Deductions: 'D'
};

export const ComponentInputType = {
    Onetime: 'O',
    Monthly: 'M',
    Calculated: 'C'
};
