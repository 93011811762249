import React, { useEffect, useState } from 'react';
import { conditionInitialVal } from '../../../../../comp-ben-utlis/comp-ben-utils';
import { getFormBody } from '../../../../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import SelectTag from '../../../../../../../atoms/Select/SelectTag';
import { ruleConjunctionOptions } from '../../../../../../form-builder/utils/conditional.util';
import { DeleteOutline } from '@mui/icons-material';
import BenefitAddConditional from './benefit-add-conditional.component';
import BenefitCalculationExpression from './benefit-calculation-expression.component';
import ApiSelectTag from '../../../../../../../atoms/Select/ApiSelectTag';
import { FormFieldTypes } from '../../../../../../../constants/common';
import DateField from '../../../../../../../atoms/DateField/DateField';
import { formattedTimeValue, getFormattedDate } from '../../../../../../../utils/formatter';

const BenefitExpression = (props) => {
  const { setFormulaRules, data, index, noOfRules, fieldList, configType } =
    props;
  const { conditions: fromInfo, ...rest } = data;

  const [conditions, setConditions] = useState(
    fromInfo || [conditionInitialVal]
  );
  const [ruleFormState, setRuleFormState] = useState(rest || {});

  useEffect(() => {
    setFormulaRules((prev) => {
      const newData = [...prev];
      newData[index] = { ...ruleFormState };
      return newData;
    });
  }, [ruleFormState]);

  useEffect(() => {
    setRuleFormState((prev) => ({
      ...prev,
      conditions: conditions.map((item) => getFormBody(item)),
    }));
  }, [conditions]);

  const handleDropDown = (event) => {
    const { value, name } = event.target;
    setRuleFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDropDownForExpression = (event) => {
    const { value, name } = event.target;
    setRuleFormState((prev) => {
      const updatedValue = {
        ...prev,
        expression: {
          ...prev.expression,
          [name]: value?.fieldColumnName,
        },
      };
  
      if (value.fieldColumnName !== 'CUSTOM') {
        delete updatedValue.expression.date;
      }
      if (updatedValue.expression.value !== 'BLOCK_DOJ') {
        delete updatedValue.expression.endDate;
      }
  
      return updatedValue;
    });
  };

  const handleDateChange = (value, name) => {
    const formattedVal = formattedTimeValue(value, 'YYYY-MM-DD');
    setRuleFormState((prev) => ({
      ...prev,
      expression: {
        ...prev.expression,
        [name]: formattedVal,
      },
    }));
  };

  const handleFieldClick = (field) => {
    const { fieldColumnName } = field;
    setRuleFormState((prev) => {
      return {
        ...prev,
        expression: `${prev.expression || ''} info.${fieldColumnName}`,
      };
    });
  };

  const handleFunctionClick = (field) => {
    const { refName } = field;
    setRuleFormState((prev) => {
      return {
        ...prev,
        expression: `${prev.expression || ''} ${refName}`,
      };
    });
  };

  const handleCalculatorClick = (digit) => {
    setRuleFormState((prev) => {
      const { expression = '' } = prev;
      const lastChar = expression.charAt(expression.length - 1);
      const allowSpace =
        (isNaN(lastChar) && lastChar !== '.') ||
        (isNaN(digit) && digit !== '.');
      const newExpr = `${prev.expression || ''}${
        allowSpace ? ` ${digit}` : `${digit}`
      }`;
      return {
        ...prev,
        expression: newExpr,
      };
    });
  };

  const handleBackClick = () => {
    setRuleFormState((prev) => {
      return { ...prev, expression: '' };
    });
  };

  const handleDelete = () => {
    if (noOfRules === 1) {
      setRuleFormState({});
    } else {
      setFormulaRules((prev) => {
        const newConditions = [...prev];
        newConditions.splice(index, 1);
        setRuleFormState(newConditions[index]);
        return newConditions;
      });
    }
  };

  const getExpression = () => {
    switch (configType) {
      case 'startDate': {
        const newList = [
          {
            id: '3',
            fieldDisplayName: 'Select Option',
            name: 'Select Option',
            fieldType: 'TXT',
            fieldDatatypeCode: 'NUM',
            fieldColumnName: null,
          },
          {
            id: '01',
            fieldDisplayName: 'Calender Year',
            name: 'startOfYear',
            fieldType: 'DT',
            fieldDatatypeCode: 'DT',
            fieldColumnName: 'START_OF_YEAR',
          },
          {
            id: '02',
            fieldDisplayName: 'Custom Date',
            name: 'custom',
            fieldType: 'DT',
            fieldDatatypeCode: 'DT',
            fieldColumnName: 'CUSTOM',
          },
          {
            id: '03',
            fieldDisplayName: 'Block From DOJ',
            name: 'blockDOJ',
            fieldType: 'DT',
            fieldDatatypeCode: 'DT',
            fieldColumnName: 'BLOCK_DOJ',
          },
          ...fieldList.filter((item) => item.fieldType === FormFieldTypes.Date),
        ];
        const newDateList = [
          {
            id: '3',
            fieldDisplayName: 'Select Option',
            name: 'Select Option',
            fieldType: 'TXT',
            fieldDatatypeCode: 'NUM',
            fieldColumnName: null,
          },
          {
            id: '1',
            fieldDisplayName: '12',
            name: '12',
            fieldType: 'TXT',
            fieldDatatypeCode: 'NUM',
            fieldColumnName: '12',
          },
          {
            id: '2',
            fieldDisplayName: '24',
            name: '24',
            fieldType: 'TXT',
            fieldDatatypeCode: 'NUM',
            fieldColumnName: '24',
          },
        ];
        const endDateList = [
          {
            id: '1',
            fieldDisplayName: 'Select Option',
            name: 'Select Option',
            fieldType: 'TXT',
            fieldDatatypeCode: 'NUM',
            fieldColumnName: null,
          },
          {
            id: '3',
            fieldDisplayName: 'End Of Year',
            name: 'endOfYear',
            fieldType: 'DT',
            fieldDatatypeCode: 'DT',
            fieldColumnName: 'END_OF_YEAR',
          },
          {
            id: '4',
            fieldDisplayName: 'End Of Next Year',
            name: 'endOfNextYear',
            fieldType: 'DT',
            fieldDatatypeCode: 'DT',
            fieldColumnName: 'END_OF_NEXT_YEAR',
          },
        ];
        return (
          <>
            <ApiSelectTag
              defaultValue={'Select Start Date'}
              fullWidth
              size="small"
              dropDownList={newList.map((item) => {
                return { ...item, name: item.fieldDisplayName };
              })}
              name="value"
              value={
                newList.find((item) => item.fieldColumnName === ruleFormState.expression?.value)
                  ?.fieldDisplayName
              }
              onchange={handleDropDownForExpression}
            />
            {ruleFormState.expression?.value === 'CUSTOM' && (
              <>
                <Box margin={2} />
                <DateField
                  placeholder="dd/mm/yyyy"
                  name="date"
                  value={
                    ruleFormState.expression?.date
                      ? getFormattedDate(ruleFormState.expression?.date, 'YYYY-MM-DD', 'DD/MM/YYYY')
                      : null
                  }
                  onChange={handleDateChange}
                  fullWidth
                />
              </>
            )}
            <Box margin={2} />
            <ApiSelectTag
              defaultValue={'Select Block'}
              fullWidth
              size="small"
              dropDownList={newDateList.map((item) => {
                return { ...item, name: item.fieldDisplayName };
              })}
              name="block"
              value={
                newDateList.find((item) => item.fieldColumnName === ruleFormState.expression?.block)
                  ?.fieldDisplayName
              }
              onchange={handleDropDownForExpression}
            />
            {ruleFormState.expression?.value === 'BLOCK_DOJ' && (
              <>
                <Box margin={2} />
                <ApiSelectTag
                  defaultValue={'Select End Date'}
                  fullWidth
                  size="small"
                  dropDownList={endDateList.map((item) => {
                    return { ...item, name: item.fieldDisplayName };
                  })}
                  name="endDate"
                  value={
                    endDateList.find(
                      (item) => item.fieldColumnName === ruleFormState.expression?.endDate
                    )?.fieldDisplayName
                  }
                  onchange={handleDropDownForExpression}
                />
              </>
            )}
          </>
        );
      }
      default: {
        return (
          <>
            <BenefitCalculationExpression
              handleFieldClick={handleFieldClick}
              handleCalculatorClick={handleCalculatorClick}
              expressionVal={ruleFormState.expression}
              handleBackClick={handleBackClick}
              handleFunctionClick={handleFunctionClick}
              fieldList={fieldList}
            />
          </>
        );
      }
    }
  };

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={8}>
        <Grid item xs={12} md={12} display="flex">
          <SelectTag
            name="conjunction"
            dropDownList={ruleConjunctionOptions}
            size="small"
            fullWidth
            value={ruleFormState?.conjunction}
            onchange={handleDropDown}
          />
          <Typography display="flex" alignItems="center">
            {' '}
            &nbsp; of the conditions are met
          </Typography>
          <Box display="flex" alignItems="center"></Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper sx={{ p: 2 }}>
            {conditions.map((item, index) => {
              return (
                <BenefitAddConditional
                  key={index}
                  index={index}
                  setConditions={setConditions}
                  condition={item}
                  noOfConditions={conditions.length}
                  setFormState={setRuleFormState}
                  fieldList={fieldList}
                />
              );
            })}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          {getExpression()}
        </Grid>
        <Grid item xs={12} md={12} display="flex" justifyContent="flex-end">
          <Button
            color="error"
            variant="outlined"
            sx={{
              borderColor: 'white',
              borderRadius: '20px',
              textTransform: 'capitalize',
            }}
            onClick={handleDelete}
          >
            <DeleteOutline sx={{ width: '24px', height: '24px', mr: '5px' }} />
            Delete
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default BenefitExpression;
