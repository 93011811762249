import Joi from 'joi-browser';
export const companyInfoSchema = {
    companyName:Joi.string().required(),
    companyEmail: Joi.string().regex(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/).required().error(() => {
      return {
        message: 'Invalid Email',
      };
    }),
    companyDescription:Joi.string().allow(''),
    companyLogo:Joi.allow(null),
    companyWebsite:Joi.string().allow(''),
    callingCode:Joi.allow(null),
    altCallingCode:Joi.allow(null),
    billingAddress:Joi.string().allow(''),
    billingAddressCity:Joi.allow(null),
    addressCity:Joi.required().allow(null).error(() => {
      return {
        message: 'City is Required',
      };
    }),
    billingAddressCountry:Joi.allow(null),
    addressCountry:Joi.required().allow(null).error(() => {
      return {
        message: 'Country is Required',
      };
    }),
    phoneNumber: Joi.string().required(),
    altPhoneNumber: Joi.string().required(),
    address: Joi.string().required(),
    gstNumber: Joi.string().required(),
    addressPostalCode:Joi.string().required(),
    billingAddressPostalCode:Joi.string().allow(''),
    addressState:Joi.required().allow(null).error(() => {
      return {
        message: 'State is Required',
      };
    }),
    billingAddressState:Joi.allow(null),
    establishedOn: Joi.allow(null)
    
  };

  export const companyInfoUpdationSchema = {
    companyName:Joi.string().allow(''),
    companyEmail: Joi.string().regex(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/),
    companyDescription:Joi.string().allow(''),
    companyLogo:Joi.allow(null),
    companyWebsite:Joi.string().allow(''),
    countryCodeMNo:Joi.allow(null),
    CountryCodeAltMNo:Joi.allow(null),
    billingAddress:Joi.string().allow(''),
    baddCityId:Joi.allow(null),
    addCityId:Joi.allow(null),
    bCountryId:Joi.allow(null),
    countryId:Joi.allow(null),
    mobileNumber: Joi.string().allow(),
    alternateMobileNumber: Joi.string(),
    address: Joi.string().allow(),
    gstNumber: Joi.string().allow(),
    postalCode:Joi.string().allow(''),
    altPostalCode:Joi.string().allow(''),
    stateId:Joi.allow(null),
    bStateId:Joi.allow(null),
    establishedOn: Joi.allow(null)
  };