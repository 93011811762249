import API from './api';
export class InterviewServices extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL + '/recruiting/interview-schedule/' || '';
  constructor() {
    super(InterviewServices.baseURL);
  }

  getInterviewDetails = async (roleId, candidateJobId) => (
    this.responsify(await this.instance.get('interview', {
      params: {
        roleId,
        jobMappingId: candidateJobId
      }
    }))
  );

  postInterviewInfo = async (interviewFormInfo) => (
    this.responsify(await this.instance.post('interview', interviewFormInfo)));

  getDropdownOptions = async (fieldName) => (
    this.responsify(await this.instance.get(`lookup-details/${fieldName}`))
  );

  getInterviewFeedbackDetails = async (roleId, interviewId) => (
    this.responsify(await this.instance.get('feedback', {
      params: {
        roleId,
        interviewId
      }
    }))
  );

  postInterviewFeedbackInfo = async (body, interviewId) => (
    this.responsify(await this.instance.put('feedback', body, {params:{interviewId}})));

}


