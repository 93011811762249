import React from 'react';
import { Box, Typography } from '@mui/material';

export const FormHeader = (props) => {
  const { title, labelCode } = props;
  return (
    <Box mb={3} mx={-3} mt={-3}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box border={labelCode?3:0} borderColor={labelCode?'primary.main40':''} paddingRight={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <Box sx={{ px: 3, py: '12px' }} bgcolor={'primary.main40'}>
            <Typography variant='body2' color={'neutral.dark'}>
              {title}
            </Typography>
          </Box>
          <Typography variant='body2Bold' color={'neutral.dark'} pl={2}>
            {labelCode}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
