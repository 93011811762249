import React, { useEffect, useState } from 'react';
import './DashboardTable.css';

import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TableCell,
  TextField,
  Box,
  Icon,
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useNavigate } from 'react-router-dom';
import store from '../../redux/store';

import SortIcon from '../../assets/sort.svg';

import { setClaimData } from '../../redux/actions/employee-portal/employee-portal.action';
import { IconButton } from '@material-ui/core';
import ButtonUI from '../../shared/components/ui-elements/ButtonUI';
import { ClaimRejectModal } from '../../shared/components/claim-forms';
import {
  approveClaimMultipleRequestCall,
  rejectClaimMultipleRequestCall,
} from '../forms/common-services/services';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { PaymentDate } from '../../shared/components/claim-forms';
import InfoIcon from '../../assets/info-icon.svg';
import { getPayDateForManager } from '../forms/common-services/functions';
import ReasonIssueList from '../../constants/Reasons';
import Downloader, { downloadWithCustomName as downloadFile } from '../download/Downloader'; 
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';

// import { DatePicker, LocalizationProvider } from '@mui/lab'; 
// import { AdapterDateFns as AdapterDayjs } from '@mui/x-date-pickers/AdapterDateFns';
const useStyles = makeStyles({
  root: {
    '&.MuiFormControl-root': {
      marginTop: '5px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#00ac94',
      },
    },
    '&.MuiCheckbox-root': {
      color: 'rgba(0, 0, 0, 0.54)',
      '&.Mui-checked': {
        color: '#00ac94',
      },
    },
    '&.MuiTypography-body1': {
      fontSize: '14px',
    },
  },
});
const typeOfChange = {select:'SELECT', date:'DATE'};
const DashboardTable = (props) => {
  const {
    valueToOrderBy,
    orderDirection,
    handleRequestSort,
    getComparator,
    sortedRowInformation,
  } = props;

  const downloadWithCustomName = (url,name,promise=true)=>downloadFile(url,name,promise);
  const classes = useStyles();
  // Functions for sorting

  const createSortHandler = (property) => (Event) => {
    // console.log(toggle, 'toggle');

    if (
      toggle.name ||
      toggle.claimType ||
      toggle.submissionData ||
      toggle.refNumber ||
      toggle.amount ||
      toggle.status
    ) {
      settoggle({
        name: false,
        refNumber: false,
        claimType: false,
        submissionData: false,
        amount: false,
        status: false,
      });
    }

    settoggle((prev) => (prev = { [property]: !toggle[`${property}`] }));

    handleRequestSort(Event, property, toggle);
  };

  // Sorting functions ends

  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');
  const [finalData, setFinalData] = useState([]);
  const [copyFinalData, setCopyFinalData] = useState([]);
  const [activeActions, setActiveActions] = useState(false);
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [selected, setSelected] = React.useState(new Set());
  const [selectedReason, setSelectedReason] = useState([]);
  const [remark, setRemark] = useState('');
  const [data, setData] = useState([]);
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [payDate, setPayDate] = useState(new Date());
  const [toggle, settoggle] = useState({
    name: false,
    refNumber: false,
    claimType: false,
    submissionData: false,
    amount: false,
    status: false,
  });
  const [downloading, setDownloading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dateRange, setDateRange] = useState({start:null,end:null});
  const [enableActionOnForm, setEnableActionOnForm] = useState(false);

  const [downloadQueue, setDownloadQueue] = useState([]);

  useEffect(()=>{
    console.log('Download Que', downloadQueue);
    if(downloadQueue && downloadQueue.length){
      const temp = [...downloadQueue];
      if(temp[0]){
        const [file,...rest] = temp;
        file().finally(()=>{
          setDownloadQueue([...rest]);
        });
      }
    }else{
      setDownloading(false);
    }
  },[downloadQueue]);

  // const [tempData, setTempData] = useState([]);

  // useEffect(()=>{
  //   console.log('Download Que', downloadQueue);
  //   if(downloadQueue && downloadQueue.length){
  //     const temp = [...downloadQueue];
  //     if(temp[0]){
  //       const [file,...rest] = temp;
  //       file().then(()=>{
  //         setDownloadQueue([...rest]);
  //       });
  //     }
  //   }else{
  //     setDownloading(false);
  //   }
  // },[downloadQueue]);

 

  // const handleDownloadFiles = () => {
  //   setDownloading(true);
  //   const zip = new JSZip();
  //   const queryList = [];
  //   selectedFiles.forEach((meta) => {

  //     if (Array.isArray(meta.files)) {

  //       meta.files?.forEach((file,i) => {
  //         const fileName = meta.files.length > 1 ? `${meta.name}_${i}` : meta.name;
  //         queryList.push({file,name:fileName});
  //       });

  //     } else {
  //       queryList.push({file:meta.files,name:meta.name});
  //     }

  //   });
  //   queryList.forEach(async (query,count)=>{
  //     const url = `${process.env.REACT_APP_DOCUMENT_PATH}${query.file}`;
  //     const file = await JSZipUtils.getBinaryContent(url).catch('Error while donloading the file.');
  //       // setTempData((temp)=>{temp.push(file); return temp;});
  //       const urlArr = query.file.split('.');
  //       const extension = urlArr[urlArr.length-1];
  //       zip.file(`${query.name}-${count}${extension}`, file, { binary: true});
  //       if(count === queryList.length-1) {
  //         zip.generateAsync({type:'blob'}).then(function(content) {
  //           saveAs(content, Date.now());
  //           setDownloading(false);
  //         });
  //       }
  //   });
  //   console.log(queryList);
  //   setDownloadQueue(queryList);
  // };
  const handleDownloadFiles = () => {
    setDownloading(true);
    const queryList = [];
    selectedFiles.forEach((meta) => {

      if (Array.isArray(meta.files)) {

        meta.files?.forEach((file,i) => {

          queryList.push(() => downloadWithCustomName(file, `${meta.name}_${i}`));

        });

      } else {
        queryList.push(()=> downloadWithCustomName(meta.files, meta.name));
      }

    });
    setDownloadQueue(queryList);
  };

const [openDatePicker, setOpenDatePicker] = useState(false);
const [firstChange, setFirstChange] = useState('');

const filterOnBaseOfText = (claims = [], text='')=>{
  return claims.filter((claim) => {
    if(claim.name.toLowerCase().includes(text) ||
    claim.refNumber.toLowerCase().includes(text) ||
    claim.status.toLowerCase().includes(text)){
      return true;
    }else if (props.currentUserType !== 'Employee') {
      return claim.hasAttachment.toLowerCase().includes(text);
    }
  });
};

const getClaimsInDateRange = ()=>{
  if(copyFinalData){
    const temp = [...copyFinalData];
    return temp.filter(claim=> checkInSelectedDateRange(claim.submissionData));
  }
  return [];
};


  const filter = (text='')=>{
    let data = [];
    if(firstChange === typeOfChange.select){
      // if text is the first change.
      if(dateRange.start && dateRange.end) {
        // if date is selected but firstChange is text; 
        data = filterOnBaseOfText(getClaimsInDateRange(),text);
      }else{
        // if date is not selected and want to filter on the base of text;
        data = filterOnBaseOfText(copyFinalData,text);
      }
    }else{
      // if date is the first change
      if(text){
        // if date is selected and want to search by text;
        data = filterOnBaseOfText(getClaimsInDateRange(),text);
      }else{
        // only filter by date range
        data = getClaimsInDateRange();
      }
    }
    setFinalData(data);
    // if date is selected but not want of search on the base of text;
  };

  const handleChangeInput = (e) => {
    setSearchValue(()=>e.target.value);
    if(!dateRange.start && !dateRange.end){
      setFirstChange(()=> typeOfChange.select);
    }

    if(copyFinalData){
      filter(e.target.value);
    }

    // if (copyFinalData) {
      // if (e.target.value.length > 0) {
      //   let records = [...copyFinalData];
      //   if(dateRange.start && dateRange.end){
      //     records = records.filter(claim =>{
      //       return checkInSelectedDateRange(claim.submissionData);
      //     });
      //   }
      //   console.log('records', records);
      //   const value = e.target.value.toLowerCase();
      //   const filteredRows = records.filter((row) => {
      //     console.log('row---->',row);
      //     if(row.name.toLowerCase().includes(value) ||
      //     row.refNumber.toLowerCase().includes(value) ||
      //     row.status.toLowerCase().includes(value)){
      //       return true;
      //     }else if (props.currentUserType !== 'Employee') {
      //       return row.hasAttachment.toLowerCase().includes(value);
      //     }
      //   });
      //   setFinalData(filteredRows);
      // } else {
      //   setFinalData(copyFinalData);
      // }
    // }
  };

  const handleDateRangeSelect = (value,position)=>{
    if(!searchValue) {
      setFirstChange(()=>typeOfChange.date);
    }
    setDateRange(()=> ({...dateRange, [position]:value}));

    filter();
  };

  let filteredData = [];

  if (props.tableData && props.tableData.length > 0) {
    filteredData = props.tableData.filter(
      (item) => item.referenceNumber.toLowerCase() == searchValue.toLowerCase()
    );
  }

  let updatedObject = {};
  let updatedArray = [];
  let wholeObject = {};
  let newArr = [];

  let filteredDataNew = [];

  if (props.tableData.length > 0) {
    filteredDataNew = props.tableData.filter(
      (data) => data.type.id === props.id
    );

    updatedArray =
      filteredDataNew.length > 0 &&
      filteredDataNew.map((item) => {
        return (updatedObject = {
          name:
            item.employee.firstName.toUpperCase() +
            ' ' +
            item.employee.lastName.toUpperCase(),
          refNumber: item.referenceNumber,
          claimType:
            (item.claimSubType == null ? item.type.name : item.claimSubType) ||
            item.modeOfTransport,
          submissionData:
            item.submissionDate ? item.submissionDate : item.createdAt,
          // ||
          // item.receiptDate ||
          // item.paymentDate ||
          // item.travelDate ||
          // moment(item.createdAt).format('DD-MM-YYYY'),
          amount:
            item.claimAmount ||
            item.finalAmount ||
            item.receiptAmount ||
            Number(item.amount).toFixed(2),
          status: `${item.status}${item.isResubmitted && item.status == 'PENDING' ? '(R)' : ''
            }`,
          hasAttachment: item.attachment?.length ? 'Yes' : 'No',
          id: item.id,
          type: item.type.id,
          linkName: item.type.name,
          paymentDate: item?.paymentDate,
          attachment: item.attachment ? item.attachment : [],

        });
      });

    newArr = props.tableData.map((item) => {
      return (wholeObject = item);
    });
  }

  const passDataToClaim = (data, refNumber) => {
    let linkId = data.type;
    let linkName = data.linkName;

    let formIds = [7, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
    let value = new RegExp(formIds.join('|')).test(linkId);
    // Logic for common forms of expenses.
    if (value) {
      let updatedLink = linkName.replaceAll(' ', '-');
      navigate(`/employee-portal/expenses/${updatedLink}/view`);
    } else {
      if (data.linkName == 'overtime-claim') {
        navigate('/employee-portal/overtime-claim/view');
      }
      if (data.linkName == 'transport-claim') {
        navigate('/employee-portal/transport-claim/view');
      }
      if (data.linkName == 'flexible-benefit') {
        navigate('/employee-portal/flexible-benefit/view');
      }
      if (data.linkName == 'commission-claim') {
        navigate('/employee-portal/commission-claim/view');
      }
      if (data.linkName == 'funeral-assistance') {
        navigate('/employee-portal/funeral-assistance/view');
      }
      if (data.linkName == 'staff-training') {
        navigate('/employee-portal/staff-training/view');
      }
      if (data.linkName == 'unergizer-claim') {
        navigate('/employee-portal/unergizer-claim/view');
      }
      if (data.linkName == 'marriage') {
        navigate('/employee-portal/marriage/view');
      }
      if (data.linkName == 'union-gathering') {
        navigate('/employee-portal/union-gathering/view');
      }
      if (data.linkName == 'child-birth') {
        navigate('/employee-portal/child-birth/view');
      }
      if (data.linkName == 'computer-loan') {
        navigate('/employee-portal/computer-loan/view');
      }
      if (data.linkName == 'study-loan') {
        navigate('/employee-portal/study-loan/view');
      }
      if (data.linkName == 'retirement') {
        navigate('/employee-portal/retirement/view');
      }
    }

    store.dispatch(setClaimData(data, newArr, refNumber));
  };

  // Check box handler for single checkboxes
  const checkboxHandler = (ref, e, i) => {
    let newArray = [...finalData];
    newArray.forEach((claim,j)=>{
      if(ref === claim.refNumber){
        newArray[j].isChecked = !newArray[j].isChecked;
      }
    });
    setFinalData(newArray);

    let isUnchecked = false;
    for (let j = 0; j < finalData.length; j++) {
      if (
        finalData[j].isChecked === undefined ||
        finalData[j].isChecked === false
      ) {
        isUnchecked = true;
        break;
      }
    }
    // setEnableActionOnForm(false);
    if (isUnchecked) {
      setSelectAllCheck(false);
    } else {
      setSelectAllCheck(true);
    }

    let finalDataChecked = [];
    let pendingClaims = [];
    finalDataChecked = finalData.filter((each) => {
      if(each.isChecked && (each.status === 'PENDING' || each.status === 'PENDING(R)')) {
        pendingClaims.push(each);
      }
      return each.isChecked;
    });


    if (finalDataChecked.length > 0) {
      setActiveActions(true);
      setEnableActionOnForm(pendingClaims.length ? true : false);
      setData(finalDataChecked);
    } else {
      setActiveActions(false);
    }
  };

  // Select All checkbox handler
  const selectAllCheckHandler = () => {
    let EnableAction = false;
    let newArray = finalData.map((element) => {
      element.isChecked = !selectAllCheck;
      if(!selectAllCheck)
      if(element.status === 'PENDING' || element.status === 'PENDING(R)'){
        EnableAction = true;
      }
      return element;
    });

    setFinalData([...newArray]);

    setSelectAllCheck((prevState) => !prevState);

    let finalDataChecked = [];

    finalDataChecked = finalData.filter((each) => each.isChecked);

    if (finalDataChecked.length > 0) {
      setActiveActions(true);
      setEnableActionOnForm(EnableAction);
      setData(finalDataChecked);
    } else {
      setActiveActions(false);
    }
  };

  useEffect(() => {
    setFinalData(updatedArray);
    setCopyFinalData(updatedArray);
  }, [props.tableData]);

  // useEffect(() => {
  //   let temp = [];
  //   data.forEach(row => {
  //     if (row.attachment?.length && row.isChecked) {
  //       temp.push({ name: row.refNumber, files: row.attachment });
  //     }
  //   });
  //   setSelectedFiles(temp);
  //   setDownload(temp.length ? true: false);
  // }, [data]);
  useEffect(()=>{
    let temp = [];
    let length = 0;
    if(Array.isArray(finalData)){
      finalData.forEach(row => {
        if(row.isChecked){
          length = length+1;
          if (row.attachment?.length) {
            temp.push({ name: row.refNumber, files: row.attachment });
          }
        }
      });
      setSelectedFiles(temp);
      setDownloading(temp.length ? false: true);
      setSelectAllCheck(length && length === finalData.length);
    }
  },[finalData]);
  // List for claim reject reasons for Manager
  const selectionList = ReasonIssueList;

  /** Close the modal */
  const closeARModalHandler = () => {
    // setIsSuccess(false);
    setIsBackdropOpen(false);
    setRemark('');
    setSelected(new Set());
  };

  // Chip selection logic for reject modal, helps manager to selected claim reject reasons.
  const handleChipSelected = (id) => {
    // console.log(id,'selected');
    const newSet = new Set(selected);
    if (newSet.has(id)) newSet.delete(id);
    else newSet.add(id);
    const arr = [...newSet];
    setSelectedReason(arr);
    setSelected(newSet);
  };

  // Manager can reject claim request for Employee.
  const handleApproveRequest = async () => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    let date = new Date(payDate);
    let updatedRequestData = [];

    if (finalData) {
      updatedRequestData = finalData.filter((each) => each.isChecked && !getCheckboxEnableStatus(each));
      updatedRequestData = updatedRequestData.map((each) => {
        if (each.isChecked) {
          return {
            remark: 'Test',
            status: 'APPROVED',
            claimID: each.id,
            user: userData.userDetails.id.toString(),
            paymentDate: date,
            reasons: selectedReason,
          };
        }
      });
    }
    // console.log('Approved Data', updatedRequestData);
    let approveResponse = await approveClaimMultipleRequestCall(
      updatedRequestData
    );

    if (approveResponse == 'Action Completed Successfully!') {
      navigate('/claims');
      setSelectAllCheck(false);
      setActiveActions(false);
      props.getDataForManager(userData.userDetails.id);
      // window.location.reload();
      closeBackdropHandler();
    }
  };

  // Manager can reject claim request for Employee.
  const handleRejectRequest = async (status) => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    let date = new Date(payDate);
    let updatedRequestData = [];

    if (finalData) {
      updatedRequestData = finalData.filter((each) => each.isChecked && !getCheckboxEnableStatus(each));
      updatedRequestData = updatedRequestData.map((each) => {
        if (each.isChecked) {
          return {
            remark: remark,
            status: status,
            claimID: each.id,
            user: userData.userDetails.id.toString(),
            paymentDate: date,
            reasons: selectedReason,
          };
        }
      });
    }

    let rejectResponse = await rejectClaimMultipleRequestCall(
      updatedRequestData
    );

    if (rejectResponse == 'Action Completed Successfully!') {
      navigate('/claims');
      setSelectAllCheck(false);
      setActiveActions(false);
      props.getDataForManager(userData.userDetails.id);
      closeBackdropHandler();
    }
  };

  // Remarks textfield change handler for reject claim modal
  const handleRemarkChange = (event) => {
    setRemark(event.target.value);
  };

  // Modal close handler
  const closeBackdropHandler = () => {
    setIsBackdropOpen(false);
  };

  // Modal Open handler
  const openBackdropHandler = () => {
    setIsBackdropOpen(true);
    setRemark('');
    setSelected(new Set());
  };

  // For payment date set
  useEffect(() => {
    setPayDate(getPayDateForManager());
  }, []);

  // Passing claim type id to audit-trails.
  const passDataToReports = (data) => {
    navigate('/employee-portal/audit-trail', {
      state: {
        claimType: data,
      },
    });
  };

  const DisableDateForThisRole = ['Manager', 'HOD'];

  const getCheckboxEnableStatus = (data) => {
    let result = true;
    if (data.status === 'PENDING' || data.status === 'PENDING(R)') {
      result = false;
      result = !checkInSelectedDateRange(data.submissionData);
    }
    return result;
  };

  const checkInSelectedDateRange = (submissionDate )=>{
    if(dateRange.start && dateRange.end){
      const start = moment(moment(dateRange.start.format('DD-MM-YYYY'),'DD-MM-YYYY'));
      const end = moment(dateRange.end.format('DD-MM-YYYY'),'DD-MM-YYYY');
      const submissionData = moment(moment(submissionDate).format('DD-MM-YYYY'),'DD-MM-YYYY');
      if((start.isSame(submissionData) || start.isBefore(submissionData))&& (end.isAfter(submissionData) || end.isSame(submissionData))){
            
        return true;
      }
      return false;
    }else{
      return true;
    }
  };

  const resetSelectedDate = (reset=false)=>{
     setFinalData(copyFinalData.map(claim=> {claim.isChecked = false; return claim;}));
     setDateRange({start:null, end:null});
     setSearchValue('');
     setOpenDatePicker(false);
     setSelectAllCheck(false);
  };

  const FilterClaimInRange = (start,end)=>{
    return claim =>{
      const submissionData =  moment(moment(claim.submissionData).format('DD-MM-YYYY'),'DD-MM-YYYY');
      if((start.isSame(submissionData) || start.isBefore(submissionData)) && (end.isAfter(submissionData) || end.isSame(submissionData))){
        return true;
      }
      return false;
    };
  };

  useEffect(() => {
    
    if(dateRange.start && dateRange.end){ 
      const temp = [...copyFinalData];
      const start = moment(dateRange.start.format('DD-MM-YYYY'),'DD-MM-YYYY');
      const end = moment(dateRange.end.format('DD-MM-YYYY'),'DD-MM-YYYY');

      const filteredData = temp.filter(FilterClaimInRange(start,end));

      const newData = data.filter(FilterClaimInRange(start,end));
      setData(newData);
      setFinalData(filteredData);

    }else{
      // setFinalData([...copyFinalData]);
      handleChangeInput({target:{value:'' && searchValue}});
    }
  
    
  }, [dateRange.start,dateRange.end]);
  

  return (
    <React.Fragment>
      <Grid container>
        <Grid item lg={12} md={4} sm={4} xs={12} className="table-options-wrap">
          <>
          <input
            type="text"
            placeholder="Search"
            className="search-bar"
            value={searchValue}
            onChange={handleChangeInput}
          />
          <Box style={{display:'flex', flexFlow:'row'}}>
          {openDatePicker &&  <>
          <div style={{margin:'0 5px',width:'195px'}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="SUBMISSION FROM"
            value={dateRange.start}
            inputFormat="DD-MM-YYYY"
            inputPlaceholder="DD-MM-YYYY"
            onChange={(newValue) => {
              handleDateRangeSelect(newValue,'start');
              // setDateRange({...dateRange,start: newValue});
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          </LocalizationProvider>
          </div>
          <div style={{margin:'0 5px', width:'195px'}}>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="TO SUBMISSION"
            value={dateRange.end}
            inputFormat="DD-MM-YYYY"
            inputPlaceholder="DD-MM-YYYY"
            onChange={(newValue) => {
              handleDateRangeSelect(newValue,'end');
              // setDateRange({...dateRange,end: newValue});
            }}
            renderInput={(params) => <TextField align="center" {...params} />}
          />
          </LocalizationProvider>
          </div> </>}
          <div className="action-buttons">
          {!openDatePicker && <ButtonUI
                backgroundColor="#00AC94"
                color="#ffffff"
                size="small"
                margin="0 3px"
                onclick={()=>setOpenDatePicker(true)}>
           <CalendarMonthIcon fontSize='small'/>
          </ButtonUI>
           }
          <ButtonUI
                backgroundColor="#00AC94"
                color="#ffffff"
                size="small"
                onclick={()=>resetSelectedDate()}>
            { <RestartAltIcon fontSize='small'/>}
          </ButtonUI>
          </div>
          </Box>
          </>
          {activeActions && props.currentUserType !== 'Employee' && (
            <div className="action-buttons">
              {selectedFiles.length ? (
              <ButtonUI
                backgroundColor="#00AC94"
                color="#ffffff"
                size="small"
                padding="3px 20px"
                margin="0 10px 0 10px "
                onclick={handleDownloadFiles}
                disabled={downloading}
              >
                <CloudDownloadIcon style={{marginRight:'5px'}}/> Attachment {/* selectedFiles && `(${selectedFiles.length})`*/}
              </ButtonUI>
              ): ''}
              <PaymentDate
                style={{width:'195px !important'}}
                payDate={payDate}
                onChange={(e) => setPayDate(e)}
                fromDashboard={true}
                disabledPicker={DisableDateForThisRole.includes(
                  props.currentUserType
                )}
              />

              <ButtonUI
                backgroundColor="#00AC94"
                color="#ffffff"
                size="small"
                padding="3px 20px"
                margin="0 10px 0 10px "
                onclick={handleApproveRequest}
                disabled={!enableActionOnForm}
              >
                Approve
              </ButtonUI>
              <ButtonUI
                variant="outlined"
                backgroundColor="#fff"
                color="#00AC94"
                size="small"
                padding="3px 20px"
                margin="0 10px 0 0"
                border="1px solid #00AC94"
                onclick={openBackdropHandler}
                disabled={!enableActionOnForm}
              >
                Send Back
              </ButtonUI>
            </div>
          )}
        </Grid>
        {/* <CustomDateRangePicker setDateRange={setDateRange}/> */}
        <Grid item lg={12} style={{ marginTop: '20px' }}>
          <TableContainer className="responsive_table">
            <Table sx={{ minWidth: 650 }} id="myTable">
              <TableHead
                style={{
                  borderBottom: '3px solid #F7F7F7',
                  borderRadius: '11px',
                }}
              >
                <TableRow className="my-custom-table">
                  {props.currentUserType !== 'Employee' && (
                    <TableCell>
                      <Checkbox
                        size="small"
                        onChange={(e) => selectAllCheckHandler(e)}
                        checked={selectAllCheck}
                        inputProps={{ 'aria-label': 'controlled' }}
                        classes={classes}
                      />
                    </TableCell>
                  )}
                  <TableCell
                    key="name"
                    className={
                      toggle.name
                        ? 'tablecell-title-active'
                        : 'tablecell-title-holder'
                    }
                    sortDirection={
                      valueToOrderBy === 'name' ? orderDirection : false
                    }
                  >
                    NAME
                    <IconButton onClick={createSortHandler('name')}>
                      {/* <img src={SortIcon} alt='Sort' />
                       */}
                      <svg
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiDataGrid-sortIcon css-1k33q06"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ArrowDownwardIcon"
                      >
                        <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path>
                      </svg>
                    </IconButton>
                  </TableCell>
                  <TableCell
                    key="refNumber"
                    // className='tablecell-title-holder'
                    className={
                      toggle.refNumber
                        ? 'tablecell-title-active'
                        : 'tablecell-title-holder'
                    }
                    sortDirection={
                      valueToOrderBy === 'refNumber' ? orderDirection : false
                    }
                  >
                    REFERENCE NUMBER{' '}
                    <IconButton onClick={createSortHandler('refNumber')}>
                      {/* <img src={SortIcon} alt='Sort' /> */}
                      <svg
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiDataGrid-sortIcon css-1k33q06"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ArrowDownwardIcon"
                      >
                        <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path>
                      </svg>
                    </IconButton>
                  </TableCell>
                  <TableCell
                    key="claimType"
                    className={
                      toggle.claimType
                        ? 'tablecell-title-active'
                        : 'tablecell-title-holder'
                    }
                    sortDirection={
                      valueToOrderBy === 'claimType' ? orderDirection : false
                    }
                  >
                    CLAIM TYPES
                    <IconButton onClick={createSortHandler('claimType')}>
                      {/* <img src={SortIcon} alt='Sort' /> */}
                      <svg
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiDataGrid-sortIcon css-1k33q06"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ArrowDownwardIcon"
                      >
                        <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path>
                      </svg>
                    </IconButton>
                  </TableCell>
                  <TableCell
                    key="submissionData"
                    className={
                      toggle.submissionData
                        ? 'tablecell-title-active'
                        : 'tablecell-title-holder'
                    }
                    sortDirection={
                      valueToOrderBy === 'submissionData'
                        ? orderDirection
                        : false
                    }
                  >
                    SUBMISSION DATE
                    <IconButton onClick={createSortHandler('submissionData')}>
                      {/* <img src={SortIcon} alt='Sort' /> */}
                      <svg
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiDataGrid-sortIcon css-1k33q06"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ArrowDownwardIcon"
                      >
                        <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path>
                      </svg>
                    </IconButton>
                  </TableCell>
                  <TableCell
                    key="amount"
                    className={
                      toggle.amount
                        ? 'tablecell-title-active'
                        : 'tablecell-title-holder'
                    }
                    sortDirection={
                      valueToOrderBy === 'amount' ? orderDirection : false
                    }
                  >
                    AMOUNT
                    <IconButton onClick={createSortHandler('amount')}>
                      {/* <img src={SortIcon} alt='Sort' /> */}
                      <svg
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiDataGrid-sortIcon css-1k33q06"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ArrowDownwardIcon"
                      >
                        <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path>
                      </svg>
                    </IconButton>
                  </TableCell>
                  <TableCell
                    key="status"
                    className={
                      toggle.status
                        ? 'tablecell-title-active'
                        : 'tablecell-title-holder'
                    }
                    sortDirection={
                      valueToOrderBy === 'status' ? orderDirection : false
                    }
                  >
                    STATUS
                    <IconButton onClick={createSortHandler('status')}>
                      {/* <img src={SortIcon} alt='Sort' /> */}
                      <svg
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiDataGrid-sortIcon css-1k33q06"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ArrowDownwardIcon"
                      >
                        <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path>
                      </svg>
                    </IconButton>
                  </TableCell>
                  {props.currentUserType !== 'Employee' && (<TableCell
                    key="hasAttachment"
                    className={
                      toggle.status
                        ? 'tablecell-title-active'
                        : 'tablecell-title-holder'
                    }
                    sortDirection={
                      valueToOrderBy === 'hasAttachment' ? orderDirection : false
                    }
                  >
                    ATTACHMENT
                    <IconButton onClick={createSortHandler('hasAttachment')}>
                      {/* <img src={SortIcon} alt='Sort' /> */}
                      <svg
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiDataGrid-sortIcon css-1k33q06"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ArrowDownwardIcon"
                      >
                        <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path>
                      </svg>
                    </IconButton>
                  </TableCell>)}

                  <TableCell key="action" className="tablecell-title-holder">
                    ACTION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="custom-table-body">
                {sortedRowInformation &&
                  sortedRowInformation(
                    finalData || [],
                    getComparator(orderDirection, valueToOrderBy)
                  ).map((row, i) => {
                    return (
                      <TableRow key={(row, i)} className="table-data">
                        {props.currentUserType !== 'Employee' && (
                          <TableCell>
                            <Checkbox
                              name="isChecked"
                              size="small"
                              // disabled={getCheckboxEnableStatus(row)}
                              onChange={(e) =>
                                checkboxHandler(row.refNumber, e, i)
                              }
                              checked={row.isChecked ?? false}
                              inputProps={{ 'aria-label': 'controlled' }}
                              classes={classes}
                            />
                          </TableCell>
                        )}
                        {
                          Object.values(row)
                            .splice(0, props.currentUserType !== 'Employee' ? 7 : 6)
                            .map((each, i) => {
                              return (
                                <TableCell
                                  onClick={() => {
                                    (props.currentUserType !== 'Employee'
                                      ? (row.status === 'PENDING' || row.status === 'PENDING(R)') && passDataToClaim(row, row.refNumber) : passDataToClaim(row, row.refNumber));
                                  }
                                  }
                                  className="table-cell"
                                  style={{ border: 'none' }}
                                  align="left"
                                  key={i}
                                >
                                  {i === Object.getOwnPropertyNames(row).indexOf('submissionData')
                                    ? moment(each).format('DD-MM-YYYY')
                                    : i == '2'
                                      ? each.replaceAll('-', ' ').toLocaleUpperCase()
                                      : i === Object.getOwnPropertyNames(row).indexOf('status')
                                        ? each === 'APPROVED'
                                          ? (row.paymentDate && moment(row.paymentDate, 'YYYY-MM-DD').isBefore(moment()))
                                            ? 'Payment Processed'
                                            : each
                                          : each
                                        : each}
                                </TableCell>
                              );
                            })}
                        <TableCell onClick={() => passDataToReports(row?.id)}>
                          <IconButton>
                            <img src={InfoIcon} alt="Info" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                {finalData.length === 0 && (
                  <TableRow
                    style={{
                      display: 'flex',
                      margin: '10px',
                      justifyContent: 'center',
                    }}
                  >
                    No Claims Found !
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <ClaimRejectModal
        isBackdropOpen={isBackdropOpen}
        closeBackdropHandler={closeARModalHandler}
        handleRejectRequest={handleRejectRequest}
        selectionList={selectionList}
        selected={selected}
        onclick={handleRejectRequest}
        handleChipSelected={handleChipSelected}
        remark={remark}
        handleRemarkChange={handleRemarkChange}
        multipleRejection={true}
      // currentUserData={currentUserData}
      // employeeClaimData={employeeClaimData}
      // employeeId={employeeId}
      />

    </React.Fragment>
  );
};

export default DashboardTable;
