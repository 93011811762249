import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Paper } from '@mui/material';
import {
  UnergizerFieldConfig,
  UnergizerFormConfig,
  disableSelectedMonths,
} from '../utils/unergizer-utils';
import UnergizerConfig from './unergizer-claim-form-config.component';

const UnergizerEdit = (props) => {
  const { setFormData, formData, unergizerBenefit } = props;
  const { unergizerErrors } = useSelector((state) => state.newClaims);

  const formConfig = useMemo(() => {
    const config = UnergizerFieldConfig.reduce((acc, item) => {
      let temp = item;
      switch (item.fieldRefName) {
        case UnergizerFormConfig.Month: {
          temp = {
            ...temp,
            monthDataArray: disableSelectedMonths(formData)
          };
          break;
        }
        case UnergizerFormConfig.Limit: {
          temp = {
            ...temp,
            computeExpression: `data.numberOfEmployees * ${Number(
              unergizerBenefit
            )}`,
          };
          break;
        }
        default: {
          temp = item;
          break;
        }
      }
      acc.push(temp);
      return acc;
    }, []);
    return config;
  }, [formData]);

  return (
    <>
      <Paper sx={{ flexGrow: 1, p: 4, my: 1 }}>
        {formData.map((item, index) => {
          return (
            <UnergizerConfig
              key={index}
              monthsFormData={item}
              setMonthsData={setFormData}
              index={index}
              noOfMonths={formData.length}
              formConfig={formConfig}
              errors={unergizerErrors?.length ? unergizerErrors?.[index] : {}}
            />
          );
        })}
      </Paper>
    </>
  );
};

export default UnergizerEdit;
