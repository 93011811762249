export const GET_PAYROLL_DETAILS_REQUEST = 'GET_PAYROLL_DETAILS_REQUEST';
export const GET_PAYROLL_DETAILS_SUCCESS = 'GET_PAYROLL_DETAILS_SUCCESS';
export const GET_PAYROLL_DETAILS_FAILURE = 'GET_PAYROLL_DETAILS_FAILURE';

export const RESET_PAYROLL = 'RESET_PAYROLL';

export const UPDATE_PAYROLL_INFO_REQUEST = 'UPDATE_PAYROLL_INFO_REQUEST';
export const UPDATE_PAYROLL_INFO_SUCCESS = 'UPDATE_PAYROLL_INFO_SUCCESS';
export const UPDATE_PAYROLL_INFO_FAILURE = 'UPDATE_PAYROLL_INFO_FAILURE';

export const GET_ALL_PAYROLL_REQUEST = 'GET_ALL_PAYROLL_REQUEST';
export const GET_ALL_PAYROLL_SUCCESS = 'GET_ALL_PAYROLL_SUCCESS';
export const GET_ALL_PAYROLL_FAILURE = 'GET_ALL_PAYROLL_FAILURE';

export const GET_ALL_PAYROLL_RUN_DATES_REQUEST = 'GET_ALL_PAYROLL_RUN_DATES_REQUEST';
export const GET_ALL_PAYROLL_RUN_DATES_SUCCESS = 'GET_ALL_PAYROLL_RUN_DATES_SUCCESS';
export const GET_ALL_PAYROLL_RUN_DATES_FAILURE = 'GET_ALL_PAYROLL_RUN_DATES_FAILURE';

export const SET_DATE_FILTER_PAYROLL = 'SET_DATE_FILTER_PAYROLL';