import React from 'react';
import {
  HighlightOff,
  DeleteOutline,
  AssignmentInd,
  AddTask,
} from '@mui/icons-material';
import BtnDropDown from '../../../../atoms/Buttons/BtnDropDown';
import { useSelector } from 'react-redux';
import { GoalFormFieldNames, GoalStatus } from '../../Utlis/goals.util';

const DropDownAction = (props) => {
  const {
    handleAssignTo,
    handleActivate,
    handleDeactivate,
    handleDelete,
    checkedItems,
  } = props;
  const goalsDropDownBtnOptions = [
    {
      id: 2,
      title: 'Assign to',
      icon: <AssignmentInd sx={{ width: 20, height: 20 }} />,
      handleClick: handleAssignTo,
      disabled: !!checkedItems.find(
        (item) =>item?.[GoalFormFieldNames.GoalStatus]?.id ===
          GoalStatus.Deactivated
      ),
    },
    {
      id: 3,
      title: 'Activate Appraisal',
      icon: <AddTask sx={{ width: 20, height: 20 }} />,
      handleClick: handleActivate,
      disabled: !!checkedItems.find(
        (item) =>
          item?.[GoalFormFieldNames.GoalStatus]?.id !==
          GoalStatus.GoalAssigned && item?.[GoalFormFieldNames.GoalStatus]?.id !==
          GoalStatus.Deactivated
      ),
    },
    {
      id: 4,
      title: 'Deactivate Goal',
      icon: <HighlightOff sx={{ width: 20, height: 20 }} />,
      handleClick: handleDeactivate,
      disabled: !!checkedItems.find(
        (item) =>
          item?.[GoalFormFieldNames.GoalStatus]?.id ===
            GoalStatus.Deactivated ||
          item?.[GoalFormFieldNames.GoalStatus]?.id ===
            GoalStatus.Published
      ),
    },
    {
      id: 5,
      title: 'Delete',
      icon: <DeleteOutline sx={{ width: 20, height: 20 }} />,
      handleClick: handleDelete,
      disabled: true,
    },
  ];
  return (
    <BtnDropDown
      BtnOptions={goalsDropDownBtnOptions}
      selectedOption={0}
      disabled={!checkedItems.length}
    />
  );
};

export default DropDownAction;
