import {
  Box,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CardUI from '../../../shared/components/ui-elements/CardUI';
import Footer from '../../footer/Footer';
import PersonIcon from '@mui/icons-material/Person';
import { useLocation } from 'react-router-dom';
import Modal from '../../../shared/components/ui-elements/Modal';
import ButtonUI from '../../../shared/components/ui-elements/ButtonUI';
import { useNavigate } from 'react-router-dom';
// import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  generateCurrentTimeStamp,
  generateFormData,
  validateNumber,
  checkFilesCountHandler,
} from '../common-services/functions';
import { postFilesData, putFormData } from '../common-services/services';
import UserProfile from '../../../shared/components/claim-forms/UserProfile';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ReportingDetails from '../../../shared/components/claim-forms/ReportingDetails';

const FuneralAssistanceEdit = () => {
  const params = useLocation();

  // const uniqueNum = Math.floor(Math.random() * 10000000000) + 10000000000;
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [claimsData, setClaimsData] = useState(params.state.claimsData);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    reportingOfficer: 'Kelvin Lionel',
    hod: 'Benjamin Koh',
    costCenter: 'Singapore Labour',
    claimReferenceNumber: claimsData.referenceNumber,
    claimsMonth: claimsData.claimMonth || null,
    totalPaymentAmount: claimsData.amount,
    description: claimsData.description,
    membership: claimsData.membership,
    deathOfStaffs: claimsData.deathOfStaffs,
    entitlement: claimsData.entitlement,
  });

  useEffect(() => {
    // console.log(claimsData);
  }, [claimsData]);

  const setEntitleMent = (e) => {
    const newArray = { ...inputs };
    newArray.membership = e.target.value;
    if (e.target.value === 'SUN Member') {
      newArray.entitlement = 500;
      newArray.totalPaymentAmount = 500;
    } else {
      newArray.entitlement = 400;
      newArray.totalPaymentAmount = 400;
    }
    setInputs(newArray);
  };

  const deathOfStaffsOptions = [
    'Spouse',
    'Children',
    'Parents',
    'Parents-in-law',
  ];
  // console.log(params);
  const [type] = useState(params.state.name);
  let userData = JSON.parse(localStorage.getItem('userData'));
  let asProfile = localStorage.getItem('asProfile');

  const membershipOptions = ['SUN Member', 'Non-SUN Member'];

  const handleChangeInput = (e, key = null, key1) => {
    const values = { ...inputs };
    if (key) values[key] = e;
    else {
      if (key1) {
        const validate = validateNumber(e.target.name, e.target.value);
        if (!validate) {
          return false;
        }
      }
      values[e.target.name] = e.target.value;
    }
    // else values[i][e.target.name] = e.target.value;
    setInputs(values);
  };

  const cardData = [
    {
      post: 'Reporting Officer',
      name:
        userData.userDetails.reportingTo.firstName +
        ' ' +
        userData.userDetails.reportingTo.lastName,
    },
    {
      post: 'HOD',
      name: userData.userDetails.HOD,
    },
    {
      post: 'Cost Center',
      name: userData.userDetails.costCenter,
    },
  ];

  const validateFields = () => {
    if (
      inputs.totalPaymentAmount == undefined ||
      inputs.totalPaymentAmount == null ||
      inputs.totalPaymentAmount == ''
    ) {
      window.alert('Please Enter Claim Amount !');
      return;
    }
    if (
      inputs.claimsMonth == undefined ||
      inputs.claimsMonth == null ||
      inputs.claimsMonth == ''
    ) {
      window.alert('Please Enter Claim Month !');
      return;
    }
    if (
      inputs.description == undefined ||
      inputs.description == null ||
      inputs.description == ''
    ) {
      window.alert('Please Enter Description!');
      return;
    }
    if (
      inputs.membership == undefined ||
      inputs.membership == null ||
      inputs.membership == ''
    ) {
      window.alert('Please Enter Membership Details!');
      return;
    }
    if (
      inputs.deathOfStaffs == undefined ||
      inputs.deathOfStaffs == null ||
      inputs.deathOfStaffs == ''
    ) {
      window.alert('Please Enter Death of Staffs!');
      return;
    }
    return true;
  };

  const generateDataForClaimRequest = (
    inputs,
    userData,
    dateTime,
    callType
  ) => {
    let finalFormData = [];
    let updateFormObject = {};
    let callForPost = callType;

    // console.log('updateFormObject', updateFormObject, finalFormData);
    if (inputs) {
      return (updateFormObject = {
        typeId: 23,
        id: claimsData.id,
        employee: userData && userData.userDetails.id,
        claimReferenceNumber: inputs.claimReferenceNumber.toString(),
        claimSubType: inputs.claimType,
        amount: Number(inputs.totalPaymentAmount),
        benefitType: inputs.benefitType,
        // receiptDate: inputs.receiptDate,
        // receiptNumber: inputs.receiptNumber,
        filePaths: [...claimsData.attachment],
        status: callForPost,
        claimMonth: inputs.claimsMonth,
        description: inputs.description,
        deathOfStaffs: inputs.deathOfStaffs,
        nameOfClaimant: inputs.nameOfClaimant,
        personnelNo: inputs.personnelNo,
        membership: inputs.membership,
        entitlement: inputs.entitlement.toString(),
        designation: inputs.designation,
        department: inputs.department,
      });
    }
  };

  // On file select (from the pop up)
  const onFileChange = async (e, refNumber, typeId = 23) => {
    if (!checkFilesCountHandler(e, claimsData)) {
      return;
    }

    // Generates form data for file upload.
    let formData = await generateFormData(e, refNumber, typeId);

    // console.log(formData);
    // Send form data for file upload.
    let updatedPaths = await postFilesData(formData);

    // Sets updated paths received from POST api call.
    const newArr = { ...claimsData };
    // console.log(updatedPaths);
    for (let paths of updatedPaths) {
      newArr.attachment.push(paths);
    }
    setClaimsData(newArr);
  };
  const submitData = async (callType) => {
    const dateTime = generateCurrentTimeStamp();
    setFormSubmitted(true);
    setButtonClicked(callType);
    let validate = validateFields();

    if (!validate) {
      setFormSubmitted(false);
      return;
    }

    let finalFormData = generateDataForClaimRequest(
      inputs,
      userData,
      dateTime,
      callType
    );

    // POST call for submit form data.
    let businessTravelsResponse = await putFormData(finalFormData);

    if (businessTravelsResponse === 201) {
      let successMessage = '';
      if (callType === 'PENDING') {
        successMessage = 'Claim request submitted successfully !';
      } else {
        successMessage = 'Draft saved successfully !';
      }
      setFormSubmitted(false);
      openSuccessPopUp(successMessage);
    } else {
      setFormSubmitted(false);
      window.alert('Something went wrong');
    }
  };

  // remove attachment from claims data start
  const removeAttachement = (i) => {
    const newArr = { ...claimsData };
    newArr.attachment.splice(i, 1);
    // console.log(newArr.attachment);
    setClaimsData(newArr);
  };
  // remove attachment from claims data end

  /** Close the modal */
  const closeBackdropHandler = () => {
    setIsBackdropOpen(false);
    navigate('/dashboard', {
      state: {
        claimType: '23',
      },
    });
  };

  // Open Modal When api response succesfully for(Draft/Submit)
  const openSuccessPopUp = (successMessage) => {
    setSuccessMessage(successMessage);
    setIsBackdropOpen(true);
  };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }} className='mar-top'>
        <Container>
          <Grid container>
              <ReportingDetails cardData={cardData} />
              <UserProfile
                name={userData && userData.userDetails.firstName}
                id={userData && userData.userDetails.id}
                designation={userData.userDetails?.jobTitle?.name}
                department={userData.userDetails?.department?.name}
              />
            <Grid xs={11}>
              <Box>
                <Box
                  className='form-main'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '1em',
                    marginBottom: '4em',
                  }}
                >
                  <Grid xs={11}>
                    <CardUI className='form-heading-wrap'>
                      <Grid
                        xs={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Grid xs={6}>
                          <h4>Funeral Assistance</h4>
                        </Grid>
                        <Grid
                          xs={6}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <h4>
                            Claim Reference Number :
                            {inputs.claimReferenceNumber}
                          </h4>
                        </Grid>
                      </Grid>
                    </CardUI>
                    <CardUI className='form-content-wrap'>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        {/* <Grid item xs={12} lg={4} md={4} sm={4}>
                                  <label htmlFor=''>Claim Reference Number</label>
                                  <TextField
                                    type='number'
                                    required
                                    variant='outlined'
                                    color='primary'
                                    size='small'
                                    value={inputs.claimReferenceNumber}
                                    name='claimReferenceNumber'
                                    fullWidth
                                    disabled
                                    onChange={(e) => handleChangeInput(i, e)}
                                  />
                                </Grid> */}
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Claim Month *</label>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              variant='inline'
                              inputFormat='MM/yyyy'
                              maxDate={new Date()}
                              minDate={
                                new Date(
                                  userData && userData.userDetails.dateOfJoining
                                )
                              }
                              views={['year', 'month']}
                              margin='normal'
                              id='date-picker-inline'
                              value={inputs.claimsMonth}
                              onChange={(e) =>
                                handleChangeInput(e, 'claimsMonth')
                              }
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              renderInput={(params) => (
                                <TextField
                                  size='small'
                                  fullWidth
                                  variant='outlined'
                                  {...params}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Description*</label>
                          <TextField
                            required
                            variant='outlined'
                            color='primary'
                            size='small'
                            value={inputs.description}
                            name='description'
                            fullWidth
                            onChange={(e) => handleChangeInput(e)}
                          />
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Death Of Staff*</label>
                          <FormControl
                            sx={{ minWidth: 120 }}
                            fullWidth
                            size='small'
                          >
                            <Select
                              value={inputs.deathOfStaffs}
                              onChange={(e) => handleChangeInput(e)}
                              name='deathOfStaffs'
                              displayEmpty
                              inputProps={{
                                'aria-label': 'Without label',
                              }}
                              variant='outlined'
                              autoWidth
                            >
                              {deathOfStaffsOptions &&
                                deathOfStaffsOptions.map((each, i) => (
                                  <MenuItem key={i} value={each}>
                                    {each}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Membership*</label>
                          <FormControl
                            sx={{ minWidth: 120 }}
                            fullWidth
                            size='small'
                          >
                            <Select
                              value={inputs.membership}
                              onChange={(e) => {
                                handleChangeInput(e);
                                setEntitleMent(e);
                              }}
                              name='membership'
                              displayEmpty
                              inputProps={{
                                'aria-label': 'Without label',
                              }}
                              variant='outlined'
                              autoWidth
                            >
                              {membershipOptions &&
                                membershipOptions.map((each) => (
                                  <MenuItem key={each} value={each}>
                                    {each}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Entitlement*</label>
                          <TextField
                            required
                            disabled
                            variant='outlined'
                            color='primary'
                            size='small'
                            value={`$${Number(inputs.entitlement).toFixed(2)}`}
                            name='entitlement'
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Total Payment Amount*</label>
                          <TextField
                            required
                            type='number'
                            variant='outlined'
                            color='primary'
                            size='small'
                            disabled
                            value={Number(inputs.totalPaymentAmount).toFixed(2)}
                            name='totalPaymentAmount'
                            fullWidth
                            onChange={(e) =>
                              handleChangeInput(e, null, 'number')
                            }
                          />
                        </Grid>

                        {/* {inputs.claimType == 'Funeral-Assistance' && (
                          <Grid item xs={12} lg={4} md={4} sm={4}>
                            <label htmlFor=''>Benefit Type *</label>
                            <FormControl
                              sx={{ minWidth: 120 }}
                              fullWidth
                              size='small'
                            >
                              <Select
                                value={inputs.benefitType}
                                onChange={(e) => handleChangeInput(e)}
                                displayEmpty
                                name='benefitType'
                                inputProps={{
                                  'aria-label': 'Without label',
                                }}
                                variant='outlined'
                                autoWidth
                              >
                                {benefitTypes.map((each, i) => (
                                  <MenuItem key={i} value={each}>
                                    {each}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )} */}

                        {/* <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Description</label>
                          <TextField
                            required
                            variant='outlined'
                            color='primary'
                            size='small'
                            value={inputs.description}
                            name='description'
                            fullWidth
                            onChange={(e) => handleChangeInput(, e)}
                          />
                        </Grid> */}
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Attachment</label>
                          <input
                            multiple
                            accept='image/pdf*'
                            type='file'
                            onChange={(e) =>
                              onFileChange(e, inputs.claimReferenceNumber)
                            }
                          />
                          {claimsData.attachment &&
                            claimsData.attachment.map((each, i) => {
                              return (
                                <div key={i} className='file-path-wrap'>
                                  {/* <h6>{each.split('/')[4]}</h6> */}
<h6>{inputs.claimReferenceNumber}_{i+1}</h6>
 <IconButton
  onClick={() => removeAttachement(i)}
                                    disableTouchRipple
                                  >
                                    <CancelRoundedIcon />
                                  </IconButton>
                                </div>
                              );
                            })}
                        </Grid>
                      </Grid>
                    </CardUI>
                    {/* dsdsdsd */}
                  </Grid>
                </Box>

                <Grid style={{ position: 'relative' }}>
                  <Footer
                    fromEdit={true}
                    type={type}
                    onclick={() => submitData('PENDING')}
                    onSaveDraft={() => submitData('DRAFT')}
                    disabledSubmit={formSubmitted}
                    buttonClicked = {buttonClicked}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Modal
        show={isBackdropOpen}
        onCancel={closeBackdropHandler}
        header={<h1>Success</h1>}
        contentClass='modal-content'
        footerClass='footer-content'
        submitButton={
          <ButtonUI
            backgroundColor='#00AC94'
            color='#ffffff'
            size='small'
            padding='3px 20px'
            onclick={closeBackdropHandler}
          >
            Ok
          </ButtonUI>
        }
      >
        <h4>{successMessage}</h4>
      </Modal>
    </React.Fragment>
  );
};
export default FuneralAssistanceEdit;
