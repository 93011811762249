import React from 'react';

import Modal from '../ui-elements/Modal';
import ButtonUI from '../ui-elements/ButtonUI';

const SuccessModal = ({isSuccess,closeBackdropHandler, closeSuccessHandler,successMessage}) => {
    return (
        <Modal
        show={isSuccess}
        onCancel={closeBackdropHandler}
        header={<h1>Success</h1>}
        contentClass="modal-content"
        footerClass="footer-content"
        submitButton={
          <ButtonUI
            backgroundColor="#00AC94"
            color="#ffffff"
            size="small"
            padding="3px 20px"
            onclick={closeSuccessHandler}
          >
            Ok
          </ButtonUI>
        }
      >
        <h4>{successMessage}</h4>
      </Modal>
    );
};

export default SuccessModal;