import {
  Box,
  Container,
  Grid,
  Paper,
  Button,
  FormControl,
} from '@mui/material';
import React, { useState } from 'react';
import ClaimedEmployeeDetails from '../claim-view/claimed-employee-details';
import Modal from '../../shared/components/ui-elements/Modal';
import ButtonUI from '../../shared/components/ui-elements/ButtonUI';
import { useNavigate, useParams } from 'react-router-dom';
import CardUI from '../../shared/components/ui-elements/CardUI';
import InputField from '../../atoms/Input/Input';
import * as Joi from 'joi';
import Loading from '../../atoms/Loading/loading';
import FilesUploader from '../../atoms/FileUpload/FilesUploader';
import { SeparationServices } from '../../services/separation';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

export const FormContainer = (props) => (
  <Grid item xs={12} lg={4} md={4} sm={4}>
    <FormControl fullWidth>{props.children}</FormControl>
  </Grid>
);

export const FormType = {
  RESUBMIT: 'resubmit',
};

export default function Resign() {
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const navigate = useNavigate();
  const { id: formType } = useParams();
  const selectedUser = useSelector((state) => state?.main?.userInfo?.id);
  const uniqueNum = Math.floor(Math.random() * 10000000000) + 10000000000;
  /* eslint-disable */
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [Message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [errors, setErrors] = useState({});
  const [input, setInput] = useState({
    reason: "",
    attachments: [],
  });
  const schmea = {
    reason: Joi.string().required().label("Reason"),
    attachments: Joi.array()
      .items(Joi.string())
      .label("Documents")
      .min(1)
      .required(),
  };
  const { currentUserRoleId } = useSelector((state) => state.main);

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    const { error } = schmea[name].validate(value);
    const err = { ...errors };
    if (error) {
      err[name] = error.message;
    } else {
      delete err[name];
    }
    setErrors(err);
    setInput({ ...input, [name]: value });
  };

  /** Close the modal */
  const closeBackdropHandler = () => {
    setIsBackdropOpen(false);
    if (!isError) navigate(-1);
    //   navigate('/claims', {
    //     state: {
    //       claimType: '1',
    //     },
    //   });
  };

  // Open Modal When api response succesfully for(Draft/Submit)
  const openPopUp = (successMessage) => {
    setMessage(successMessage);
    setIsBackdropOpen(true);
  };

  const submit = async () => {
    setMessage(false);
    setLoading(true);
    setIsError(false);
    const { error } = Joi.object(schmea).validate(input, { abortEarly: false });
    if (error) {
      let err = {};
      error?.details?.forEach((item) => {
        err[item.context.key] =
          item.type === "array.min"
            ? `Please upload valid ${item.context.label}`
            : item.message;
      });
      setErrors(err);
      setLoading(false);
      return;
    }
    try {
      const separationServices = new SeparationServices();
      const res =
        formType == FormType.RESUBMIT
          ? await separationServices.updateResign({
              resignId: details.id,
              roleId: currentUserRoleId,
              ...input,
            })
          : await separationServices.postResign({...input, roleId: currentUserRoleId });
      console.log(res);
      openPopUp(res?.message || "Request Submitted!");
    } catch (error) {
      setIsError(true);
      openPopUp(error?.response?.data?.message || error.message);
      console.log(error);
    }

    setLoading(false);
  };
  const getDetails = useCallback(async () => {
    setLoading(true);
    const separationServices = new SeparationServices();
    try {
      const response = await separationServices.getUserResignDetails({
        userId: selectedUser,
      });
      console.log(response);
      setInput({
        reason: response?.details?.reason ?? "",
        attachments: response?.details?.attachments ?? [],
      });
      setDetails(response?.details ?? {});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    console.log("id", formType);
    if (formType == FormType.RESUBMIT) {
      getDetails();
    }
  }, [formType]);

  return (
    <Loading loading={loading}>
      <Box sx={{ flexGrow: 1 }}>
        <Container>
          <Grid container>
            <ClaimedEmployeeDetails />

            <Grid xs={12}>
              <Box>
                <CardUI className="form-heading-wrap">
                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Grid xs={6}>
                      <h4>Separation</h4>
                    </Grid>
                  </Grid>
                </CardUI>
              </Box>
              <Paper>
                <Box p={3}>
                  <Grid container spacing={1}>
                    <FormContainer>
                      <InputField
                        name={"reason"}
                        labelText={"Reason"}
                        onChange={handleChange}
                        required={true}
                        value={input.reason}
                        error={errors["reason"] ? true : false}
                        helperText={errors["reason"]}
                        size="small"
                      />
                    </FormContainer>
                    <FormContainer>
                      <FilesUploader
                        name={"attachments"}
                        label={"Attachments"}
                        required={true}
                        value={input.attachments}
                        callback={(values) =>
                          handleChange({
                            target: { name: "attachments", value: values },
                          })
                        }
                        error={errors["attachments"] ? true : false}
                        helperText={errors["attachments"]}
                      />
                    </FormContainer>
                  </Grid>
                </Box>
              </Paper>
            </Grid>

            <Paper sx={{ p: 2, mt: 2 }} style={{ width: "100%" }}>
              <Box display="flex" gap="10px" justifyContent="flex-end">
                {
                  <>
                    <Button
                      size="medium"
                      sx={{
                        bgcolor: "neutral.light80",
                        color: "neutral.dark80",
                        px: 2,
                        textTransform: "capitalize",
                      }}
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </Button>
                    <Button variant="contained" size="medium" onClick={submit}>
                      Submit
                    </Button>
                  </>
                }
              </Box>
            </Paper>
          </Grid>
        </Container>
      </Box>

      <Modal
        show={isBackdropOpen}
        onCancel={closeBackdropHandler}
        header={<h1>{!isError ? "Success" : "Error"}</h1>}
        contentClass="modal-content"
        footerClass="footer-content"
        submitButton={
          <ButtonUI
            backgroundColor="#00AC94"
            color="#ffffff"
            size="small"
            padding="3px 20px"
            onclick={closeBackdropHandler}
          >
            Ok
          </ButtonUI>
        }
      >
        <h4>{Message}</h4>
      </Modal>
    </Loading>
  );
}
