import React from 'react';
import { useSelector } from 'react-redux';
import * as moment from 'moment-timezone';
import { Box } from '@mui/material';

const ComputerLoanView = ({loanAmount, months, auditTrail}) => {
  const { logoImage } = useSelector((state) => state && state.onBoarding);
  const defaultTimezone = logoImage?.defaultTimezone;
  const currencySymbol = JSON.parse(localStorage.getItem('orgInfo'))?.currencySymbol;
  const approvedTime = auditTrail[auditTrail.length -1 ].updatedUtc;
  const divideLoan = () => {
    if (!loanAmount || !months) {
      return [];
    }
    const approvalDateMoment = moment(approvedTime).tz(defaultTimezone);
    // Adjust start month based on the current date
    if (approvalDateMoment.date() > 5) {
      approvalDateMoment.add(1, 'months');
    }
    let startMonth = approvalDateMoment.month();
    let startYear = approvalDateMoment.year();
    const basePayment = Number(loanAmount) / Number(months);
    const paymentsAndDates = [];
    let totalRounded = 0;
    for (let i = 0; i < months; i++) {
      let payment = parseFloat(basePayment.toFixed(2)); // Round to two decimal places
      totalRounded += payment;
      if (startMonth === 11) {
        startMonth = 0;
        startYear++;
      } else {
        if (i !== 0) {
          startMonth++;
        }
      }
      const installmentDate = moment.tz(
        { year: startYear, month: startMonth, day: 1 },
        defaultTimezone
      );
      const formattedDate = installmentDate.format('MMMM YYYY');
      if (i === months - 1) {
        const remainingAmount = parseFloat(
          (loanAmount - totalRounded).toFixed(2)
        );
        payment += remainingAmount;
      }
      paymentsAndDates.push({
        payment: payment.toFixed(2), // Ensure each payment is a string with two decimal places
        installmentMonth: formattedDate,
      });
    }
    return paymentsAndDates;
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <table
        style={{
          width: '50%',
          border: '1px solid black',
          textAlign: 'center',
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                width: '50%',
                border: '1px solid black',
                textAlign: 'center',
              }}
            >
              Month
            </th>
            <th
              style={{
                width: '50%',
                border: '1px solid black',
                textAlign: 'center',
              }}
            >
              Installment Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {divideLoan().map((item, index) => {
            return (
              <tr key={index}>
                <td
                  style={{
                    width: '50%',
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {item.installmentMonth}
                </td>
                <td
                  style={{
                    width: '50%',
                    border: '1px solid black',
                    textAlign: 'center',
                  }}
                >
                  {currencySymbol} {item.payment}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};

export default ComputerLoanView;
