import * as React from 'react';
import { Box, Typography, styled } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { getOnboardingNavigationList } from '../Organization/utils/onboarding.util';
import RHSMenuListItem from './RHSMenuListItem';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { onboarding, userProfile } from '../Organization/constants/onboarding.constants';
import { permissionsObj } from '../Organization/RolesAndPermissions/constants/roles-permissions.constants';

export default function RHSSlideOut() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { permissions  } = useSelector((state) => state.onBoarding);
  const { appPermissions } = useSelector((state) => state.main);
  const { userProfilePermissions } = useSelector((state) => state.modulePermissions);
  const onboardingNavigationList = getOnboardingNavigationList(permissions, appPermissions, userProfilePermissions);
  const navigate = useNavigate();
	const location = useLocation();
	const currentPath = location.pathname.split('/');

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const dropdownOptions = onboardingNavigationList.map((item) => {
    const { name, label, path } = item;
    if (!appPermissions?.[userProfile]?.[permissionsObj.allowView]) {
      if (name !== 'user-onboarding') {
        return { ...item, show: true };
      } else {
        return { ...item, show: false };
      }
    }
    if (!appPermissions?.[onboarding]?.[permissionsObj.allowView]) {
      if (name !== onboarding) {
        return { ...item, show: true };
      } else {
        return { ...item, show: false };
      }
    }
    else {
      return {...item, show:true};
    }
  }).filter((item) => item?.show);

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role='presentation'
    >
      <List
        sx={{ width: '100%', maxWidth: 360, mt: 6, bgcolor: 'transparent' }}
        component='nav'
        aria-labelledby='nested-list-subheader'
        subheader={
          <Typography variant='body1Light' pl={5}>
            Onboarding
          </Typography>
        }
      >{dropdownOptions?.map((item) => {
        const {name, label, subList} = item;
          return (
            <RHSMenuListItem
              key={name}
              title={label}
              content={subList}
              parentPath={name}
              onClickListItem={() => setState({ ...state, ['right']: false })}
              />
          );
      })}
      </List>
    </Box>
  );
  const IconState = () => {
    return (
      <Box
        position={'fixed'}
        top={75}
        right={'0px'}
        bottom={0}
        bgcolor={'neutral.dark80'}
        px={1}
        py={5}
        width={100}
        textAlign={'center'}
        zIndex={100}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          mb={3}
          onClick={toggleDrawer('right',true)}
          sx={{cursor:'pointer'}}
        >
          <HomeOutlinedIcon sx={{ color: 'neutral.light100' }} />
          <Typography variant='captionBold' color={'neutral.light100'}>
            Onboarding
          </Typography>
        </Box>
        {appPermissions?.[userProfile]?.[permissionsObj.allowView] &&
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          mb={3}
          onClick={toggleDrawer('right',true)}
          sx={{cursor:'pointer'}}
        >
          <PeopleOutlinedIcon sx={{ color: currentPath[1]==='user-onboarding'?'accentTertiary.accent60':'neutral.light100' }} />
          <Typography variant='captionBold' color={currentPath[1]==='user-onboarding'?'accentTertiary.accent60':'neutral.light100'}>
            Users and Profiles
          </Typography>
        </Box>}
        {appPermissions?.[onboarding]?.[permissionsObj.allowView] &&
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          mb={3}
          onClick={toggleDrawer('right',true)}
          sx={{cursor:'pointer'}}
        >
          <LanOutlinedIcon sx={{ color: currentPath[1]==='onboarding'?'accentTertiary.accent60':'neutral.light100' }} />
          <Typography variant='captionBold' color={currentPath[1]==='onboarding'?'accentTertiary.accent60':'neutral.light100'}>
            Organization
          </Typography>
        </Box>}
        {/* <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          mb={3}
          onClick={toggleDrawer('right',true)}
          sx={{cursor:'pointer'}}
        >
          <HelpOutlineOutlinedIcon sx={{ color: 'neutral.light100' }} />
          <Typography variant='captionBold' color={'neutral.light100'}>
            Help
          </Typography>
        </Box> */}
      </Box>
    );
  };

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Box
            sx={{
              position: 'fixed',
              right: state[anchor] ? '230px' : '100px',
              top: '180px',
              backgroundColor: 'neutral.light80',
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 40,
              width: 40,
              minWidth: 0,
              zIndex: (theme) => theme.zIndex.modal - 1,
              cursor: 'pointer',
            }}
          >
            {state[anchor] ? (
              <NavigateNextIcon
                onClick={toggleDrawer(anchor, false)}
                sx={{ color: 'neutral.dark80', height: 36, width: 36 }}
              />
            ) : (
              <ArrowBackIosNewIcon
                onClick={toggleDrawer(anchor, true)}
                sx={{ color: 'neutral.dark80', height: 24, width: 24,  position:'relative', zIndex: 1000  }}
              />
            )}
          </Box>
          {!state[anchor] && <IconState/>}
          <StyledDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </StyledDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${(props) => props.theme.palette.neutral.dark80};
    color: ${(props) => props.theme.palette.neutral.light} !important;
  }
  .Mui-selected {
    background-color: ${(props) =>
      props.theme.palette.accentTertiary.accent40}!important;
    color: ${(props) => props.theme.palette.accentTertiary.main}!important;

    &:hover {
      background-color: ${(props) =>
        props.theme.palette.accentTertiary.accent40};
      color: ${(props) => props.theme.palette.accentTertiary.main};
    }
  }
`;
