import { Box, Button, Paper, Typography, styled } from '@mui/material';
import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { ReplayOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function TryAgain(props) {
  const { handleCloseClick } = props;

  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/home');
  };


  return (
    <Paper>
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        height='100vh'
        gap={2}
      >
        <ErrorIcon sx={{ height: 100, width: 100, color: 'neutral.dark40' }} />
        <Typography variant='h5' color='neutral.dark60' mt={2}>
          Service failed
        </Typography>
        <Typography variant='body2' color='neutral.dark60' mb={1} sx={{ maxWidth: 500, textAlign: 'center' }}>
          Please try again or contact the administrator.
        </Typography>
        <StyledButton variant="contained" onClick={handleCloseClick || handleClose} >Close</StyledButton>
      </Box>
    </Paper>
  );
}


const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutral.dark80,
  backgroundColor: theme.palette.neutral.light60,
  textTransform: 'capitalize',
  minWidth: 140,
  '&:hover': {
    backgroundColor: theme.palette.neutral.light80,
  },
}));