import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ListView from '../../../../atoms/DataList/ListView';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGoalsList } from '../../../../redux/goals/action';
import moment from 'moment';
import { goalsHeadCells } from '../../Utlis/Goals.helper';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import { APIRequestStatus, LoadingStatus } from '../../../../constants/common';
import DateRange from '../../../molecules/DateRange/DateRange';
import ModalMap from '../../Goals/common/ModalMap';
import { permissionsObj } from '../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import { AccessAllGoals, PerformanceChild, PerformanceDashBoard } from '../../Goals/common/constants';
import TryAgain from '../../../molecules/ErrorState/error.component';

export default function AllGoals() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { goalsList, allowCreate, requestProcessing: { [APIRequestStatus.GET_ALL_GOALS_LIST]: goalsListLoading }
  } = useSelector((state) => state.goals);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const [checkedItems, setCheckedItems] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { performancePermission } = useSelector((state) => state.modulePermissions);
  const [searchParam] = useSearchParams();
  const searchInput = searchParam.get('q');
  const moduleId = performancePermission?.[PerformanceChild]?.id;
  useEffect(() => {
    if (currentUserRoleId) {
      dispatch(getAllGoalsList(currentUserRoleId, searchInput,moduleId));
    }
  }, [currentUserRoleId,moduleId]);

  useEffect(() => {
    setCheckedItems([]);
  }, [goalsList]);

  const rowData = goalsList?.filter((item) => {
    const { goalCreatedOn } = item;
    return (startDate && endDate) ? (moment(goalCreatedOn, 'YYYY-MM-DD').format() >= moment(startDate).format() &&
      moment(goalCreatedOn, 'YYYY-MM-DD').format() <= moment(endDate).format()) : item;
  }).map((items) => {
    const { goalName, id, goalStartDate, goalEndDate, fkGoalPriority, assignedTo, createdBy, fkGoalStatus, goalCreatedOn } = items;
    return ({
      title: goalName || '',
      goalStartDate: moment(goalStartDate, 'YYYY-MM-DD').format('YYYY/MM/DD') || '',
      goalEndDate: moment(goalEndDate, 'YYYY-MM-DD').format('YYYY/MM/DD') || '',
      assignTo: assignedTo?.map((item) => item?.user?.firstName).join(', '),
      assignBy: createdBy?.firstName,
      priority: fkGoalPriority?.name || '',
      createdOn: moment(goalCreatedOn, 'YYYY-MM-DD').format('YYYY/MM/DD'),
      status: fkGoalStatus?.name || '',
      action: <>
        <InfoOutlinedIcon
          sx={{ color: 'primary.main80' }}
          onClick={() => navigate({ pathname: `${id}` })} />
      </>,
      id: {
        hide: true,
        value: {id,fkGoalStatus}
      },
    });
  });

  if (goalsListLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={goalsListLoading === LoadingStatus.Loading} />
      <Box display='flex' justifyContent='flex-end' gap={2} mb={2} pr={2}>
        <DateRange startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
        {performancePermission?.[PerformanceChild]?.allowAdd ?
          <Box>
            <ModalMap checkedItems={checkedItems} searchInput={searchInput} moduleId={moduleId}/>
          </Box> : null}
      </Box>
      <ListView
        headCells={goalsHeadCells}
        rowsData={rowData}
        showAddIcon={performancePermission?.[PerformanceChild]?.allowAdd || false}
        onAddClick={() => navigate('add-goals')}
        showCheckbox
        showSerialNo={true}
        setCheckedItems={setCheckedItems}
        checkedItems={checkedItems}
      />
    </>
  );
}

