
import { getFormBody } from '../../components/OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { JobFormFieldNames } from '../../components/Recruiting/Constant/constant';
import { JobServices } from '../../services/jobRecuriting';
import { GET_ALL_JOB_LIST_FAILURE, GET_ALL_JOB_LIST_REQUEST, GET_ALL_JOB_LIST_SUCCESS, GET_JOB_CONFIG_FAILURE, GET_JOB_CONFIG_REQUEST, GET_JOB_CONFIG_SUCCESS, POST_DRAFT_FAILURE, POST_DRAFT_REQUEST, POST_DRAFT_SUCCESS, POST_JOB_INFO_FAILURE, POST_JOB_INFO_REQUEST, POST_JOB_INFO_SUCCESS, RESET_JOB } from './actionTypes';


const getJobFormConfigRequest = () => (
  {
    type: GET_JOB_CONFIG_REQUEST
  }
);

const getJobFormConfigSuccess = (payload) => (
  {
    type: GET_JOB_CONFIG_SUCCESS,
    payload
  }
);

const getJobFormConfigFailure = () => (
  {
    type: GET_JOB_CONFIG_FAILURE
  }
);

const postJobInfoRequest = () => (
  {
    type: POST_JOB_INFO_REQUEST
  }
);

const postJobInfoSuccess = (payload) => (
  {
    type: POST_JOB_INFO_SUCCESS,
    payload
  }
);

const postJobInfoFailure = () => (
  {
    type: POST_JOB_INFO_FAILURE
  }
);

const getAllJobListRequest = () => (
  {
    type: GET_ALL_JOB_LIST_REQUEST
  }
);

const getAllJobListSuccess = (payload) => (
  {
    type: GET_ALL_JOB_LIST_SUCCESS,
    payload
  }
);

const getAllJobListFailure = () => (
  {
    type: GET_ALL_JOB_LIST_FAILURE
  }
);

export const resetJob = () => (
  {
    type: RESET_JOB
  }
);


const postDraftRequest = () => (
  {
    type: POST_DRAFT_REQUEST
  }
);

const postDraftSuccess = (payload) => (
  {
    type: POST_DRAFT_SUCCESS,
    payload
  }
);

const postDraftFailure = () => (
  {
    type: POST_DRAFT_FAILURE
  }
);

export const getJobConfig = (currentRoleId, jobId, appModuleId, moduleId, fromLib, newReq) => async (dispatch) => {
  dispatch(getJobFormConfigRequest());
  new JobServices().getJobDetails(currentRoleId,jobId,appModuleId, moduleId,fromLib).then((res) => {
    dispatch(getJobFormConfigSuccess({ formData: res, fromLib, newReq }));
  }).catch(() => {
    dispatch(getJobFormConfigFailure());
  });
};

export const postJobInfo = (jobFormInfo, currentUserRoleId) => async (dispatch) => {
  dispatch(postJobInfoRequest());
  const jobFormData = getFormBody(jobFormInfo);
  const { statusCode, ...rest } = jobFormData;
  Object.assign(rest, {[JobFormFieldNames.RoleId]: currentUserRoleId});
  return new JobServices().postJobInfo(rest).then((res) => {
    dispatch(postJobInfoSuccess({ jobFormInfo, id: res.id }));
    console.log(res);
    return res;
  }).catch((err) => {
    dispatch(postJobInfoFailure());
    throw err?.response?.data?.message || 'Error Saving';
  });
};

export const getAllJobList = (roleId, searchInput, startDate, endDate, title, appModuleId, moduleId) => async (dispatch) => {
  dispatch(getAllJobListRequest());
  new JobServices().getAllJobList(roleId, searchInput, startDate, endDate, title, appModuleId, moduleId).then((res) => {
    dispatch(getAllJobListSuccess(res));
  }).catch(() => {
    dispatch(getAllJobListFailure());
  });
};

export const postJobInfoDraft = (jobFormInfo, currentUserRoleId) => async (dispatch) => {
  dispatch(postDraftRequest());
  const jobFormData = getFormBody(jobFormInfo);
  const { statusCode, ...rest } = jobFormData;
  Object.assign(rest, {[JobFormFieldNames.RoleId]: currentUserRoleId});
  return new JobServices().postJobInfoDraft(rest).then((res) => {
    dispatch(postDraftSuccess({ jobFormInfo, id: res.id }));
    return res;
  }).catch((err) => {
    dispatch(postDraftFailure());
    throw err?.response?.data?.message || 'Error Saving';
  });
};