import React, { useState, useEffect } from 'react';
import { Box, Typography, Link, Accordion } from '@mui/material';
import Grid from '@mui/material/Grid';
import { updateDesignation } from '../../../redux/onboarding/action';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../../atoms/Input/Input';
import {
  getHelperTextOnInputInvalid,
  isNotEmptyString,
} from './utils/onboarding.util';
import { showToast } from '../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from './constants/onboarding.constants';
import { ToastThemes, toastMessage } from '../../../constants/common';
import ActionHeader from './ActionHeader/ActionHeader';
import SelectTag from '../../../atoms/Select/SelectTag';
import ApiSelectTag from '../../../atoms/Select/ApiSelectTag';
import { OnboardingServices } from '../../../services/onboarding';
import { getFormBody } from './utils/userOnboarding/userOnboarding.util';

export default function DesignationConfigure(props) {
  const {
    id,
    shortName,
    longName,
    gradeLevel,
    index,
    viewDesignation,
    setShortName,
  } = props;
  const [allowAdd, setAllowAdd] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [inputs, setInputs] = useState({
    shortName: shortName,
    longName: longName,
    fkGrade: gradeLevel,
  });
  const [option, setOption] = useState([]);

  const { designations } = useSelector((state) => state.onBoarding);

  const handleChangeInput = (e) => {
    const values = e.target.name;
    const name = e.target.value;
    if (
      !designations.find((item) => item.shortName === name) &&
      isNotEmptyString(name)
    ) {
      setAllowAdd(true);
    } else {
      setAllowAdd(false);
    }
    setInputs({
      ...inputs,
      [values]: e.target.value,
    });
    setIsEdit(true);
  };

  const dispatch = useDispatch();

  const handleDraftSubmit = () => {
    dispatch(updateDesignation(inputs, id, index)).then((success) => {
      if (success) {
        viewDesignation();
        setShortName('');
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.success),
          ToastThemes.success
        );
      } else {
        showToast(
          ToastMessages.failed.replace(stringSubstitute, toastMessage.error),
          ToastThemes.error
        );
      }
    });
  };

  const designationConfigureForm = [
    {
      name: 'shortName',
      label: 'Short Name',
      placeholder: 'Add Short Name',
      value: inputs.shortName,
      maxWidth: 200,
    },
    {
      name: 'longName',
      label: 'Long Name',
      placeholder: 'Add Long Name',
      value: inputs.longName,
      maxWidth: 350,
    },
  ];

  const getDropdownOptions = () => {
    return option;
  };

  const handleDropdownClick = async () => {
    return new OnboardingServices().getGradeList().then((res) => {
      setOption(res);
      return true;
    });
  };

  const ConfigureFields = () =>
    designationConfigureForm.map((item) => {
      const { name, label, placeholder, value, maxWidth } = item;
      return (
        <Grid item md={6} key={name}>
          <Box mb={2} sx={{ maxWidth: maxWidth }}>
            <InputField
              placeholder={placeholder}
              labelText={label}
              size="small"
              fullWidth
              name={name}
              value={value}
              onChange={(e) => handleChangeInput(e)}
              helperText={getHelperTextOnInputInvalid(allowAdd)}
            />
          </Box>
        </Grid>
      );
    });
  return (
    <Box>
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={shortName}
          showDiscard={true}
          editButtonText="Save"
          onEditClick={() => handleDraftSubmit()}
          onClickDiscard={viewDesignation}
          disabledDiscard={isEdit}
          disabledSave={!isEdit}
        />
      </Box>
      <Grid container rowSpacing={1} columnSpacing={1} pb={1}>
        {ConfigureFields()}
        <Grid item md={6}>
          <Box mb={2} sx={{ maxWidth: 200 }}>
            <ApiSelectTag
              defaultValue={'Select Grade'}
              fullWidth
              size="small"
              onOpen={() => handleDropdownClick()}
              dropDownList={getDropdownOptions()}
              labelText="Grade Level"
              name="fkGrade"
              value={inputs.fkGrade}
              onchange={handleChangeInput}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
