import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Box, Tab } from '@mui/material';
import { StyledTabs } from './tab-bar.styled';
import SearchTab from './search-tab';
import { Link } from 'react-router-dom';
import { LightTooltip } from '../Tooltip/LightTooltip';
import theme from '../../foundation/theme';

const NavTabBar = (props) => {
  const { list, searchTab = true, searchWidth, tabWidth } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const paths = location.pathname.split('/');

  const currentPath = paths[paths.length - 1];

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'neutral.light100',
        boxShadow: '0px 2px 4px rgba(51, 51, 51, 0.15)',
      }}
    >
      <Box
        borderRadius={1}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          bgcolor: 'neutral.light80',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            overflow: 'scroll',
          }}
        >
          {searchTab && (
            <SearchTab
              list={list}
              onChange={(e, tab) => navigate(tab.path)}
              width={searchWidth}
            />
          )}
          <StyledTabs variant="scrollable">
            {list.map((tab) => {
              const { label, path } = tab;
              const paths = path.split('/');
              const exact = paths[paths.length - 1];
              return (
                <LightTooltip
                  key={path}
                  title={label}
                  placement="bottom-end"
                  arrow
                  bgColor="#212B36"
                  color={theme.palette.neutral.light60}
                  sx={{ margin: 2 }}
                  enterDelay={500}
                >
                  <Link to={path} style={{ textDecoration: 'none' }}>
                    <Tab
                      sx={{ color: '#000000' }}
                      key={path}
                      label={
                        <Box
                          sx={{
                            maxWidth: tabWidth,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {label}
                        </Box>
                      }
                      className={exact === currentPath ? 'Mui-selected' : ''}
                    />
                  </Link>
                </LightTooltip>
              );
            })}
          </StyledTabs>
        </Box>
      </Box>
    </Box>
  );
};
export default NavTabBar;
