import { Box, Grid, Typography } from '@mui/material';
import React, {
  useState,
  forwardRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import AccordionTabs from '../../../../../../atoms/Accordian/Accordian';
import { ApprovalWorkflowHeaders } from '../../../../../Performance/Goals/common/constants';
import FormEdit from '../../../../common/Form/FormEdit';
import { RHSBoxAction } from '../../../../../Performance/Goals/RHSBoxAction/RHSBoxAction';
import { useDispatch } from 'react-redux';
import { ApprovalWorkFlowLib } from '../../../constants/onboarding.constants';
import {
  addStepsTabAppWork,
  deleteStepsTabAppWork,
  duplicateStepsTabAppWork,
  updateStepsTabAppWork,
} from '../../../../../../redux/approvalFlow/action';
import ConditionalModal from './conditionalModal';
import { approvalWorkflowServices } from '../../../../../../services/approvalWokflowService';
import { approverTypes } from './AppWorkConstants';

const AppWorkStep = (props) => {
  const {
    formConfigSteps,
    index,
    stepsLength,
    getDropdownOptions,
    handleDropdownClick,
    formInfo,
    formStepsValidationErrors,
    setformStepsValidationErrors,
    formId,
  } = props;

  const [fromDataSteps, setFromDataSteps] = useState(formInfo);
  const [validationErrors, setValidationErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [configureConditionsShow, setConfigureConditionsShow] = useState(
    fromDataSteps?.[ApprovalWorkFlowLib.isApprovalConditional]
      ? fromDataSteps?.[ApprovalWorkFlowLib.isApprovalConditional]
      : false
  );
  const dispatch = useDispatch();
  useEffect(() => {
    setFromDataSteps(formInfo);
  }, [formInfo]);
  const handleAddSteps = () => {
    setformStepsValidationErrors((prev) => [...prev, {}]);
    dispatch(addStepsTabAppWork());
  };

  const handleDeleteSteps = () => {
    dispatch(deleteStepsTabAppWork({ index: index, postId: formInfo?.id }));
    setformStepsValidationErrors((prev) => {
      const newError = [...prev];
      newError.splice(index, 1);
      return newError;
    });
  };

  useEffect(() => {
    setValidationErrors(formStepsValidationErrors);
  }, [formStepsValidationErrors]);

  useEffect(() => {
    dispatch(updateStepsTabAppWork({ fromDataSteps, index }));
  }, [fromDataSteps]);

  const handleDuplicateSteps = () => {
    dispatch(duplicateStepsTabAppWork(index));
    const { id, ...rest } = fromDataSteps;
    setformStepsValidationErrors((prev) => [...prev, {}]);
  };
  const handleChangeApprovalConditional = useCallback(
    (event) => {
      const key = event.target.name;
      const val = event.target.checked;
      setConfigureConditionsShow(val);
      setFromDataSteps((prev) => ({
        ...prev,
        [key]: val,
      }));
    },
    [fromDataSteps?.[ApprovalWorkFlowLib.isApprovalConditional]]
  );

  const handleChangeApproverTye = useCallback(
    (event) => {
      const key = event.target.name;
      const val = event.target.value;
      if(val.name === approverTypes.reportingManger){
        new approvalWorkflowServices().getDropdownOptions('getManagerId')
        .then((res) => {
          setFromDataSteps((prev) => ({
            ...prev,
            [ApprovalWorkFlowLib.approverRoleID]:res[0],
            [key]: val,
          }));
          return true;
        });
      }else if (val.name === approverTypes.counterSignOfficer){
        new approvalWorkflowServices().getDropdownOptions('getCounterSignOfficer')
        .then((res) => {
          setFromDataSteps((prev) => ({
            ...prev,
            [ApprovalWorkFlowLib.approverRoleID]:res[0],
            [key]: val,
          }));
          return true;
        });
      }else{
        setFromDataSteps((prev) => ({
          ...prev,
          [key]: val,
        }));
      }
      setValidationErrors((prev) => ({
        ...prev,
        [key]: '',
      }));
    },
    [fromDataSteps?.[ApprovalWorkFlowLib.ApproverType]]
  );
  const handleChangeShowModal = useCallback(
    (event) => {
      setShowModal(true);
    },[]
  );
  const formFields = useMemo(() => {
    return formConfigSteps.reduce((acc, item) => {
      const { fieldRefName } = item;
      let temp = { ...item };
      switch (fieldRefName) {
        case ApprovalWorkFlowLib.formInputTaken: {
          temp = {
            ...temp,
            fieldSize:12,
            isHidden:
              fromDataSteps?.[ApprovalWorkFlowLib.ApproverType]?.name ==
              'Form Input Based'
                ? false
                : true,
          };
          break;
        }
        case ApprovalWorkFlowLib.approverUserId: {
          temp = {
            ...temp,
            fieldSize:12,
            isHidden:
              fromDataSteps?.[ApprovalWorkFlowLib.ApproverType]?.name ==
              'Direct Based'
                ? false
                : true,
          };
          break;
        }
        case ApprovalWorkFlowLib.approverRoleID: {
          temp = {
            ...temp,
            isReadOnly: fromDataSteps?.[ApprovalWorkFlowLib.ApproverType]?.name === 'Reporting Manger' || fromDataSteps?.[ApprovalWorkFlowLib.ApproverType]?.name === approverTypes.counterSignOfficer,
            fieldSize:fromDataSteps?.[ApprovalWorkFlowLib.ApproverType]?.name === 'Reporting Manger'? 6 : 12,
            isHidden:
              [approverTypes.roleBased,approverTypes.reportingManger,approverTypes.counterSignOfficer].includes(fromDataSteps?.[ApprovalWorkFlowLib.ApproverType]?.name) ? false:true ,
          };
          break;
        }
        case ApprovalWorkFlowLib.userBasedApprover: {
          temp = {
            ...temp,
            fieldSize:12,
            isHidden:
              fromDataSteps?.[ApprovalWorkFlowLib.ApproverType]?.name ==
              'User Based'
                ? false
                : true,
          };
          break;
        }
        case ApprovalWorkFlowLib.reportingLevel: {
          temp = {
            ...temp,
            fieldSize:6,
            isHidden:
              fromDataSteps?.[ApprovalWorkFlowLib.ApproverType]?.name ==
              approverTypes.reportingManger
                ? false
                : true,
          };
          break;
        }
        case ApprovalWorkFlowLib.fkConditionalCriteriaId: {
          temp = { ...temp, isHidden: true };
          break;
        }
        case ApprovalWorkFlowLib.ApproverType: {
          temp = { ...temp, onChange:handleChangeApproverTye};
          break;
        }
        case ApprovalWorkFlowLib.isApprovalConditional: {
          temp = { ...temp, fieldSize: fromDataSteps?.[ApprovalWorkFlowLib.isApprovalConditional]?6:12  };
          break;
        }
        case ApprovalWorkFlowLib.isEmailNotificationEnabled: {
          temp = { ...temp, fieldSize: fromDataSteps?.[ApprovalWorkFlowLib.isEmailNotificationEnabled]?6:12};
          break;
        }
        case ApprovalWorkFlowLib.emailTemplate: {
          temp = { ...temp, isHidden: fromDataSteps?.[ApprovalWorkFlowLib.isEmailNotificationEnabled]?false:true};
          break;
        }
        case ApprovalWorkFlowLib.approvalConditionsBtn: {
          temp = { ...temp, onClick: handleChangeShowModal,isHidden:fromDataSteps?.[ApprovalWorkFlowLib.isApprovalConditional]?false:true };
          break;
        }
        default: {
          break;
        }
      }
      if (!temp.isHidden) {
        acc.push(temp);
      }
      return acc;
    }, []);
  }, [formConfigSteps, fromDataSteps]);
  const conditionalCriteria = formConfigSteps.filter((item) => {
    return item.fieldRefName === ApprovalWorkFlowLib.fkConditionalCriteriaId;
  });
  return (
    <>
      <FormEdit
        list={formFields}
        getDropdownOptions={getDropdownOptions}
        handleDropdownClick={handleDropdownClick}
        setFormData={setFromDataSteps}
        formInfo={fromDataSteps}
        errors={validationErrors}
        setErrors={setValidationErrors}
        hideFields={false}
      />
      <Typography variant="caption" color={'neutral.dark80'}>
        Note: Step 0 is top level step who creates the record.
      </Typography>
      <RHSBoxAction
        disableDelete={stepsLength === 1}
        onClickAdd={handleAddSteps}
        onClickDelete={handleDeleteSteps}
        onClickCopy={handleDuplicateSteps}
      />
      <ConditionalModal
        showModal={showModal}
        secondaryAction="close"
        title="Approval conditions &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;"
        setshowModal={setShowModal}
        listComponent={
          <FormEdit
            list={conditionalCriteria}
            getDropdownOptions={getDropdownOptions}
            handleDropdownClick={handleDropdownClick}
            setFormData={setFromDataSteps}
            formInfo={fromDataSteps}
            errors={validationErrors}
            setErrors={setValidationErrors}
            padding='0'
            hideFields={false}
          />
        }
      />
    </>
  );
};

export default React.memo(forwardRef(AppWorkStep));
