import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import AddJob from './AddJob';
import TabBar from '../../../../../../atoms/Tabs/TabBar';

export default function ManageJob() {
  const [viewMode, setViewMode] = useOutletContext();

  const jobsTabs = [
    { 
      name: 'create-job',
      label: 'Manage Job',
      title: 'Manage Job',
      hide: false,
      panel: <AddJob viewMode={viewMode}
      setViewMode={setViewMode} />
    }
];

  return (
    <Box>
      <TabBar
          tabPanels={jobsTabs}
          searchTabs
          disableCreate />
    </Box>
  );
}
