import React, { useEffect, useMemo, useState } from 'react';
import ModalDialog from '../../../../../atoms/Modal/ModalDialog';
import DropDownActionForCandidate from './DropDownActionForCandidate';
import AssignCandidate from '../CandidateDetails/AssignCandidate';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../../OnBoarding/Organization/constants/onboarding.constants';
import {
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../../../../constants/common';
import InterviewSchedule from '../CandidateDetails/ManageCandidate/InterviewSchedule/InterviewSchedule';
import { CandidateServices } from '../../../../../services/candidateServices';
import { getAllCandidateList } from '../../../../../redux/candidateDetails/action';
import {
  getFormBody,
  getMandatoryFields,
  getValidationErrors,
} from '../../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { isEmpty } from 'lodash';
import {
  getInterviewConfig,
  getInterviewFeedbackConfig,
  postInterviewInfo,
} from '../../../../../redux/interviewSchedule/action';
import {
  CandidateFormFieldNames,
  InterviewFormFields,
} from '../../../Constant/constant';
import InterviewFormFeedbackForm from '../CandidateDetails/ManageCandidate/InterviewSchedule/Feedback/InterviewFormFeedbackForm';
import { InterviewServices } from '../../../../../services/interviewServices';
import { GoalsServices } from '../../../../../services/goals';
import moment from 'moment';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import { getFormattedValues } from '../../../../../utils/formatter';

const CandidateModal = (props) => {
  const {
    checkedItems,
    jobId,
    candidateList,
    searchInput,
    selectedDate,
    candidateNameParams,
  } = props;

  const dispatch = useDispatch();
  const { currentUserRoleId } = useSelector((state) => state.main);
  const {
    interviewConfig,
    interviewFormData,
    interviewSkills,
    interviewCompetencies,
    interviewFeedbackFormData,
    interviewFeedbackFormConfig,
  } = useSelector((state) => state.interview);
  const { isInterviewer } = useSelector((state) => state.candidate);

  const [showModal, setshowModal] = useState(false);
  const [context, setContext] = useState('');
  const [JobIds, setJobIds] = useState([]);
  const [viewMode, setViewMode] = useState(false);
  const [interviewForm, setInterviewForm] = useState(interviewFormData);
  const [error, setError] = useState({});
  const [closeModalOnSave, setCloseModalOnSave] = useState(false);

  const candidateObject = candidateList.find(
    (item) => item.id === checkedItems[0]
  );
  const candidateJobId = candidateObject?.fkCandidateJobMapping;
  const candidateCode = candidateObject?.candidateCode;
  const candidateName = candidateObject?.name;
  const interviewId = candidateObject?.interviewId;
  const interviewStatusCode =
    candidateObject?.interviewStatusCode?.interviewStatusCode;
  const jobInterviewStatus =
    candidateObject?.jobInterviewStatus?.recruitingStatusCode;
  const jobStatusCode = candidateObject?.jobStatusCode;

  const [competencyRatingScale, setCompetencyRatingScale] = useState(4);
  const [competencies, setCompetencies] = useState(interviewCompetencies);
  const [skills, setSkills] = useState(interviewSkills);
  const [interviewFeedbackFormInfo, setInterviewFeedbackFormInfo] = useState(
    interviewFeedbackFormData
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSkills(interviewSkills);
  }, [interviewSkills]);

  useEffect(() => {
    setInterviewFeedbackFormInfo(interviewFeedbackFormData);
  }, [interviewFeedbackFormData]);

  useEffect(() => {
    setCompetencies(interviewCompetencies);
  }, [interviewCompetencies]);

  useEffect(() => {
    if (isEmpty(interviewFormData)) {
      setViewMode(false);
    } else {
      setViewMode(true);
    }
  }, [interviewConfig, interviewFormData]);

  useEffect(() => {
    setInterviewForm({
      ...interviewFormData,
      [CandidateFormFieldNames.Code]: candidateCode,
      [CandidateFormFieldNames.Name]: candidateName,
    });
  }, [interviewFormData]);

  useEffect(() => {
    if (showModal && !isEmpty(interviewFormData)) {
      setViewMode(true);
    }
  }, [showModal]);

  const interviewFormRequiredFields = useMemo(() => {
    return getMandatoryFields(interviewConfig);
  }, [interviewConfig]);

  const handleAddJobClick = () => {
    new CandidateServices()
      .postAssignJob({
        jobs: JobIds.map((item) => Number(item.id)),
        candidates: checkedItems.map(Number),
      })
      .then(() => {
        dispatch(
          getAllCandidateList(
            currentUserRoleId,
            searchInput,
            getFormattedValues('YYYY-MM-DD', selectedDate[0]),
            getFormattedValues('YYYY-MM-DD', selectedDate[1]),
            candidateNameParams
          )
        );
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.update),
          ToastThemes.success
        );
        setCloseModalOnSave(true);
        setshowModal(false);
      })
      .catch(() => {
        showToast(
          ToastMessages.failed.replace(
            stringSubstitute,
            toastMessage.errorupdate
          ),
          ToastThemes.error
        );
      });
  };
  const handleAddJob = () => {
    setContext('addJob');
    setshowModal(true);
  };

  const handleInterview = () => {
    setContext('interview');
    setLoading(LoadingStatus.Loading);
    if (
      currentUserRoleId &&
      candidateJobId &&
      jobId &&
      checkedItems.length === 1
    ) {
      dispatch(getInterviewConfig(currentUserRoleId, candidateJobId))
        .then((success) => {
          if (success) {
            setshowModal(true);
            setLoading(LoadingStatus.Success);
          }
        })
        .catch((err) => {
          showToast(
            ToastMessages.failed.replace(
              stringSubstitute,
              toastMessage.errorupdate
            ),
            ToastThemes.error
          );
          setLoading(LoadingStatus.Failure);
        });
    }
  };

  const handleInterviewFeedback = () => {
    setContext('interviewFeedback');
    setLoading(LoadingStatus.Loading);
    if (
      currentUserRoleId &&
      candidateJobId &&
      jobId &&
      checkedItems.length === 1
    ) {
      new GoalsServices()
        .getRatingScales()
        .then((res) => {
          setCompetencyRatingScale(res.competencyRatingScale);
          dispatch(
            getInterviewFeedbackConfig(currentUserRoleId, interviewId)
          ).then((success) => {
            if (success) {
              setshowModal(true);
              setLoading(LoadingStatus.Success);
            }
          });
        })
        .catch((err) => {
          showToast(
            ToastMessages.failed.replace(
              stringSubstitute,
              toastMessage.errorupdate
            ),
            ToastThemes.error
          );
          setLoading(LoadingStatus.Failure);
        });
    }
  };
  const handleSaveClick = () => {
    const interviewFormErrors = getValidationErrors(
      interviewForm,
      interviewFormRequiredFields
    );
    if (isEmpty(interviewFormErrors)) {
      dispatch(postInterviewInfo(candidateJobId, interviewForm))
        .then((success) => {
          if (success) {
            showToast(
              ToastMessages.success.replace(
                stringSubstitute,
                toastMessage.success
              ),
              ToastThemes.success
            );
            setViewMode(true);
            setCloseModalOnSave(true);
            setshowModal(false);
            dispatch(
              getAllCandidateList(
                currentUserRoleId,
                searchInput,
                getFormattedValues('YYYY-MM-DD', selectedDate[0]),
                getFormattedValues('YYYY-MM-DD', selectedDate[1]),
                candidateNameParams
              )
            );
          }
        })
        .catch((err) => {
          showToast(
            ToastMessages.failed.replace(
              stringSubstitute,
              toastMessage.errorupdate
            ),
            ToastThemes.error
          );
        });
    } else {
      setCloseModalOnSave(false);
      setError(interviewFormErrors);
    }
  };

  const handleEditClick = () => {
    setViewMode(false);
    setCloseModalOnSave(false);
  };

  const handleSaveInterviewFeedClick = () => {
    const competenciesBody = competencies.map((item) => {
      delete item.name;
      return item;
    });

    const skillsBody = skills.map((item) => {
      delete item.name;
      return item;
    });
    const interviewFormData = {
      ...getFormBody({ ...interviewForm }),
      ...getFormBody({ ...interviewFeedbackFormInfo }),
    };
    delete interviewFormData[CandidateFormFieldNames.Code];
    delete interviewFormData[CandidateFormFieldNames.Name];
    delete interviewFormData[InterviewFormFields.InterviewType];
    delete interviewFormData[InterviewFormFields.Interviewer];

    const body = {};
    Object.assign(
      body,
      { skills: skillsBody },
      { competencies: competenciesBody },
      { interviewInfo: interviewFormData }
    );

    new InterviewServices()
      .postInterviewFeedbackInfo(body, interviewId)
      .then(() => {
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.success),
          ToastThemes.success
        );
        setViewMode(true);
        setCloseModalOnSave(true);
        setshowModal(false);
        dispatch(
          getAllCandidateList(
            currentUserRoleId,
            searchInput,
            getFormattedValues('YYYY-MM-DD', selectedDate[0]),
            getFormattedValues('YYYY-MM-DD', selectedDate[1]),
            candidateNameParams
          )
        );
      })
      .catch((err) => {
        showToast(
          ToastMessages.failed.replace(
            stringSubstitute,
            toastMessage.errorupdate
          ),
          ToastThemes.error
        );
      });
  };

  const modalMap = {
    addJob: {
      title: 'Add to Job',
      listComponent: <AssignCandidate setIds={setJobIds} />,
      primaryAction: 'Save',
      onClickConfirm: () => handleAddJobClick(),
      severity: 'success',
      maxWidth: 'md',
      fullWidth: true,
      viewMode: false,
    },
    interview: {
      title: 'Interview Scheduled',
      listComponent: (
        <InterviewSchedule
          viewMode={viewMode}
          setInterviewForm={setInterviewForm}
          interviewFormInfo={interviewForm}
          formConfig={interviewConfig}
          error={error}
          setError={setError}
          competencies={competencies}
          setCompetencies={setCompetencies}
          skills={skills}
          setSkills={setSkills}
          interviewFeedbackFormInfo={interviewFeedbackFormInfo}
          setInterviewFeedbackFormInfo={setInterviewFeedbackFormInfo}
        />
      ),
      primaryAction: 'Save',
      severity: 'success',
      tertiaryAction: 'Edit',
      maxWidth: 'md',
      fullWidth: true,
      onClickConfirm: () => handleSaveClick(),
      handleTertiaryAction: () => handleEditClick(),
      viewMode: viewMode,
    },
    interviewFeedback: {
      title: 'Interview Feedback',
      listComponent: (
        <InterviewFormFeedbackForm
          list={interviewConfig}
          info={interviewForm}
          isInterviewer={isInterviewer}
          competencies={competencies}
          setCompetencies={setCompetencies}
          skills={skills}
          setSkills={setSkills}
          interviewFeedbackFormInfo={interviewFeedbackFormInfo}
          setInterviewFeedbackFormInfo={setInterviewFeedbackFormInfo}
          setInterviewForm={setInterviewForm}
          interviewFeedbackFormConfig={interviewFeedbackFormConfig}
          competencyRatingScale={competencyRatingScale}
        />
      ),
      primaryAction: 'Save Feedback',
      severity: 'success',
      maxWidth: 'md',
      fullWidth: true,
      onClickConfirm: () => handleSaveInterviewFeedClick(),
      viewMode: false,
      primaryButtonDisabled: !(
        moment() >=
        moment(
          interviewForm?.[InterviewFormFields.InterviewDateTime],
          'YYYY-MM-DD HH:mm'
        )
          .utc(
            interviewForm?.[InterviewFormFields.InterviewDateTime],
            'YYYY-MM-DD HH:mm'
          )
          .local()
      ),
    },
  };
  return (
    <>
      <DropDownActionForCandidate
        checkedItems={checkedItems}
        handleAddJob={handleAddJob}
        handleInterview={handleInterview}
        handleInterviewFeedback={handleInterviewFeedback}
        jobId={jobId}
        isInterviewer={isInterviewer}
        interviewStatusCode={interviewStatusCode}
        jobInterviewStatus={jobInterviewStatus}
        jobStatusCode = {jobStatusCode}
      />
      <CircularLoader show={loading === LoadingStatus.Loading} />
      <ModalDialog
        severity={modalMap[context]?.severity}
        title={modalMap[context]?.title}
        listComponent={modalMap[context]?.listComponent}
        description={modalMap[context]?.description}
        primaryAction={
          !modalMap[context]?.viewMode
            ? modalMap[context]?.primaryAction
            : false
        }
        secondaryAction="Cancel"
        tertiaryAction={
          modalMap[context]?.viewMode
            ? modalMap[context]?.tertiaryAction
            : false
        }
        showModal={showModal}
        onClickClose={() => setshowModal(false)}
        onClickConfirm={modalMap[context]?.onClickConfirm}
        onClickTertiary={modalMap[context]?.handleTertiaryAction}
        maxWidth={modalMap[context]?.maxWidth}
        fullWidth={modalMap[context]?.fullWidth}
        primaryButtonDisabled={modalMap[context]?.primaryButtonDisabled}
        confirmClose={closeModalOnSave}
      />
    </>
  );
};

export default CandidateModal;

{
  /* <ModalDialog
        title='Offer Approval'
        description={<OfferApproval />}
        showModal={showModal}
        maxWidth='md'
        severity='success'
        primaryAction='Send for Approval'
        secondaryAction='Cancel'
        fullWidth
      /> */
}

{
  /* <ModalDialog
        title='Offer Template'
        description={<SendOffer />}
        showModal={showModal}
        maxWidth='md'
        severity='success'
        primaryAction='Send Offer'
        secondaryAction='Cancel'
        fullWidth
      /> */
}
