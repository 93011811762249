import { Box, Divider, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  ConditionFormFieldsName,
  DaysList,
  conditionInitialVal,
  getConditionFormFields,
  operatorTypes,
  operatorTypesForDate,
  operatorTypesForDateTime,
  operatorTypesForNumber,
  operatorTypesForTime,
} from '../../utils/conditional.util';
import {
  AddOutlined,
  ClearOutlined,
  DeleteOutline,
  LibraryBooksOutlined,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import FormEdit from '../../../OnBoarding/common/Form/FormEdit';
import { BuilderServices } from '../../../../services/builder';
import { FormFieldTypes } from '../../../../constants/common';
import IconDropdown from '../../../../atoms/IconDropdown/icon-dropdown.component';

const AddConditions = (props) => {
  const { index, setConditions, condition, noOfConditions, setFormState } =
    props;
  const { list } = useSelector((state) => state.formBuilder);
  const [conditionFormState, setConditionFormState] = useState(condition);
  const [valueDropDown, setValueDropDown] = useState([]);
  const [showFields, setshowFields] = useState(false);

  useEffect(() => {
    setConditionFormState(condition);
  }, [condition]);

  useEffect(() => {
    setConditions((prev) => {
      const newConditions = [...prev];
      newConditions[index] = conditionFormState;
      return newConditions;
    });
    const newList = conditionFormFields.map((item) => {
      switch (item.fieldRefName) {
        case ConditionFormFieldsName.Value:
          return {
            ...item,
            fieldType:
              conditionFormState.fieldType === FormFieldTypes.Formula
                ? FormFieldTypes.Text
                : conditionFormState.fieldType,
            dataType:
              conditionFormState.fieldType === FormFieldTypes.Formula
                ? FormFieldTypes.Number
                : conditionFormState.dataType,
            componentFieldType: conditionFormState.componentFieldType,
            isDisabled: showFields,
          };
        default:
          return item;
      }
    });
    if (
      (conditionFormState?.componentFieldType === FormFieldTypes.Formula ||
        conditionFormState?.componentFieldType === FormFieldTypes.Number) &&
      isNaN(conditionFormState?.value)
    ) {
      setshowFields(true);
    }
    setConditionFormFields(newList);
  }, [conditionFormState, showFields]);

  useEffect(() => {
    if (
      (conditionFormState.fieldType === FormFieldTypes.SingleSelect &&
        conditionFormState.sysField) ||
      conditionFormState.fieldType === FormFieldTypes.MutliSelect
    ) {
      valueActionOnComponentChange(
        conditionFormState.fieldType,
        conditionFormState.sysField
      );
    }
  }, [
    conditionFormState[ConditionFormFieldsName.Component],
    conditionFormState[ConditionFormFieldsName.Operator],
  ]);

  const handleChangeComponent = (event) => {
    const { value, name } = event.target;
    const {
      fieldType,
      sysField: { id },
      dataType,
    } = value;
    setConditionFormState((prev) => ({
      ...prev,
      [name]: value,
      fieldType: fieldType,
      sysField: id,
      dataType: dataType,
      componentFieldType: dataType || fieldType,
      [ConditionFormFieldsName.Value]: '',
    }));
  };

  const handleChangeOperator = (event) => {
    const { value, name } = event.target;
    const { fieldType } = value;
    setConditionFormState((prev) => {
      return {
        ...prev,
        [name]: value,
        fieldType: fieldType || prev.fieldType,
        [ConditionFormFieldsName.Value]: '',
      };
    });
  };

  const handleChangeIconButton = (name) => {
    setshowFields(true);
    setConditionFormState((prev) => ({
      ...prev,
      [ConditionFormFieldsName.Value]: name,
    }));
  };

  const [conditionFormFields, setConditionFormFields] = useState(
    getConditionFormFields({
      [ConditionFormFieldsName.Component]: handleChangeComponent,
      [ConditionFormFieldsName.Operator]: handleChangeOperator,
    })
  );
  const getOperatorType = (fieldType) => {
    switch (fieldType) {
      case FormFieldTypes.Date:
        return operatorTypesForDate;
      case FormFieldTypes.Time:
        return operatorTypesForTime;
      case FormFieldTypes.DateTime:
        return operatorTypesForDateTime;
      case FormFieldTypes.Number:
      case FormFieldTypes.Formula:
        return operatorTypesForNumber;
      default:
        return operatorTypes;
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case ConditionFormFieldsName.Component:
        return list.map((item) => {
          const { fieldRefName, fieldLabel } = item;
          return { ...item, id: fieldRefName, name: fieldLabel };
        });
      case ConditionFormFieldsName.Operator:
        return getOperatorType(conditionFormState.componentFieldType);
      case ConditionFormFieldsName.Value:
        return valueDropDown;
    }
  };

  const valueActionOnComponentChange = (fieldType, sysFieldId) => {
    switch (fieldType) {
      case FormFieldTypes.SingleSelect: {
        new BuilderServices()
          .getDropDownOption(sysFieldId, false)
          .then((res) => {
            setValueDropDown(res);
          });
        break;
      }
      case FormFieldTypes.MutliSelect: {
        setValueDropDown(DaysList);
        break;
      }
    }
  };

  const handleDelete = (index) => {
    if (noOfConditions === 1) {
      setConditionFormState(conditionInitialVal);
      setFormState({});
      setshowFields(false);
    } else {
      setConditions((prev) => {
        const newConditions = [...prev];
        newConditions.splice(index, 1);
        setConditionFormState(newConditions[index]);
        return newConditions;
      });
      setshowFields(false);
    }
  };

  const handleClear = () => {
    setshowFields(false);
    setConditionFormState((prev) => ({
      ...prev,
      [ConditionFormFieldsName.Value]: '',
    }));
  };

  return (
    <>
      <Box display="flex" alignItems="center" flex="1 1 0">
        <Box flexGrow={3}>
          <FormEdit
            list={conditionFormFields}
            formInfo={conditionFormState}
            setFormData={setConditionFormState}
            getDropdownOptions={getDropdownOptions}
            padding={0}
            modal={false}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
          {showFields && (
            <Box
              sx={{
                width: 'fit-content',
                height: '1.4375em',
                border: '1px solid #D8D8D8',
                p: 1,
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'space-between',
                mb: -1,
              }}
            >
              {conditionFormState?.value}
              <IconButton onClick={handleClear} color="error">
                <ClearOutlined sx={{ fontSize: 12 }} />
              </IconButton>
            </Box>
          )}
          {(conditionFormState.fieldType === FormFieldTypes.Formula ||
            conditionFormState.dataType === FormFieldTypes.Number) && (
            <IconDropdown
              menuItems={list
                .filter(
                  (item) =>
                    item.fieldType === FormFieldTypes.Formula ||
                    item.dataType === FormFieldTypes.Number
                )
                .map((item) => ({
                  ...item,
                  label: item.fieldLabel,
                  name: item.fieldRefName,
                }))}
              Icon={<LibraryBooksOutlined />}
              onChange={handleChangeIconButton}
            />
          )}
          <IconButton
            color="success"
            sx={{ borderColor: 'white' }}
            onClick={() =>
              setConditions((prev) => {
                return [...prev, { ...conditionInitialVal }];
              })
            }
          >
            <AddOutlined sx={{ width: '24px', height: '24px', mr: '5px' }} />
          </IconButton>
          <IconButton
            color="error"
            sx={{ borderColor: 'white' }}
            onClick={() => handleDelete(index)}
          >
            <DeleteOutline sx={{ width: '24px', height: '24px', mr: '5px' }} />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ margin: 2 }} />
    </>
  );
};

export default AddConditions;
