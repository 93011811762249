import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import Calculator from '../../../../../../../atoms/Calculator/calculator.component';
import FunctionPicker from '../../../../../../form-builder/modal-component/conditional-expression/expression-accordian/expression-editor/function-picker.component';
import BenefitFieldPicker from './benefit-field-picker';

const BenefitCalculationExpression = (props) => {
    const {
        handleFieldClick,
        handleCalculatorClick,
        handleBackClick,
        expressionVal,
        handleFunctionClick,
        fieldList
      } = props;
      const [selectedPanel, setSelectedPanel] = useState('Operator');
      const handlePanelChange = (panel) => {
        setSelectedPanel(panel);
      };
      return (
        <Box
          border="1px solid #A7ECE2"
          display="grid"
          gridTemplateColumns={{
            xs: '1fr',
            sm: '1fr 2fr',
            md: '1fr 2fr 1fr'
          }}
          gridTemplateRows={{
            xs: 'auto',
            md: '40px 260px'
          }}
          width="100%"
          height="fit-content"
        >
          <Box>
            <Typography p={1}>Fields</Typography>
            <BenefitFieldPicker handleClick={handleFieldClick} fieldList={fieldList} />
          </Box>
          <Box>
            <Typography
              p={1}
              borderLeft={{ xs: 'none', sm: '1px solid #eeeeee' }}
              borderBottom="1px solid #eeeeee"
            >
              Formula
            </Typography>
            <Box p={1}>{expressionVal}</Box>
          </Box>
          <Box>
            <Box
              borderBottom="1px solid #eeeeee"
              display="grid"
              gridTemplateColumns={{ xs: '1fr 1fr', sm: '1fr 1fr' }}
              width="fit-content"
              height="fit-content"
            >
              <Typography
                p={1}
                borderLeft={{ xs: 'none', sm: '1px solid #eeeeee' }}
                onClick={() => handlePanelChange('Operator')}
                style={{
                  cursor: 'pointer',
                  fontWeight: selectedPanel === 'Operator' ? 'bold' : 'normal',
                }}
              >
                Operator
              </Typography>
              <Typography
                p={1}
                borderLeft="1px solid #eeeeee"
                onClick={() => handlePanelChange('Function')}
                style={{
                  cursor: 'pointer',
                  fontWeight: selectedPanel === 'Function' ? 'bold' : 'normal',
                }}
              >
                Function
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderLeft={{ xs: 'none', sm: '1px solid #eeeeee' }}
            >
              {selectedPanel === 'Operator' ? (
                <Calculator
                  handleDigitClick={handleCalculatorClick}
                  handleBackClick={handleBackClick}
                />
              ) : (
                <FunctionPicker handleClick={handleFunctionClick} />
              )}
            </Box>
          </Box>
        </Box>
      );
    };

export default BenefitCalculationExpression;