import React from 'react';
import FormView from '../../../../../../OnBoarding/common/Form/FormView';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

const TargetFormView = (props) => {
    const {info} = props;
    const { targetConfig } = useSelector((state) => state.goals);

  return (

    targetConfig.map((config, index) => {
        return (<Box key={index} mt={2}>
            <FormView list={config} info={info[index] || {}}/>
            </Box>
        );
    })
  );

};

export default TargetFormView;