import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import TabBar from '../../../../../../atoms/Tabs/TabBar';
import AddCandidate from './AddCandidate';
import { useOutletContext } from 'react-router-dom';
import CandidateAssessment from './CandidateAssessment/CandidateAssessment';
import { CandidateAssessmentTab } from '../../../../Constant/constant';
import { permissionsObj } from '../../../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import { useSelector } from 'react-redux';

export default function ManageCandidate() {
  const [viewMode, setViewMode] = useOutletContext();
  const { recrutingPermissions } = useSelector((state) => state.modulePermissions);

  const jobsTabs = [
    {
      name: 'add-candidate',
      label: 'Manage Candidate',
      hide: false,
      title: 'Manage Candidate',
      panel: <AddCandidate viewMode={viewMode}
        setViewMode={setViewMode} />
    },
    {
      name: 'candidate-assessment',
      label: 'Candidate Assessment',
      hide: !recrutingPermissions?.[CandidateAssessmentTab]?.[permissionsObj.allowView],
      title: 'Candidate Assessment',
      panel: <CandidateAssessment />
    }
  ];

  return (
    <Box>
      <TabBar
        tabPanels={jobsTabs}
        searchTabs
        disableCreate
      />
    </Box>
  );
}
