import { Alert, Box, CircularProgress, Container, FormControl, Grid, IconButton, MenuItem, Paper, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CardUI from '../../shared/components/ui-elements/CardUI';
import FormSelect from '../../atoms/Select/Select';
import InputField from '../../atoms/Input/Input';
import FormDate from '../../atoms/Date/Date';
import TimePicker from '../../atoms/time/timepicker';
import { set } from 'date-fns';
import FilesUploader from '../../atoms/FileUpload/FilesUploader';
import * as Joi from 'joi';
import Footer from '../footer/Footer';
import FieldsRender from './fields-render';
import { useParams } from 'react-router-dom';
import { ClaimServices } from '../../services/claims';
import Loading from '../../atoms/Loading/loading';

export default function DynamicForm(props) {
  const claimService = new ClaimServices();
  const {claimType} = useParams();
  const [formSchema, setFormSchema] = useState({});
  const [currencyExchangeRate, setCurrencyExchangeRate] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    claimService.getClaimConfigurationSchema(
      claimType
    ).then(res=>{
      setFormSchema(res.data?.data);
    }).catch(error=>{
      console.log(error?.response?.data || error.message);
    }).finally(()=> setLoading(false));
    claimService.getCurrencyExchangeRate().then(res=>{
      setCurrencyExchangeRate(res.data?.data);
    }).catch(error=>{
      console.log(error?.response?.data || error.message);
    }).finally(()=> setLoading(false));

  }, []);
  
  return (
    <>
    <Loading loading={loading}>
      <FieldsRender entitlements = {formSchema.entitlements||[]} openSuccessPopUp={props.openSuccessPopUp} currencyExchangeRate={currencyExchangeRate} formSchema={formSchema}  />
    </Loading>
    </>
  );
}
