import { Box } from '@mui/material';
import React from 'react';
import ListView from '../../../../../../../atoms/DataList/ListView';

const ClaimPreviewModal = (props) => {
  const { content } = props;

  const getViewName = (id) => {
    switch (id) {
      case 1:
        return 'My Data';
      case 2:
        return 'Approver Data';
      case 3:
        return 'Approver Data With Dashboard Approve Access';
      case 4:
        return 'Reportees Data';
      case 5:
        return 'Department Data';
      case 6:
        return 'All Approved Data';
      case 7:
        return 'All Data';
      default:
        return 'No Data';
    }
  };

  const rowData = content?.map((items) => {
    const {
      displayName,
      permissions: { allowAdd, allowEdit, viewAccess, allowView, showSubtype },
    } = items;
    return {
      name: displayName,
      allowAdd: String(allowAdd),
      allowEdit: String(allowEdit),
      viewAccess: getViewName(viewAccess),
      showSubtype: String(showSubtype)
    };
  }).sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Box>
      <ListView
        headCells={[
          { id: 'name', label: 'Claim Type' },
          { id: 'allowAdd', label: 'Allow Add' },
          { id: 'allowEdit', label: 'Allow Edit' },
          { id: 'viewAccess', label: 'View Acces' },
          { id: 'showSubtype', label: 'Show Subtype' },
        ]}
        rowsData={rowData || []}
        showSerialNo={true}
        showCheckbox={false}
        allowSelectAll={false}
        showAddIcon={false}
      />
    </Box>
  );
};

export default ClaimPreviewModal;
