import React from 'react';
import FieldView from '../../components/molecules/FieldView/FieldView';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import { LightTooltip } from '../Tooltip/LightTooltip';
import { InfoOutlined } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { getDate } from '../../utils/formatter';

const MonthField = (props) => {
  const {
    id,
    defaultValue,
    placeholder,
    labelText,
    size,
    hiddenLabel,
    fullWidth,
    required,
    onChange,
    name,
    value,
    errorMessage,
    maxDate,
    minDate,
    disabled,
    copyTo,
    isReadOnly,
    infoIcon,
    disablePast,
    disableFuture,
    monthDataArray = []
  } = props;

const shouldDisableDate = (date) => {
  const monthYear = moment(date).format('YYYY-MM');
  return monthDataArray.includes(monthYear);
};
const handleKeyDown = (event) => {
  if (event.key.startsWith('Arrow')) {
    event.preventDefault();
  }
};

  if (isReadOnly) {
    return (
      <FieldView
        labelText={labelText}
        labelValue={value ? moment(value, 'YYYY-MM').format('MMM/YYYY') : ''}
      />
    );
  }
  return (
    <>
      {labelText && (
        <Typography
          variant="body2"
          color={`${disabled ? 'neutral.light40' : 'neutral.dark80'}`}
          mb={1}
        >
          {labelText}
          {required && (
            <Box color="accentSecondary.main" sx={{ display: 'inline-block' }}>
              *
            </Box>
          )}
          {infoIcon && (
            <LightTooltip title={infoIcon} placement="top" arrow>
              <Box color="primary.main" sx={{ display: 'inline-block' }}>
                <InfoOutlined
                  sx={{ width: 14, height: 14, ml: '4px', cursor: 'pointer' }}
                />
              </Box>
            </LightTooltip>
          )}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
        <DatePicker
          format="MMM/YYYY"
          onChange={(value) => onChange(value, name)}
          value={value ? getDate(value, 'YYYY-MM') : null}
          maxDate={maxDate}
          minDate={minDate}
          disabled={disabled}
          views={['month', 'year']}
          disableFuture={disableFuture}
          disablePast={disablePast}
          shouldDisableMonth={shouldDisableDate}
          openTo="month"
          slotProps={{
            textField: {
              fullWidth: fullWidth,
              size: 'small',
              variant: 'outlined',
              error: errorMessage? errorMessage: '',
              helperText: errorMessage,
              onKeyPress: (event) => event.preventDefault(),
              onKeyDown: handleKeyDown,
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
};

export default MonthField;
