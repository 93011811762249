import { Alert, Button, CircularProgress, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput, Paper, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box, margin } from '@mui/system';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import '../login/Login.css';
import  Joi from 'joi-browser';
import { MARKETPLACE_USER_TOKEN, PROFILE } from '../../constants/constants';
import axios from 'axios';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
     margin: '20px 0'
    },
    main:{
        padding: '20px 10px',
        minHeight: '70vh',
        height: 'auto',
        background: '#ffffff',
    },
    subHeading:{
        textAlign: 'center'
    },
    p: {
        padding: '15px'
    },
    contentBody:{
        marginTop: '20px'
    },
    intro:{
        margin: '1.5rem 3rem !important'
    },
    colCenter:{
        display:'flex',
        flexFlow:'column',
        justifyContent:'center',
        height: '100%'
    }
  }));
export default function MarketPlace(props) {
    const classes = useStyles();
    const hostname = window.location.hostname;
    const tenant = hostname.split('.')[0] === 'localhost'? process.env.REACT_APP_LOCALHOST_CLIENT_ID : hostname.split('.')[0];
    const [formData, setFormData] = useState({
        name:'',
        organizationName:'',
        email:'',
        contactNumber:'',
        organizationSize: 0,
        organizationWebsite: '',
        country:'',
        state: '',
        city:'',

    });
    const [loading, setloading] = useState(false);
    const [submited, setSubmited] = useState(false);
    const [requestError, setRequestError] = useState('');
    const [error, setError] = useState({});
    const [searchParams] = useSearchParams();
    const schema = {
        name: Joi.string().min(3).max(20).required(),
        organizationName: Joi.string().min(3).max(20).required(),
        email:Joi.string().email().min(3).required(),
        contactNumber:Joi.number().required(),
        organizationSize: Joi.number().min(5).required(),
        organizationWebsite: Joi.string().uri().required(),
        country:Joi.string().min(3).max(20).required(),
        state: Joi.string().min(3).max(20).required(),
        city:Joi.string().min(3).max(20).required(),
    };

    const validateInput = (event)=>{
        const {name, value} = event.target;
        const inputSchema = {[name]: schema[name]};
        const {error} = Joi.validate({[name]:value}, inputSchema);
        return error ? error.details[0].message: null;
    };

    const handleChange = (e)=>{
        const {name, value} =  e.target;

        const temp = {...formData};
        temp[name] = value;
        setFormData(temp);
        // Add Validation here.
        const inputError = validateInput(e);
        const errTemp = {...error};
        if(inputError){
            errTemp[name] = inputError;
        }else{
            delete errTemp[name];
        }
        setError(errTemp);
    };

    const handleSubmit = async(e)=>{ 
        setloading(true);
        setRequestError('');
        setSubmited(false);
        const {error:formError} = Joi.validate(formData,schema,{abortEarly: false});
        if(formError){
            const tempError = {};
            for (const input of formError.details) {
                const name = input.path[0];
                const message = input.message;
                tempError[name] = message;
            }
            setError(tempError);
            setloading(false);
            return;
        }
        // other wise call api.

        try {
           const token = searchParams.get('token');
           const request = await axios.post(`${process.env.REACT_APP_MARKET_SETUP_REQUEST}`,formData,{headers:{Authorization: `Bearer ${token}`,'client_id': tenant}});
           setloading(false);
           if(request.data.success){
            setSubmited(true);
            }

        } catch (error) {
            setloading(false);
            setSubmited(false);
            const message = error.response ? error.response.data.message : error.message;
            setRequestError(message);
            console.log (error);
        }

    };

    const loadDataFromLocalStorage = useCallback(async ()=>{
        const userDetails =  JSON.parse(localStorage.getItem(PROFILE.MARKETPLACE_USER));
        const data = {...formData, organizationName: userDetails.name,
        email:userDetails.email,};
        console.log('data--->', userDetails);
        setFormData(data);
    },[]);

    useEffect(() => {
      loadDataFromLocalStorage();
    }, []);
    
  return (
    <div>
        <Grid container className="login-screen-wrap" style={{ padding: '5%' }}>
            <Grid item xs={12} sm={12} md={12} className="login-inputs-container">
           
                <Box  className={classes.main}>
                    <Box  textAlign="center" direction="row">
                     <img src="/vexecution-logo.jpeg" height={'100px'}  width="100px"/>
                    </Box>
                    <Typography variant='h5' className={classes.subHeading}>Tell Us About Your Organization.</Typography>
                    <Grid container className={classes.contentBody} justifyContent="center">
                        <Grid item xs={12} sm={6} 
                        >
                        <div className={classes.colCenter}>
                            <Typography textAlign={'center'} variant='h6' className={classes.p}>
                                Thanks for Joining Us. <br />
                            </Typography>
                            <Typography textAlign={'center'} className={classes.intro} variant='body1'>
                            We constantly ideate, collaborate to be razor sharp focussed on building products to help enterprises execute better, solve their biggest challenges by empowering their staff through right tools. <br />  
                            </Typography>
                            <Typography textAlign={'center'} variant='body1'>
                                You will soon hear from one of our team members. <br />  
                            </Typography>
                            <Typography textAlign={'center'} variant='body1'>
                                For other information <br />
                                please feel free to reach out obie@vexecution.com <br />
                            </Typography>
                        </div>
                        </Grid>
                        {submited ? (
                        <Grid item xs={12} sm={6}>
                           <Grid container spacing={2} justifyContent="center">
                           <Grid item xs={12} textAlign="center" sm={12}>
                                <CheckCircleOutlineIcon style={{fontSize: '80px', color:'#4BB543'}} />
                            </Grid>
                           <Grid item xs={12} textAlign="center" sm={12}>
                                <Typography textAlign="center" variant='body1' className={classes.p}>
                                    Your request submitted succesfully ! 
                                    <br/> We will start processing your on bording process.
                                </Typography>
                            </Grid>
                            </Grid> 
                        </Grid>
                        ) :(
                        <Grid item xs={12} sm={6}>
                        {requestError && <Alert severity="error">{requestError}!</Alert>}
                        <Typography textAlign="center" variant='body1' className={classes.p}>
                                Please fill up the following information. <br />
                        </Typography>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12} sm={6}>
                                <TextField
                                    id="organizationName"
                                    value={formData.organizationName}
                                    name="organizationName"
                                    onChange={handleChange} 
                                    label="Organization Name"
                                    fullWidth
                                    error={error.organizationName}
                                    helperText={error.organizationName || ''}
                                /> 
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <TextField 
                                    value={formData.organizationSize}
                                    name="organizationSize"
                                    onChange={handleChange} 
                                    label="Number of Users"
                                    fullWidth
                                    error={error.organizationSize}
                                    helperText={error.organizationSize || ''}
                                /> 
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <TextField 
                                    value={formData.organizationWebsite}
                                    name="organizationWebsite"
                                    onChange={handleChange} 
                                    label="Organization Website"
                                    placeholder='https://vexecution.com'
                                    fullWidth
                                    error={error.organizationWebsite}
                                    helperText={error.organizationWebsite || ''}
                                /> 
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <TextField
                                    id="name"
                                    value={formData.name}
                                    name="name"
                                    onChange={handleChange} 
                                    label="Your Name"
                                    fullWidth
                                     error={error.name}
                                    helperText={error.name || ''}
                                /> 
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <TextField
                                    id="email"
                                    type="email"
                                    value={formData.email}
                                    name="email"
                                    onChange={handleChange} 
                                    label="Your Email"
                                    fullWidth
                                     error={error.email}
                                    helperText={error.email || ''}
                                /> 
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <TextField  
                                    value={formData.contactNumber}
                                    name="contactNumber"
                                    onChange={handleChange} 
                                    label="Contact Number"
                                    fullWidth
                                     error={error.contactNumber}
                                    helperText={error.contactNumber || ''}
                                /> 
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <TextField
                                    id="name"
                                    value={formData.country}
                                    name="country"
                                    onChange={handleChange} 
                                    label="Country"
                                    fullWidth
                                     error={error.country}
                                    helperText={error.country || ''}
                                /> 
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <TextField
                                    id="name"
                                    value={formData.state}
                                    name="state"
                                    onChange={handleChange} 
                                    label="State"
                                    fullWidth
                                     error={error.state}
                                    helperText={error.state || ''}
                                /> 
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="city"
                                        value={formData.city}
                                        name="city"
                                        onChange={handleChange} 
                                        label="City"
                                        fullWidth
                                         error={error.city}
                                    helperText={error.city || ''}
                                    /> 
                                </Grid>
                                <Grid item xs={12} sm={12} textAlign="center" >
                                    {loading ? <CircularProgress /> : (<Button onClick={handleSubmit} variant="contained" color="primary">
                                        Submit
                                    </Button>)}
                                    
                                </Grid>
                            </Grid >
                        </Grid>
                        )}
                    </Grid>   
                </Box>
            </Grid>
        </Grid>
    </div>
  );
}
