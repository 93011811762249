import React, { useEffect, useState } from 'react';
import { Button, Container, Grid } from '@mui/material';
import { useLocation,useNavigate } from 'react-router-dom';
import './AuditTrail.css';
import { getAuditTrailDataAPICall } from '../forms/common-services/services';
import AuditStepper from './AuditStepper';
import ButtonUI from '../../shared/components/ui-elements/ButtonUI';
import moment from 'moment';
import { downloadWithCustomName as downloadFile } from '../download/Downloader';

const AuditTrail = () => {
  const params = useLocation();
  const navigate = useNavigate();
  const [auditTrailData, setAuditTrailData] = useState([]);
  const [claimDetails, setClaimDetails] = useState({});
  const [mainAuditTrail,setMainAuditTrail] = useState([]);
  const [sliceFrom1, setSliceFrom1] = useState(0);
  const [sliceFrom2, setSliceFrom2] = useState(0);
  const [claimType,setClaimType] = useState(1);

  // Fields to format
  const dateKeys = ['travelDate','receiptDate','overTimeDate','dateJoined','courseDate','gatheringDate','paymentDate','childBirthDate'];
  const monthKeys = ['claimMonth','timeFrom','timeTo'];
  const timeKeys = ['departureTime','mealBreak'];
  

  // Gets the audit trail data for selected claim.
  // console.log(auditTrailData,'traill');
  const getAuditTrailData = async () => {
    let claimID = params?.state?.claimType;

    let response = await getAuditTrailDataAPICall(claimID);
  
    setAuditTrailData(response);
    setClaimType(response?.claimDetails?.type?.id);
    setClaimDetails(cleanObject(response?.claimDetails));

    let a = cleanObject(response?.claimDetails);

    // Gets the last step number from audit trail logs.
    let lastStepNumber = response?.logData[response?.logData.length - 1]?.stepnumber;

    // Using last step number we add remaining steps to audit trails which are not completed yet.
    if(lastStepNumber < response?.configDetails?.numberOfSteps){
    let updatedData = response?.configDetails?.steps.splice(lastStepNumber);
    setMainAuditTrail([...response.logData,...updatedData]);
    } else {
      setMainAuditTrail(response?.logData);
    }

    // Below logic helps to devide object slicing for rendering data.
    let objLength = Object.keys(a).length;
    if(objLength <= 10) {
      setSliceFrom1(3);
      setSliceFrom2(3);
    } else if (objLength > 18){
      setSliceFrom1(10);
      setSliceFrom2(11);
    }

  };

  const downloadWithCustomName = (url,name)=>downloadFile(url,name);

  useEffect(() => {
    getAuditTrailData();
  }, []);

  // Cleans the object removes the undefined and null values.
  const cleanObject = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  };

 // Data format handler 
  const generateClaimsData = (data= {},key) => {
    console.log(data,key, 'Dddddd');
    if(key == 'mealBreak'){
      return (data.mealBreak);
    }
    if(dateKeys.includes(key)){
      return moment(data[key]).format('DD-MM-YYYY');
    } 
    if(monthKeys.includes(key)){
      return moment(data[key]).format('MM-YYYY');
    }
    if(timeKeys.includes(key)){
      return moment(data[key]).format('hh:mm A');
    }
    if(key=='amount'){
      return data[key] &&Number(data[key]).toFixed(2);
      
    }
    if(key=='parkingFee'){
      return data[key] &&Number(data[key]).toFixed(2);
      
    }
    if(key=='tollFee'){
      return data[key] &&Number(data[key]).toFixed(2);
      
    }
    if(key=='courseFee'){
      return data[key] &&Number(data[key]).toFixed(2);
  
    }
    if(key=='entitlement'){
      return data[key] &&Number(data[key]).toFixed(2);
      
    }
    if(key=='tollFee'){
      return data[key] &&Number(data[key]).toFixed(2);
      
    }
    if(key=='amountOfLoan'){
      return data[key] &&Number(data[key]).toFixed(2);
      
    }
    if(key=='toFromHome'){
      return data[key]?'Yes':'NO';
      
    }

    if(key === 'status' & data[key] === 'APPROVED' && Object.prototype.hasOwnProperty.call(data, 'paymentDate')){
      console.log('APPROVED--', data);
      return moment(data.paymentDate, 'YYYY-MM-DD').isBefore(moment()) > 0 ? 'Payment Processed' : 'APPROVED';
    }
    
    return data[key];
  };

  return (
    <React.Fragment>
      <Container className="mar-top audit-trail-wrap-upper">
        <Grid container spacing={0}>
          <Grid item xs={12} md={6} sm={6} lg={6}>
            <ButtonUI
              variant="contained"
              size="small"
              width="auto"
              backgroundColor="#00AC94"
              color="#ffffff"
              onclick={() => navigate('/dashboard',{
                state: {
                  claimType: claimType,
                },
              })}
            >
              Go Back
            </ButtonUI>
          </Grid>
        </Grid>
      </Container>
      <Container className="mar-top audit-trail-wrap">
        <Grid container spacing={0}>
          <Grid item xs={12} md={6} sm={6} lg={6}>
            <div className="audit-summary-wrap">
              <h4>Summary</h4>
              <div className="summary-details">
                <p>
                  Claim reference number :{' '}
                  {auditTrailData?.claimDetails?.referenceNumber}
                </p>
                
                <p>Amount : ${Number(auditTrailData?.claimDetails?.amount).toFixed(2)}</p>
              </div>
              <div className="summary-details">
                <p>Claim type</p>
                <p>
                  {console.log(auditTrailData)}
                {auditTrailData?.claimDetails?.type?.name.replaceAll(
                    '-',
                    ' '
                  )=='transport claim' && 'Adhoc Transport Claim' || auditTrailData?.claimDetails?.type?.name=='flexible-benefit' && 'Flexible Benefit / Maternity Benefit / Health Screening'||auditTrailData?.claimDetails?.type?.name
                  =='union-gathering' && 'Department/Union Gathering Claim'||auditTrailData?.claimDetails?.type?.name
                  =='consumables' && 'Consumables (SA)'||auditTrailData?.claimDetails?.type?.name
                  =='marriage' && 'Marriage Benefit'||
                  auditTrailData?.claimDetails?.type?.name
                  =='child-birth' && 'Child Birth Benefit'||auditTrailData?.claimDetails?.type?.name
                  =='retirement' && 'Retirement Gift'||
                  auditTrailData?.claimDetails?.type?.name.replaceAll(
                    '-',
                    ' '
                  ) }
                </p>
              </div>
            </div>

            <div className="audit-claim-details-wrap">
              <h4>Details</h4>
              <Grid container spacing={0}>
                  {claimDetails &&
                    Object.keys(claimDetails)
                      .map((keys, i) => {
                        if(claimDetails.type.name !== 'flexible-benefit' && keys=== 'coPaymentAmount' ){
                          return;
                        }
                        if (keys !== 'type' && keys !== 'attachment' && keys !== 'benefit' &&keys !== 'numberOfShifts'&& keys !=='reasonsForWorkingOutside' ) {
                          let updatedKey = keys.split(/(?=[A-Z])/);
                          if(claimDetails.type.name === 'transport-claim' && keys === 'distance'){
                            updatedKey = String('Home To Office Distance(KM)').split(' ');
                          }
                          
                          return (
                          <Grid xs={6} key={i}>
                            <div className="single-claim-detail">

                             
                             <>
                             {console.log(keys,'in')}
                             <p className="claim-detail-title">
                                {updatedKey.join(' ')=='to From Home' ?'To/From Home':updatedKey.join(' ')=='toll Fee'?'ERP':updatedKey.join(' ')}
                              </p>
                              <p className="claim-detail-value">
                                {console.log(auditTrailData.claimDetails.numberOfShifts,'clalala')}
                                { 
                                  auditTrailData?.claimDetails[keys] !== ''
                                  ? generateClaimsData(auditTrailData?.claimDetails,keys)
                                  : 'NA'
                                  } 
                              </p>
</>
                              
                            </div>
                            </Grid>
                          );
                        }
                      })}

              </Grid>
              {claimDetails &&
                Object.keys(claimDetails).map((keys, i) => {
                  if (keys == 'attachment') {
                    let updatedKey = keys.split(/(?=[A-Z])/);
                    return (
                      <Grid xs={6} key={i}>
                        <p className="claim-detail-title">
                          {updatedKey.join(' ') + 's'}
                        </p>
                        {auditTrailData?.claimDetails[keys].map((img, i) => {
                          return (<Button key={i} variant="text" onClick={()=> downloadWithCustomName(img,claimDetails.referenceNumber+'-A'+(i+1))}> {claimDetails.referenceNumber}-A{i + 1} </Button>);
                          // return (
                          //   <a
                          //     style={{
                          //       display: 'flex',
                          //       flexDirection: 'column',
                          //     }}
                          //     key={i}
                          //     href={`${process.env.REACT_APP_FILE_BASE_URL}${img}`}
                          //     target="_blank"
                          //     rel="noreferrer"
                          //     className="claim-attachment-link"
                          //   >
                          //     File {i + 1}
                          //   </a>
                          // );
                        })}
                      </Grid>
                    );
                  }
                })}

              {/* {Object.keys(auditTrailData?.claimDetails).map((key,i) => {
                return (
                    <p key={i}>{key}</p>
                );
            })} */}
            </div>
          </Grid>
          <Grid item xs={12} md={6} sm={6} lg={6}>
            <div className="audit-trail-wrap-inner">
              <h4>Audit Trail</h4>
              <AuditStepper auditTrailData={mainAuditTrail} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default AuditTrail;
