import React, { useEffect, useState } from 'react';
import BenefitCalculationExpression from './benefit-calculation-expression.component';
import { FormFieldTypes } from '../../../../../../../constants/common';
import ApiSelectTag from '../../../../../../../atoms/Select/ApiSelectTag';
import { Box } from '@mui/material';
import {
  formattedTimeValue,
  getFormattedDate,
} from '../../../../../../../utils/formatter';
import DateField from '../../../../../../../atoms/DateField/DateField';

const BenefitCommonExpressionEditor = (props) => {
  const { setFormInfo, computeExpression, fieldList, configType } = props;
  const [value, setValue] = useState(computeExpression || '');

  useEffect(() => {
    setFormInfo(value);
  }, [value]);

  const handleFieldClick = (field) => {
    const { fieldColumnName } = field;
    setValue((prev) => {
      return `${prev || ''} info.${fieldColumnName}`;
    });
  };

  const handleFunctionClick = (field) => {
    const { refName } = field;
    setValue((prev) => {
      return `${prev || ''} ${refName}`;
    });
  };

  const handleCalculatorClick = (digit) => {
    setValue((prev) => {
      const lastChar = prev.charAt(prev.length - 1);
      const allowSpace =
        (isNaN(lastChar) && lastChar !== '.') ||
        (isNaN(digit) && digit !== '.');
      const newExpr = `${prev || ''}${allowSpace ? ` ${digit}` : `${digit}`}`;
      return newExpr;
    });
  };

  const handleBackClick = () => {
    setValue('');
  };

  const handleDropDown = (event) => {
    const { value, name } = event.target;
    setValue((prev) => {
      const updatedValue = {
        ...prev,
        [name]: value?.fieldColumnName,
      };
    
      if (updatedValue.value !== 'CUSTOM') {
        delete updatedValue.date;
      }
      if (updatedValue.value !== 'BLOCK_DOJ') {
        delete updatedValue.endDate;
      }
    
      return updatedValue;
    });
  };

  const handleDateChange = (value, name) => {
    const formattedVal = formattedTimeValue(value, 'YYYY-MM-DD');
    setValue((prev) => ({
      ...prev,
      [name]: formattedVal,
    }));
  };

  switch (configType) {
    case 'startDate': {
      const newList = [
        {
          id: '3',
          fieldDisplayName: 'Select Option',
          name: 'Select Option',
          fieldType: 'TXT',
          fieldDatatypeCode: 'NUM',
          fieldColumnName: null,
        },
        {
          id: '01',
          fieldDisplayName: 'Calender Year',
          name: 'startOfYear',
          fieldType: 'DT',
          fieldDatatypeCode: 'DT',
          fieldColumnName: 'START_OF_YEAR',
        },
        {
          id: '02',
          fieldDisplayName: 'Custom Date',
          name: 'custom',
          fieldType: 'DT',
          fieldDatatypeCode: 'DT',
          fieldColumnName: 'CUSTOM',
        },
        {
          id: '03',
          fieldDisplayName: 'Block From DOJ',
          name: 'blockDOJ',
          fieldType: 'DT',
          fieldDatatypeCode: 'DT',
          fieldColumnName: 'BLOCK_DOJ',
        },
        ...fieldList.filter((item) => item.fieldType === FormFieldTypes.Date),
      ];
      const newDateList = [
        {
          id: '3',
          fieldDisplayName: 'Select Option',
          name: 'Select Option',
          fieldType: 'TXT',
          fieldDatatypeCode: 'NUM',
          fieldColumnName: null,
        },
        {
          id: '1',
          fieldDisplayName: '12',
          name: '12',
          fieldType: 'TXT',
          fieldDatatypeCode: 'NUM',
          fieldColumnName: '12',
        },
        {
          id: '2',
          fieldDisplayName: '24',
          name: '24',
          fieldType: 'TXT',
          fieldDatatypeCode: 'NUM',
          fieldColumnName: '24',
        },
      ];
      const endDateList = [
        {
          id: '3',
          fieldDisplayName: 'End Of Year',
          name: 'endOfYear',
          fieldType: 'DT',
          fieldDatatypeCode: 'DT',
          fieldColumnName: 'END_OF_YEAR',
        },
        {
          id: '4',
          fieldDisplayName: 'End Of Next Year',
          name: 'endOfNextYear',
          fieldType: 'DT',
          fieldDatatypeCode: 'DT',
          fieldColumnName: 'END_OF_NEXT_YEAR',
        },
      ];
      return (
        <>
          <ApiSelectTag
            defaultValue={'Select Start Date'}
            fullWidth
            size="small"
            dropDownList={newList.map((item) => {
              return { ...item, name: item.fieldDisplayName };
            })}
            name="value"
            value={
              newList.find((item) => item.fieldColumnName === value?.value)
                ?.fieldDisplayName
            }
            onchange={handleDropDown}
          />
          {value?.value === 'CUSTOM' && (
            <>
              <Box margin={2} />
              <DateField
                placeholder="dd/mm/yyyy"
                name="date"
                value={
                  value?.date
                    ? getFormattedDate(value?.date, 'YYYY-MM-DD', 'DD/MM/YYYY')
                    : null
                }
                onChange={handleDateChange}
                fullWidth
              />
            </>
          )}
          <Box margin={2} />
          <ApiSelectTag
            defaultValue={'Select Block'}
            fullWidth
            size="small"
            dropDownList={newDateList.map((item) => {
              return { ...item, name: item.fieldDisplayName };
            })}
            name="block"
            value={
              newDateList.find((item) => item.fieldColumnName === value?.block)
                ?.fieldDisplayName
            }
            onchange={handleDropDown}
          />
          {value?.value === 'BLOCK_DOJ' && (
            <>
              <Box margin={2} />
              <ApiSelectTag
                defaultValue={'Select End Date'}
                fullWidth
                size="small"
                dropDownList={endDateList.map((item) => {
                  return { ...item, name: item.fieldDisplayName };
                })}
                name="endDate"
                value={
                  endDateList.find(
                    (item) => item.fieldColumnName === value?.endDate
                  )?.fieldDisplayName
                }
                onchange={handleDropDown}
              />
            </>
          )}
        </>
      );
    }
    default: {
      return (
        <>
          <BenefitCalculationExpression
            handleFieldClick={handleFieldClick}
            handleBackClick={handleBackClick}
            handleCalculatorClick={handleCalculatorClick}
            handleFunctionClick={handleFunctionClick}
            expressionVal={value}
            fieldList={fieldList}
          />
        </>
      );
    }
  }
};

export default BenefitCommonExpressionEditor;
