import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AutocompleteCheckbox from '../../../../atoms/Autocomplete/Checkboxes';
import { GoalsServices } from '../../../../services/goals';
import { clone } from 'lodash';
import { useSelector } from 'react-redux';

export default function AssignGoalModal(props) {

  const { setUserIds} = props;
  const [userOption, setUserOption] = useState([]);
  const [open, setOpen] = useState(false);
  const { currentUserRoleId } = useSelector((state) => state.main);

  const handleClickCompetency = () => {
    if (!userOption.length) {
      new GoalsServices().getAllUserOptin(currentUserRoleId).then((res) => {
        setUserOption(res);
        setOpen(true);
      });
    }
    setOpen(true);
  };

  return (
    <Box minWidth={420}>
      <Grid container columnSpacing={2} rowSpacing={3}>
        {/* <Grid item md={12} pt={2}>
          <AutocompleteCheckbox
            optionList={userOption}
            fieldLabel='Department'
            placeholder='Please Select'
            required
          />
        </Grid> */}
        <Grid item md={12} pt={2}>
          <AutocompleteCheckbox
            optionList={userOption}
            fieldLabel='User List'
            placeholder='Please Select'
            required={false}
            handleDropDownClick={handleClickCompetency}
            open={open}
            setOpen={setOpen}
            setIds={setUserIds}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
