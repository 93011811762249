import { getFormBody } from '../../components/OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { ComponentInputType } from '../../constants/common';
import { Payroll } from '../../services/payroll';
import { GET_ALL_PAYROLL_FAILURE, GET_ALL_PAYROLL_REQUEST, GET_ALL_PAYROLL_RUN_DATES_FAILURE, GET_ALL_PAYROLL_RUN_DATES_REQUEST, GET_ALL_PAYROLL_RUN_DATES_SUCCESS, GET_ALL_PAYROLL_SUCCESS, GET_PAYROLL_DETAILS_FAILURE, GET_PAYROLL_DETAILS_REQUEST, GET_PAYROLL_DETAILS_SUCCESS, POST_PAYROLL_INFO_FAILURE, POST_PAYROLL_INFO_REQUEST, POST_PAYROLL_INFO_SUCCESS, RESET_PAYROLL, SET_DATE_FILTER_PAYROLL, UPDATE_PAYROLL_INFO_FAILURE, UPDATE_PAYROLL_INFO_REQUEST, UPDATE_PAYROLL_INFO_SUCCESS } from './actionTypes';

const getPayrollFormDetailsRequest = () => (
  {
    type: GET_PAYROLL_DETAILS_REQUEST
  }
);

const getPayrollFormDetailsSuccess = (payload) => (
  {
    type: GET_PAYROLL_DETAILS_SUCCESS,
    payload
  }
);

const getPayrollFormDetailsFailure = () => (
  {
    type: GET_PAYROLL_DETAILS_FAILURE
  }
);

export const resetPayroll = () => (
  {
    type: RESET_PAYROLL
  }
);

const updatePayrollInfoRequest = () => (
  {
    type: UPDATE_PAYROLL_INFO_REQUEST
  }
);

const updatePayrollInfoSuccess = (payload) => (
  {
    type: UPDATE_PAYROLL_INFO_SUCCESS,
    payload
  }
);

const updatePayrollInfoFailure = () => (
  {
    type: UPDATE_PAYROLL_INFO_FAILURE
  }
);

const getAllPayrollRequest = () => (
  {
    type: GET_ALL_PAYROLL_REQUEST
  }
);

const getAllPayrollSuccess = (payload) => (
  {
    type: GET_ALL_PAYROLL_SUCCESS,
    payload
  }
);

const getAllPayrollFailure = () => (
  {
    type: GET_ALL_PAYROLL_FAILURE
  }
);

export const setDateFilterPayroll = (payload) => (
  {
    type: SET_DATE_FILTER_PAYROLL,
    payload
  }
);

const getAllPayrollRunDatesRequest = ()=>(
  {
    type: GET_ALL_PAYROLL_RUN_DATES_REQUEST,
  }
);

const getAllPayrollRunDatesSuccess = (payload)=>(
  {
    type: GET_ALL_PAYROLL_RUN_DATES_SUCCESS,
    payload
  }
);

const getAllPayrollRunDatesFailure = ()=>(
  {
    type: GET_ALL_PAYROLL_RUN_DATES_FAILURE
  }
);

export const getPayrollDetails = (roleId, payrollId, moduleId, isApprover) => async (dispatch) => {
  dispatch(getPayrollFormDetailsRequest());
  new Payroll().getPayrollDetails(roleId, payrollId, moduleId, isApprover).then((res) => {
    dispatch(getPayrollFormDetailsSuccess(res));
  }).catch((err) => {
    dispatch(getPayrollFormDetailsFailure());
  });
};

export const updatePayrollInfo = (additionComponent, deductionComponent, prlRegisterId, isAuditEdit, moduleId, employeeId, refId ) =>
  async (dispatch) => {
    dispatch(updatePayrollInfoRequest());
    const additionPayroll = additionComponent.filter((item) => item.inputSourceCode === ComponentInputType.Monthly);
    const deductionPayroll = deductionComponent.filter((item) => item.inputSourceCode === ComponentInputType.Monthly);
    const body = [...additionPayroll, ...deductionPayroll];
    const payroll = body.map((item) => ({
      valueId: item.valueId,
      value: item.value || 0,
      inputSourceCode: item.inputSourceCode,
    }));
    return new Payroll().updatePayrollInfo({prlRegisterId, payroll, isAuditEdit, moduleId, employeeId, refId }).then(() => {
      dispatch(updatePayrollInfoSuccess({ additionComponent, deductionComponent }));
      return true;
    }).catch((err) => {
      dispatch(updatePayrollInfoFailure());
      throw err?.response?.data?.message || 'Error Saving';
    });
  };

export const getAllPayroll = (currentUserRoleId, searchInput, startDate, endDate, employeeId, appModuleId, moduleId) => 
async (dispatch) => {
  dispatch(getAllPayrollRequest());
  new Payroll().getAllPayroll(currentUserRoleId, searchInput, startDate, endDate, employeeId, appModuleId, moduleId).then((res) => {
    dispatch(getAllPayrollSuccess(res));
  }).catch((err) => {
    dispatch(getAllPayrollFailure());
  });
};

export const getAllPayrollRunDates = (roleId,startDate,endDate) => 
  async (dispatch) => {
    dispatch(getAllPayrollRunDatesRequest());
    new Payroll().getAllPayrollRunDates(roleId,startDate,endDate).then((res) => {
      dispatch(getAllPayrollRunDatesSuccess(res));
    }).catch((err) => {
      dispatch(getAllPayrollRunDatesFailure());
    });
  };