import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { SubModuleConfigure } from './subModuleConfigure';
import AccordionTabs from '../../../../../atoms/Accordian/Accordian';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRolesConfig,
  resetRolesConfig,
  updateRolePermissions,
} from '../../../../../redux/rolesAndPermissions/action';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import {
  APIRequestStatus,
  LoadingStatus,
  ToastThemes,
} from '../../../../../constants/common';
import { isEmpty } from 'lodash';
import { showToast } from '../../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../constants/onboarding.constants';
import ActionHeader from '../../ActionHeader/ActionHeader';
import InputField from '../../../../../atoms/Input/Input';
import { getRolesList } from '../../../../../redux/onboarding/action';

export const EditSettings = (props) => {
  const { roleConfig, setShowConfigure, onClickDiscard } = props;
  const { roleName, id: roleId } = roleConfig;
  const dispatch = useDispatch();
  const { roleConfig: rolePermissions, requestProcessing } = useSelector(
    (state) => state.rolesAndPermissions
  );
  const {
    [APIRequestStatus.GET_ROLE_CONFIG]: roleConfigLoad,
    [APIRequestStatus.UPDATE_ROLE_PERMISSIONS]: updatePermissionsLoad,
  } = requestProcessing;
  const [modulePermissions, setModulePermissions] = useState([]);
  const [role_name, setRoleName] = useState(roleName);

  useEffect(() => {
    setRoleName(roleName);
  }, [roleName]);

  useEffect(() => {
    if (updatePermissionsLoad === LoadingStatus.Success) {
      setShowConfigure(false);
      if(roleName !== role_name){
      dispatch(getRolesList());
      }
      showToast(
        ToastMessages.success.replace(stringSubstitute, 'Saved'),
        ToastThemes.success
      );
    } else if (updatePermissionsLoad === LoadingStatus.Failure) {
      showToast(
        ToastMessages.failed.replace(stringSubstitute, 'save'),
        ToastThemes.error
      );
    }
  }, [updatePermissionsLoad]);
  useEffect(() => {
    if (!rolePermissions) {
      dispatch(getRolesConfig(roleId));
    }
    return () => dispatch(resetRolesConfig());
  }, []);

  return (
    <Box>
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={roleName}
          showDiscard={true}
          editButtonText="Save"
          onEditClick={() =>
            dispatch(updateRolePermissions(roleId, modulePermissions, roleName !== role_name? role_name: null))
          }
          disabledSave={isEmpty(modulePermissions) && roleName === role_name}
          onClickDiscard={onClickDiscard}
        />
      </Box>
      <CircularLoader show={roleConfigLoad === LoadingStatus.Loading} />
      <Grid container rowSpacing={1} columnSpacing={1} pb={1}>
        <Grid item md={4}>
          <InputField
            labelText="Name"
            size="small"
            fullWidth
            name="role_name"
            value={role_name}
            onChange={(e) => {setRoleName(e.target.value);}}
          />
        </Grid>
      </Grid>
      {rolePermissions?.map((item) => {
        const { id, name, child, displayName } = item;
        return (
          <AccordionTabs
            key={id}
            title={displayName}
            tabDetails={
              <SubModuleConfigure
                content={child}
                setModulePermissions={setModulePermissions}
                parentName={name}
                roleId={roleId}
                modulePermissions={modulePermissions}
                moduleId={id}
                roleName={roleName}
              />
            }
          />
        );
      })}
    </Box>
  );
};
