import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import { GET_ROLES_CONFIG_FAILURE, GET_ROLES_CONFIG_REQUEST, GET_ROLES_CONFIG_SUCCESS, RESET_ROLES_CONFIG, UPDATE_ROLE_PERMISSIONS_FAILURE, UPDATE_ROLE_PERMISSIONS_REQUEST, UPDATE_ROLE_PERMISSIONS_SUCCESS } from './actionTypes';

const initialState = {
    roleConfig: null,
    requestProcessing: {
        [APIRequestStatus.GET_ROLE_CONFIG]: LoadingStatus.Loading,
        [APIRequestStatus.UPDATE_ROLE_PERMISSIONS]: LoadingStatus.Loading
    }
};

const rolesAndPermissionsReducer = (state=initialState, action) => {
    switch(action.type) {
        case GET_ROLES_CONFIG_REQUEST: {
            return {
                ...state,
                requestProcessing: {
                    ...state.requestProcessing,
                [APIRequestStatus.GET_ROLE_CONFIG]: LoadingStatus.Loading
            }
            };
        }
        case GET_ROLES_CONFIG_SUCCESS: {
            const config = action.payload;
            return {
                ...state,
                roleConfig: config,
                requestProcessing: {
                    ...state.requestProcessing,
                [APIRequestStatus.GET_ROLE_CONFIG]: LoadingStatus.Success
            }
            };
        }
        case GET_ROLES_CONFIG_FAILURE: {
            return {
                ...state,
                requestProcessing: {
                    ...state.requestProcessing,
                [APIRequestStatus.GET_ROLE_CONFIG]: LoadingStatus.Failure
            }
            };
        }

        case RESET_ROLES_CONFIG: {
            return initialState;
        }

        case UPDATE_ROLE_PERMISSIONS_REQUEST: {
            return {
                ...state,
                requestProcessing: {
                    ...state.requestProcessing,
                [APIRequestStatus.UPDATE_ROLE_PERMISSIONS]: LoadingStatus.Loading
            }
            };
        }
        case UPDATE_ROLE_PERMISSIONS_SUCCESS: {
            const config = action.payload;
            return {
                ...state,
                roleConfig: config,
                requestProcessing: {
                    ...state.requestProcessing,
                [APIRequestStatus.UPDATE_ROLE_PERMISSIONS]: LoadingStatus.Success
            }
            };
        }
        case UPDATE_ROLE_PERMISSIONS_FAILURE: {
            return {
                ...state,
                requestProcessing: {
                    ...state.requestProcessing,
                [APIRequestStatus.UPDATE_ROLE_PERMISSIONS]: LoadingStatus.Failure
            }
            };
        }

        default: return initialState;
    }
};

export default rolesAndPermissionsReducer;