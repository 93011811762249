import { Autocomplete, Box } from '@mui/material';
import React from 'react';
import { StyledTextField } from './tab-bar.styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SearchTab = (props) => {
    const {list, onChange=()=>{}, width=200} = props;
  return (
    <Box width={width} sx={{ position: 'relative' }} mr={2}>
    <Autocomplete
      id='free-solo-demo'
      freeSolo
      options={list}
      onChange={onChange}
      getOptionLabel={(option) => option.title }
      renderInput={(params) => (
        <StyledTextField
          size='small'
          {...params}
          placeholder='Search Tab'
        />
      )}
      sx={{width: 'inherit'}}
    />
    <ExpandMoreIcon
      sx={{
        position: 'absolute',
        top: '11px',
        right: '10px',
      }}
    />
  </Box>
  );
};

export default SearchTab;