import React from 'react';
import { Box, Chip, Paper, Typography } from '@mui/material';
import StatusLabel from '../../../../../molecules/StatusLabel/StatusLabel';
import { STATUS_ICON } from '../../ApprovalWorkflow_helpers/auditLogHelper';
import {
  getSGTTimeZone,
  getUtcWithoutFormatFrom,
} from '../../../../../../utils/formatter';
import { DateFormatters } from '../../../../../../constants/dateConstants';

export default function AuditLogApprovalWorkflow(props) {
  const { key, log, rejectedRecord, index, defaultTimezone } = props;

  return (
    <>
      <Box
        key={key}
        maxWidth={360}
        mb={2}
        sx={{
          opacity:
            index > rejectedRecord && rejectedRecord !== -1 ? '70%' : '100%',
        }}
      >
        <Paper>
          <Box p={1} position="relative">
            <StatusLabel
              value={log.status}
              strickedDown={index > rejectedRecord && rejectedRecord !== -1}
              type={String(log.status).toLowerCase()}
            />
            <Paper
              sx={{
                position: 'absolute',
                left: -68,
                top: 0,
                width: 40,
                height: 40,
                borderRadius: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {STATUS_ICON[log.status]}
            </Paper>
            <Typography variant="body2" color={'neutral.dark100'} mt={1} mb={0}>
              <b>Name:</b>
              {log.createdBy
                ? `${log.createdBy?.firstName} ${log.createdBy?.lastName}`
                : log.designation}
            </Typography>
            <Typography
              variant="body3"
              color={'neutral.dark70'}
              mt={1}
              mb={0}
              sx={{
                wordWrap: 'break-word',
              }}
            >
              <b>Remarks:</b>
              {log.remark || ''}
            </Typography>
            {log.updatedUtc && log.status != 'PENDING' && (
              <>
                {log?.reasons?.length > 0 && (
                  <Box>
                    <Typography
                      variant="body3"
                      color={'neutral.dark70'}
                      mt={1}
                      mb={0}
                    >
                      <b>Reasons:</b>
                      {log.reasons?.map((item) => item).join(', ')}
                    </Typography>
                  </Box>
                )}
                <Box>
                  <Typography variant="body3" color={'neutral.dark60'} mb={0}>
                    <b>Date:</b>
                    {`${getSGTTimeZone(
                      log.updatedUtc,
                      DateFormatters.DateTime24Hour,
                      defaultTimezone
                    )} ${defaultTimezone === 'Asia/Singapore' ? 'SGT' : 'UTC'}`}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Paper>
      </Box>
    </>
  );
}
