import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { FormHeader } from '../../../common/formHeader';
import ListView from '../../../../../atoms/DataList/ListView';
import { payStructureListHeader } from '../../utils/onboarding.util';
import DateRange from '../../../../molecules/DateRange/DateRange';
import ModalMapForPayStr from './ActionButton/ModalMapForPayStr';
import AddPayStr from '../PayStructureForm/AddPayStr';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { APIRequestStatus, LoadingStatus } from '../../../../../constants/common';
import { getAllSalaryStructureList } from '../../../../../redux/salaryStructure/action';
import moment from 'moment';
import { IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import TryAgain from '../../../../molecules/ErrorState/error.component';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import { searchQuery } from '../../../../../constants/constants';
import { payStructure } from '../../constants/onboarding.constants';
import { permissionsObj } from '../../RolesAndPermissions/constants/roles-permissions.constants';


const AllStructure = () => {
	const [checkedItems, setCheckedItems] = useState([]);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [viewMode, setViewMode] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [strucutreId, setStructureId] = useState();
	const [searchParams] = useSearchParams();
	const dispatch = useDispatch();
	const { currentUserRoleId } = useSelector((state) => state.main);
	const { salaryStructureList,
		requestProcessing: { [APIRequestStatus.GET_ALL_SALARY_STRUCTURE_LIST]: loadingStatus }
	} = useSelector((state) => state.salaryStructure);
	const searchInput = searchParams.get(searchQuery);

	const { permissions } = useSelector((state) => state.onBoarding);

	useEffect(() => {
		if (currentUserRoleId && !showForm) {
			dispatch(getAllSalaryStructureList(currentUserRoleId, searchInput));
		}
	}, [currentUserRoleId, showForm]);

	useEffect(() => {
		setCheckedItems([]);
	}, [salaryStructureList]);

	const onClickAddIcon = () => {
		setShowForm(true);
		setStructureId('');
	};

	const onDetailsClick = (id) => {
		setShowForm(true);
		setViewMode(true);
		setStructureId(id);
	};

	const rowData = salaryStructureList?.filter((item) => {
		const { createdUtc } = item;
		return (startDate && endDate) ? (moment(createdUtc, 'YYYY-MM-DD').format() >= moment(startDate).format() &&
			moment(createdUtc, 'YYYY-MM-DD').format() <= moment(endDate).format()) : item;
	}).map((items) => {
		const { id, name, salaryType, employees, additionCount, deductionCount, statusCode } = items;
		return ({
			name: name,
			base: salaryType,
			assignee: '',
			add: additionCount,
			sub: deductionCount,
			status: statusCode,
			action:!permissions?.[payStructure]?.[permissionsObj.allowEdit] ? {hide:true}:
				<IconButton onClick={() => onDetailsClick(id)}
				disabled={!permissions?.[payStructure]?.[permissionsObj.allowEdit]}>
					<InfoOutlined
						sx={{ color: 'primary.main80' }}
					/>
				</IconButton>,
			id: {
				hide: true,
				value: id
			}
		});
	});
	if (loadingStatus === LoadingStatus.Failure) {
		return <TryAgain />;
	}
	return (
		<>
			<CircularLoader show={loadingStatus === LoadingStatus.Loading} />
			{showForm ?
				<AddPayStr viewMode={viewMode} setViewMode={setViewMode} strucutreId={strucutreId} setShowForm={setShowForm} /> :
				<Box>
					<Box display='flex' justifyContent='space-between'>
						<FormHeader title='Pay Structure' />
						<Box display='flex' justifyContent='flex-end' gap={2} mb={2} pr={2}>
							<DateRange startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
							<Box>
								<ModalMapForPayStr checkedItems={checkedItems} searchInput={searchInput} />
							</Box>
						</Box>
					</Box>
					<ListView
						headCells={payStructureListHeader}
						rowsData={rowData}
						showSerialNo={true}
						showCheckbox={true}
						setCheckedItems={setCheckedItems}
						checkedItems={checkedItems}
						showAddIcon={permissions?.[payStructure]?.[permissionsObj.allowAdd]}
						onAddClick={() => onClickAddIcon()}
					/>
				</Box>
			}
		</>
	);
};

export default AllStructure;