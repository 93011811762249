import { clone, cloneDeep } from 'lodash';
import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import {
  ADD_KRA_TAB,
  CALCULATE_TOTAL_SCORE,
  DELETE_KRA_TAB,
  DISCARD_DATA,
  GET_ALL_GOALS_LIST_FAILURE,
  GET_ALL_GOALS_LIST_REQUEST,
  GET_ALL_GOALS_LIST_SUCCESS,
  GET_GOALS_CONFIG_FAILURE,
  GET_GOALS_CONFIG_REQUEST,
  GET_GOALS_CONFIG_SUCCESS,
  POST_GOALS_INFO_FAILURE,
  POST_GOALS_INFO_REQUEST,
  POST_GOALS_INFO_SUCCESS,
  RESET_GOALS,
  UPDATE_KRA_DATA,
  UPDATE_KRA_DATA_PERFORMANCE,
  GET_ALL_APPRAISAL_LIST_FAILURE,
  GET_ALL_APPRAISAL_LIST_REQUEST,
  GET_ALL_APPRAISAL_LIST_SUCCESS,
  GET_APPRAISAL_DETAILS_REQUEST,
  GET_APPRAISAL_DETAILS_SUCCESS,
  GET_APPRAISAL_DETAILS_FAILURE,
  POST_APPRAISAL_DETAILS_REQUEST,
  POST_APPRAISAL_DETAILS_FAILURE,
  POST_APPRAISAL_DETAILS_SUCCESS,
  DUPLICATE_KRA_TAB,
  MANGER_ACCES_READ_ONLY,
  SCORE_GRADE,
} from './actionTypes';
import {
  AppraisalStatus,
  CompetencyFormFieldNames,
  GoalFormFieldNames,
  KRAFormFieldNames,
  KRAPerformanceFormFieldNames,
  TargetPointsFormFieldNames,
} from '../../components/Performance/Utlis/goals.util';

const initialState = {
  requestProcessing: {
    [APIRequestStatus.GET_GOALS_CONFIG]: LoadingStatus.Loading,
    [APIRequestStatus.POST_GOALS_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.GET_ALL_GOALS_LIST]: LoadingStatus.Loading,
    [APIRequestStatus.GET_ALL_APPRAISAL_LIST]: LoadingStatus.Loading,
    [APIRequestStatus.GET_APPRAISAL_DETAILS]: LoadingStatus.Loading,
  },
  goalsConfig: [],
  goalsFormData: {},
  kraConfig: [],
  kraFormData: [{ targetPoints: [] }],
  kraFormDataLocal: [{ targetPoints: [] }],
  goalsList: [],
  competencyConfig: {},
  competencyData: [],
  competencyDataLocal: [],
  targetConfig: [],
  allowCreate: false,
  appraisalList: [],
  performanceConfig: [],
  totalScoreConfig: [],
  totalScoreData: {},
  auditTrail: [],
  goalInfo: {},
  assignedTo: {},
  kraRatingScale: 1,
  competencyRatingScale: 1,
  gradeScore: [],
};

export const goalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GOALS_CONFIG_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_GOALS_CONFIG]: LoadingStatus.Loading,
        },
      };
    }

    case GET_GOALS_CONFIG_SUCCESS: {
      const { form, goalId, ratingScales } = action.payload;
      const { kraRatingScale } = ratingScales;
      const { goalInfo, formConfig } = form;
      const { goal, kra, competency } = formConfig;
      const { kraFormConfig, targetPointsFormConfig } = kra;
      if (goalId) {
        return getStateOnGoalDetailsSuccess(
          state,
          formConfig,
          goalInfo,
          ratingScales
        );
      }

      const targetConfig = [];
      const targetPoints = [];
      for (let i = 0; i < kraRatingScale; i++) {
        targetConfig.push([...targetPointsFormConfig]);
        targetPoints.push({ [TargetPointsFormFieldNames.Point]: i + 1 });
      }
      return {
        ...state,
        goalsConfig: goal,
        kraConfig: [[...kraFormConfig]],
        kraFormData: [{ targetPoints }],
        kraFormDataLocal: [{ targetPoints }],
        targetConfig: targetConfig,
        competencyConfig: competency[0],
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_GOALS_CONFIG]: LoadingStatus.Success,
        },
      };
    }

    case GET_GOALS_CONFIG_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_GOALS_CONFIG]: LoadingStatus.Failure,
        },
      };
    }

    case POST_GOALS_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_GOALS_INFO]: LoadingStatus.Loading,
        },
      };
    }

    case POST_GOALS_INFO_SUCCESS: {
      const { goalFormInfo, kraFormInfo, competencyFormInfo, id } =
        action.payload;
      id && Object.assign(goalFormInfo, { id });
      return {
        ...state,
        goalsFormData: goalFormInfo,
        kraFormData: kraFormInfo,
        competencyData: competencyFormInfo,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_GOALS_INFO]: LoadingStatus.Success,
        },
      };
    }

    case POST_GOALS_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_GOALS_INFO]: LoadingStatus.Failure,
        },
      };
    }

    case GET_ALL_GOALS_LIST_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_GOALS_LIST]: LoadingStatus.Loading,
        },
      };
    }

    case GET_ALL_GOALS_LIST_SUCCESS: {
      const goals = action.payload;
      return {
        ...state,
        goalsList: goals,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_GOALS_LIST]: LoadingStatus.Success,
        },
      };
    }

    case GET_ALL_GOALS_LIST_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_GOALS_LIST]: LoadingStatus.Failure,
        },
      };
    }

    case RESET_GOALS: {
      return initialState;
    }

    case ADD_KRA_TAB: {
      const kraRatingScale = state.kraFormData[0].targetPoints.length;
      const targetPoints = [];
      for (let i = 0; i < kraRatingScale; i++) {
        targetPoints.push({ [TargetPointsFormFieldNames.Point]: i + 1 });
      }
      return {
        ...state,
        kraConfig: [...state.kraConfig, state.kraConfig[0]],
        kraFormData: [...state.kraFormData, { targetPoints: targetPoints }],
        kraFormDataLocal: [
          ...state.kraFormDataLocal,
          { targetPoints: targetPoints },
        ],
      };
    }

    case DELETE_KRA_TAB: {
      const index = action.payload;
      const newKraConfig = [...state.kraConfig];
      const newKraData = [...state.kraFormData];
      const newKraDataLocal = [...state.kraFormDataLocal];
      newKraConfig.splice(index, 1);
      newKraData.splice(index, 1);
      newKraDataLocal.splice(index, 1);
      return {
        ...state,
        kraConfig: newKraConfig,
        kraFormData: newKraData,
        kraFormDataLocal: newKraDataLocal,
      };
    }

    case UPDATE_KRA_DATA: {
      const { index, formData } = action.payload;
      const { kraFormInfo, targetFormInfo } = formData;
      return {
        ...state,
        kraFormDataLocal: state.kraFormDataLocal.map((item, ind) => {
          if (ind === index) {
            return { ...kraFormInfo, targetPoints: targetFormInfo };
          }
          return item;
        }),
      };
    }

    case DISCARD_DATA: {
      return {
        ...state,
        kraFormDataLocal: state.kraFormData,
      };
    }

    case UPDATE_KRA_DATA_PERFORMANCE: {
      const { kraIndex, formData } = action.payload;
      return {
        ...state,
        kraFormDataLocal: state.kraFormDataLocal.map((item, ind) => {
          if (ind === kraIndex) {
            return { ...item, kraPerformance: formData };
          }
          return item;
        }),
      };
    }

    case MANGER_ACCES_READ_ONLY: {
      const managerAccess = action.payload;
      if (managerAccess) {
        const totalScoreConfig = state.totalScoreConfig.map((item) => {
          return { ...item, isReadOnly: true };
        });
        const performanceConfig = state.performanceConfig.map((item) => {
          if (item.fieldRefName === CompetencyFormFieldNames.AppraiserRemarks) {
            return { ...item, isReadOnly: false };
          }
          return { ...item, isReadOnly: true };
        });
        console.log(performanceConfig);
        return {
          ...state,
          totalScoreConfig: totalScoreConfig,
          performanceConfig: [...performanceConfig],
        };
      }
      break;
    }
    case CALCULATE_TOTAL_SCORE: {
      const competencyData = action.payload;
      const kraScore =
        (!competencyData &&
          CalcTotalScoreKra(
            state.kraFormDataLocal,
            state.goalsFormData[GoalFormFieldNames.KraWeightage],
            state.kraRatingScale
          )) ||
        state.totalScoreData.totalKraScore ||
        0;
      const competencyScore =
        (competencyData &&
          CalcTotalScoreCompetency(
            competencyData,
            state.competencyRatingScale,
            state.goalsFormData[GoalFormFieldNames.CompetencyWeightage]
          )) ||
        state.totalScoreData.totalCompetencyScore ||
        0;
      const { grade, status, id } = getOverallGradeAndStatus(
        kraScore + competencyScore,
        state.gradeScore
      );
      return {
        ...state,
        totalScoreData: {
          totalKraScore: kraScore,
          totalCompetencyScore: competencyScore,
          totalScoreOverall: kraScore + competencyScore,
          overallGrade: grade || '',
          overallStatus: status || '',
          fkScoreGrade: id
        },
      };
    }
    case GET_ALL_APPRAISAL_LIST_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_APPRAISAL_LIST]: LoadingStatus.Loading,
        },
      };
    }
    case GET_ALL_APPRAISAL_LIST_SUCCESS: {
      const appraisals = action.payload;
      return {
        ...state,
        
        appraisalList: appraisals,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_APPRAISAL_LIST]: LoadingStatus.Success,
        },
      };
    }

    case GET_ALL_APPRAISAL_LIST_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_APPRAISAL_LIST]: LoadingStatus.Failure,
        },
      };
    }

    case GET_APPRAISAL_DETAILS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_APPRAISAL_DETAILS]: LoadingStatus.Loading,
        },
      };
    }
    case GET_APPRAISAL_DETAILS_SUCCESS: {
      const { form, goalId, ratingScales } = action.payload;
      const { goalInfo, formConfig, auditTrail } = form;
      return getStateOnAppraisalDetailsSuccess(
        state,
        formConfig,
        goalInfo.fkGoal,
        goalInfo || {},
        ratingScales,
        auditTrail || []
      );
    }

    case GET_APPRAISAL_DETAILS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_APPRAISAL_DETAILS]: LoadingStatus.Failure,
        },
      };
    }

    case POST_APPRAISAL_DETAILS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_APPRAISAL_DETAILS]: LoadingStatus.Loading,
        },
      };
    }

    case POST_APPRAISAL_DETAILS_SUCCESS: {
      const { competencyData, kraData } = action.payload;
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_APPRAISAL_DETAILS]: LoadingStatus.Success,
        },
        kraFormData: kraData,
        competencyData: competencyData,
        goalInfo: {
          ...state.goalInfo,
          fkGoalStatus: {
            ...state.goalInfo.fkGoalStatus,
            id: AppraisalStatus.Submitted,
          },
        },
      };
    }

    case POST_APPRAISAL_DETAILS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_APPRAISAL_DETAILS]: LoadingStatus.Failure,
        },
      };
    }

    case DUPLICATE_KRA_TAB: {
      const tabIndex = action.payload;
      const newTabData = cloneDeep(state.kraFormDataLocal[tabIndex]);
      const { targetPoints, ...kraData } = newTabData;
      const newTargetPoints = targetPoints.map((item) => {
        delete item.id;
        return { ...item };
      });
      Object.assign(newTabData, kraData, { targetPoints: newTargetPoints });
      delete newTabData.id;
      return {
        ...state,
        kraConfig: [...state.kraConfig, state.kraConfig[0]],
        kraFormData: [...state.kraFormData, newTabData],
        kraFormDataLocal: [...state.kraFormDataLocal, newTabData],
      };
    }

    case SCORE_GRADE: {
      const list = action.payload;
      return {
        ...state,
        gradeScore: list,
      };
    }

    default: {
      return state;
    }
  }
};

const getStateOnGoalDetailsSuccess = (
  state,
  formConfig,
  goalInfo,
  ratingScales
) => {
  const { kraRatingScale } = ratingScales;
  const { kra: kraFormData, goalCompetencies, ...rest } = goalInfo;
  const newState = clone(state);
  const { goal, kra, competency } = formConfig;
  const { kraFormConfig, targetPointsFormConfig } = kra;

  const { newKraFormData, newKraFormConfig, newTargetConfig } =
    getKraFormDataAndConfig(
      kraFormData,
      kraFormConfig,
      targetPointsFormConfig,
      kraRatingScale
    );

  newState.goalsFormData = rest;
  newState.goalsConfig = goal;
  newState.kraConfig = newKraFormConfig;
  newState.competencyConfig = competency[0];
  newState.competencyData = goalCompetencies;
  newState.kraFormData = newKraFormData;
  newState.kraFormDataLocal = newKraFormData;
  newState.targetConfig = newTargetConfig;
  newState.requestProcessing[APIRequestStatus.GET_GOALS_CONFIG] =
    LoadingStatus.Success;
  return newState;
};

const getKraFormDataAndConfig = (
  kraFormData,
  kraFormConfig,
  targetConfig,
  kraRatingScale
) => {
  let newKraFormData = [];
  const newKraFormConfig = [];
  let newTargetConfig = [];
  kraFormData.forEach((kraData, index) => {
    const { targetPoints } = kraData;
    newKraFormConfig.push([...kraFormConfig]);
    newKraFormData[index] = { ...kraData };
    if (!newTargetConfig.length) {
      newTargetConfig = getTargetPointConfig(
        targetPoints,
        targetConfig,
        kraRatingScale
      );
    }
  });
  return { newKraFormData, newKraFormConfig, newTargetConfig };
};

const getTargetPointConfig = (targetData, targetConfig) => {
  const newTargetConfig = [];
  if (targetData.length > 0) {
    targetData.forEach(() => {
      newTargetConfig.push([...targetConfig]);
    });
  } else {
    newTargetConfig.push([...targetConfig]);
  }
  return newTargetConfig;
};

const CalcTotalScoreKra = (kraData, totalWeightageKra, maxScore) => {
  let totalWeightedScore = 0;
  const { targetPoints } = kraData[0];
  const totalWeightage = kraData.reduce(
    (total, kra) => total + kra[KRAFormFieldNames.Weightage],
    0
  );
  kraData.forEach((formData) => {
    const { kraPerformance, [KRAFormFieldNames.Weightage]: kraWeightage } =
      formData;
    const score = kraPerformance?.[KRAPerformanceFormFieldNames.Rating] || 0;
    totalWeightedScore +=
      (score / (targetPoints?.length || maxScore)) *
      (kraWeightage / totalWeightage);
  });
  return Math.floor(totalWeightedScore * totalWeightageKra);
};

const getStateOnAppraisalDetailsSuccess = (
  state,
  formConfig,
  goalInfoFkGoal,
  goalInfo,
  ratingScales,
  auditTrail
) => {
  const { kraRatingScale, competencyRatingScale } = ratingScales;
  const { kra: kraFormData, goalCompetencies, ...rest } = goalInfoFkGoal;
  const newState = clone(state);
  const { goal, kra, competency, totalScoreFormConfig } = formConfig;
  const { kraFormConfig, targetPointsFormConfig, performanceFormConfig } = kra;
  const { newKraFormData, newKraFormConfig, newTargetConfig } =
    getKraFormDataAndConfigAppraisal(
      kraFormData,
      kraFormConfig,
      targetPointsFormConfig,
      performanceFormConfig
    );
  newState.goalsFormData = rest;
  newState.goalsConfig = goal;
  newState.kraConfig = newKraFormConfig;
  newState.competencyConfig = competency[0];
  newState.competencyData = goalCompetencies;
  newState.kraFormData = newKraFormData;
  newState.kraFormDataLocal = newKraFormData;
  newState.targetConfig = newTargetConfig;
  newState.performanceConfig = [...performanceFormConfig];
  newState.totalScoreConfig = totalScoreFormConfig;
  newState.requestProcessing[APIRequestStatus.GET_APPRAISAL_DETAILS] =
    LoadingStatus.Success;
  newState.auditTrail = [...auditTrail];
  newState.goalInfo = goalInfo;
  newState.assignedTo = goalInfo.user;
  newState.kraRatingScale = kraRatingScale;
  newState.competencyRatingScale = competencyRatingScale;
  return newState;
};

const getKraFormDataAndConfigAppraisal = (
  kraFormData,
  kraFormConfig,
  targetConfig
) => {
  let newKraFormData = [];
  const newKraFormConfig = [];
  let newTargetConfig = [];
  kraFormData.forEach((kraData, index) => {
    const { targetPoints } = kraData;
    const { kraSubmission, ...rest } = kraData;
    newKraFormConfig.push([...kraFormConfig]);
    newKraFormData[index] = { ...rest, kraPerformance: kraSubmission || {} };
    if (!newTargetConfig.length) {
      newTargetConfig = getTargetPointConfig(targetPoints, targetConfig);
    }
  });
  return { newKraFormData, newKraFormConfig, newTargetConfig };
};

const CalcTotalScoreCompetency = (data, maxVal = 4, weightage) => {
  let score = 0;
  data?.forEach((item) => {
    const { [CompetencyFormFieldNames.Weightage]: compWeightage } = item;
    const rating =
      item?.[CompetencyFormFieldNames.Submission]?.[
        CompetencyFormFieldNames.Rating
      ] || 0;
    score += (rating / maxVal) * (compWeightage / 100);
  });
  return Math.floor(score * weightage);
};

const getOverallGradeAndStatus = (totalScore, gradeScore = []) => {
  let grade, status, id;

  if (!totalScore) {
    return { grade: '', status: '', id: null };
  }

  for (const range of gradeScore) {
    if (
      (range.minValue === null || totalScore >= range.minValue) &&
      (range.maxValue === null || totalScore <= range.maxValue)
    ) {
      grade = range.grade || '';
      status = range.status || '';
      id = range.id || null;
      break;
    }
  }

  return { grade, status, id };
};
