import { Box, Container, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CardUI from '../../../shared/components/ui-elements/CardUI';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Footer from '../../footer/Footer';
import PersonIcon from '@mui/icons-material/Person';
import { Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Modal from '../../../shared/components/ui-elements/Modal';
import ButtonUI from '../../../shared/components/ui-elements/ButtonUI';
import { useNavigate } from 'react-router-dom';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import {
  generateCurrentTimeStamp,
  generateFormData,
  disablePrevDates,
  validateNumber,
  checkFilesCountHandler,
} from '../common-services/functions';
import { postFilesData, putFormData } from '../common-services/services';
import UserProfile from '../../../shared/components/claim-forms/UserProfile';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import ReportingDetails from '../../../shared/components/claim-forms/ReportingDetails';

const ExpensesEdit = () => {
  const params = useLocation();
  // const uniqueNum = Math.floor(Math.random() * 10000000000) + 10000000000;
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [claimsData, setClaimsData] = useState(params.state.claimsData);
  const [successMessage, setSuccessMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');

  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    reportingOfficer: 'Kelvin Lionel',
    hod: 'Benjamin Koh',
    costCenter: 'Singapore Labour',
    claimReferenceNumber: claimsData.referenceNumber,
    nameOfClaimant: '',
    personnelNo: '',
    designation: '',
    department: '',
    totalPaymentAmount: claimsData.amount,
    descriptionReasonRemarks: claimsData.description,
    claimDate: claimsData.claimDate,
  });

  const [formType, setFormType] = useState({});

  useEffect(() => {
    // let formId = formDetails && formDetails.id;
    let formDetails = {
      name: params.state.name,
      id: params.state.id,
    };
    setFormType(formDetails);
  }, [params]);

  // const [type] = useState(params.state.type);
  let userData = JSON.parse(localStorage.getItem('userData'));
  let asProfile = localStorage.getItem('asProfile');

  const handleChangeInput = (e, key = null, key1) => {
    const values = { ...inputs };
    if (key) values[key] = e;
    else {
      if (key1) {
        const validate = validateNumber(e.target.name, e.target.value);
        if (!validate) {
          return false;
        }
      }
      values[e.target.name] = e.target.value;
    }
    setInputs(values);
  };

  const cardData = [
    {
      post: 'Reporting Officer',
      name:
        userData.userDetails.reportingTo.firstName +
        ' ' +
        userData.userDetails.reportingTo.lastName,
    },
    {
      post: 'HOD',
      name: userData.userDetails.HOD,
    },
    {
      post: 'Cost Center',
      name: userData.userDetails.costCenter,
    },
  ];

  const validateFields = () => {
    if (
      inputs.totalPaymentAmount == undefined ||
      inputs.totalPaymentAmount == null ||
      inputs.totalPaymentAmount == ''
    ) {
      window.alert('Please Enter A Total Payment Amount!');
      return false;
    }
    if (
      inputs.descriptionReasonRemarks == undefined ||
      inputs.descriptionReasonRemarks == null ||
      inputs.descriptionReasonRemarks == ''
    ) {
      window.alert('Please Enter A Description Reason Remarks');
      return false;
    }
    if (
      inputs.claimDate == undefined ||
      inputs.claimDate == null ||
      inputs.claimDate == ''
    ) {
      window.alert('Please Enter claimDate Date !');
      return false;
    }
    return true;
  };

  const generateDataForClaimRequest = (
    inputs,
    userData,
    dateTime,
    callType
  ) => {
    let finalFormData = [];
    let updateFormObject = {};
    let callForPost = callType;

    if (inputs) {
      return (updateFormObject = {
        typeId: formType && Number(formType.id),
        id: claimsData.id,
        employee: userData && userData.userDetails.id,
        claimReferenceNumber: inputs.claimReferenceNumber,
        nameOfClaimant: inputs.nameOfClaimant,
        personnelNo: inputs.personnelNo,
        designation: inputs.designation,
        department: inputs.department,
        amount: Number(inputs.totalPaymentAmount),
        description: inputs.descriptionReasonRemarks,
        claimDate: moment(inputs.claimDate).format('yyyy-MM-DD'),
        filePaths: [...claimsData.attachment],
        status: callForPost,
      });
    }
  };

  const submitData = async (callType) => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    setFormSubmitted(true);
    setButtonClicked(callType);
    const dateTime = generateCurrentTimeStamp();

    let validate = validateFields();

    if (!validate) {
      setFormSubmitted(false);
      return;
    }

    let finalFormData = generateDataForClaimRequest(
      inputs,
      userData,
      dateTime,
      callType
    );

    // POST call for submit form data.
    let businessTravelsResponse = await putFormData(finalFormData);

    if (businessTravelsResponse === 201) {
      let successMessage = '';
      if (callType === 'PENDING') {
        successMessage = 'Claim request submitted successfully !';
      } else {
        successMessage = 'Draft saved successfully !';
      }
      setFormSubmitted(false);
      openSuccessPopUp(successMessage);
    } else {
      setFormSubmitted(false);
      window.alert('Something went wrong');
    }
  };

  // On file select (from the pop up)
  const onFileChange = async (e, refNumber, typeId = formType.id) => {
    if (!checkFilesCountHandler(e, claimsData)) {
      return;
    }

    // Generates form data for file upload.
    let formData = await generateFormData(e, refNumber, typeId);

    // Send form data for file upload.
    let updatedPaths = await postFilesData(formData);

    // Sets updated paths received from POST api call.
    const newArr = { ...claimsData };

    for (let paths of updatedPaths) {
      newArr.attachment.push(paths);
    }
    setClaimsData(newArr);
  };

  /** Close the modal */
  const closeBackdropHandler = () => {
    setIsBackdropOpen(false);
    navigate('/dashboard', {
      state: {
        claimType: formType && formType.id,
      },
    });
  };

  // Open Modal When api response succesfully for(Draft/Submit)
  const openSuccessPopUp = (successMessage) => {
    setSuccessMessage(successMessage);
    setIsBackdropOpen(true);
  };

  // remove attachment from claims data start
  const removeAttachement = (i) => {
    const newArr = { ...claimsData };
    newArr.attachment.splice(i, 1);

    setClaimsData(newArr);
  };
  // remove attachment from claims data end

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }} className='mar-top'>
        <Container>
          <Grid container>
              <ReportingDetails cardData={cardData} />
              <UserProfile
                name={userData && userData.userDetails.firstName}
                id={userData && userData.userDetails.id}
                designation={userData.userDetails?.jobTitle?.name}
                department={userData.userDetails?.department?.name}
              />
            <Grid xs={11}>
              <Box>
                <Box
                  className='form-main'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '1em',
                    marginBottom: '4em',
                  }}
                >
                  <Grid xs={11}>
                    <CardUI className='form-heading-wrap'>
                      <Grid
                        xs={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Grid xs={6}>
                          <h4 style={{ textTransform: 'capitalize' }}>
                            {formType.name &&
                              formType.name.replaceAll('-', ' ')}{' '}
                            
                          </h4>
                        </Grid>
                        <Grid
                          xs={6}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <h4>
                            Claim Reference Number :{' '}
                            {inputs.claimReferenceNumber}
                          </h4>
                        </Grid>
                      </Grid>
                    </CardUI>
                    <CardUI className='form-content-wrap'>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Total payment amount *</label>

                          <TextField
                            type='number'
                            required
                            variant='outlined'
                            color='primary'
                            size='small'
                            value={inputs.totalPaymentAmount}
                            name='totalPaymentAmount'
                            fullWidth
                            onChange={(e) =>
                              handleChangeInput(e, null, 'number')
                            }
                          />
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>
                            Description / Reason / Remarks *
                          </label>

                          <TextField
                            required
                            variant='outlined'
                            color='primary'
                            size='small'
                            value={inputs.descriptionReasonRemarks}
                            name='descriptionReasonRemarks'
                            fullWidth
                            onChange={(e) => handleChangeInput(e)}
                          />
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Over Time Date *</label>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              shouldDisableDate={disablePrevDates()}
                              disableFuture
                              value={inputs.claimDate}
                              onChange={(e) =>
                                handleChangeInput(e, 'claimDate')
                              }
                              inputFormat='dd-MM-yyyy'
                              renderInput={(params) => (
                                <TextField
                                  size='small'
                                  fullWidth
                                  variant='outlined'
                                  {...params}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <Stack direction='row' alignItems='center'>
                            <label htmlFor='icon-button-file'>
                              <input
                                multiple
                                accept='image/pdf*'
                                type='file'
                                onChange={(e) =>
                                  onFileChange(e, inputs.claimReferenceNumber)
                                }
                              />
                            </label>
                          </Stack>
                          {claimsData.attachment &&
                            claimsData.attachment.map((each, i) => {
                              return (
                                <div key={i} className='file-path-wrap'>
                                  {/* <h6>{each.split('/')[4]}</h6> */}
<h6>{inputs.claimReferenceNumber}_{i+1}</h6>
 <IconButton
  onClick={() => removeAttachement(i)}
                                    disableTouchRipple
                                  >
                                    <CancelRoundedIcon />
                                  </IconButton>
                                </div>
                              );
                            })}
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}></Grid>
                      </Grid>
                    </CardUI>
                    {/* dsdsdsd */}
                  </Grid>
                </Box>

                <Grid style={{ position: 'relative' }}>
                  <Footer
                    fromEdit={true}
                    formType={formType}
                    type={formType.name}
                    onclick={() => submitData('PENDING')}
                    onSaveDraft={() => submitData('DRAFT')}
                    disabledSubmit={formSubmitted}
                    buttonClicked = {buttonClicked}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Modal
        show={isBackdropOpen}
        onCancel={closeBackdropHandler}
        header={<h1>Success</h1>}
        contentClass='modal-content'
        footerClass='footer-content'
        submitButton={
          <ButtonUI
            backgroundColor='#00AC94'
            color='#ffffff'
            size='small'
            padding='3px 20px'
            onclick={closeBackdropHandler}
          >
            Ok
          </ButtonUI>
        }
      >
        <h4>{successMessage}</h4>
      </Modal>
    </React.Fragment>
  );
};
export default ExpensesEdit;
