import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Box, Typography, styled } from '@mui/material';

export default function BtnDropDown(props) {
  const { BtnOptions, selectedOption, disabled } = props;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(selectedOption);

  const handleClick = () => {
    const clickEvenFn = BtnOptions[selectedIndex]?.handleClick;
    if (clickEvenFn) {
      clickEvenFn(selectedIndex);
    }
  };

  const handleMenuItemClick = (event, index) => {
    const clickEvenFn = BtnOptions[index]?.handleClick;
    setSelectedIndex(index);
    setOpen(false);
    clickEvenFn(index);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant='contained'
        ref={anchorRef}
        aria-label='split button'
      >
        <StyledButton
          onClick={handleClick}
          sx={{
            borderRadius: '4px 0 0 4px !important',
          }}
          disabled={BtnOptions[selectedIndex]?.disabled? true: disabled}
        >
          <Box mr={1}>{BtnOptions[selectedIndex]?.title} </Box>
          {BtnOptions[selectedIndex]?.icon}
        </StyledButton>
        <StyledButton
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
          sx={{
            borderRadius: '0 4px 4px 0 !important',
          }}
          disabled={disabled}
        >
          <KeyboardArrowDownOutlinedIcon />
        </StyledButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 3,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  {BtnOptions?.map((option, index) => (
                    <MenuItem
                      key={''}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      disabled={option.disabled}
                    >
                      <Box display={'flex'} alignItems={'center'} mr={1}>
                        {option.icon}
                      </Box>{' '}
                      {option.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutral.dark,
  backgroundColor: theme.palette.accentTertiary.accent80,
  textTransform: 'capitalize',
  minWidth: 140,
  '&:hover': {
    backgroundColor: theme.palette.accentTertiary.accent80,
  },
}));
