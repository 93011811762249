import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ViewNewJob from './JobForm/ViewNewJob';
import CreateNewJob from './JobForm/CreateNewJob';
import { APIRequestStatus, LoadingStatus } from '../../../../../../constants/common';
import { getJobConfig, resetJob } from '../../../../../../redux/jobRecruiting/action';
import CircularLoader from '../../../../../../atoms/CircularLoader/circular-loader';
import TryAgain from '../../../../../molecules/ErrorState/error.component';
import { AppLevelPermissions } from '../../../../../OnBoarding/Organization/constants/onboarding.constants';
import { JobRequisition } from '../../../../Constant/constant';

export default function AddJob(props) {
  const { viewMode, setViewMode } = props;
  const { jobConfig,
    requestProcessing: { [APIRequestStatus.GET_JOB_CONFIG]: configLoadingStatus }
  } = useSelector((state) => state.job);
  const { currentUserRoleId, appPermissions } = useSelector((state) => state.main);
  const { recrutingPermissions } = useSelector((state) => state.modulePermissions);

  const dispatch = useDispatch();
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const idForJobDetails = pathNames[pathNames.length - 1];
  // const fromLib = location.state;

  useEffect(() => {
    if (currentUserRoleId) {
      if (!isNaN(idForJobDetails)) {
        dispatch(getJobConfig(currentUserRoleId, idForJobDetails, appPermissions?.[AppLevelPermissions.Job]?.id,
          recrutingPermissions?.[JobRequisition]?.id));
        setViewMode(true);
      } else {
        dispatch(getJobConfig(currentUserRoleId));
      }
    }
    return (() => {
      setViewMode(false);
      dispatch(resetJob());
    });
  }, [idForJobDetails, currentUserRoleId]);

  if (configLoadingStatus === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={configLoadingStatus === LoadingStatus.Loading} />
      {
        configLoadingStatus === LoadingStatus.Success &&
          viewMode ?
          <ViewNewJob jobConfig={jobConfig} setViewMode={setViewMode} /> :
          <CreateNewJob jobConfig={jobConfig} setViewMode={setViewMode} isNewJob={isNaN(idForJobDetails)} idForJobDetails={idForJobDetails} />
      }
    </>
  );
}
