import React, { useState, useEffect } from 'react';
import { Box, Typography, Link, Accordion } from '@mui/material';
import SelectTag from '../../../atoms/Select/SelectTag';
import Grid from '@mui/material/Grid';
import { fetchEmployeeInfo, updateDepartmentInfo } from '../../../redux/onboarding/action';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../../atoms/Input/Input';
import { doesNameAlreadyExists, getHelperTextOnInputInvalid, isNotEmptyString } from './utils/onboarding.util';
import ActionHeader from './ActionHeader/ActionHeader';

export default function DataConfigure(props) {

  const { id, managerName, supervisorName, name, index, managerId, supervisorId, departmentView } = props;
  const { currentUserRoleId } = useSelector((state) => state.main);
  const [allowAdd, setAllowAdd] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [inputs, setInputs] = useState({
    name: name,
    managerId: managerId,
    supervisorId: supervisorId,
  });

  const handleChangeInput = (e) => {
    const values = e.target.name;
    const name = e.target.value;
    if (!doesNameAlreadyExists(departments, name) && (isNotEmptyString(name))) {
      setAllowAdd(true);
    }
    else {
      setAllowAdd(false);
    }
    setInputs({
      ...inputs,
      [values]: e.target.value,
    });
    setIsEdit(true);
  };

  const dispatch = useDispatch();

  const { employees, departments } = useSelector((state) => {
    return state.onBoarding;
  });

  const handleDraftSubmit = () => {
    dispatch(updateDepartmentInfo(inputs, id, index,currentUserRoleId));
    departmentView();
  };


  const departmentConfigureForm = [
    {
      name: 'managerId',
      label: 'Department Manager/Head',
      sideLabel: 'Role',
      sideLabelValue: 'Manager',
      sideLabelValueColor: 'accentPrimary.main',
      defaultValue: managerName,
    },
    {
      name: 'supervisorId',
      label: 'Department Supervisor',
      sideLabel: 'Role',
      sideLabelValue: 'Employee',
      sideLabelValueColor: 'primary.main100',
      defaultValue: supervisorName,
    },
  ];

  useEffect(() => {
    dispatch(fetchEmployeeInfo(id,currentUserRoleId));
  }, []);

  const ConfigureFields = () =>
    departmentConfigureForm.map((item) => {
      const { name, label, sideLabel, sideLabelValue, sideLabelValueColor, defaultValue } = item;
      return (
        <Grid item md={6} key={name}>
          <Box sx={{ display: 'flex' }}>
            <Box width={235}>
              <SelectTag
                dropDownList={employees}
                name={name}
                value={inputs[name]}
                onchange={(e) => handleChangeInput(e)}
                labelText={label}
                size='small'
                defaultValue={defaultValue}
                fullWidth
              />
            </Box>
            <Box ml={4}>
              <Typography
                variant='body2'
                color={'neutral.dark80'}
                mt={1}
                mb={2}
              >
                {sideLabel}
              </Typography>
              <Typography variant='body3' color={sideLabelValueColor}>
                {sideLabelValue}
              </Typography>
            </Box>
          </Box>
        </Grid>
      );
    });
  return (
    <Box>
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={name}
          showDiscard={true}
          editButtonText='Save'
          onEditClick={() => handleDraftSubmit()}
          onClickDiscard={departmentView}
          disabledDiscard={isEdit}
          disabledSave={!isEdit}
        />
      </Box>
      <Grid container rowSpacing={1} columnSpacing={1} pb={1}>
        <Grid item md={12}>
          <Box mb={2} sx={{ maxWidth: 235 }}>
            <InputField
              id="deptName"
              placeholder="Department Name"
              labelText="Department Name"
              size="small"
              fullWidth
              name="name"
              value={inputs.name}
              onChange={(e) => handleChangeInput(e)}
              helperText={getHelperTextOnInputInvalid(allowAdd)}
            />
          </Box>
        </Grid>
        {ConfigureFields()}

      </Grid>
    </Box>
  );
}
