import React, { useState, useEffect } from 'react';

// Third-party imports
import { Grid } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

// Local imports
import {
  UserDetails,
  SingleRequestData,
  Attachments,
  PaymentDate,
  DraftOptions,
  ClaimBasicDetails,
  ClaimRejectModal,
  SuccessModal,
  ManagerActions,
} from '../../../shared/components/claim-forms';
import {
  approveClaimRequestCall,
  deleteDraftCall,
  getRemainingClaimAmount,
  getSelectedClaimsDataCall,
  rejectClaimRequestCall,
} from '../common-services/services';

// CSS imports
import './FlexiClaimView';
import '../FormsMain.css';
import { getPayDateForManager } from '../common-services/functions';
import ReasonIssueList from '../../../constants/Reasons';
import { Alert } from '@mui/material';

const FlexiClaimView = () => {
  // For Navigation
  const navigate = useNavigate();

  // Initial states
  const [payDate, setPayDate] = useState(new Date());
  const [fullName, setFullName] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [remark, setRemark] = useState('');
  const [selected, setSelected] = React.useState(new Set());
  const [selectedReason, setSelectedReason] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [claimsData, setClaimsData] = useState({});
  const [healthPaymentAmount, setHealthPaymentAmount] = useState(0);
  const [employeeEntitlement, setEmployeeEntitlement] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // List for claim reject reasons for Manager
  const selectionList = ReasonIssueList;

  // Redux data fetch for current user type
  const currentUserType = useSelector(
    (state) => state && state.claims.currentUserType
  );

  // Redux data fetch for current user data
  const currentUserData = useSelector((state) => state.claims.claimData);

  // Filter to get the selected user data from table
  let employeeClaimData = [];
  let refNumber = currentUserData.refNumber;
  if (currentUserData.wholeData) {
    employeeClaimData = currentUserData.wholeData.filter(
      (item) => item.referenceNumber == refNumber
    );
  }

  // Below effect only runs one time and gets the current user data from localStorage
  useEffect(() => {
    // const userData = JSON.parse(localStorage.getItem('userData'));
    // setEmployeeId(userData.userDetails.id);
  });

  useEffect(() => {
    getDataForSelectedClaim(currentUserData.singleData.id);
  }, []);
  useEffect(() => {
    getEmployeeEntitlement();
  }, [claimsData]);


  const getEmployeeEntitlement = async ()=>{
    try {
      if(claimsData){
        const response = await getRemainingClaimAmount(claimsData?.employee?.id, claimsData.clientID);
        if(response){
          setEmployeeEntitlement(response);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  let claimType = '';

  if (employeeClaimData[0]) {
    claimType = employeeClaimData[0].type.id;
  }

  // Remarks textfield change handler for reject claim modal
  const handleRemarkChange = (event) => {
    setRemark(event.target.value);
  };

  /** Open the modal */
  const openBackdropHandler = () => {
    setIsBackdropOpen(true);
  };

  /** Close the modal */
  const closeBackdropHandler = () => {
    setIsSuccess(false);
    setIsBackdropOpen(false);
  };

  /** close the modal */
  const closeSuccessHandler = () => {
    setIsSuccess(false);
    navigate('/dashboard', {
      state: {
        claimType: '1',
      },
    });
  };

  // For payment date set start
  useEffect(() => {
    setPayDate(getPayDateForManager());
  }, []);
  // For payment date set end

  // Chip selection logic for reject modal, helps manager to selected claim reject reasons.
  const handleChipSelected = (id) => {
    const newSet = new Set(selected);
    if (newSet.has(id)) newSet.delete(id);
    else newSet.add(id);
    const arr = [...newSet];
    setSelectedReason(arr);
    setSelected(newSet);
  };

  // Delete request API call for Employee to delete draft.
  const deleteDraft = async (currentUserData) => {
    let deleteResponse = await deleteDraftCall(currentUserData);
    if (deleteResponse == 200) {
      setIsSuccess(true);
      setSuccessMessage('Draft deleted successfully !');
    }
  };

  // Manager can approve claim request for Employee.
  const handleApproveRequest = async () => {
    setErrorMessage('');
    let approveResponse = await approveClaimRequestCall(
      currentUserData,
      currentUserType,
      payDate
    );
      console.log('approveResponse',approveResponse);
    if (approveResponse == 'Action Completed Successfully!') {
      navigate('/success', {
        state: {
          claimType: `${claimsData.claimSubType}`,
          employeeId: employeeId,
          employeeName: fullName,
          claimReferenceNumber: employeeClaimData[0].referenceNumber,
          amount:
            employeeClaimData[0].finalAmount ||
            employeeClaimData[0].claimAmount ||
            employeeClaimData[0].amount,
        },
      });
    } else {
      setErrorMessage(approveResponse);
    }
  };

  // Manager can reject claim request for Employee.
  const handleRejectRequest = async (status) => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    setFormSubmitted(true);
    let rejectResponse='';
    if(selectedReason.length>0){
     rejectResponse = await rejectClaimRequestCall(
      userData,
      remark,
      currentUserData,
      payDate,
      selectedReason,
      status
    );
  }
  else{
    alert('Sendback Reason(s) Is Mandatory');
    setFormSubmitted(false);
  }
    if (rejectResponse == 'Action Completed Successfully!') {
      navigate('/claims');
      closeBackdropHandler();
    }
  };

  // Get the data for selected claim.
  const getDataForSelectedClaim = async (id) => {
    let claimsData = await getSelectedClaimsDataCall(id);
    if (claimsData) {
      setClaimsData(claimsData);
      setFullName(
        claimsData.employee.firstName + ' ' + claimsData.employee.lastName
      );
      setEmployeeId(claimsData.employee.id);
    }
  };

  const editData = () => {
    if (claimsData) {
      navigate('/employee-portal/flexible-benefit/edit', {
        state: {
          claimsData: claimsData,
          name: claimsData.claimSubType,
          id: currentUserData.singleData.type,
        },
      });
    }
  };

  const getPaymentAmount = () => {

    let amount = claimsData.isFirstHealthScreening && +claimsData.receiptAmount > 20 
    ?claimsData.receiptAmount && Number (Number(claimsData.receiptAmount).toFixed(2)-20 ).toFixed(2)
    :  Number(claimsData.amount).toFixed(2);

    if(employeeEntitlement && amount > employeeEntitlement.healthScreeningEntitlementAmount){
      amount = employeeEntitlement.healthScreeningEntitlementAmount;
    }
    return amount;
  };

  return (
    <React.Fragment>
      <Grid container maxWidth="lg" className="success-card-main">
        {/* User details component start */}
        <UserDetails
          fullName={fullName}
          employeeId={employeeId}
          claimType={claimsData.claimSubType}
          status={currentUserData?.singleData?.status}
        />
        {/* User details component end */}

        {/* Main content of claim request start */}
        <Grid
          lg={9}
          md={9}
          sm={9}
          xs={12}
          className="card-view-two success-card-wrapper"
        >
          {/* Basic claim details component start */}
          <ClaimBasicDetails
            claimType={claimType}
            employeeClaimData={claimsData}
            claimName={claimsData.claimSubType}
          />
          {/* Basic claim details component end */}
          {errorMessage && (
            <Alert severity="error">{errorMessage}</Alert>
          )}
          {/* Claim request details submitted by Employee start */}
          <Grid container spacing={2} className="claims-data">
            <Grid xs={3} sm={6} md={9} lg={12} className="data-display">
              <Grid xs={12} sm={9} lg={4}>
                <SingleRequestData
                  title="Submission Date"
                  value={
                    claimsData.createdAt &&
                    moment(claimsData.createdAt).format('DD-MM-YYYY')
                  }
                />
                <SingleRequestData
                  title={
                    claimsData?.claimSubType == 'Flexible Benefit'
                      ? 'Claim Amount ($)'
                      : 'Receipt Amount ($)'
                  }
                  value={
                    claimsData?.claimSubType === 'Health Screening' ? Number(claimsData.receiptAmount).toFixed(2) :
                    claimsData.amount && Number(claimsData.amount).toFixed(2)
                  }
                />
              </Grid>
              <div className="vertical-bar"></div>
              <Grid xs={12} sm={9} lg={4}>
                <SingleRequestData
                  title="Benefit Type"
                  value={claimsData?.benefit?.benefitName}
                />
                {claimsData?.claimSubType === 'Health Screening' && <>
                <SingleRequestData
                  title={'Co Payment Amount ($)'}
                  value={claimsData.coPaymentAmount
                    // claimsData?.claimSubType === 'Health Screening' ? claimsData.isFirstHealthScreening && +claimsData.receiptAmount > 20.00 ? 20.00 :  claimsData.coPaymentAmount ? claimsData.coPaymentAmount  :0 : 0
                  }
                />
                <SingleRequestData
                  title={'Payment Amount ($)'}
                  value={ 
                    claimsData.amount
                    // getPaymentAmount()
                  }
                />
                </>}
                {currentUserData.singleData.status == 'APPROVED' && (
                  <SingleRequestData
                    title="Payment Date"
                    value={
                      claimsData.paymentDate
                        ? moment(claimsData.paymentDate).format('DD-MM-YYYY')
                        : undefined
                    }
                  />
                )}
              </Grid>
              <div className="vertical-bar"></div>

              <Grid xs={12} sm={9} lg={4}>
                {claimsData.claimSubType !== 'Maternity Benefit' ? (
                  <React.Fragment>
                    <SingleRequestData
                      title="Receipt Number "
                      value={claimsData.receiptNumber}
                    />
                    <SingleRequestData
                      title="Receipt Date"
                      value={
                        claimsData.receiptDate
                          ? moment(claimsData.receiptDate).format('DD-MM-YYYY')
                          : undefined
                      }
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <SingleRequestData
                      title="Child Birth Certificate Number "
                      value={claimsData.birthCertificateNumber}
                    />
                    <SingleRequestData
                      title="Child Birthdate"
                      value={
                        claimsData.childBirthDate
                          ? moment(claimsData.childBirthDate).format(
                              'DD-MM-YYYY'
                            )
                          : undefined
                      }
                    />
                  </React.Fragment>
                )}
              </Grid>
              {(currentUserData.singleData.status == 'REJECTED' ||
                currentUserData.singleData.status == 'SENT BACK') && (
                <React.Fragment>
                  <div className="vertical-bar"></div>
                  <Grid xs={12} sm={9} lg={6}>
                    <SingleRequestData
                      title="Remark"
                      value={claimsData.notApproveReason}
                    />
                    <SingleRequestData
                      title="Reasons"
                      value={claimsData.reasons}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
          {/* Claim request details submitted by Employee end */}

          {/* Attachment component renders all file links uploaded by Employee start */}
          <Attachments paths={claimsData} />
          {/* Attachment component renders all file links uploaded by Employee end */}

          {/* Payment Date option for Manager when he/she Approves or Rejects the claim request start */}
          <PaymentDate
            payDate={payDate}
            onChange={(e) => setPayDate(e)}
            claimsData={claimsData}
            currentUserType={currentUserType}
          />
          {/* Payment Date option for Manager when he/she Approves or Rejects the claim request end */}

          {/* Draft options for Employees to Delete,Edit or Go back Claim requests start */}
          <DraftOptions
            currentUserType={currentUserType}
            currentUserData={currentUserData}
            claimType={claimType}
            deleteDraft={() => deleteDraft(currentUserData)}
            editData={editData}
            disabled={formSubmitted}
          />
          {/* Draft options for Employees to Delete,Edit or Go back Claim requests end */}

          {/* Actions for Manager to Approve, Reject or Review Later claim requests start */}
          <ManagerActions
            currentUserType={currentUserType}
            claimType={claimType}
            openBackdropHandler={openBackdropHandler}
            handleApproveRequest={handleApproveRequest}
            disabled={formSubmitted}
          />
          {/* Actions for Manager to Approve, Reject or Review Later claim requests end */}
        </Grid>
        {/* Main content of claim request end */}
      </Grid>

      {/* Modals start */}

      {/* Success Modal for Edit and Delete success API calls start */}
      <SuccessModal
        isSuccess={isSuccess}
        closeBackdropHandler={closeBackdropHandler}
        closeSuccessHandler={closeSuccessHandler}
        successMessage={successMessage}
       
      />
      {/* Success Modal for Edit and Delete success API calls end */}

      {/* Claim Reject Modal start */}
      <ClaimRejectModal
        isBackdropOpen={isBackdropOpen}
        closeBackdropHandler={closeBackdropHandler}
        handleRejectRequest={handleRejectRequest}
        selectionList={selectionList}
        selected={selected}
        onclick={handleRejectRequest}
        handleChipSelected={handleChipSelected}
        remark={remark}
        handleRemarkChange={handleRemarkChange}
        currentUserData={currentUserData}
        employeeClaimData={employeeClaimData}
        employeeId={employeeId}
        disabled={formSubmitted}
      />
      {/* Claim Reject Modal end */}

      {/* Modals end */}
    </React.Fragment>
  );
};

export default FlexiClaimView;
