import React from 'react';

import { CircularProgress, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ButtonUI from '../ui-elements/ButtonUI';
import './ManagerActions.css';

const ManagerActions = ({
  currentUserType,
  claimType,
  openBackdropHandler,
  handleApproveRequest,
  disabled,
}) => {
  const navigate = useNavigate();
  return (
    <Grid>
      {currentUserType !== 'Employee' && (
        <Grid className="bottom">
          <Grid
            lg={12}
            className="bottom manager-options"
            style={{ width: '75%' }}
          >
            <ButtonUI
              variant="text"
              size="small"
              color="#777B81"
              margin="0 0 0 10px"
              onclick={() =>
                navigate('/dashboard', {
                  state: {
                    claimType,
                  },
                })
              }
            >
              Review Later
            </ButtonUI>
            <ButtonUI
              variant="outlined"
              border="1px solid #00AC94"
              backgroundColor="transparent"
              size="small"
              color="#00AC94"
              margin="0 0 0 10px"
              onclick={openBackdropHandler}
            >
              Send Back
            </ButtonUI>

            <ButtonUI
              variant="contained"
              backgroundColor="#00AC94"
              size="small"
              color="#ffffff"
              margin="0 0 0 10px"
              onclick={handleApproveRequest}
              disabled={disabled ? disabled : false}
            >
              {disabled ? (
                <CircularProgress
                  style={{ color: 'white', margin: '2px' }}
                  size={20}
                />
              ) : (
                'Approve'
              )}
            </ButtonUI>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ManagerActions;
