import {
  Box,
  Checkbox,
  Container,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';

import CardUI from '../../../shared/components/ui-elements/CardUI';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Footer from '../../footer/Footer';
import PersonIcon from '@mui/icons-material/Person';
import { useLocation } from 'react-router-dom';
import Modal from '../../../shared/components/ui-elements/Modal';
import ButtonUI from '../../../shared/components/ui-elements/ButtonUI';
import { useNavigate } from 'react-router-dom';
import {
  checkFilesCountHandler,
  generateCurrentTimeStamp,
  generateFormData,
  validateNumber,
  disablePrevDates,
} from '../common-services/functions';
import { postFilesData, putFormData } from '../common-services/services';
import UserProfile from '../../../shared/components/claim-forms/UserProfile';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ReportingDetails from '../../../shared/components/claim-forms/ReportingDetails';

const StudyLoanEdit = () => {
  const params = useLocation();
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [claimsData, setClaimsData] = useState(params.state.claimsData);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');
  const navigate = useNavigate();
  const [inputs, setInputs] = useState([
    {
      reportingOfficer: 'Kelvin Lionel',
      hod: 'Benjamin Koh',
      costCenter: 'Singapore Labour',
      claimReferenceNumber: claimsData.referenceNumber,
      submissionDate: generateCurrentTimeStamp(),
      claimantName: '',
      personnelNo: '',
      designation: '',
      department: '',
      claimsMonth: claimsData.claimMonth,
      description: claimsData.description,
      courseTitle: claimsData.courseTitle,
      courseDate: claimsData.courseDate,
      nameOfCourseProvider: claimsData.nameOfCourseProvided,
      duration: claimsData.duration,
      courseFee: claimsData.courseFee,
      joinedDate: claimsData.dateJoined,
      declaration: claimsData.declaration,
    },
  ]);

  const [type] = useState(params.state.type);

  let userData = JSON.parse(localStorage.getItem('userData'));
  let asProfile = localStorage.getItem('asProfile');

  const handleChangeInput = (i, e, key = null, key1, key2) => {
    const values = [...inputs];
    if (key) values[i][key] = e;
    else if (key2) values[i][key2] = e.target.checked;
    else {
      if (key1) {
        const validate = validateNumber(e.target.name, e.target.value);
        if (!validate) {
          return false;
        }
      }
      values[i][e.target.name] = e.target.value;
    }
    setInputs(values);
  };

  const cardData = [
    {
      post: 'Reporting Officer',
      name:
        userData.userDetails.reportingTo.firstName +
        ' ' +
        userData.userDetails.reportingTo.lastName,
    },
    {
      post: 'HOD',
      name: userData.userDetails.HOD,
    },
    {
      post: 'Cost Center',
      name: userData.userDetails.costCenter,
    },
  ];

  const validateFields = () => {
    for (let i = 0; i <= inputs.length - 1; i++) {
      if (
        inputs[i].claimsMonth == undefined ||
        inputs[i].claimsMonth == null ||
        inputs[i].claimsMonth == ''
      ) {
        window.alert('Please Enter A Claim Month !');
        return false;
      }
      if (
        inputs[i].description == undefined ||
        inputs[i].description == null ||
        inputs[i].description == ''
      ) {
        window.alert('Please Enter A Description!');
        return false;
      }

      if (
        inputs[i].courseDate == undefined ||
        inputs[i].courseDate == null ||
        inputs[i].courseDate == ''
      ) {
        window.alert('Please Enter Course Date !');
        return false;
      }
      if (
        inputs[i].joinedDate == undefined ||
        inputs[i].joinedDate == null ||
        inputs[i].joinedDate == ''
      ) {
        window.alert('Please Enter Joined Date !');
        return false;
      }
      if (
        inputs[i].courseTitle == undefined ||
        inputs[i].courseTitle == null ||
        inputs[i].courseTitle == ''
      ) {
        window.alert('Please Enter Course Title !');
        return false;
      }
      if (
        inputs[i].nameOfCourseProvider == undefined ||
        inputs[i].nameOfCourseProvider == null ||
        inputs[i].nameOfCourseProvider == ''
      ) {
        window.alert('Please Enter Name Of Course Provider !');
        return false;
      }
      if (
        inputs[i].duration == undefined ||
        inputs[i].duration == null ||
        inputs[i].duration == ''
      ) {
        window.alert('Please Enter Duration !');
        return false;
      }
      if (
        inputs[i].courseFee == undefined ||
        inputs[i].courseFee == null ||
        inputs[i].courseFee == ''
      ) {
        window.alert('Please Enter Course Fee !');
        return false;
      }
      if (inputs[i].declaration == false) {
        window.alert('Please Select "Terms and Conditions" !');
        return false;
      }
    }
    return true;
  };

  const generateDataForClaimRequest = (
    inputs,
    userData,
    dateTime,
    callType
  ) => {
    let finalFormData = [];
    let updateFormObject = {};
    let callForPost = callType;

    // console.log('updateFormObject', updateFormObject, finalFormData);
    if (inputs[0]) {
      return (updateFormObject = {
        typeId: 31,
        id: claimsData.id,
        employee: userData && userData.userDetails.id,
        claimReferenceNumber: inputs[0].claimReferenceNumber,
        submissionDate: dateTime,
        filePaths: [...claimsData.attachment],
        status: callForPost,
        amount: Number(inputs[0].courseFee),
        claimMonth: inputs[0].claimsMonth,
        description: inputs[0].description,
        courseTitle: inputs[0].courseTitle,
        courseDate: inputs[0].courseDate,
        nameOfCourseProvided: inputs[0].nameOfCourseProvider,
        duration: inputs[0].duration,
        courseFee: Number(inputs[0].courseFee),
        dateJoined: inputs[0].joinedDate,
        declaration: inputs[0].declaration,
      });
    }
  };

  const submitData = async (callType) => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    setFormSubmitted(true);
    setButtonClicked(callType);
    const dateTime = generateCurrentTimeStamp();

    let validate = validateFields();

    if (!validate) {
      setFormSubmitted(false);
      return;
    }

    let finalFormData = generateDataForClaimRequest(
      inputs,
      userData,
      dateTime,
      callType
    );

    // POST call for submit form data.
    let businessTravelsResponse = await putFormData(finalFormData);

    if (businessTravelsResponse === 201) {
      let successMessage = '';
      if (callType === 'PENDING') {
        successMessage = 'Claim request submitted successfully !';
      } else {
        successMessage = 'Draft saved successfully !';
      }
      setFormSubmitted(false);
      openSuccessPopUp(successMessage);
    } else {
      setFormSubmitted(false);
      window.alert('Something went wrong');
    }
  };

  // On file select (from the pop up)
  const onFileChange = async (e, refNumber, typeId = 1) => {
    if (!checkFilesCountHandler(e, claimsData)) {
      return;
    }

    // Generates form data for file upload.
    let formData = await generateFormData(e, refNumber, typeId);

    // console.log(formData);
    // Send form data for file upload.
    let updatedPaths = await postFilesData(formData);

    // Sets updated paths received from POST api call.
    const newArr = { ...claimsData };
    // console.log(updatedPaths);
    for (let paths of updatedPaths) {
      newArr.attachment.push(paths);
    }
    setClaimsData(newArr);
  };

  // remove attachment from claims data start
  const removeAttachement = (i) => {
    const newArr = { ...claimsData };
    newArr.attachment.splice(i, 1);
    // console.log(newArr.attachment);
    setClaimsData(newArr);
  };
  // remove attachment from claims data end

  /** Close the modal */
  const closeBackdropHandler = () => {
    setIsBackdropOpen(false);
    navigate('/dashboard', {
      state: {
        claimType: '31',
      },
    });
  };

  // Open Modal When api response succesfully for(Draft/Submit)
  const openSuccessPopUp = (successMessage) => {
    setSuccessMessage(successMessage);
    setIsBackdropOpen(true);
  };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }} className='mar-top'>
        <Container>
          <Grid container>
              <ReportingDetails cardData={cardData} />
              <UserProfile
                name={userData && userData.userDetails.firstName}
                id={userData && userData.userDetails.id}
                designation={userData.userDetails?.jobTitle?.name}
                department={userData.userDetails?.department?.name}
              />
            <Grid xs={11}>
              <Box>
                {inputs &&
                  inputs.map((input, i) => (
                    <Box
                      key={i}
                      className='form-main'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '1em',
                        marginBottom: '4em',
                      }}
                    >
                      <Grid xs={11}>
                        <CardUI className='form-heading-wrap'>
                          <Grid
                            xs={12}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Grid xs={6}>
                              <h4>Study Loan</h4>
                            </Grid>
                            <Grid
                              xs={6}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <h4>
                                Claim Reference Number :{' '}
                                {input.claimReferenceNumber}
                              </h4>
                            </Grid>
                          </Grid>
                        </CardUI>
                        <CardUI className='form-content-wrap'>
                          <Grid
                            container
                            spacing={2}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Claim Month *</label>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  variant='inline'
                                  inputFormat='MM/yyyy'
                                  maxDate={new Date()}
                                  minDate={
                                    new Date(
                                      userData &&
                                        userData.userDetails.dateOfJoining
                                    )
                                  }
                                  views={['year', 'month']}
                                  margin='normal'
                                  id='date-picker-inline'
                                  value={input.claimsMonth}
                                  onChange={(e) =>
                                    handleChangeInput(i, e, 'claimsMonth')
                                  }
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      size='small'
                                      fullWidth
                                      variant='outlined'
                                      {...params}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Description *</label>

                              <TextField
                                required
                                variant='outlined'
                                color='primary'
                                size='small'
                                value={input.description}
                                name='description'
                                fullWidth
                                onChange={(e) => handleChangeInput(i, e)}
                              />
                            </Grid>
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Course Date</label>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  value={input.courseDate}
                                  onChange={(e) =>
                                    handleChangeInput(i, e, 'courseDate')
                                  }
                                  shouldDisableDate={disablePrevDates()}
                                  disableFuture
                                  inputFormat='dd-MM-yyyy'
                                  renderInput={(params) => (
                                    <TextField
                                      size='small'
                                      fullWidth
                                      variant='outlined'
                                      {...params}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Joined Date</label>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  value={input.joinedDate}
                                  onChange={(e) =>
                                    handleChangeInput(i, e, 'joinedDate')
                                  }
                                  shouldDisableDate={disablePrevDates()}
                                  disableFuture
                                  inputFormat='dd-MM-yyyy'
                                  renderInput={(params) => (
                                    <TextField
                                      size='small'
                                      fullWidth
                                      variant='outlined'
                                      {...params}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Course Title *</label>

                              <TextField
                                required
                                variant='outlined'
                                color='primary'
                                size='small'
                                value={input.courseTitle}
                                name='courseTitle'
                                fullWidth
                                onChange={(e) => handleChangeInput(i, e)}
                              />
                            </Grid>
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>
                                Name of Course Provider *
                              </label>

                              <TextField
                                required
                                variant='outlined'
                                color='primary'
                                size='small'
                                value={input.nameOfCourseProvider}
                                name='nameOfCourseProvider'
                                fullWidth
                                onChange={(e) => handleChangeInput(i, e)}
                              />
                            </Grid>
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Duration (In Months)*</label>
                              <TextField
                                required
                                variant='outlined'
                                color='primary'
                                size='small'
                                value={input.duration}
                                name='duration'
                                fullWidth
                                onChange={(e) =>
                                  handleChangeInput(i, e, null, 'number')
                                }
                                type='number'
                              />
                            </Grid>

                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Course Fee *</label>

                              <TextField
                                required
                                type='number'
                                variant='outlined'
                                color='primary'
                                size='small'
                                value={input.courseFee}
                                name='courseFee'
                                fullWidth
                                onChange={(e) =>
                                  handleChangeInput(i, e, null, 'number')
                                }
                              />
                            </Grid>

                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Attachment</label>
                              <input
                                multiple
                                accept='image/pdf*'
                                type='file'
                                onChange={(e) =>
                                  onFileChange(e, inputs.claimReferenceNumber)
                                }
                              />
                              {claimsData.attachment &&
                                claimsData.attachment.map((each, i) => {
                                  return (
                                    <div key={i} className='file-path-wrap'>
                                      {/* <h6>{each.split('/')[4]}</h6> */}
                                      <h6>{inputs.claimReferenceNumber}_{i+1}</h6>
                                      <IconButton
                                        onClick={() => removeAttachement(i)}
                                        disableTouchRipple
                                      >
                                        <CancelRoundedIcon />
                                      </IconButton>
                                    </div>
                                  );
                                })}
                            </Grid>
                            <Grid item xs={12} lg={12} md={12} sm={12}>
                              <Grid item xs={12} lg={12} md={4} sm={4}>
                                <Checkbox
                                  size='small'
                                  onChange={(e) =>
                                    handleChangeInput(
                                      i,
                                      e,
                                      null,
                                      null,
                                      'declaration'
                                    )
                                  }
                                  checked={input.declaration}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />

                                <p>
                                  I shall abide by the prevailing Terms and
                                  Conditions for sponsorship for Study Loan if
                                  my application is successful.
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardUI>
                        {/* dsdsdsd */}
                      </Grid>
                    </Box>
                  ))}
                <Grid style={{ position: 'relative' }}>
                  <Footer
                    type={type}
                    fromEdit
                    onclick={() => submitData('PENDING')}
                    onSaveDraft={() => submitData('DRAFT')}
                    disabledSubmit={formSubmitted}
                    buttonClicked = {buttonClicked}
                    // marginLeft='-2em'
                    // margin='0 -14px'
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Modal
        show={isBackdropOpen}
        onCancel={closeBackdropHandler}
        header={<h1>Success</h1>}
        contentClass='modal-content'
        footerClass='footer-content'
        submitButton={
          <ButtonUI
            backgroundColor='#00AC94'
            color='#ffffff'
            size='small'
            padding='3px 20px'
            onclick={closeBackdropHandler}
          >
            Ok
          </ButtonUI>
        }
      >
        <h4>{successMessage}</h4>
      </Modal>
    </React.Fragment>
  );
};
export default StudyLoanEdit;
