import API from './api';
export class SalaryStructureServices extends API {
  static baseURL =
    API.env.REACT_APP_BASE_API_LOCAL + '/payroll/pay-structure/' || '';
  constructor() {
    super(SalaryStructureServices.baseURL);
  }

  getSalaryStructureDetails = async (roleId, salaryStructureId) =>
    this.responsify(
      await this.instance.get('', {
        params: {
          id: salaryStructureId,
          roleId,
        },
      })
    );

  postSalaryStructureInfo = async (roleId, salaryStructureFormInfo) =>
    this.responsify(
      await this.instance.post('', salaryStructureFormInfo, {
        params: { roleId },
      })
    );

  getAllSalaryStructureList = async (
    roleId,
    searchQuery,
    forLibrary,
    forAssignment
  ) =>
    this.responsify(
      await this.instance.get('list', {
        params: { searchQuery, forLibrary, roleId, forAssignment },
      })
    );

  getDropdownOptions = async (fieldName) =>
    this.responsify(await this.instance.get(`lookup-details/${fieldName}`));

  postPublishIdIRequest = async (info) =>
    this.responsify(await this.instance.put('publish', info));

  getAdditionDeductionDropdownOptions = async () =>
    this.responsify(await this.instance.get('components'));

  getEntitlementDropdownOptions = async () =>
    this.responsify(await this.instance.get('entitlement-type-components'));
}
