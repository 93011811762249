import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { APIRequestStatus, LoadingStatus } from '../../../../../../constants/common';
import { isEmpty } from 'lodash';
import ViewCandidate from './CandidateForm/ViewCandidate';
import CreateCandidate from './CandidateForm/CreateCandidate';
import { getCandidateConfig, resetCandidate } from '../../../../../../redux/candidateDetails/action';
import CircularLoader from '../../../../../../atoms/CircularLoader/circular-loader';
import TryAgain from '../../../../../molecules/ErrorState/error.component';

export default function AddCandidate(props) {
  const { viewMode, setViewMode } = props;
  const { candidateConfig,
    requestProcessing: { [APIRequestStatus.GET_CANDIDATE_CONFIG]: configLoadingStatus }
  } = useSelector((state) => state.candidate);
  const { currentUserRoleId } = useSelector((state) => state.main);

  const dispatch = useDispatch();
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const idForCandidateDetails = pathNames[pathNames.length - 1];

  useEffect(() => {
    if (currentUserRoleId) {
      if (!isNaN(idForCandidateDetails)) {
        dispatch(getCandidateConfig(currentUserRoleId, idForCandidateDetails));
        setViewMode(true);
      } else {
        dispatch(getCandidateConfig(currentUserRoleId));
      }
    }
    return (() => {
      setViewMode(false);
      dispatch(resetCandidate());
    });
  }, [idForCandidateDetails, currentUserRoleId]);

  if (configLoadingStatus === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={configLoadingStatus === LoadingStatus.Loading} />
      {
        configLoadingStatus === LoadingStatus.Success &&
          viewMode ?
          <ViewCandidate candidateConfig={candidateConfig} setViewMode={setViewMode} /> :
          <CreateCandidate candidateConfig={candidateConfig} setViewMode={setViewMode} isNewCandidate={isNaN(idForCandidateDetails)} />
      }
    </>
  );
}
