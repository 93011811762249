import React, { forwardRef, memo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import AccordionTabs from '../../../../atoms/Accordian/Accordian';
import EmployeeForm from './EmployeementForm/EmployeeForm';
import { useSelector } from 'react-redux';
import useDidMountEffect from '../../../../customHooks/useDidMountEffect';
import { OnboardingServices } from '../../../../services/onboarding';
import { EmployeeFormFields } from '../../Organization/constants/onboarding.constants';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { NavigateNext } from '@mui/icons-material';

const EmployeeTab = (props) => {
  const { viewMode, employeeFormConfig,
    empValidationErrors, setEmpValidationErrors, setEmpData, empData } = props;
  const bottomRef = useRef(null);

  const [locationDropDownOptions, setLocationDropDownOptions] = useState([]);
  useDidMountEffect(() => {
    if (employeeFormConfig.length) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [employeeFormConfig.length]);

  const handleDropdownClick = (name) => {
    switch (name) {
      case EmployeeFormFields.Location: {
        return new OnboardingServices().getUserNationality().then((res) => {
          const countryNames = res.map((item) => {
            return { ...item, name: item.countryName };
          });
          setLocationDropDownOptions(countryNames);
          return true;
        });
      }
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case EmployeeFormFields.Location: {
        return locationDropDownOptions;
      }
    }
  };

  return (
    <Box mr={12} >
      {employeeFormConfig.map((empFormConfig, index) => {
        return (
          <div key={index}
            ref={index === employeeFormConfig.length - 1 ? bottomRef : null}
          >
            <AccordionTabs
              key={index}
              title={!isEmpty(empData[index]) ? (
                <div>
                  {empData[index]?.[EmployeeFormFields.StartDate] ? (
                    (moment(empData[index]?.[EmployeeFormFields.StartDate], 'YYYY-MM-DD', true).isValid()) ?
                    moment(empData[index]?.[EmployeeFormFields.StartDate], 'YYYY-MM-DD').format('YYYY') :
                    moment(empData[index]?.[EmployeeFormFields.StartDate], 'DD/MM/YYYY').format('YYYY')
                  ) : ''}-
                  {empData[index]?.[EmployeeFormFields.EndDate] ? (
                    (moment(empData[index]?.[EmployeeFormFields.EndDate], 'YYYY-MM-DD', true).isValid()) ?
                    moment(empData[index]?.[EmployeeFormFields.EndDate], 'YYYY-MM-DD').format('YYYY') :
                    moment(empData[index]?.[EmployeeFormFields.EndDate], 'DD/MM/YYYY').format('YYYY')
                  ) : ''}
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  {empData[index]?.[EmployeeFormFields.Position]}
                </div>
              ) : 'New Tab'}
              tabDetails={
                <Box>
                  <EmployeeForm
                    formConfig={empFormConfig}
                    tabIndex={index}
                    formData={empData[index] || []}
                    setEmpData={setEmpData}
                    numberOfAccordion={employeeFormConfig.length}
                    viewMode={viewMode}
                    empValidationErrors={empValidationErrors[index]}
                    setEmpValidationErrors={setEmpValidationErrors}
                    handleDropdownClick={handleDropdownClick}
                    getDropdownOptions={getDropdownOptions}
                  />
                </Box>
              }
            />
          </div>
        );
      })}
    </Box>
  );
};

export default memo(forwardRef(EmployeeTab));