import React, { useEffect } from 'react';
import { CompBenTabs } from '../comp-ben-utlis/comp-ben-utils';
import { Box } from '@mui/material';
import NavTabBar from '../../../atoms/Tabs/nav-tab-bar';
import BenefitsDashBoard from './benefits-dashboard.component';
import CompBenHeader from '../comp-ben-header/comp-ben-header.component';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { permissionsObj } from '../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import AllUserBenefit from './user-benefit/user-benefit-dashboard.compoment';

const ManageCompBenTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const benefitTabId = pathNames[pathNames.length - 1];

  const { compBenPermission } = useSelector((state) => state.modulePermissions);

  const tabsData = CompBenTabs.map((item, index) => {
    const { id, module } = item;
    return {
      id: id,
      name: compBenPermission?.[module]?.displayName,
      path: `/comp-ben/${id}`,
      label: compBenPermission?.[module]?.displayName,
      title: compBenPermission?.[module]?.displayName,
      show: compBenPermission?.[module]?.[permissionsObj.allowView],
    };
  });

  const filteredTabsList = tabsData.filter((item) => item.show);

  useEffect(() => {
    if (benefitTabId === ':id' && CompBenTabs.length) {
      navigate(`/comp-ben/${filteredTabsList[0]?.id}`);
    }
  }, [CompBenTabs]);

  const getDashboards = () => {
    {
      switch (benefitTabId) {
        case 'benefits': {
          return <BenefitsDashBoard />;
        }
        case 'ben-dashboard': {
          return <AllUserBenefit />;
        }
        default:
          break;
      }
    }
  };

  return (
    <>
      <Box mt={4}>
        <CompBenHeader />
        <NavTabBar list={filteredTabsList} searchWidth={150} tabWidth={150} />
        {getDashboards()}
      </Box>
    </>
  );
};

export default ManageCompBenTab;
