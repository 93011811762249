import React, { useEffect, useMemo, useState } from 'react';
import FormEdit from '../../../../OnBoarding/common/Form/FormEdit';
import {
  fieldNamesTeamBuilding,
  getTeamBuildingFields,
} from '../../../../form-builder/utils/team-building-utils';
import { Box } from '@mui/system';
import { Button, Divider, Grid, IconButton, Paper } from '@mui/material';
import { AddOutlined, DeleteOutline } from '@mui/icons-material';

const TeamBuildingEdit = (props) => {
  const {
    isActionHeader,
    actionHeader,
    setDepartments,
    index,
    handleDropdownClick,
    getDropdownOptions,
    setDepartementFomdata,
    noOfDept,
    departementFomdata,
    singleDeptAllowed
  } = props;

  const [formInfo, setForminfo] = useState(departementFomdata);

  useEffect(() => {
    setDepartementFomdata((prev) => {
      const newConditions = [...prev];
      newConditions[index] = formInfo;
      return newConditions;
    });
  }, [formInfo]);

  useEffect(()=>{
    if(Object.keys(departementFomdata).length){
      setForminfo(departementFomdata);
    }
  },[departementFomdata]);

  const onchangeDepartement = (event) => {
    const key = event.target.name;
    const val = event.target.value;
    setForminfo((prev) => {
      return {
        ...prev,
        [key]: val,
        [fieldNamesTeamBuilding.Total_Budget]: val.sumTotal,
        [fieldNamesTeamBuilding.Total_Balance]: val.sumBalance,
      };
    });
  };

  const handleDelete = () => {
    if (noOfDept !== 1) {
      setDepartments((prev) => {
        const newConditions = [...prev];
        newConditions.splice(index, 1);
        return newConditions;
      });
      setDepartementFomdata((prev) => {
        const newConditions = [...prev];
        newConditions.splice(index, 1);
        return newConditions;
      });
    } else {
      setForminfo({});
      setDepartementFomdata((prev) => {
        const newConditions = [...prev];
        newConditions.splice(index, 1);
        return newConditions;
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flex="1 1 0"
        justifyContent={'space-between'}
        mb={2}
      >
        <Box sx={{ width: '100%' }}>
          <FormEdit
            list={getTeamBuildingFields({[fieldNamesTeamBuilding.Department]:onchangeDepartement,singleDeptAllowed})}
            isActionHeader={isActionHeader}
            actionHeader={actionHeader}
            getDropdownOptions={getDropdownOptions}
            handleDropdownClick={handleDropdownClick}
            formInfo={formInfo}
            setFormData={setForminfo}
            modal={false}
            padding={0}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={3}
          ml={6}
        >
          <IconButton
            color="success"
            sx={{ borderColor: 'white' }}
            onClick={() => {
              setDepartments((prev) => {
                return [...prev, 1];
              });
            }}
            disabled={singleDeptAllowed}
          >
            <AddOutlined sx={{ width: '24px', height: '24px', mr: '5px' }} />
          </IconButton>
          <IconButton
            color="error"
            sx={{ borderColor: 'white' }}
            onClick={handleDelete}
            disabled={singleDeptAllowed}
          >
            <DeleteOutline sx={{ width: '24px', height: '24px', mr: '5px' }} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default TeamBuildingEdit;
