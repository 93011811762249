import React from 'react';
import { Box, Typography } from '@mui/material';
import { LightTooltip } from '../../../atoms/Tooltip/LightTooltip';
import { InfoOutlined } from '@mui/icons-material';

export default function FieldView({
    labelText,
    labelValue,
    required,
    textTransform,
    tooltipText,
    ...rest
  }) {
  return (
    <Box>
      {labelText && (
        <Typography variant='body2' color={'neutral.dark60'}>
          {labelText}{' '}
          {required && (
            <Box color='accentSecondary.main' sx={{ display: 'inline-block' }}>
              *
            </Box>
          )}
          {tooltipText && (
            <LightTooltip title={tooltipText} placement="top" arrow>
              <Box color="primary.main" sx={{ display: 'inline-block' }}>
                <InfoOutlined
                  sx={{ width: 14, height: 14, ml: '4px', cursor: 'pointer' }}
                />
              </Box>
            </LightTooltip>
          )}
        </Typography>
      )}
      {labelValue && (
        <Typography variant='body2' color={'neutral.dark100'} mt={1} sx={{textTransform: {textTransform}}}>
          {labelValue}
        </Typography>
      )}
    </Box>
  );
}
