import { KRATargetFormFieldNames } from '../../../Utlis/goals.util';

export const getRatingValue = (achievementScore, targetPoints) => {
    const numAchievementScore = parseFloat(achievementScore);
    const targetValueMap = new Map();
    targetPoints.sort((a, b) => a[KRATargetFormFieldNames.TargetPoints] - b[KRATargetFormFieldNames.TargetPoints]);
    let rating = 0;
    let minValue = -Infinity;
    let maxValue = Infinity;
    if (targetPoints[0][KRATargetFormFieldNames.TargetVal] <
        targetPoints[targetPoints.length - 1][KRATargetFormFieldNames.TargetVal]) {
        for (let i = 0; i < targetPoints.length; i++) {
            const elem = targetPoints[i];
            maxValue = parseInt(elem[KRATargetFormFieldNames.TargetVal]);
            if(i === targetPoints.length -1) {
                maxValue = Infinity;
            }
            targetValueMap.set([minValue, maxValue], elem[KRATargetFormFieldNames.TargetPoints]);
            minValue = maxValue + 0.01;
        }
    } else {
        for (let i = 0; i < targetPoints.length; i++) {
            const elem = targetPoints[i];
            minValue = parseInt(elem[KRATargetFormFieldNames.TargetVal]);
            if(i === targetPoints.length -1){
                minValue = -Infinity;
            }
            targetValueMap.set([minValue, maxValue], elem[KRATargetFormFieldNames.TargetPoints]);
            maxValue = minValue - 0.01;
        }
    }
    for (const range of targetValueMap.keys()) {
        const [rangeStart, rangeEnd] = range;
        if (numAchievementScore >= rangeStart && numAchievementScore <= rangeEnd) {    
            rating = targetValueMap.get(range);
            break;
        } 
        rating = 0;
    }
    return rating;
};

