import { Typography } from '@mui/material';
import React from 'react';
import { LightTooltip } from '../../../../../../atoms/Tooltip/LightTooltip';
import theme from '../../../../../../foundation/theme';

const TextAttendees = (props) => {
    const {textAlign='',item} = props;
  return (
    <>
      <Typography
      textAlign={textAlign}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: 'rgba(0, 0, 0, 0.87)',
          cursor: 'default',
        }}
      >
        <LightTooltip
          title={item}
          placement="left-start"
          arrow
          bgColor="#212B36"
          color={theme.palette.neutral.light60}
          sx={{ margin: 2 }}
          enterDelay={500}
        >
          {item}
        </LightTooltip>
      </Typography>
    </>
  );
};

export default TextAttendees;
