export const GET_INTERVIEW_CONFIG_REQUEST = 'GET_INTERVIEW_CONFIG_REQUEST';
export const GET_INTERVIEW_CONFIG_SUCCESS = 'GET_INTERVIEW_CONFIG_SUCCESS';
export const GET_INTERVIEW_CONFIG_FAILURE = 'GET_INTERVIEW_CONFIG_FAILURE';

export const POST_INTERVIEW_INFO_REQUEST = 'POST_INTERVIEW_INFO_REQUEST';
export const POST_INTERVIEW_INFO_SUCCESS = 'POST_INTERVIEW_INFO_SUCCESS';
export const POST_INTERVIEW_INFO_FAILURE = 'POST_INTERVIEW_INFO_FAILURE';

export const GET_INTERVIEW_FEEDBACK_CONFIG_REQUEST = 'GET_INTERVIEW_FEEDBACK_CONFIG_REQUEST';
export const GET_INTERVIEW_FEEDBACK_CONFIG_SUCCESS = 'GET_INTERVIEW_FEEDBACK_CONFIG_SUCCESS';
export const GET_INTERVIEW_FEEDBACK_CONFIG_FAILURE = 'GET_INTERVIEW_FEEDBACK_CONFIG_FAILURE';