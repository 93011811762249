import API from './api';

export class GoalsServices extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL + '/performance-and-goals/goal/' || '';
  constructor() {
    super(GoalsServices.baseURL);
  }
  getGoalsConfig = async (roleId, goalId,moduleId) => (
    this.responsify(await this.instance.get('details', {
      params: {
        id: goalId, roleId,moduleId
      }
    }))
  );

  approveOrRejectGoal = async (body) => {
    const { userGoalId, remark,
      reasons,
      statusId } = body;
    return await this.instance.post('submit-approval',
      {
        userGoalId, remark,
        reasons,
        statusId
      });
  };

  postGoalsInfo = async (goalsFormInfo) => (
    this.responsify(await this.instance.post('create', goalsFormInfo)));

  getAllGoalsList = async (roleId, searchQuery,moduleId) => (
    this.responsify(await this.instance.get(`fetch-valid-goals?roleId=${roleId}`, { params: { searchQuery ,moduleId} })));

  getDropdownOptions = async (fieldName) => (
    this.responsify(await this.instance.get(`lookup-details/${fieldName}`))
  );

  getAllCompetencies = async () => (
    this.responsify(await this.instance.get('/competencies/list'))
  );

  getAllUserOptin = async (roleId) => (
    this.responsify(await this.instance.get('assignees', { params: { roleId } }))
  );

  postGoalsAssignInfo = async (goalAssignInfo) => (
    this.responsify(await this.instance.post('assign-goals', goalAssignInfo)));

  postGoalActivation = async (goalActivateInfo) => (
    this.responsify(await this.instance.post('activate-goals', goalActivateInfo)));
  
  postGoalDeActivation = async (goalActivateInfo) => (
    this.responsify(await this.instance.post('de-activate-goals', goalActivateInfo)));

  getAllAppraisalList = async (roleId, searchQuery,moduleId) => (
    this.responsify(await this.instance.get(`appraisal/list?roleId=${roleId}`, { params: { searchQuery ,moduleId } })));

  getAppraisalDetails = async (goalId, roleId,moduleId) => (
    this.responsify(await this.instance.get('appraisal/details', { params: { goalId, roleId,moduleId } })));

  postAppraisalDetails = async (data) => (
    this.responsify(await this.instance.post('submit-appraisal', data)));

  getRatingScales = async (data) => (
    this.responsify(await this.instance.get('get-default-rating-scale', data)));

  getAppraiserRemarks = async (appraisalId) => (
      this.responsify(await this.instance.get('get-appraiser-remarks',{params:{appraisalId}})));

  postAppraiserRemarks = async (competencyAppraiserRemarks,kraPerformanceAppraiserRemarks,appraisalId) => (
    this.responsify(await this.instance.post('post-appraiser-remarks',{competencyAppraiserRemarks,kraPerformanceAppraiserRemarks,goalId:appraisalId})));
  
  statusUpdateAppraisal = async (body) =>
    this.responsify(await this.instance.post('status-update-appraisal', body));

  BulkApproveOrRejectAppraisal = async (body) =>
    this.responsify(await this.instance.post('bulk-approver-or-reject-appraisal', body));

  performanceScoreGrade = async () => (
    this.responsify(await this.instance.get('performance_score_grade'))
  );
}