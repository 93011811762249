import { Box, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DynamicBreadcrumb from '../../atoms/Breadcrumbs/dynamicBreadcrumb';
import { Outlet, useLocation } from 'react-router-dom';
import SubHeader from './SubHeader';
import { getAllAppraisalList, getAllGoalsList } from '../../redux/goals/action';
import { useDispatch, useSelector } from 'react-redux';
import { AppLevelPermissions } from '../OnBoarding/Organization/constants/onboarding.constants';
import { fetchPerformancePermissions } from '../../redux/modulePermissions/action';
import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import CircularLoader from '../../atoms/CircularLoader/circular-loader';
import TryAgain from '../molecules/ErrorState/error.component';
import {
  Appraisal,
  PerformanceChild,
  ViewGoal,
} from './Goals/common/constants';

export default function Performance() {
  const { currentUserRoleId, appPermissions } = useSelector(
    (state) => state.main
  );
  const dispatch = useDispatch();
  const {
    requestProcessing: {
      [APIRequestStatus.FETCH_PERFORMANCE_PERMISSIONS]:
        performancePermissionsLoading,
    },
  } = useSelector((state) => state.modulePermissions);
  const { performancePermission } = useSelector(
    (state) => state.modulePermissions
  );
  const moduleId = performancePermission?.[PerformanceChild]?.id;
  const location = useLocation();
  const paths = location.pathname.split('/');
  const currentPath = paths[paths.length - 1];

  useEffect(() => {
    if (appPermissions && currentUserRoleId) {
      if (appPermissions[AppLevelPermissions.Performance]) {
        dispatch(
          fetchPerformancePermissions(
            currentUserRoleId,
            appPermissions[AppLevelPermissions.Performance].id
          )
        );
      }
    }
  }, [currentUserRoleId, appPermissions]);

  const handleSearch = (searchInput) => {
    switch (currentPath) {
      case ViewGoal:
        dispatch(getAllGoalsList(currentUserRoleId, searchInput,moduleId));
        break;
      case Appraisal:
        dispatch(getAllAppraisalList(currentUserRoleId, searchInput, moduleId));
        break;
      default:
        break;
    }
  };

  if (performancePermissionsLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <Box m={4} pl={4}>
      <CircularLoader
        show={performancePermissionsLoading === LoadingStatus.Loading}
      />
      <Box>
        <SubHeader handleSearch={handleSearch} />
        <Box my={3}>
          <DynamicBreadcrumb />
          <Box mt={1}>
            <Divider />
          </Box>
        </Box>
      </Box>
      {performancePermissionsLoading === LoadingStatus.Success && <Outlet />}
    </Box>
  );
}
