import {
  Box,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import CardUI from '../../../shared/components/ui-elements/CardUI';
import Footer from '../../footer/Footer';
import ReportingDetails from '../../../shared/components/claim-forms/ReportingDetails';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useLocation } from 'react-router-dom';
import Modal from '../../../shared/components/ui-elements/Modal';
import ButtonUI from '../../../shared/components/ui-elements/ButtonUI';
import { useNavigate } from 'react-router-dom';
import {
  checkFilesCountHandler,
  generateCurrentTimeStamp,
  generateFormData,
  validateNumber,
} from '../common-services/functions';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { postFilesData, putFormData } from '../common-services/services';
import UserProfile from '../../../shared/components/claim-forms/UserProfile';

const CommissionEdit = () => {
  // const uniqueNum = Math.floor(Math.random() * 10000000000) + 10000000000;
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const params = useLocation();
  const [claimsData, setClaimsData] = useState(params.state.claimsData);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');

  const [inputs, setInputs] = useState({
    reportingOfficer: 'Kelvin Lionel',
    hod: 'Benjamin Koh',
    costCenter: 'Singapore Labour',
    claimReferenceNumber: claimsData.referenceNumber,
    nameOfClaimant: '',
    personnelNo: '',
    designation: '',
    department: '',
    claimsMonth: claimsData?.claimMonth ? claimsData?.claimMonth : '',
    totalPaymentAmount: claimsData?.amount ? claimsData?.amount : '',
    description: claimsData?.description ? claimsData?.description : '',
    cardsApprove: claimsData?.cardsApproved ? claimsData?.cardsApproved : '',
    commissionPayble: claimsData?.commissionPayable
      ? claimsData?.commissionPayable
      : '',
  });

  const [type] = useState(params.state.type);
  const [mutliplyNumber] = useState(9);

  const commissionPaybleOptions = ['Monthly', 'Quarterly', 'Yearly'];
  let userData = JSON.parse(localStorage.getItem('userData'));
  let asProfile = localStorage.getItem('asProfile');

  const handleChangeInput = (e, key = null, key1) => {
    const values = { ...inputs };

    if (key) values[key] = e;
    else {
      if (key1) {
        const validate = validateNumber(e.target.name, e.target.value);
        if (!validate) {
          return false;
        }
      }
      values[e.target.name] = e.target.value;
      mutliplyNoOfCardApprove(values);
    }
    setInputs(values);
  };

  // no of cards approve multiply logic start
  const mutliplyNoOfCardApprove = (each) => {
    const { cardsApprove } = each;
    if (cardsApprove === '') {
      each.totalPaymentAmount = '';
      return false;
    }
    if (Number(cardsApprove) > 0) {
      let multiplication = Number(cardsApprove) * mutliplyNumber;
      each.totalPaymentAmount = multiplication.toFixed(2);
    }
  };
  // no of cards approve multiply logic end

  const cardData = [
    {
      post: 'Reporting Officer',
      name:
        userData.userDetails.reportingTo.firstName +
        ' ' +
        userData.userDetails.reportingTo.lastName,
    },
    {
      post: 'HOD',
      name: userData.userDetails.HOD,
    },
    {
      post: 'Cost Center',
      name: userData.userDetails.costCenter,
    },
  ];

  const validateFields = () => {
    if (
      inputs.claimsMonth == undefined ||
      inputs.claimsMonth == null ||
      inputs.claimsMonth == ''
    ) {
      window.alert('Please Enter Claims Month!');
      return;
    }

    if (
      inputs.cardsApprove == undefined ||
      inputs.cardsApprove == null ||
      inputs.cardsApprove == ''
    ) {
      window.alert('Please Enter A Number Of Approved Cards !');
      return;
    }
    if (
      inputs.commissionPayble == undefined ||
      inputs.commissionPayble == null ||
      inputs.commissionPayble == ''
    ) {
      window.alert('Please Enter A Payble Commission !');
      return;
    }
    if (
      inputs.description == undefined ||
      inputs.description == null ||
      inputs.description == ''
    ) {
      window.alert('Please Enter Description !');
      return;
    }
    return true;
  };

  // create object for send to the api start.
  const generateDataForClaimRequest = (
    inputs,
    userData,
    dateTime,
    callType
  ) => {
    let finalFormData = [];
    let updateFormObject = {};
    let callForPost = callType;

    if (inputs) {
      return (updateFormObject = {
        typeId: 4,
        id: claimsData && claimsData.id,
        employee: userData && userData.userDetails.id,
        claimReferenceNumber: inputs.claimReferenceNumber.toString(),
        cardsApproved: Number(inputs.cardsApprove),
        commissionPayable: inputs.commissionPayble,
        claimMonth: inputs.claimsMonth,
        amount: Number(inputs.totalPaymentAmount),
        description: inputs.description,
        submissionDate: dateTime,
        filePaths: [...claimsData.attachment],
        status: callForPost,
      });
    }
  };
  // create object for send to the api end.
  const submitData = async (callType) => {
    const dateTime = generateCurrentTimeStamp();
    setFormSubmitted(true);
    setButtonClicked(callType);
    let validate = validateFields();

    if (!validate) {
      setFormSubmitted(false);
      return;
    }
    let finalFormData = generateDataForClaimRequest(
      inputs,
      userData,
      dateTime,
      callType
    );

    // POST call for submit form data.
    let businessTravelsResponse = await putFormData(finalFormData);

    if (businessTravelsResponse === 201) {
      let successMessage = '';
      if (callType === 'PENDING') {
        successMessage = 'Claim request submitted successfully !';
      } else {
        successMessage = 'Draft saved successfully !';
      }
      setFormSubmitted(false);
      openSuccessPopUp(successMessage);
    } else {
      setFormSubmitted(false);
      window.alert('Something went wrong');
    }
  };

  // On file select (from the pop up)
  const onFileChange = async (e, refNumber, typeId = 4) => {
    if (!checkFilesCountHandler(e, claimsData)) {
      return;
    }

    // Generates form data for file upload.
    let formData = await generateFormData(e, refNumber, typeId);

    // Send form data for file upload.
    let updatedPaths = await postFilesData(formData);

    // Sets updated paths received from POST api call.
    const newArr = { ...claimsData };
    for (let paths of updatedPaths) {
      newArr.attachment.push(paths);
    }
    setClaimsData(newArr);
  };

  // remove attachment from claims data start
  const removeAttachement = (i) => {
    const newArr = { ...claimsData };
    newArr.attachment.splice(i, 1);
    setClaimsData(newArr);
  };
  // remove attachment from claims data end

  /** Close the modal */
  const closeBackdropHandler = () => {
    setIsBackdropOpen(false);
    navigate('/dashboard', {
      state: {
        claimType: '4',
      },
    });
  };

  // Open Modal When api response succesfully for(Draft/Submit)
  const openSuccessPopUp = (successMessage) => {
    setSuccessMessage(successMessage);
    setIsBackdropOpen(true);
  };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }} className='mar-top'>
        <Container>
          <Grid container>
              <ReportingDetails cardData={cardData} />
            
              <UserProfile
                name={userData && userData.userDetails.firstName}
                id={userData && userData.userDetails.id}
                designation={userData.userDetails?.jobTitle?.name}
                department={userData.userDetails?.department?.name}
              />
            <Grid xs={11}>
              <Box>
                <Box
                  className='form-main'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '1em',
                    marginBottom: '4em',
                  }}
                >
                  <Grid xs={11}>
                    <CardUI className='form-heading-wrap'>
                      <Grid
                        xs={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Grid xs={6}>
                          <h4>Commission Claims</h4>
                        </Grid>
                        <Grid
                          xs={6}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <h4>
                            Claim Reference Number :
                            {inputs.claimReferenceNumber}
                          </h4>
                        </Grid>
                      </Grid>
                    </CardUI>
                    <CardUI className='form-content-wrap'>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Claim Month *</label>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              variant='inline'
                              inputFormat='MM/yyyy'
                              maxDate={new Date()}
                              minDate={
                                new Date(
                                  userData && userData.userDetails.dateOfJoining
                                )
                              }
                              views={['year', 'month']}
                              margin='normal'
                              id='date-picker-inline'
                              value={inputs.claimsMonth}
                              onChange={(e) =>
                                handleChangeInput(e, 'claimsMonth')
                              }
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              renderInput={(params) => (
                                <TextField
                                  size='small'
                                  fullWidth
                                  variant='outlined'
                                  {...params}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>No of Cards Approve *</label>
                          <TextField
                            type='number'
                            required
                            variant='outlined'
                            color='primary'
                            size='small'
                            value={inputs.cardsApprove}
                            name='cardsApprove'
                            fullWidth
                            onChange={(e) => {
                              handleChangeInput(e, null, 'number');
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Commission Payable *</label>
                          <FormControl
                            sx={{ minWidth: 120 }}
                            fullWidth
                            size='small'
                          >
                            <Select
                              value={inputs.commissionPayble}
                              onChange={(e) => handleChangeInput(e)}
                              name='commissionPayble'
                              displayEmpty
                              inputProps={{
                                'aria-label': 'Without label',
                              }}
                              variant='outlined'
                              autoWidth
                            >
                              {commissionPaybleOptions &&
                                commissionPaybleOptions.map((each, i) => (
                                  <MenuItem key={i} value={each}>
                                    {each}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Description *</label>
                          <TextField
                            required
                            variant='outlined'
                            color='primary'
                            size='small'
                            value={inputs.description}
                            name='description'
                            fullWidth
                            onChange={(e) => handleChangeInput(e)}
                          />
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Total Payment Amount *</label>
                          <TextField
                            type='number'
                            required
                            disabled
                            variant='outlined'
                            color='primary'
                            size='small'
                            value={inputs.totalPaymentAmount}
                            name='totalPaymentAmount'
                            fullWidth
                            onChange={(e) => handleChangeInput(e)}
                          />
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor='icon-button-file'>Attachement</label>
                          <input
                            multiple
                            accept='image/pdf*'
                            type='file'
                            onChange={(e) =>
                              onFileChange(e, inputs.claimReferenceNumber)
                            }
                          />

                          {claimsData.attachment &&
                            claimsData.attachment.map((each, i) => {
                              return (
                                <div key={i} className='file-path-wrap'>
                                  {/* <h6>{each.split('/')[4]}</h6> */}
<h6>{inputs.claimReferenceNumber}_{i+1}</h6>
 <IconButton
  onClick={() => removeAttachement(i)}
                                    disableTouchRipple
                                  >
                                    <CancelRoundedIcon />
                                  </IconButton>
                                </div>
                              );
                            })}
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}></Grid>
                      </Grid>
                    </CardUI>
                  </Grid>
                </Box>

                <Grid style={{ position: 'relative' }}>
                  <Footer
                    type={type}
                    fromEdit={true}
                    onclick={() => submitData('PENDING')}
                    onSaveDraft={() => submitData('DRAFT')}
                    disabledSubmit={formSubmitted}
                    buttonClicked = {buttonClicked}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Modal
        show={isBackdropOpen}
        onCancel={closeBackdropHandler}
        header={<h1>Success</h1>}
        contentClass='modal-content'
        footerClass='footer-content'
        submitButton={
          <ButtonUI
            backgroundColor='#00AC94'
            color='#ffffff'
            size='small'
            padding='3px 20px'
            onclick={closeBackdropHandler}
          >
            Ok
          </ButtonUI>
        }
      >
        <h4>{successMessage}</h4>
      </Modal>
    </React.Fragment>
  );
};
export default CommissionEdit;
