import React, { useEffect, useState } from 'react';
import { Box, Typography, styled, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SearchBar from '../../atoms/SearchBar/SearchBar';
import { getPerforamceTab } from './Utlis/goals.util';

export default function SubHeader(props) {
  const { handleSearch } = props;
  const [value, setValue] = useState([]);
  const navigate = useNavigate();
  const { permissions } = useSelector((state) => state.onBoarding);

  const handleChange = (event) => {
    navigate(`/performance/${event.target.value}`);
  };

  return (
    <Box sx={{ display: 'flex', gap: 7 }}>
      <FormControl variant='standard' sx={{ width: 150, border: 0, mt: 1 }}>
        <StyledSelect
          displayEmpty
          value={value}
          onChange={handleChange}
          renderValue={(selected) => {
            if (selected) {
              return 'Performance';
            }
          }}
        >
          {getPerforamceTab.map((item)=> {
            const {name, label} = item ;
            return(
              <MenuItem key={name} value={name}>{label}</MenuItem>
            );
          })    } 
        </StyledSelect>
      </FormControl>

      <SearchBar
        handleSearch={handleSearch}
      />
    </Box>
  );
}

const StyledSelect = styled(Select)`
  height: 36px;
  &.MuiInput-underline:before,
  &.MuiInput-underline:after {
    display: none;
  }
`;
