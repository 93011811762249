export const RESET_USER_INFO = 'RESET_USER_INFO';

export const POST_USER_INFO_REQUEST = 'POST_USER_INFO_REQUEST';
export const POST_USER_INFO_SUCCESS = 'POST_USER_INFO_SUCCESS';
export const POST_USER_INFO_FAILURE = 'POST_USER_INFO_FAILURE';

export const UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAILURE = 'UPDATE_USER_INFO_FAILURE';

export const PUBLISH_USER_REQUEST = 'PUBLISH_USER_REQUEST';
export const PUBLISH_USER_SUCCESS = 'PUBLISH_USER_SUCCESS';
export const PUBLISH_USER_FAILURE = 'PUBLISH_USER_FAILURE';

export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE';

export const GET_USER_DOCUMENT_LIST_REQUEST = 'GET_USER_DOCUMENT_LIST_REQUEST';
export const GET_USER_DOCUMENT_LIST_SUCCESS = 'GET_USER_DOCUMENT_LIST_SUCCESS';
export const GET_USER_DOCUMENT_LIST_FAILURE = 'GET_USER_DOCUMENT_LIST_FAILURE';

export const GET_USER_GENDER_REQUEST = 'GET_USER_GENDER_REQUEST';
export const GET_USER_GENDER_SUCCESS = 'GET_USER_GENDER_SUCCESS';
export const GET_USER_GENDER_FAILURE = 'GET_USER_GENDER_FAILURE';

export const GET_USER_BLOOD_GROUP_REQUEST = 'GET_USER_BLOOD_GROUP_REQUEST';
export const GET_USER_BLOOD_GROUP_SUCCESS = 'GET_USER_BLOOD_GROUP_SUCCESS';
export const GET_USER_BLOOD_GROUP_FAILURE = 'GET_USER_BLOOD_GROUP_FAILURE';

export const GET_USER_WORK_STATUS_REQUEST = 'GET_USER_WORK_STATUS_REQUEST';
export const GET_USER_WORK_STATUS_SUCCESS = 'GET_USER_WORK_STATUS_SUCCESS';
export const GET_USER_WORK_STATUS_FAILURE = 'GET_USER_WORK_STATUS_FAILURE';

export const GET_USER_DESIGNATION_REQUEST = 'GET_USER_DESIGNATION_REQUEST';
export const GET_USER_DESIGNATION_SUCCESS = 'GET_USER_DESIGNATION_SUCCESS';
export const GET_USER_DESIGNATION_FAILURE = 'GET_USER_DESIGNATION_FAILURE';

export const GET_USER_ONBOARDING_MENU_REQUEST = 'GET_USER_ONBOARDING_MENU_REQUEST';
export const GET_USER_ONBOARDING_MENU_SUCCESS = 'GET_USER_ONBOARDING_MENU_SUCCESS';
export const GET_USER_ONBOARDING_MENU_FAILURE = 'GET_USER_ONBOARDING_MENU_FAILURE';

export const UPDATE_USER_STATUS_REQUEST = 'UPDATE_USER_STATUS_REQUEST';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_FAILURE = 'UPDATE_USER_STATUS_FAILURE';

export const GET_USER_COST_CENTRE_REQUEST = 'GET_USER_COST_CENTRE_REQUEST';
export const GET_USER_COST_CENTRE_SUCCESS = 'GET_USER_COST_CENTRE_SUCCESS';
export const GET_USER_COST_CENTRE_FAILURE = 'GET_USER_COST_CENTRE_FAILURE';

export const GET_BUSINESS_UNITS_REQUEST = 'GET_BUSINESS_UNITS_REQUEST';
export const GET_BUSINESS_UNITS_SUCCESS = 'GET_BUSINESS_UNITS_SUCCESS';
export const GET_BUSINESS_UNITS_FAILURE = 'GET_BUSINESS_UNITS_FAILURE';

export const GET_EMPLOYMENT_DETAILS_REQUEST = 'GET_EMPLOYMENT_DETAILS_REQUEST';
export const GET_EMPLOYMENT_DETAILS_SUCCESS = 'GET_EMPLOYMENT_DETAILS_SUCCESS';
export const GET_EMPLOYMENT_DETAILS_FAILURE = 'GET_EMPLOYMENT_DETAILS_FAILURE';

export const GET_ORG_DIVISIONS_REQUEST = 'GET_ORG_DIVISIONS_REQUEST';
export const GET_ORG_DIVISIONS_SUCCESS = 'GET_ORG_DIVISIONS_SUCCESS';
export const GET_ORG_DIVISIONS_FAILURE = 'GET_ORG_DIVISIONS_FAILURE';

export const GET_USER_NATIONALITY_REQUEST = 'GET_USER_NATIONALITY_REQUEST';
export const GET_USER_NATIONALITY_SUCCESS = 'GET_USER_NATIONALITY_SUCCESS';
export const GET_USER_NATIONALITY_FAILURE = 'GET_USER_NATIONALITY_FAILURE';

export const GET_USER_RELIGION_REQUEST = 'GET_USER_RELIGION_REQUEST';
export const GET_USER_RELIGION_SUCCESS = 'GET_USER_RELIGION_SUCCESS';
export const GET_USER_RELIGION_FAILURE = 'GET_USER_RELIGION_FAILURE';

export const GET_USER_RACE_REQUEST = 'GET_USER_RACE_REQUEST';
export const GET_USER_RACE_SUCCESS = 'GET_USER_RACE_SUCCESS';
export const GET_USER_RACE_FAILURE = 'GET_USER_RACE_FAILURE';

export const GET_USER_FUNCTION_REQUEST = 'GET_USER_FUNCTION_REQUEST';
export const GET_USER_FUNCTION_SUCCESS = 'GET_USER_FUNCTION_SUCCESS';
export const GET_USER_FUNCTION_FAILURE = 'GET_USER_FUNCTION_FAILURE';

export const GET_USER_CATEGORY_REQUEST = 'GET_USER_CATEGORY_REQUEST';
export const GET_USER_CATEGORY_SUCCESS = 'GET_USER_CATEGORY_SUCCESS';
export const GET_USER_CATEGORY_FAILURE = 'GET_USER_CATEGORY_FAILURE';

export const GET_USER_WORK_AUTHORIZATION_REQUEST = 'GET_USER_WORK_AUTHORIZATION_REQUEST';
export const GET_USER_WORK_AUTHORIZATION_SUCCESS = 'GET_USER_WORK_AUTHORIZATION_SUCCESS';
export const GET_USER_WORK_AUTHORIZATION_FAILURE = 'GET_USER_WORK_AUTHORIZATION_FAILURE';

export const RESET_WORK_AUTH = 'RESET_WORK_AUTH';

export const UPDATE_DOC_LIST = 'UPDATE_DOC_LIST';

export const ADD_EMPLOYEE_TAB = 'ADD_EMPLOYEE_TAB';

export const DELETE_EMPLOYEE_TAB = 'DELETE_EMPLOYEE_TAB';

export const ADD_EDUCATION_TAB = 'ADD_EDUCATION_TAB';

export const DELETE_EDUCATION_TAB = 'DELETE_EDUCATION_TAB';

export const DUPLICATE_EDUCATION_TAB = 'DUPLICATE_EDUCATION_TAB';

export const DUPLICATE_EMPLOYEE_TAB = 'DUPLICATE_EMPLOYEE_TAB';

export const GET_EMPLOYEE_CONFIG_REQUEST = 'GET_EMPLOYEE_CONFIG_REQUEST';
export const GET_EMPLOYEE_CONFIG_SUCCESS = 'GET_EMPLOYEE_CONFIG_SUCCESS';
export const GET_EMPLOYEE_CONFIG_FAILURE = 'GET_EMPLOYEE_CONFIG_FAILURE';

export const GET_EDUCATION_CONFIG_REQUEST = 'GET_EDUCATION_CONFIG_REQUEST';
export const GET_EDUCATION_CONFIG_SUCCESS = 'GET_EDUCATION_CONFIG_SUCCESS';
export const GET_EDUCATION_CONFIG_FAILURE = 'GET_EDUCATION_CONFIG_FAILURE';

export const POST_EMPLOYEE_INFO_REQUEST = 'POST_EMPLOYEE_INFO_REQUEST';
export const POST_EMPLOYEE_INFO_SUCCESS = 'POST_EMPLOYEE_INFO_SUCCESS';
export const POST_EMPLOYEE_INFO_FAILURE = 'POST_EMPLOYEE_INFO_FAILURE';

export const POST_EDUCATION_INFO_REQUEST = 'POST_EDUCATION_INFO_REQUEST';
export const POST_EDUCATION_INFO_SUCCESS = 'POST_EDUCATION_INFO_SUCCESS';
export const POST_EDUCATION_INFO_FAILURE = 'POST_EDUCATION_INFO_FAILURE';