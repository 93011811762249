import React, { useEffect, useState } from 'react';
import { Box, Typography, styled, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SearchBar from '../../atoms/SearchBar/SearchBar';
import { useNavigate } from 'react-router-dom';
import { onboarding, userProfile } from './Organization/constants/onboarding.constants';
import { useSelector } from 'react-redux';
import { getOnboardingDropdownList } from './Organization/utils/onboarding.util';
import { permissionsObj } from './Organization/RolesAndPermissions/constants/roles-permissions.constants';

export default function SubHeader(props) {
  const { handleSearch } = props;
  const [value, setValue] = useState([]);
  const navigate = useNavigate();
  const { permissions } = useSelector((state) => state.onBoarding);
  const { appPermissions } = useSelector((state) => state.main);
  const { userProfilePermissions } = useSelector((state) => state.modulePermissions);
  const onboardingDropdownList = getOnboardingDropdownList(permissions, appPermissions, userProfilePermissions).filter((item) => item.show);

  const dropdownOptions = onboardingDropdownList.map((item) => {
    const { name, label, path } = item;
    if (!appPermissions?.[userProfile]?.[permissionsObj.allowView]) {
      if (name !== 'user-onboarding') {
        return { ...item, show: true };
      } else {
        return { ...item, show: false };
      }
    }
    if (!appPermissions?.[onboarding]?.[permissionsObj.allowView]) {
      if (name !== onboarding) {
        return { ...item, show: true };
      } else {
        return { ...item, show: false };
      }
    }
    else {
      return item;
    }
  }).filter((item) => item?.show);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    navigate(value.path);
  };

  return (
    <Box sx={{ display: 'flex', gap: 7 }}>
      <FormControl variant='standard' sx={{ width: 150, border: 0, mt: 1 }}>
        <StyledSelect
          displayEmpty
          value={value}
          onChange={handleChange}
          renderValue={(selected) => {
            if (selected) {
              return 'Onboarding';
            }
          }}
        >
          {dropdownOptions.map((item) => {
            const { name, label, path } = item;
            return (
              <StyledMenuItem
                key={name}
                value={{ name, path }}
                disabled={name === onboarding || name === 'user-onboarding'}
              >
                {label}
              </StyledMenuItem>
            );
          })}
        </StyledSelect>
      </FormControl>

      <SearchBar handleSearch={handleSearch} />
    </Box>
  );
}

const StyledSelect = styled(Select)`
  height: 36px;
  &.MuiInput-underline:before,
  &.MuiInput-underline:after {
    display: none;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.Mui-disabled{
    background-color:#434B4A;
    color:#F0F0F0;
    opacity:1;
  }
`;