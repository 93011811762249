import { clone } from 'lodash';
import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import { GET_ALL_CANDIDATE_LIST_FAILURE, GET_ALL_CANDIDATE_LIST_REQUEST, GET_ALL_CANDIDATE_LIST_SUCCESS, GET_CANDIDATE_ASSESSMENT_FALIURE, GET_CANDIDATE_ASSESSMENT_REQUEST, GET_CANDIDATE_ASSESSMENT_SUCCESS, GET_CANDIDATE_CONFIG_FAILURE, GET_CANDIDATE_CONFIG_REQUEST, GET_CANDIDATE_CONFIG_SUCCESS, POST_CANDIDATE_DRAFT_FAILURE, POST_CANDIDATE_DRAFT_REQUEST, POST_CANDIDATE_DRAFT_SUCCESS, POST_CANDIDATE_INFO_FAILURE, POST_CANDIDATE_INFO_REQUEST, POST_CANDIDATE_INFO_SUCCESS, RESET_CANDIDATE } from './actionTypes';

const initialState = {
  requestProcessing: {
    [APIRequestStatus.GET_CANDIDATE_CONFIG]: LoadingStatus.Loading,
    [APIRequestStatus.POST_CANDIDATE_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.GET_ALL_CANDIDATE_LIST]: LoadingStatus.Loading,
    [APIRequestStatus.POST_CANDIDATE_INFO_DRAFT]: LoadingStatus.Loading,
    [APIRequestStatus.GET_CANDIDATE_ASSESSMENT]: LoadingStatus.Loading
  },
  candidateConfig: [],
  candidateFormData: {},
  candidateList: [],
  candidateIdPropertiesMap: null,
  isPublishedCandidate: false,
  isInterviewer: false,
  interviewConfig: [],
  feedbackConfig: [],
  interviewInfo: [],
  candidateInfo: {}
};

export const candidateReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_CANDIDATE_CONFIG_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CANDIDATE_CONFIG]: LoadingStatus.Loading
        }
      };
    }

    case GET_CANDIDATE_CONFIG_SUCCESS: {
      const res = action.payload;
      const { formConfig, candidateInfo } = res;
      const { isPublished, ...formData } = candidateInfo;
      return {
        ...state,
        candidateConfig: formConfig,
        candidateFormData: formData,
        isPublishedCandidate: isPublished,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CANDIDATE_CONFIG]: LoadingStatus.Success
        }
      };
    }

    case GET_CANDIDATE_CONFIG_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CANDIDATE_CONFIG]: LoadingStatus.Failure
        }
      };
    }

    case POST_CANDIDATE_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_CANDIDATE_INFO]: LoadingStatus.Loading
        }
      };
    }

    case POST_CANDIDATE_INFO_SUCCESS: {
      const { candidateFormInfo, id } = action.payload;
      id && Object.assign(candidateFormInfo, { id });
      return {
        ...state,
        candidateFormData: candidateFormInfo,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_CANDIDATE_INFO]: LoadingStatus.Success
        }
      };
    }

    case POST_CANDIDATE_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_CANDIDATE_INFO]: LoadingStatus.Failure
        }
      };
    }

    case GET_ALL_CANDIDATE_LIST_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_CANDIDATE_LIST]: LoadingStatus.Loading
        }
      };
    }

    case GET_ALL_CANDIDATE_LIST_SUCCESS: {
      const { candidateList, isInterviewer } = action.payload;
      const candidateIdPropertiesMap = {};
      candidateList.forEach((item) => {
        const { id, ...rest } = item;
        Object.assign(candidateIdPropertiesMap, { [id]: rest });
      });
      return {
        ...state,
        candidateList: candidateList,
        candidateIdPropertiesMap: candidateIdPropertiesMap,
        isInterviewer: isInterviewer,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_CANDIDATE_LIST]: LoadingStatus.Success
        }
      };
    }

    case GET_ALL_CANDIDATE_LIST_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_CANDIDATE_LIST]: LoadingStatus.Failure
        }
      };
    }

    case RESET_CANDIDATE: {
      return initialState;
    }

    case POST_CANDIDATE_DRAFT_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_CANDIDATE_INFO_DRAFT]: LoadingStatus.Loading
        }
      };
    }

    case POST_CANDIDATE_DRAFT_SUCCESS: {
      const { candidateFormInfo, id } = action.payload;
      id && Object.assign(candidateFormInfo, { id });
      return {
        ...state,
        candidateFormData: candidateFormInfo,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_CANDIDATE_INFO_DRAFT]: LoadingStatus.Success
        }
      };
    }

    case POST_CANDIDATE_DRAFT_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_CANDIDATE_INFO_DRAFT]: LoadingStatus.Failure
        }
      };
    }

    case GET_CANDIDATE_ASSESSMENT_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CANDIDATE_ASSESSMENT]: LoadingStatus.Loading
        }
      };
    }

    case GET_CANDIDATE_ASSESSMENT_SUCCESS: {
      const res = action.payload;
      const { formConfig, feedbackFormConfig, interviewInfo, candidateInfo } = res;
      const newConfig = feedbackFormConfig.map((item) => {
        return { ...item, isReadOnly: true };
      });
      return {
        ...state,
        interviewConfig: formConfig,
        feedbackConfig: newConfig,
        interviewInfo: interviewInfo,
        candidateInfo: candidateInfo,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CANDIDATE_ASSESSMENT]: LoadingStatus.Success
        }
      };
    }

    case GET_CANDIDATE_ASSESSMENT_FALIURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CANDIDATE_ASSESSMENT]: LoadingStatus.Failure
        }
      };
    }

    default: {
      return state;
    }
  }
};

