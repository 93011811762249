import React, { useEffect, useState } from 'react';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import AppraisalForm from './AppraisalForm/AppraisalForm';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getAppraisalDetails, resetGoals, scoreGrade } from '../../../redux/goals/action';
import { APIRequestStatus, LoadingStatus, ToastThemes } from '../../../constants/common';
import TryAgain from '../../molecules/ErrorState/error.component';
import { GoalsServices } from '../../../services/goals';
import { showToast } from '../../../utils/common.util';
import { PerformanceChild } from '../Goals/common/constants';

const AddAppraisal = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const idForAppraisalDetails = pathNames[pathNames.length - 1];
  const {
    requestProcessing: { [APIRequestStatus.GET_APPRAISAL_DETAILS]: appraisalLoading }
  } = useSelector((state) => state.goals);
  const { performancePermission } = useSelector((state) => state.modulePermissions);
  const moduleId = performancePermission?.[PerformanceChild]?.id;
  const { currentUserRoleId } = useSelector((state) => state.main);
  const [res,setRes] = useState();

  useEffect(() => {
    if (!isNaN(idForAppraisalDetails) && currentUserRoleId) {
      new GoalsServices().getRatingScales().then((res) => {
        setRes(res);
        dispatch(getAppraisalDetails(currentUserRoleId, res, idForAppraisalDetails,moduleId));
      }).catch((err) => {
        showToast(err, ToastThemes.error);
      });
    } 
    return (() => {
      dispatch(resetGoals());
    });
  }, [idForAppraisalDetails,currentUserRoleId ]);

  useEffect(async () => {
    const response = await new GoalsServices()
      .performanceScoreGrade();
      dispatch(scoreGrade(response));
}, []);

  if (appraisalLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
    return (
      <>
        <CircularLoader show={appraisalLoading === LoadingStatus.Loading} />
        {appraisalLoading === LoadingStatus.Success &&
          <div>
            <AppraisalForm appraisalId={idForAppraisalDetails} res={res} currentUserRoleId={currentUserRoleId} />
          </div>}
      </>
    );
  };

export default AddAppraisal;