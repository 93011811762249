import { Box, Container, Grid, Paper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import TabBar from '../../../atoms/Tabs/TabBar';
import AddClaimForm from './add-form-data/add-claim-form.component';
import { useSelector } from 'react-redux';
import ClaimedEmployeeDetails from '../../claim-view/claimed-employee-details';
import EntitlementsView from './entitlements/entitlement-view.component';
import { BuilderServices } from '../../../services/builder';
import DepartmentsTeam from './add-form-data/claim-team-building-form/departments-team-building';
import { isEmpty } from 'lodash';
import UnergizerEdit from './add-form-data/unergizer-claim-form/unergizer-claim-form-edit/unergizer-claim-form-edit.component';
import {
  UnergizerFieldConfig,
  initialVal,
} from './add-form-data/unergizer-claim-form/utils/unergizer-utils';
import UnergizerView from './add-form-data/unergizer-claim-form/unergizer-claim-form-view.component';
import { NewClaimsService } from '../../../services/newClaims';
import AccordionTabs from '../../../atoms/Accordian/Accordian';
import ClaimTRFView from './add-form-data/claim-trf/claim-trf-view';
import ComputerLoanView from './add-form-data/computer-loan/computer-loan-view';

const ManageClaimFormTab = (props) => {
  const [viewMode, setViewMode] = useState(false);
  const { claimData, trfClaimData, trfClaimConfig, trfClaimType, claimFormData, auditTrail } = useSelector(
    (state) => state.formBuilder
  );
  const paths = location.pathname.split('/');
  const claimId = Number(paths[paths.length - 1]);
  const [enitilements, setEnitilements] = useState();
  const [departementFomdata, setDepartementFomdata] = useState([{}]);
  const [teamBuildingData, setTeamBuildingData] = useState({});
  const [claimHeaderData, setClaimHeaderData] = useState({});
  const [departementDropDown, setDepartementDropDown] = useState([]);
  const [unergizerFormData, setUnergizerFormData] = useState([initialVal]);
  const [unergizerBenefit, setUnergizerBenefit] = useState();
  const claimTypeId = Number(paths[paths.length - 2]);

  const setBenefits = (benefitId, claimId) => {
    if (!isNaN(claimId)) {
      new BuilderServices()
        .getEntitlements(benefitId, claimTypeId, claimId)
        .then((res) => {
          if (res) {
            setEnitilements(res);
          }
        });
    } else {
      new BuilderServices()
        .getEntitlements(benefitId, claimTypeId)
        .then((res) => {
          if (res) {
            setEnitilements(res);
          }
        });
    }
  };

  const claimsTab = [
    {
      name: 'claim',
      label: claimData?.name || 'Claims',
      title: claimData?.name || 'Claims',
      hide: false,
      panel: (
        <AddClaimForm
          viewMode={viewMode}
          setViewMode={setViewMode}
          enitilements={enitilements}
          departementFomdata={departementFomdata}
          setTeamBuildingData={setTeamBuildingData}
          benefitId={claimData?.fkBenefitId?.id}
          isMultiDepartment={claimData?.isMultiDepartment}
          setClaimHeaderData={setClaimHeaderData}
          setDepartementFomdata={setDepartementFomdata}
          setDepartementDropDown={setDepartementDropDown}
          setBenefits={setBenefits}
          setUnergizerFormData={setUnergizerFormData}
          unergizerFormData={unergizerFormData}
          allowCopayment={claimData?.fkBenefitId?.allowCopayment}
          singleDeptAllowed={claimData?.departmentAdditionAllowed}
        />
      ),
    },
  ];

  useEffect(() => {
    if (claimData?.isMultiDepartment && claimData?.fkBenefitId) {
      if (!isNaN(claimId)) {
        new BuilderServices().getTeamBuildingDetails(claimId).then((res) => {
          setDepartementFomdata(res);
        });
      }
    }
    if (
      !claimData?.isMultiDepartment &&
      claimData?.isBenefitAttached &&
      claimData?.fkBenefitId
    ) {
      setBenefits(claimData?.fkBenefitId?.id), claimId;
    }
  }, [claimData]);

  useEffect(() => {
    if (claimData?.isUnergizer && claimData?.fkBenefitId?.id) {
      if (!isNaN(claimId)) {
        new NewClaimsService()
          .getUnergizer(claimData?.fkBenefitId?.id, claimId)
          .then((res) => {
            setUnergizerBenefit(res?.entitlementAmt);
            setUnergizerFormData(
              res?.unergizerData.length
                ? res?.unergizerData
                : [initialVal] || [initialVal]
            );
          });
      } else {
        new NewClaimsService()
          .getUnergizer(claimData?.fkBenefitId?.id)
          .then((res) => {
            setUnergizerBenefit(res?.entitlementAmt);
          });
      }
    }
  }, [claimData]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container>
        <Grid container>
          {viewMode ? (
            <ClaimedEmployeeDetails employee={claimHeaderData} />
          ) : (
            <ClaimedEmployeeDetails
              employee={!isEmpty(claimHeaderData) ? claimHeaderData : ''}
            />
          )}
          {trfClaimData.length > 0 &&
            trfClaimData.map((item, index) => {
              return (
                <AccordionTabs
                  key={index}
                  title={item.referenceNumber}
                  tabDetails={
                    <Box sx={{ flexGrow: 1, width: '100%' }}>
                      <ClaimTRFView
                        config={trfClaimConfig || []}
                        info={item || {}}
                      />
                    </Box>
                  }
                  expand="no"
                  width="100%"
                />
              );
            })}
          {claimData?.isUnergizer && claimData?.fkBenefitId && (
            <>
              {viewMode ? (
                <UnergizerView
                  formData={unergizerFormData}
                  config={UnergizerFieldConfig}
                />
              ) : (
                <UnergizerEdit
                  setFormData={setUnergizerFormData}
                  formData={unergizerFormData}
                  unergizerBenefit={unergizerBenefit}
                />
              )}
            </>
          )}
          {claimData?.isMultiDepartment && claimData?.fkBenefitId && (
            <DepartmentsTeam
              benifitId={claimData?.fkBenefitId?.id}
              departementFomdata={departementFomdata}
              setDepartementFomdata={setDepartementFomdata}
              teamBuildingData={teamBuildingData}
              viewMode={viewMode}
              departementDropDown={departementDropDown}
              setDepartementDropDown={setDepartementDropDown}
              claimHeaderData={claimHeaderData}
              isNew={isNaN(claimId)}
              singleDeptAllowed={claimData?.departmentAdditionAllowed}
              claimId={claimId}
            />
          )}
          {!claimData?.isMultiDepartment &&
            !claimData?.isUnergizer &&
            claimData?.isBenefitAttached &&
            claimData?.fkBenefitId &&
            !viewMode && (
              <EntitlementsView
                entitlements={enitilements}
                BenefitsAttached={claimData?.fkBenefitId}
              />
            )}
          <Box sx={{ flexGrow: 1, width: '100%' }}>
            <TabBar
              tabPanels={claimsTab}
              searchTabs
              disableCreate
              isScroll={false}
            />
          </Box>
          {claimData.claimTypeCode === 'CL' &&
            claimFormData?.status === 'APPROVED' && 
                <AccordionTabs
                  title={'Repayment Schedule'}
                  tabDetails={
                    <Box sx={{ flexGrow: 1, width: '100%' }}>
                      <ComputerLoanView
                        loanAmount={claimFormData?.amount}
                        months={claimFormData?.distance}
                        auditTrail={auditTrail}
                      />
                    </Box>
                  }
                  expand="no"
                  width="100%"
                  mt={1} 
                />
              }
        </Grid>
      </Container>
    </Box>
  );
};

export default ManageClaimFormTab;
