import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider } from '@mui/material';
import ActionHeader from '../../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import FormEdit from '../../../../OnBoarding/common/Form/FormEdit';
import { BenefitFormConfig } from '../../../comp-ben-utlis/comp-ben-utils';
import BenefitConfigButtons from './benefit-config-buttons/benefit-config-buttons.component';
import AmountButton from './benefit-config-buttons/amount-button/amount-button.component';
import StartDateButton from './benefit-config-buttons/start-date-button/start-date-button.component';
import BlockPeriodConfig from './benefit-config-buttons/block-period-config/block-period-config.component';
import {
  getMandatoryFields,
  getValidationErrors,
} from '../../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { isEmpty } from 'lodash';
import { showToast } from '../../../../../utils/common.util';
import { ToastThemes, toastMessage } from '../../../../../constants/common';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../../OnBoarding/Organization/constants/onboarding.constants';
import { CompBenServices } from '../../../../../services/compBen';
import { useDispatch, useSelector } from 'react-redux';
import { getBenefitsDetails } from '../../../../../redux/benefits/action';
import { SalaryStructureServices } from '../../../../../services/salaryServices';
import { CommonSevice } from '../../../../../services/common';
import { OnboardingServices } from '../../../../../services/onboarding';
import { getDropdownListValue } from '../../../../OnBoarding/common/Form/util/form.util';

const BenefitEdit = (props) => {
  const { setViewMode, isNewBenefit, benefitsData } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [benInfo, setBenInfo] = useState(
    benefitsData || { allowCopayment: false }
  );
  const [validationErrors, setValidationErrors] = useState({});
  const [showConfig, setShowConfig] = useState('');
  const [component, setComponent] = useState([]);
  const [proRateCondtions, setProRateCondtions] = useState([]);
  const [proRateMetaData, setProRateMetaData] = useState([]);
  const [autoCreatorRole, setAutoCreatorRole] = useState([]);
  const [autoCreator, setAutoCreator] = useState([]);

  const { currentUserRoleId } = useSelector((state) => state.main);

  useEffect(() => {
    setBenInfo(benefitsData);
  }, [benefitsData]);

  const handleDiscard = () => {
    if (isNewBenefit) {
      navigate(-1);
    } else {
      setViewMode(true);
    }
  };
  const requiredFields = useMemo(() => {
    return getMandatoryFields(BenefitFormConfig);
  }, [BenefitFormConfig]);

  const handleSave = async () => {
    const errors = getValidationErrors(benInfo, requiredFields);
    if (isEmpty(errors)) {
      const {
        fkLinkedPayStructureComponentId,
        prorateMethod,
        prorateMethodOptions,
        // autoCreator,
        // autoCreatorRole,
        ...rest
      } = benInfo;
      await new CompBenServices()
        .postBenefits({
          ...rest,
          fkLinkedPayStructureComponentId: fkLinkedPayStructureComponentId?.id,
          prorateMethodOptions: prorateMethodOptions?.map((item) =>
            Number(item.id)
          ),
          prorateMethod: prorateMethod?.id,
          // autoCreator: autoCreator?.id,
          // autoCreatorRole: autoCreatorRole?.id
        })
        .then((res) => {
          showToast(
            ToastMessages.success.replace(
              stringSubstitute,
              toastMessage.success
            ),
            ToastThemes.success
          );
          res &&
            isNewBenefit &&
            navigate(`/comp-ben/benefits/${res}`, { replace: true });
          !isNewBenefit && dispatch(getBenefitsDetails(res, currentUserRoleId));
          setViewMode(true);
        })
        .catch((err) => {
          showToast(err?.response?.data?.message[0], ToastThemes.error);
        });
    } else {
      setValidationErrors(errors);
    }
  };

  const formFields = useMemo(() => {
    return BenefitFormConfig.reduce((acc, item) => {
      const { fieldRefName } = item;
      let temp = { ...item };
      switch (fieldRefName) {
        case 'coPaymentAmount':
          temp = {
            ...temp,
            isHidden: !benInfo?.allowCopayment,
          };
          break;
        default: {
          break;
        }
      }
      if (!temp.isHidden) {
        acc.push(temp);
      }
      if (temp.isHidden) {
        delete benInfo[temp?.fieldRefName];
      }
      return acc;
    }, []);
  }, [benInfo]);

  const getDropdownOptions = (name) => {
    switch (name) {
      case 'fkLinkedPayStructureComponentId': {
        return component;
      }
      case 'prorateMethod': {
        return proRateCondtions;
      }
      case 'prorateMethodOptions': {
        return proRateMetaData;
      }
      // case 'autoCreatorRole': {
      //   return autoCreatorRole.map((role) => {
      //     const { id, role_name } = role;
      //     return getDropdownListValue(id, role_name);
      //   });
      // }
      // case 'autoCreator': {
      //   return autoCreator.map((role) => {
      //     const { id, displayName } = role;
      //     return getDropdownListValue(id, displayName);
      //   });
      // }
    }
  };

  const handleDropdownClick = (name) => {
    switch (name) {
      case 'fkLinkedPayStructureComponentId': {
        return new SalaryStructureServices()
          .getEntitlementDropdownOptions()
          .then((res) => {
            setComponent(res);
            return true;
          });
      }
      case 'prorateMethod': {
        return new CommonSevice().getDropDown('prorateMethods').then((res) => {
          setProRateCondtions(res);
          return true;
        });
      }

      case 'prorateMethodOptions': {
        return new CommonSevice()
          .getDropDown('prorateMethodOptions')
          .then((res) => {
            setProRateMetaData(res);
            return true;
          });
      }
      // case 'autoCreatorRole' : {
      //   return new OnboardingServices().getRolesList().then((res) => {
      //     setAutoCreatorRole(res);
      //     return true;
      //   });
      // }
      // case 'autoCreator': {
      //   return new OnboardingServices().getUserInRoles(benInfo.autoCreatorRole.id).then((res) => {
      //     setAutoCreator(res);
      //     return true;
      //   });
      // }
    }
  };

  switch (showConfig) {
    case 'amount': {
      return (
        <AmountButton
          setShowConfig={setShowConfig}
          setBenInfo={setBenInfo}
          object={benInfo?.formulaExpression || {}}
          name={benInfo?.name || ''}
        />
      );
    }
    case 'startDate': {
      return (
        <StartDateButton
          setShowConfig={setShowConfig}
          setBenInfo={setBenInfo}
          object={benInfo?.startDateConfig || {}}
          name={benInfo?.name || ''}
        />
      );
    }
    default: {
      return (
        <Box>
          <Box mb={3} mx={-3} mt={-3}>
            <ActionHeader
              labelText={benInfo?.name || 'New'}
              showDiscard
              showPublish
              showPublishModal={false}
              publishButtonText="Save"
              onPublishClick={handleSave}
              onClickDiscard={handleDiscard}
              showSave={false}
            />
          </Box>
          <FormEdit
            list={formFields}
            formInfo={benInfo}
            setFormData={setBenInfo}
            errors={validationErrors}
            setErrors={setValidationErrors}
            getDropdownOptions={getDropdownOptions}
            handleDropdownClick={handleDropdownClick}
          />
          <Divider sx={{ mt: 2, mb: 2 }} />
          <BenefitConfigButtons
            setShowConfig={setShowConfig}
            viewMode={false}
          />
        </Box>
      );
    }
  }
};

export default BenefitEdit;
