import React from 'react';
import ComponentHeader from './ComponentHeader';
import { Box, Grid, Typography } from '@mui/material';
import CurrencyFormatter from '../../../../../atoms/CurrencyFormater/CurrencyFormater';

const OtSection = (props) => {
  const { overtimeInfo, currencySymbol } = props;
  return (
    <Grid container rowSpacing={3} columnSpacing={8} pr={8}>
      <Grid item md={6} xs={12}>
        <ComponentHeader
          label="D. Overtime"
          total={overtimeInfo?.value}
          toolTip={overtimeInfo?.cpfWageClassificationCode}
        />
        {overtimeInfo?.otMetadata?.map((item, index) => {
          return (
            <Box
              key={index}
              marginTop={3}
              display="flex"
              justifyContent="space-between"
            >
              <Box>
                <Typography>{item?.name}</Typography>
                <br />
                <Typography>{`${currencySymbol} ${Number(item?.hourlyRate).toFixed(2)} X ${item?.multiplier} X ${item?.otHours} Hrs`}</Typography>
              </Box>
              <Box>
                <Typography>
                  <CurrencyFormatter value={Number(item?.amount).toFixed(2) || 0} />
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default OtSection;
