import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { giroFileDashboard, giroFileLabel, payrollDashboard, payrollLabel } from '../Constant/Constant';
import { Box } from '@mui/material';
import AllPayroll from './DashBoard/AllPayroll';
import BankGiroFileDashboard from './DashBoard/AllGiroFile';
import NavTabBar from '../../../atoms/Tabs/nav-tab-bar';
import { useSelector } from 'react-redux';
import { getAllowedTabsPayroll } from './Utils/PayrollUtils';

const PayrollOutlet = () => {
  const { payrollPermission } = useSelector((state) => state.modulePermissions);
  const navigate = useNavigate();
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const payrollTabId = pathNames[pathNames.length - 1];
  const dataTabs = [
    {
      id: payrollDashboard,
      name: payrollLabel,
    },
    {
      id: giroFileDashboard,
      name: giroFileLabel,
    },
  ];

  const tabsData = dataTabs.map((item) => {
    const { id, name } = item;
    return {
      id: id,
      name: name,
      path: `/payroll/${id}`,
      label: name,
      title: name,
      show: getAllowedTabsPayroll(payrollPermission,id),
    };
  });

  const filteredTabsList = tabsData.filter((item) => item.show);

  useEffect(() => {
    if (payrollTabId === ':id' && filteredTabsList.length) {
      navigate(`/payroll/${filteredTabsList[0]?.id}`);
    }
  }, [filteredTabsList]);

  const getDashboards = () => {
    {
      switch (payrollTabId) {
        case payrollDashboard: {
          return <AllPayroll />;
        }
        case giroFileDashboard: {
          return <BankGiroFileDashboard />;
        }
        default:
          break;
      }
    }
  };

  return (
    <Box>
      <NavTabBar list={filteredTabsList} searchWidth={150} tabWidth={150} />
      <Box pt={2} sx={{ background: 'white' }}>
        {getDashboards()}
      </Box>
    </Box>
  );
};

export default PayrollOutlet;
