import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import { setLocalStorage } from '../../utils/common.util';
import { FETCH_APP_LEVEL_PERMISSIONS_FAILURE, FETCH_APP_LEVEL_PERMISSIONS_REQUEST, FETCH_APP_LEVEL_PERMISSIONS_SUCCESS, SET_CURRENT_USER_INFO, SET_CURRENT_USER_ROLE_ID, SET_AUTH,
  FILE_UPLOAD_FAILURE, FILE_UPLOAD_REQUEST, FILE_UPLOAD_SUCCESS } from './actionTypes';

const initialState = {
  appPermissions: null,
  currentUserRoleId: null,
  userInfo: null,
  fileData: [],
  requestProcessing: {
    [APIRequestStatus.FETCH_APP_PERMISSIONS]: LoadingStatus.Loading,
    [APIRequestStatus.FETCH_APP_PERMISSIONS]: LoadingStatus.Loading,
    [APIRequestStatus.FILE_UPLOAD]: LoadingStatus.Loading
  },
  token: null,
  refreshToken: null,
  sessionExpired: false,
};

const mainReducer = (state = initialState, action) => {
    switch (action.type) {

        
      case FETCH_APP_LEVEL_PERMISSIONS_REQUEST: {
        return {
          ...state, 
          requestProcessing: {
            ...state.requestProcessing,
            [APIRequestStatus.FETCH_APP_PERMISSIONS]: LoadingStatus.Loading
        }
        };
      }

      case FETCH_APP_LEVEL_PERMISSIONS_SUCCESS: {
        const appPermissions = action.payload;
        return {
          ...state,
          appPermissions: appPermissions,
          requestProcessing: {
            ...state.requestProcessing,
            [APIRequestStatus.FETCH_APP_PERMISSIONS]: LoadingStatus.Success
        }
        };
      }

      case FETCH_APP_LEVEL_PERMISSIONS_FAILURE: {
        return {
          ...state,
          requestProcessing: {
            ...state.requestProcessing,
            [APIRequestStatus.FETCH_APP_PERMISSIONS]: LoadingStatus.Failure
        }
        };
      }

      case SET_CURRENT_USER_ROLE_ID: {
        const roleId = action.payload;
        return {
            ...state,
            currentUserRoleId: roleId
        };
      }

      case SET_CURRENT_USER_INFO: {
        const userInfo = action.payload;
        return {
            ...state,
            userInfo: userInfo
        };
      }

      case SET_AUTH: {
        if (action.payload) {
        const {token, refreshToken} = action.payload;
        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('refreshToken' ,JSON.stringify(refreshToken));
        return {
            ...state,
            token: token,
            refreshToken: refreshToken
        };
      }
      localStorage.clear();
      return initialState;
    } 
      case FILE_UPLOAD_REQUEST: {
        return {
          ...state,
          requestProcessing: {
            ...state.requestProcessing,
            [APIRequestStatus.FILE_UPLOAD]: LoadingStatus.Loading
        }
      };
    }

    case FILE_UPLOAD_SUCCESS: {
      const fileData= action.payload;
      return {
        ...state,
        fileData:fileData,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FILE_UPLOAD]: LoadingStatus.Success
      }
    };
    }

    case FILE_UPLOAD_FAILURE: {  
      return {
        ...state, 
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FILE_UPLOAD]: LoadingStatus.Failure
      }
      };
    }

        default: {
            return state;
        }
    }
};

export default mainReducer;