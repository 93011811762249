import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import './SingleRequestData.css';

const SingleRequestData = ({ title, value = undefined }) => {
  const [showSkelton, setShowSkelton] = useState(true);

  let finalValue = null;

  if (value !== null) {
    if (typeof value == 'object') {
      finalValue = value.map((each) => (
        <Typography key={each} className="data-subtitle">
          {each}
        </Typography>
      ));
    } else {
      finalValue = value;
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setShowSkelton(false);
    }, 2000);
  }, [value]);

  return (
    <div className="data-wrap">
      <Typography className="data-title">{title}</Typography>
      {!value && showSkelton ? <Skeleton /> : finalValue || 'NA'}
      {/* {finalValue || 'NA'} */}
    </div>
  );
};

export default SingleRequestData;
