import { FormFieldTypes } from '../../../../../constants/common';

export const currencyConversionDashboardHeaders = [
  {
    id: 'name',
    label: 'Currency Name',
  },
  {
    id: 'currencyCode',
    label: 'Currency Code',
  },
  {
    id: 'monthYear',
    label: 'Currency Month',
  },
  {
    id: 'conversion_rate',
    label: 'Conversion Rate',
  },
];

export const fieldsForCurrencyConversion = [
  {
    id: 2,
    fieldLabel: 'Month & Year',
    placeholderText: 'Month',
    fieldType: FormFieldTypes.Month,
    isRequired: true,
    fieldRefName: 'monthYear',
    size: 'small',
    fullWidth: true,
    fieldSize: 6,
  },
  {
    id: 1,
    fieldLabel: 'Currency',
    placeholderText: 'Select',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    fieldRefName: 'currency',
    size: 'small',
    fullWidth: true,
    fieldSize: 6,
  },
  {
    id: 3,
    fieldLabel: 'Conversion Rate',
    placeholderText: 'Conversion Rate',
    fieldType: FormFieldTypes.Text,
    dataType:FormFieldTypes.Number,
    isRequired: true,
    isHidden: false,
    fieldRefName: 'conversion_rate',
    size: 'small',
    fullWidth: true,
    fieldSize: 6,
    percisionNumber:6
  },
];
export const currencyConversionFieldsNames={
  Currency:'currency'
};
