import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

const AttendeesModalHeader = () => {
  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="repeat(4, 200px)"
        gap="30px 30px"
        padding={1}
      >
        <Typography variant="h5">{'Attendees Name'}</Typography>
        <Typography variant="h5">{'Department'}</Typography>
        <Typography variant="h5" textAlign="right">
          {'Available Amount'}
        </Typography>
        <Typography variant="h5" textAlign="right">
          {'Deduction Amount'}
        </Typography>
      </Box>
      <Divider sx={{ m: '2px 0px' }} />
    </>
  );
};

export default AttendeesModalHeader;
