import API from './api';

export class PowerBiService extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL + '/power-bi/' || '';
  constructor() {
    super(PowerBiService.baseURL);
  }
  getReport = async (reportId, datasetId) => (
    this.responsify(await this.instance.get('get_token', { params: { reportId, datasetId } }))
  );

  getReportList = async (roleId, searchParam, startDate, endDate) => (
    this.responsify(await this.instance.get('reports',{params:{roleId, searchParam, startDate, endDate}}))
  );
}