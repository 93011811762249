import { OnboardingServices } from '../../services/onboarding';
import { FETCH_COMP_BEN_PERMISSIONS_FAILURE, FETCH_COMP_BEN_PERMISSIONS_REQUEST, FETCH_COMP_BEN_PERMISSIONS_SUCCESS, FETCH_MY_PROFILE_PERMISSIONS_FAILURE, FETCH_MY_PROFILE_PERMISSIONS_REQUEST, FETCH_MY_PROFILE_PERMISSIONS_SUCCESS, FETCH_PAYROLL_PERMISSIONS_FAILURE, FETCH_PAYROLL_PERMISSIONS_REQUEST, FETCH_PAYROLL_PERMISSIONS_SUCCESS, FETCH_PERFORMANCE_PERMISSIONS_FAILURE, FETCH_PERFORMANCE_PERMISSIONS_REQUEST, FETCH_PERFORMANCE_PERMISSIONS_SUCCESS, FETCH_RECRUITING_PERMISSIONS_FAILURE, FETCH_RECRUITING_PERMISSIONS_REQUEST, FETCH_RECRUITING_PERMISSIONS_SUCCESS, FETCH_TIME_MANAGEMENT_PERMISSIONS_FAILURE, FETCH_TIME_MANAGEMENT_PERMISSIONS_REQUEST, FETCH_TIME_MANAGEMENT_PERMISSIONS_SUCCESS, FETCH_USER_ONBOARDING_PERMISSIONS_FAILURE, FETCH_USER_ONBOARDING_PERMISSIONS_REQUEST, FETCH_USER_ONBOARDING_PERMISSIONS_SUCCESS, RESET_MODULE_PERMISSIONS } from './actionTypes';

const fetchUserOnboardingPermissionsRequest = () => (
  {
    type: FETCH_USER_ONBOARDING_PERMISSIONS_REQUEST
  }
);

const fetchUserOnboardingPermissionsSuccess = (payload) => (
  {
    type: FETCH_USER_ONBOARDING_PERMISSIONS_SUCCESS,
    payload
  }
);

const fetchUserOnboardingPermissionsFailure = () => (
  {
    type: FETCH_USER_ONBOARDING_PERMISSIONS_FAILURE
  }
);

const fetchMyProfilePermissionsRequest = () => (
  {
    type: FETCH_MY_PROFILE_PERMISSIONS_REQUEST
  }
);

const fetchMyProfilePermissionsSuccess = (payload) => (
  {
    type: FETCH_MY_PROFILE_PERMISSIONS_SUCCESS,
    payload
  }
);

const fetchMyProfilePermissionsFailure = () => (
  {
    type: FETCH_MY_PROFILE_PERMISSIONS_FAILURE
  }
);

export const resetModulePermissions = () => (
  {
    type: RESET_MODULE_PERMISSIONS
  }
);

const fetchRecruitingPermissionsRequest = () => (
  {
    type: FETCH_RECRUITING_PERMISSIONS_REQUEST
  }
);

const fetchRecruitingPermissionsSuccess = (payload) => (
  {
    type: FETCH_RECRUITING_PERMISSIONS_SUCCESS,
    payload
  }
);

const fetchRecruitingPermissionsFailure = () => (
  {
    type: FETCH_RECRUITING_PERMISSIONS_FAILURE
  }
);

const fetchPerformancePermissionsRequest = () => (
  {
    type: FETCH_PERFORMANCE_PERMISSIONS_REQUEST
  }
);

const fetchPerformancePermissionsSuccess = (payload) => (
  {
    type: FETCH_PERFORMANCE_PERMISSIONS_SUCCESS,
    payload
  }
);

const fetchPerformancePermissionsFailure = () => (
  {
    type: FETCH_PERFORMANCE_PERMISSIONS_FAILURE
  }
);

const fetchTimeManagementPermissionsRequest = () => (
  {
    type: FETCH_TIME_MANAGEMENT_PERMISSIONS_REQUEST
  }
);

const fetchTimeManagementPermissionsSuccess = (payload) => (
  {
    type: FETCH_TIME_MANAGEMENT_PERMISSIONS_SUCCESS,
    payload
  }
);

const fetchTimeManagementPermissionsFailure = () => (
  {
    type: FETCH_TIME_MANAGEMENT_PERMISSIONS_FAILURE
  }
);

const fetchPayrollPermissionsRequest = () => (
  {
    type: FETCH_PAYROLL_PERMISSIONS_REQUEST
  }
);

const fetchPayrollPermissionsSuccess = (payload) => (
  {
    type: FETCH_PAYROLL_PERMISSIONS_SUCCESS,
    payload
  }
);

const fetchPayrollPermissionsFailure = () => (
  {
    type: FETCH_PAYROLL_PERMISSIONS_FAILURE
  }
);

const fetchCompBenPermissionsRequest = () => (
  {
    type: FETCH_COMP_BEN_PERMISSIONS_REQUEST
  }
);

const fetchCompBenPermissionsSuccess = (payload) => (
  {
    type: FETCH_COMP_BEN_PERMISSIONS_SUCCESS,
    payload
  }
);

const fetchCompBenPermissionsFailure = () => (
  {
    type: FETCH_COMP_BEN_PERMISSIONS_FAILURE
  }
);

export const fetchUserOnboardingPermissions = (roleId, moduleId) => async (dispatch) => {
  dispatch(fetchUserOnboardingPermissionsRequest());
  new OnboardingServices().fetchCompanyOnboardingPermissions(roleId, moduleId).then((res) => {
    dispatch(fetchUserOnboardingPermissionsSuccess(res));
  }).catch(() => {
    dispatch(fetchUserOnboardingPermissionsFailure());
  });
};

export const fetchMyProfilePermissions = (roleId, moduleId) => async (dispatch) => {
  dispatch(fetchMyProfilePermissionsRequest());
  new OnboardingServices().fetchCompanyOnboardingPermissions(roleId, moduleId).then((res) => {
    dispatch(fetchMyProfilePermissionsSuccess(res));
  }).catch(() => {
    dispatch(fetchMyProfilePermissionsFailure());
  });
};

export const fetchRecruitingPermissions = (roleId, moduleId) => async (dispatch) => {
  dispatch(fetchRecruitingPermissionsRequest());
  new OnboardingServices().fetchCompanyOnboardingPermissions(roleId, moduleId).then((res) => {
    dispatch(fetchRecruitingPermissionsSuccess(res));
  }).catch(() => {
    dispatch(fetchRecruitingPermissionsFailure());
  });
};

export const fetchPerformancePermissions = (roleId, moduleId) => async (dispatch) => {
  dispatch(fetchPerformancePermissionsRequest());
  new OnboardingServices().fetchCompanyOnboardingPermissions(roleId, moduleId).then((res) => {
    dispatch(fetchPerformancePermissionsSuccess(res));
  }).catch(() => {
    dispatch(fetchPerformancePermissionsFailure());
  });
};

export const fetchTimeManagementPermissions = (roleId, moduleId) => async (dispatch) => {
  dispatch(fetchTimeManagementPermissionsRequest());
  new OnboardingServices().fetchCompanyOnboardingPermissions(roleId, moduleId).then((res) => {
    dispatch(fetchTimeManagementPermissionsSuccess(res));
  }).catch(() => {
    dispatch(fetchTimeManagementPermissionsFailure());
  });
};

export const fetchPayrollPermissions = (roleId, moduleId) => async (dispatch) => {
  dispatch(fetchPayrollPermissionsRequest());
  new OnboardingServices().fetchCompanyOnboardingPermissions(roleId, moduleId).then((res) => {
    dispatch(fetchPayrollPermissionsSuccess(res));
  }).catch(() => {
    dispatch(fetchPayrollPermissionsFailure());
  });
};

export const fetchCompBenPermissions = (roleId, moduleId) => async (dispatch) => {
  dispatch(fetchCompBenPermissionsRequest());
  new OnboardingServices().fetchCompanyOnboardingPermissions(roleId, moduleId).then((res) => {
    dispatch(fetchCompBenPermissionsSuccess(res));
  }).catch(() => {
    dispatch(fetchCompBenPermissionsFailure());
  });
};