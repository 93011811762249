import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PerformanceFormEdit from '../KRAForm/KRAPerformance/PerformanceFormEdit';
import { useSelector } from 'react-redux';
import PerformanceFormView from '../KRAForm/KRAPerformance/PerformanceFormView';

const KraPerformance = (props) => {
  const { kraData, kraIndex, viewMode,managerAcess } = props;
  const { performanceConfig } = useSelector((state) => state.goals);
  const { targetPoints, kraPerformance } = kraData;
  return (
    <Box mt={3}>
      <Typography variant="subtitle2" color={'neutral.dark80'} mt={1}>
        Performance Assessment
      </Typography>
      <Divider sx={{ marginBottom: '15px' }} />
      {!viewMode ? (
        <PerformanceFormEdit
          formConfig={performanceConfig}
          targetPoints={targetPoints}
          kraIndex={kraIndex}
          formData={kraPerformance}
          managerAcess={managerAcess}
        />
      ) : (
        <PerformanceFormView
          formConfig={performanceConfig}
          formData={kraPerformance}
        />
      )}
    </Box>
  );
};

export default KraPerformance;
