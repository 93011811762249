import { Drawer, styled } from '@mui/material';

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${(props) => props.theme.palette.neutral.dark80};
    color: ${(props) => props.theme.palette.neutral.light} !important;
  }
  .Mui-selected {
    background-color: ${(props) =>
      props.theme.palette.accentTertiary.accent40}!important;
    color: ${(props) => props.theme.palette.accentTertiary.main}!important;

    &:hover {
      background-color: ${(props) =>
        props.theme.palette.accentTertiary.accent40};
      color: ${(props) => props.theme.palette.accentTertiary.main};
    }
  }
`;

export default StyledDrawer;