/* eslint-disable react/display-name */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import FormEdit from '../../../../../OnBoarding/common/Form/FormEdit';
import { RHSBoxAction } from '../../../RHSBoxAction/RHSBoxAction';
import { useDispatch, useSelector } from 'react-redux';
import { addKraTab, deleteKraTab, duplicateKraTab, updateKraData } from '../../../../../../redux/goals/action';
import { useDebounce } from 'usehooks-ts';
import { KRAFormFieldNames } from '../../../../Utlis/goals.util';
import TargetFrom from './TargetForm/TargetFrom';
import useDidMountEffect from '../../../../../../customHooks/useDidMountEffect';
import { set } from 'lodash';


const KRAForm = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { formConfig, tabIndex, formData, getDropdownOptions, handleDropdownClick,
    numberOfKras, ratingScaleKra, setKraValidationErrors, kraValidationErrors, kraErrorIndex, setKraErrorTabIndex} = props;
  const { targetPoints, ...kraData } = formData;
  const [kraFormInfo, setKraFormInfo] = useState(kraData);
  const [targetFormInfo, setTargetFormInfo] = useState(targetPoints);
  const debouncedFormInfo = useDebounce(kraFormInfo, 500);
  const { targetConfig } = useSelector((state) => state.goals);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    setValidationErrors(kraValidationErrors);
  }, [kraValidationErrors]);

  useEffect(() => {
    setKraFormInfo(formData);
  }, [formData]);

  useEffect(() => {
    setTargetFormInfo(targetPoints);
  }, [targetPoints]);

  useEffect(() => {
    dispatch(updateKraData({ index: tabIndex, formData: { kraFormInfo, targetFormInfo } }));
    setKraErrorTabIndex(-1);
  }, [debouncedFormInfo, targetFormInfo]);

  useEffect(() => {
    if(kraErrorIndex === tabIndex) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [kraErrorIndex]);

  const TFormConfig = targetConfig.map((item, index) => (
    item.reduce((acc, item) => {
      let temp = item;
      if (index === 0) {
        switch (item.fieldRefName) {
          case KRAFormFieldNames.Target: {
            temp = { ...temp, fieldLabel: `${item.fieldLabel} ${kraFormInfo[KRAFormFieldNames.Measure]?.name ? kraFormInfo[KRAFormFieldNames.Measure]?.name : ''}` };
            break;
          }
        }
      }
      acc.push(temp);
      return acc;
    }, [])
  ));
  const handleKraAdd = () => {
    setKraValidationErrors((prev) => [...prev, {}]);
    dispatch(addKraTab());
  };
  const handleKraDelete = () => {
    setKraValidationErrors((prev) => {
      const newError = [...prev];
      newError.splice(tabIndex, 1);
      return newError;
    });
    dispatch(deleteKraTab(tabIndex));
  };
  const handleKraDuplicate = () => {
    setKraValidationErrors((prev) => [...prev, {}]);
    dispatch(duplicateKraTab(tabIndex));
  };
  return (
    <>
      <Box position='relative' ref={ref}>
        <FormEdit
          list={formConfig}
          formInfo={kraFormInfo}
          setFormData={setKraFormInfo}
          errors={validationErrors}
          setErrors={setValidationErrors}
          handleDropdownClick={handleDropdownClick}
          getDropdownOptions={getDropdownOptions}
        />
        <Box mt={1}>
          <Typography variant='caption' color={'neutral.dark80'}>
            Note: Total Weightage cannot be less than or more than 100.
          </Typography>
        </Box>
        <TargetFrom
          targetConfig={TFormConfig}
          targetFormData={targetFormInfo}
          setTargetFormInfo={setTargetFormInfo}
          ratingScaleKra={ratingScaleKra}
        />
        <RHSBoxAction
          disableDelete={numberOfKras === 1}
          onClickAdd={handleKraAdd}
          onClickDelete={handleKraDelete}
          onClickCopy={handleKraDuplicate}
        />
      </Box>
    </>
  );
});

export default React.memo(KRAForm);