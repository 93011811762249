import { Box } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router';
import TabBar from '../../../atoms/Tabs/TabBar';
import AllEmployeeTimeManagement from './DashBoard/AllEmployeeTimeManagement';
import { TimeManagement, TimeManagementLabel } from '../Constant/Constant';

const TimeManagementDashBoard = (props) => {
	const { currentTab = 0 } = props;
	const navigate = useNavigate();

	const dataTabs = [
		{
			name: TimeManagement,
			label: TimeManagementLabel,
			title: TimeManagementLabel,
			hide: false,
			panel: <Box mx={-3}><AllEmployeeTimeManagement /> </Box>,
		},
	];

	const handleChange = (tabIndex) => {
		navigate(`/${dataTabs[tabIndex].name}`);
	};
	const onSearchChange = (e, tabIndex) => {
		navigate(`/${tabIndex.name}`);
	};

	return (
		<Box>
			<TabBar
				tabPanels={dataTabs}
				searchTabs
				disableCreate
				currentTab={currentTab}
				onTabChange={handleChange}
				onSearchChange={onSearchChange}
			/>
		</Box>
	);
};

export default TimeManagementDashBoard;