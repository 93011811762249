import styled from '@emotion/styled';
import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { getSGTTimeZone } from '../../../../../utils/formatter';
import { DateFormatters } from '../../../../../constants/dateConstants';
import { useSelector } from 'react-redux';

const KraAndCompentencyTable = (props) => {
  const { data, header } = props;
  const { logoImage } = useSelector((state) => state && state.onBoarding);
  const defaultTimezone = logoImage?.defaultTimezone;
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: '1px solid rgba(224, 224, 224, 1)',
    textAlign: 'center',
  }));

  const EllipsisCell = styled(StyledTableCell)({
    wordBreak: 'break-all',
  });
  return (
    <Box mt={1}>
      <Typography variant="subtitle2" color={'neutral.dark80'} mt={1}>
        {header}
      </Typography>
      <Divider sx={{ marginBottom: '15px' }} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {header === 'Competency Appraiser Remarks' && ( <StyledTableCell>Competency Name</StyledTableCell>)}
              <StyledTableCell>Appraiser Name</StyledTableCell>
              <StyledTableCell>Remarks</StyledTableCell>
              <StyledTableCell>Appraised On</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <StyledTableCell colSpan={4}>No data available</StyledTableCell>
              </TableRow>
            ) : (
              data.map((row, index) => (
                <TableRow key={index}>
                  {row?.fkCompetencyId?.competencyName && (<StyledTableCell>{row?.fkCompetencyId?.competencyName}</StyledTableCell>)}
                  <StyledTableCell>{row.createdBy.displayName}</StyledTableCell>
                  <Tooltip title={row.mgrRemarks}>
                    <EllipsisCell>{row.mgrRemarks}</EllipsisCell>
                  </Tooltip>
                  <StyledTableCell>
                    {getSGTTimeZone(
                      row.updatedAt,
                      DateFormatters.DateTime24Hour,
                      defaultTimezone
                    )}
                  </StyledTableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default KraAndCompentencyTable;
