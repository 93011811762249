import moment from 'moment';

// Gets client id from localStorage
const getClientID = () => {
  let userData = JSON.parse(localStorage.getItem('userData'));
  return userData.userDetails.client.id;
};

// Generates current time stamp.
export const generateCurrentTimeStamp = () => {
  let today = new Date();
  let dateTime = moment(today).format('h:mm:ss');
  return dateTime;
};

// Genarates form data for file upload;
export const generateFormData = async (e, refNumber=Date.now(), typeId=1, validFiles=['png', 'jpg', 'jpeg', 'pdf']) => {
  const formData = new FormData();

  const myfiles = e.target.files;
  if(e.target.files){
    let foundInvalid = false;
    let fileIsLargerThenExpected = false;
   
    Array.from(myfiles).forEach(file=>{
      const fileName = file.name || '';
      const fileNameSlice = String(fileName).toLowerCase().split('.');
      fileIsLargerThenExpected = file.size > 5000000;
      if(!foundInvalid){
        foundInvalid = !validFiles.includes(fileNameSlice[fileNameSlice.length-1]);
      }
    });
    
    
    if(foundInvalid) {
      alert('Only PDF, PNG, JPG, JPEG format allowed!');
      return false;
    }
    if(fileIsLargerThenExpected){
      alert('File size should not exceed 5MB');
      return false;
    }
  }

  // formData.append('typeId', typeId);
  // formData.append('refNumber', refNumber);
  // formData.append('clientID', await getClientID());

  for (let i = 0; i < myfiles.length; i++) {
    formData.append('files', myfiles[i]);
  }

  return formData;
};
// Genarates form data for file upload;
export const generateFilesFormData = async (e, refNumber, typeId, validFiles=['png', 'jpg', 'jpeg', 'pdf'], sizeLimit = 5000000) => {
  const formData = new FormData();
  const result = {error: null, data: null};
  const myfiles = e.target.files;
  if(e.target.files){
    let foundInvalid = false;
    let fileIsLargerThenExpected = false;
   
    Array.from(myfiles).forEach(file=>{
      const fileName = file.name || '';
      const fileNameSlice = String(fileName).toLowerCase().split('.');
      fileIsLargerThenExpected = file.size > sizeLimit;
      if(!foundInvalid){
        foundInvalid = !validFiles.includes(fileNameSlice[fileNameSlice.length-1]);
      }
    });
    
    
    if(foundInvalid) {
      result.error =  new Error(`Only ${validFiles.join(' ,')} format files are allowed`);
      return result;
    }
    if(fileIsLargerThenExpected){
      alert('File size should not exceed 5MB');
      return false;
    }
  }

  // formData.append('typeId', typeId);
  // formData.append('refNumber', refNumber);
  // formData.append('clientID', await getClientID());

  for (let i = 0; i < myfiles.length; i++) {
    formData.append('files', myfiles[i]);
  }

  return formData;
};

// Common logic for disable previous date from date of joining;
export const disablePrevDates = () => {
  let userData = JSON.parse(localStorage.getItem('userData'));
  let dateOfJoining = userData.userDetails.dateOfJoining;
  const startDate = new Date(dateOfJoining);
  startDate.setDate(startDate.getDate() - 1);
  const startSeconds = Date.parse(startDate);
  return (date) => {
    return Date.parse(date) < startSeconds;
  };
};

export const enableListedDatesOnly = (dateList=[]) => {
  const today = moment();
  const DateList =  dateList.filter(date=>!moment(date, 'YYYY-MM-DD').isAfter(today));
 
  return (date)=>{ 
    const found = DateList.includes(moment(date).format('YYYY-MM-DD'));
    return !found ;
  };
};

// Check for number validation
export const validateNumber = (name, value) => {
  if (name == 'carParkFees' || name == 'erp') {
    let val = Number(value);
    if (val < 0) {
      return false;
    }
  } else if (value !== '') {
    let val = Number(value);
    if (val <= 0) {
      return false;
    }
  }

  return true;
};

export const checkFilesCountHandler = (e, claimsData) => {
  if (e.target.files.length > 2) {
    alert('Only Two Attachements Are Allowed');
    return false;
  }

  if (claimsData.attachment.length === 1 && e.target.files.length >= 2) {
    alert('Only Two Attachements Are Allowed');
    return false;
  }

  if (claimsData.attachment.length === 2) {
    alert('Only Two Attachements Are Allowed');
    return false;
  }
  return true;
};

// Common logic for disable previous date from date of joining &
export const disablePrevDateForThreeMonth = () => {
  let date = new Date();
  let userData = JSON.parse(localStorage.getItem('userData'));
  let dateOfJoining = userData.userDetails.dateOfJoining;
  const joinDate = new Date(dateOfJoining);
  joinDate.setDate(joinDate.getDate() - 1);
  date.setMonth(date.getMonth() - 3);
  let startSeconds;
  if (joinDate.getTime() < date.getTime()) {
    const startDate = new Date(date);
    startSeconds = Date.parse(startDate);
  } else {
    const startDate = new Date(joinDate);
    startSeconds = Date.parse(startDate);
  }
  return (date) => {
    return Date.parse(date) < startSeconds;
  };
};
// common logic for disable prev year or past dates


export const disablePastYears = () => {
  let userData = JSON.parse(localStorage.getItem('userData'));
  let dateOfJoining = userData.userDetails.dateOfJoining;
  const joinDate = new Date(dateOfJoining);
  const fisrstDateOfYear = new Date(new Date().getFullYear(), 0, 1);
  
  let startSeconds;
  if (joinDate.getTime() < fisrstDateOfYear.getTime()) {
    const startDate = new Date(fisrstDateOfYear);
    startSeconds = Date.parse(startDate);
  } else {
    const startDate = new Date(dateOfJoining);
    startSeconds = Date.parse(startDate);
  }
  return (date) => {
    return Date.parse(date) < startSeconds;
  };
};

export const convertToFormatMMDDYYYY =  (date)=>{
 const fDate = new Date(date);
 const day = fDate.getDate();
 const month = fDate.getMonth()+1;
 const year = fDate.getFullYear(); 
 return `${month}/${day}/${year}`;
};
export const EnableDateInRangeOnlyFromStart=(data)=>{
  const start =moment(data,'DD-MM-yyyy').subtract(1,'d').toDate();
  console.log(start,'ibnto dine');
  return (date)=> {
    // start <= current <= end;
    let shouldbedisabled = true;
    let today = Date.parse(date);

    if(start < today) shouldbedisabled = false;
    
    return shouldbedisabled;
  };


};
// calculate date on the base of claim type
export const EnableDateInRangeOnly = (startDate,endDate)=>{
  let Start = typeof startDate === 'string' ? moment(startDate,'DD-MM-yyyy').toDate() : new Date(`${convertToFormatMMDDYYYY(startDate)}`);
  let Today = new Date();
  let EndDate = new Date(endDate);
  let End = EndDate > Today ? Today : EndDate;
  console.log('Start', Start);
  console.log('End', End);
  return (date)=> {
    // start <= current <= end;
    let shouldbedisabled = true;
    let today = Date.parse(date);

    if(Start <= today && today <= End) shouldbedisabled = false;
    
    return shouldbedisabled;
  };
};

// Allowed Only two digit after decimal start
export const numberDecimalConverter = (e) => {
  let values = e.target.value;
  e.target.value =
    values.indexOf('.') >= 0
      ? values.substr(0, values.indexOf('.')) +
        values.substr(values.indexOf('.'), 3)
      : values;
};
// Allowed Only two digit after decimal end

// Gets the paydate for manager using today's date start
export const getPayDateForManager = () => {

  let payDate;

  let date = new Date();
  let currentDate = moment(date).format('DD/MM/YYYY');
  const startOfMonth = moment().clone().startOf('month').format('DD/MM/YYYY');
  const fifthDate = moment().date(6).format('DD/MM/YYYY');
  const twentyThreeDate = moment().date(23).format('DD/MM/YYYY');

  const endOfMonth = moment().clone().endOf('month').format('DD/MM/YYYY');
  const isBetween = moment(currentDate).isBetween(startOfMonth, fifthDate);
  const nextMonthEndDate = moment()
    .date(23)
    .add(1, 'M')
    .format('DD/MM/YYYY');

  let newEndDate = twentyThreeDate.split('/').reverse().join('-');
  let newNextMonthDate = nextMonthEndDate.split('/').reverse().join('-');

  if (isBetween) {
    payDate = new Date(newEndDate).toString();
    return payDate;
  } else {
    payDate=new Date(newNextMonthDate).toString(); 
    return payDate;
  }
};
// Gets the paydate for manager using today's date end

// Check if user is authenticated start
export const redirectToLogin = (err) => {

    if(err.response.statusText == 'Unauthorized'){
      alert('Your session has expired ! Please Login');
      // window.stop();
      // localStorage.clear();
      window.location.replace(`${process.env.REACT_APP_AUTH_URL}`);
    }
};
// Check if user is authenticated end

