import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ListView from '../../../../../atoms/DataList/ListView';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { jobHeadCells } from '../../../utils/recruitingUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  APIRequestStatus,
  DebounceTimer,
  LoadingStatus,
} from '../../../../../constants/common';
import JobModal from '../ModalMap/JobModal';
import moment from 'moment';
import {
  getAllJobList,
  resetJob,
} from '../../../../../redux/jobRecruiting/action';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import {
  AllCandidate,
  AllJob,
  CandidateDetails,
  JobApprover,
  JobRequisition,
  JobStatusCode,
} from '../../../Constant/constant';
import { permissionsObj } from '../../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import NavTabBar from '../../../../../atoms/Tabs/nav-tab-bar';
import { getAllowedTabs } from '../../../utils/recruiting.permission.util';
import { recruitingTabs } from '../../../utils/tabs';
import TryAgain from '../../../../molecules/ErrorState/error.component';
import { DateRangePro } from '../../../../molecules/DateRange/DateRangePro';
import { SettingsBackupRestoreOutlined } from '@mui/icons-material';
import {
  currentDate,
  getFormattedValues,
  noOfmonthfromCurrent,
} from '../../../../../utils/formatter';
import { useDebounce } from 'usehooks-ts';
import { AppLevelPermissions } from '../../../../OnBoarding/Organization/constants/onboarding.constants';

function AllJobs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isReqApprover,
    jobList,
    requestProcessing: { [APIRequestStatus.GET_ALL_JOB_LIST]: jobListLoading },
  } = useSelector((state) => state.job);
  const { logoImage } = useSelector((state) => state && state.onBoarding);
  const defaultMonths = logoImage?.defaultMonths;
  
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState([
    noOfmonthfromCurrent(defaultMonths),
    currentDate,
  ]);

  const { currentUserRoleId, appPermissions } = useSelector((state) => state.main);
  const { recrutingPermissions } = useSelector(
    (state) => state.modulePermissions
  );
  const isApplicantButtonDisabled =
    recrutingPermissions?.[CandidateDetails]?.[permissionsObj.allowView] ||
    recrutingPermissions?.[AllCandidate]?.[permissionsObj.allowView];
  const [searchParams, setSearchParams] = useSearchParams();
  const jobNameParams = searchParams.get('jName');
  const searchQuery = searchParams?.get('q');
  const debouncedVal = useDebounce(searchQuery, DebounceTimer);

  useEffect(() => {
    if (currentUserRoleId) {
      dispatch(
        getAllJobList(
          currentUserRoleId,
          searchQuery,
          getFormattedValues('YYYY-MM-DD', selectedDate[0]),
          getFormattedValues('YYYY-MM-DD', selectedDate[1]),
          jobNameParams,
          appPermissions?.[AppLevelPermissions.Job]?.id,
          recrutingPermissions?.[JobRequisition]?.id
        )
      );
    }
  }, [currentUserRoleId, selectedDate, jobNameParams, debouncedVal, recrutingPermissions, appPermissions]);

  useEffect(() => {
    setCheckedItems([]);
  }, [jobList]);

  const isDisabledCheckbox = (item) => {
    return item.statusCode.value === JobStatusCode.Draft;
  };

  const rowData = jobList?.map((items) => {
    const {
      id,
      jobCode,
      fkJobTitle,
      fkLocation,
      numOfOpenings,
      fkRecruiter,
      fkHiringMgr,
      statusCode,
      createdUtc,
      applicants,
    } = items;
    return {
      code: jobCode,
      title: (
        <Link
          to={{ search: `?jName=${fkJobTitle?.longName}` }}
          style={{ textDecoration: 'none' }}
        >
          <Button variant="text" color="primary">
            {fkJobTitle?.longName}
          </Button>
        </Link>
      ),
      location: fkLocation?.name,
      openings: numOfOpenings,
      applicant: (
        <Link
          to={{
            pathname: '/recruiting/candidate-details',
            search: `?jId=${id}`,
          }}
          style={{
            pointerEvents: !isApplicantButtonDisabled ? 'none' : 'auto',
          }}
        >
          <Button variant="text" color="primary">
            {applicants}
          </Button>
        </Link>
      ),
      // posting: '',
      recruiter: fkRecruiter?.firstName,
      hm: fkHiringMgr?.firstName,
      status: statusCode?.name || 'Draft',
      createdOn: moment(createdUtc, 'YYYY-MM-DD').format('YYYY/MM/DD'),
      action: (
        <IconButton onClick={() => navigate({ pathname: `${id}` })}>
          <InfoOutlinedIcon sx={{ color: 'primary.main80' }} />
        </IconButton>
      ),
      id: {
        hide: true,
        value: id,
      },
      statusCode: {
        hide: true,
        value: statusCode?.jobRequisitionStatusCode,
      },
    };
  });

  const handleAddClick = () => {
    navigate('create-job');
    dispatch(resetJob());
  };

  const isDisabledReset = () => {
    return (
      !jobNameParams &&
      selectedDate[0].isSame(noOfmonthfromCurrent(defaultMonths)) &&
      selectedDate[1].isSame(currentDate)
    );
  };

  const resetFilters = () => {
    setSelectedDate([noOfmonthfromCurrent(defaultMonths), currentDate]);
    searchParams.delete('jName');
    setSearchParams(searchParams);
  };

  const handleClear = (event) => {
    event.stopPropagation();
    searchParams.delete('jName');
    setSearchParams(searchParams);
  };

  const allowedTabs = getAllowedTabs(recrutingPermissions, recruitingTabs);
  if (jobListLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      {recrutingPermissions?.[JobRequisition]?.[permissionsObj.allowView] ||
      recrutingPermissions?.[AllJob]?.[permissionsObj.allowView] 
      || recrutingPermissions?.[JobApprover]?.[permissionsObj.allowView] ? (
        <>
          <CircularLoader show={jobListLoading === LoadingStatus.Loading} />
          <NavTabBar list={allowedTabs} />
          {/* <TabHead placeholder='Search Job' filterDataRoles={roles} /> */}
          <Box sx={{ background: 'white' }}>
            <Box display="flex" justifyContent="flex-end" gap={2} mb={1} p={2}>
              <DateRangePro
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
              <Button
                startIcon={<SettingsBackupRestoreOutlined />}
                sx={{ textTransform: 'capitalize' }}
                disabled={isDisabledReset()}
                onClick={resetFilters}
              >
                Clear
              </Button>
              <Box>
                <JobModal
                  checkedItems={checkedItems}
                  isApproval={isReqApprover}
                  selectedDate={selectedDate}
                  jobNameParams={jobNameParams}
                  searchQuery={searchQuery}
                  appModuleId = {appPermissions?.[AppLevelPermissions.Job]?.id}
                  moduleId = {recrutingPermissions?.[JobRequisition]?.id}
                />
              </Box>
            </Box>
            <ListView
              headCells={jobHeadCells(jobNameParams, handleClear)}
              rowsData={rowData}
              showCheckbox
              onAddClick={handleAddClick}
              showAddIcon={
                recrutingPermissions?.[JobRequisition]?.[
                  permissionsObj.allowAdd
                ] || recrutingPermissions?.[AllJob]?.[permissionsObj.allowAdd]
              }
              setCheckedItems={setCheckedItems}
              checkedItems={checkedItems}
              showSerialNo={true}
              isDisabledCheckbox={(item) => isDisabledCheckbox(item)}
              allowSelectAll={true}
            />
          </Box>
        </>
      ) : (
        <Navigate to={allowedTabs?.length ? allowedTabs[0].path : '/home'} />
      )}
    </>
  );
}
export default React.memo(AllJobs);
