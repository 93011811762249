import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalDialog from '../../../../atoms/Modal/ModalDialog';
import DropDownActionForPayroll from './DropDownActionForPayroll';
import {
  FormFieldTypes,
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../../../constants/common';
import { Payroll } from '../../../../services/payroll';
import { getAllPayroll } from '../../../../redux/payroll/action';
import { getFormattedValues } from '../../../../utils/formatter';
import { showToast } from '../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../OnBoarding/Organization/constants/onboarding.constants';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import { PayrollPermissions, PayrollStatus } from '../../Constant/Constant';
import FormEdit from '../../../OnBoarding/common/Form/FormEdit';
import { isEmpty } from 'lodash';
import { getFormBody } from '../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';

const ModalMapForPayroll = (props) => {
  const {
    checkedItems,
    searchQuery,
    currentUserRoleId,
    empIdParams,
    selectedDate,
    appModuleId,
    moduleId,
  } = props;
  const [showModal, setshowModal] = useState(false);
  const [context, setContext] = useState('');
  const [postActionLoading, setPostActionLoading] = useState(-1);
  const [formInfo, setFormInfo] = useState({});

  const { payrollPermission } = useSelector((state) => state.modulePermissions);

  const dispatch = useDispatch();

  const handlePublish = () => {
    setContext('publish');
    setshowModal(true);
  };
  const handlePaid = () => {
    setContext('paid');
    setshowModal(true);
  };

  const handleBankTransfer = () => {
    setContext('bank');
    setshowModal(true);
  };

  const handlePublishClick = (statusCode) => {
    const newFormData = getFormBody(formInfo);
    newFormData.payDate = newFormData?.payDate?.split('T')[0];
    setPostActionLoading(LoadingStatus.Loading);
    new Payroll()
      .updateStatus({
        payrollInfo: checkedItems.map((item)=>{ return ({id: item.id, refId: item.refId });}),
        roleId: currentUserRoleId,
        moduleId:
          payrollPermission?.[PayrollPermissions.ACCESS_ALL_PAYROLL]?.id,
        statusCode,
        payMonth: checkedItems?.[0]?.payMonth,
        ...newFormData,
      })
      .then(() => {
        setPostActionLoading(LoadingStatus.Success);
        dispatch(
          getAllPayroll(
            currentUserRoleId,
            searchQuery,
            getFormattedValues('YYYYMM', selectedDate[0]),
            getFormattedValues('YYYYMM', selectedDate[1]),
            empIdParams,
            appModuleId,
            moduleId
          )
        );
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.update),
          ToastThemes.success
        );
      })
      .catch((err) => {
        setPostActionLoading(LoadingStatus.Failure);
        showToast(err?.response?.data?.message, ToastThemes.error);
      });
  };

  const modalMap = {
    publish: {
      title: 'Publish',
      description: 'Are you sure you want to Publish?',
      primaryAction: 'Publish',
      onClickConfirm: () => handlePublishClick(PayrollStatus.Publish),
      severity: 'success',
      maxWidth: 'md',
      fullWidth: true,
      actionButtonDisabled: false,
    },
    paid: {
      title: 'Pay Salary',
      description: 'Are you sure you have paid the salary?',
      primaryAction: 'Save',
      onClickConfirm: () => handlePublishClick(PayrollStatus.Pay),
      severity: 'success',
      maxWidth: 'md',
      fullWidth: true,
      listComponent: (
        <>
          <br />
          <FormEdit
            list={[
              {
                fieldLabel: 'Pay Date',
                placeholderText: 'dd/mm/yyyy',
                fieldType: FormFieldTypes.Date,
                isRequired: false,
                fieldRefName: 'payDate',
                fieldSize: 13,
              },
            ]}
            padding={0}
            setFormData={setFormInfo}
            formInfo={formInfo}
          />
        </>
      ),
      actionButtonDisabled: isEmpty(formInfo),
    },
    bank: {
      title: 'Bank Transfer',
      description: 'Are you sure you want to Transfer?',
      primaryAction: 'Transfer',
      onClickConfirm: () => handlePublishClick(PayrollStatus.BankTransfer),
      severity: 'success',
      maxWidth: 'md',
      fullWidth: true,
      actionButtonDisabled: false,
    }
  };
  return (
    <>
      <CircularLoader show={postActionLoading === LoadingStatus.Loading} />
      <DropDownActionForPayroll
        checkedItems={checkedItems}
        handlePublish={handlePublish}
        handlePaid={handlePaid}
        handleBankTransfer={handleBankTransfer}
      />
      <ModalDialog
        severity={modalMap[context]?.severity}
        title={modalMap[context]?.title}
        listComponent={modalMap[context]?.listComponent}
        description={modalMap[context]?.description}
        primaryAction={modalMap[context]?.primaryAction}
        secondaryAction="Cancel"
        showModal={showModal}
        onClickClose={() => setshowModal(false)}
        onClickConfirm={modalMap[context]?.onClickConfirm}
        maxWidth={modalMap[context]?.maxWidth}
        fullWidth={modalMap[context]?.fullWidth}
        primaryButtonDisabled={modalMap[context]?.actionButtonDisabled}
        lhsActionButton={modalMap[context]?.lhsActionButton}
      />
    </>
  );
};

export default ModalMapForPayroll;
