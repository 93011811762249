import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Box, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { getDateTime } from '../../utils/formatter';
import FieldView from '../../components/molecules/FieldView/FieldView';
import moment from 'moment';
import { LightTooltip } from '../Tooltip/LightTooltip';
import { InfoOutlined } from '@mui/icons-material';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

const DateTimeField = (props) => {
  const {
    id,
    defaultValue,
    placeholder,
    labelText,
    size,
    hiddenLabel,
    fullWidth,
    required,
    onChange,
    name,
    value,
    errorMessage,
    disabled,
    isReadOnly,
    dataInputFormat = 'DD/MM/YYYY hh:mm a',
    infoIcon,
    maxDate,
    minDate,
    disableFuture,
    disablePast,
  } = props;

  if (isReadOnly) {
    return (
      <FieldView
        labelText={labelText}
        labelValue={
          value
            ? moment(value, 'YYYY-MM-DD HH:mm')
                .utc(value, 'YYYY-MM-DD HH:mm')
                .local()
                .format(dataInputFormat)
            : null
        }
      />
    );
  }
  return (
    <>
      {labelText && (
        <Typography
          variant="body2"
          color={`${disabled ? 'neutral.light40' : 'neutral.dark80'}`}
          mb={1}
        >
          {labelText}
          {required && (
            <Box color="accentSecondary.main" sx={{ display: 'inline-block' }}>
              *
            </Box>
          )}
          {infoIcon && (
            <LightTooltip title={infoIcon} placement="top" arrow>
              <Box color="primary.main" sx={{ display: 'inline-block' }}>
                <InfoOutlined
                  sx={{ width: 14, height: 14, ml: '4px', cursor: 'pointer' }}
                />
              </Box>
            </LightTooltip>
          )}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
        <DateTimePicker
          ampm={true}
          format="DD/MM/YYYY hh:mm a"
          value={
            value
              ? getDateTime(value, dataInputFormat)
              : null
          }
          onChange={(value) => onChange(value, name)}
          disabled={disabled}
          maxDateTime={maxDate}
          minDateTime={minDate}
          disableFuture={disableFuture}
          disablePast={disablePast}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          slotProps={{
            textField: {
              fullWidth: fullWidth,
              size: 'small',
              variant: 'outlined',
              error: !!errorMessage,
              helperText: errorMessage,
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
};

export default DateTimeField;
