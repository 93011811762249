import { DateFormatters } from '../../../constants/dateConstants';
import { formatCurrency } from '../../../utils/common.util';
import { getFormattedValues } from '../../../utils/formatter';

export const entitlementsConfigs = [
  {
    label: 'Start Date',
    getter: (details) =>
      getFormattedValues(DateFormatters.ddmmyyyy, details.startDate),
    required: false,
    hide: (details) => !details.startDate,
  },
  {
    label: 'End Date',
    getter: (details) =>
      getFormattedValues(DateFormatters.ddmmyyyy, details.endDate),
    required: false,
    hide: (details) => !details.endDate,
  },
  {
    label: 'Entitlement',
    getter: (details, currencySymbol) =>
      details.entitlementAmt
        ? `${currencySymbol} ${formatCurrency(Number(details.entitlementAmt))}`
        : '',
    required: false,
    hide: (details) => false,
  },
  {
    label: 'Balance',
    getter: (details, currencySymbol) =>
      details.entitlementBalanceAmt
        ? `${currencySymbol} ${formatCurrency(
            Number(details.entitlementBalanceAmt)
          )}`
        : '',
    required: false,
    hide: (details) => false,
  },
];
