import React, { useEffect, useState } from 'react';
import FormEdit from '../../../../../OnBoarding/common/Form/FormEdit';
import { Box, IconButton } from '@mui/material';
import { initialVal } from '../utils/unergizer-utils';
import { AddOutlined, DeleteOutline } from '@mui/icons-material';
import { NewClaimsService } from '../../../../../../services/newClaims';

const UnergizerConfig = (props) => {
  const {
    monthsFormData,
    setMonthsData,
    index,
    noOfMonths,
    formConfig,
    errors,
  } = props;
  const [formInfo, setFormInfo] = useState(monthsFormData || {});
  const [error, setError] = useState(errors || {});

  useEffect(() => {
    setError(errors);
  }, [errors]);

  useEffect(() => {
    setMonthsData((prev) => {
      const newData = [...prev];
      newData[index] = { ...formInfo };
      return newData;
    });
  }, [formInfo]);

  const handleDelete = (index) => {
    if (formInfo?.id) {
      new NewClaimsService().deleteUnergizer(formInfo?.id).then(() => {
        if (noOfMonths === 1) {
          setFormInfo(initialVal);
          setMonthsData([initialVal]);
        } else {
          setMonthsData((prev) => {
            const newMonths = [...prev];
            newMonths.splice(index, 1);
            setFormInfo(newMonths[index]);
            return newMonths;
          });
        }
      });
    } else {
      if (noOfMonths === 1) {
        setFormInfo(initialVal);
        setMonthsData([initialVal]);
      } else {
        setMonthsData((prev) => {
          const newMonths = [...prev];
          newMonths.splice(index, 1);
          setFormInfo(newMonths[index]);
          return newMonths;
        });
      }
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flex="1 1 0"
      justifyContent={'space-between'}
      mb={2}
    >
      <Box sx={{ width: '100%' }}>
        <FormEdit
          list={formConfig}
          formInfo={formInfo}
          setFormData={setFormInfo}
          errors={error}
          setErrors={setError}
          hideFields={false}
          padding={0}
          modal={false}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <IconButton
          color="success"
          sx={{ borderColor: 'white' }}
          onClick={() =>
            setMonthsData((prev) => {
              return [...prev, { ...initialVal }];
            })
          }
        >
          <AddOutlined sx={{ width: '24px', height: '24px', mr: '5px' }} />
        </IconButton>
        <IconButton
          color="error"
          sx={{ borderColor: 'white' }}
          onClick={() => handleDelete(index)}
        >
          <DeleteOutline sx={{ width: '24px', height: '24px', mr: '5px' }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default UnergizerConfig;
