import {
  Box,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  Checkbox,
  OutlinedInput,
  IconButton,
  Hidden,
  InputAdornment,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../shared/context/auth-context';
import LoginImage from '../../assets/ui-gif-2.gif';
import Microsoft from '../../assets/Microsoft.png';
import './Login.css';
import ButtonUI from '../../shared/components/ui-elements/ButtonUI';
import { makeStyles } from '@mui/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { MsalConfig } from '../../config/msal-config';
import { PublicClientApplication } from '@azure/msal-browser';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../../redux/actions/employee-portal/employee-portal.action';
import Logo from '../../assets/tenant-logo.png';
import axios from 'axios';
import { decodeJWT, setLocalStorage, sortRolesArray } from '../../utils/common.util';
import { Link, useNavigate } from 'react-router-dom';
import { setAuth, setUserInfo } from '../../redux/main/action';

export const useStyles = makeStyles({
  root: {
    '&.MuiFormControl-root': {
      marginTop: '5px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#00ac94',
      },
    },
    '&.MuiCheckbox-root': {
      color: 'rgba(0, 0, 0, 0.54)',
      '&.Mui-checked': {
        color: '#00ac94',
      },
    },
    '&.MuiTypography-body1': {
      fontSize: '14px',
    },
  },
});

const Login = () => {
  const dispatch = useDispatch();

  const hostname = window.location.hostname;
  const tenant = hostname.split('.')[0] === 'localhost'? process.env.REACT_APP_LOCALHOST_CLIENT_ID : hostname.split('.')[0];

  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const auth = useContext(AuthContext);

  const authSubmitLogin = () => {
    if (email === '' || values.password === '') {
      window.alert('Please check Username and/or Password!');
      return false;
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASE_API_LOCAL}/auth/signin`,
          {
            username: email,
            password: values.password
          },
          {
            headers: {
              'Content-type': 'application/json',
              'client_id': tenant
            },
          }
        )
        .then((resp) => {
          const { token, refresh_token, employeeProfile, orgDetails } = resp.data.data;
          if (token) {

            localStorage.setItem('asProfile', 'Employee');
            setLocalStorage('isLoggedIn', true);
            setLocalStorage('refreshToken', refresh_token);
            setLocalStorage('token', token);
            const decodeToken = decodeJWT(token);
            const { roles } = decodeToken;
            const sortedRoles = sortRolesArray(roles);
            setLocalStorage('role', { roleId: sortedRoles[0].id, name: sortedRoles[0].role_name });
            setLocalStorage('userInfo', resp.data.data.employeeProfile);
            setLocalStorage('orgInfo', orgDetails);

            auth.login('Employee');
            dispatch(setCurrentUser(roles[0].role_name));
            dispatch(setAuth({
              refreshToken: refresh_token,
              token: token
            }));

            dispatch(setUserInfo(employeeProfile));
          } else {
            window.alert('Please check Username and/or Password!');
            return;
          }
        }).catch((err) => {
          window.alert('Please check Username and/or Password!');
        });
    }
  };

  const authSubmitLoginNew = (token, isSSO) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_API_LOCAL}/auth/signin`,
        {
          isSSO: isSSO,
          token: token,
        },
        {
          headers: {
            'Content-type': 'application/json',
            'client_id': tenant
          },
        }
      )
      .then((resp) => {
        if (resp.data.token) {
          localStorage.setItem('userData', JSON.stringify(resp.data));
          localStorage.setItem(
            'asProfile',
            resp.data.userDetails.designation[0]
          );
          dispatch(setCurrentUser(resp.data.userDetails.designation[0]));
          auth.login(resp.data.userDetails.designation[0]);
          window.location.reload();
        } else {
          window.alert('Please check Username and/or Password!');
          return;
        }
      });
  };

  let publicClientApplication = new PublicClientApplication({
    auth: {
      clientId: MsalConfig.appId,
      redirectUri: MsalConfig.redirectUri,
      authority: MsalConfig.authority,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  });

  const login = async () => {
    try {
      await publicClientApplication
        .loginPopup({
          scopes: MsalConfig.scopes,
          prompt: 'select_account',
        })
        .then((res) => authSubmitLoginNew(res.accessToken, true));
    } catch (error) {
      // console.log('Error', error);
    }
  };

  const LoginWithOkta = () => {
    window.location.replace(process.env.REACT_APP_SAML2_AUTH);
  };

  // const logout2 = () =>{
  //   publicClientApplication.logoutPopup();
  // }

  // Login using Enter key press
  const handleKeyPress = (e) => {
    if (e.key == 'Enter') {
      authSubmitLogin();
    }
  };

  return (
    <React.Fragment>
      <Grid container className="login-screen-wrap" style={{ padding: '5%' }}>
        <Grid item xs={12} sm={12} md={6} className="login-inputs-container">
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{
              minHeight: '80vh',
              background: '#ffffff',
            }}
          >
            <Grid xs={11} sm={6} style={{}}>
              <Grid container>
                <h2 style={{ padding: '0.1em' }} className="client-logo">
                  {/* <img src={} alt='NTUC'/> */}
                  <img src={Logo} alt="NTUC" />
                </h2>

                <Grid xs={12}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <label htmlFor="" style={{ fontSize: '15px' }}>
                          Username
                        </label>
                        <TextField
                          type="email"
                          required
                          variant="outlined"
                          size="small"
                          value={email}
                          name="Designation"
                          fullWidth
                          onChange={(e) => setEmail(e.target.value)}
                          classes={classes}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          size="small"
                          classes={classes}
                        >
                          <label htmlFor="" style={{ fontSize: '15px' }}>
                            Password
                          </label>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            onKeyPress={(e) => handleKeyPress(e)}
                            fullWidth
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {values.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="medium"
                              color="primary"
                              classes={classes}
                            />
                          }
                          label="Remember Me"
                          classes={classes}
                          className="remember-me-label"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <ButtonUI
                          backgroundColor="#00ac94"
                          color="#ffffff"
                          onclick={authSubmitLogin}
                          width="100%"
                          className="login-btn"
                        >
                          Login
                        </ButtonUI>
                      </Grid>
                      <Grid item xs={12} className="forgot-password-wrap" style={{ cursor: 'pointer' }}>
                        <Link style={{ cursor: 'pointer' }} to='/forgot-password'>Forgot Password ?</Link>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                        }}
                      >
                        <Box className="authentication">
                          <img
                            src={Microsoft}
                            alt={Microsoft}
                            onClick={LoginWithOkta}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} sm={12} md={6}>
            <div className="login-image-container">
              <img src={LoginImage} alt="Login Image" />
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </React.Fragment>
  );
};
export default Login;
