import React, { useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import OverallRemarks from './OverallRemarks';
import CompetencySkillsForm from './CompetencySkillsForm/CompetencySkillsForm';
import { useSelector } from 'react-redux';
import CompetencySkillsFormView from './CompetencySkillsForm/CompetencySkillsItem/CompetencySkillsFormView';


const InterviewFeedbackForm = (props) => {
  const { interviewFeedbackFormConfig,
    competencies,
    setCompetencies = () => { },
    skills,
    setSkills = () => { },
    interviewFeedbackFormInfo,
    setInterviewFeedbackFormInfo = () => { },
    competencyRatingScale,
    viewMode = false
  } = props;

  return (
    <Box>
      <Divider sx={{ my: 2 }} />
      <Grid container rowSpacing={3} columnSpacing={8} pr={[0, 0, 0]}>
        {!viewMode ?
          <>
            <Grid item xs={12} md={6}>
              <CompetencySkillsForm
                heading='Competencies:'
                values={competencies}
                setValues={setCompetencies}
                competencyRatingScale={competencyRatingScale}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CompetencySkillsForm
                heading='Skills:'
                values={skills}
                setValues={setSkills}
              />
            </Grid>
          </> :
          <>
            <Grid item xs={12} md={6}>
              <CompetencySkillsFormView
                heading='Competencies:'
                values={competencies}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CompetencySkillsFormView
                heading='Skills:'
                values={skills}
              />
            </Grid>
          </>}
      </Grid>
      <Divider sx={{ my: 2 }} />
      <OverallRemarks
        formConfig={interviewFeedbackFormConfig}
        formInfo={interviewFeedbackFormInfo}
        setFormInfo={setInterviewFeedbackFormInfo}
      />
    </Box>
  );
};

export default InterviewFeedbackForm;