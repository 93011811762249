import API from './api';
import store from '../redux/store';
// http://localhost:4545/recruiting/job-requisition/draft
export class SeparationServices extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL + '/seperation/' || '';
  constructor() {
    super(SeparationServices.baseURL);
  }

  postResign = async ({ reason, attachments, roleId }) => (
    this.responsify(await this.instance.post('create', { reason, attachments, roleId })));

  updateResign = async ({ reason, attachments, resignId, roleId }) => (
    this.responsify(await this.instance.post('update', { resignId, attachments, reason, roleId })));

  approveOrReject = async ({ resignationId, actionId, lastWorkingDate, remarks, actionComment }) => (
    this.responsify(await this.instance.post('approval', { resignationId, actionId, lastWorkingDate, remarks, actionComment })));

  getUserResignDetails = async ({ userId }) => (
    this.responsify(await this.instance.get(`employee/${userId}`)));

  saveDetails = async (body) => (
    this.responsify(await this.instance.post('save', body)));

}