import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import SelectTag from '../../../../../../../atoms/Select/SelectTag';
import PermissionsType from '../permission-type.component';
import CheckBoxInput from '../../../../../../../atoms/checkBox/checkBox';
import { RoleService } from '../../../../../../../services/roles';
import { getDropdownListValue } from '../../../../../common/Form/util/form.util';
import FieldView from '../../../../../../molecules/FieldView/FieldView';

const ClaimParentModel = (props) => {
  const {
    setFormInfo,
    formInfo,
    moduleId,
    roleId,
    roleName,
    setError,
    error,
    showDropDown = true,
  } = props;
  const [option, setOption] = useState([]);

  useEffect(() => {
    if (showDropDown) {
      new RoleService().getChildModules(moduleId).then((res) => {
        const moduleList = res.map((role) => {
          const { id, displayName } = role;
          return getDropdownListValue(id, displayName);
        });
        setOption(moduleList);
      });
    }
  }, []);

  useEffect(async () => {
    if (!showDropDown) {
      await new RoleService().getChildModules(moduleId).then(async (res) => {
        const permissionData = await new RoleService().getChildPermissions(
          roleId,
          res?.[0]?.id
        );
        const { id, ...rest } = permissionData || {};
        if (permissionData) {
          setFormInfo((prev) => ({
            ...prev,
            ...rest,
            moduleId: res?.[0]?.id,
          }));
        } else {
          setFormInfo((prev) => ({
            moduleId: res?.[0]?.id,
          }));
        }
        setError('');
      });
    }
  }, [showDropDown]);

  const handleDropDown = async (event) => {
    const { value, name } = event.target;
    const permissionData = await new RoleService().getChildPermissions(
      roleId,
      value
    );
    const { id, ...rest } = permissionData || {};
    if (permissionData) {
      setFormInfo((prev) => ({
        ...prev,
        ...rest,
        [name]: value,
      }));
    } else {
      setFormInfo((prev) => ({
        [name]: value,
      }));
    }
    setError('');
  };

  const handleCheckboxChange = (event) => {
    const key = event.target.name;
    const val = event.target.checked;
    setFormInfo((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  return (
    <>
      <Box width="100hh" bgcolor="#f2f2f2" padding={2} display="flex">
        {showDropDown && (
          <Box width={350}>
            <SelectTag
              labelText="Select Form"
              placeholder="select form"
              name="moduleId"
              dropDownList={option}
              size="small"
              fullWidth
              value={formInfo?.moduleId}
              onchange={handleDropDown}
              errorMessage={error}
            />
          </Box>
        )}
        <Box ml={showDropDown ? '25%' : '2%'}>
          <FieldView labelText="Role" labelValue={roleName} />
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="100px auto"
        gridTemplateRows="70px 70px 70px"
        gap="50px 50px"
        padding={1}
      >
        <Typography
          variant="h4"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          View:
        </Typography>
        <PermissionsType formInfo={formInfo} setFormInfo={setFormInfo} />
        {showDropDown && (
          <>
            <Typography
              variant="h4"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              Show Subtype:
            </Typography>
            <CheckBoxInput
              name="showSubtype"
              value={formInfo?.showSubtype ? true : false}
              onChange={handleCheckboxChange}
            />
          </>
        )}
        <Typography
          variant="h4"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          Edit:
        </Typography>
        <CheckBoxInput
          name="allowEdit"
          value={formInfo?.allowEdit ? true : false}
          onChange={handleCheckboxChange}
        />
        <Typography
          variant="h4"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          Add:
        </Typography>
        <CheckBoxInput
          name="allowAdd"
          value={formInfo?.allowAdd ? true : false}
          onChange={handleCheckboxChange}
        />
      </Box>
    </>
  );
};

export default ClaimParentModel;
