import { clone } from 'lodash';
import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import { GET_ALL_JOB_LIST_FAILURE, GET_ALL_JOB_LIST_REQUEST, GET_ALL_JOB_LIST_SUCCESS, GET_JOB_CONFIG_FAILURE, GET_JOB_CONFIG_REQUEST, GET_JOB_CONFIG_SUCCESS, POST_DRAFT_FAILURE, POST_DRAFT_REQUEST, POST_DRAFT_SUCCESS, POST_JOB_INFO_FAILURE, POST_JOB_INFO_REQUEST, POST_JOB_INFO_SUCCESS, RESET_JOB } from './actionTypes';
import { JobFormFieldNames } from '../../components/Recruiting/Constant/constant';

const initialState = {
    requestProcessing: {
        [APIRequestStatus.GET_JOB_CONFIG]: LoadingStatus.Loading,
        [APIRequestStatus.POST_JOB_INFO]: LoadingStatus.Loading,
        [APIRequestStatus.GET_ALL_JOB_LIST]: LoadingStatus.Loading,
        [APIRequestStatus.POST_JOB_INFO_DRAFT]: LoadingStatus.Loading,
    },
    jobConfig: [],
    jobFormData: {}, 
    jobList: [],
    isReqApprover: false,
    jobIdPropertiesMap: null,
    logs: [],
    auditTrail:[],
    approvalFlow: [],
  };
  
export const jobReducer = (state = initialState, action) => {
  switch (action.type) {
          
    case GET_JOB_CONFIG_REQUEST: {
      return {
        ...state, 
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_JOB_CONFIG]: LoadingStatus.Loading
      }
      };
    }

    case GET_JOB_CONFIG_SUCCESS: {
      const {formData , fromLib, newReq} = action.payload;
      const oldJobCode = state.jobFormData[JobFormFieldNames.Code];
      const { formConfig, jobInfo,isReqApprover, logs,auditTrail, approvalFlow } = formData;
      console.log(formData);
      const { id, [JobFormFieldNames.StatusCode]: status, 
        [JobFormFieldNames.Code]: jobCode, fkDocument, createdBy, updatedBy, ...rest} = jobInfo;
      if(fromLib && !newReq) {
        jobInfo[JobFormFieldNames.Code] = oldJobCode;
      }
      return {
        ...state,
        jobConfig: formConfig,
        jobFormData: fromLib && newReq ? rest : jobInfo,
        isReqApprover: fromLib && newReq ? false : isReqApprover,
        logs: fromLib && newReq ? [] : logs,
        auditTrail:auditTrail || [],
        approvalFlow: fromLib && newReq ? [] : approvalFlow, 
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_JOB_CONFIG]: LoadingStatus.Success
      }
      };
    }

    case GET_JOB_CONFIG_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_JOB_CONFIG]: LoadingStatus.Failure
      }
      };
    }

    case POST_JOB_INFO_REQUEST: {
      return {
        ...state, 
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_JOB_INFO]: LoadingStatus.Loading
      }
      };
    }

    case POST_JOB_INFO_SUCCESS: {
      const {jobFormInfo, id } = action.payload;
      id && Object.assign(jobFormInfo, {id});
      return {
        ...state,
        jobFormData: jobFormInfo,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_JOB_INFO]: LoadingStatus.Success
      }
      };
    }

    case POST_JOB_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_JOB_INFO]: LoadingStatus.Failure
      }
      };
    }

    case GET_ALL_JOB_LIST_REQUEST: {
      return {
        ...state, 
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_JOB_LIST]: LoadingStatus.Loading
      }
      };
    }

    case GET_ALL_JOB_LIST_SUCCESS: {
      const {isReqApprover,jobList,jobIdStatusMap} = action.payload;
      const jobIdPropertiesMap = {};
      jobList.forEach((item) => {
        const {id, ...rest} = item;
        Object.assign(jobIdPropertiesMap, {[id]: rest});
      });
      return {
        ...state,
        jobList: jobList,
        isReqApprover:isReqApprover,
        jobIdPropertiesMap:jobIdPropertiesMap,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_JOB_LIST]: LoadingStatus.Success
      }
      };
    }

    case GET_ALL_JOB_LIST_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_JOB_LIST]: LoadingStatus.Failure
      }
      };
    }

    case RESET_JOB: {
      return initialState;
    }

    case POST_DRAFT_REQUEST: {
      return {
        ...state, 
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_JOB_INFO_DRAFT]: LoadingStatus.Loading
      }
      };
    }

    case POST_DRAFT_SUCCESS: {
      const {jobFormInfo, id } = action.payload;
      id && Object.assign(jobFormInfo, {id});
      return {
        ...state,
        jobFormData: jobFormInfo,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_JOB_INFO_DRAFT]: LoadingStatus.Success
      }
      };
    }

    case POST_DRAFT_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_JOB_INFO_DRAFT]: LoadingStatus.Failure
      }
      };
    }

    default: {
        return state;
    }
}
};

