import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button, Link, FormHelperText } from '@mui/material';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CompanyInfoView from './CompanyInfoView';
import FileUPload from '../../../atoms/FileUpload/FileUPload';
import InputField from '../../../atoms/Input/Input';
import { validateForm } from '../../../validations/validation';
import { companyInfoSchema } from '../../../validations/schemas/companyInfo';
import { getCompanyInfo, postCompanyInfo, updateCompanyInfo } from '../../../redux/onboarding/action';
import { fileUpload } from '../../../redux/main/action';
import { ToastMessages, companyInfoConstants, stringSubstitute } from './constants/onboarding.constants';
import { showToast } from '../../../utils/common.util';
import { LoadingStatus, ToastThemes, toastMessage } from '../../../constants/common';
import { getSaveButtonText, getOrgOnboardingTabs } from './utils/onboarding.util';
import { isEmpty, set } from 'lodash';
import { APIRequestStatus, } from '../../../constants/common';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import { getAllCities } from '../../../redux/countriesAndCities/action';
import { getFormBody } from './utils/userOnboarding/userOnboarding.util';
import ActionHeader from './ActionHeader/ActionHeader';
import TryAgain from '../../molecules/ErrorState/error.component';
import { getFormattedValues, getDate } from '../../../utils/formatter';
import { DateFormatters } from '../../../constants/dateConstants';
import DateField from '../../../atoms/DateField/DateField';
import moment from 'moment';
import { companyInfo as companyInfoConst } from './constants/onboarding.constants';
import { Navigate } from 'react-router-dom';


export default function CompanyInfo() {

  const dispatch = useDispatch();
  const { companyInfo, permissions, requestProcessing: { [APIRequestStatus.GET_COMPANY_INFO]: companyInfoLoading } } = useSelector((state) => state.onBoarding);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const { countries, states, cities, callingCodes,
    requestProcessing: { [APIRequestStatus.GET_ALL_CITIES_LIST]: getCitiesLoading, [APIRequestStatus.GET_ALL_COUNTRIES_LIST]: getCountriesLoading } } = useSelector((state) => state.countriesAndCities);

  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedBCity, setSelectedBCity] = useState(null);
  const [selectedBState, setSelectedBState] = useState(null);
  const [selectedBCountry, setSelectedBCountry] = useState(null);
  const onboardingTabs = getOrgOnboardingTabs(permissions).filter((item) => item.show);

  const initailFormInput = {
    companyName: '',
    companyLogo: null,
    companyDescription: '',
    companyEmail: '',
    companyWebsite: '',
    callingCode: null,
    phoneNumber: '',
    altPhoneNumber: '',
    altCallingCode: null,
    address: '',
    addressCity: selectedCity,
    addressState: selectedState,
    addressCountry: selectedCountry,
    addressPostalCode: '',
    billingAddress: '',
    billingAddressCity: selectedBCity,
    billingAddressState: selectedBState,
    billingAddressCountry: selectedBCountry,
    billingAddressPostalCode: '',
    gstNumber: '',
    establishedOn: null
  };
  const [fileRes, setFileRes] = useState();
  const [errors, setErrors] = useState({});
  const [inputs, setInputs] = useState(
    companyInfo ||
    initailFormInput
  );
  const [companyInfoEdit, setCompanyInfoEdit] = useState(true);
  const [fileLoading, setFileLoading] = useState(-1);
  const [isEdit, setIsEdit] = useState(false);

  const handleCityChange = (event) => {
    const values = event.target.name;
    setSelectedCity(event.target.value);
    setSelectedState(null);
    setSelectedCountry(null);
    setInputs({
      ...inputs, [values]: event.target.value,
    });
    setIsEdit(true);
  };

  const handleStateChange = (event) => {
    const values = event.target.name;
    setSelectedState(event.target.value);
    setSelectedCountry(null);
    setInputs({
      ...inputs, [values]: event.target.value
    });
    setIsEdit(true);
  };

  const handleCountryChange = (event) => {
    const values = event.target.name;
    setSelectedCountry(event.target.value);
    setInputs({
      ...inputs, [values]: event.target.value
    });
    setIsEdit(true);
  };

  const handleBCityChange = (event) => {
    const values = event.target.name;
    setSelectedBCity(event.target.value);
    setSelectedBState(null);
    setSelectedBCountry(null);
    setInputs({
      ...inputs, [values]: event.target.value
    });
    setIsEdit(true);
  };

  const handleBStateChange = (event) => {
    const values = event.target.name;
    setSelectedBState(event.target.value);
    setSelectedBCountry(null);
    setInputs({
      ...inputs, [values]: event.target.value
    });
    setIsEdit(true);
  };

  const handleBCountryChange = (event) => {
    const values = event.target.name;
    setSelectedBCountry(event.target.value);
    setInputs({
      ...inputs, [values]: event.target.value
    });
    setIsEdit(true);
  };

  useEffect(() => {
    if (!companyInfo) {
      dispatch(getCompanyInfo(currentUserRoleId));
      dispatch(getAllCities());
    }
  }, []);

  useEffect(() => {
    if (companyInfoEdit) {
      dispatch(getCompanyInfo(currentUserRoleId));
      dispatch(getAllCities());
    }
  }, [companyInfoEdit]);

  useEffect(() => {
    if (companyInfo)
      setInputs(companyInfo);
  }, [companyInfo]);

  const { id, currencyCode, isPublished, isActive, callingCode, ...rest } = inputs;

  const handleChangeInput = (e) => {
    const values = (e.target.name);
    setInputs({
      ...inputs, [values]: e.target.value
    });
    setErrors({
      ...errors,
      [values]: ''
    });
    setIsEdit(true);
  };

  const handleDateChange = (name, value) => {
    const formattedVal = getFormattedValues(DateFormatters.ddmmyyyy, value);
    setInputs({
      ...inputs,
      [name]: formattedVal
    });
    setErrors((prev) => ({ ...prev, [name]: '' }));
    setIsEdit(true);
  };

  const handleFileChange = (event) => {
    const key = event.target.name;
    setFileLoading(LoadingStatus.Loading);
    dispatch(fileUpload(event.target.files[0])).then((res) => {
      setInputs({
        ...inputs, [key]: { id: res?.id, url: res?.url }
      });
      setFileLoading(LoadingStatus.Success);
      setFileRes(res);
      showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.upload), ToastThemes.success);
    }).catch((err) => {
      showToast(err, ToastThemes.error);
      setFileLoading(LoadingStatus.Failure);
    });
    setIsEdit(true);
  };

  const handleDraftSubmit = () => {
    const formInfo = getFormBody(inputs);
    if (isPublished) {
      const validationErrors = validateForm(rest, companyInfoSchema);
      if (validationErrors) {
        setErrors(validationErrors);
      } else {
        if (!isEmpty(companyInfo)) {
          dispatch(updateCompanyInfo(formInfo, currentUserRoleId)).then((success) => {
            if (success) {
              setCompanyInfoEdit(true);
              showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.success), ToastThemes.success);
            }
            else {
              showToast(ToastMessages.failed.replace(stringSubstitute, toastMessage.error), ToastThemes.error);
            }
          });
        } else {
          dispatch(postCompanyInfo(formInfo, currentUserRoleId)).then((success) => {
            if (success) {
              setCompanyInfoEdit(true);
              showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.success), ToastThemes.success);
            }
            else {
              showToast(ToastMessages.failed.replace(stringSubstitute, toastMessage.error), ToastThemes.error);
            }
          });
        }
      }
    } else {
      if (!isEmpty(companyInfo)) {
        dispatch(updateCompanyInfo(formInfo, currentUserRoleId)).then((success) => {
          if (success) {
            setCompanyInfoEdit(true);
            showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.success), ToastThemes.success);
          }
          else {
            showToast(ToastMessages.failed.replace(stringSubstitute, toastMessage.error), ToastThemes.error);
          }
        });
      } else {
        dispatch(postCompanyInfo(formInfo, currentUserRoleId)).then((success) => {
          if (success) {
            setCompanyInfoEdit(true);
            showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.success), ToastThemes.success);
          }
          else {
            showToast(ToastMessages.failed.replace(stringSubstitute, toastMessage.error), ToastThemes.error);
          }
        });
      }
    }
  };

  const copyAddress = () => {
    selectedCity ? setSelectedBCity(selectedCity) : setSelectedBCity(inputs.addressCity);
    selectedCity ? setSelectedBState(selectedState) : setSelectedBState(inputs.addressState);
    selectedCity ? setSelectedBCountry(selectedCountry) : setSelectedBCountry(inputs.addressCountry);
    setInputs({
      ...inputs,
      billingAddress: inputs.address,
      billingAddressCity: selectedCity ? selectedCity : inputs.addressCity,
      billingAddressPostalCode: inputs.addressPostalCode,
      billingAddressState: selectedState ? selectedState : inputs.addressState,
      billingAddressCountry: selectedCountry ? selectedCountry : inputs.addressCountry
    });
  };

  const onPublishClick = () => {
    const formInfo = getFormBody(inputs);
    const { id, currencyCode, isActive, ...rest } = formInfo;
    const validationErrors = validateForm(rest, companyInfoSchema);
    if (validationErrors) {
      setErrors(validationErrors);
    } else {
      setCompanyInfoEdit(true);
      dispatch(postCompanyInfo({ ...formInfo, isPublished: true }, currentUserRoleId)).then((success) => {
        if (success) {
          setCompanyInfoEdit(true);
          showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.publish), ToastThemes.success);
        }
        else {
          showToast(ToastMessages.failed.replace(stringSubstitute, toastMessage.publishError), ToastThemes.error);
        }
      });
    }
  };

  const onDiscardClick = () => {
    if (companyInfo) {
      setInputs(companyInfo);
    } else {
      setInputs(initailFormInput);
    }
    setCompanyInfoEdit(true);
  };

  if (companyInfoLoading === LoadingStatus.Failure) {
    if(!permissions[companyInfoConst]?.allowView){
      return <Navigate to={onboardingTabs?.length ? onboardingTabs[0].path : '/home'} />;
    }
    return <TryAgain />;
  } else {
    return (
      <Box>
        <CircularLoader show={companyInfoLoading === LoadingStatus.Loading || fileLoading === LoadingStatus.Loading}
        // || getCitiesLoading === LoadingStatus.Loading || getCountriesLoading === LoadingStatus.Loading} 
        />
        {companyInfo && companyInfoEdit ?
          <CompanyInfoView
            companyInfoEdit={companyInfoEdit}
            setCompanyInfoEdit={setCompanyInfoEdit}
          /> :
          <>
            <Box mb={3} mx={-3} mt={-3}>
              <ActionHeader labelText='Entity'
                onClick={handleDraftSubmit}
                showPublish={!companyInfo && !companyInfo?.isPublished}
                onPublishClick={onPublishClick}
                onEditClick={handleDraftSubmit}
                editButtonText={getSaveButtonText((companyInfoEdit ? !companyInfoEdit : companyInfoEdit), isPublished)}
                onClickDiscard={onDiscardClick}
                showDiscard={true}
                disabledDiscard={isEdit}
                disabledSave={!isEdit}
              />

            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container rowSpacing={3} columnSpacing={8}>
                <Grid item md={6}>
                  <InputField
                    placeholder="Enter Company Name"
                    id="companyName"
                    labelText="Company Name"
                    fullWidth
                    name="companyName"
                    error={errors.companyName}
                    helperText={errors.companyName}
                    value={inputs.companyName}
                    onChange={(e) => handleChangeInput(e)}
                    size='small'
                  />
                </Grid>
                <Grid item md={6}>
                  <FileUPload
                    onUpload={handleFileChange}
                    labelText='Company logo' allowedImageType={companyInfoConstants.imageValidation}
                    placeholder={companyInfoConstants.imagePlaceholder}
                    name='companyLogo'
                    fileRes={fileRes}
                  />
                </Grid>
                <Grid item md={12}>
                  <InputField
                    id="coDesc"
                    labelText="Company Description"
                    multiline
                    fullWidth
                    name="companyDescription"
                    placeholder='Enter Company Description'
                    value={inputs.companyDescription}
                    onChange={(e) => handleChangeInput(e)}
                    error={errors.companyDescription}
                    helperText={errors.companyDescription}
                  />
                </Grid>
                <Grid item md={6}>
                  <InputField
                    id="coEmail"
                    placeholder="eg: info@abc.com"
                    labelText="Company email"
                    size="small"
                    fullWidth
                    required
                    name="companyEmail"
                    value={inputs.companyEmail}
                    onChange={(e) => handleChangeInput(e)}
                    error={errors.companyEmail}
                    helperText={errors.companyEmail}
                  />
                </Grid>
                <Grid item md={6}>
                  <Typography variant="subtitle1"></Typography>
                  <InputField
                    id="coWeb"
                    placeholder="eg: www.company123.com"
                    labelText="Company Website"
                    size="small"
                    fullWidth
                    name="companyWebsite"
                    value={inputs.companyWebsite}
                    onChange={(e) => handleChangeInput(e)}
                    error={errors.companyWebsite}
                    helperText={errors.companyWebsite}
                  />
                </Grid>
                <Grid item md={6}>
                  <Typography variant="body2" color={'neutral.dark80'} mb={1}>
                    Phone number
                    <Box
                      color="accentSecondary.main"
                      sx={{ display: 'inline-block' }}
                    >
                      *
                    </Box>
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    <Box mr={2}>
                      <Select
                        displayEmpty
                        value={inputs.callingCode}
                        onChange={(e) => handleChangeInput(e)}
                        name='callingCode'
                        inputProps={{ 'aria-label': 'Without label' }}
                        size='small'
                        sx={{ width: '100%', minWidth: 75 }}
                        renderValue={(selected) => selected ? selected.callingCode : <Box sx={{ color: 'neutral.dark40' }}>{'Select Calling Code'}</Box>}
                      >
                        {callingCodes.map((code) => {
                          const { id, callingCode } = code;
                          return <MenuItem key={id} value={{ id, callingCode }}>{callingCode}</MenuItem>;
                        }
                        )}
                      </Select>
                    </Box>
                    <InputField
                      id="phNum"
                      placeholder="Type here"
                      size="small"
                      fullWidth
                      required
                      name="phoneNumber"
                      value={inputs.phoneNumber}
                      onChange={(e) => handleChangeInput(e)}
                      error={errors.phoneNumber}
                      helperText={errors.phoneNumber}
                      type='text'
                    />
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Typography variant="body2" color={'neutral.dark80'} mb={1}>
                    Alternate phone number
                    <Box
                      color="accentSecondary.main"
                      sx={{ display: 'inline-block' }}
                    >
                      *
                    </Box>
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    <Box mr={2}>
                      <Select
                        displayEmpty
                        value={inputs.altCallingCode}
                        onChange={(e) => handleChangeInput(e)}
                        name='altCallingCode'
                        inputProps={{ 'aria-label': 'Without label' }}
                        size='small'
                        sx={{ width: '100%', minWidth: 75 }}
                        renderValue={(selected) => selected ? selected.callingCode : <Box sx={{ color: 'neutral.dark40' }}>{'Select Calling Code'}</Box>}
                      >
                        {callingCodes.map((code) => {
                          const { id, callingCode } = code;
                          return <MenuItem key={id} value={{ id, callingCode }}>{callingCode}</MenuItem>;
                        }
                        )}
                      </Select>
                    </Box>
                    <InputField
                      id="aPhNum"
                      placeholder="Type here"
                      size="small"
                      fullWidth
                      required
                      name="altPhoneNumber"
                      value={inputs.altPhoneNumber}
                      onChange={(e) => handleChangeInput(e)}
                      error={errors.altPhoneNumber}
                      helperText={errors.altPhoneNumber}
                      type='text'
                    />
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <InputField
                    id="address"
                    placeholder="Address Line 1"
                    labelText="Address"
                    size="small"
                    fullWidth
                    required
                    name="address"
                    value={inputs.address}
                    onChange={(e) => handleChangeInput(e)}
                    error={errors.address}
                    helperText={errors.address}
                  />
                  <Select
                    displayEmpty
                    value={inputs.addressCity ? inputs.addressCity : selectedCity}
                    onChange={handleCityChange}
                    name='addressCity'
                    inputProps={{ 'aria-label': 'Without label' }}
                    size='small'
                    sx={{ width: '100%', mt: 2 }}
                    renderValue={(selected) => selected ? selected.cityName : <Box sx={{ color: 'neutral.dark40' }}>{'Select City'}</Box>}
                  >
                    {cities.map((code) => {
                      const { id, cityName } = code;
                      return <MenuItem key={id} value={{ id, cityName }}>{cityName}</MenuItem>;
                    }
                    )}
                  </Select>
                  <FormHelperText style={{ color: '#d32f2f' }}>{errors.addressCity}</FormHelperText>
                  <Grid container columnSpacing={2}>
                    <Grid item md={4} pt={2}>
                      <InputField
                        id="phCode"
                        placeholder="postal-code"
                        size="small"
                        fullWidth
                        hiddenLabel
                        name="addressPostalCode"
                        value={inputs.addressPostalCode}
                        onChange={(e) => handleChangeInput(e)}
                        error={errors.addressPostalCode}
                        helperText={errors.addressPostalCode}
                        type='number'
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Box mt={2}>
                        <Select
                          displayEmpty
                          value={selectedCity ? selectedState : inputs.addressState}
                          onChange={handleStateChange}
                          name='addressState'
                          inputProps={{ 'aria-label': 'Without label' }}
                          size='small'
                          sx={{ width: '100%' }}
                          renderValue={(selected) => selected ? selected.stateName : <Box sx={{ color: 'neutral.dark40' }}>{'Select State'}</Box>}
                        >{cities.filter(
                          (city) => city.id === selectedCity?.id
                        ).map((code) => {
                          const { id, stateName } = code;
                          return <MenuItem key={id} value={{ id, stateName }}>{stateName}</MenuItem>;
                        }
                        )}
                        </Select>
                        <FormHelperText style={{ color: '#d32f2f' }}>{errors.addressState}</FormHelperText>
                      </Box>
                    </Grid>
                    <Grid item md={4}>
                      <Box mt={2}>
                        <Select
                          displayEmpty
                          value={selectedCity ? selectedCountry : inputs.addressCountry}
                          onChange={handleCountryChange}
                          name='addressCountry'
                          inputProps={{ 'aria-label': 'Without label' }}
                          size='small'
                          sx={{ width: '100%' }}
                          renderValue={(selected) => selected ? selected.countryName : <Box sx={{ color: 'neutral.dark40' }}>{'Select Country'}</Box>}
                        >
                          {cities.filter(
                            (city) => city.id === selectedCity?.id
                          ).map((code) => {
                            const { countryId, countryName } = code;
                            return <MenuItem key={countryId} value={{ id: countryId, countryName }}>{countryName}</MenuItem>;
                          }
                          )}
                        </Select>
                        <FormHelperText style={{ color: '#d32f2f' }}>{errors.addressCountry}</FormHelperText>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Typography variant="body2" color={'neutral.dark80'} mb={1}>
                    Billing Address{' '}
                    <Link
                      variant="caption"
                      color="error"
                      sx={{ textTransform: 'capitalize', cursor: 'pointer', ml: 1 }}
                      onClick={copyAddress}
                    >
                      Same as company address
                    </Link>
                  </Typography>
                  <InputField
                    id="bAdd"
                    placeholder="Address Line 1"
                    size="small"
                    fullWidth
                    name="billingAddress"
                    value={inputs.billingAddress}
                    onChange={(e) => handleChangeInput(e)}
                  />
                  <Select
                    displayEmpty
                    value={inputs.billingAddressCity ? inputs.billingAddressCity : selectedBCity}
                    onChange={handleBCityChange}
                    name='billingAddressCity'
                    inputProps={{ 'aria-label': 'Without label' }}
                    size='small'
                    sx={{ width: '100%', mt: 2 }}
                    renderValue={(selected) => selected ? selected.cityName : <Box sx={{ color: 'neutral.dark40' }}>{'Select Billing City'}</Box>}
                  >
                    {cities.map((code) => {
                      const { id, cityName } = code;
                      return <MenuItem key={id} value={{ id, cityName }}>{cityName}</MenuItem>;
                    }
                    )}
                  </Select>
                  <Grid container columnSpacing={2}>
                    <Grid item md={4} pt={2}>
                      <InputField
                        id="altPostCode"
                        placeholder="alternate-postal-code"
                        size="small"
                        fullWidth
                        hiddenLabel
                        name="billingAddressPostalCode"
                        value={inputs.billingAddressPostalCode}
                        onChange={(e) => handleChangeInput(e)}
                        type='number'
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Box mt={2}>
                        <Select
                          displayEmpty
                          value={selectedBCity ? selectedBState : inputs.billingAddressState}
                          onChange={handleBStateChange}
                          name='billingAddressState'
                          inputProps={{ 'aria-label': 'Without label' }}
                          size='small'
                          sx={{ width: '100%' }}
                          renderValue={(selected) => selected ? selected.stateName : <Box sx={{ color: 'neutral.dark40' }}>{'Select Billing State'}</Box>}
                        >{cities.filter(
                          (city) => city.id === selectedBCity?.id
                        ).map((code) => {
                          const { id, stateName } = code;
                          return <MenuItem key={id} value={{ id, stateName }}>{stateName}</MenuItem>;
                        }
                        )}
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item md={4}>
                      <Box mt={2}>
                        <Select
                          displayEmpty
                          value={selectedBCity ? selectedBCountry : inputs.billingAddressCountry}
                          onChange={handleBCountryChange}
                          name='billingAddressCountry'
                          inputProps={{ 'aria-label': 'Without label' }}
                          size='small'
                          sx={{ width: '100%' }}
                          renderValue={(selected) => selected ? selected.countryName : <Box sx={{ color: 'neutral.dark40' }}>{'Select Billing Country'}</Box>}
                        >
                          {cities.filter(
                            (city) => city.id === selectedBCity?.id
                          ).map((code) => {
                            const { countryId, countryName } = code;
                            return <MenuItem key={countryId} value={{ id: countryId, countryName }}>{countryName}</MenuItem>;
                          }
                          )}
                        </Select>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <InputField
                    id="coGstNum"
                    placeholder="eg: 22AA47301583028"
                    labelText="GST number"
                    size="small"
                    fullWidth
                    required
                    name="gstNumber"
                    value={inputs.gstNumber}
                    onChange={(e) => handleChangeInput(e)}
                    error={errors.gstNumber}
                    helperText={errors.gstNumber}
                  />
                </Grid>
                <Grid item md={6}>
                  <DateField
                    id='establishedOn'
                    placeholder='dd/mm/yyyy'
                    labelText='Established On'
                    name='establishedOn'
                    value={inputs?.establishedOn ? getDate((moment(inputs?.establishedOn, 'YYYY-MM-DD', true).isValid())?moment(inputs?.establishedOn, 'YYYY-MM-DD').format(DateFormatters.ddmmyyyy):inputs?.establishedOn, DateFormatters.ddmmyyyy) : null}
                    onChange={(value) => handleDateChange('establishedOn', value)}
                    fullWidth
                  />
                </Grid>

              </Grid>
            </Box>
          </>
        }
      </Box>
    );
  }
}