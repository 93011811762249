import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Typography,
} from '@mui/material';
import React from 'react';
import { LightTooltip } from '../Tooltip/LightTooltip';
import { FileUploadOutlined, InfoOutlined } from '@mui/icons-material';
import { UploadBox } from './Upload-box.styled';
import theme from '../../foundation/theme';
import MultiFileUploadView from './MultiFileUploadView';
import FieldView from '../../components/molecules/FieldView/FieldView';

const MutliFileUpload = (props) => {
  const {
    onUpload,
    name,
    placeholder,
    labelText,
    required,
    allowedImageType,
    disabled,
    fileRes,
    infoIcon,
    isReadOnly,
    value,
    setFormData,
    setFileRes,
    errorMessage,
  } = props;

  const handleRemove = (index) => {
    const updatedFileRes = [...fileRes];
    updatedFileRes.splice(index, 1);

    const updatedFormData = { ...value };
    updatedFormData[name] = updatedFileRes.map((file) => ({
      id: file.id,
      url: file.url,
    }));
    setFormData(updatedFormData);
    setFileRes((prev) => ({
      ...prev,
      [name]: [...updatedFileRes],
    }));
  };

  if (isReadOnly) {
    return (
      <FieldView
        labelText={labelText}
        labelValue={
          <Box
            style={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto' }}
          >
            <MultiFileUploadView fileRes={fileRes} />
          </Box>
        }
        required={required}
      />
    );
  }

  return (
    <>
      {labelText && (
        <Typography
          variant="body2"
          color={`${disabled ? 'neutral.light40' : 'neutral.dark80'}`}
          mb={1}
        >
          {labelText}
          {required && (
            <Box color="accentSecondary.main" sx={{ display: 'inline-block' }}>
              *
            </Box>
          )}
          {infoIcon && (
            <LightTooltip title={infoIcon} placement="top" arrow>
              <Box color="primary.main" sx={{ display: 'inline-block' }}>
                <InfoOutlined
                  sx={{ width: 14, height: 14, ml: '4px', cursor: 'pointer' }}
                />
              </Box>
            </LightTooltip>
          )}
        </Typography>
      )}
      <UploadBox errorMessage={errorMessage ? true : false}>
        <button disabled={disabled}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2" color={'neutral.dark40'}>
              {placeholder}
            </Typography>
            <FileUploadOutlined
              sx={{
                height: '18px',
                mt: '3px',
                color: disabled ? 'neutral.dark40' : 'neutral.dark80',
              }}
            />
          </Box>
        </button>
        <input
          type="file"
          onChange={onUpload}
          name={name}
          accept={
            allowedImageType ||
            'image/jpeg, image/jpg, image/png, application/pdf'
          }
          disabled={disabled}
          onClick={(event)=> { 
            event.target.value = null;
       }}
        />
      </UploadBox>
      {errorMessage && (
        <FormHelperText style={{ color: '#d32f2f' }}>
          {errorMessage}
        </FormHelperText>
      )}
      {fileRes && (
        <Box style={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto' }}>
          <MultiFileUploadView
            fileRes={fileRes}
            handleRemove={handleRemove}
            remove={true}
          />
        </Box>
      )}
    </>
  );
};

export default MutliFileUpload;
