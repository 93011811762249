import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  marginBottom: 8,

  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => {
  return (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  );
})(({ theme, bgColorSummary }) => ({
  backgroundColor: `${bgColorSummary || theme.palette.neutral.light80}`,
  borderRadius: 8,
  justifyContent: 'space-between',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  border: '1px solid #CBD5E1',
  borderRadius: 8,
  marginTop: 8,
  marginBottom: 8,
}));

export default function AccordionTabs(props) {
  const { bgColorSummary, title, tabDetails, width, expand, mt } = props;
  const [expanded, setExpanded] = React.useState(expand || 'panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={{ width: width, mt: mt || 'none' }}>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          bgColorSummary={bgColorSummary}
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{tabDetails}</AccordionDetails>
      </Accordion>
    </Box>
  );
}
