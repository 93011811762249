import { Box, Grid } from '@mui/material';
import React from 'react';
import ActionHeader from '../../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import FormView from '../../../../OnBoarding/common/Form/FormView';
import { useSelector } from 'react-redux';

const ClaimTRFView = (props) => {
  const { config, info } = props;
  const { publicHoliday } = useSelector((state) => state && state.onBoarding);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box mb={3} mx={-2} mt={-2}>
        <ActionHeader
          labelText={info.referenceNumber || 'New TRF Claim'}
          showSave={false}
        />
      </Box>
      <Grid container rowSpacing={3} columnSpacing={3}>
        <Grid item md={12}>
          <FormView list={config} info={info} publicHoliday={publicHoliday} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClaimTRFView;
