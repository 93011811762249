import React, { useMemo, useState } from 'react';
import FormEdit from '../../../OnBoarding/common/Form/FormEdit';
import { useSelector } from 'react-redux';
import { ModalConfigFieldName } from '../../form-builder.constants';
import { BuilderServices } from '../../../../services/builder';
import {
  BuilderModalFormFieldConfig,
  DateFormter,
  FieldFormter,
  FieldSizeDropDown,
} from '../../utils/builder-utils';
import { CommonSevice } from '../../../../services/common';
import { FormFieldTypes } from '../../../../constants/common';

const FormConfigEdit = (props) => {
  const {
    formInfo,
    setFormInfo,
    fieldType,
    validationErrors,
    setValidationErrors,
    dataType,
  } = props;
  const [fieldRefName, setFieldRefName] = useState([]);
  const [fieldValidation, setFieldValidation] = useState([]);
  const { list } = useSelector((state) => state.formBuilder);

  const handleChangeRefNameType = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    setValidationErrors((prev) => ({
      ...prev,
      [key]: '',
      [ModalConfigFieldName.FieldLabel]: '',
    }));
    setFormInfo((prev) => ({
      ...prev,
      [key]: value,
      [ModalConfigFieldName.FieldLabel]: value?.name,
    }));
  };

  const formConfig = useMemo(() => {
    const config = BuilderModalFormFieldConfig.reduce((acc, item) => {
      let temp = item;
      switch (item.fieldRefName) {
        case ModalConfigFieldName.FieldRefName: {
          temp = { ...temp, onChange: handleChangeRefNameType };
          break;
        }
        case ModalConfigFieldName.DateFormat: {
          if (fieldType === FormFieldTypes.Date) {
            temp = { ...temp, isHidden: false, fieldSize: 13 };
          }
          break;
        }
        case ModalConfigFieldName.DataFormat:
        case ModalConfigFieldName.ThousandSeparator:
        case ModalConfigFieldName.RemoveDecimal: {
          if (
            fieldType === FormFieldTypes.Formula ||
            dataType === FormFieldTypes.Number ||
            fieldType === FormFieldTypes.EntitlementAutoPopulate ||
            fieldType === FormFieldTypes.AutoPopulate ||
            fieldType === FormFieldTypes.ProRateField
          ) {
            temp = { ...temp, isHidden: false };
          }
          break;
        }
        case ModalConfigFieldName.TimeErrorMessage:
        case ModalConfigFieldName.MinMinutesNextClaim: {
          if (fieldType === FormFieldTypes.DateTime) {
            temp = { ...temp, isHidden: false };
          }
          break;
        }
        case ModalConfigFieldName.ToUpperCase:
        case ModalConfigFieldName.MaxLength: {
          if (fieldType === FormFieldTypes.Text) {
            temp = { ...temp, isHidden: false };
          }
          break;
        }
        default: {
          temp = item;
          break;
        }
      }
      acc.push(temp);
      return acc;
    }, []);
    return config;
  }, [formInfo[ModalConfigFieldName.FieldRefName], fieldType, dataType]);

  const handleDropdownClick = (name) => {
    switch (name) {
      case ModalConfigFieldName.FieldRefName: {
        return new BuilderServices().getFieldRefName(fieldType).then((res) => {
          setFieldRefName(res);
          return true;
        });
      }
      case ModalConfigFieldName.ValidationOption: {
        return new CommonSevice()
          .getDropDown(ModalConfigFieldName.UnqiueValidationName)
          .then((res) => {
            setFieldValidation(res);
            return true;
          });
      }
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case ModalConfigFieldName.FieldRefName: {
        return fieldRefName.filter((field) => {
          return !list.some((item) => item.sysField.id === field.id);
        });
      }
      case ModalConfigFieldName.FieldSize: {
        return FieldSizeDropDown;
      }
      case ModalConfigFieldName.ValidationOption: {
        return fieldValidation;
      }
      case ModalConfigFieldName.DataFormat: {
        return FieldFormter;
      }
      case ModalConfigFieldName.DateFormat: {
        return DateFormter;
      }
    }
  };
  return (
    <FormEdit
      list={formConfig}
      padding={0}
      setFormData={setFormInfo}
      formInfo={formInfo}
      handleDropdownClick={handleDropdownClick}
      getDropdownOptions={getDropdownOptions}
      hideFields={true}
      errors={validationErrors}
      setErrors={setValidationErrors}
    />
  );
};

export default FormConfigEdit;
