import { Box, Typography } from '@mui/material';
import React from 'react';
import FieldView from '../../../../molecules/FieldView/FieldView';

const BenefitsView = (props) => {
  const { employeeBenifits } = props;
  return (
    <>
      <Typography ml={1} variant="subtitle2">
        Entitlements
      </Typography>
      {employeeBenifits.map((item, index) => {
        const { name, endDate, entitlementAmt, startDate } = item;
        return (
          <Box
            display="grid"
            gridTemplateColumns="1.5fr 1fr 1fr .5fr"
            gridTemplateRows={`repeat(${employeeBenifits?.length}, 3px)`}
            gap="30px 30px"
            padding={1}
            key={index}
          >
            <FieldView labelText="Name" labelValue={name} />
            <FieldView
              labelText="Entitlement Amount"
              labelValue={entitlementAmt || ''}
            />
            <FieldView labelText="Start Date" labelValue={startDate || ''} />
            <FieldView labelText="End Date" labelValue={endDate || ''} />
          </Box>
        );
      })}
    </>
  );
};

export default BenefitsView;
