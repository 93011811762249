import React, { useEffect } from 'react';
import GolasView from './GoalsView';
import GoalsCreate from './GoalsCreate';
import { useDispatch, useSelector } from 'react-redux';
import { APIRequestStatus, LoadingStatus, ToastThemes } from '../../../constants/common';
import { getGoalsConfig, resetGoals } from '../../../redux/goals/action';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import { useLocation } from 'react-router-dom';
import TryAgain from '../../molecules/ErrorState/error.component';
import { GoalsServices } from '../../../services/goals';
import { showToast } from '../../../utils/common.util';
import { PerformanceChild } from './common/constants';

const AddGoals = (props) => {
  const { viewMode, setViewMode } = props;
  const dispatch = useDispatch();
  const { goalsConfig, kraConfig,
    requestProcessing: { [APIRequestStatus.GET_GOALS_CONFIG]: configLoadingStatus }
  } = useSelector((state) => state.goals);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const { performancePermission } = useSelector(
    (state) => state.modulePermissions
  );
  const moduleId = performancePermission?.[PerformanceChild]?.id;
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const idForGoalDetails = pathNames[pathNames.length - 1];

  useEffect(() => {
    if (currentUserRoleId) {
      if (!isNaN(idForGoalDetails)) {
        new GoalsServices().getRatingScales().then((res) => {
          dispatch(getGoalsConfig(currentUserRoleId, res, idForGoalDetails,moduleId));
        }).catch((err) => {
          showToast(err, ToastThemes.error);
        });
        setViewMode(true);
      } else {
        new GoalsServices().getRatingScales().then((res) => {
          dispatch(getGoalsConfig(currentUserRoleId, res,undefined ,moduleId));
        }).catch((err) => {
          showToast(err, ToastThemes.error);
        });
      }
    }
    return (() => {
      setViewMode(false);
      dispatch(resetGoals());
    });
  }, [idForGoalDetails, currentUserRoleId]);

  if (configLoadingStatus === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={configLoadingStatus === LoadingStatus.Loading} />
      {
        configLoadingStatus === LoadingStatus.Success &&
        <div>
          {viewMode ?
            <GolasView
              goalsConfig={goalsConfig}
              kraConfig={kraConfig}
              setViewMode={setViewMode} /> :
            <GoalsCreate
              isNewGoal={isNaN(idForGoalDetails)}
              goalsConfig={goalsConfig}
              kraConfig={kraConfig}
              setViewMode={setViewMode} />
          }
        </div>}
    </>
  );
};

export default AddGoals;