import {
  Box,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CardUI from '../../../shared/components/ui-elements/CardUI';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Footer from '../../footer/Footer';
import PersonIcon from '@mui/icons-material/Person';
import { useLocation } from 'react-router-dom';
import Modal from '../../../shared/components/ui-elements/Modal';
import ButtonUI from '../../../shared/components/ui-elements/ButtonUI';
import { useNavigate } from 'react-router-dom';
import {
  generateCurrentTimeStamp,
  generateFormData,
  disablePrevDates,
} from '../common-services/functions';
import moment from 'moment';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { postFilesData, putFormData } from '../common-services/services';
import UserProfile from '../../../shared/components/claim-forms/UserProfile';
import ReportingDetails from '../../../shared/components/claim-forms/ReportingDetails';

const OvertimeEdit = () => {
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');

  const navigate = useNavigate();
  const params = useLocation();
  const [type] = useState(params.state.type);
  const [claimsData, setClaimsData] = useState(params.state.claimsData);
  const [inputs, setInputs] = useState({
    reportingOfficer: 'Kelvin Lionel',
    hod: 'Benjamin Koh',
    costCenter: 'Singapore Labour',
    claimReferenceNumber: claimsData.referenceNumber,
    submissionDate: generateCurrentTimeStamp(),
    claimAmount: claimsData.amount,
    claimsMonth: claimsData.claimsMonth,
    description: claimsData.description,
    numberOfHoursClaim: claimsData.numberOfHoursClaim,
    reasonsForWorkingOutside: claimsData.reasonsForWorkingOutside,
    overTimeDate: claimsData.overTimeDate,
    timeFrom: moment(claimsData.fromTime).format('HH:mm:ss') || null,
    timeTo: claimsData.toTime,
    mealBreak: claimsData.mealBreak,
    paymentHours: claimsData.paymentHours,
    numberOfShifts: claimsData.numberOfShifts,
  });

  useEffect(() => {
    // console.log('edit', claimsData, type);
  }, [claimsData]);

  const payMentHoursOptions = ['DA', 'OT', 'PH', 'RD', 'OD'];
  let userData = JSON.parse(localStorage.getItem('userData'));
  let asProfile = localStorage.getItem('asProfile');

  const handleChangeInput = (e, key = null) => {
    const values = { ...inputs };
    if (key) values[key] = e;
    else {
      values[e.target.name] = e.target.value;
      switch (e.target.name) {
        case 'paymentHours':
        case 'numberOfHoursClaim':
        case 'numberOfRestDays':
        case 'numberOfShifts':
          calculateOvertimePay(values);
          break;
        default:
          break;
      }
    }
    setInputs(values);
  };

  const cardData = [
    {
      post: 'Reporting Officer',
      name: 'Kelvin Leionel',
    },
    {
      post: 'HOD',
      name: 'Benjamin Koh',
    },
    {
      post: 'Cost Center',
      name: 'Singapore Labour F..',
    },
  ];
  useEffect(() => {
    // console.log(inputs);
  }, [inputs]);
  const validateFields = () => {
    if (
      inputs.overTimeDate == undefined ||
      inputs.overTimeDate == null ||
      inputs.overTimeDate == ''
    ) {
      window.alert('Please Enter Overtime Date !');
      return false;
    }
    if (
      inputs.claimsMonth == undefined ||
      inputs.claimsMonth == null ||
      inputs.claimsMonth == ''
    ) {
      window.alert('Please Enter claims Month!');
      return false;
    }
    if (
      inputs.description == undefined ||
      inputs.description == null ||
      inputs.description == ''
    ) {
      window.alert('Please Enter Description !');
      return false;
    }
    if (
      inputs.reasonsForWorkingOutside == undefined ||
      inputs.reasonsForWorkingOutside == null ||
      inputs.reasonsForWorkingOutside == ''
    ) {
      window.alert('Please Enter Reasons For Working Outside!');
      return false;
    }
    if (
      inputs.paymentHours == undefined ||
      inputs.paymentHours == null ||
      inputs.paymentHours == ''
    ) {
      window.alert('Please Enter Payment Hours!');
      return false;
    }
    if (
      inputs.paymentHours == 'OD' ||
      inputs.paymentHours == 'OT' ||
      inputs.paymentHours == 'RD'
    ) {
      if (
        inputs.numberOfHoursClaim == undefined ||
        inputs.numberOfHoursClaim == null ||
        inputs.numberOfHoursClaim == ''
      ) {
        window.alert('Please Enter Number Of Hours Claim !');
        return false;
      }
    }
    if (
      inputs.timeFrom == undefined ||
      inputs.timeFrom == null ||
      inputs.timeFrom == ''
    ) {
      window.alert('Please Enter A Valid Time From !');
      return false;
    }
    if (
      inputs.timeTo == undefined ||
      inputs.timeTo == null ||
      inputs.timeTo == ''
    ) {
      window.alert('Please Enter A Time To !');
      return false;
    }
    if (
      inputs.mealBreak == undefined ||
      inputs.mealBreak == null ||
      inputs.mealBreak == ''
    ) {
      window.alert('Please Enter A Meal Break !');
      return false;
    }
    if (
      inputs.paymentHours == undefined ||
      inputs.paymentHours == null ||
      inputs.paymentHours == ''
    ) {
      window.alert('Please Enter A Payment Hours !');
      return false;
    }
    if (inputs.paymentHours == 'DA') {
      if (
        inputs.numberOfShifts == undefined ||
        inputs.numberOfShifts == null ||
        inputs.numberOfShifts == ''
      ) {
        window.alert('Please Enter Number Of Shifts!');
        return false;
      }
    }
    if (inputs.paymentHours == 'RD') {
      if (
        inputs.numberOfRestDays == undefined ||
        inputs.numberOfRestDays == null ||
        inputs.numberOfRestDays == ''
      ) {
        window.alert('Please Enter Number Of Rest Days!');
        return false;
      }
    }

    return true;
  };

  const generateDataForClaimRequest = (
    inputs,
    userData,
    dateTime,
    callType
  ) => {
    let updateFormObject = {};
    let callForPost = callType;
    // console.log(updateFormObject);

    if (inputs) {
      return (updateFormObject = {
        typeId: 3,
        employee: userData && userData.userDetails.id,
        claimReferenceNumber: inputs.claimReferenceNumber,
        claimMonth: inputs.claimsMonth,
        from: moment(inputs.timeFrom).format('hh:mm:ss'),
        to: moment(inputs.timeTo).format('hh:mm:ss'),
        amount: Number(inputs.claimAmount),
        mealBreak: moment(inputs.mealBreak).format('hh:mm'),
        overTimeDate: inputs.overTimeDate,
        reasonsForWorkingOutside: inputs.reasonsForWorkingOutside,
        description: inputs.description,
        numberOfHoursClaim: Number(inputs.numberOfHoursClaim),
        paymentHours: inputs.paymentHours,
        submissionDate: dateTime,
        numberOfRestDays: Number(inputs.numberOfRestDays),
        numberOfShifts: Number(inputs.numberOfShifts),
        filePaths: [...claimsData.attachment],
        status: callForPost,
      });
    }
  };

  const submitData = async (callType) => {
    const dateTime = generateCurrentTimeStamp();
    setFormSubmitted(true);
    setButtonClicked(callType);
    let validate = validateFields();

    if (!validate) {
      setFormSubmitted(false);
      return;
    }

    let finalFormData = generateDataForClaimRequest(
      inputs,
      userData,
      dateTime,
      callType
    );

    // console.log(finalFormData);

    // POST call for submit form data.
    let businessTravelsResponse = await putFormData(finalFormData);

    if (businessTravelsResponse === 201) {
      let successMessage = '';
      if (callType === 'PENDING') {
        successMessage = 'Claim request Updated successfully !';
      } else {
        successMessage = 'Draft saved successfully !';
      }
      setFormSubmitted(false);
      openSuccessPopUp(successMessage);
    } else {
      setFormSubmitted(false);
      window.alert('Something went wrong');
    }
  };

  // On file select (from the pop up)
  const onFileChange = async (e, refNumber, typeId = 3) => {
    // Generates form data for file upload.
    let formData = await generateFormData(e, refNumber, typeId);

    // console.log(formData);
    // Send form data for file upload.
    let updatedPaths = await postFilesData(formData);

    // Sets updated paths received from POST api call.
    const newArr = { ...claimsData };
    // console.log(updatedPaths);
    for (let paths of updatedPaths) {
      newArr.attachment.push(paths);
    }
    setClaimsData(newArr);
  };

  // remove attachment from claims data start
  const removeAttachement = (i) => {
    const newArr = { ...claimsData };
    newArr.attachment.splice(i, 1);
    // console.log(newArr.attachment);
    setClaimsData(newArr);
  };
  // remove attachment from claims data end

  // Overtime calculation pay logic
  const calculateOvertimePay = (each) => {
    const monthlyBasicPay = 15000; // Need from client
    const standardMonthsValue = 12;
    const annualWorkingHours = 40 * 52;
    const numberOfHoursClaim = each.numberOfHoursClaim;
    const numberOfWorkignDaysInWeek = 5; // Need from client
    let ratePerDay = (
      (monthlyBasicPay * standardMonthsValue) /
      (52 * numberOfWorkignDaysInWeek)
    ).toFixed(2);
    let overtimePay;

    let selectedOvertimeType = each.paymentHours;
    if (selectedOvertimeType == 'OD' || selectedOvertimeType == 'OT') {
      let ratePerHour =
        (monthlyBasicPay * standardMonthsValue) / annualWorkingHours;
      let overtimeRate = ratePerHour * 1.5;
      overtimePay = overtimeRate * numberOfHoursClaim;
      each.claimAmount = overtimePay.toFixed(2);
    } else if (selectedOvertimeType == 'PH') {
      overtimePay = ratePerDay * 11; // Need from client
      each.claimAmount = overtimePay.toFixed(2);
    } else if (selectedOvertimeType == 'RD') {
      if (each.numberOfHoursClaim > 8) {
        window.alert('Maximum number of hours for rest day claim is 8');
        each.numberOfHoursClaim = 0;
        return;
      }
      if (each.numberOfHoursClaim <= 4) {
        overtimePay = ratePerDay * 1 * each.numberOfRestDays;
        each.claimAmount = overtimePay.toFixed(2);
      } else if (each.numberOfHoursClaim > 4 && each.numberOfHoursClaim <= 8) {
        overtimePay = ratePerDay * each.numberOfRestDays;
        each.claimAmount = overtimePay.toFixed(2);
      }
    } else if (selectedOvertimeType == 'DA') {
      overtimePay = each.numberOfShifts * 30;
      each.claimAmount = overtimePay.toFixed(2);
    }
  };

  useEffect(() => {
    // console.log(inputs);
  }, [inputs]);

  /** Close the modal */
  const closeBackdropHandler = () => {
    setIsBackdropOpen(false);
    navigate('/claims');
  };

  // Open Modal When api response succesfully for(Draft/Submit)
  const openSuccessPopUp = (successMessage) => {
    setSuccessMessage(successMessage);
    setIsBackdropOpen(true);
  };

  useEffect(() => {
    // console.log(claimsData);
  }, [claimsData]);

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Container>
          <Grid container>
              <ReportingDetails cardData={cardData} />
              <UserProfile
                name={userData && userData.userDetails.firstName}
                id={userData && userData.userDetails.id}
                designation={userData.userDetails?.jobTitle?.name}
                department={userData.userDetails?.department?.name}
              />
            <Grid xs={11}>
              <Box>
                <Box
                  className='form-main'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '1em',
                    marginBottom: '4em',
                  }}
                >
                  <Grid xs={11}>
                    <CardUI className='form-heading-wrap'>
                      <Grid
                        xs={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Grid xs={6}>
                          <h4>Overtime Claim</h4>
                        </Grid>
                        <Grid
                          xs={6}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <h4>
                            Claim Reference Number :
                            {inputs.claimReferenceNumber}
                          </h4>
                        </Grid>
                      </Grid>
                    </CardUI>
                    <CardUI className='form-content-wrap'>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Over Time Date</label>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              shouldDisableDate={disablePrevDates()}
                              value={inputs.overTimeDate}
                              onChange={(e) =>
                                handleChangeInput(e, 'overTimeDate')
                              }
                              renderInput={(params) => (
                                <TextField
                                  size='small'
                                  fullWidth
                                  variant='outlined'
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Claim Month *</label>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              variant='inline'
                              format='MM/yyyy'
                              disableFuture
                              views={['year', 'month']}
                              margin='normal'
                              id='date-picker-inline'
                              value={inputs.claimsMonth}
                              onChange={(e) =>
                                handleChangeInput(e, 'claimsMonth')
                              }
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              renderInput={(params) => (
                                <TextField
                                  size='small'
                                  fullWidth
                                  variant='outlined'
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Description</label>
                          <TextField
                            required
                            variant='outlined'
                            color='primary'
                            size='small'
                            value={inputs.description}
                            name='description'
                            fullWidth
                            onChange={(e) => handleChangeInput(e)}
                          />
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Reasons For Working Outside</label>
                          <TextField
                            required
                            variant='outlined'
                            color='primary'
                            size='small'
                            value={inputs.reasonsForWorkingOutside}
                            name='reasonsForWorkingOutside'
                            fullWidth
                            onChange={(e) => handleChangeInput(e)}
                          />
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Payment Hours *</label>
                          <FormControl
                            sx={{ minWidth: 120 }}
                            fullWidth
                            size='small'
                          >
                            <Select
                              value={inputs.paymentHours}
                              onChange={(e) => {
                                handleChangeInput(e);
                              }}
                              displayEmpty
                              name='paymentHours'
                              inputProps={{
                                'aria-label': 'Without label',
                              }}
                              variant='outlined'
                              autoWidth
                            >
                              {payMentHoursOptions &&
                                payMentHoursOptions.map((each, i) => (
                                  <MenuItem key={i} value={each}>
                                    {each}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        {inputs.paymentHours !== 'DA' &&
                          inputs.paymentHours !== 'PH' && (
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Number Of Hours Claim *</label>
                              <TextField
                                type='number'
                                required
                                variant='outlined'
                                color='primary'
                                size='small'
                                value={inputs.numberOfHoursClaim}
                                name='numberOfHoursClaim'
                                fullWidth
                                onChange={(e) => {
                                  handleChangeInput(e);
                                }}
                              />
                            </Grid>
                          )}

                        {inputs.paymentHours == 'RD' && (
                          <Grid item xs={12} lg={4} md={4} sm={4}>
                            <label htmlFor=''>Number Of Rest Days *</label>
                            <TextField
                              type='number'
                              required
                              variant='outlined'
                              color='primary'
                              size='small'
                              value={inputs.numberOfRestDays}
                              name='numberOfRestDays'
                              fullWidth
                              onChange={(e) => {
                                handleChangeInput(e);
                              }}
                            />
                          </Grid>
                        )}

                        {inputs.paymentHours == 'DA' && (
                          <Grid item xs={12} lg={4} md={4} sm={4}>
                            <label htmlFor=''>Number Of Shifts *</label>
                            <TextField
                              type='number'
                              required
                              variant='outlined'
                              color='primary'
                              size='small'
                              value={inputs.numberOfShifts}
                              name='numberOfShifts'
                              fullWidth
                              onChange={(e) => {
                                handleChangeInput(e);
                              }}
                            />
                          </Grid>
                        )}

                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>From *</label>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                              format='HH:mm:ss'
                              value={inputs.timeFrom}
                              onChange={(e) => handleChangeInput(e, 'timeFrom')}
                              renderInput={(params) => (
                                <TextField
                                  size='small'
                                  fullWidth
                                  variant='outlined'
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>To *</label>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                              inputFormat='hh:mm'
                              value={inputs.timeTo}
                              onChange={(e) => handleChangeInput(e, 'timeTo')}
                              renderInput={(params) => (
                                <TextField
                                  size='small'
                                  fullWidth
                                  variant='outlined'
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Meal Break *</label>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                              ampm={false}
                              openTo='hours'
                              views={['hours', 'minutes']}
                              mask='__:__:__'
                              value={inputs.mealBreak}
                              onChange={(e) =>
                                handleChangeInput(e, 'mealBreak')
                              }
                              renderInput={(params) => (
                                <TextField
                                  size='small'
                                  fullWidth
                                  variant='outlined'
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor=''>Claim Amount *</label>
                          <TextField
                            disabled
                            required
                            variant='outlined'
                            color='primary'
                            size='small'
                            value={inputs.claimAmount}
                            name='claimAmount'
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor='icon-button-file'>Attachement</label>
                          <input
                            multiple
                            accept='image/pdf*'
                            type='file'
                            onChange={(e) =>
                              onFileChange(e, inputs.claimReferenceNumber)
                            }
                          />
                          {claimsData.attachment &&
                            claimsData.attachment.map((each, i) => {
                              return (
                                <div key={i} className='file-path-wrap'>
                                  {/* <h6>{each.split('/')[4]}</h6> */}
<h6>{inputs.claimReferenceNumber}_{i+1}</h6>
 <IconButton
  onClick={() => removeAttachement(i)}
                                    disableTouchRipple
                                  >
                                    <CancelRoundedIcon />
                                  </IconButton>
                                </div>
                              );
                            })}
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}></Grid>
                      </Grid>
                    </CardUI>
                    {/* dsdsdsd */}
                  </Grid>
                </Box>
                <Grid style={{ position: 'relative' }}>
                  <Footer
                    type={type}
                    fromEdit
                    onclick={() => submitData('PENDING')}
                    onSaveDraft={() => submitData('DRAFT')}
                    marginRight='20px'
                    disabledSubmit={formSubmitted}
                    buttonClicked = {buttonClicked}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Modal
        show={isBackdropOpen}
        onCancel={closeBackdropHandler}
        header={<h1>Success</h1>}
        contentClass='modal-content'
        footerClass='footer-content'
        submitButton={
          <ButtonUI
            backgroundColor='#00AC94'
            color='#ffffff'
            size='small'
            padding='3px 20px'
            onclick={closeBackdropHandler}
          >
            Ok
          </ButtonUI>
        }
      >
        <h4>{successMessage}</h4>
      </Modal>
    </React.Fragment>
  );
};
export default OvertimeEdit;
