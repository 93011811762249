import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AmountButton from '../benefit-edit-form/benefit-config-buttons/amount-button/amount-button.component';
import { Box, Divider } from '@mui/material';
import ActionHeader from '../../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import BenefitConfigButtons from '../benefit-edit-form/benefit-config-buttons/benefit-config-buttons.component';
import FormView from '../../../../OnBoarding/common/Form/FormView';
import { BenefitFormConfig } from '../../../comp-ben-utlis/comp-ben-utils';

const BenefitView = (props) => {
  const { setViewMode, benefitsData } = props;
  const navigate = useNavigate();

  const [showConfig, setShowConfig] = useState('');

  const formFields = useMemo(() => {
    return BenefitFormConfig.reduce((acc, item) => {
      const { fieldRefName } = item;
      let temp = { ...item };
      switch (fieldRefName) {
        case 'coPaymentAmount':
          temp = {
            ...temp,
            isHidden: !benefitsData?.allowCopayment,
          };
          break;
        default: {
          break;
        }
      }
      if (!temp.isHidden) {
        acc.push(temp);
      }
      if (temp.isHidden) {
        delete benefitsData[temp?.fieldRefName];
      }
      return acc;
    }, []);
  }, [benefitsData]);

  switch (showConfig) {
    case 'amount': {
      return <AmountButton object={benefitsData?.formulaExpression || {}} />;
    }
    case 'startDate': {
      return <>start date</>;
    }
    case 'blockPeriod': {
      return <>bloack period</>;
    }
    default: {
      return (
        <Box>
          <Box mb={3} mx={-3} mt={-3}>
            <ActionHeader
              labelText={benefitsData?.name || 'NEW'}
              editButtonText="Edit"
              onEditClick={() => setViewMode(false)}
              showSave={true}
              showBackButton
              onBackClick={() => navigate(-1)}
            />
          </Box>
          <FormView list={formFields} info={benefitsData} />
          <Divider sx={{ mt: 2, mb: 2 }} />
          <BenefitConfigButtons setShowConfig={setShowConfig} viewMode={true} />
        </Box>
      );
    }
  }
};

export default BenefitView;
