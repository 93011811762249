export const GET_ALL_CLAIM_TAB_REQUEST = 'GET_ALL_CLAIM_TAB_REQUEST';
export const GET_ALL_CLAIM_TAB_SUCCESS = 'GET_ALL_CLAIM_TAB_SUCCESS';
export const GET_ALL_CLAIM_TAB_FAILURE = 'GET_ALL_CLAIM_TAB_FAILURE';

export const GET_ALL_CLAIM_LIST_REQUEST = 'GET_ALL_CLAIM_LIST_REQUEST';
export const GET_ALL_CLAIM_LIST_SUCCESS = 'GET_ALL_CLAIM_LIST_SUCCESS';
export const GET_ALL_CLAIM_LIST_FAILURE = 'GET_ALL_CLAIM_LIST_FAILURE';

export const RESET_CLAIMS = 'RESET_CLAIMS';

export const SET_UNERGIZER_ERROR = 'SET_UNERGIZER_ERROR';

export const RESET_UNERGIZER_ERRORS = 'RESET_UNERGIZER_ERRORS';