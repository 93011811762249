import React, { useCallback, useEffect, useState } from 'react';
import FormEdit from '../../../OnBoarding/common/Form/FormEdit';
import { GoalDropdownEndPoints, GoalFormFieldNames } from '../../Utlis/goals.util';
import { GoalsServices } from '../../../../services/goals';
import { useDispatch } from 'react-redux';
import { ChangeRating } from '../../../../redux/goals/action';

const GoalForm = (props) => {
  const dispatch = useDispatch();
  const { goalsConfig, validationErrors, formInfo, setFormInfo, setValidationErrors } = props;
  const [actionDropDownOptions, setActionDropdownOptions] = useState([]);
  const [priorityDropDownOptions, setPriorityDropdownOptions] = useState([]);
  const [ratingScaleDropDownOptions, setRatingScaleDropDownOptions] = useState([]);

  const handleDropdownClick = (name) => {
    switch (name) {
      case GoalFormFieldNames.Action: {
        return new GoalsServices().getDropdownOptions(GoalDropdownEndPoints.Action).then((res) => {
          setActionDropdownOptions(res);
          return true;
        });
      }
      case GoalFormFieldNames.Priority: {
        return new GoalsServices().getDropdownOptions(GoalDropdownEndPoints.Priority).then((res) => {
          setPriorityDropdownOptions(res);
          return true;
        });
      }
      case GoalFormFieldNames.RatingScale:
      case GoalFormFieldNames.CompetencyRatingSCale: {
        return new GoalsServices().getDropdownOptions(GoalDropdownEndPoints.RatingScale).then((res) => {
          setRatingScaleDropDownOptions(res);
          return true;
        });
      }
      default: {
        break;
      }
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case GoalFormFieldNames.Action: {
        return actionDropDownOptions;
      }
      case GoalFormFieldNames.Priority: {
        return priorityDropDownOptions;
      }
      case GoalFormFieldNames.RatingScale:
      case GoalFormFieldNames.CompetencyRatingSCale: {
        return ratingScaleDropDownOptions;
      }
    }
  };

  const handleChangeKraWeightage = useCallback((event) => {
    const key = event.target.name;
    const value = event.target.value;
    setValidationErrors((prev) => ({ ...prev, [key]: '', [GoalFormFieldNames.CompetencyWeightage]: '' }));
    setFormInfo((prev) => {
      let adjustedValue;
      if (value === '' || value === null) {
        adjustedValue = '';
      } else {
        adjustedValue = value > 99 ? 99 : value < 1 ? 1 : value;
      }
      return {
        ...prev,
        [key]: adjustedValue,
        [GoalFormFieldNames.CompetencyWeightage]: `${100 - adjustedValue}`,
      };
    });
  }, [formInfo[GoalFormFieldNames.KraWeightage]]);

  const handleChangeCompetencyWeightage = useCallback((event) => {
    const key = event.target.name;
    const value = event.target.value;
    setValidationErrors((prev) => ({ ...prev, [key]: '', [GoalFormFieldNames.KraWeightage]: '' }));
    setFormInfo((prev) => {
      let adjustedValue;
      if (value === '' || value === null) {
        adjustedValue = '';
      } else {
        adjustedValue = value > 99 ? 99 : value < 1 ? 1 : value;
      }
      return {
        ...prev,
        [key]: adjustedValue,
        [GoalFormFieldNames.KraWeightage]: `${100 - adjustedValue}`,
      };
    });
  }, [formInfo[GoalFormFieldNames.CompetencyWeightage]]);


  const goalsFormConfig = goalsConfig.reduce((acc, item) => {
    let temp = item;
    switch (item.fieldRefName) {
      case GoalFormFieldNames.KraWeightage: {
        temp = { ...temp, onChange: handleChangeKraWeightage, minDate: 1, maxDate: 99 };
        break;
      }
      case GoalFormFieldNames.CompetencyWeightage: {
        temp = { ...temp, onChange: handleChangeCompetencyWeightage, minDate: 1, maxDate: 99 };
        break;
      }
    }
    acc.push(temp);
    return acc;
  }, []);

  return (
    <FormEdit
      list={goalsFormConfig}
      formInfo={formInfo}
      setFormData={setFormInfo}
      errors={validationErrors}
      setErrors={setValidationErrors}
      handleDropdownClick={handleDropdownClick}
      getDropdownOptions={getDropdownOptions}
    />
  );
};

export default React.memo(GoalForm);