import React, { useEffect, useState } from 'react';
import FormEdit from '../../../../../../../OnBoarding/common/Form/FormEdit';
import { useDebounce } from 'usehooks-ts';
import { Box } from '@mui/material';

const TargetItem = (props) => {
    const { formConfig, targetFormData, setTargetFormInfo, index } = props;
    const [formInfo, setFormInfo] = useState(targetFormData);
    const debouncedFormInfo = useDebounce(formInfo, 500);

    useEffect(() => {
        setFormInfo(targetFormData);
    }, [targetFormData]);

    useEffect(() => {
        setTargetFormInfo((prev) => {
            const newData = [...prev];
            newData[index] = {...formInfo};
            return newData;
        });
      }, [debouncedFormInfo]);

    return (
        <Box mt={2}>
        <FormEdit
            list={formConfig}
            formInfo={formInfo}
            setFormData={setFormInfo}
            errors={{}}
        />
        </Box>
    );
};

export default React.memo(TargetItem);