import React, { useEffect, useState } from 'react';
import FormEdit from '../../../OnBoarding/common/Form/FormEdit';
import {
  CurrencyConfigsFields,
  CurrencyExConfig,
  CurrencyExFields,
} from '../../utils/currency-config.utils';
import { useSelector } from 'react-redux';
import { FormFieldTypes } from '../../../../constants/common';

const CurrencyConfig = (props) => {
  const { mainFormInfo, mainSetFormInfo } = props;
  const [formInfo, setFormInfo] = useState(mainFormInfo?.[CurrencyExConfig]);
  const { list } = useSelector((state) => state.formBuilder) || {};

  const getDropdownOptions = (name) => {
    switch (name) {
      case CurrencyExFields.FilterDate: {
        return list
          .filter(
            (item) =>
              item.fieldType === FormFieldTypes.DateTime ||
              item.fieldType === FormFieldTypes.Date
          )
          .map((item) => {
            const { fieldRefName, fieldLabel } = item;
            return { id: fieldRefName, name: fieldLabel };
          });
      }
      case CurrencyExFields.Target: {
        return list
          .filter(
            (item) =>
              item.fieldType === FormFieldTypes.Text &&
              item.dataType === FormFieldTypes.Number
          )
          .map((item) => {
            const { fieldRefName, fieldLabel } = item;
            return { id: fieldRefName, name: fieldLabel };
          });
      }
    }
  };

  useEffect(()=>{
    mainSetFormInfo((prev)=>{
      return {
        ...prev,
        [CurrencyExConfig]:formInfo
      };
    });
  },[formInfo]);
  return (
    <>
      <FormEdit
        list={CurrencyConfigsFields}
        formInfo={formInfo}
        setFormData={setFormInfo}
        getDropdownOptions={getDropdownOptions}
        padding={0}
        modal={false}
      />
    </>
  );
};

export default CurrencyConfig;
