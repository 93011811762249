import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Outlet } from 'react-router';

const NewClaims = () => {
  const [viewMode, setViewMode] = useState(false);

  return (
    <Box m={4} pl={4}>
      <Outlet context={[viewMode, setViewMode]} />
    </Box>
  );
};

export default NewClaims;
