import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, Typography } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export default function AutocompleteCheckbox(props) {
  const { optionList, fieldLabel, placeholder, required, open, setOpen, handleDropDownClick, setIds } = props;
  const [values, setValues] = React.useState([]);

  return (
    <>
      {fieldLabel && (
        <Typography variant='body2' color={'neutral.dark80'} mb={1}>
          {fieldLabel}
          {required && (
            <Box color='accentSecondary.main' sx={{ display: 'inline-block' }}>
              *
            </Box>
          )}
        </Typography>
      )}
      <Autocomplete
        multiple
        id='checkboxes-tags-demo'
        options={optionList ? optionList : [{ name: 'empty' }]}
        disableCloseOnSelect
        getOptionLabel={(option) => option?.name}
        renderOption={(props, option, { selected }) => (
          optionList ?
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li> : ''
        )}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
        size='small'
        onOpen={handleDropDownClick}
        onClose={() => setOpen(false)}
        open={open}
        onChange={(event, newValue) => {
          setValues(newValue);
          setIds(newValue);
        }}
        value={values}
      />
    </>
  );
}
