export const GET_JOB_CONFIG_REQUEST = 'GET_JOB_CONFIG_REQUEST';
export const GET_JOB_CONFIG_SUCCESS = 'GET_JOB_CONFIG_SUCCESS';
export const GET_JOB_CONFIG_FAILURE = 'GET_JOB_CONFIG_FAILURE';

export const POST_JOB_INFO_REQUEST = 'POST_JOB_INFO_REQUEST';
export const POST_JOB_INFO_SUCCESS = 'POST_JOB_INFO_SUCCESS';
export const POST_JOB_INFO_FAILURE = 'POST_JOB_INFO_FAILURE';

export const GET_ALL_JOB_LIST_REQUEST = 'GET_ALL_JOB_LIST_REQUEST';
export const GET_ALL_JOB_LIST_SUCCESS = 'GET_ALL_JOB_LIST_SUCCESS';
export const GET_ALL_JOB_LIST_FAILURE = 'GET_ALL_JOB_LIST_FAILURE';

export const RESET_JOB = 'RESET_JOB';

export const POST_DRAFT_REQUEST = 'POST_DRAFT_REQUEST';
export const POST_DRAFT_SUCCESS = 'POST_DRAFT_SUCCESS';
export const POST_DRAFT_FAILURE = 'POST_DRAFT_FAILURE';