import React from 'react';
import { Box } from '@mui/material';
import StyledTypography from '../../../../../atoms/StyledTypography/StyledTypography';
import BasicForm from './BasicForm';
import CurrencyFormatter from '../../../../../atoms/CurrencyFormater/CurrencyFormater';

const TotalCalculationView = (props) => {
  const {
    basicComponents,
    totalDeductions,
    totalAdditions,
    setBasicComponents,
    viewMode = true,
    payrollEditDisabled,
    basicErrors,
    setPayrollError,
    setIsPayrollChange,
  } = props;

  const total =
    Number(basicComponents?.salaryTypeRateAmt) +
    Number(totalAdditions) -
    Number(totalDeductions);
  return (
    <Box display="flex" justifyContent="space-between" marginRight={5}>
      <Box>
        {viewMode ? (
          <BasicForm
            basicComponents={{
              ...basicComponents,
              salaryTypeRateAmt: (
                <CurrencyFormatter
                  value={Number(basicComponents?.salaryTypeRateAmt) || 0}
                />
              ),
            }}
            isReadOnly={true}
          />
        ) : (
          <BasicForm
            basicComponents={basicComponents}
            setBasicComponents={setBasicComponents}
            readOnly={false}
            payrollEditDisabled={payrollEditDisabled}
            basicErrors={basicErrors}
            setPayrollError={setPayrollError}
            setIsPayrollChange={setIsPayrollChange}
          />
        )}
      </Box>
      <StyledTypography>+</StyledTypography>
      <Box>
        <BasicForm
          basicComponents={{
            name: 'Total Additions',
            salaryTypeRateAmt: (
              <CurrencyFormatter value={Number(totalAdditions) || 0} />
            ),
          }}
          isReadOnly={true}
        />
      </Box>
      <StyledTypography>-</StyledTypography>
      <Box>
        <BasicForm
          basicComponents={{
            name: 'Total Deductions',
            salaryTypeRateAmt: (
              <CurrencyFormatter value={Number(totalDeductions) || 0} />
            ),
          }}
          isReadOnly={true}
        />
      </Box>
      <StyledTypography>=</StyledTypography>
      <Box>
        <BasicForm
          basicComponents={{
            name: 'Gross Pay',
            salaryTypeRateAmt: <CurrencyFormatter value={total || 0} />,
          }}
          isReadOnly={true}
        />
      </Box>
    </Box>
  );
};

export default TotalCalculationView;
