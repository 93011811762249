import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import { GET_INTERVIEW_CONFIG_FAILURE, GET_INTERVIEW_CONFIG_REQUEST, GET_INTERVIEW_CONFIG_SUCCESS, GET_INTERVIEW_FEEDBACK_CONFIG_FAILURE, GET_INTERVIEW_FEEDBACK_CONFIG_REQUEST, GET_INTERVIEW_FEEDBACK_CONFIG_SUCCESS, POST_INTERVIEW_INFO_FAILURE, POST_INTERVIEW_INFO_REQUEST, POST_INTERVIEW_INFO_SUCCESS } from './actionTypes';

const initialState = {
    requestProcessing: {
        [APIRequestStatus.GET_INTERVIEW_CONFIG]: LoadingStatus.Loading,
        [APIRequestStatus.POST_INTERVIEW_INFO]: LoadingStatus.Loading,
    },
    interviewConfig: [],
    interviewFormData: {}, 
    interviewFeedbackFormConfig: [],
    interviewSkills: [],
    isInterviewer: false,
    interviewCompetencies: [],
    interviewFeedbackFormData: {}
  };
  
export const interviewReducer = (state = initialState, action) => {
  switch (action.type) {
          
    case GET_INTERVIEW_CONFIG_REQUEST: {
      return {
        ...state, 
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_INTERVIEW_CONFIG]: LoadingStatus.Loading
      }
      };
    }

    case GET_INTERVIEW_CONFIG_SUCCESS: {
      const res = action.payload;
      const {formConfig, interviewInfo} = res;
      return {
        ...state,
        interviewConfig: formConfig,
        interviewFormData: interviewInfo,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_INTERVIEW_CONFIG]: LoadingStatus.Success
      }
      };
    }

    case GET_INTERVIEW_CONFIG_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_INTERVIEW_CONFIG]: LoadingStatus.Failure
      }
      };
    }

    case POST_INTERVIEW_INFO_REQUEST: {
      return {
        ...state, 
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_INTERVIEW_INFO]: LoadingStatus.Loading
      }
      };
    }

    case POST_INTERVIEW_INFO_SUCCESS: {
      const interviewFormInfo = action.payload;
      return {
        ...state,
        interviewFormData: interviewFormInfo,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_INTERVIEW_INFO]: LoadingStatus.Success
      }
      };
    }

    case POST_INTERVIEW_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_INTERVIEW_INFO]: LoadingStatus.Failure
      }
      };
    }

    case GET_INTERVIEW_FEEDBACK_CONFIG_REQUEST: {
      return {
        ...state, 
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_INTERVIEW_FEEDBACK_CONFIG]: LoadingStatus.Loading
      }
      };
    }

    case GET_INTERVIEW_FEEDBACK_CONFIG_SUCCESS: {
      const res = action.payload;
      const {formConfig, interviewInfo, skills, competencies, feedbackFormConfig, isInterviewer, overall} = res;
      return {
        ...state,
        interviewConfig: formConfig,
        interviewFormData: interviewInfo,
        isInterviewer: isInterviewer,
        interviewSkills: skills,
        interviewCompetencies: competencies,
        interviewFeedbackFormConfig: feedbackFormConfig,
        interviewFeedbackFormData: overall,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_INTERVIEW_FEEDBACK_CONFIG]: LoadingStatus.Success
      }
      };
    }

    case GET_INTERVIEW_FEEDBACK_CONFIG_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_INTERVIEW_FEEDBACK_CONFIG]: LoadingStatus.Failure
      }
      };
    }

    default: {
        return state;
    }
}
};

