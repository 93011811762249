import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import './Card.css';

const CardUI = (props) => {
  return (
    <React.Fragment>
      <Card
        sx={{ minWidth: 275 }}
        style={{
          padding: props.padding ? props.padding : '10px 10px 15px 10px',
          boxShadow: props.boxShadow ? props.boxShadow : '0 0 16px #ebebeb',
          borderRadius: props.borderRadius ? props.borderRadius : '10px',
          borderBottomStyle: props.borderBottomStyle
            ? props.borderBottomStyle
            : '',
          borderBottomColor: props.borderBottomColor
            ? props.borderBottomColor
            : '',
          background: props.background,
          margin: props.margin ? props.margin : '',
          width: props.width ? props.width : '',
          height: props.height ? props.height : '',
        }}
        onClick={props.onClick}
        className={props.className}
      >
        <CardContent>{props.children}</CardContent>
      </Card>
    </React.Fragment>
  );
};
export default CardUI;
