import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
} from '@mui/material';
import AccordionTabs from '../../../atoms/Accordian/Accordian';
import ActionHeader from '../../OnBoarding/Organization/ActionHeader/ActionHeader';
import GoalForm from './GoalsForm/GoalForm';
import { GoalFormHeaders, GoalHeader, GoalToastMessages, stringSubstitute } from './common/constants';
import { Save } from '../../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { discardData, postGoalsInfo } from '../../../redux/goals/action';
import { APIRequestStatus, LoadingStatus, ToastThemes, toastMessage } from '../../../constants/common';
import { isEmpty, replace } from 'lodash';
import { getMandatoryFields, getValidationErrors } from '../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import KRATab from './KRA/KRATab/KRATab';
import { CompetencyFormFieldNames, KRAFormFieldNames } from '../Utlis/goals.util';
import { showToast } from '../../../utils/common.util';
import { GoalFormFieldNames } from '../Utlis/goals.util';
import CompetencyForm from './Competency/CompetencyForm';
import { useNavigate } from 'react-router-dom';
import { ToastMessages } from '../../OnBoarding/Organization/constants/onboarding.constants';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';

export default function GoalsCreate(props) {
  const { setViewMode, goalsConfig, kraConfig, isNewGoal } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { goalsFormData, kraFormData, kraFormDataLocal, competencyConfig, competencyData,
    requestProcessing: { [APIRequestStatus.POST_GOALS_INFO]: postGoalsLoading }
  } = useSelector((state) => state.goals);
  const { currentUserRoleId } = useSelector((state) => state.main);

  const [validationErrors, setValidationErrors] = useState({});
  const [goalsFormInfo, setGoalsFormInfo] = useState(goalsFormData);
  const [competencyFormData, setCompetencyFormData] = useState(competencyData);
  const [kraValidationErrors, setKraValidationErrors] = useState(Array(kraConfig.length).fill({}));
  const [kraErrorTabIndex, setKraErrorTabIndex] = useState(-1);
  const [postLoading, setPostLoading] = useState(-1);

  const KRATabRef = useRef([]);

  const goalsFormRequiredFields = useMemo(() => {
    return getMandatoryFields(goalsConfig);
  }, [goalsConfig]);

  const kraFormRequiredFields = useMemo(() => {
    return getMandatoryFields(kraConfig[0]);
  }, [kraConfig]);

  const getKraErrors = () => {
    const kraErrors = [];
    kraFormDataLocal.forEach((formData) => {
      const errors = getValidationErrors(formData, kraFormRequiredFields);
      kraErrors.push(errors);
    });
    return kraErrors;
  };

  const handleSave = () => {
    const totalWeightage = kraFormDataLocal.reduce((accumulator, formInfo) => {
      return accumulator + Number(formInfo[KRAFormFieldNames.Weightage]);
    }, 0);

    const totalWeightageCompetency = competencyFormData.reduce((accumulator, formInfo) => {
      return accumulator + Number(formInfo[CompetencyFormFieldNames.Weightage]);
    }, 0);

    const goalsFormErrors = getValidationErrors(goalsFormInfo, goalsFormRequiredFields);
    const kraErrors = getKraErrors();
    const kraErrorIndex = kraErrors.findIndex((error) => !isEmpty(error));

    if (isEmpty(goalsFormErrors) && kraErrorIndex === -1 &&
      totalWeightage === 100 && totalWeightageCompetency === 100) {
      setPostLoading(LoadingStatus.Loading);
      dispatch(postGoalsInfo({ ...goalsFormInfo, [GoalFormFieldNames.RoleId]: currentUserRoleId }, kraFormDataLocal, competencyFormData)).then((res) => {
        res.id && navigate(`/performance/goals/${res.id}`, { replace: true });
        setViewMode(true);
        showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.success), ToastThemes.success);
        setPostLoading(LoadingStatus.Success);
      }).catch((err) => {
        showToast(err, ToastThemes.error);
        setPostLoading(LoadingStatus.Failure);
      });
    } else {
      if (totalWeightage !== 100) {
        showToast(<div>{GoalToastMessages.target.replace(stringSubstitute, totalWeightage)}<br />{GoalToastMessages.note}</div>, ToastThemes.error);
      }
      if (totalWeightageCompetency !== 100) {
        showToast(<div>{GoalToastMessages.target.replace(stringSubstitute, totalWeightageCompetency)}<br />{GoalToastMessages.competencyNote}</div>, ToastThemes.error);
      }
      setValidationErrors(goalsFormErrors);
      setKraValidationErrors(kraErrors);
      setKraErrorTabIndex(kraErrorIndex);
    }
  };

  const handleDiscard = () => {
    if (isNewGoal) {
      navigate('../');
    } else {
      dispatch(discardData());
      setViewMode(true);
    }
  };

  return (
    <Box>
      <CircularLoader show={postLoading === LoadingStatus.Loading} />
      <Box>
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            labelText='Goal'
            editButtonText={Save}
            showDiscard={true}
            onEditClick={handleSave}
            onClickDiscard={handleDiscard}
          />
        </Box>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item md={11}>
            <Box
              py={2}
              px={3}
              bgcolor={'neutral.light80'}
              borderRadius={'8px 8px 0 0'}
            >
              <Typography variant='body2Bold' color={'neutral.dark80'}>
                {`${GoalHeader} ${goalsFormData[GoalFormFieldNames.Name] ? goalsFormData[GoalFormFieldNames.Name] : ''}`}
              </Typography>
            </Box>
            <Paper sx={{ p: 3 }}>
              <GoalForm
                goalsConfig={goalsConfig}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
                formInfo={goalsFormInfo}
                setFormInfo={setGoalsFormInfo}
              />
              <Box mt={3} mr={7}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <>
                    <KRATab
                      KRATabRef={KRATabRef}
                      formConfig={kraConfig}
                      formData={kraFormData}
                      kraValidationErrors={kraValidationErrors}
                      setKraValidationErrors={setKraValidationErrors}
                      kraErrorIndex={kraErrorTabIndex}
                      setKraErrorTabIndex={setKraErrorTabIndex}
                    />
                  </>
                  <Grid item md={11}>
                    <AccordionTabs
                      title={GoalFormHeaders.Competency}
                      tabDetails={<CompetencyForm
                        formConfig={competencyConfig}
                        data={competencyFormData}
                        setData={setCompetencyFormData} />}
                    />
                  </Grid>
                  {/* <Grid item md={11}>
                    <AccordionTabs
                      title={GoalFormHeaders.Skills}
                      tabDetails={<SkillsForm />}
                    />
                  </Grid> */}
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}



