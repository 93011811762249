import { Grid } from '@mui/material';
import React, { useState } from 'react';
import ApiSelectTag from '../../../../atoms/Select/ApiSelectTag';
import { clone } from 'lodash';
import { CommonSevice } from '../../../../services/common';

const AllUserMultiselect = (props) => {
  const { formInfo, setFormInfo } = props;
  const [list, setList] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState({});

  const getDropdownOptions = () => {
    return list;
  };

  const handleDropdownClick = async () => {
    return new CommonSevice().getDropDown('staff').then((res) => {
      setList(res);
      return true;
    });
  };

  const onChangeBenefit = (event) => {
    const key = event.target.name;
    const selected = event.target.value;
    const newVal = selected[selected.length - 1];
    const list = getDropdownOptions();
    if (newVal?.id === 'all' && isAllSelected?.[key]) {
      setFormInfo((prev) => ({
        ...prev,
        [key]: [],
      }));
      setIsAllSelected((prev) => ({
        ...prev,
        [key]: false,
      }));
    } else if (newVal?.id === 'all' && !isAllSelected?.[key]) {
      const newList = clone(list || []);
      const index = newList.findIndex((item) => item.id === newVal.id);
      if (index !== -1) {
        newList.splice(index, 1);
      }
      setFormInfo((prev) => ({
        ...prev,
        [key]: newList,
      }));
      setIsAllSelected((prev) => ({
        ...prev,
        [key]: newList.length > 0 && list?.length === newList.length,
      }));
    } else {
      const currentVal = clone(formInfo?.[key] || []);
      const index = currentVal.findIndex((item) => item.id === newVal.id);
      if (index !== -1) {
        currentVal.splice(index, 1);
      } else {
        currentVal.push(newVal);
      }
      setFormInfo((prev) => ({
        ...prev,
        [key]: currentVal,
      }));
      setIsAllSelected((prev) => ({
        ...prev,
        [key]: currentVal?.length > 0 && list?.length === currentVal?.length,
      }));
    }
  };

  return (
    <>
      <Grid container columnSpacing={1} maxWidth={'100%'}>
        <Grid item md={12}>
          <ApiSelectTag
            labelText={'Users'}
            onOpen={handleDropdownClick}
            value={formInfo?.users}
            dropDownList={list}
            onChangeMultiSelect={onChangeBenefit}
            name="users"
            size="small"
            fullWidth
            isMulti={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AllUserMultiselect;
