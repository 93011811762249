export const GoalFormFieldNames = {
  Priority: 'fkGoalPriority',
  Action: 'fkGoalAction',
  Name: 'goalName',
  KraWeightage: 'weightageKra',
  CompetencyWeightage: 'weightageCompetency',
  CompetencyRatingSCale: 'fkCompetencyRatingScale',
  RoleId: 'fkRole',
  GoalStatus: 'fkGoalStatus'
};
export const GoalDropdownEndPoints = {
  Priority: 'cfg_hrm_goal_priorities',
  Action: 'cfg_approval_actions',
  RatingScale: 'cfg_point_ratings'
};

export const KRAFormFieldNames = {
  Category: 'fkKraCategory',
  Type: 'fkKraType',
  Measure: 'fkKraMeasure',
  Kpi: 'kpi',
  Target: 'targetValue',
  Weightage: 'kraWeightage',
  TargetPoint: 'targetPoints'
};

export const TargetPointsFormFieldNames = {
  Point: 'targetPoint'
};

export const KRADropdownEndPoints = {
  Category: 'cfg_hrm_kra_categories',
  Type: 'cfg_hrm_kra_types',
  Measure: 'cfg_hrm_kra_measures',
  Kpi: 'cfg_hrm_kra_kpis'
};

export const CompetencyFormFieldNames = {
  Weightage: 'goalCompetencyWeightage',
  Competency: 'fkCompetency',
  Rating: 'competencyRatingValue',
  Submission: 'competencySubmission',
  Remarks: 'remarks',
  AppraiserRemarks:'mgrRemarks'
};

export const KRAPerformanceFormFieldNames = {
  Achievement: 'kraAchievementScore',
  Rating: 'kraRatingScore',
  AppraiserRemarks:'mgrRemarks'
};

export const KRATargetFormFieldNames = {
  TargetVal: 'targetValue',
  TargetPoints: 'targetPoint'
};

export const getPerforamceTab = [
  {
    name: 'goals',
    label: 'Goals',
    title: 'Goals',
    hide: false,
  },
  {
    name: 'appraisal',
    label: 'Appraisal',
    title: 'Appraisal',
    hide: false,
  },
];

export const GoalStatus = {
  GoalAssigned: 2,
  Published: 1,
  Activate: 3,
  Deactivated:11,
};

export const TotalScoreFormFieldNames = {
  OverallRemarks: 'overallRemarks'
};

export const AppraisalStatus = {
  Activated:3,
  Submitted:4,
  ReSubmitted:5,
  Approved:6,
  Rejected:8,
  SendBack:9,
  Pending:10
};