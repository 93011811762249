import React, { useEffect } from 'react';
import AllAppraisalsApprover from './AllAppraisalsApprover';
import AllAppraisalsEmp from './AllAppraisalsEmp';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAppraisalList } from '../../../../redux/goals/action';
import { useSearchParams } from 'react-router-dom';
import { PerformanceChild } from '../../Goals/common/constants';


const AllAppraisalList = () => {
  const { appraisalList } = useSelector((state) => state.goals);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();
  const { performancePermission } = useSelector(
    (state) => state.modulePermissions
  );
  const moduleId = performancePermission?.[PerformanceChild]?.id;
  useEffect(() => {
    if (currentUserRoleId) {
      dispatch(getAllAppraisalList(currentUserRoleId, searchParam.get('q'),moduleId));
    }
  }, [currentUserRoleId]);

  return (
    <AllAppraisalsEmp appraisalList={appraisalList} /> 
  );
};

export default AllAppraisalList;