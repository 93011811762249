import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import ListView from '../../../../atoms/DataList/ListView';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DateRange from '../../../molecules/DateRange/DateRange';
import { appraisalEmpHeadCells } from '../../Utlis/Goals.helper';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { APIRequestStatus, LoadingStatus } from '../../../../constants/common';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import moment from 'moment';
import TryAgain from '../../../molecules/ErrorState/error.component';
import {
  AppraisalStatus,
  PerformanceChild,
} from '../../Goals/common/constants';
import ApproveRejectAppraisal from './approve-reject-modal-appraisal';
import { ViewAccess } from '../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';

export default function AllAppraisalsEmp(props) {
  const { appraisalList } = props;
  const navigate = useNavigate();
  const {
    requestProcessing: {
      [APIRequestStatus.GET_ALL_APPRAISAL_LIST]: appraisalListLoading,
    },
  } = useSelector((state) => state.goals);
  const [checkedItems, setCheckedItems] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { performancePermission } = useSelector(
    (state) => state.modulePermissions
  );
  const viewAccess = performancePermission?.[PerformanceChild]?.viewAccess;
  const moduleId = performancePermission?.[PerformanceChild]?.id;
  const { currentUserRoleId} = useSelector(
    (state) => state && state.main
  );
  useEffect(() => {
    setCheckedItems([]);
  }, [appraisalList]);

  const rowData = appraisalList
    ?.filter((item) => {
      const { createdUtc } = item;
      return startDate && endDate
        ? moment(createdUtc, 'YYYY-MM-DD').format() >=
            moment(startDate).format() &&
            moment(createdUtc, 'YYYY-MM-DD').format() <=
              moment(endDate).format()
        : item;
    })
    .map((items) => {
      const {
        id,
        goalName,
        statusId,
        status,
        createdOn,
        employeeName,
        assignedBy,
        totalScoreOverall,
        goalStartDate,
        goalEndDate,
        appraisalStartDate,
        appraisalEndDate,
      } = items;
      return {
        goals: goalName,
        emp: assignedBy,
        assignTo: employeeName,
        goalStartDate: goalStartDate
          ? moment(goalStartDate, 'YYYY-MM-DD').format('YYYY/MM/DD')
          : '',
        goalEndDate: goalEndDate
          ? moment(goalEndDate, 'YYYY-MM-DD').format('YYYY/MM/DD')
          : '',
        sDate: appraisalStartDate
          ? moment(appraisalStartDate, 'YYYY-MM-DD').format('YYYY/MM/DD')
          : '',
        eDate: appraisalEndDate
          ? moment(appraisalEndDate, 'YYYY-MM-DD').format('YYYY/MM/DD')
          : '',
        createdOn: moment(createdOn, 'YYYY-MM-DD').format('YYYY/MM/DD'),
        status: status,
        rating: totalScoreOverall,
        action: !statusId !== AppraisalStatus.Deactivated && (
          <IconButton onClick={() => navigate({ pathname: `${id}` })}>
            <InfoOutlinedIcon sx={{ color: 'primary.main80' }} />
          </IconButton>
        ),
        id: {
          hide: true,
          value: {id,statusId},
        },
      };
    });

    if (appraisalListLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      {/* <TabHead placeholder='Search Job' filterDataRoles={roles} /> */}
      <CircularLoader show={appraisalListLoading === LoadingStatus.Loading} />

      <Box display="flex" justifyContent="flex-end" gap={2} mb={2} pr={2}>
        <DateRange
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        {viewAccess === ViewAccess.APPROVER_APPROVE_ACCESS && (
          <ApproveRejectAppraisal
            checkedItems={checkedItems}
            viewAccess={viewAccess}
            moduleId={moduleId}
            currentUserRoleId={currentUserRoleId}
          />
        )}
      </Box>
      <ListView
        headCells={appraisalEmpHeadCells}
        rowsData={rowData}
        showCheckbox
        setCheckedItems={setCheckedItems}
        checkedItems={checkedItems}
        showSerialNo={true}
      />
    </>
  );
}
