export const TimeManagement = 'time-management';
export const TimeManagementLabel = 'Time Management';
export const AccessAllTime = 'access-all-time-management';
export const AllowImport = 'allow-csv-import';
export const EmptyDashboardMsg = 'No Records found for the selected period';
export const MyAttendence = 'my-attendence';

export const LogError = 'Check downloaded log file';

export const TmtStatus = {
    Approve: 'AP',
    Reject: 'RE'
};

export const TimeStatus = {
    Approved: 'Approved',
    Rejected: 'Rejected' 
};