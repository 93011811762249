import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, IconButton } from '@mui/material';
import PowerBIEmbed from '../reports-config';
import { PowerBiService } from '../../../services/powerBi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import SubHeader from '../SubHeader/SubHeader';
import DynamicBreadcrumb from '../../../atoms/Breadcrumbs/dynamicBreadcrumb';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import TryAgain from '../../molecules/ErrorState/error.component';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { DebounceTimer } from '../../../constants/common';
import { getFormattedValues, getSGTTimeZone } from '../../../utils/formatter';
import { DateRangePro } from '../../molecules/DateRange/DateRangePro';
import {
  InfoOutlined,
  SettingsBackupRestoreOutlined,
} from '@mui/icons-material';
import ListView from '../../../atoms/DataList/ListView';
import TabBar from '../../../atoms/Tabs/TabBar';
import { DateFormatters } from '../../../constants/dateConstants';

const ReportsDashboard = () => {
  const [showReport, setShowReport] = useState(false);
  const [reportData, setReportData] = useState({});
  const [reportList, setReportList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams?.get('q');
  const debouncedVal = useDebounce(searchQuery, DebounceTimer);

  const { appPermissions, currentUserRoleId } = useSelector(
    (state) => state && state.main
  );
  const { logoImage } = useSelector((state) => state && state.onBoarding);
  const defaultTimezone = logoImage?.defaultTimezone;

  const navigate = useNavigate();

  const displayReport = (reportId, datasetId) => {
    setShowReport(true);
    setReportData({ reportId, datasetId });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await new PowerBiService().getReportList(
          currentUserRoleId,
          searchQuery,
          selectedDate[0]
            ? getFormattedValues('YYYY-MM-DD', selectedDate[0])
            : null,
          selectedDate[1]
            ? getFormattedValues('YYYY-MM-DD', selectedDate[1])
            : null
        );
        setReportList(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching report list:', error);
        setLoading(false);
        setError(true);
      }
    };
    if (
      appPermissions &&
      appPermissions.reports &&
      appPermissions.reports.allowView
    ) {
      setLoading(true);
      fetchData();
    }
  }, [appPermissions, currentUserRoleId, debouncedVal, selectedDate]);

  useEffect(() => {
    if (
      appPermissions &&
      appPermissions.reports &&
      !appPermissions.reports.allowView
    ) {
      navigate('/home');
    }
  }, [appPermissions]);

  const rowData = reportList?.map((items) => {
    const {
      id,
      reportId,
      datasetId,
      name,
      description,
      updatedUtc,
      lastRefreshUtc,
      category
    } = items;
    return {
      name,
      category,
      desc: description,
      updatedUTC: updatedUtc
        ? `${getSGTTimeZone(
            updatedUtc,
            'YYYY-MM-DD',
            defaultTimezone
          )}\n${getSGTTimeZone(updatedUtc, 'HH:mm', defaultTimezone)} ${
            defaultTimezone === 'Asia/Singapore' ? 'SGT' : 'UTC'
          }`
        : '',
      action: (
        <IconButton onClick={() => displayReport(reportId, datasetId)}>
          <InfoOutlined sx={{ color: 'primary.main80' }} />
        </IconButton>
      ),
    };
  });

  const resetFilters = () => {
    setSelectedDate([null, null]);
  };

  const isDisabledReset = () => {
    return selectedDate?.[0] === null && selectedDate?.[1] === null;
  };

  const dataTabs = [
    {
      name: 'reports',
      label: 'Reports',
      title: 'Reports',
      hide: false,
      panel: (
        <Box mx={-3}>
          <Box sx={{ background: 'white' }}>
            <Box
              display="flex"
              justifyContent="flex-end"
              gap={2}
              p={2}
              mb={1}
              mt={-2}
            >
              <DateRangePro
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                mt={'9px'}
              />
              <Button
                startIcon={<SettingsBackupRestoreOutlined />}
                sx={{ textTransform: 'capitalize' }}
                disabled={isDisabledReset()}
                onClick={resetFilters}
              >
                Clear
              </Button>
            </Box>
            <ListView
              headCells={[
                {
                  id: 'name',
                  label: 'Report',
                },
                {
                  id: 'category',
                  label: 'Category',
                },
                {
                  id: 'desc',
                  label: 'Description ',
                },
                {
                  id: 'updatedUTC',
                  label: 'Last Modified On',
                },
                {
                  id: 'action',
                  label: 'View Report',
                },
              ]}
              rowsData={rowData || []}
              showSerialNo={true}
              showCheckbox={false}
              checkedItems={[]}
              allowSelectAll={false}
            />
          </Box>
        </Box>
      ),
    },
  ];

  const handleChange = (tabIndex) => {
    navigate(`/${dataTabs[tabIndex].name}`);
  };
  const onSearchChange = (e, tabIndex) => {
    navigate(`/${tabIndex.name}`);
  };

  if (error) {
    return <TryAgain />;
  }

  if (showReport) {
    return (
      <PowerBIEmbed setShowReport={setShowReport} reportData={reportData} />
    );
  }
  return (
    <Box m={4} pl={4}>
      <CircularLoader show={loading} />
      <Box>
        <SubHeader />
        <Box my={3}>
          <DynamicBreadcrumb />
          <Box mt={1}>
            <Divider />
          </Box>
        </Box>
      </Box>
      <TabBar
        tabPanels={dataTabs}
        searchTabs
        disableCreate
        currentTab={0}
        onTabChange={handleChange}
        onSearchChange={onSearchChange}
      />
    </Box>
  );
};

export default ReportsDashboard;
