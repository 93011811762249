import axios from 'axios';
import { redirectToLogin } from './functions';

const hostname = window.location.hostname;
const tenant = hostname.split('.')[0] === 'localhost'? process.env.REACT_APP_LOCALHOST_CLIENT_ID : hostname.split('.')[0];

// Gets client id from localStorage
const getClientID = () => {
  let userData = JSON.parse(localStorage.getItem('userData'));
  return userData.userDetails.client.id;
};

// Gets auth Token from localStorage
export const getAuthToken = () => {
  let token = JSON.parse(localStorage.getItem('token'));
  return token;
};

// Gets client id from localStorage
const getUserID = () => {
  let userData = JSON.parse(localStorage.getItem('userData'));
  return userData.userDetails.id;
};

// Below function handles 'POST' call for multiple file upload in forms attachment and returns file paths.
export const postFilesData = async (formData) => {
  let paths = [];
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_API_LOCAL}/files/upload`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getAuthToken()}`,
      'client_id': tenant
    },
  })
    .then((res) => {
      console.log('res',res);
      paths = res.data?.data?.map(file => file.url);
      return paths;
    })
    .catch((err) => {
      if (err) {
        const message = err?.response?.data?.message;
        if(String(message).toLowerCase().trim() === String('file too large').toLowerCase().trim()) {
          alert('File size should not exceed 5MB');
        }else{
          alert(message ? message : 'File size limit exceeds !');
        }
        // alert('File size limit exceeds !');
        return;
      }
      redirectToLogin(err);
    });

  if (paths) {
    return paths;
  }
};

// Below function handles 'POST' call for form data and returns status code.
export const postFormData = async (formData) => {
  if (formData) {
    for (let i = 0; i < formData.length; i++) {
      formData[i]['clientID'] = await getClientID();
    }
  }

  let responseStatusCode = null;

  try {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_API_LOCAL}/business-travels/new-claims`,
      data: formData,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
        'client_id': tenant
      },
    }).then((res) => {
      responseStatusCode = res.data.statusCode;
    }).catch(err => {
      redirectToLogin(err);
      return (responseStatusCode = 'Same travel dates AND departure timings OR receipt numbers are not allowed !');
    });
    if (responseStatusCode) {
      return responseStatusCode;
    }
  } catch (error) {
    return (responseStatusCode = error.response.data.message);
  }
};
export const postCreateFormData = async (formData) => {

  let responseStatusCode = null;

  console.log(formData);

  try {
    const resCode = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_API_LOCAL}/claims/main/create-claims`,
      data: formData,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
        'client_id': tenant
      },
    }).then((res) => {
      console.log('res',res);
      responseStatusCode = res?.data.status;
      return responseStatusCode;
    }).catch(err => {
      // redirectToLogin(err);
      console.log(err);
      return (responseStatusCode = 'Same travel dates AND departure timings OR receipt numbers are not allowed !');
    });
    if (resCode) {
      console.log('res', resCode);
      return resCode;
    }
  } catch (error) {
    console.log(error);
    return (responseStatusCode = error.response?.data?.message);
  }
};
export const postEditFormData = async (formData) => {

  let responseStatusCode = null;

  console.log(formData);

  try {
    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_API_LOCAL}/claims/main/edit_claimp`,
      data: formData,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
        'client_id': tenant
      },
    }).then((res) => {
      console.log('res',res);
      responseStatusCode = res?.data.status;
    }).catch(err => {
      // redirectToLogin(err);
      console.log(err);
      return (responseStatusCode = 'Same travel dates AND departure timings OR receipt numbers are not allowed !');
    });
  } catch (error) {
    console.log(error);
    return (responseStatusCode = error.response?.data?.message);
  }
};

// Below function handles 'DELETE' call for drafted claims.
export const deleteDraftCall = async (currentUserData) => {
  let id = '';
  let responseStatusCode = '';

  if (currentUserData && currentUserData.singleData) {
    id = currentUserData.singleData.id;
  }

  await axios
    .delete(
      `${
        process.env.REACT_APP_BASE_API_LOCAL
      }/business-travels/${id}?clientID=${await getClientID()}`,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
          'client_id': tenant
        },
      }
    )
    .then((res) => {
      responseStatusCode = res.data.statusCode;
    }).catch(err => {
      redirectToLogin(err);
    });

  if (responseStatusCode) {
    return responseStatusCode;
  }
};

// Below function handles 'POST' call to approve submitted claims by Employee.
export const approveClaimRequestCall = async (
  currentUserData,
  currentUserType,
  payDate
) => {
  let responseStatusCode = '';

  await axios
    .post(
      `${process.env.REACT_APP_BASE_API_LOCAL}/manager-claims`,
      [
        {
          remark: 'Test',
          status: 'APPROVED',
          claimID: currentUserData && currentUserData.singleData.id,
          user: await getUserID().toString(),
          paymentDate: new Date(payDate),
          reasons: [],
          clientID: await getClientID(),
        },
      ],
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getAuthToken()}`,
          'client_id': tenant
        },
      }
    )
    .then((res) => {
      responseStatusCode = res.data.message;
    }).catch(err => {
      redirectToLogin(err);
    });

  if (responseStatusCode) {
    return responseStatusCode;
  }
};

// Below function handles 'POST' call to reject submitted claims by Employee.
export const rejectClaimRequestCall = async (
  userData,
  remark,
  currentUserData,
  payDate,
  selectedReason,
  status
) => {
  let responseStatusCode = '';

  let updatedPaymentDate = new Date(payDate);

  await axios
    .post(
      `${process.env.REACT_APP_BASE_API_LOCAL}/manager-claims`,
      [
        {
          remark: remark,
          status: status,
          claimID: currentUserData && currentUserData.singleData.id,
          user: await getUserID().toString(),
          paymentDate: updatedPaymentDate,
          reasons: selectedReason,
          clientID: await getClientID(),
        },
      ],
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getAuthToken()}`,
          'client_id': tenant
        },
      }
    )
    .then((res) => {
      responseStatusCode = res.data.message;
    }).catch(err => {
      redirectToLogin(err);
    });

  if (responseStatusCode) {
    return responseStatusCode;
  }
};

// Below function handles 'GET' call to get selected claims data.
export const getSelectedClaimsDataCall = async (claimsId) => {
  let response;
  await axios
    .get(
      `${
        process.env.REACT_APP_BASE_API_LOCAL
      }/manager-claims/${claimsId}?clientID=${await getClientID()}`,
      {
        headers: { Authorization: `Bearer ${getAuthToken()}`,'client_id': tenant },
      }
    )
    .then((res) => (response = res.data)).catch(err => {
      redirectToLogin(err);
    });

  if (response) {
    return response;
  }
};

// Below function handles 'PUT' call for form data and returns status code.
export const putFormData = async (formData) => {

  // console.log(formData,'formData');
  let responseStatusCode = null;

  formData['clientID'] = await getClientID();
  try {
    await axios({
      method: 'put',
      url: `${process.env.REACT_APP_BASE_API_LOCAL}/business-travels`,
      data: formData,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
        'client_id': tenant
      },
    }).then((res) => {
      responseStatusCode = res.data.statusCode;
    }).catch(err => {
      // console.log(err,'errccrrr');
      redirectToLogin(err);
      return (responseStatusCode = err.response.data.message);
    });
    if (responseStatusCode) {
      return responseStatusCode;
    }
  } catch (error) {
    // console.log(error,'errrrr');

    return (responseStatusCode = error.response.data.message);
  }
};

// Below function handles 'POST' call to reject submitted claims by Employee.
export const rejectClaimMultipleRequestCall = async (data) => {
  let responseStatusCode = '';

  if (data) {
    for (let i = 0; i < data.length; i++) {
      data[i]['clientID'] = await getClientID();
    }
  }

  await axios
    .post(`${process.env.REACT_APP_BASE_API_LOCAL}/manager-claims`, data, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
        'client_id': tenant
      },
    })
    .then((res) => {
      responseStatusCode = res.data.message;
    }).catch(err => {
      redirectToLogin(err);
    });

  if (responseStatusCode) {
    return responseStatusCode;
  }
};

// Below function handles 'POST' call to reject submitted claims by Employee.
export const approveClaimMultipleRequestCall = async (data) => {
  let responseStatusCode = '';

  if (data) {
    for (let i = 0; i < data.length; i++) {
      data[i]['clientID'] = await getClientID();
    }
  }

  await axios
    .post(`${process.env.REACT_APP_BASE_API_LOCAL}/manager-claims`, data, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
        'client_id': tenant
      },
    })
    .then((res) => {
      responseStatusCode = res.data.message;
    }).catch(err => {
      redirectToLogin(err);
    });

  if (responseStatusCode) {
    return responseStatusCode;
  }
};

// Below function handles 'GET' call to get selected claims data.
export const getFlexiBenefitTypes = async () => {
  let response;
  await axios
    .get(
      `${
        process.env.REACT_APP_BASE_API_LOCAL
      }/business-travels/getFlexiBenefitTypes?clientID=${await getClientID()}`,
      {
        headers: { Authorization: `Bearer ${getAuthToken()}`,'client_id': tenant },
      }
    )
    .then((res) => (response = res.data)).catch(err => {
      redirectToLogin(err);
    });

  if (response) {
    return response;
  }
};

// Below function handles 'GET' call to get selected claims data.
export const getRemainingClaimAmount = async (userId , clientID) => {
  let response;
  const UserId = userId ? userId : await getUserID();
  const ClientID = clientID ? clientID : await getClientID();
  await axios
    .get(
      `${
        process.env.REACT_APP_BASE_API_LOCAL
      }/business-travels/getRemainingClaimAmount?empID=${UserId}&clientID=${ClientID}`,
      {
        headers: { Authorization: `Bearer ${getAuthToken()}`,'client_id': tenant },
      }
    )
    .then((res) => (response = res.data)).catch(err => {
      redirectToLogin(err);
    });

  if (response) {
    return response;
  }
};

// Below function handles 'GET' call to multi level config data.
export const getMultiLevelConfigData = async (typeID) => {
  let response;
  await axios
    .get(
      `${
        process.env.REACT_APP_BASE_API_LOCAL
      }/multi-level-config?typeID=${typeID}&clientID=${await getClientID()}`,
      {
        headers: { Authorization: `Bearer ${getAuthToken()}`,'client_id': tenant },
      }
    )
    .then((res) => (response = res.data)).catch(err => {
      redirectToLogin(err);
    });
  if (response) {
    return response[0];
  }
};

// Below function handles 'GET' call to get selected claims data.
export const getAuditTrailDataAPICall = async (claimID) => {
  let response;
  await axios
    .get(
      `${
        process.env.REACT_APP_BASE_API_LOCAL
      }/business-travels/getAuditTrail?clientID=${await getClientID()}&claimID=${claimID}`,
      {
        headers: { Authorization: `Bearer ${getAuthToken()}`,'client_id': tenant },
      }
    )
    .then((res) => (response = res.data)).catch(err => {
      redirectToLogin(err);
    });
  if (response) {
    return response;
  }
};


// Below function handles 'GET' call to public holidays of current year

export const getPublicHolidays =  async ()=>{
  let response;
  await axios
    .get(
      `${
        process.env.REACT_APP_BASE_API_LOCAL
      }/business-travels/getHolidayList`,
      {
        headers: { Authorization: `Bearer ${getAuthToken()}`,'client_id': tenant },
      }
    )
    .then((res) => (response = res.data)).catch(err => {
      redirectToLogin(err);
    });
  if (response) {
    return response;
  }
};
export const getEligibility =  async ()=>{
  let response;
  await axios
    .get(
      `${
        process.env.REACT_APP_BASE_API_LOCAL
      }/manager-claims/is-eligibal-for-overtime`,
      {
        headers: { Authorization: `Bearer ${getAuthToken()}`,'client_id': tenant },
      }
    )
    .then((res) => (response = res.data)).catch(err => {
      redirectToLogin(err);
    });
  if (response) {
    return response;
  }
};