export const GET_COMPANY_INFO_REQUEST = 'GET_COMPANY_INFO_REQUEST';
export const GET_COMPANY_INFO_SUCCESS = 'GET_COMPANY_INFO_SUCCESS';
export const GET_COMPANY_INFO_FAILURE = 'GET_COMPANY_INFO_FAILURE';

export const GET_DEPARTMENT_INFO_REQUEST = 'GET_DEPARTMENT_INFO_REQUEST';
export const GET_DEPARTMENT_INFO_SUCCESS = 'GET_DEPARTMENT_SUCCESS';
export const GET_DEPARTMENT_INFO_FAILURE = 'GET_DEPARTMENT_FAILURE';

export const GET_SUB_DEPARTMENT_INFO_REQUEST = 'GET_SUB_DEPARTMENT_INFO_REQUEST';
export const GET_SUB_DEPARTMENT_INFO_SUCCESS = 'GET_SUB_DEPARTMENT_SUCCESS';
export const GET_SUB_DEPARTMENT_INFO_FAILURE = 'GET_SUB_DEPARTMENT_FAILURE';

export const POST_DEPARTMENT_SAVE_REQUEST = 'POST_DEPARTMENT_SAVE_REQUEST';
export const POST_DEPARTMENT_SAVE_SUCCESS = 'POST_DEPARTMENT_SAVE_SUCCESS';
export const POST_DEPARTMENT_SAVE_FAILURE = 'POST_DEPARTMENT_SAVE_FAILURE';

export const POST_COMPANY_INFO_REQUEST = 'POST_COMPANY_INFO_REQUEST';
export const POST_COMPANY_INFO_SUCCESS = 'POST_COMPANY_SUCCESS';
export const POST_COMPANY_INFO_FAILURE = 'POST_COMPANY_FAILURE';

export const UPDATE_COMPANY_INFO_REQUEST = 'UPDATE_COMPANY_INFO_REQUEST';
export const UPDATE_COMPANY_INFO_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_INFO_FAILURE = 'UPDATE_COMPANY_FAILURE';

export const GET_LOCATION_INFO_REQUEST = 'GET_LOCATION_INFO_REQUEST';
export const GET_LOCATION_INFO_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_INFO_FAILURE = 'GET_LOCATION_FAILURE';

export const POST_LOCATION_SAVE_REQUEST = 'POST_LOCATION_SAVE_REQUEST';
export const POST_LOCATION_SAVE_SUCCESS = 'POST_LOCATION_SAVE_SUCCESS';
export const POST_LOCATION_SAVE_FAILURE = 'POST_LOCATION_SAVE_FAILURE';

export const FETCH_EMPLOYEE_INFO_REQUEST = 'FETCH_EMPLOYEE_INFO_REQUEST';
export const FETCH_EMPLOYEE_INFO_SUCCESS = 'FETCH_EMPLOYEE_INFO_SUCCESS';
export const FETCH_EMPLOYEE_INFO_FAILURE = 'FETCH_EMPLOYEE_INFO_FAILURE';

export const UPDATE_DEPARTMENT_INFO_REQUEST = 'UPDATE_DEPARTMENT_INFO_REQUEST';
export const UPDATE_DEPARTMENT_INFO_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS';
export const UPDATE_DEPARTMENT_INFO_FAILURE = 'UPDATE_DEPARTMENT_FAILURE';

export const GET_ROLES_LIST_REQUEST = 'GET_ROLES_LIST_REQUEST';
export const GET_ROLES_LIST_SUCCESS = 'GET_ROLES_LIST_SUCCESS';
export const GET_ROLES_LIST_FAILURE = 'GET_ROLES_LIST_FAILURE';

export const FETCH_COMPANY_ONBOARDING_PERMISSIONS_REQUEST = 'FETCH_COMPANY_ONBOARDING_PERMISSIONS_REQUEST';
export const FETCH_COMPANY_ONBOARDING_PERMISSIONS_SUCCESS = 'FETCH_COMPANY_ONBOARDING_PERMISSIONS_SUCCESS';
export const FETCH_COMPANY_ONBOARDING_PERMISSIONS_FAILURE = 'FETCH_COMPANY_ONBOARDING_PERMISSIONS_FAILURE';

export const PUBLISH_COMPANY_INFO_REQUEST = 'PUBLISH_COMPANY_INFO_REQUEST';
export const PUBLISH_COMPANY_INFO_SUCCESS = 'PUBLISH_COMPANY_INFO_SUCCESS';
export const PUBLISH_COMPANY_INFO_FAILURE = 'PUBLISH_COMPANY_INFO_FAILURE';

export const RESET_ONBOARDING_STATE = 'RESET_ONBOARDING_STATE';

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';

export const UPDATE_LOCATION_REQUEST = 'UPDATE_LOCATION_REQUEST';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE';

export const DELETE_DEPARTMENT_REQUEST = 'DELETE_DEPARTMENT_REQUEST';
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_FAILURE = 'DELETE_DEPARTMENT_FAILURE';

export const DELETE_LOCATION_REQUEST = 'DELETE_LOCATION_REQUEST';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAILURE = 'DELETE_LOCATION_FAILURE';

export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE';

export const GET_DESIGNATION_REQUEST = 'GET_DESIGNATION_REQUEST';
export const GET_DESIGNATION_SUCCESS = 'GET_DESIGNATION_SUCCESS';
export const GET_DESIGNATION_FAILURE = 'GET_DESIGNATION_FAILURE';

export const POST_DESIGNATION_REQUEST = 'POST_DESIGNATION_REQUEST';
export const POST_DESIGNATION_SUCCESS = 'POST_DESIGNATION_SUCCESS';
export const POST_DESIGNATION_FAILURE = 'POST_DESIGNATION_FAILURE';

export const UPDATE_DESIGNATION_REQUEST = 'UPDATE_DESIGNATION_REQUEST';
export const UPDATE_DESIGNATION_SUCCESS = 'UPDATE_DESIGNATION_SUCCESS';
export const UPDATE_DESIGNATION_FAILURE = 'UPDATE_DESIGNATION_FAILURE';

export const DELETE_DESIGNATION_REQUEST = 'DELETE_DESIGNATION_REQUEST';
export const DELETE_DESIGNATION_SUCCESS = 'DELETE_DESIGNATION_SUCCESS';
export const DELETE_DESIGNATION_FAILURE = 'DELETE_DESIGNATION_FAILURE';

export const FETCH_LOGO_IMAGE_REQUEST = 'FETCH_LOGO_IMAGE_REQUEST';
export const FETCH_LOGO_IMAGE_SUCCESS = 'FETCH_LOGO_IMAGE_SUCCESS';
export const FETCH_LOGO_IMAGE_FAILURE = 'FETCH_LOGO_IMAGE_FAILURE';
