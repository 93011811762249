export const claimsHeadCell = (viewAccess) => {
  return [
    {
      id: 'referenceNumber',
      label: 'REFERENCE NUMBER',
    },
    {
      id: 'name',
      label: 'CLAIMANT',
      hide: !viewAccess.my_access,
    },
    {
      id: 'dept',
      label: 'DEPARTMENT',
    },
    {
      id: 'type',
      label: 'CLAIM TYPES',
    },
    {
      id: 'createdAt',
      label: 'SUBMISSION DATE',
      dataType: 'date',
      dateFormat: 'YYYY-MM-DD'
    },
    {
      id: 'amount',
      label: 'AMOUNT',
      numeric: true,
      dataType: 'number'
    },
    {
      id: 'payDate',
      label: viewAccess.paymode ? 'PAYMENT MONTH' : 'PAYMENT DATE',
      hide: viewAccess?.my_access && viewAccess?.approver_access,
      dataType: 'date',
      dateFormat: viewAccess.paymode ? 'MMM YYYY' : 'DD/MM/YYYY'
    },
    {
      id: 'status',
      label: 'STATUS',
    },
  ];
};
