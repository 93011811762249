import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDidMountEffect from '../../../../../../customHooks/useDidMountEffect';
import {
  getMandatoryFields,
  getValidationErrors,
} from '../../../utils/userOnboarding/userOnboarding.util';
import { isEmpty } from 'lodash';
import { Box } from '@mui/material';
import ActionHeader from '../../../ActionHeader/ActionHeader';
import FormEdit from '../../../../common/Form/FormEdit';
import { currencyConversionFieldsNames } from '../../utils/currencyConversion.utils';
import { CurrencyConversionService } from '../../../../../../services/currencyConversionService';
import {
  getCurrencyConversionDetails,
  postCurrencyConversionInfo,
  updateCurrencyConversionInfo,
} from '../../../../../../redux/currency-conversion/action';
import { showToast } from '../../../../../../utils/common.util';
import {
  stringSubstitute,
  ToastMessages,
} from '../../../constants/onboarding.constants';
import { toastMessage, ToastThemes } from '../../../../../../constants/common';

const CurrencyFormEdit = (props) => {
  const {
    setViewMode,
    formConfig,
    isNew,
    formData,
    setShowForm,
    currencyId,
    setCurrencyId,
  } = props;
  const dispatch = useDispatch();
  const { currentUserRoleId } = useSelector((state) => state.main);

  const [validationErrors, setValidationErrors] = useState({});
  const [formInfo, setFormInfo] = useState({});

  const [disabledSave, setDisabledSave] = useState(true);

  const [currencyDropDown, setCurrencyDropDown] = useState([]);

  useEffect(() => {
    setFormInfo(formData);
  }, [formData]);

  useDidMountEffect(() => {
    setDisabledSave(false);
  }, [formInfo]);

  const handleDiscard = () => {
    if (isNew) {
      setShowForm(false);
    } else {
      setViewMode(true);
    }
  };

  const handleDropdownClick = (name) => {
    switch (name) {
      case currencyConversionFieldsNames.Currency: {
        return new CurrencyConversionService()
          .getCurrencyList(currentUserRoleId)
          .then((res) => {
            setCurrencyDropDown(res);
            return true;
          });
      }
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case currencyConversionFieldsNames.Currency: {
        return currencyDropDown.map((item) => {
          return { ...item, name: `${item.codeName}-${item.name}` };
        });
      }
    }
  };

  const requiredFields = useMemo(() => {
    return getMandatoryFields(formConfig);
  }, [formConfig]);

  const handleSave = () => {
    const errors = getValidationErrors(formInfo, requiredFields);
    if (isEmpty(errors)) {
      if (currencyId) {
        dispatch(
          updateCurrencyConversionInfo(currentUserRoleId, formInfo, currencyId)
        )
          .then(() => {
            dispatch(
              getCurrencyConversionDetails(currentUserRoleId, currencyId)
            );
            showToast(
              ToastMessages.success.replace(
                stringSubstitute,
                toastMessage.success
              ),
              ToastThemes.success
            );
            setViewMode(true);
          })
          .catch((err) => {
            showToast(err, ToastThemes.error);
          });
      } else {
        dispatch(postCurrencyConversionInfo(currentUserRoleId, formInfo))
          .then((res) => {
            setCurrencyId(res);
            showToast(
              ToastMessages.success.replace(
                stringSubstitute,
                toastMessage.success
              ),
              ToastThemes.success
            );
            setViewMode(true);
          })
          .catch((err) => {
            showToast(err, ToastThemes.error);
          });
      }
    } else {
      setValidationErrors(errors);
    }
  };

  return (
    <Box>
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={formData?.currency?.name|| 'Currency Conversion'}
          showDiscard
          showPublish
          showPublishModal={false}
          publishButtonText="Save"
          onPublishClick={handleSave}
          onClickDiscard={handleDiscard}
          showSave={false}
          disabledPublish={disabledSave}
        />
      </Box>
      <FormEdit
        list={formConfig}
        formInfo={formInfo}
        setFormData={setFormInfo}
        errors={validationErrors}
        setErrors={setValidationErrors}
        handleDropdownClick={handleDropdownClick}
        getDropdownOptions={getDropdownOptions}
      />
    </Box>
  );
};

export default CurrencyFormEdit;
