import React, { useState } from 'react';
import ModalDialog from '../../../../../atoms/Modal/ModalDialog';
import DropDownActionForJob from './DropDownActionForJob';
import { JobServices } from '../../../../../services/jobRecuriting';
import { getAllJobList } from '../../../../../redux/jobRecruiting/action';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../../OnBoarding/Organization/constants/onboarding.constants';
import {
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../../../../constants/common';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import { useSearchParams } from 'react-router-dom';
import { getFormattedValues } from '../../../../../utils/formatter';
import { JobStatusCode } from '../../../Constant/constant';

const JobModal = (props) => {
  const { checkedItems, isApproval, selectedDate, jobNameParams, searchQuery, appModuleId, moduleId } =
    props;
  const [showModal, setshowModal] = useState(false);
  const [context, setContext] = useState('');
  const [publishLoading, setPublishLoading] = useState(-1);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const handlePublish = () => {
    setContext('publish');
    setshowModal(true);
  };

  const handleCloseJob = () => {
    setContext('Close');
    setshowModal(true);
  };

  const handleReopenJob = () => {
    setContext('Reopen');
    setshowModal(true);
  };

  const handleApprove = () => {
    // setContext('approve');
    // setshowModal(true);
  };

  const handleReject = () => {
    // setContext('reject');
    // setshowModal(true);
  };

  const recallJobList = () => {
    dispatch(getAllJobList(currentUserRoleId, 
      searchQuery,
        getFormattedValues('YYYY-MM-DD', selectedDate[0]),
        getFormattedValues('YYYY-MM-DD', selectedDate[1]),
        jobNameParams,
        appModuleId,
        moduleId
      ));
    showToast(
      ToastMessages.success.replace(stringSubstitute, toastMessage.update),
      ToastThemes.success
    );
    setPublishLoading(LoadingStatus.Success);    
  };

  const handlePublishClick = () => {
    setPublishLoading(LoadingStatus.Loading);
    new JobServices()
      .publishJobForApproval({ jobIds: checkedItems.map(Number),currentUserRoleId })
      .then(() => {
        recallJobList();
      })
      .catch(() => {
        setPublishLoading(LoadingStatus.Failure);
      });
  };
  const handleDeactivateClick = () => {
    setPublishLoading(LoadingStatus.Loading);
    new JobServices()
      .changeJobStatus({ jobsIds: checkedItems.map(Number), action:JobStatusCode.Closed,currentUserRoleId })
      .then(() => {
        recallJobList();
      })
      .catch(() => {
        setPublishLoading(LoadingStatus.Failure);
      });
  };

  const handleReactivateClick = () => {
    setPublishLoading(LoadingStatus.Loading);
    new JobServices()
      .changeJobStatus({ jobsIds: checkedItems.map(Number), action:JobStatusCode.Reactivated,currentUserRoleId })
      .then(() => {
        recallJobList();
      })
      .catch(() => {
        setPublishLoading(LoadingStatus.Failure);
      });
  };

  const modalMap = {
    publish: {
      title: 'Publish',
      description: (
        <div>
          This will send the selected job for approval.
          <br />
          <br /> Contiune?
        </div>
      ),
      primaryAction: 'Publish',
      onClickConfirm: () => handlePublishClick(),
      severity: 'success',
      // maxWidth: 'md',
    },
    Close: {
      title: 'Close',
      description: (
        <div>
          This will Close The Selected Jobs.
          <br />
          <br /> Contiune?
        </div>
      ),
      primaryAction: 'Save',
      onClickConfirm: () => handleDeactivateClick(),
      severity: 'success',
      // maxWidth: 'md',
    },
    Reopen: {
      title: 'Reopen',
      description: (
        <div>
          This will Reopen(set status to previously available status) The Selected Jobs.
          <br />
          <br /> Contiune?
        </div>
      ),
      primaryAction: 'Save',
      onClickConfirm: () => handleReactivateClick(),
      severity: 'success',
      // maxWidth: 'md',
    },
    approve: {
      title: 'Approve',
      description: 'Are you sure you want to Approve Appraisal?',
      primaryAction: 'Approve',
      // onClickConfirm: () => handleApproveClick ,
      severity: 'success',
      maxWidth: 'md',
      fullWidth: true,
      primaryButtonDisabled: true, // Change to false for enable the button to perform the dispatch api action
    },
    reject: {
      title: 'Reject',
      description: 'Are you sure you want to Reject/Send-Back Appraisal?',
      primaryAction: 'Send Back',
      //   onClickConfirm: () => handleRejectClick ,
      severity: 'error',
      maxWidth: 'md',
      fullWidth: true,
      primaryButtonDisabled: true,
    },
  };
  return (
    <>
      <CircularLoader show={publishLoading === LoadingStatus.Loading} />
      <DropDownActionForJob
        handlePublish={handlePublish}
        checkedItems={checkedItems}
        handleApprove={handleApprove}
        handleReject={handleReject}
        isApproval={isApproval}
        handleCloseJob = {handleCloseJob}
        handleReopenJob = {handleReopenJob}
      />
      <ModalDialog
        severity={modalMap[context]?.severity}
        title={modalMap[context]?.title}
        listComponent={modalMap[context]?.listComponent}
        description={modalMap[context]?.description}
        primaryAction={modalMap[context]?.primaryAction}
        secondaryAction="Cancel"
        showModal={showModal}
        onClickClose={() => setshowModal(false)}
        onClickConfirm={modalMap[context]?.onClickConfirm}
        maxWidth={modalMap[context]?.maxWidth}
        fullWidth={modalMap[context]?.fullWidth}
        primaryButtonDisabled={modalMap[context]?.primaryButtonDisabled}
      />
    </>
  );
};

export default JobModal;
