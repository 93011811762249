import { AppLevelPermissions, addUser, companyInfo, myProfile, onboarding, userId, userOnboarding } from '../../components/OnBoarding/Organization/constants/onboarding.constants';

export const navOptionsList = [
    {
        label: 'Home',
        show: true,
        name: 'home',
        to: '/home'
    },
    {
        label: 'My Profile',
        show: true,
        name: myProfile,
        to: `/${userOnboarding}/${userId}`
    },
    {
        label: 'Claims',
        show: false,
        name: 'claims',
        to: '/claims'
    },
    {
        label: 'Claims V3.2',
        show: false,
        name: 'claims-3.2',
        to: '/new-claims'
    },
    {
        label: 'Onboarding',
        show: false,
        name: 'onboarding',
        to: `/${onboarding}/${companyInfo}`
    },
    {
        label: 'All Users',
        show: false,
        name: 'user-profile',
        to: '/user-onboarding'
    },
    {
        label: 'Recruiting',
        show: false,
        name: AppLevelPermissions.Job,
        to: '/recruiting'
    },
    {
        label: 'Performance',
        show: false,
        name: AppLevelPermissions.Performance,
        to: '/performance'
    },
    {
        label: 'Time Management',
        show: false,
        name: AppLevelPermissions.TimeManagement,
        to: '/time-management'
    },
    {
        label: 'Payroll',
        show: false,
        name: AppLevelPermissions.Payroll,
        to: '/payroll'
    },
    {
        label: 'Form Builder',
        show: false,
        name: AppLevelPermissions.FormBuilder,
        to: '/builder'
    },
    {
        label: 'Comp and Ben',
        show: false,
        name: AppLevelPermissions.CompAndBen,
        to: '/comp-ben'
    },
    {
        label: 'Reports',
        show: false,
        name: 'reports',
        to: '/reports'
    },
];