import React, { useEffect, useMemo, useState } from 'react';
import ModalDialog from '../../../atoms/Modal/ModalDialog';
import ManageTab from './manage-tab.component';
import { useDispatch, useSelector } from 'react-redux';
import { addConfig, editModalData } from '../../../redux/form-builder/action';
import {
  BuilderModalFormFieldConfig,
  getFieldTypeAndDataType,
} from '../utils/builder-utils';
import {
  getMandatoryFields,
  getValidationErrors,
} from '../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { isEmpty } from 'lodash';

const ModalView = (props) => {
  const { showModal, setShowModal, fieldType, data, isEdit, index, fieldSize, dataType } =
    props;
  const [formInfo, setFormInfo] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [confirmClose, setConfirmClose] = useState(false);
  const value = getFieldTypeAndDataType(dataType || fieldType);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      const { isDisabled, ...rest } = data || {};
      setFormInfo({ ...rest, fieldSize: { id: fieldSize, name: fieldSize } });
    }
  }, [data]);

  useEffect(() => {
    if (showModal === true) {
      setConfirmClose(false);
    }
  }, [showModal]);

  const requiredFields = useMemo(() => {
    return getMandatoryFields(BuilderModalFormFieldConfig);
  }, [BuilderModalFormFieldConfig]);

  const handleSave = () => {
    const errors = getValidationErrors(formInfo || {}, requiredFields);
    if (isEmpty(errors)) {
      if (isEdit) {
        dispatch(
          editModalData({
            data: {
              ...formInfo,
              fieldRefName: formInfo?.sysField?.fieldRefName,
              ...value,
            },
            index,
          })
        );
      } else {
        dispatch(
          addConfig({
            ...formInfo,
            ...value,
            fieldRefName: formInfo?.sysField?.fieldRefName,
          })
        );
      }
      setConfirmClose(true);
      setShowModal(false);
      setFormInfo({});
    } else {
      setValidationErrors(errors);
      setConfirmClose(false);
      setShowModal(true);
    }
  };

  return (
    <ModalDialog
      title={`${formInfo?.fieldLabel? `${formInfo?.fieldLabel} - ` : ''} ${value?.dataType || value?.fieldType}`}
      description={
        <ManageTab
          formInfo={formInfo}
          setFormInfo={setFormInfo}
          fieldType={value?.fieldType}
          dataType={value?.dataType}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
        />
      }
      primaryAction="Save"
      secondaryAction="Cancel"
      showModal={showModal}
      onClickClose={() => {
        setShowModal(false);
        setFormInfo({});
        setValidationErrors({});
        setConfirmClose(false);
      }}
      onClickConfirm={handleSave}
      severity="success"
      maxWidth="lg"
      fullWidth
      confirmClose={confirmClose}
    />
  );
};

export default ModalView;
