import { Box } from '@mui/material';
import React from 'react';
import { FormFieldTypes } from '../../../../../../../constants/common';
import { getIcon } from '../../../../../../form-builder/helper/helper-utlis';

const BenefitFieldPicker = (props) => {
    const {handleClick, height = '255px', fieldList = []} = props; 
     
    return (
      <>
        <Box
          border="1px solid #efefef"
          borderLeft='none'
          height={height}
          sx={{ overflowY: 'scroll' }}
        >
          {fieldList.map((item, index) => {
            if (
              item.fieldType === FormFieldTypes.Formula ||
              item.fieldDatatypeCode === FormFieldTypes.Number || 
              item.fieldType === FormFieldTypes.AutoPopulate
            )
              return (
                <Box
                  key={index}
                  borderBottom="1px solid #efefef"
                  display="flex"
                  alignItems="center"
                  onClick={() => handleClick(item)}
                  padding='3px 10px 5px 12px'
                  lineHeight='30px'
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgba(167, 236, 226, 0.3)',
                    },
                  }}
                >
                  {getIcon(item.dataType || item.fieldType, {
                    width: '24px',
                    height: '24px',
                    mr: '5px',
                    color: 'gray',
                  })}
                  {item.fieldDisplayName}
                </Box>
              );
          })}
        </Box>
      </>
    );
  };

export default BenefitFieldPicker;