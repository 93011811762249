import { Box, styled } from '@mui/material';

const StyledIconBox = styled(Box)(({ theme, color }) => ({
  padding: '10px 10px',
  border: '1px solid #ccc',
  borderColor: color,
  background: 'white',
  borderRadius: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
}));

export default StyledIconBox;
