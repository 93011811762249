import React, { useState, useEffect } from 'react';

// Third-party imports
import { Grid } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

// Local imports
import {
  UserDetails,
  SingleRequestData,
  Attachments,
  PaymentDate,
  DraftOptions,
  ClaimBasicDetails,
  ClaimRejectModal,
  SuccessModal,
  ManagerActions,
} from '../../../shared/components/claim-forms';
import {
  approveClaimRequestCall,
  deleteDraftCall,
  getSelectedClaimsDataCall,
  rejectClaimRequestCall,
} from '../common-services/services';

// CSS imports
import '../overtime-claim/OvertimeClaimView.css';
import '../FormsMain.css';
import { getPayDateForManager } from '../common-services/functions';
import ReasonIssueList from '../../../constants/Reasons';

const TransportClaimView = () => {
  // For Navigation
  const navigate = useNavigate();
  const hostname = window.location.hostname;
  const tenant = hostname.split('.')[0] === 'localhost'? process.env.REACT_APP_LOCALHOST_CLIENT_ID : hostname.split('.')[0];

  // Initial states
  const [payDate, setPayDate] = useState(new Date());
  const [fullName, setFullName] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [remark, setRemark] = useState('');
  const [selected, setSelected] = React.useState(new Set());
  const [selectedReason, setSelectedReason] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [claimsData, setClaimsData] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  // List for claim reject reasons for Manager
  const selectionList = ReasonIssueList;

  // Redux data fetch for current user type
  const currentUserType = useSelector(
    (state) => state && state.claims.currentUserType
  );

  // Redux data fetch for current user data
  const currentUserData = useSelector((state) => state.claims.claimData);

  // Filter to get the selected user data from table
  let employeeClaimData = [];
  let refNumber = currentUserData.refNumber;
  if (currentUserData.wholeData) {
    employeeClaimData = currentUserData.wholeData.filter(
      (item) => item.referenceNumber == refNumber
    );
  }

  // Below effect only runs one time and gets the current user data from localStorage
  // useEffect(() => {
  //   const userData = JSON.parse(localStorage.getItem('userData'));
  //   setEmployeeId(userData.userDetails.id);
  //   setFullName(
  //     userData.userDetails.firstName + ' ' + userData.userDetails.lastName
  //   );
  // });

  useEffect(() => {
    getDataForSelectedClaim(currentUserData.singleData.id);
  }, []);

  let claimType = '';

  if (employeeClaimData[0]) {
    claimType = employeeClaimData[0].type.id;
  }

  // Remarks textfield change handler for reject claim modal
  const handleRemarkChange = (event) => {
    setRemark(event.target.value);
  };

  /** Open the modal */
  const openBackdropHandler = () => {
    setIsBackdropOpen(true);
  };

  /** Open the modal */
  const openSuccessHandler = () => {
    setIsSuccess(true);
  };

  /** Close the modal */
  const closeBackdropHandler = () => {
    setIsSuccess(false);
    setIsBackdropOpen(false);
  };

  /** close the modal */
  const closeSuccessHandler = () => {
    setIsSuccess(false);
    navigate('/dashboard', {
      state: {
        claimType: '2',
      },
    });
  };

  // For payment date set
  useEffect(() => {
    setPayDate(
      claimsData.paymentDate ? claimsData.paymentDate : getPayDateForManager()
    );
  }, [claimsData.paymentDate]);

  // Chip selection logic for reject modal, helps manager to selected claim reject reasons.
  const handleChipSelected = (id) => {
    const newSet = new Set(selected);
    if (newSet.has(id)) newSet.delete(id);
    else newSet.add(id);
    const arr = [...newSet];
    // console.log(arr,'reasons');
    setSelectedReason(arr);
    setSelected(newSet);
  };

  // Delete request API call for Employee to delete draft.
  const deleteDraft = async (currentUserData) => {
    let deleteResponse = await deleteDraftCall(currentUserData);
    if (deleteResponse == 200) {
      setIsSuccess(true);
      setSuccessMessage('Draft deleted successfully !');
    }
  };

  // Manager can approve claim request for Employee.
  const handleApproveRequest = async () => {
    setFormSubmitted(true);
    let approveResponse = await approveClaimRequestCall(
      currentUserData,
      currentUserType,
      payDate
    );
    setFormSubmitted(false);
    if (approveResponse == 'Action Completed Successfully!') {
      navigate('/success', {
        state: {
          claimType: 'Transport Claim',
          employeeId: employeeId,
          employeeName: fullName,
          claimReferenceNumber: employeeClaimData[0].referenceNumber,
          amount:
            employeeClaimData[0].finalAmount ||
            employeeClaimData[0].claimAmount ||
            employeeClaimData[0].amount,
        },
      });
    }
  };

  // Manager can reject claim request for Employee.
  const handleRejectRequest = async (status) => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    setFormSubmitted(true);
    let rejectResponse='';
    if(selectedReason.length>0){
      rejectResponse = await rejectClaimRequestCall(
        userData,
        remark,
        currentUserData,
        payDate,
        selectedReason,
        status
      );
    }
    else{
      alert('Sendback Reason(s) Is Mandatory');
      setFormSubmitted(false);
    }
    if (rejectResponse == 'Action Completed Successfully!') {
      navigate('/claims');
      closeBackdropHandler();
    }
  };

  // Get the data for selected claim.
  const getDataForSelectedClaim = async (id) => {
    let claimsData = await getSelectedClaimsDataCall(id);
    if (claimsData) {
      setClaimsData(claimsData);
      setFullName(
        claimsData.employee.firstName + ' ' + claimsData.employee.lastName
      );
      setEmployeeId(claimsData.employee.id);
    }
  };
  useEffect(() => {
    // console.log(claimsData, currentUserData.wholeData);
  }, [claimsData]);

  const submitData = () => {
    let userData = JSON.parse(localStorage.getItem('userData'));

    let today = new Date();
    let date =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate();
    let time =
      today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    let dateTime = date + ' ' + time;

    // console.log('INPUTS', employeeClaimData);

    let finalFormData = [];
    let updateFormObject = {};

    updateFormObject = {
      typeId: '1',
      referenceNumber: employeeClaimData[0].claimReferenceNumber.toString(),
      description: '',
      fromDate: '',
      toDate: '',
      receiptDate: employeeClaimData[0].receiptDate,
      receiptNumber: employeeClaimData[0].receiptNumber,
      status: 'PENDING',
      employee: userData && userData.userDetails.id,

      request: {
        typeId: '1',
        claimReferenceNumber:
          employeeClaimData[0].claimReferenceNumber.toString(),
        claimAmount: employeeClaimData[0].claimAmount,
        benefitType: employeeClaimData[0].benefitType,
        receiptDate: employeeClaimData[0].receiptDate,
        receiptNumber: employeeClaimData[0].receiptNumber,
        claimType: employeeClaimData[0].claimType,
        status: 'PENDING',
        submissionDate: dateTime,
        files: [],
      },
    };

    // console.log('updated', updateFormObject);

    // console.log('Finallllll', finalFormData);

    fetch(`${process.env.REACT_APP_BASE_API_LOCAL}/business-travels`, {
      crossDomain: true,
      // Adding method type
      method: 'POST',

      // Adding body or contents to send
      body: JSON.stringify(updateFormObject),

      // Adding headers to the request
      headers: {
        'Content-type': 'application/json',
        'client_id': tenant
      },
    })
      // Converting to JSON
      .then((response) => response.json())

      // Displaying results to console
      .then((json) => {
        if (json.statusCode == 201) {
          // openBackdropHandler();
          // openSuccessHandler();
          setIsSuccess(true);
          setSuccessMessage('Draft deleted successfully !');
        } else {
          window.alert('Something went wrong !');
        }
      });
  };

  const editData = () => {
    if (claimsData) {
      navigate('/employee-portal/transport-claim/edit', {
        state: {
          claimsData: claimsData,
          name: currentUserData.singleData.linkName,
          id: currentUserData.singleData.type,
        },
      });
    }
  };

  useEffect(() => {
    ('Claims Data', currentUserData);
  }, [currentUserData]);

  const submitDataForTransport = () => {
    let userData = JSON.parse(localStorage.getItem('userData'));

    let today = new Date();
    let date =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate();
    let time =
      today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    let dateTime = date + ' ' + time;

    let updateFormObject = {};

    updateFormObject = {
      typeId: '2',
      referenceNumber: employeeClaimData[0].claimReferenceNumber.toString(),
      description: '',
      fromDate: '',
      toDate: '',
      receiptDate: employeeClaimData[0].receiptDate,
      receiptNumber: employeeClaimData[0].receiptNumber,
      status: 'PENDING',
      employee: userData && userData.userDetails.id,

      request: {
        typeId: '2',
        claimReferenceNumber:
          employeeClaimData[0].claimReferenceNumber.toString(),
        modeOfTransport: employeeClaimData[0].type,
        travelDate: employeeClaimData[0].travelDate,
        distance: employeeClaimData[0].homeToOfficeDist,
        fromDest: employeeClaimData[0].locationFrom,
        toDest: employeeClaimData[0].toDest,
        reasonOfJourney: employeeClaimData[0].reasonJourney,
        otherReasons: employeeClaimData[0].otherReasons,
        departureTime: employeeClaimData[0].departureTime,
        toFromHome: employeeClaimData[0].toFromHome,
        parkingfFee: employeeClaimData[0].carParkFees,
        tollFee: employeeClaimData[0].erp,
        mileage: employeeClaimData[0].mileage,
        computedAmount: employeeClaimData[0].compAmount,
        finalAmount: employeeClaimData[0].finalAmount,
        paymentDate: employeeClaimData[0].paymentDate,
        receiptAmount: employeeClaimData[0].receiptAmount,
        totalAmount: employeeClaimData[0].totalAmt,
        status: 'PENDING',
        submissionDate: dateTime,
      },
    };

    fetch(`${process.env.REACT_APP_BASE_API_LOCAL}/business-travels`, {
      crossDomain: true,
      // Adding method type
      method: 'POST',

      // Adding body or contents to send
      body: JSON.stringify(updateFormObject),

      // Adding headers to the request
      headers: {
        'Content-type': 'application/json',
        'client_id': tenant
      },
    })
      // Converting to JSON
      .then((response) => response.json())

      // Displaying results to console
      .then((json) => {
        if (json.statusCode == 201) {
          // openBackdropHandler();
          openSuccessHandler();
        } else {
          window.alert('Something went wrong !');
        }
      });
  };

  return (
    <React.Fragment>
      <Grid container maxWidth="lg" className="success-card-main">
        {/* User details component start */}
        <UserDetails
          fullName={fullName}
          employeeId={employeeId}
          claimType={currentUserData.singleData.linkName.replaceAll('-', ' ')=='transport claim' && 'Adhoc Transport Claim' }
          status={currentUserData.singleData.status}
        />
        {/* User details component end */}

        {/* Main content of claim request start */}
        <Grid
          lg={9}
          md={9}
          sm={9}
          xs={12}
          className="card-view-two success-card-wrapper"
        >
          {/* Basic claim details component start */}
          <ClaimBasicDetails
            claimType={claimType}
            employeeClaimData={claimsData}
            claimName={
              currentUserData.singleData.linkName.replaceAll('-', ' ')=='transport claim' && 'Adhoc Transport Claim'
            }
          />
          {/* Basic claim details component end */}

          {/* Claim request details submitted by Employee start */}
          <Grid container spacing={2} className="claims-data">
            <Grid xs={3} sm={6} md={9} lg={12} className="data-display">
              <Grid xs={12} sm={9} lg={6}>
                <SingleRequestData
                  title="Submission Date"
                  value={
                    claimsData.createdAt &&
                    moment(claimsData.createdAt).format('DD-MM-YYYY')
                  }
                />
                <SingleRequestData
                  title="To/From Home"
                  value={claimsData && claimsData?.toFromHome ? 'Yes' : 'No'}
                />
                {/* <SingleRequestData
                  title="To"
                  value={claimsData?.toDestination}
                /> */}

                <SingleRequestData
                  title="From"
                  value={claimsData.fromDestination}
                />
              </Grid>
              <div className="vertical-bar"></div>
              <Grid xs={12} sm={9} lg={6}>
                <SingleRequestData
                  title="Mode Of Transport"
                  value={claimsData?.modeOfTransport}
                />
                <SingleRequestData
                  title="Travel Date"
                  value={
                    claimsData?.travelDate &&
                    moment(claimsData?.travelDate).format('DD-MM-YYYY')
                  }
                />

                <SingleRequestData
                  title="To"
                  value={claimsData?.toDestination}
                />
              </Grid>
              <div className="vertical-bar"></div>
              <Grid xs={12} sm={9} lg={6}>
                <SingleRequestData
                  title="Reason For Journey"
                  value={claimsData.reasonForJourney}
                />
                {claimsData.otherReasons && (
                  <SingleRequestData
                    title="Reason"
                    value={claimsData.otherReasons}
                  />
                )}
                {claimsData.modeOfTransport === 'BUS/MRT/LRT' || claimsData.modeOfTransport === 'Taxi' 
                ? (<SingleRequestData
                  title="Home To Office($)"
                  value={
                    claimsData?.homeToOfficeAmount == 0 ? 'NA' : claimsData?.homeToOfficeAmount
                  }
                />) 
                : <SingleRequestData
                  title="Home To Office Distance(KM)"
                  value={
                    claimsData?.distance == 0 ? 'NA' : claimsData?.distance
                  }
                />
                
               }
                
              </Grid>
            </Grid>
            <div className="horizontal-bar"></div>
            <Grid xs={3} sm={6} md={9} lg={12} className="data-display">
              <Grid xs={12} sm={9} lg={6}>
                {claimsData.modeOfTransport !== 'Taxi' && claimsData.modeOfTransport !== 'BUS/MRT/LRT' && (<>
                <SingleRequestData
                  title="Mileage(KM)"
                  value={claimsData.mileage}
                  />
                <SingleRequestData
                  title="Parking Fees($)"
                  value={
                    claimsData.parkingFee &&
                    Number(claimsData.parkingFee).toFixed(2)
                  }
                  />
                  </>)}
                <SingleRequestData
                  title="Departure Time"
                  value={
                    claimsData.departureTime &&
                    moment(claimsData.departureTime).format('hh:mm A')
                  }
                />
              </Grid>
              <div className="vertical-bar"></div>
              <Grid xs={12} sm={9} lg={6}>
              {claimsData.modeOfTransport !== 'Taxi' && claimsData.modeOfTransport !== 'BUS/MRT/LRT' &&(<>
                <SingleRequestData
                  title="Computed Amount($)"
                  value={claimsData.computedAmount}
                  />
                <SingleRequestData
                  title="ERP($)"
                  value={
                    claimsData.tollFee && Number(claimsData.tollFee).toFixed(2)
                  }
                  />
                  </>)}
                  <SingleRequestData
                    title="Final Amount($)"
                    value={
                      claimsData.amount && Number(claimsData.amount).toFixed(2)
                    }
                  />
              </Grid>
              <div className="vertical-bar"></div>
              <Grid xs={12} sm={9} lg={6}>
                <SingleRequestData
                  title="Receipt Number"
                  value={claimsData.receiptNumber}
                />
                {currentUserData.singleData.status == 'APPROVED' && (
                  <React.Fragment>
                    {/* <div className='vertical-bar'></div> */}
                    <Grid xs={12} sm={9} lg={6}>
                      <SingleRequestData
                        title="Payment Date"
                        value={
                          claimsData.paymentDate &&
                          moment(claimsData.paymentDate).format('DD-MM-YYYY')
                        }
                      />
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>

              {(currentUserData.singleData.status == 'REJECTED' ||
                currentUserData.singleData.status == 'SENT BACK') && (
                <React.Fragment>
                  <div className="vertical-bar"></div>
                  <Grid xs={12} sm={9} lg={6}>
                    <SingleRequestData
                      title="Remark"
                      value={claimsData.notApproveReason}
                    />
                    <SingleRequestData
                      title="Reasons"
                      value={claimsData.reasons}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
          {/* Claim request details submitted by Employee end */}

          {/* Attachment component renders all file links uploaded by Employee start */}
          <Attachments paths={claimsData} />
          {/* Attachment component renders all file links uploaded by Employee end */}

          {/* Payment Date option for Manager when he/she Approves or Rejects the claim request start */}
          <PaymentDate
            payDate={payDate}
            onChange={(e) => setPayDate(e)}
            claimsData={claimsData}
            currentUserType={currentUserType}
            disableCheckOnRole={false}
          />
          {/* Payment Date option for Manager when he/she Approves or Rejects the claim request end */}

          {/* Draft options for Employees to Delete,Edit or Go back Claim requests start */}
          <DraftOptions
            currentUserType={currentUserType}
            currentUserData={currentUserData}
            claimType={claimType}
            deleteDraft={() => deleteDraft(currentUserData)}
            submitData={submitData}
            editData={editData}
            submitDataForTransport={submitDataForTransport}
          disabled={formSubmitted}
          />
          {/* Draft options for Employees to Delete,Edit or Go back Claim requests end */}

          {/* Actions for Manager to Approve, Reject or Review Later claim requests start */}
          <ManagerActions
            currentUserType={currentUserType}
            claimType={claimType}
            openBackdropHandler={openBackdropHandler}
            handleApproveRequest={handleApproveRequest}
            disabled={formSubmitted}
          />
          {/* Actions for Manager to Approve, Reject or Review Later claim requests end */}
        </Grid>
        {/* Main content of claim request end */}
      </Grid>

      {/* Modals start */}

      {/* Success Modal for Edit and Delete success API calls start */}
      <SuccessModal
        isSuccess={isSuccess}
        closeBackdropHandler={closeBackdropHandler}
        closeSuccessHandler={closeSuccessHandler}
        successMessage={successMessage}
      />
      {/* Success Modal for Edit and Delete success API calls end */}

      {/* Claim Reject Modal start */}
      <ClaimRejectModal
        isBackdropOpen={isBackdropOpen}
        closeBackdropHandler={closeBackdropHandler}
        handleRejectRequest={handleRejectRequest}
        selectionList={selectionList}
        selected={selected}
        onclick={handleRejectRequest}
        handleChipSelected={handleChipSelected}
        remark={remark}
        handleRemarkChange={handleRemarkChange}
        currentUserData={currentUserData}
        employeeClaimData={employeeClaimData}
        employeeId={employeeId}
        disabled={formSubmitted}
      />
      {/* Claim Reject Modal end */}

      {/* Modals end */}
    </React.Fragment>
  );
};

export default TransportClaimView;
