import { Box, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import { CandidateServices } from '../../../../../services/candidateServices';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCandidateList } from '../../../../../redux/candidateDetails/action';
import { showToast } from '../../../../../utils/common.util';
import { ToastMessages, stringSubstitute } from '../../../../OnBoarding/Organization/constants/onboarding.constants';
import { ToastThemes, toastMessage } from '../../../../../constants/common';
import { getFormattedValues } from '../../../../../utils/formatter';

const StatusOption = (props) => {
  const { id, value, isDisabled, jobId, searchInput, selectedDate, candidateNameParams } = props;
  const [Options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { currentUserRoleId } = useSelector((state) => state.main);

  const handleSelect = (event) => {
    const { value, name } = event.target;
    const { recruitingStatusCode } = value;
    new CandidateServices().updateCandidateStatus({ id: id, statusCode: recruitingStatusCode, jobId }).then(() => {
      dispatch(getAllCandidateList(currentUserRoleId, searchInput, getFormattedValues('YYYY-MM-DD', selectedDate[0]),
        getFormattedValues('YYYY-MM-DD', selectedDate[1]), candidateNameParams));
      showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.update), ToastThemes.success);
    }).catch(() => {
      showToast(ToastMessages.failed.replace(stringSubstitute, toastMessage.errorupdate), ToastThemes.error);
    });
  };

  const handleClick = () => {
    if (!Options.length) {
      new CandidateServices().getStatusDropDown().then((res) => {
        setOptions(res);
        setOpen(true);
      });
    } else {
      setOpen(true);
    }
  };

  return (
    <Select
      value={value}
      displayEmpty
      onChange={handleSelect}
      name=''
      disabled={isDisabled}
      inputProps={{ 'aria-label': 'Without label' }}
      size='small'
      sx={{ width: '100%' }}
      placeholder='Select Status'
      onOpen={handleClick}
      onClose={() => setOpen(false)}
      open={open}
      renderValue={(selected) => selected ? selected.name : <Box sx={{ color: 'neutral.dark40' }}>{'Select Status'}</Box>}
    >{Options.map((item) => {
      const { id, name, isDisabled } = item;
      return <MenuItem key={id} value={item} disabled={isDisabled}>{name}</MenuItem>;
    })}
    </Select>
  );
};

export default StatusOption;