import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import TargetItem from './TargetItem/TargetItem';

const TargetFrom = (props) => {
    const { targetFormData, setTargetFormInfo, targetConfig } = props;
    return (
        <Box mt={2}>
            {targetConfig.map((formConfig, index) => (
                <TargetItem
                    key={index}
                    formConfig={formConfig}
                    targetFormData={targetFormData[index]}
                    setTargetFormInfo={setTargetFormInfo}
                    index={index}
                />
            ))}
        </Box>
    );
};

export default React.memo(TargetFrom);