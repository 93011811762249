import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAllFormsList } from '../../../redux/form-builder/action';
import ListView from '../../../atoms/DataList/ListView';
import { FormHeadCell } from '../utils/builder-utils';
import { IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import {
  APIRequestStatus,
  DebounceTimer,
  LoadingStatus,
} from '../../../constants/common';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import { useDebounce } from 'usehooks-ts';

const AllForms = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkedItems, setCheckedItems] = useState([]);

  const { currentUserRoleId } = useSelector((state) => state.main);
  const { allDashboardList, requestProcessing } =
    useSelector((state) => state.formBuilder) || {};
  const { [APIRequestStatus.GET_ALL_FORMS_LIST]: listLoading } =
    requestProcessing || {};
  const [searchParams, setSearchParams] = useSearchParams();
  const searchInput = searchParams.get('q');
  const debouncedVal = useDebounce(searchInput, DebounceTimer);

  useEffect(() => {
    if (currentUserRoleId) {
      dispatch(getAllFormsList(currentUserRoleId, searchInput));
    }
  }, [currentUserRoleId, debouncedVal]);

  useEffect(() => {
    setCheckedItems([]);
  }, [allDashboardList]);

  const rowData = allDashboardList?.map((item) => {
    const { name, categoryName, fieldCount, id } = item;
    return {
      name: name,
      category: categoryName,
      fieldCount: fieldCount || '',
      action: (
        <IconButton onClick={() => navigate({ pathname: `${id}` })}>
          <InfoOutlined sx={{ color: 'primary.main80' }} />
        </IconButton>
      ),
      id: {
        hide: true,
        value: id,
      },
    };
  });

  return (
    <>
      <CircularLoader show={listLoading === LoadingStatus.Loading} />
      <ListView
        headCells={FormHeadCell}
        rowsData={rowData}
        showSerialNo={true}
        showCheckbox={true}
        setCheckedItems={setCheckedItems}
        checkedItems={checkedItems}
        allowSelectAll={true}
        showAddIcon={true}
        onAddClick={() => navigate('create-form')}
      />
    </>
  );
};

export default AllForms;
