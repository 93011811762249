import React from 'react';
import InputField from '../../../../../atoms/Input/Input';
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

const BasicForm = (props) => {
  const {
    setBasicComponents,
    basicComponents,
    readOnly,
    isReadOnly,
    isHidden,
    payrollEditDisabled,
    basicErrors,
    setPayrollError,
    setIsPayrollChange,
  } = props;

  const handleInputChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;
    if (val > -1 ) {
      setBasicComponents((prev) => ({
        ...prev,
        [key]: val,
      }));
    }
    setPayrollError((prev) => ({
      ...prev,
      basicErrors: false,
    }));
    setIsPayrollChange(true);
  };
  const preventNegativeKeyPress = (event) => {
    if (event.key === '-' || event.key === 'e' || event.key === '+') {
      event.preventDefault();
    }
  };
  if (readOnly) {
    return (
      <Box marginTop={3} display="flex" justifyContent="space-between">
        <Box>
          <Typography>{basicComponents?.name}</Typography>
        </Box>
        <Box>
          <Typography>{basicComponents?.salaryTypeRateAmt || ''}</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box marginTop={3} maxWidth={250}>
      {!isEmpty(basicComponents) ? (
        <InputField
          labelText={basicComponents?.name}
          size="small"
          placeholder="type here"
          type="number"
          onChange={handleInputChange}
          name="salaryTypeRateAmt"
          value={basicComponents?.salaryTypeRateAmt}
          isHidden={isHidden}
          fullWidth={true}
          isReadOnly={isReadOnly}
          disabled={payrollEditDisabled}
          error={basicErrors}
          helperText={basicErrors?`${basicComponents?.name} can't be empty}`: ''}
          infoIcon='Value cannot be negative'
          onKeyDown={preventNegativeKeyPress}
        />
      ) : (
        ''
      )}
    </Box>
  );
};

export default BasicForm;
