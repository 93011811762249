import React, { useMemo, useState } from 'react';
import FormView from '../../../../../../../OnBoarding/common/Form/FormView';
import InterviewFeedbackForm from './InterviewFeedbackForm';
import { useSelector } from 'react-redux';
import { InterviewFormFields } from '../../../../../../Constant/constant';
import FormEdit from '../../../../../../../OnBoarding/common/Form/FormEdit';
import { Box, Paper } from '@mui/material';
import { InterviewServices } from '../../../../../../../../services/interviewServices';

const InterviewFormFeedbackForm = (props) => {
  const { list, info, isInterviewer,
    competencies,
    setCompetencies,
    skills,
    setSkills,
    interviewFeedbackFormInfo,
    setInterviewFeedbackFormInfo,
    setInterviewForm,
    interviewFeedbackFormConfig,
    competencyRatingScale
  } = props;

  const [modeOfInterviewOption, setModeOfInterviewOption] = useState([]);

  const handleDropdownClick = (name) => {
    switch (name) {
      case InterviewFormFields.modeOfInterview: {
        return new InterviewServices().getDropdownOptions(name).then((res) => {
          setModeOfInterviewOption(res);
          return true;
        });
      }
      default: break;
    }
  };
  const getDropdownOptions = (name) => {
    switch (name) {
      case InterviewFormFields.modeOfInterview: {
        return modeOfInterviewOption;
      }
    }
  };

  const formFields = useMemo(() => {
    return list.reduce((acc, item) => {
      const { fieldRefName } = item;
      let temp = { ...item };
      switch (fieldRefName) {
        case InterviewFormFields.Interviewer: {
          temp = { ...temp, isReadOnly: true };
          break;
        }
        case InterviewFormFields.InterviewType: {
          temp = {
            ...temp, isReadOnly: true
          };
          break;
        }
        default: {
          break;
        }
      }
      acc.push(temp);
      return acc;
    }, []);
  }, [list]);


  return (
    <Paper sx={{ mt: 3 }}>
      <Box sx={{ p: 5 }} >
        <FormEdit
          list={formFields}
          formInfo={info}
          setFormData={setInterviewForm}
          padding={0}
          handleDropdownClick={handleDropdownClick}
          getDropdownOptions={getDropdownOptions}
        />
        {isInterviewer &&
          <InterviewFeedbackForm
            interviewFeedbackFormConfig={interviewFeedbackFormConfig}
            competencies={competencies}
            setCompetencies={setCompetencies}
            skills={skills}
            setSkills={setSkills}
            interviewFeedbackFormInfo={interviewFeedbackFormInfo}
            setInterviewFeedbackFormInfo={setInterviewFeedbackFormInfo}
            competencyRatingScale={competencyRatingScale}
          />
        }
      </Box>
    </Paper>
  );
};

export default InterviewFormFeedbackForm;