import React from 'react';
import FormView from '../../../../../../OnBoarding/common/Form/FormView';

const PerformanceFormView = (props) => {
    const { formConfig, formData } = props;
    return (
        <FormView
            list={formConfig}
            info={formData}
        />
    );
};

export default PerformanceFormView;