import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Divider } from '@mui/material';
import Builder from './builder.component';
import DynamicBreadcrumb from '../../atoms/Breadcrumbs/dynamicBreadcrumb';
import SubHeader from './sub-header/sub-header.component';
import ManageFormTab from './manage-tab/manage-form-tab.component';
import { Outlet } from 'react-router-dom';

const FormBuilder = () => {
  const [viewMode, setViewMode] = useState(false);

  return (
    <Box m={4} pl={4} pr={10}>
      <Box>
        <SubHeader />
        <Box my={3}>
          <DynamicBreadcrumb />
          <Box mt={1}>
            <Divider />
          </Box>
        </Box>
      </Box>
      <Outlet context={[viewMode, setViewMode]} />
    </Box>
  );
};
export default FormBuilder;
