import React from 'react';
import { useNavigate } from 'react-router-dom';
import { setCurrentUser } from '../../../redux/actions/employee-portal/employee-portal.action';
import store from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import './Toggle.css';
import ManagerSvg from '../../../assets/Manager.svg';
import EmployeeSvg from '../../../assets/Employee.svg';
import { setLocalStorage } from '../../../utils/common.util';
import {
  fetchAppLevelPermissions,
  setCurrentUserRoleId,
} from '../../../redux/main/action';
import { resetOnboardingState } from '../../../redux/onboarding/action';
import { resetModulePermissions } from '../../../redux/modulePermissions/action';
import { resetGoals } from '../../../redux/goals/action';
import { resetJob } from '../../../redux/jobRecruiting/action';
import { resetCandidate } from '../../../redux/candidateDetails/action';
import { resetNewClaims } from '../../../redux/newClaims/action';
import { resetPayroll } from '../../../redux/payroll/action';
import { LightTooltip } from '../../../atoms/Tooltip/LightTooltip';
import theme from '../../../foundation/theme';

const Toggle = (props) => {
  const { roleId, name, background, to } = props;
  const navigate = useNavigate();

  const { currentUserRoleId } = useSelector((state) => state.main);
  const dispatch = useDispatch();

  const switchRole = (path, name, roleId) => {
    navigate(path, {
      state: {
        typeOfUser: name,
      },
    });
    dispatch(setCurrentUserRoleId(roleId));
    dispatch(fetchAppLevelPermissions(roleId));
    dispatch(resetOnboardingState());
    dispatch(resetModulePermissions());
    dispatch(resetGoals());
    dispatch(resetJob());
    dispatch(resetCandidate());
    dispatch(resetNewClaims());
    dispatch(resetPayroll());
    setLocalStorage('role', { roleId, name });
  };

  return (
    <LightTooltip
      title={props.name}
      placement="bottom-start"
      arrow
      bgColor="#212B36"
      color={theme.palette.neutral.light60}
      sx={{ margin: 2 }}
      padding= '6px 8px'
      fontSize= {12}
    >
      <div
        className={`outer-circle-wrap ${
          currentUserRoleId === roleId ? 'active-user' : ''
        }`}
        style={{ background: background }}
        onClick={() => switchRole(to, name, roleId)}
      >
        {currentUserRoleId === roleId && <span>{props.name}</span>}
        <div
          className={`inner-circle-wrap ${
            currentUserRoleId === roleId ? 'active-circle' : ''
          }`}
        >
          {props.name == 'Employee' ? (
            <img style={{ width: '100%' }} src={EmployeeSvg} alt="Employee" />
          ) : (
            <img style={{ width: '100%' }} src={ManagerSvg} alt="Manager" />
          )}
          {/* {props.name && props.name.split('')[0]} */}
        </div>
      </div>
   </LightTooltip>
  );
};

export default Toggle;
