export const GET_GOALS_CONFIG_REQUEST = 'GET_GOALS_CONFIG_REQUEST';
export const GET_GOALS_CONFIG_SUCCESS = 'GET_GOALS_CONFIG_SUCCESS';
export const GET_GOALS_CONFIG_FAILURE = 'GET_GOALS_CONFIG_FAILURE';

export const POST_GOALS_INFO_REQUEST = 'POST_GOALS_INFO_REQUEST';
export const POST_GOALS_INFO_SUCCESS = 'POST_GOALS_INFO_SUCCESS';
export const POST_GOALS_INFO_FAILURE = 'POST_GOALS_INFO_FAILURE';

export const GET_ALL_GOALS_LIST_REQUEST = 'GET_ALL_GOALS_LIST_REQUEST';
export const GET_ALL_GOALS_LIST_SUCCESS = 'GET_ALL_GOALS_LIST_SUCCESS';
export const GET_ALL_GOALS_LIST_FAILURE = 'GET_ALL_GOALS_LIST_FAILURE';

export const RESET_GOALS = 'RESET_GOALS';

export const ADD_KRA_TAB = 'ADD_KRA_TAB';

export const DELETE_KRA_TAB = 'DELETE_KRA_TAB';

export const UPDATE_KRA_DATA = 'UPDATE_KRA_DATA';

export const DISCARD_DATA = 'DISCARD_DATA';

export const UPDATE_KRA_DATA_PERFORMANCE = 'UPDATE_KRA_DATA_PERFORMANCE';

export const CALCULATE_TOTAL_SCORE = 'CALCULATE_TOTAL_SCORE';

export const GET_ALL_APPRAISAL_LIST_REQUEST = 'GET_ALL_APPRAISAL_LIST_REQUEST';
export const GET_ALL_APPRAISAL_LIST_SUCCESS = 'GET_ALL_APPRAISAL_LIST_SUCCESS';
export const GET_ALL_APPRAISAL_LIST_FAILURE = 'GET_ALL_APPRAISAL_LIST_FAILURE';

export const GET_APPRAISAL_DETAILS_REQUEST = 'GET_APPRAISAL_DETAILS_REQUEST';
export const GET_APPRAISAL_DETAILS_SUCCESS = 'GET_APPRAISAL_DETAILS_SUCCESS';
export const GET_APPRAISAL_DETAILS_FAILURE = 'GET_APPRAISAL_DETAILS_FAILURE';

export const POST_APPRAISAL_DETAILS_REQUEST = 'POST_APPRAISAL_DETAILS_REQUEST';
export const POST_APPRAISAL_DETAILS_SUCCESS = 'POST_APPRAISAL_DETAILS_SUCCESS';
export const POST_APPRAISAL_DETAILS_FAILURE = 'POST_APPRAISAL_DETAILS_FAILURE';

export const DUPLICATE_KRA_TAB = 'DUPLICATE_KRA_TAB';

export const MANGER_ACCES_READ_ONLY = 'MANGER_ACCES_READ_ONLY';

export const SCORE_GRADE = 'SCORE_GRADE';


