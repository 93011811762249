import { Box, Grid } from '@mui/material';
import React, { useMemo } from 'react';
import CompetencyItem from './CompetencyItem/CompetencyItem';
import { CompetencyFormFieldNames } from '../../Utlis/goals.util';

const CompetencyView = (props) => {
  const {
    data,
    maxScoreCompetency,
    setData,
    isAppraisal,
    viewMode,
    setCompetencyError,
    competencyError,
    appraiserRemarksCompetency,
    setAppraiserRemarksCompetency,
    managerAcess
  } = props;
  const ratingDropdown = [];
  useMemo(() => {
    for (let i = 0; i <= maxScoreCompetency; i++) {
      ratingDropdown.push({ id: i, name: i });
    }
  }, [ratingDropdown]);
  return (
    <>
      <Grid item xs={12} md={6}>
        {data.map((item, index) => {
          const {
            fkCompetency,
            goalCompetencyWeightage,
            [CompetencyFormFieldNames.Submission]: submission,
          } = item;
          const rating = submission?.[CompetencyFormFieldNames.Rating] || 0;
          const remarks = submission?.[CompetencyFormFieldNames.Remarks];

          return (
            <Box key={index} mb={2}>
              <CompetencyItem
                heading={fkCompetency.name}
                weightageValue={goalCompetencyWeightage}
                ratingDropdown={ratingDropdown}
                setData={setData}
                rating={rating}
                itemIndex={index}
                isAppraisal={isAppraisal}
                viewMode={viewMode}
                remarks={remarks}
                competencyError={isAppraisal ? competencyError[index] : ''}
                setCompetencyError={isAppraisal ? setCompetencyError : ''}
                appraiserRemarksCompetency={appraiserRemarksCompetency?appraiserRemarksCompetency[index]:{}}
                setAppraiserRemarksCompetency={setAppraiserRemarksCompetency}
                competencyId={fkCompetency.id}
                managerAcess={managerAcess}
              />
            </Box>
          );
        })}
      </Grid>
    </>
  );
};

export default React.memo(CompetencyView);
