import { fileUploadFailure } from '../../constants/common';
import { fileUploadServices } from '../../services/fileUpload';
import { mainServices } from '../../services/main';
import { FETCH_APP_LEVEL_PERMISSIONS_FAILURE, FETCH_APP_LEVEL_PERMISSIONS_REQUEST, FETCH_APP_LEVEL_PERMISSIONS_SUCCESS, 
  SET_CURRENT_USER_INFO,
  SET_AUTH, 
  SET_CURRENT_USER_ROLE_ID,
  FILE_UPLOAD_FAILURE, FILE_UPLOAD_REQUEST, FILE_UPLOAD_SUCCESS
  } from './actionTypes';

const fetchAppLevelPermissionsRequest = () => (
    {
      type: FETCH_APP_LEVEL_PERMISSIONS_REQUEST
    } 
  );
  
  const fetchAppLevelPermissionsSuccess = (payload) => (
    {
      type: FETCH_APP_LEVEL_PERMISSIONS_SUCCESS,
      payload
    } 
  );
  
  const fetchAppLevelPermissionsFailure = () => (
    {
      type: FETCH_APP_LEVEL_PERMISSIONS_FAILURE
    } 
  );

  const postFileUploadRequest = () => (
    {
      type: FILE_UPLOAD_REQUEST
    }
  );
  
  const postFileUploadSuccess = (payload) => (
    {
      type: FILE_UPLOAD_SUCCESS,
      payload
    }
  );
  
  const postFileUploadFailure = () => (
    {
      type: FILE_UPLOAD_FAILURE
    }
  );

  export const setCurrentUserRoleId = (payload) => (
    {
      type: SET_CURRENT_USER_ROLE_ID,
      payload
    });

  export const setUserInfo = (payload) => (
    {
      type: SET_CURRENT_USER_INFO,
      payload
    });

  export const setAuth = (payload) => (
    {
      type: SET_AUTH,
      payload
    });

export const fetchAppLevelPermissions = (roleId) => async (dispatch) => {
    dispatch(fetchAppLevelPermissionsRequest());
    new mainServices().fetchAppLevelPermissions(roleId).then((res) => {
       dispatch(fetchAppLevelPermissionsSuccess(res));
   }).catch(() => {
       dispatch(fetchAppLevelPermissionsFailure());
   });
  };

  export const fileUpload = (fileData) => async (dispatch) => {
    const formData = new FormData();
    formData.append('files', fileData);
    dispatch(postFileUploadRequest());
    return new fileUploadServices().fileUpload(formData).then((res) => {
      dispatch(postFileUploadSuccess(res));
      return res[0];
    }).catch(() => {
      dispatch(postFileUploadFailure());
      throw fileUploadFailure;
    });
  };  