import React, { useEffect, useMemo, useState } from 'react';
import FormEdit from '../OnBoarding/common/Form/FormEdit';
import BuilderSidebar from './builder-sidebar/builder-sidebar.component';
import { Box, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormFieldTypes,
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../constants/common';
import ActionHeader from '../OnBoarding/Organization/ActionHeader/ActionHeader';
import { deleteModal, postConfigInfo } from '../../redux/form-builder/action';
import { showToast } from '../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../OnBoarding/Organization/constants/onboarding.constants';
import { useNavigate } from 'react-router';
import ModalView from './modal-component/modal-view.component';
import {
  BuilderFormConfig,
  ClaimCategoryDropDown,
  builderFields,
} from './utils/builder-utils';
import CircularLoader from '../../atoms/CircularLoader/circular-loader';
import { BuilderServices } from '../../services/builder';
import { NewClaimsService } from '../../services/newClaims';
import { CommonSevice } from '../../services/common';

const Builder = (props) => {
  const {
    list,
    claimData,
    idForFormDetails,
    formInfo,
    setFormInfo,
    handleFormSaveClick,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalData, setModalData] = useState({});
  const [configItemIndex, setConfigItemIndex] = useState(null);
  const [loading, setLoading] = useState(-1);
  const [benfitsDropDown, setBenfitsDropDown] = useState([]);
  const [categories, setCategories] = useState([]);
  const [userDropDown, setUserDropDown] = useState([]);
  const [paymentOption, setPaymentOptions] = useState([]);

  useEffect(() => {
    setFormInfo(claimData);
  }, [claimData]);

  const handleEditButtonClick = (item, index) => {
    setModalData({ ...item });
    setShowModal(true);
    setConfigItemIndex(index);
  };

  const deleteClick = (index) => {
    dispatch(deleteModal(index));
  };

  const newList = list?.map((item) => {
    let newItem = { ...item };

    if (!item.isDisabled) {
      newItem.isDisabled = true;
    }

    if (item.fieldSize && item.fieldSize.id) {
      newItem.fieldSize = item.fieldSize.id;
    }

    return newItem;
  });
  const getDropdownOptions = (name) => {
    switch (name) {
      case builderFields.categoryId: {
        return categories;
      }
      case builderFields.benifitsAttached: {
        return benfitsDropDown;
      }
      case builderFields.keyContactPersons: {
        return userDropDown;
      }
      case builderFields.paymentMode: {
        return paymentOption;
      }
    }
  };

  const handleDropdownClick = (name) => {
    switch (name) {
      case builderFields.benifitsAttached: {
        return new BuilderServices().getDropDownBenifits().then((res) => {
          setBenfitsDropDown(res);
          return true;
        });
      }
      case builderFields.categoryId: {
        return new NewClaimsService().getAllCategories().then((res) => {
          setCategories(res);
          return true;
        });
      }
      case builderFields.keyContactPersons: {
        return new NewClaimsService().getUserList().then((res) => {
          setUserDropDown(res);
          return true;
        });
      }
      case builderFields.paymentMode:{
        return new CommonSevice().getDropDown('paymentDateOptions').then((res) => {
          setPaymentOptions(res);
          return true;
        });
      }
    }
  };

  const handleDiscard = () => {
    navigate(-1);
  };

  const formFields = useMemo(() => {
    return BuilderFormConfig.reduce((acc, item) => {
      const { fieldRefName } = item;
      let temp = { ...item };
      switch (fieldRefName) {
        case builderFields.benifitsAttached:
          temp = {
            ...temp,
            isHidden:
              !formInfo?.[builderFields.isBenefitAttached],
          };
          break;
        default: {
          break;
        }
      }
      if (!temp.isHidden) {
        acc.push(temp);
      }
      if (temp.isHidden) {
        delete formInfo[temp?.fieldRefName];
      }
      return acc;
    }, []);
  }, [formInfo]);

  return (
    <Box>
      <CircularLoader show={LoadingStatus.Loading === loading} />
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={claimData?.name || 'New Form'}
          showDiscard
          showPublish
          showPublishModal={false}
          publishButtonText="Save"
          onPublishClick={handleFormSaveClick}
          onClickDiscard={handleDiscard}
          showSave={false}
        />
      </Box>
      <FormEdit
        list={formFields}
        getDropdownOptions={getDropdownOptions}
        handleDropdownClick={handleDropdownClick}
        formInfo={formInfo}
        setFormData={setFormInfo}
      />
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box mt={2}>
        <FormEdit
          list={newList}
          button={true}
          editClick={handleEditButtonClick}
          deleteClick={deleteClick}
          formInfo={{}}
          hideFields={false}
        />
      </Box>
      <BuilderSidebar />
      {modalData && (
        <ModalView
          showModal={showModal}
          setShowModal={setShowModal}
          data={modalData}
          index={configItemIndex}
          isEdit={true}
          fieldType={modalData?.fieldType}
          dataType={modalData?.dataType}
          fieldSize={modalData?.fieldSize}
        />
      )}
    </Box>
  );
};

export default Builder;
