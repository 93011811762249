import API from './api';
export class approvalWorkflowServices extends API {
  static baseURL =
    API.env.REACT_APP_BASE_API_LOCAL + '/approval-workflow/' || '';
  constructor() {
    super(approvalWorkflowServices.baseURL);
  }

  getApprovalWorkflowStructureDetails = async (
    roleId,
    approvalWorkflowStructureId
  ) =>
    this.responsify(
      await this.instance.get('', {
        params: {
          id: approvalWorkflowStructureId,
          roleId,
        },
      })
    );
  getAllapprovalWorkflowList = async (roleId, searchQuery, forLibrary) =>
    this.responsify(
      await this.instance.get('list', {
        params: { searchQuery, forLibrary, roleId },
      })
    );
  getDropdownOptions = async (fieldName) =>
    this.responsify(
      await this.instance.get('lookup-details', { params: { fieldName } })
    );

  getFormNames = async (moduleName) =>
    this.responsify(
      await this.instance.get('get-form-names', { params: { moduleName } })
    );

  getApprovalPermissions = async (
    moduleId,
    recordId,
    currentUserRoleId,
    userId,
    moduleType,
    moduleName
  ) =>
    this.responsify(
      await this.instance.get('get-approval-permission', {
        params: {
          moduleId,
          recordId,
          currentUserRoleId,
          userId,
          moduleType,
          moduleName,
        },
      })
    );
  postApprovalWorkflowInfo = async (
    roleId,
    formState,
    formSateStepsBody,
    flag,
    formID,
    stepsDeletedForm
  ) =>
    this.responsify(
      await this.instance.post(
        '',
        { formSateStepsBody, formState },
        {
          params: {
            roleId,
            flag: flag,
            formID: formID,
            stepsDeletedForm: stepsDeletedForm,
          },
        }
      )
    );

  approveOrRejectApprovalWorflow = async (
    recordId,
    moduleId,
    action,
    remarks,
    reasons,
    refId,
    currentUserRoleId,
    dataForApproval,
    claimRaisingFor
  ) =>
    this.responsify(
      await this.instance.post('approver-or-reject', {
        recordId,
        moduleId,
        action,
        remarks,
        reasons,
        refId,
        currentUserRoleId,
        dataForApproval,
        claimRaisingFor
      })
    );

  deleteDraftClaims = async (recordId, moduleId, tableName) =>
    this.responsify(
      await this.instance.post('delete_draft_claims', {
        recordId,
        moduleId,
        tableName,
      })
    );
}
