import React from 'react';
import { Box, Typography, styled } from '@mui/material';

export default function StatusLabel(props) {
  const { value, type, strickedDown = false } = props;
  const color =
    type == 'approved' || type == 'payment processed'
      ? 'primary.main'
      : type == 'rejected'
      ? 'accentSecondary.main'
      : 'neutral.dark80';
  return (
    <Box
      px={1}
      py={'4px'}
      bgcolor={'neutral.light80'}
      borderRadius={2}
      display={'inline-block'}
    >
      {strickedDown ? (
        <s>
          <Typography
            variant="button"
            color={color}
          >
            {value}
          </Typography>
        </s>
      ) : (
        <Typography
          variant="button"
          color={color}
        >
          {value}
        </Typography>
      )}
    </Box>
  );
}
