import { ArrowBackIosNew, NavigateNext } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import StyledDrawer from './StyledDrawer.component';

const Drawer = ({children}) => {

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
    

    const toggleDrawer = (anchor, open) => (event) => {
        if (
          event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });       
      };

  return (
    <div>
    {['right'].map((anchor) => (
      <React.Fragment key={anchor}>
        <Box
          sx={{
            position: 'fixed',
            right: state[anchor] ? '330px' : '100px',
            top: '180px',
            backgroundColor: 'neutral.light80',
            borderRadius: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 40,
            width: 40,
            minWidth: 0,
            zIndex:  (theme) => theme.zIndex.modal - 1,
            cursor: 'pointer',
          }}
        >
          {state[anchor] ? (
            <NavigateNext
              onClick={toggleDrawer(anchor, false)}
              sx={{ color: 'neutral.dark80', height: 36, width: 36 }}
            />
          ) : (
            <ArrowBackIosNew
              onClick={toggleDrawer(anchor, true)}
              sx={{
                color: 'neutral.dark80',
                height: 24,
                width: 24,
                position: 'relative',
                zIndex: (theme) => theme.zIndex.modal - 1,
              }}
            />
          )}
        </Box>
        {!state[anchor] && <Box
        position={'fixed'}
        top={75}
        right={'0px'}
        bottom={0}
        bgcolor={'neutral.dark80'}
        px={1}
        py={5}
        width={100}
        textAlign={'center'}
        zIndex={100}
      ></Box>}
        <StyledDrawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          {children}
        </StyledDrawer>
      </React.Fragment>
    ))}
  </div>
  );
};

export default Drawer;