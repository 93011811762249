import React from 'react';
import './calculator.css';

const CalculatorButton = (props) => {
  const { digit, handleDigitClick, borderRadius } = props;
  return (
    <button className='ve-calc-btn'
      onClick={() => handleDigitClick(digit)}
      style={{
        padding: '5px 5px',
        border: '1px solid #ccc',
        fontSize: '13px',
        background: 'white',
        borderRadius: borderRadius
      }}
    >
      {digit}
    </button>
  );
};

export default CalculatorButton;
