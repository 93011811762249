import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, styled } from '@mui/material';
import ListView from '../../../atoms/DataList/ListView';
import { documentsHeadCells } from '../Organization/utils/userOnboarding/userOnboarding.util';
import FileUPload from '../../../atoms/FileUpload/FileUPload';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useState } from 'react';
import { LoadingStatus, ToastThemes, toastMessage } from '../../../constants/common';
import { fileUpload } from '../../../redux/main/action';
import { showToast } from '../../../utils/common.util';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import { FormFieldNames, ToastMessages, stringSubstitute } from '../Organization/constants/onboarding.constants';
import { updateDocList } from '../../../redux/userOnboarding/action';

export default function UserDocumentsList(props) {

    const { viewMode, isEditDisabled, setFileRes, fileRes, setFormData } = props;

    const dispatch = useDispatch();
    const { userDocumentsList } = useSelector((state) => state.userOnboarding);
    const [fileLoading, setFileLoading] = useState(-1);
    const [currentVal, setCurrentVal] = useState([]);

    const handleFileChange = (event, index, docId) => {
        const key = event.target.name;
        const val = event.target.files[0];
        setFileLoading(LoadingStatus.Loading);
        dispatch(fileUpload(val)).then((res) => {
            const updatedVal = [...currentVal, { documentType: docId, documentId: res?.id }];
            setFileRes({ ...fileRes, [index]: res });
            setFileLoading(LoadingStatus.Success);
            setCurrentVal(updatedVal);
            showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.upload), ToastThemes.success);
            setFormData((prev) => ({
                ...prev,
                [key]: updatedVal
            }));
        dispatch(updateDocList({res, index}));
        }).catch((err) => {
            showToast(err, ToastThemes.error);
            setFileLoading(LoadingStatus.Failure);
        });
    };
   
    const documentRowData = userDocumentsList?.documentsMapped?.map((item, index) => {
        const { id, createdAt, documentId, documentType: { name: name, id: docId } } = item;
        return {
            name: name || '',
            upload: <FileUPload
                placeholder={id ? 'Document uploaded: to change, re-upload (PNG/JPEG/PDF)' : 'Upload (PNG/JPEG/PDF) '}
                onUpload={(event) => handleFileChange(event, index, docId)}
                name= {FormFieldNames.Documents}
                fileRes={fileRes?.[index]}
                disabled={viewMode || isEditDisabled}
            />,
            uploadedOn: createdAt ? createdAt : '--',
            download: <a href={documentId?.url} download={documentId?.fileName}>
                <StyledButton
                    variant='outlined'
                    size='medium'
                    disabled={documentId?.url ? false : true}
                    endIcon={<FileDownloadOutlinedIcon sx={{ color: documentId?.url ? 'neutral.dark80' : 'neutral.dark40' }} />}>
                    Download
                </StyledButton></a>
        };
    });
    return (
        <>
            <CircularLoader show={fileLoading === LoadingStatus.Loading} />
            <ListView headCells={documentsHeadCells} rowsData={documentRowData} showSerialNo={true} />
        </>
    );
}

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.neutral.dark40,
    borderColor: theme.palette.neutral.dark60,
    textTransform: 'capitalize',
    px: 2,
    '&:hover': {
        borderColor: theme.palette.neutral.light40,
    },
}));