import { Box, Select, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import SelectTag from '../Select/SelectTag';
import { LightTooltip } from '../Tooltip/LightTooltip';
import { InfoOutlined } from '@mui/icons-material';
import FieldView from '../../components/molecules/FieldView/FieldView';

const RatingInput = (props) => {
  const { name, value, onChange, label, precision = 1,
    size = 'large', max = 5, disabled, isReadOnly, align = 'horizontal', marginTop = 0,
    infoIcon
  } = props;

  const dropdownList = useMemo(() => {
    const list = [];
    for (let i = 1; i <= max; i++) {
      list.push({ id: i, name: i });
    }
    return list;
  }, [max]);
  if (isReadOnly) {
    return (
      <Box display={align === 'horizontal' ? 'flex' : 'block'} justifyContent={'space-between'} alignItems='center' marginTop={marginTop}>
        {label && (
          <Typography variant='body2' color={'neutral.dark60'}>
            {label}
          </Typography>
        )}
        {value && (
          <Typography variant='body2' color={'neutral.dark100'} mt={1} >
            {value}
          </Typography>
        )}
      </Box>
    );
  }
  return (
    <Box display={align === 'horizontal' ? 'flex' : 'block'} justifyContent={'space-between'} alignItems='center' marginTop={marginTop}>
      <Typography mb={align !== 'horizontal' && 1}>{label}
        {infoIcon && (
          <LightTooltip title={infoIcon} placement='top' arrow>
            <Box color='primary.main' sx={{ display: 'inline-block' }}>
              <InfoOutlined
                sx={{ width: 14, height: 14, ml: '4px', cursor: 'pointer' }}
              />
            </Box>
          </LightTooltip>
        )}</Typography>
      <SelectTag
        name={name}
        dropDownList={dropdownList}
        onchange={onChange}
        value={value}
        widthAuto
        defaultValue={value}
        size='small' />
    </Box>
  );
};

export default RatingInput;