import React, { useEffect, useState } from 'react';
import Builder from '../builder.component';
import { APIRequestStatus, LoadingStatus } from '../../../constants/common';
import TryAgain from '../../molecules/ErrorState/error.component';

const AddForm = (props) => {
  const {
    loading,
    list,
    claimData,
    idForFormDetails,
    formInfo,
    setFormInfo,
    handleFormSaveClick,
  } = props;

  if (loading === LoadingStatus.Failure) {
    return <TryAgain />;
  }

  useEffect(() => {
    setFormInfo(formInfo);
  }, [formInfo]);

  return (
    <>
      <Builder
        list={list || []}
        claimData={claimData || {}}
        idForFormDetails={idForFormDetails}
        formInfo={formInfo}
        setFormInfo={setFormInfo}
        handleFormSaveClick={handleFormSaveClick}
      />
    </>
  );
};

export default AddForm;
