import React, { useEffect, useState } from 'react';
import TabBar from '../../../atoms/Tabs/TabBar';
import { useDispatch, useSelector } from 'react-redux';
import { getClaimTab } from '../../../redux/newClaims/action';
import { Box, Divider, Grid } from '@mui/material';
import SubHeader from '../sub-header/sub-header.component';
import DynamicBreadcrumb from '../../../atoms/Breadcrumbs/dynamicBreadcrumb';
import NavTabBar from '../../../atoms/Tabs/nav-tab-bar';
import { useLocation, useNavigate } from 'react-router';
import NewClaimsAllDashBoard from './new-claims-all-dashboard.component';
import { LoadingStatus, ToastThemes } from '../../../constants/common';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import { showToast } from '../../../utils/common.util';
import TryAgain from '../../molecules/ErrorState/error.component';
import NewClaimRHS from '../new-claims-rhs/new-claims-rhs.component';

const ManageTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUserRoleId } = useSelector((state) => state.main);
  const { claimsTab = [] } = useSelector((state) => state.newClaims);
  const paths = location.pathname.split('/');
  const currentPath = paths[paths.length - 1];
  const [loading, setLoading] = useState(-1);
  const [showRightBar, setShowRightBar] = useState(true);

  useEffect(() => {
    if (currentUserRoleId && !claimsTab.length) {
      setLoading(LoadingStatus.Loading);
      dispatch(getClaimTab(currentUserRoleId))
        .then(() => {
          setLoading(LoadingStatus.Success);
        })
        .catch((err) => {
          showToast(err, ToastThemes.error);
          setLoading(LoadingStatus.Failure);
        });
    }
  }, [currentUserRoleId]);

  useEffect(() => {
    if (isNaN(currentPath) && claimsTab.length) {
      navigate(`/new-claims/${claimsTab[0]?.id}`);
    }
  }, [currentPath, claimsTab]);

  const tabsData = claimsTab.map((item, index) => {
    const { id, name } = item;
    return {
      id: id,
      name: name,
      path: `/new-claims/${id}`,
      label: name,
      title: name,
      show: false,
    };
  });

  if (loading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={loading === LoadingStatus.Loading} />
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={showRightBar ? 9 : 12}
          md={showRightBar ? 10 : 12}
          lg={showRightBar ? 11 : 12}
          xl={showRightBar ? 11 : 12}
        >
          <Box>
            <SubHeader tabsData={tabsData} />
            <Box my={3}>
              <DynamicBreadcrumb />
              <Box mt={1}>
                <Divider />
              </Box>
            </Box>
            <NavTabBar list={tabsData} searchWidth={150} tabWidth={150} />
            {!isNaN(currentPath) && (
              <NewClaimsAllDashBoard ctId={currentPath} claimsTab={claimsTab} />
            )}
          </Box>
        </Grid>
        {showRightBar && (
          <Grid item xs={12} sm={3} md={2} lg={1} xl={1}>
            <NewClaimRHS setShowRightBar={setShowRightBar} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ManageTab;
