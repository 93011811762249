import React, { useState } from 'react';
import ModalDialog from '../../../../atoms/Modal/ModalDialog';
import AssignGoalModal from '../GoalAssign/AssignGoalModal';
import DropDownAction from './DropDownAction';
import { useDispatch, useSelector } from 'react-redux';
import { GoalsServices } from '../../../../services/goals';
import { getAllGoalsList } from '../../../../redux/goals/action';
import { showToast } from '../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../OnBoarding/Organization/constants/onboarding.constants';
import {
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../../../constants/common';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import moment from 'moment';
import { DateRangeFields } from '../../../molecules/DateRange/DateRange';

const ModalMap = (props) => {
  const { checkedItems, searchInput, moduleId } = props;
  console.log(checkedItems);
  const [showModal, setshowModal] = useState(false);
  const [context, setContext] = useState('');
  const [userIds, setUserIds] = useState([]);
  const [goalAssignLoading, setGoalAssignLoading] = useState(-1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { currentUserRoleId } = useSelector((state) => state.main);
  const dispatch = useDispatch();

  const handleAssignTo = () => {
    setContext('assignTo');
    setshowModal(true);
  };

  const handleActivate = () => {
    setContext('activate');
    setshowModal(true);
  };

  const handleDeactivate = () => {
    setshowModal(true);
    setContext('deactivate');
  };

  const handleDelete = () => {
    setshowModal(true);
    setContext('delete');
  };

  const handleAssignToClick = () => {
    new GoalsServices()
      .postGoalsAssignInfo({
        userIds: userIds.map((item) => Number(item.id)),
        goals: checkedItems.map((item)=>Number(item?.id)),
      })
      .then(() => {
        dispatch(getAllGoalsList(currentUserRoleId, searchInput, moduleId));
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.update),
          ToastThemes.success
        );
        setGoalAssignLoading(LoadingStatus.Success);
      })
      .catch(() => {
        setGoalAssignLoading(LoadingStatus.Failure);
      });
  };

  const handleActivateConfirm = () => {
    new GoalsServices()
      .postGoalActivation({
        goals: checkedItems.map((item)=>Number(item?.id)),
        appraisalStartDate: moment(startDate).format(),
        appraisalEndDate: moment(endDate).format(),
        moduleId
      })
      .then(() => {
        dispatch(getAllGoalsList(currentUserRoleId, searchInput, moduleId));
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.update),
          ToastThemes.success
        );
        setStartDate(null);
        setEndDate(null);
      })
      .catch(() => {
        showToast(
          ToastMessages.failed.replace(
            stringSubstitute,
            toastMessage.errorupdate
          ),
          ToastThemes.error
        );
      });
  };

  const handleDeActivateConfirm = () => {
    new GoalsServices()
      .postGoalDeActivation({ goals: checkedItems.map((item)=>Number(item?.id)), moduleId})
      .then(() => {
        dispatch(getAllGoalsList(currentUserRoleId, searchInput, moduleId));
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.update),
          ToastThemes.success
        );
        setStartDate(null);
        setEndDate(null);
      })
      .catch(() => {
        showToast(
          ToastMessages.failed.replace(
            stringSubstitute,
            toastMessage.errorupdate
          ),
          ToastThemes.error
        );
      });
  };

  const modalMap = {
    assignTo: {
      title: 'Goal Assign To',
      listComponent: <AssignGoalModal setUserIds={setUserIds} />,
      primaryAction: 'Assign',
      onClickConfirm: () => handleAssignToClick(),
      severity: 'success',
      maxWidth: 'md',
      fullWidth: true,
    },
    activate: {
      title: 'Activate Goal For Appraisal',
      listComponent: (
        <DateRangeFields
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      ),
      primaryAction: 'Activate',
      onClickConfirm: () => handleActivateConfirm(),
      severity: 'success',
      maxWidth: 'md',
      fullWidth: true,
    },
    deactivate: {
      title: 'Deactivate',
      listComponent: 'Are you sure you want to Deactivate?',
      primaryAction: 'Deactivate',
      onClickConfirm: () => handleDeActivateConfirm(),
      severity: 'error',
      primaryButtonDisabled: false,
    },
    delete: {
      title: 'Delete',
      // listComponent: 'Are you sure you want to Delete Department?',
      primaryAction: 'Delete',
      //   onClickConfirm: () => dispatch(deleteDepartment(currentUserRoleId, id, configureIndex)),
      severity: 'error',
      primaryButtonDisabled: true,
    },
  };
  return (
    <>
      <CircularLoader show={goalAssignLoading === LoadingStatus.Loading} />
      <DropDownAction
        handleAssignTo={handleAssignTo}
        handleActivate={handleActivate}
        handleDeactivate={handleDeactivate}
        handleDelete={handleDelete}
        checkedItems={checkedItems}
      />
      <ModalDialog
        severity={modalMap[context]?.severity}
        title={modalMap[context]?.title}
        listComponent={modalMap[context]?.listComponent}
        primaryAction={modalMap[context]?.primaryAction}
        secondaryAction="Cancel"
        showModal={showModal}
        onClickClose={() => setshowModal(false)}
        onClickConfirm={modalMap[context]?.onClickConfirm}
        maxWidth={modalMap[context]?.maxWidth}
        fullWidth={modalMap[context]?.fullWidth}
        primaryButtonDisabled={modalMap[context]?.primaryButtonDisabled}
      />
    </>
  );
};

export default ModalMap;
