import React, { useEffect, useMemo, useState } from 'react';
import FormEdit from '../../../../common/Form/FormEdit';
import { Box } from '@mui/system';
import ActionHeader from '../../../ActionHeader/ActionHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMandatoryFields,
  getValidationErrors,
} from '../../../utils/userOnboarding/userOnboarding.util';
import { isEmpty } from 'lodash';
import {
  getSalaryStructureConfig,
  postSalaryStructureInfo,
} from '../../../../../../redux/salaryStructure/action';
import { showToast } from '../../../../../../utils/common.util';
import {
  NewPayStructure,
  PayStrFormField,
  ToastMessages,
  stringSubstitute,
} from '../../../constants/onboarding.constants';
import {
  FormFieldTypes,
  ToastThemes,
  toastMessage,
} from '../../../../../../constants/common';
import { SalaryStructureServices } from '../../../../../../services/salaryServices';
import { useSearchParams } from 'react-router-dom';
import useDidMountEffect from '../../../../../../customHooks/useDidMountEffect';
import ModalDialog from '../../../../../../atoms/Modal/ModalDialog';
import { currentDate, firstDayOfMonth } from '../../../../../../utils/formatter';

const PayStrEditForm = (props) => {
  const {
    setViewMode,
    formConfig,
    isNew,
    salaryStructureFormData,
    setShowForm,
  } = props;
  const dispatch = useDispatch();
  const { currentUserRoleId } = useSelector((state) => state.main);
  const [searchParams] = useSearchParams();

  const [validationErrors, setValidationErrors] = useState({});
  const [formInfo, setFormInfo] = useState(salaryStructureFormData);

  const [libraryOptions, setLibraryOptions] = useState([]);
  const [baseOption, setBaseOption] = useState([]);
  const [additionComponentOption, setAdditionComponentOption] = useState([]);
  const [deductionComponentOption, setDeductionComponentOption] = useState([]);
  const [disabledSave, setDisabledSave] = useState(true);
  const [showModal, setshowModal] = useState(false);

  useEffect(() => {
    setFormInfo(salaryStructureFormData);
  }, [salaryStructureFormData]);

  useDidMountEffect(() => {
    setDisabledSave(false);
  }, [formInfo]);

  const handleDiscard = () => {
    if (isNew) {
      setShowForm(false);
    } else {
      setViewMode(true);
    }
  };

  const handleDropdownClick = (name) => {
    switch (name) {
      case PayStrFormField.Library: {
        return new SalaryStructureServices()
          .getAllSalaryStructureList(
            currentUserRoleId,
            searchParams.get('q'),
            true
          )
          .then((res) => {
            setLibraryOptions(res);
            return true;
          });
      }
      case PayStrFormField.Base: {
        return new SalaryStructureServices()
          .getDropdownOptions(name)
          .then((res) => {
            setBaseOption(res);
            return true;
          });
      }
      case PayStrFormField.Addition: {
        return new SalaryStructureServices()
          .getAdditionDeductionDropdownOptions()
          .then((res) => {
            setAdditionComponentOption(res.additionComponents);
            return true;
          });
      }
      case PayStrFormField.Deducation: {
        return new SalaryStructureServices()
          .getAdditionDeductionDropdownOptions()
          .then((res) => {
            setDeductionComponentOption(res.deductionComponents);
            return true;
          });
      }
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case PayStrFormField.Library: {
        return libraryOptions;
      }
      case PayStrFormField.Base: {
        return baseOption;
      }
      case PayStrFormField.Addition: {
        return additionComponentOption;
      }
      case PayStrFormField.Deducation: {
        return deductionComponentOption;
      }
    }
  };

  const handleChangeLibrary = (event) => {
    const value = event.target.value;
    const key = event.target.name;
    dispatch(
      getSalaryStructureConfig(currentUserRoleId, value.id, true, isNew)
    );
  };

  const formFields = useMemo(() => {
    return formConfig.reduce((acc, item) => {
      const { fieldRefName } = item;
      let temp = { ...item };
      switch (fieldRefName) {
        case PayStrFormField.Library: {
          temp = {
            ...temp,
            onChange: handleChangeLibrary,
            isDisabled: !!salaryStructureFormData.id,
          };
          break;
        }
        default: {
          break;
        }
      }
      acc.push(temp);
      return acc;
    }, []);
  }, [formConfig]);

  const requiredFields = useMemo(() => {
    return getMandatoryFields(formFields);
  }, [formFields]);
  const handleSave = () => {
    const errors = getValidationErrors(formInfo, requiredFields);
    if (isEmpty(errors)) {
      dispatch(postSalaryStructureInfo(currentUserRoleId, formInfo))
        .then((res) => {
          showToast(
            ToastMessages.success.replace(
              stringSubstitute,
              toastMessage.success
            ),
            ToastThemes.success
          );
          setViewMode(true);
        })
        .catch((err) => {
          showToast(err, ToastThemes.error);
        });
    } else {
      setValidationErrors(errors);
    }
  };

  const handleFormSaveClick = () => {
    setshowModal(true);
    setFormInfo((prev)=>({
      ...prev, effectiveDate: firstDayOfMonth
    }));
  };

  return (
    <Box>
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={formInfo?.[PayStrFormField.Name] || NewPayStructure}
          showDiscard
          showPublish
          showPublishModal={false}
          publishButtonText="Save"
          onPublishClick={handleFormSaveClick}
          onClickDiscard={handleDiscard}
          showSave={false}
          disabledPublish={disabledSave}
        />
      </Box>
      <FormEdit
        list={formFields}
        formInfo={formInfo}
        setFormData={setFormInfo}
        errors={validationErrors}
        setErrors={setValidationErrors}
        handleDropdownClick={handleDropdownClick}
        getDropdownOptions={getDropdownOptions}
      />
      <ModalDialog
        title="Effective Date"
        description={
          <FormEdit
            list={[
              {
                fieldLabel: 'Effective Date',
                placeholderText: 'dd/mm/yyyy',
                fieldType: FormFieldTypes.Date,
                isRequired: false,
                fieldRefName: 'effectiveDate',
                fieldSize: 13,
                minDate: currentDate,
                defaultValue: firstDayOfMonth,
                tooltipText: 'Updated values will be effective from next pay cycle'
              },
            ]}
            padding={0}
            setFormData={setFormInfo}
          />
        }
        primaryAction="Save"
        secondaryAction="Cancel"
        showModal={showModal}
        onClickClose={() => setshowModal(false)}
        onClickConfirm={() => handleSave()}
        severity="success"
        maxWidth="lg"
        fullWidth
      />
    </Box>
  );
};

export default PayStrEditForm;
