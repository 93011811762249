
import { getFormBody } from '../../components/OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { CandidateServices } from '../../services/candidateServices';
import { GET_ALL_CANDIDATE_LIST_FAILURE, GET_ALL_CANDIDATE_LIST_REQUEST, GET_ALL_CANDIDATE_LIST_SUCCESS, GET_CANDIDATE_CONFIG_FAILURE, GET_CANDIDATE_CONFIG_REQUEST, GET_CANDIDATE_CONFIG_SUCCESS, POST_CANDIDATE_INFO_FAILURE, POST_CANDIDATE_INFO_REQUEST, POST_CANDIDATE_INFO_SUCCESS, RESET_CANDIDATE, POST_CANDIDATE_DRAFT_REQUEST, POST_CANDIDATE_DRAFT_SUCCESS, POST_CANDIDATE_DRAFT_FAILURE, GET_CANDIDATE_ASSESSMENT_REQUEST, GET_CANDIDATE_ASSESSMENT_SUCCESS, GET_CANDIDATE_ASSESSMENT_FALIURE } from './actionTypes';


const getCandidateFormConfigRequest = () => (
  {
    type: GET_CANDIDATE_CONFIG_REQUEST
  }
);

const getCandidateFormConfigSuccess = (payload) => (
  {
    type: GET_CANDIDATE_CONFIG_SUCCESS,
    payload
  }
);

const getCandidateFormConfigFailure = () => (
  {
    type: GET_CANDIDATE_CONFIG_FAILURE
  }
);

const postCandidateInfoRequest = () => (
  {
    type: POST_CANDIDATE_INFO_REQUEST
  }
);

const postCandidateInfoSuccess = (payload) => (
  {
    type: POST_CANDIDATE_INFO_SUCCESS,
    payload
  }
);

const postCandidateInfoFailure = () => (
  {
    type: POST_CANDIDATE_INFO_FAILURE
  }
);

const getAllCandidateListRequest = () => (
  {
    type: GET_ALL_CANDIDATE_LIST_REQUEST
  }
);

const getAllCandidateListSuccess = (payload) => (
  {
    type: GET_ALL_CANDIDATE_LIST_SUCCESS,
    payload
  }
);

const getAllCandidateListFailure = () => (
  {
    type: GET_ALL_CANDIDATE_LIST_FAILURE
  }
);

export const resetCandidate = () => (
  {
    type: RESET_CANDIDATE
  }
);


const postDraftRequest = () => (
  {
    type: POST_CANDIDATE_DRAFT_REQUEST
  }
);

const postDraftSuccess = (payload) => (
  {
    type: POST_CANDIDATE_DRAFT_SUCCESS,
    payload
  }
);

const postDraftFailure = () => (
  {
    type: POST_CANDIDATE_DRAFT_FAILURE
  }
);

const getCandidateAssessmentRequest = () => (
  {
    type: GET_CANDIDATE_ASSESSMENT_REQUEST
  }
);

const getCandidateAssessmentSuccess = (payload) => (
  {
    type: GET_CANDIDATE_ASSESSMENT_SUCCESS,
    payload
  }
);

const getCandidateAssessmentFailure = () => (
  {
    type: GET_CANDIDATE_ASSESSMENT_FALIURE
  }
);

export const getCandidateConfig = (currentUserRoleId,candidateId) => async (dispatch) => {
  dispatch(getCandidateFormConfigRequest());
  new CandidateServices().getCandidateDetails(currentUserRoleId, candidateId).then((res) => {
    dispatch(getCandidateFormConfigSuccess(res));
  }).catch(() => {
    dispatch(getCandidateFormConfigFailure());
  });
};

export const postCandidateInfo = (candidateFormInfo) => async (dispatch) => {
  dispatch(postCandidateInfoRequest());
  const candidateFormData = getFormBody(candidateFormInfo);
  return new CandidateServices().postCandidateInfo(candidateFormData).then((res) => {
    dispatch(postCandidateInfoSuccess({ candidateFormInfo, id: res.id }));
    return res;
  }).catch((err) => {
    dispatch(postCandidateInfoFailure());
    throw err?.response?.data?.message || 'Error Saving';
  });
};

export const getAllCandidateList = (currentUserRoleId, searchInput, startDate, endDate, title) => async (dispatch) => {
  dispatch(getAllCandidateListRequest());
  new CandidateServices().getAllCandidateList(currentUserRoleId, searchInput, startDate, endDate, title).then((res) => {
    dispatch(getAllCandidateListSuccess(res));
  }).catch((err) => {
    dispatch(getAllCandidateListFailure());
  });
};

export const postCandidateInfoDraft = (candidateFormInfo) => async (dispatch) => {
  dispatch(postDraftRequest());
  const candidateFormData = getFormBody(candidateFormInfo);
  return new CandidateServices().postCandidateInfoDraft(candidateFormData).then((res) => {
    dispatch(postDraftSuccess({ candidateFormInfo, id: res.id }));
    return res;
  }).catch((err) => {
    dispatch(postDraftFailure());
    throw err?.response?.data?.message || 'Error Saving';
  });
};

export const getCandidateAssessment = (candidateJobId) => async (dispatch) => {
  dispatch(getCandidateAssessmentRequest());
  new CandidateServices().getCandidateAssessment(candidateJobId).then((res) => {
    dispatch(getCandidateAssessmentSuccess(res));
  }).catch(() => {
    dispatch(getCandidateAssessmentFailure());
  });
};

