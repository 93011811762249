import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import NavTabBar from '../../../atoms/Tabs/nav-tab-bar';
import { Paper } from '@mui/material';
import { getOrgOnboardingTabs } from './utils/onboarding.util';

export default function Organization() {

  const { permissions } = useSelector((state) => state.onBoarding);
  const onboardingTabs = getOrgOnboardingTabs(permissions).filter((item) => item.show);
  return (<>
    <NavTabBar list={onboardingTabs} />
    <Paper sx={{ p: 3 }}>
      <Outlet />
    </Paper>
  </>
  );
}
