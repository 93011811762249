import { useState } from 'react';
import API from './api';
import { saveAs } from 'file-saver';
import { StatusCodes } from 'http-status-codes';
import JSZip from 'jszip';
export class DownloadAPIResponse extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL;

  constructor() {
    super(DownloadAPIResponse.baseURL);
  }
  validImport = async (docId) => {
    try {
      await this.instance.get('/time-management/validate-import', {
        params: { docId },
        responseType: 'blob',
      });
    } catch (error) {
      if (error?.response?.status === StatusCodes.NOT_ACCEPTABLE) {
        const contentDisposition =
          error?.response.headers['content-disposition'];
        const filename = contentDisposition
          ? contentDisposition.split('filename=')[1]
          : 'error-log.csv';
        saveAs(error?.response.data, filename);
        throw 'Uploaded file has errors! Please check the downloaded report';
      } else {
        throw error?.response?.data?.message || 'Error check template';
      }
    }
  };

  paySlipDownload = async (roleId, payrollId, empId, period) => {
    try {
      const response = await this.instance.get('/payroll/download-payslip', {
        params: { roleId, payrollId },
        responseType: 'blob',
      });
      const contentDisposition = response.headers['content-disposition'];
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : `PaySlip _${empId}_${period}.pdf`;
      saveAs(response.data, filename);
    } catch (error) {
      throw error?.response?.data?.message || 'Error while download';
    }
  };
}
