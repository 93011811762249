import React, { useEffect, useState } from 'react';
import { LoadingStatus, ToastThemes } from '../../../../../constants/common';
import TryAgain from '../../../../molecules/ErrorState/error.component';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import IntegrationEditForm from './IntegrationFormEdit/IntegrationEditForm';
import { IntegrationService } from '../../../../../services/externalIntegration';
import { showToast } from '../../../../../utils/common.util';

const AddIntegration = (props) => {
  const { integrationId, setShowForm, integrationList } = props;
  const [loading, setLoading] = useState(-1);
  const [formData, setFormData] = useState({});

  useEffect(async () => {
    if (integrationId) {
      try {
        setLoading(LoadingStatus.Loading);
        const response = await new IntegrationService().getDetails(
          integrationId
        );
        setFormData(response);
        setLoading(LoadingStatus.Success);
      } catch (err) {
        setLoading(LoadingStatus.Failure);
        showToast(err?.response?.data?.message, ToastThemes.error);
        throw err?.response?.data?.message;
      }
    }
  }, [integrationId]);
  if (loading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={loading === LoadingStatus.Loading} />
      <IntegrationEditForm
        formData={formData}
        setShowForm={setShowForm}
        integrationId={integrationId}
        integrationList={integrationList}
      />
    </>
  );
};

export default AddIntegration;
