import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import AutocompleteCheckbox from '../../../../../atoms/Autocomplete/Checkboxes';
import { CandidateServices } from '../../../../../services/candidateServices';

export default function AssignCandidate(props) {
  const {setIds} = props;

  const [option, setOption] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClickAddJob = () => {
    if (!option.length) {
      new CandidateServices().getAllJobList().then((res) => {
        setOption(res);
        setOpen(true);
      });
    }
    setOpen(true);
  };

  return (
    <Box minWidth={420}>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item md={12} pt={2}>
          <AutocompleteCheckbox
            optionList={option}
            fieldLabel='Active Jobs'
            placeholder='Please Select'
            required={false}
            handleDropDownClick={handleClickAddJob}
            open={open}
            setOpen={setOpen}
            setIds={setIds}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
