import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Box, TextField, Typography } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import { getTime } from '../../utils/formatter';
import { LightTooltip } from '../Tooltip/LightTooltip';
import { InfoOutlined } from '@mui/icons-material';
import FieldView from '../../components/molecules/FieldView/FieldView';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';


const TimeField = (props) => {
  const {
    id,
    defaultValue,
    placeholder,
    labelText,
    size,
    hiddenLabel,
    fullWidth,
    required,
    onChange,
    name,
    value,
    errorMessage,
    disabled,
    isReadOnly,
    infoIcon,
    maxDate,
    minDate,
    disableFuture,
    disablePast,
  } = props;

  if (isReadOnly) {
    return (
      <FieldView
        labelText={labelText}
        labelValue={value ? moment(value, 'HH:mm').format('hh:mm a') : ''}
      />
    );
  }

  return (
    <>
      {labelText && (
        <Typography
          variant="body2"
          color={`${disabled ? 'neutral.light40' : 'neutral.dark80'}`}
          mb={1}
        >
          {labelText}
          {required && (
            <Box color="accentSecondary.main" sx={{ display: 'inline-block' }}>
              *
            </Box>
          )}
          {infoIcon && (
            <LightTooltip title={infoIcon} placement="top" arrow>
              <Box color="primary.main" sx={{ display: 'inline-block' }}>
                <InfoOutlined
                  sx={{ width: 14, height: 14, ml: '4px', cursor: 'pointer' }}
                />
              </Box>
            </LightTooltip>
          )}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
        <TimePicker
          ampm={true}
          format="hh:mm a"
          value={value ? getTime(value) : null}
          onChange={(value) => onChange(value, name)}
          disabled={disabled}
          maxTime={maxDate}
          minTime={minDate}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          disableFuture={disableFuture}
          disablePast={disablePast}
          slotProps={{
            textField: {
              fullWidth: fullWidth,
              size: 'small',
              variant: 'outlined',
              error: errorMessage ?? '',
              helperText: errorMessage,
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
};

export default TimeField;
