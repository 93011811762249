import { Button } from '@mui/material';
import React from 'react';

const DownloadButton = (props) => {
  const { lhsActionButton, href, fileName } = props;
  return (
    <a href={href} download={fileName} style={{textDecoration: 'none'}}>
      <Button
        variant="contained"
        sx={{
          textTransform: 'capitalize',
          boxShadow: 'none',
          px: 2,
          ml: 1,
          mt: 1
        }}
      >
        {lhsActionButton}
      </Button>
    </a>
  );
};

export default DownloadButton;