import API from './api';
export class EntitlementsService extends API {
  static baseURL =
    API.env.REACT_APP_BASE_API_LOCAL + '/enitlement-benefits/' || '';
  constructor() {
    super(EntitlementsService.baseURL);
  }

  revertEntitlements = async (status,recordId,moduleId) => (
    this.responsify(await this.instance.post('revert', {status,recordId,moduleId}))
  );

  getUserEntitlement = async (benefitId,userId, deptId) => (
    this.responsify(await this.instance.get('get', {params:{benefitId,userId, deptId}}))
  );

  updateUserEntitlement = async (entitlementId, addAmount, deductionAmount) => (
    this.responsify(await this.instance.put('update', {entitlementId,addAmount,deductionAmount}))
  );
  saveBenfitsRole = async (benefits,roleId) => (
    this.responsify(await this.instance.post('save-benefits-role', {benefits,roleId}))
  );

  saveBenfitsDasboardRole = async (benefits,roleId) => (
    this.responsify(await this.instance.post('save-benefits-role-dashboard', {benefits,roleId}))
  );

  getBenfitsRole = async (roleId, forBenefitsDashboard) => (
    this.responsify(await this.instance.get('benefits-role', {params:{roleId, forBenefitsDashboard}}))
  );
}
