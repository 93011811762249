import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AllForms from './all-form-dashboard.component';
import TabBar from '../../../atoms/Tabs/TabBar';

const ManageFormDashboard = (props) => {
    const { currentTab = 0 } = props;
	const navigate = useNavigate();

	const dataTabs = [
		{
			name: 'builder',
			label: 'Builder',
			title: 'Builder',
			hide: false,
			panel: <Box mx={-3}><AllForms /> </Box>,
		},
	];

	const handleChange = (tabIndex) => {
		navigate(`/${dataTabs[tabIndex].name}`);
	};
	const onSearchChange = (e, tabIndex) => {
		navigate(`/${tabIndex.name}`);
	};

	return (
		<Box>
			<TabBar
				tabPanels={dataTabs}
				searchTabs
				disableCreate
				currentTab={currentTab}
				onTabChange={handleChange}
				onSearchChange={onSearchChange}
			/>
		</Box>
	);
};

export default ManageFormDashboard;