import API from './api';
export class TimeManagementServices extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL + '/time-management/';
  constructor() {
    super(TimeManagementServices.baseURL);
  }

  getAllTimeManagement = async (roleId, searchQuery, startDate, endDate, employeeId) => (
    this.responsify(await this.instance.get('list-attendance', {
      params:
        { roleId, searchQuery, startDate, endDate, employeeId }
    })));

  postImportData = async (importDocId) => (
    this.responsify(await this.instance.post('import-details', importDocId)));

  updateStatus = async (info, roleId) => (
    this.responsify(await this.instance.put('update-status', info, { params: { roleId } })));
}


