import moment from 'moment';
import TimePicker from 'rc-time-picker';
import React, { useState } from 'react';

export default function TimePickerComponent({ selectedDate, all_hours, ...props }) {
  const [selectedTime, setSelectedTime] = useState(moment());
  const TodayDate = moment().format('DD-MM-YYYY');
  const SelectedDate = moment(selectedDate ? selectedDate : new Date()).format('DD-MM-YYYY');
  const disabledHours = () => {
    const hours = [];
    if (all_hours) {
      return hours;
    }
    if (SelectedDate !== TodayDate) {
      return hours;
    }
    const currentHour = moment().hour();

    for (let i = currentHour + 1; i <= 24; i++) {
      hours.push(i);
    }

    return hours;
  };

  const disabledMinutes = (selectedHour) => {
    const minutes = [];
    if (all_hours) {
      return minutes;
    }
    if (SelectedDate === TodayDate) {
      const currentMinute = moment().minute();
      if (selectedHour === moment().hour()) {
        for (let i = currentMinute + 1; i <= 60; i++) {
          minutes.push(i);
        }
      }
    }
    // if(props.inputs){
    //   props.inputs.forEach(input=>{
    //     const DepartureTime = input['departureTime'];
    //     if(DepartureTime && moment(DepartureTime).hour() === selectedHour){

    //       // departure time 
    //       const departureTime = moment(DepartureTime);
    //       const startDepartureTime = moment(DepartureTime).subtract(10,'minutes');
    //       const endDepartureTime = moment(DepartureTime).add(10,'minutes');



    //     }
    //   });
    // }
    return minutes;
  };

  const onSelect = (time) => {
    if (time.isAfter(moment())) {
      console.log('ping');
      setSelectedTime(moment());
      return;
    }

    setSelectedTime(time);
  };

  return (
    <TimePicker
      // onSelect={onSelect}
      disabledHours={disabledHours}
      disabledMinutes={disabledMinutes}
      format="h:mm a"
      value={selectedTime}
      use12Hours={true}
      {...props}
    />
  );
}
