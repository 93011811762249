import React from 'react';
import SidebarItem from './sidebar-item/sidebar-item.component';
import { AdditionFields } from '../form-builder-config';
import { Box, List } from '@mui/material';
import Drawer from '../../../atoms/Drawer/Drawer.component';

const BuilderSidebar = (props) => {
  return (
    <>
      <Drawer>
        <Box sx={{ width: 350 }}>
          <List
            sx={{
              maxWidth: 360,
              mt: 2,
              bgcolor: 'transparent',
              display: 'flex',
              flexDirection: 'column',
              padding: '0 40px',
              textAlign: 'left'
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {AdditionFields.map((item, index) => {
              const { fieldLabel, fieldType } = item;
              return (
                <SidebarItem
                  key={index}
                  label={fieldLabel}
                  fieldType={fieldType}
                />
              );
            })}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default BuilderSidebar;
