import React from 'react';
import BtnDropDown from '../../../../atoms/Buttons/BtnDropDown';
import {
	CheckCircleOutline,
	FileUploadOutlined,
	SwipeLeftOutlined,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import {
	AccessAllTime,
	AllowImport,
	MyAttendence,
	TimeManagement,
	TimeStatus,
} from '../../Constant/Constant';
import { permissionsObj } from '../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';

const DropDownActionForTime = (props) => {
	const { checkedItems, handleApprove, handleReject, handleImport } = props;
	const { timeManagementPermission } = useSelector(
		(state) => state.modulePermissions
	);
	const { timeIdPropertiesMap } = useSelector((state) => state.timeManagement);

	const empApproveDisabled = !!checkedItems.find(
		(item) =>
			timeIdPropertiesMap?.[item.id]?.empStatusCode === TimeStatus.Approved ||
			timeIdPropertiesMap?.[item.id]?.empStatusCode === TimeStatus.Rejected
	);
	const mgrApproveDisabled = !!checkedItems.find(
		(item) =>
			timeIdPropertiesMap?.[item.id]?.mgrStatusCode === TimeStatus.Approved ||
			timeIdPropertiesMap?.[item.id]?.mgrStatusCode === TimeStatus.Rejected
	);
	const hrApproveRejectDisabled = !!checkedItems.find(
		(item) =>
			timeIdPropertiesMap?.[item.id]?.hrStatusCode === TimeStatus.Approved ||
			timeIdPropertiesMap?.[item.id]?.hrStatusCode === TimeStatus.Rejected
	);

	const tmtActionBtndisbaled = () => {
		switch (true) {
			case timeManagementPermission?.[AccessAllTime]?.[permissionsObj.allowView] && hrApproveRejectDisabled:
				return true;
			case timeManagementPermission?.[TimeManagement]?.[permissionsObj.allowView] && mgrApproveDisabled:
				return true;
			case timeManagementPermission?.[MyAttendence]?.[permissionsObj.allowView] && empApproveDisabled:
				return true;
			default:
				return false;
		}
	};

	const timeBtnOption = [
		{
			id: 1,
			title: 'Approve',
			icon: <CheckCircleOutline sx={{ width: 20, height: 20 }} />,
			handleClick: handleApprove,
			hide: false,
			disabled: !checkedItems.length || tmtActionBtndisbaled()
		},
		{
			id: 2,
			title: 'Reject',
			icon: <SwipeLeftOutlined sx={{ width: 20, height: 20 }} />,
			handleClick: handleReject,
			hide: !timeManagementPermission?.[AccessAllTime]?.[
				permissionsObj.allowView
			],
			disabled: !checkedItems.length || tmtActionBtndisbaled(),
		},
		{
			id: 3,
			title: 'Import Pay Day Data',
			icon: <FileUploadOutlined sx={{ width: 20, height: 20 }} />,
			handleClick: handleImport,
			hide: !timeManagementPermission?.[AllowImport]?.[
				permissionsObj.allowView
			],
		},
	];
	return (
		<BtnDropDown
			BtnOptions={timeBtnOption.filter((item) => !item.hide)}
			selectedOption={0}
		/>
	);
};

export default DropDownActionForTime;
