import { isEmpty } from 'lodash';
import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import {
  GET_ALL_APPROVAL_WORKFLOW_LIST_REQUEST,
  GET_ALL_APPROVAL_WORKFLOW_LIST_SUCCESS,
  GET_ALL_APPROVAL_WORKFLOW_LIST_FAILURE,
  GET_APPROVAL_WORKFLOW_CONFIG_SUCCESS,
  GET_APPROVAL_WORKFLOW_CONFIG_FAILURE,
  GET_APPROVAL_WORKFLOW_CONFIG_REQUEST,
  POST_APPROVAL_WORKFLOW_INFO_FAILURE,
  POST_APPROVAL_WORKFLOW_INFO_SUCCESS,
  POST_APPROVAL_WORKFLOW_INFO_REQUEST,
  RESET_APPROVAL_WORKFLOW_STRUCTURE,
  ADD_STEPS_TAB,
  DELETE_STEPS_TAB,
  DUPLICATE_STEPS_TAB,
  UPDATE_STEPS_TAB,
  RESET_APPROVAL_WORKFLOW_DATA
} from './actionTypes';

const initialState = {
  requestProcessing: {
    [APIRequestStatus.GET_ALL_APPROVAL_WORKFLOW_LIST]: LoadingStatus.Loading,
    [APIRequestStatus.GET_APPROVAL_WORKFLOW_STRUCTURE_CONFIG]: LoadingStatus.Loading,
  },
  approvalWorkflowList: [],
  approvalWorkflowConfigLib: [],
  approvalWorkflowConfigSteps: [],
  approvalWorkflowFormDataLib: {},
  approvalWorkflowFormDataSteps:[{}],
  approvalWorkflowFormDataStepsDeleted: [],
};

export const approvalWorkflowReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_APPROVAL_WORKFLOW_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_APPROVAL_WORKFLOW_INFO]: LoadingStatus.Loading
        }
      };
    }

    case POST_APPROVAL_WORKFLOW_INFO_SUCCESS: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_APPROVAL_WORKFLOW_INFO]: LoadingStatus.Success
        }
      };
    }

    case POST_APPROVAL_WORKFLOW_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_APPROVAL_WORKFLOW_INFO]: LoadingStatus.Failure
        }
      };
    }
    case DUPLICATE_STEPS_TAB: {
      const formConfigStep = state.approvalWorkflowConfigSteps;
      const listofStepsData = state.approvalWorkflowFormDataSteps;
      return {
        ...state,
        approvalWorkflowConfigSteps: [...formConfigStep, formConfigStep[0]],
        approvalWorkflowFormDataSteps: [...listofStepsData,listofStepsData[action.payload]],
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_APPROVAL_WORKFLOW_STRUCTURE_CONFIG]: LoadingStatus.Success
        }
      };
    }
    case DELETE_STEPS_TAB: {
      const { index, postId } = action.payload;
      const Steps = [...state.approvalWorkflowConfigSteps];
      Steps.splice(index, 1);
      let deletedSteps = [...state.approvalWorkflowFormDataStepsDeleted];
      if (postId) {
        deletedSteps = [...state.approvalWorkflowFormDataStepsDeleted, postId];
      }
      const listofStepsData = state.approvalWorkflowFormDataSteps;
      listofStepsData.splice(index, 1);
      return {
        ...state,
        approvalWorkflowFormDataStepsDeleted: deletedSteps,
        approvalWorkflowConfigSteps: Steps,
        approvalWorkflowFormDataSteps: listofStepsData,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_APPROVAL_WORKFLOW_STRUCTURE_CONFIG]: LoadingStatus.Success
        }
      };
    }
    case ADD_STEPS_TAB: {
      const formConfigStep = state.approvalWorkflowConfigSteps;
      const listofStepsData = state.approvalWorkflowFormDataSteps;
      listofStepsData.push({});
      return {
        ...state,
        approvalWorkflowConfigSteps: [...formConfigStep, formConfigStep[0]],
        approvalWorkflowFormDataSteps: listofStepsData,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_APPROVAL_WORKFLOW_STRUCTURE_CONFIG]: LoadingStatus.Success
        }
      };
    }
    case GET_ALL_APPROVAL_WORKFLOW_LIST_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_APPROVAL_WORKFLOW_LIST]:
            LoadingStatus.Loading,
        },
      };
    }

    case GET_ALL_APPROVAL_WORKFLOW_LIST_SUCCESS: {
      const approvalWorkflowList = action.payload;
      return {
        ...state,
        approvalWorkflowList: approvalWorkflowList,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_APPROVAL_WORKFLOW_LIST]:
            LoadingStatus.Success,
        },
      };
    }

    case GET_ALL_APPROVAL_WORKFLOW_LIST_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_APPROVAL_WORKFLOW_LIST]:
            LoadingStatus.Failure,
        },
      };
    }
    case GET_APPROVAL_WORKFLOW_CONFIG_SUCCESS: {
      const {res} = action.payload;
      const { formConfigLib, formConfigStep,approvalWorkFlowData } = res;
      let formConfig = [formConfigStep];
      if(approvalWorkFlowData?.listSteps?.length){
        formConfig = Array(approvalWorkFlowData?.listSteps.length).fill(formConfigStep);
      }
      const {library,listSteps} = approvalWorkFlowData;
      return {
        ...state,
        approvalWorkflowConfigLib: formConfigLib,
        approvalWorkflowConfigSteps:formConfig,
        approvalWorkflowFormDataLib:  library,
        approvalWorkflowFormDataSteps: isEmpty(listSteps) ? [{}] : listSteps,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_APPROVAL_WORKFLOW_STRUCTURE_CONFIG]: LoadingStatus.Success
        }
      };
    }
    case UPDATE_STEPS_TAB: {
      const {index,fromDataSteps} = action.payload;
      const listOfSteps = state.approvalWorkflowFormDataSteps;
      listOfSteps[index] = fromDataSteps;
      return {
        ...state,
        approvalWorkflowFormDataSteps: listOfSteps,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_APPROVAL_WORKFLOW_STRUCTURE_CONFIG]: LoadingStatus.Success
        }
      };
    }
    case GET_APPROVAL_WORKFLOW_CONFIG_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_APPROVAL_WORKFLOW_STRUCTURE_CONFIG]: LoadingStatus.Failure
        }
      };
    }
    case GET_APPROVAL_WORKFLOW_CONFIG_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_APPROVAL_WORKFLOW_STRUCTURE_CONFIG]: LoadingStatus.Loading
        }
      };
    }
    case RESET_APPROVAL_WORKFLOW_DATA:{
      return {
        ...state,
        approvalWorkflowList: [],
        approvalWorkflowConfigLib: [],
        approvalWorkflowConfigSteps: [],
        approvalWorkflowFormDataLib: {},
        approvalWorkflowFormDataSteps:[{}],
        approvalWorkflowFormDataStepsDeleted: [],
      };
    }
    case RESET_APPROVAL_WORKFLOW_STRUCTURE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
