export const FETCH_APP_LEVEL_PERMISSIONS_REQUEST = 'FETCH_APP_LEVEL_PERMISSIONS_REQUEST';
export const FETCH_APP_LEVEL_PERMISSIONS_SUCCESS = 'FETCH_APP_LEVEL_PERMISSIONS_SUCCESS';
export const FETCH_APP_LEVEL_PERMISSIONS_FAILURE = 'FETCH_APP_LEVEL_PERMISSIONS_FAILURE';
export const SET_CURRENT_USER_ROLE_ID = 'SET_CURRENT_USER_ROLE_ID';
export const SET_CURRENT_USER_INFO = 'SET_CURRENT_USER_INFO';
export const SET_AUTH = 'SET_AUTH';
export const RESET_STORE = 'RESET_STORE';
export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';


