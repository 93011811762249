import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FormEdit from '../../../common/Form/FormEdit';
import { RHSBoxAction } from '../../../../Performance/Goals/RHSBoxAction/RHSBoxAction';
import { useDispatch } from 'react-redux';
import { addEmployeeTab, deleteEmployeeTab, duplicateEmployeeTab } from '../../../../../redux/userOnboarding/action';
import FormView from '../../../common/Form/FormView';

const EmployeeForm = (props) => {
  const { formConfig, tabIndex, numberOfAccordion, viewMode, setEmpValidationErrors, 
    empValidationErrors, formData, setEmpData, getDropdownOptions, handleDropdownClick } = props;
  const dispatch = useDispatch();

  const [formInfo, setFormInfo] = useState(formData);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    setValidationErrors(empValidationErrors);
  }, [empValidationErrors]);

  useEffect(() => {
    setEmpData((prev) => {
      const newData = [...prev];
      newData[tabIndex] = { ...formInfo };
      return newData;
    });
  }, [formInfo]);

  const handleAdd = () => {
    setEmpValidationErrors((prev) => [...prev, {}]);
    dispatch(addEmployeeTab());
  };

  const handleDelete = () => {
    setEmpValidationErrors((prev) => {
      const newError = [...prev];
      newError.splice(tabIndex, 1);
      return newError;
    });

    setEmpData((prev) => {
      const newEmpData = [...prev];
      newEmpData.splice(tabIndex, 1);
      return newEmpData;
    });
    dispatch(deleteEmployeeTab(tabIndex));
  };

  const handleDuplicate = () => {
    setEmpValidationErrors((prev) => [...prev, {}]);
    dispatch(duplicateEmployeeTab(tabIndex));
    const {id,...rest} = formData;
    setEmpData((prev) => [...prev, rest]);
  };

//   useEffect(() => {
//     setFormInfo(formData);
// }, [formData]);


  return viewMode ? (<FormView list={formConfig} info={formData} />) :
    (<Box position='relative'
    >
      <FormEdit
        list={formConfig}
        formInfo={formInfo}
        setFormData={setFormInfo}
        errors={validationErrors}
        setErrors={setValidationErrors}
        handleDropdownClick={handleDropdownClick}
        getDropdownOptions={getDropdownOptions}
      />
      <RHSBoxAction
        disableDelete={numberOfAccordion === 1}
        onClickAdd={handleAdd}
        onClickDelete={handleDelete}
        onClickCopy={handleDuplicate}
      />
    </Box>);
};

export default EmployeeForm;