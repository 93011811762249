import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography } from '@mui/material';
import './dynamicBreadcrumb.css';


const DynamicBreadcrumb = (props) => {
  const {idNameMap} = props;
  const location = useLocation();
  const paths = (location.pathname).split('/').filter(path => path.length > 0);
  const getTitle = (path) => {
    if(!isNaN(path)) {
      return idNameMap?.[path];
    }
    const title = path.split('-').map((char) => char[0].toUpperCase() + char.slice(1)).join(' ');
    return title;
  };

  const crumblist = paths.map((subpath, idx) => {
    const href = '/' + paths.slice(0, idx + 1).join('/');
    const title = getTitle(subpath);
    return { href, title };
  });
  const breadCrumbs = [{ href: '/', title: 'Home' }, ...crumblist];
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
      fontSize='small'
    >
      {breadCrumbs.map((item, index) => {
        const { href, title } = item;
        if (index === breadCrumbs.length - 1) {
          return (
            <Typography fontSize="small" key={index} color="primary.main">
              {title}
            </Typography>);
        }

        return (
          <Link
            key={index}
            fontSize='small'
            className='ve-breadcrumb-link'
            to={href}
          >
            {title}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default DynamicBreadcrumb;