import { Box, Paper, Typography, Grid } from '@mui/material';
import React from 'react';
import ActionHeader from '../../../ActionHeader/ActionHeader';
import FormView from '../../../../common/Form/FormView';
import AccordionTabs from '../../../../../../atoms/Accordian/Accordian';
import { ApprovalWorkflowHeaders } from '../../../../../Performance/Goals/common/constants';
import { ApprovalWorkFlowLib } from '../../../constants/onboarding.constants';
const AppWorkViewForm = (props) => {
  const {
    setViewMode,
    formConfig,
    approvalWorkflowFormDataLib,
    approvalWorkflowFormDataSteps,
    setShowForm,
    steps,
  } = props;
  const formConfigFiltered = formConfig.filter(
    (item) =>
      item.fieldRefName !== ApprovalWorkFlowLib.Library &&
      item.fieldRefName !== ApprovalWorkFlowLib.isSendBackEmail &&
      item.fieldRefName !== ApprovalWorkFlowLib.isFinalApprovalEmail &&
      item.fieldRefName !== ApprovalWorkFlowLib.isRejectionEmail &&
      item.fieldRefName !== ApprovalWorkFlowLib.sendBackEmailTemplate &&
      item.fieldRefName !== ApprovalWorkFlowLib.rejectionEmailTemplate &&
      item.fieldRefName !== ApprovalWorkFlowLib.finalApprovalEmailTemplate &&
      item.fieldRefName !== ApprovalWorkFlowLib.step0EmailTemplate &&
      item.fieldRefName !== ApprovalWorkFlowLib.isStep0EmailTemplate &&
      item.fieldRefName !== ApprovalWorkFlowLib.isStep0isdailyUpdateEmail &&
      item.fieldRefName !== ApprovalWorkFlowLib.approvalEmailTemplates && 
      item.fieldRefName !== ApprovalWorkFlowLib.lastStepReportingManagerEmailTemplate &&
      item.fieldRefName !== ApprovalWorkFlowLib.isLastStepReportingManagerEmailTemplate &&
      item.fieldRefName !== ApprovalWorkFlowLib.isLastStepReportingManagerisdailyUpdateEmail
  );
  const handleEdit = () => {
    setViewMode(false);
  };
  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            labelText={approvalWorkflowFormDataLib?.name}
            editButtonText="Edit"
            onEditClick={handleEdit}
            showSave={true}
            showBackButton
            onBackClick={() => setShowForm(false)}
          />
        </Box>
      </Box>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item md={8}>
          <Box
            py={2}
            px={3}
            bgcolor={'neutral.light80'}
            borderRadius={'8px 8px 0 0'}
          >
            <Typography variant="body2Bold" color={'neutral.dark80'}>
              {approvalWorkflowFormDataLib?.name}
            </Typography>
          </Box>
          <Paper sx={{ p: 3 }}>
            <FormView
              list={formConfigFiltered}
              info={approvalWorkflowFormDataLib || {}}
              hideFields={false}
            />
            <Box mt={3}>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item md={12}>
                  {approvalWorkflowFormDataSteps?.map((step, index) => {
                    return (
                      <div key={index}>
                        <AccordionTabs
                          key={index}
                          title={`${ApprovalWorkflowHeaders.Steps} ${
                            index + 1
                          }`}
                          tabDetails={
                            <FormView
                              list={steps.filter((item) => {
                                return Object.keys(step).includes(
                                  item.fieldRefName
                                );
                              })}
                              info={step}
                              hideFields={false}
                            />
                          }
                        />
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppWorkViewForm;
