import React from 'react';
import FormView from '../../common/Form/FormView';
import { Box, Divider, Grid, Typography } from '@mui/material';
import ComponentFormView from './ComponentForm/ComponentFormView';
import { isEmpty } from 'lodash';
import TotalCalculationView from './ComponentForm/TotalCalculationView';
import BenefitsView from './ManagePayrollTab/BenefitsView';

const PayrollAssignview = (props) => {
  const {
    formFields,
    payStrInfo,
    basicComponents,
    additionComponents,
    deductionComponents,
    totalDeductions,
    totalAdditions,
    employeeBenifits,
  } = props;
  return (
    <>
      <FormView list={formFields} info={payStrInfo} />
      <Divider sx={{ mt: 3 }} />
      {!isEmpty(basicComponents) && (
        <TotalCalculationView
          totalDeductions={totalDeductions}
          totalAdditions={totalAdditions}
          basicComponents={basicComponents}
        />
      )}
      <Divider sx={{ mt: 3 }} />
      <Grid container rowSpacing={3} columnSpacing={8} pr={8}>
        {additionComponents?.length ? (
          <Grid item md={6} xs={12}>
            <Typography mt={2} variant="subtitle2">
              Addition Components
            </Typography>
            {additionComponents?.map((item, index) => {
              return (
                <ComponentFormView
                  formInfo={additionComponents[index]}
                  key={index}
                />
              );
            })}
          </Grid>
        ) : (
          <Grid item md={6} xs={12}>
            {''}
          </Grid>
        )}
        {deductionComponents?.length ? (
          <Grid item md={6} xs={12}>
            <Typography mt={2} variant="subtitle2">
              Deduction Components
            </Typography>
            {deductionComponents?.map((item, index) => {
              return (
                <ComponentFormView
                  formInfo={deductionComponents[index]}
                  key={index}
                />
              );
            })}
          </Grid>
        ) : (
          <Grid item md={6} xs={12}>
            {''}
          </Grid>
        )}
      </Grid>
      <Box mt={4}>
        {employeeBenifits?.length ? (
          <BenefitsView employeeBenifits={employeeBenifits} />
        ): ''}
      </Box>
    </>
  );
};

export default PayrollAssignview;
