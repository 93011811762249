import API from './api';

export class mainServices extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL || '';
  constructor() {
    super( mainServices.baseURL);
  }

  fetchAppLevelPermissions = async (id) => (
    this.responsify(await this.instance.get(`roles-and-permissions/app?roleId=${id}`))
  );

  resetPassword = async ({token,password,confirm_password}) =>(
    await this.instance.post('auth/reset-password',{token,password,confirm_password})
  );

  forgotPassword = async ({email}) =>(
    await this.instance.post('auth/forgot-password',{email})
  );
}