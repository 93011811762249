import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BuilderServices } from '../../../../services/builder';
import { getClaimList } from '../../../../redux/newClaims/action';
import { getFormattedValues } from '../../../../utils/formatter';
import {
  CheckCircleOutlineRounded,
  SwipeLeftOutlined,
} from '@mui/icons-material';
import { ViewAccess } from '../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import { CLAIM_STATUS } from '../../../claim-view/claim-audit-log';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import ClaimsActionHeader from '../../../claims/ClaimsActionHeader';
import ApprovalWorkflowRejectModal from '../../../OnBoarding/Organization/ApprovalFlow/Modals/RejectModal';
import ReasonIssueList from '../../../../constants/Reasons';
import { Grid } from '@mui/material';
import ApprovalWorkflowApproveModal from '../../../OnBoarding/Organization/ApprovalFlow/Modals/ApproveModal';
import { isNotEmptyString } from '../../../OnBoarding/Organization/utils/onboarding.util';
import { GoalsServices } from '../../../../services/goals';
import { getAllAppraisalList } from '../../../../redux/goals/action';
import { useSearchParams } from 'react-router-dom';

const ApproveRejectAppraisal = ({
  currentUserRoleId,
  checkedItems,
  moduleId,
  viewAccess,
  searchQuery,
  selectedDate,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [approvalRemarks, setApprovalRemarks] = useState('');
  const [searchParam] = useSearchParams();
  const dispatch = useDispatch();
  const updatedCheckedItem = checkedItems.map((item) => {
    const { id,...rest } = item;
    return {id};
  });

  const submitApprovalRejection = async (body) => {
    const { remark, reasons, action } = body;
    try {
      setLoading(true);
      const response = await new GoalsServices()
        .BulkApproveOrRejectAppraisal({
          records: updatedCheckedItem,
          moduleId,
          action,
          remarks:remark,
          reasons,
          roleId: currentUserRoleId,
        })
        .then(() => {
          dispatch(getAllAppraisalList(currentUserRoleId, searchParam.get('q'),moduleId));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
          setApprovalRemarks('');
        });
      return { success: response, error: null };
    } catch (error) {
      setModalTitle('Error');
      setModalDescription(error?.response?.data?.message || error.message);
      return {
        success: false,
        error: error?.response?.data?.message || error.message,
      };
    }
  };

  const handleSubmit = async (body) => {
    try {
      return await submitApprovalRejection(body);
    } catch (err) {
      console.error(err);
    }
  };

  const handleApproveRequest = () => {
    setModalTitle('Approve');
    setApproveModal(true);
  };

  const handleCloseModel = () => {
    setApproveModal(false);
    setModalTitle('');
    setModalDescription('');
  };

  const buttonOptions = [
    {
      title: 'Approve',
      handleClick: handleApproveRequest,
      icon: <CheckCircleOutlineRounded sx={{ width: 20, height: 20 }} />,
      hide: viewAccess !== ViewAccess.APPROVER_APPROVE_ACCESS,
      disabled: !checkedItems.length,
    },
    {
      title: 'Send Back',
      handleClick: () => setOpenModal(true),
      icon: <SwipeLeftOutlined sx={{ width: 20, height: 20 }} />,
      hide: viewAccess !== ViewAccess.APPROVER_APPROVE_ACCESS,
      disabled: !checkedItems.length,
    },
  ];

  return (
    <>
      <CircularLoader show={loading} />
      <ClaimsActionHeader
        buttonOptions={buttonOptions.filter((item) => !item.hide)}
      />
      <ApprovalWorkflowRejectModal
        handleSubmit={handleSubmit}
        open={openModal}
        setOpen={setOpenModal}
        reasonsList={ReasonIssueList}
        recordId={updatedCheckedItem}
        moduleId={moduleId}
      />
      <Grid item md={12}>
        <ApprovalWorkflowApproveModal
          showModal={approveModal}
          component
          componentProps={{
            onInputChange: (event) => setApprovalRemarks(event.target.value),
            inputValue: approvalRemarks,
            inputPlaceHolder: 'Add Remarks',
          }}
          severity={'success'}
          onClickClose={handleCloseModel}
          primaryAction={'Approve'}
          primaryButtonDisabled={!isNotEmptyString(approvalRemarks)}
          onClickConfirm={() =>
            handleSubmit({
              updatedCheckedItem,
              moduleId,
              action: 'APPROVED',
              remark: approvalRemarks,
              reasons: [],
            })
          }
          title={'Approve'}
          description={
            <>
              {modalDescription && (
                <>
                  {modalDescription}
                  <br />
                </>
              )}
            </>
          }
        />
      </Grid>
    </>
  );
};

export default ApproveRejectAppraisal;
