import React, { useState } from 'react';
import SelectTag from '../../../../../../atoms/Select/SelectTag';
import { CompetencyFormFieldNames } from '../../../../Utlis/goals.util';
import FieldView from '../../../../../molecules/FieldView/FieldView';
import { Box, Grid } from '@mui/material';
import InputField from '../../../../../../atoms/Input/Input';

const CompetencyAppraisalFormEdit = (props) => {
  const {
    dropDownList,
    setData,
    itemIndex,
    rating,
    weightageValue,
    remarks,
    isAppraisal,
    setCompetencyError,
    competencyError,
    appraiserRemarksCompetency,
    setAppraiserRemarksCompetency,
    competencyId,
    managerAcess
  } = props;
  const [ratingVal, setRatingVal] = useState(rating);
  const [remarksVal, setRemarksVal] = useState(remarks);
  

  const handleChangeRating = (event) => {
    const { value } = event.target;
    setRatingVal(value);
    setCompetencyData(value, remarksVal);
  };

  const handleChangeRemarks = (event) => {
    const { value } = event.target;
    setRemarksVal(value);
    setCompetencyData(ratingVal, value);
    setCompetencyError((prev) => {
      const newError = [...prev];
      newError[itemIndex] = '';
      return newError;
    });
  };

  const setCompetencyData = (ratingVal, remarksVal) => {
    setData((prev) => {
      const newData = [...prev];
      newData[itemIndex] = {
        ...newData[itemIndex],
        [CompetencyFormFieldNames.Submission]: {
          ...newData[itemIndex][CompetencyFormFieldNames.Submission],
          [CompetencyFormFieldNames.Rating]: ratingVal,
          [CompetencyFormFieldNames.Remarks]: remarksVal,
        },
      };
      return newData;
    });
  };
  console.log(dropDownList);
  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <SelectTag
          labelText="Rating"
          dropDownList={dropDownList}
          onchange={handleChangeRating}
          value={ratingVal}
          widthAuto
          size="small"
          disabled={managerAcess}
        />
        <FieldView labelText={'Weightage'} labelValue={weightageValue} />
      </Box>
      <Box mb={1}>
        <InputField
          labelText="Remarks"
          size="small"
          value={remarksVal}
          onChange={handleChangeRemarks}
          required={true}
          multiline={true}
          fullWidth={true}
          error={competencyError}
          helperText={competencyError}
          disabled={managerAcess}
        />
      </Box>
      <Box>
        <InputField
          labelText="Appraiser Remarks"
          size="small"
          value={appraiserRemarksCompetency?.[CompetencyFormFieldNames.AppraiserRemarks]}
          onChange={(event)=>{setAppraiserRemarksCompetency((prev)=>{
            const newData = [...prev];
            newData[itemIndex] = {[CompetencyFormFieldNames.AppraiserRemarks]:event.target.value,competencyId};
            return newData;
          });}}
          required={true}
          multiline={true}
          fullWidth={true}
          disabled={!managerAcess}
        />
      </Box>
    </>
  );
};

export default React.memo(CompetencyAppraisalFormEdit);
