import { Box } from '@mui/material';
import React from 'react';
import CardView from '../../atoms/DataList/CardView';

const ModalCardView = (props) => 
{
    const {onInputChange, inputValue, inputPlaceHolder, inputId, helperText, countBox} = props;
return (
    <Box width={500} mt={4} display='flex' justifyContent='center'>
      <Box width={450}>
        <CardView
          countBox={countBox}
          count={(inputId)?inputId:0}
          editable={true}
          onChange={onInputChange}
          value={inputValue}
          placeholder={inputPlaceHolder}
          helperText={helperText}
        />
      </Box>
    </Box>
  );
};

export default ModalCardView;