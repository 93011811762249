import {
  Box,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import CardUI from '../../../shared/components/ui-elements/CardUI';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import Footer from '../../footer/Footer';
import PersonIcon from '@mui/icons-material/Person';
import Alert from '@mui/material/Alert';
import { useLocation } from 'react-router-dom';
import Modal from '../../../shared/components/ui-elements/Modal';
import ButtonUI from '../../../shared/components/ui-elements/ButtonUI';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  generateCurrentTimeStamp,
  generateFormData,
  validateNumber,
  disablePastYears,
  disablePrevDates,
  EnableDateInRangeOnly,
} from '../common-services/functions';
import {
  postFilesData,
  postFormData,
  getFlexiBenefitTypes,
  getRemainingClaimAmount,
  getAuthToken,
} from '../common-services/services';
import UserProfile from '../../../shared/components/claim-forms/UserProfile';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import './FlexiClaim.css';
import ReportingDetails from '../../../shared/components/claim-forms/ReportingDetails';
import { parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import axios from 'axios';

let mappedArr = new Map();
const FlexiClaim = () => {
  const hostname = window.location.hostname;
  const tenant = hostname.split('.')[0] === 'localhost'? process.env.REACT_APP_LOCALHOST_CLIENT_ID : hostname.split('.')[0];
  const uniqueNum = Math.floor(Math.random() * 10000000000) + 10000000000;
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showImgName, setShowImgName] = useState(0);
  const [benefitTypes, setBenefitTypes] = useState([]);
  const [remainingClaimAmount, setRemainingClaimAmount] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');
  const [tableData, setTableData] = useState([]);
  let count=0 ;

  const navigate = useNavigate();
  const [inputs, setInputs] = useState([
    {
      reportingOfficer: 'Kelvin Lionel',
      hod: 'Benjamin Koh',
      costCenter: 'Singapore Labour',
      claimReferenceNumber: uniqueNum,
      claimType: '',
      claimAmount: '',
      benefitType: '',
      receiptDate: null,
      receiptNumber: '',
      filesUpload: null,
      birthCertificateNumber: '',
      childBirthDate: null,
    },
  ]);
  const getClientID = () => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    return userData.userDetails.client.id;
  };
 const currentUserType = useSelector(
    (state) => state && state.claims.currentUserType
  );
  const [value, setValue] = React.useState('1');
  const getDataForEmployee = (userId) => {
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_API_LOCAL
        }/business-travels?empID=${userId}&clientID=${getClientID()}`,
        {
          headers: { Authorization: `Bearer ${getAuthToken()}`,'client_id': tenant },
        }
      )
      .then((res) => {
        // console.log(res.data.payload, 'pdpdpdpdd');
        setTableData(res.data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(tableData,'fgfgfgffgfgfgfgf');
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem('userData'));

    if (currentUserType == 'Employee') {
      getDataForEmployee(userData.userDetails.id);
    } 
  }, [currentUserType, value]);
  const filterData = tableData.find(data=> data.claimSubType = 'Health Screening');
  if(filterData) {
    count= 1;}
    else{
      count =0;
    }
    
    console.log(count,'count');

  const params = useLocation();

  const [type] = useState(params.state.type);
  let userData = JSON.parse(localStorage.getItem('userData'));
  let asProfile = localStorage.getItem('asProfile');

  let claimTypes = ['Flexible Benefit', 'Health Screening'];

  if (userData.userDetails.gender == 'female') {
    claimTypes.push('Maternity Benefit');
  }

  const handleChangeInput = (i, e, key = null, key1) => {
    const values = [...inputs];
    if (key) values[i][key] = e;
    else {
      if (key1) {
        const validate = validateNumber(e.target.name, e.target.value);
        if (!validate) {
          return false;
        }
      }
      values[i][e.target.name] = e.target.value;
      switch (e.target.name) {
        case 'claimType':
        case 'claimAmount':
        case 'benefitType':
          benefitsCalculations(i, values[i]);
          // amountCalculation(i, values[i]);
          break;
        default:
          break;
      }
    }
    setInputs(values);
  };

  let addFormFields = (claimReferenceNumber) => {
    setInputs([
      ...inputs,
      {
        reportingOfficer: 'Kelvin Lionel',
        hod: 'Benjamin Koh',
        costCenter: 'Singapore Labour',
        claimReferenceNumber: claimReferenceNumber + 1,
        claimType: '',
        claimAmount: '',
        benefitType: '',
        receiptDate: null,
        receiptNumber: '',
        filesUpload: null,
        birthCertificateNumber: '',
        childBirthDate: null,
      },
    ]);
  };

  const copyForm = (i, input, claimReferenceNumber) => {
    let inputFinals = {
      ...input,
      claimAmount: input.claimType == 'Maternity Benefit' ? 1200 : 0,
      claimReferenceNumber: claimReferenceNumber + 1,
    };

    setInputs([...inputs, inputFinals]);
  };

  const cardData = [
    {
      post: 'Reporting Officer',
      name:
        userData.userDetails.reportingTo.firstName +
        ' ' +
        userData.userDetails.reportingTo.lastName,
    },
    {
      post: 'HOD',
      name: userData.userDetails.HOD,
    },
    {
      post: 'Cost Center',
      name: userData.userDetails.costCenter,
    },
  ];
  const deleteForm = (i) => {
    if (inputs.length > 1) {
      const newArray = [...inputs];
      newArray.splice(i, 1);
      setInputs(newArray);
    }
  };

  const validateFields = () => {

    for (let i = 0; i <= inputs.length - 1; i++) {
      console.log(inputs[i],'input');
      if (
        inputs[i].claimAmount == undefined ||
        inputs[i].claimAmount == null ||
        inputs[i].claimAmount == ''
      ) {
        let message =
          inputs[i].claimType == 'Flexible Benefit'
            ? 'Please Enter Claim Amount!'
            : 'Please Enter Receipt Amount!';
        window.alert(message);
        return;
      }
      if (
        inputs[i].claimType == undefined ||
        inputs[i].claimType == null ||
        inputs[i].claimType == ''
      ) {
        window.alert('Please Enter A Valid Claim Type !');
        return;
      }
      if (inputs[i].claimType == 'Flexible Benefit') {
        if (
          inputs[i].benefitType == undefined ||
          inputs[i].benefitType == null ||
          inputs[i].benefitType == ''
        ) {
          window.alert('Please Enter A Benefit Type !');
          return;
        }
      }
      if (inputs[i].claimType) {
        let res = mappedArr.get(inputs[i].claimReferenceNumber);
        if (!res || res.length === 0) {
          window.alert('Attachment is necessary!');
          return false;
        }
      }
      if (inputs[i].claimType !== 'Maternity Benefit') {
        if (
          inputs[i].receiptNumber == undefined ||
          inputs[i].receiptNumber == null ||
          inputs[i].receiptNumber == ''
        ) {
          window.alert('Please Enter Receipt Number !');
          return;
        }
        if (
          inputs[i].receiptDate == undefined ||
          inputs[i].receiptDate == null ||
          inputs[i].receiptDate == ''
        ) {
          window.alert('Please Enter A Date !');
          return;
        }
      }

      if (inputs[i].claimType == 'Maternity Benefit') {
        if (
          inputs[i].birthCertificateNumber == undefined ||
          inputs[i].birthCertificateNumber == null ||
          inputs[i].birthCertificateNumber == ''
        ) {
          window.alert('Please Enter Child Birth Certificate Number!');
          return;
        }
        if (
          inputs[i].childBirthDate == undefined ||
          inputs[i].childBirthDate == null ||
          inputs[i].childBirthDate == ''
        ) {
          window.alert('Please Enter Child Birthdate!');
          return;
        }
      }
      // if (inputs[i].claimType == 'Health Screening' && count==0) {
      if (inputs[i].claimType == 'Health Screening' && remainingClaimAmount.isFirstHealthScreening) {
        console.log('remainingClaimAmount.isFirstHealthScreening',remainingClaimAmount.isFirstHealthScreening);
        if (inputs[i].claimAmount <= 20 && remainingClaimAmount.isFirstHealthScreening) {
          alert('Minimum claim amount for health screening is $21. \n Until your first health screening claim is approved.');
          let newArray = [...inputs];
          newArray[i].claimAmount = 0;
          setInputs(newArray);
          return;
        }
      }
    }
    return true;
  };

  const generateDataForClaimRequest = (
    inputs,
    userData,
    dateTime,
    callType
  ) => {
    let finalFormData = [];
    let updateFormObject = {};
    let callForPost = callType;

    if (inputs) {
      return (finalFormData = inputs.map((input) => {
        return (updateFormObject = {
          typeId: 1,
          employee: userData && userData.userDetails.id,
          claimReferenceNumber: input.claimReferenceNumber,
          claimSubType: input.claimType,
          amount: input.claimAmount,
          benefitType: input.benefitType.id,
          receiptDate: input.receiptDate,
          receiptNumber: input.receiptNumber,
          filePaths: mappedArr.get(input.claimReferenceNumber),
          status: callForPost,
          birthCertificateNumber: input.birthCertificateNumber,
          childBirthDate: input.childBirthDate,
        });
      }));
    }
  };

  // On file select (from the pop up)
  const onFileChange = async (e, refNumber, typeId = 1) => {
    // check size for mapped array start
    if (e.target.files.length > 2) {
      alert('Only Two Attachements Are Allowed');
      return;
    }
    let res = mappedArr.get(refNumber);
    if (res && res.length) {
      if (res.length === 2) {
        alert('Only Two Attachements Are Allowed');
        return false;
      }
      if (res.length === 1 && e.target.files.length >= 2) {
        alert('Only Two Attachements Are Allowed');
        return false;
      }
    }
    // check size for mapped array end

    // Generates form data for file upload.
    let formData = await generateFormData(e, refNumber, typeId);

    // Send form data for file upload.
    if (formData) {
      let updatedPaths = await postFilesData(formData);
      // Sets updated paths received from POST api call.
      if (!Array.isArray(mappedArr.get(refNumber))) {
        mappedArr.set(refNumber, updatedPaths);
      } else {
        let temp = mappedArr.get(refNumber);
        temp = [...temp, ...updatedPaths];
        mappedArr.set(refNumber, temp);
      }
      setShowImgName(showImgName + 1);
    }
  };

  // remove attachment from claims data start
  const removeAttachement = (i, refNumber) => {
    const res = mappedArr.get(refNumber);
    if (Array.isArray(res)) {
      res.splice(i, 1);
    }
    setShowImgName(showImgName + 1);
  };
  // remove attachment from claims data end

  // handle date range on the base of state;
  const handleDateRange = (input)=>{
    console.log('input', input);
    let StartDate = new Date();
    let EndDate = new Date();
    if(remainingClaimAmount)
    {
      switch(input.claimType){
        case 'Flexible Benefit': 
        StartDate =  new Date(remainingClaimAmount.startDate);
        EndDate = new Date(remainingClaimAmount.endDate);
        break;
        case 'Health Screening': 
        StartDate =  new Date(remainingClaimAmount.healthScreeningStartDate);
        EndDate = new Date(remainingClaimAmount.healthScreeningEndDate);
        break;
        default : 
        StartDate =  new Date(remainingClaimAmount.startDate);
        EndDate = new Date(remainingClaimAmount.endDate);
      }
    }
    return [StartDate,EndDate];
    // return  EnableDateInRangeOnly('05-20-2022','05-20-2023');
  };

  useEffect(() => {
    getFlexiBenefitTypesAPICall();
    getRemainingClaimAmountAPICall();
  }, []);

  const submitData = async (callType) => {
    const dateTime = generateCurrentTimeStamp();
    setFormSubmitted(true);
    setButtonClicked(callType);
    const duplicateEntries = inputs.find((input, i) => {
      return inputs.find((x, ind) => {
        // if (
        //   moment(x.receiptDate).format('DD:MM:YYYY') ==
        //     moment(input.receiptDate).format('DD:MM:YYYY') &&
        //   i !== ind
        // ) {
        //   return x;
        // }
        if (input.claimType !== 'Maternity Benefit') {
          if (x.receiptNumber == input.receiptNumber && i !== ind) {
            return x;
          }
        } else {
          if (
            x.birthCertificateNumber == input.birthCertificateNumber &&
            i !== ind
          ) {
            return x;
          }
        }
      });
    });

    if (duplicateEntries) {
      let message =
        duplicateEntries.claimType == 'Maternity Benefit'
          ? 'Duplicate Child Birth Certificate Numbers Are Not Allowed !'
          : 'Duplicate Receipt Numbers Are Not Allowed !';
      alert(message);
      setFormSubmitted(false);
      return;
    }

    let validate = validateFields();

    if (!validate) {
      setFormSubmitted(false);
      return;
    }

    let finalFormData = generateDataForClaimRequest(
      inputs,
      userData,
      dateTime,
      callType
    );

    // POST call for submit form data.
    let businessTravelsResponse = await postFormData(finalFormData);

    if (businessTravelsResponse === 201) {
      let successMessage = '';
      if (callType === 'PENDING') {
        successMessage = 'Claim request submitted successfully !';
      } else {
        successMessage = 'Draft saved successfully !';
      }
      setFormSubmitted(false);
      openSuccessPopUp(successMessage);
    } else {
      setFormSubmitted(false);
      setErrorMessage(businessTravelsResponse);
    }
  };

  /** Close the modal */
  const closeBackdropHandler = () => {
    setIsBackdropOpen(false);
    navigate('/dashboard', {
      state: {
        claimType: '1',
      },
    });
  };

  // Open Modal When api response succesfully for(Draft/Submit)
  const openSuccessPopUp = (successMessage) => {
    setSuccessMessage(successMessage);
    setIsBackdropOpen(true);
  };

  const getFlexiBenefitTypesAPICall = async () => {
    let response = await getFlexiBenefitTypes();
    if (response) {
      setBenefitTypes(response);
    }
  };
  console.log(benefitTypes,'type');

  const getRemainingClaimAmountAPICall = async () => {
    let response = await getRemainingClaimAmount();
    if (response) {
      setRemainingClaimAmount(response);
    }
    setBalanceAmount(Math.round(response.totalBalanceAmount));
  };

  const healthNonHealthValidation = (
    i,
    claimAmountUpdated,
    totalBalanceAmount,
    covidTypeId,
    isCovid
  ) => {
    const isFirstHealthScreening = i === 0 && (remainingClaimAmount?.isFirstHealthScreening ? remainingClaimAmount?.hasNoPendingHealthScreening : false);
    if (
      claimAmountUpdated > totalBalanceAmount &&
      isCovid == true &&
      covidTypeId == 10 &&
      !isFirstHealthScreening
    ) {
      alert(`Your health balance amount is $ ${totalBalanceAmount}`);
      let newArray = [...inputs];
      newArray[i].claimAmount = 0;
      setInputs(newArray);
      return;
    } else {
      let newArray = [...inputs];
      let amountsArray = [];
      newArray.forEach((each) => {
        if (each.claimType == 'Flexible Benefit') {
          amountsArray.push(Number(each.claimAmount));
        }
      });
      let finalAmount = 0;
      if (amountsArray) {
        amountsArray.forEach((each) => (finalAmount += each));
      }

      if (finalAmount > totalBalanceAmount) {
        amountsArray.pop();
        alert('You have exceeded the benefit entitlement !');
        let newArray = [...inputs];
        newArray[i].claimAmount = 0;
        return;
      }
    }
  };
  const healthNonHealthValidation2 = (
    i,
    claimAmountUpdated,
    totalBalanceAmount
    ) => {
    const isFirstHealthScreening = i === 0 && (remainingClaimAmount?.isFirstHealthScreening ? remainingClaimAmount?.hasNoPendingHealthScreening : false);
    if (claimAmountUpdated > totalBalanceAmount && !(remainingClaimAmount.isFirstHealthScreening && remainingClaimAmount.hasNoPendingHealthScreening)) {
      alert(`Your health balance amount is $ ${totalBalanceAmount}`);
      let newArray = [...inputs];
      newArray[i].claimAmount = 0;
      setInputs(newArray);
      return;
    } else {
      let newArray = [...inputs];
      let amountsArray = [];
      newArray.forEach((each) => {
        if (each.claimType == 'Health Screening') {
          amountsArray.push(Number(each.claimAmount));
        }
      });
      let finalAmount = 0;
      if (amountsArray) {
        amountsArray.forEach((each) => (finalAmount += each));
      }
      if (finalAmount > totalBalanceAmount && !isFirstHealthScreening) {
        amountsArray.pop();
        alert('You have exceeded the benefit entitlement!');
        let newArray = [...inputs];
        newArray[i].claimAmount = 0;
        return;
      }
    }
  };

  // Amount calculation for benifit types
  const benefitsCalculations = (i, each) => {
    const { claimType, claimAmount, benefitType } = each;
    console.log(remainingClaimAmount  ,'amount');

    let nonHealthClaimBalanceAmount =
      remainingClaimAmount.nonHealthClaimBalanceAmount.toFixed(2);
    let entitlementAmount = remainingClaimAmount.entitlementAmount.toFixed(2);
    let covidClaimBalanceAmount =
      remainingClaimAmount.covidClaimBalanceAmount.toFixed(2);

    let totalBalanceAmount = remainingClaimAmount.totalBalanceAmount.toFixed(2);
    let healthScreeningBalanceAmount =
    remainingClaimAmount.healthScreeningBalanceAmount.toFixed(2);

    let typeOfBenefit = benefitType.type;
    let covidTypeId = benefitType.id;
    let claimAmountUpdated = Number(claimAmount);

    if (claimType == 'Flexible Benefit') {
      if (typeOfBenefit == 'Non Health' && claimAmountUpdated > 0) {
        if (
          claimAmountUpdated > totalBalanceAmount ||
          claimAmountUpdated > nonHealthClaimBalanceAmount
        ) {
          alert('You have exceeded the benefit entitlement');
          let newArray = [...inputs];
          newArray[i].claimAmount = '';
          setInputs(newArray);
          return;
        }
      }

      if (typeOfBenefit == 'Non Health' && nonHealthClaimBalanceAmount <= 0) {
        alert('You have exceeded the benefit entitlement !');
        let newArray = [...inputs];
        newArray[i].benefitType = '';
        setInputs(newArray);
        return;
      }

      if (typeOfBenefit == 'Health' && totalBalanceAmount <= 0) {
        alert('You have exceeded the benefit entitlement!');
        let newArray = [...inputs];
        newArray[i].benefitType = '';
        setInputs(newArray);
        return;
      }

      if (nonHealthClaimBalanceAmount > 0) {
        if (typeOfBenefit == 'Non Health') {
          // For COVID-19 /Non-Health
          if (covidTypeId == 10) {
            if (
              claimAmountUpdated > totalBalanceAmount ||
              claimAmountUpdated > nonHealthClaimBalanceAmount
            ) {
              alert('You have exceeded the benefit entitlement !');
              let newArray = [...inputs];
              newArray[i].claimAmount = '';
              setInputs(newArray);
              return;
            }

            if (claimAmountUpdated > nonHealthClaimBalanceAmount) {
              alert(
                `You can claim Covid-19 benefit amount up to $ ${nonHealthClaimBalanceAmount}`
              );
              let newArray = [...inputs];
              newArray[i].claimAmount = 0;
              setInputs(newArray);
              return;
            } else if (claimAmountUpdated > covidClaimBalanceAmount) {
              alert(
                `Your Covid-19 balance amount is $ ${covidClaimBalanceAmount}`
              );
              let newArray = [...inputs];
              newArray[i].claimAmount = 0;
              setInputs(newArray);
              return;
              // } else if (covidClaimBalanceAmount > entitlementAmount) {
            } else if (Number(covidClaimBalanceAmount) < Number(claimAmount)) {
              // console.log('convid claims balance', covidClaimBalanceAmount);
              // console.log('entitlementAmount', entitlementAmount,typeof claimAmount);
              alert(
                'Your Covid-19 amount should be less than your available entitlement amount.'
              );
              let newArray = [...inputs];
              newArray[i].claimAmount = 0;
              setInputs(newArray);
              return;
            } else {
              let newArray = [...inputs];
              let amountsArray = [];
              newArray.forEach((each) => {
                if (each.benefitType.id == 10) {
                  amountsArray.push(Number(each.claimAmount));
                }
              });
              let finalAmount = 0;
              if (amountsArray) {
                amountsArray.forEach((each) => (finalAmount += each));
              }
              if (finalAmount > covidClaimBalanceAmount) {
                alert(
                  'Total Covid-19 Amount is greater than available Covid-19 balance amount'
                );
                let newArray = [...inputs];
                newArray[i].claimAmount = 0;
                return;
              } else if (finalAmount > nonHealthClaimBalanceAmount) {
                amountsArray.pop();
                // alert('Total Covid-19 Amount is greater than available Covid-19 balance amount');
                alert('Total Amount is greater than health balance amount.');
                let newArray = [...inputs];
                newArray[i].claimAmount = 0;
                return;
              }
            }
          }
          // For Non-Health other than Non-Health
          healthNonHealthValidation(
            i,
            claimAmountUpdated,
            nonHealthClaimBalanceAmount,
            covidTypeId,
            true
          );
        } else if (typeOfBenefit == 'Health') {
          healthNonHealthValidation(
            i,
            claimAmountUpdated,
            totalBalanceAmount,
            covidTypeId,
            false
          );
        }
      }
      if (typeOfBenefit == 'Health') {
        healthNonHealthValidation(
          i,
          claimAmountUpdated,
          totalBalanceAmount,
          covidTypeId,
          false
        );
      }
    } else if (claimType == 'Health Screening') {
      healthNonHealthValidation2(
        i,
        claimAmountUpdated,
        healthScreeningBalanceAmount
      );
    } else if (claimType == 'Maternity Benefit') {
      // Logic for date of joining should be more than 3 Months.
      let months;
      let dateOfJoining = userData && userData.userDetails.dateOfJoining;

      // let d2 = new Date('2022-01-27T14:52:28.938Z');
      console.log(dateOfJoining,'dateOfJoining');
       let d2 = new Date();
//       console.log(d2,'d2');
//       let d1 = new Date();
//       months = (d1.getFullYear() - d2.getFullYear()) * 12;
//       console.log(d1.getFullYear(),'d1.getFullYear()');
//       months -= d2.getMonth();
//       months += d1.getMonth();
// console.log(months,'months');
 var startDate = moment(dateOfJoining, 'DD.MM.YYYY');
  var endDate = moment(d2, 'DD.MM.YYYY');
   months = endDate.diff(startDate, 'days');
console.log(months,d2,'months');
      if (months <= 90) {
        window.alert(
          'Not eligible for Maternity Benefit, Staff must have served NTUC-ARU for atleast 3 months prior to the day of child birth.'
        );
        let newArray = [...inputs];
        newArray[i].claimType = '';
        return;
      }
      // if (claimAmount > 1200) {
      //   window.alert('Amount should be less than $1200');
      //   let newArray = [...inputs];
      //   newArray[i].claimAmount = 0;
      //   setInputs(newArray);
      //   return;
      // }
      let newArray = [...inputs];
      newArray[i].claimAmount = 1200;
      newArray[i].receiptDate = '';
      newArray[i].receiptNumber = '';
      setInputs(newArray);
    }
  };

  // clear field when transport mode is changes start
  const fieldsEmpty = (i, e) => {
    const values = [...inputs];
    if (e.target.value && e.target.value !== 'Maternity Benefit') {
      Object.keys(values[i]).forEach((each) => {
        if (each === 'claimAmount') {
          values[i][each] = '';
          return;
        }
      });
      setInputs(values);
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Container>
          <Grid container>
            <ReportingDetails cardData={cardData} />
            <UserProfile
              name={userData && userData.userDetails.firstName}
              id={userData && userData.userDetails.id}
              designation={userData.userDetails?.jobTitle?.name}
              department={userData.userDetails?.department?.name}
            />
            <Grid xs={11}>
              <Box>
                {inputs &&
                  inputs.map((input, i) => (
                    <Box
                      key={i}
                      className="form-main"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '1em',
                        marginBottom: '4em',
                      }}
                    >
                      <Grid xs={11}>
                        {errorMessage && (
                          <Alert severity="error">{errorMessage}</Alert>
                        )}
                        <CardUI className="form-heading-wrap">
                          <Grid
                            xs={12}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Grid xs={6}>
                              <h4>
                                Flexible Benefit / Maternity Benefit / Health
                                Screening
                              </h4>
                            </Grid>
                            <Grid
                              xs={6}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <h4>
                                Claim Reference Number :{' '}
                                {input.claimReferenceNumber}
                              </h4>
                            </Grid>
                          </Grid>
                        </CardUI>
                        <CardUI className="form-content-wrap">
                          <Grid
                            container
                            spacing={2}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            {/* <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Claim Reference Number</label>
                              <TextField
                                type='number'
                                required
                                variant='outlined'
                                color='primary'
                                size='small'
                                value={input.claimReferenceNumber}
                                name='claimReferenceNumber'
                                fullWidth
                                disabled
                                onChange={(e) => handleChangeInput(i, e)}
                              />
                            </Grid> */}
                            <Grid className="amounts-wrap" item container>
                              {input.claimType == 'Flexible Benefit' && (
                                <React.Fragment>
                                  <Grid item xs={6} lg={3} md={4} sm={4}>
                                    <h4>Entitlement</h4>
                                    <h5>
                                      $
                                      {Math.floor(remainingClaimAmount?.entitlementAmount).toFixed(
                                        2
                                      )}
                                    </h5>
                                  </Grid>
                                  <Grid item xs={6} lg={3} md={4} sm={4}>
                                    <h4> Balance</h4>
                                    <h5>
                                      $
                                      {remainingClaimAmount?.entitlementAmount === remainingClaimAmount?.totalBalanceAmount? Math.floor(remainingClaimAmount?.totalBalanceAmount).toFixed(
                                        2
                                      ) : remainingClaimAmount?.totalBalanceAmount.toFixed(
                                        2
                                      )}
                                    </h5>
                                  </Grid>
                                  {/* <Grid item xs={6} lg={3} md={2} sm={2}>
                                    <h4>COVID-19 99999</h4>
                                    <h5>
                                      $
                                      {remainingClaimAmount?.covidClaimBalanceAmount &&
                                        Number(
                                          remainingClaimAmount?.covidClaimBalanceAmount
                                        ).toFixed(2)}
                                    </h5>
                                  </Grid> */}
                                </React.Fragment>
                              )}
                              {input.claimType == 'Health Screening' && (
                                <React.Fragment>
                                  <Grid item xs={6} lg={3} md={4} sm={4}>
                                    <h4>Entitlement</h4>
                                    <h5>
                                      $
                                      {Math.floor(remainingClaimAmount?.healthScreeningEntitlementAmount).toFixed(
                                        2
                                      )}
                                    </h5>
                                  </Grid>
                                  <Grid item xs={6} lg={3} md={4} sm={4}>
                                    <h4>Balance</h4>
                                    <h5>
                                      $
                                      {remainingClaimAmount?.healthScreeningEntitlementAmount === remainingClaimAmount?.healthScreeningBalanceAmount
                                      ?Math.floor(remainingClaimAmount?.healthScreeningBalanceAmount).toFixed(
                                        2
                                      )
                                      :remainingClaimAmount?.healthScreeningBalanceAmount.toFixed(
                                        2
                                      )}
                                    </h5>
                                  </Grid>
                                  <Grid item xs={6} lg={3} md={4} sm={4}>
                                    <h4>Entitlement Start Date</h4>
                                    <h5>
                                      {moment(
                                        remainingClaimAmount?.healthScreeningStartDate
                                      ).format('DD-MM-YYYY')}
                                    </h5>
                                  </Grid>
                                  <Grid item xs={6} lg={3} md={4} sm={4}>
                                    <h4>Entitlement End Date</h4>
                                    <h5>
                                      {moment(
                                        remainingClaimAmount?.healthScreeningEndDate
                                      ).format('DD-MM-YYYY')}
                                    </h5>
                                  </Grid>
                                </React.Fragment>
                              )}
                              {input.claimType == 'Flexible Benefit' && (
                                <React.Fragment>
                                  {remainingClaimAmount?.startDate && (
                                    <Grid item xs={6} lg={3} md={4} sm={4}>
                                      <h4>Entitlement Start Date</h4>
                                      <h5>
                                        {moment(
                                          remainingClaimAmount?.startDate
                                        ).format('DD-MM-YYYY')}
                                      </h5>
                                    </Grid>
                                  )}
                                  {remainingClaimAmount?.endDate && (
                                    <Grid item xs={6} lg={3} md={4} sm={4}>
                                      <h4>Entitlement End Date</h4>
                                      <h5>
                                        {moment(
                                          remainingClaimAmount?.endDate
                                        ).format('DD-MM-YYYY')}
                                      </h5>
                                    </Grid>
                                  )}
                                </React.Fragment>
                              )}
                              
                              {userData?.userDetails?.contractEndDate && (
                                <Grid item xs={6} lg={3} md={4} sm={4}>
                                  <h4> Date of Joining</h4>
                                  <h5>
                                    {moment(
                                      userData?.userDetails?.dateOfJoining
                                    ).format('DD-MM-YYYY')}
                                  </h5>
                                </Grid>
                              )}
                              {userData?.userDetails?.contractEndDate && (
                                <Grid item xs={6} lg={3} md={4} sm={4}>
                                  <h4> End of Contract Date</h4>
                                  <h5>
                                    {moment(
                                      userData?.userDetails?.contractEndDate
                                    ).format('DD-MM-YYYY')}
                                  </h5>
                                </Grid>
                              )}
                            </Grid>

                            <Grid item xs={12} lg={3} md={4} sm={4}>
                              <label htmlFor="">Claim Type *</label>
                              <FormControl
                                sx={{ minWidth: 120 }}
                                fullWidth
                                size="small"
                              >
                                <Select
                                  value={input.claimType}
                                  onChange={(e) => {
                                    handleChangeInput(i, e);
                                    fieldsEmpty(i, e);
                                  }}
                                  name="claimType"
                                  displayEmpty
                                  inputProps={{
                                    'aria-label': 'Without label',
                                  }}
                                  variant="outlined"
                                  autoWidth
                                >
                                  {claimTypes.map((each, i) => (
                                    <MenuItem key={i} value={each}>
                                      {each}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>

                            {input.claimType == 'Flexible Benefit' && (
                              <Grid item xs={12} lg={4} md={4} sm={4}>
                                <label htmlFor="">Benefit Type *</label>
                                <FormControl
                                  sx={{ minWidth: 120 }}
                                  fullWidth
                                  size="small"
                                >
                                  <Select
                                    value={input.benefitType}
                                    onChange={(e) => handleChangeInput(i, e)}
                                    displayEmpty
                                    name="benefitType"
                                    inputProps={{
                                      'aria-label': 'Without label',
                                    }}
                                    variant="outlined"
                                    autoWidth
                                  >
                                    {benefitTypes.map((each, i) => (
                                      <MenuItem key={i} value={each}>
                                        {each.benefitName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            )}
                            {input.claimType !== 'Maternity Benefit' && (
                              <Grid item xs={12} lg={4} md={4} sm={4}>
                                <label htmlFor="">
                                  {input.claimType == 'Flexible Benefit'
                                    ? 'Claim Amount'
                                    : 'Receipt Amount'}{' '}
                                  *
                                </label>
                                <TextField
                                  disabled={
                                    input.claimType == '' ? true : false
                                  }
                                  required
                                  type="number"
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  value={input.claimAmount}
                                  name="claimAmount"
                                  fullWidth
                                  onChange={(e) =>
                                    handleChangeInput(i, e, null, 'number')
                                  }
                                />
                              </Grid>
                            )}
                            {input.claimType == 'Health Screening' && (
                              <>
                             <Grid item xs={12} lg={4} md={4} sm={4}>
                             <label htmlFor="">
                             Co Payment
                             </label>
                             <TextField
                               disabled={
                                 true
                               }
                               required
                               type="number"
                               variant="outlined"
                               color="primary"
                               size="small"
                               value={remainingClaimAmount.isFirstHealthScreening && input.claimAmount > 20 && i == 0 ? remainingClaimAmount.hasNoPendingHealthScreening ?  20.00 : 0.00 : 0.00 }
                               name="copayment"
                               fullWidth
                              //  onChange={(e) =>
                              //    handleChangeInput(i, e, null, 'number')
                              //  }
                             />
                            </Grid>
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                            <label htmlFor="">
                            Total Amount
                            </label>
                            <TextField
                              disabled={
                                true
                              }
                              required
                              type="number"
                              variant="outlined"
                              color="primary"
                              size="small"
                              value={remainingClaimAmount.isFirstHealthScreening && input.claimAmount > 20  && i == 0 ? remainingClaimAmount.hasNoPendingHealthScreening ? input.claimAmount-20 > remainingClaimAmount.healthScreeningBalanceAmount  ? remainingClaimAmount.healthScreeningBalanceAmount : input.claimAmount-20 : input.claimAmount : input.claimAmount}
                              name="totalpayment" 
                              fullWidth
                              // onChange={(e) =>
                              //   handleChangeInput(i, e, null, 'number')
                              // }
                            />
                           </Grid>
                           </>
                            )}
                            {input.claimType !== 'Maternity Benefit' && (
                              <React.Fragment>
                                <Grid item xs={12} lg={4} md={4} sm={4}>
                                  <label htmlFor="">Receipt Date *</label>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DatePicker
                                      // shouldDisableDate={disablePastYears()}
                                      shouldDisableDate={EnableDateInRangeOnly(...handleDateRange(input))}
                                      // disableFuture
                                      value={input.receiptDate}
                                      onChange={(e) =>
                                        handleChangeInput(i, e, 'receiptDate')
                                      }
                                      inputFormat="dd-MM-yyyy"
                                      // minDate={parseISO('20/05/2022')}
                                      // maxDate='20-05-2023'
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          fullWidth
                                          variant="outlined"
                                          {...params}
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} lg={4} md={4} sm={4}>
                                  <label htmlFor="">Receipt Number *</label>
                                  <TextField
                                    required
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    value={input.receiptNumber}
                                    name="receiptNumber"
                                    fullWidth
                                    onChange={(e) => handleChangeInput(i, e)}
                                  />
                                </Grid>
                              </React.Fragment>
                            )}

                            {input.claimType == 'Maternity Benefit' && (
                              <React.Fragment>
                                <Grid item xs={12} lg={4} md={4} sm={4}>
                                  <label htmlFor="">Child Birthdate *</label>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DatePicker
                                      shouldDisableDate={disablePrevDates()}
                                      disableFuture
                                      value={input.childBirthDate}
                                      onChange={(e) =>
                                        handleChangeInput(
                                          i,
                                          e,
                                          'childBirthDate'
                                        )
                                      }
                                      inputFormat="dd-MM-yyyy"
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          fullWidth
                                          variant="outlined"
                                          {...params}
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} lg={4} md={4} sm={4}>
                                  <label htmlFor="">
                                    Child Birth Certificate Number *
                                  </label>
                                  <TextField
                                    required
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    value={input.birthCertificateNumber}
                                    name="birthCertificateNumber"
                                    fullWidth
                                    onChange={(e) => handleChangeInput(i, e)}
                                  />
                                </Grid>
                              </React.Fragment>
                            )}

                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              {/* <Stack direction='row' alignItems='center'> */}
                              <label htmlFor="icon-button-file">
                                {input.claimType == 'Maternity Benefit'
                                  ? 'Child Birth Certificate'
                                  : 'Attachment'}
                                *
                              </label>
                              <input
                                multiple
                                accept="image/pdf*"
                                type="file"
                                onChange={(e) =>
                                  onFileChange(e, input.claimReferenceNumber)
                                }
                              />

                              {showImgName > 0 &&
                                [...mappedArr].map(([key, value]) => {
                                  return (
                                    <span key={key}>
                                      {input.claimReferenceNumber === key &&
                                        value &&
                                        value.map((each, i) => (
                                          <div
                                            key={i}
                                            className="file-path-wrap"
                                          >
                                            {/* <h6>{each.split('/')[4]}</h6> */}
                                            <h6>{input.claimReferenceNumber}_{i+1}</h6>
                                            <IconButton
                                              onClick={() =>
                                                removeAttachement(
                                                  i,
                                                  input.claimReferenceNumber
                                                )
                                              }
                                            >
                                              <CancelRoundedIcon />
                                            </IconButton>
                                          </div>
                                        ))}
                                    </span>
                                  );
                                })}
                            </Grid>
                          </Grid>
                        </CardUI>
                      </Grid>
                      <Grid
                        xs={1}
                        className="form-main-actions"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <Grid>
                          <IconButton onClick={() => deleteForm(i)}>
                            <DeleteIcon
                              style={{
                                border: '1px solid #7db77d',
                                padding: '0.3em',
                                borderRadius: '8px',
                                color: '#7db77d',
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid>
                          <IconButton
                            onClick={() =>
                              copyForm(i, input, input.claimReferenceNumber)
                            }
                          >
                            <ContentCopyIcon
                              style={{
                                border: '1px solid #7db77d',
                                padding: '0.3em',
                                borderRadius: '8px',
                                color: '#7db77d',
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid>
                          <IconButton>
                            <AddIcon
                              onClick={() =>
                                addFormFields(input.claimReferenceNumber)
                              }
                              style={{
                                border: '1px solid #7db77d',
                                padding: '0.3em',
                                borderRadius: '8px',
                                color: '#7db77d',
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                <Grid style={{ position: 'relative' }}>
                  <Footer
                    type={type}
                    disabledSubmit={formSubmitted}
                    onclick={() => submitData('PENDING')}
                    onSaveDraft={() => submitData('DRAFT')}
                    buttonClicked = {buttonClicked}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Modal
        show={isBackdropOpen}
        onCancel={closeBackdropHandler}
        header={<h1>Success</h1>}
        contentClass="modal-content"
        footerClass="footer-content"
        submitButton={
          <ButtonUI
            backgroundColor="#00AC94"
            color="#ffffff"
            size="small"
            padding="3px 20px"
            onclick={closeBackdropHandler}
          >
            Ok
          </ButtonUI>
        }
      >
        <h4>{successMessage}</h4>
      </Modal>
    </React.Fragment>
  );
};
export default FlexiClaim;
