export const GET_ALL_CURRENCY_CONVERSION_LIST_REQUEST = 'GET_ALL_CURRENCY_CONVERSION_LIST_REQUEST';
export const GET_ALL_CURRENCY_CONVERSION_LIST_SUCCESS = 'GET_ALL_CURRENCY_CONVERSION_LIST_SUCCESS';
export const GET_ALL_CURRENCY_CONVERSION_LIST_FAILURE = 'GET_ALL_CURRENCY_CONVERSION_LIST_FAILURE';

export const GET_CURRENCY_CONVERSION_FORM_DATA_REQUEST = 'GET_CURRENCY_CONVERSION_FORM_DATA_REQUEST';
export const GET_CURRENCY_CONVERSION_FORM_DATA_SUCCESS = 'GET_CURRENCY_CONVERSION_FORM_DATA_SUCCESS';
export const GET_CURRENCY_CONVERSION_FORM_DATA_FAILURE = 'GET_CURRENCY_CONVERSION_FORM_DATA_FAILURE';

export const POST_CURRENCY_CONVERSION_FORM_DATA_REQUEST = 'POST_CURRENCY_CONVERSION_FORM_DATA_REQUEST';
export const POST_CURRENCY_CONVERSION_FORM_DATA_SUCCESS = 'POST_CURRENCY_CONVERSION_FORM_DATA_SUCCESS';
export const POST_CURRENCY_CONVERSION_FORM_DATA_FAILURE = 'POST_CURRENCY_CONVERSION_FORM_DATA_FAILURE';


export const UPDATE_CURRENCY_CONVERSION_FORM_DATA_REQUEST = 'UPDATE_CURRENCY_CONVERSION_FORM_DATA_REQUEST';
export const UPDATE_CURRENCY_CONVERSION_FORM_DATA_SUCCESS = 'UPDATE_CURRENCY_CONVERSION_FORM_DATA_SUCCESS';
export const UPDATE_CURRENCY_CONVERSION_FORM_DATA_FAILURE = 'UPDATE_CURRENCY_CONVERSION_FORM_DATA_FAILURE';