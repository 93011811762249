import React, { useEffect } from 'react';
import BenefitView from './benefit-view-form/benefit-view.component';
import BenefitEdit from './benefit-edit-form/benefit-edit.component';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBenefitsDetails,
  resetBenefits,
} from '../../../../redux/benefits/action';
import { APIRequestStatus, LoadingStatus } from '../../../../constants/common';
import TryAgain from '../../../molecules/ErrorState/error.component';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';

const CreateBenefitsComponent = (props) => {
  const { viewMode, setViewMode } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const idForBenefitDetails = pathNames[pathNames.length - 1];

  const { currentUserRoleId } = useSelector((state) => state.main);
  const {
    benefitsData,
    requestProcessing: {
      [APIRequestStatus.GET_BENEFITS_DETAILS]: detailsLoading,
    },
  } = useSelector((state) => state.benefits);

  useEffect(() => {
    if (currentUserRoleId) {
      if (!isNaN(idForBenefitDetails)) {
        dispatch(getBenefitsDetails(idForBenefitDetails, currentUserRoleId));
        setViewMode(true);
      }
    }
    return () => {
      setViewMode(false);
      dispatch(resetBenefits());
    };
  }, [idForBenefitDetails, currentUserRoleId]);

  if (detailsLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }

  return (
    <>
      {viewMode ? (
        <>
          <CircularLoader show={detailsLoading === LoadingStatus.Loading} />
          <BenefitView setViewMode={setViewMode} benefitsData={benefitsData} />
        </>
      ) : (
        <BenefitEdit
          setViewMode={setViewMode}
          isNewBenefit={isNaN(idForBenefitDetails)}
          benefitsData={benefitsData}
        />
      )}
    </>
  );
};

export default CreateBenefitsComponent;
