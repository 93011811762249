import React, { useCallback, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import TabBar from '../../../atoms/Tabs/TabBar';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEducationFormConfig,
  getEmployeeFormConfig,
  getUserBloodGroup,
  getUserCategory,
  getUserCostCentre,
  getUserDesignation,
  getUserDetails,
  getUserDocumentsList,
  getUserEmploymentTypes,
  getUserFunction,
  getUserGender,
  getUserNationality,
  getUserOrgDivisions,
  getUserRace,
  getUserReligion,
  getUserWorkAuthorization,
  getUserWorkStatus,
  postEducationInfo,
  postEmployeeInfo,
  postUserInfo,
  publishUser,
  resetUserInfo,
  resetWorkAuth,
  updateUserInfo,
} from '../../../redux/userOnboarding/action';
import { isEmpty, isEqual } from 'lodash';
import {
  EmpType,
  UserInfoFormFields,
  bankInfoFields,
  getMandatoryFields,
  getValidationErrors,
  totalForComponents,
} from '../Organization/utils/userOnboarding/userOnboarding.util';
import FormEdit from '../common/Form/FormEdit';
import FormView from '../common/Form/FormView';
import {
  FormFieldNames,
  ToastMessages,
  UserOnboardingTabs,
  WorkAuthorisation,
  autoGenerateEmpId,
  documents,
  educationHistory,
  employeeHistory,
  enitilement,
  payrollAssignment,
  personalDetails,
  seperation,
  stringSubstitute,
  userInfo,
  userOnboarding,
  workDetails,
} from '../Organization/constants/onboarding.constants';
import { useOutletContext } from 'react-router';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  APIRequestStatus,
  ComponentInputType,
  FormFieldTypes,
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../../constants/common';
import {
  getSaveButtonText,
  getUserInfoToast,
  isNotEmptyString,
} from '../Organization/utils/onboarding.util';
import ActionHeader from '../Organization/ActionHeader/ActionHeader';
import { getLatestUserChangeDetails, showToast } from '../../../utils/common.util';
import {
  currentDate,
  firstDayOfMonth,
  getDate,
  utcToLocal,
} from '../../../utils/formatter';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import { permissionsObj } from '../Organization/RolesAndPermissions/constants/roles-permissions.constants';
import TryAgain from '../../molecules/ErrorState/error.component';
import UserDocumentsList from './UserDocumentList';
import {
  getLocationInfo,
  getRolesList,
  getSubDepartmentInfo,
} from '../../../redux/onboarding/action';
import { getDropdownListValue } from '../common/Form/util/form.util';
import EmployeeTab from './EmployeementTab/EmployeeTab';
import EducationTab from './EducationTab/EducationTab';
import { OnboardingServices } from '../../../services/onboarding';
import {
  getComponentsDetails,
  postComponentsInfo,
  resetComponents,
  updateComponentsInfo,
} from '../../../redux/payrollAssignment/action';
import ModalDialog from '../../../atoms/Modal/ModalDialog';
import { SalaryType } from '../../Payroll/Constant/Constant';
import ManagePayroll from './PayrollTab/ManagePayrollTab/ManagePayroll';
import UserEntitlement from './UserEntitlement/UserEntitlement';
import MyJourneyDashboard from './MyJourney/my-journey-dashboard.component';
import useDidMountEffect from '../../../customHooks/useDidMountEffect';

export default function AddUser() {
  const dispatch = useDispatch();
  const {
    userInfo: { data: formData, id, isPublished },
    requestProcessing,
    userDocumentsList,
  } = useSelector((state) => state.userOnboarding);
  const { userProfilePermissions, myProfilePermissions } = useSelector(
    (state) => state.modulePermissions
  );
  const { userInfo: loggedInUserDetails, currentUserRoleId } = useSelector(
    (state) => state.main
  );
  const requestProcessingOnboarding = useSelector(
    (state) => state.onBoarding.requestProcessing
  );
  const requestProcessingUserOnboarding = useSelector(
    (state) => state.userOnboarding.requestProcessing
  );
  const { rolesList, departments, locations, users, subDepartments } =
    useSelector((state) => state.onBoarding);
  const {
    userGender,
    userBloodGroup,
    userWorkStatus,
    userDesignation,
    userCostCentre,
    orgDivisions,
    employmentTypes,
    userNationality,
    userReligion,
    userRace,
    userFunction,
    userCategory,
    userWorkAuthorization,
    employeeFormConfig,
    empFormData,
    eduFormData,
    eduFormConfig,
  } = useSelector((state) => state.userOnboarding);
  const {
    payStrData,
    salaryType,
    isEmptyData,
    additionComponents: additions,
    deductionComponents: deductions,
    employeeBenifits,
  } = useSelector((state) => state.payrollAssignment);

  const { logoImage } = useSelector((state) => state && state.onBoarding);
  const [empValidationErrors, setEmpValidationErrors] = useState(
    Array(employeeFormConfig?.length).fill({})
  );
  const [eduValidationErrors, setEduValidationErrors] = useState(
    Array(eduFormConfig?.length).fill({})
  );
  const [empData, setEmpData] = useState([]);
  const [eduData, setEduData] = useState([]);
  const [viewMode, setViewMode] = useOutletContext();
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const idForUserDetails = pathNames[pathNames.length - 1];
  const navigate = useNavigate();

  const [formInfo, setFormInfo] = useState(formData);
  const [validationErrors, setValidationErrors] = useState({});
  const [disabledSave, setDisabledSave] = useState(false);
  const [errorTabNames, setErrorTabNames] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [fileRes, setFileRes] = useState({});
  const [deptDropDownOption, setDeptDropDownOption] = useState([]);
  const [subDeptDropDownOption, setsubDeptDropDownOption] = useState([]);
  const [activatedUserDropDown, setActivatedUserDropDown] = useState([]);
  const [assignUserTagDropDown, setAssignUserTagDropDown] = useState([]);
  const [payStrInfo, setPayStrInfo] = useState({});
  const [additionComponents, setAdditionComponents] = useState([]);
  const [deductionComponents, setDeductionComponents] = useState([]);
  const [basicComponents, setBasicComponents] = useState(salaryType);
  const [postLoading, setPostLoading] = useState(-1);
  const [payrollError, setPayrollError] = useState({});
  const [isPayrollChange, setIsPayrollChange] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [emergencyRelationOption, setEmergencyRelationOption] = useState([]);
  const [bankDetails, setBankDetails] = useState({});
  const [userLog, setUserLog] = useState([]);

  useEffect(() => {
    setBasicComponents(salaryType);
  }, [salaryType]);

  useEffect(() => {
    setAdditionComponents(additions);
  }, [additions]);

  useEffect(() => {
    setDeductionComponents(deductions);
  }, [deductions]);

  useEffect(() => {
    setPayStrInfo(payStrData);
  }, [payStrData]);

  useEffect(() => {
    if (id && isEmpty(userDocumentsList)) {
      dispatch(getUserDocumentsList(id));
    }
  }, [currentUserRoleId, id]);

  useEffect(() => {
    setEmpData(empFormData);
  }, [empFormData]);

  useEffect(() => {
    setEduData(eduFormData);
  }, [eduFormData]);

  useEffect(async () => {
    if (!isNaN(idForUserDetails)) {
      dispatch(
        getUserDetails(
          idForUserDetails,
          currentUserRoleId,
          loggedInUserDetails?.id
        )
      );
      dispatch(getEmployeeFormConfig(idForUserDetails));
      dispatch(getEducationFormConfig(idForUserDetails));
      dispatch(getComponentsDetails(payStrData?.id, idForUserDetails));
      const response = await new OnboardingServices().getBankDetails(
        idForUserDetails
      );
      setBankDetails(response || {});
      const userLogs = await new OnboardingServices().getUserLogs(
        idForUserDetails
      );
      setUserLog(userLogs);
      setViewMode(true);
    } else {
      dispatch(getEmployeeFormConfig());
      dispatch(getEducationFormConfig());
    }
    return () => {
      setViewMode(false);
      dispatch(resetUserInfo());
      dispatch(resetComponents());
    };
  }, [idForUserDetails]);

  const handleChangeEmploymentType = useCallback(
    (event) => {
      const key = event.target.name;
      const value = event.target.value;
      if (value.id !== EmpType.Contract) {
        setFormInfo((prev) => {
          const copy = { ...prev };
          delete copy[FormFieldNames.ContractEndDate];
          delete copy[FormFieldNames.ContractStartDate];
          return copy;
        });
      }
      if (value[autoGenerateEmpId]) {
        setFormInfo((prev) => {
          const copy = { ...prev };
          delete copy[FormFieldNames.EmployeeId];
          return copy;
        });
        setValidationErrors((prev) => ({
          ...prev,
          [key]: '',
          [FormFieldNames.ContractStartDate]: '',
          [FormFieldNames.ContractEndDate]: '',
          [FormFieldNames.EmployeeId]: '',
        }));
      }
      if (
        formData?.[FormFieldNames.EmploymentTypes]?.id === value?.id &&
        formData?.[FormFieldNames.EmploymentTypes] === null
      ) {
        setFormInfo((prev) => {
          const copy = { ...prev };
          delete copy[FormFieldNames.EmploymentConvertedDate];
          return copy;
        });
      } else {
        if (
          formData?.[FormFieldNames.EmploymentConvertedDate] !== null &&
          formData?.[FormFieldNames.EmploymentTypes]?.id === value?.id
        ) {
          setFormInfo((prev) => ({
            ...prev,
            [FormFieldNames.EmploymentConvertedDate]:
              formData?.[FormFieldNames.EmploymentConvertedDate],
          }));
        } else {
          setFormInfo((prev) => ({
            ...prev,
            [FormFieldNames.EmploymentConvertedDate]: null,
          }));
        }
      }
      setFormInfo((prev) => ({
        ...prev,
        [key]: value,
      }));
    },
    [formInfo[FormFieldNames.EmploymentTypes]]
  );

  const handleChangeWorkAuth = useCallback(
    (event) => {
      const key = event.target.name;
      const value = event.target.value;
      setFormInfo((prev) => {
        const copy = { ...prev };
        delete copy[FormFieldNames.StartDate];
        delete copy[FormFieldNames.EndDate];
        delete copy[FormFieldNames.NRICNumber];
        delete copy[FormFieldNames.FINNumber];
        return copy;
      });
      setValidationErrors((prev) => ({
        ...prev,
        [key]: '',
        [FormFieldNames.NRICNumber]: '',
        [FormFieldNames.FINNumber]: '',
        [FormFieldNames.StartDate]: '',
        [FormFieldNames.EndDate]: '',
      }));
      setFormInfo((prev) => ({ ...prev, [key]: value }));
    },
    [formInfo[FormFieldNames.WorkAuthorisation]]
  );

  const handleChangeNationality = useCallback(
    (event) => {
      dispatch(resetWorkAuth());
      const key = event.target.name;
      const value = event.target.value;
      setFormInfo((prev) => {
        {
          const copy = { ...prev };
          delete copy[FormFieldNames.StartDate];
          delete copy[FormFieldNames.EndDate];
          delete copy[FormFieldNames.NRICNumber];
          delete copy[FormFieldNames.FINNumber];
          delete copy[FormFieldNames.WorkAuthorisation];
          return copy;
        }
      });
      setFormInfo((prev) => ({
        ...prev,
        [FormFieldNames.WorkAuthorisation]: null,
      }));
      setFormInfo((prev) => ({ ...prev, [key]: value }));
      setValidationErrors((prev) => ({
        ...prev,
        [key]: '',
        [FormFieldNames.WorkAuthorisation]: '',
        [FormFieldNames.NRICNumber]: '',
        [FormFieldNames.FINNumber]: '',
        [FormFieldNames.StartDate]: '',
        [FormFieldNames.EndDate]: '',
      }));
    },
    [formInfo[FormFieldNames.Nationality]]
  );

  const workDetailsEditDisabled =
    (Number(idForUserDetails) === loggedInUserDetails?.id
      ? !myProfilePermissions[workDetails]?.[permissionsObj.allowEdit]
      : !userProfilePermissions[workDetails]?.[permissionsObj.allowEdit]) ??
    true;

  const userDetailsEditDisabled =
    (Number(idForUserDetails) === loggedInUserDetails?.id
      ? !myProfilePermissions[userInfo]?.[permissionsObj.allowEdit]
      : !userProfilePermissions[userInfo]?.[permissionsObj.allowEdit]) ?? true;

  const personalDetailsEditDisabled =
    (Number(idForUserDetails) === loggedInUserDetails?.id
      ? !myProfilePermissions[personalDetails]?.[permissionsObj.allowEdit]
      : !userProfilePermissions[personalDetails]?.[permissionsObj.allowEdit]) ??
    true;

  const documentsEditDisabled =
    (Number(idForUserDetails) === loggedInUserDetails?.id
      ? !myProfilePermissions[documents]?.[permissionsObj.allowEdit]
      : !userProfilePermissions[documents]?.[permissionsObj.allowEdit]) ?? true;

  const educationHistoryEditDisabled =
    (Number(idForUserDetails) === loggedInUserDetails?.id
      ? !myProfilePermissions[educationHistory]?.[permissionsObj.allowEdit]
      : !userProfilePermissions[educationHistory]?.[
          permissionsObj.allowEdit
        ]) ?? true;

  const employeeHistoryEditDisabled =
    (Number(idForUserDetails) === loggedInUserDetails?.id
      ? !myProfilePermissions[employeeHistory]?.[permissionsObj.allowEdit]
      : !userProfilePermissions[employeeHistory]?.[permissionsObj.allowEdit]) ??
    true;

  const payrollEditDisabled =
    (Number(idForUserDetails) === loggedInUserDetails?.id
      ? !myProfilePermissions[payrollAssignment]?.[permissionsObj.allowEdit]
      : !userProfilePermissions[payrollAssignment]?.[
          permissionsObj.allowEdit
        ]) ?? true;

  const userEntitlementEditDisabled =
    (Number(idForUserDetails) === loggedInUserDetails?.id
      ? !myProfilePermissions[enitilement]?.[permissionsObj.allowEdit]
      : !userProfilePermissions[enitilement]?.[permissionsObj.allowEdit]) ??
    true;

  let userOnboardingFields = useMemo(() => {
    return UserInfoFormFields[UserOnboardingTabs.userInfo].reduce(
      (acc, item) => {
        let temp = item;
        if (!item.isDisabled) {
          temp = { ...item, isDisabled: userDetailsEditDisabled };
        }
        switch (item.fieldRefName) {
          case FormFieldNames.Nationality: {
            temp = { ...temp, onChange: handleChangeNationality };
            break;
          }
          case FormFieldNames.WorkAuthorisation: {
            temp = {
              ...temp,
              isDisabled:
                userDetailsEditDisabled ||
                !formInfo[FormFieldNames.Nationality],
              onChange: handleChangeWorkAuth,
            };
            break;
          }
          case FormFieldNames.NRICNumber: {
            temp = {
              // TODO: Need to remove id condition,
              ...temp,
              isHidden: !formInfo[FormFieldNames.Nationality],
              fieldLabel:
                (formInfo[FormFieldNames.Nationality]?.countryName &&
                  `${formInfo[FormFieldNames.Nationality]?.countryName} ${
                    item.fieldLabel
                  }`) ||
                item.fieldLabel,
              isDisabled:
                userDetailsEditDisabled ||
                formInfo[FormFieldNames.Nationality]?.id === 1,
              isRequired:
                formInfo[FormFieldNames.WorkAuthorisation]?.[
                  WorkAuthorisation.isNricMandatory
                ],
            };
            break;
          }
          case FormFieldNames.PassportNumber: {
            temp = {
              ...temp,
              isRequired:
                formInfo[FormFieldNames.WorkAuthorisation]?.[
                  WorkAuthorisation.isPassportMandatory
                ],
            };
            break;
          }
          case FormFieldNames.FINNumber: {
            temp = {
              ...temp,
              isHidden: !formInfo[FormFieldNames.Nationality],
              isDisabled:
                userDetailsEditDisabled ||
                !formInfo[FormFieldNames.WorkAuthorisation]?.[
                  WorkAuthorisation.isFinMandatory
                ],
              isRequired:
                !!formInfo[FormFieldNames.WorkAuthorisation]?.[
                  WorkAuthorisation.isFinMandatory
                ],
              fieldLabel:
                formInfo[FormFieldNames.WorkAuthorisation]?.[
                  WorkAuthorisation.nameOfRequiredDocument
                ] || item.fieldLabel,
              placeholderText: `Type ${
                formInfo[FormFieldNames.WorkAuthorisation]?.[
                  WorkAuthorisation.nameOfRequiredDocument
                ] || item.placeholderText
              }`,
            };
            break;
          }
          case FormFieldNames.EndDate: {
            temp = {
              ...temp,
              isHidden: !formInfo[FormFieldNames.Nationality],
              isDisabled:
                userDetailsEditDisabled ||
                !formInfo[FormFieldNames.WorkAuthorisation]?.[
                  WorkAuthorisation.nameOfRequiredEndDate
                ],
              isRequired:
                !!formInfo[FormFieldNames.WorkAuthorisation]?.[
                  WorkAuthorisation.nameOfRequiredEndDate
                ],
              fieldLabel:
                formInfo[FormFieldNames.WorkAuthorisation]?.[
                  WorkAuthorisation.nameOfRequiredEndDate
                ] || item.fieldLabel,
            };
            break;
          }
          case FormFieldNames.StartDate: {
            temp = {
              ...temp,
              isHidden: !formInfo[FormFieldNames.Nationality],
              isDisabled:
                userDetailsEditDisabled ||
                !formInfo[FormFieldNames.WorkAuthorisation]?.[
                  WorkAuthorisation.nameOfRequiredStartDate
                ],
              isRequired:
                !!formInfo[FormFieldNames.WorkAuthorisation]?.[
                  WorkAuthorisation.nameOfRequiredStartDate
                ],
              fieldLabel:
                formInfo[FormFieldNames.WorkAuthorisation]?.[
                  WorkAuthorisation.nameOfRequiredStartDate
                ] || item.fieldLabel,
            };
            break;
          }
          default: {
            break;
          }
        }
        if (!temp.isHidden) {
          acc.push(temp);
        }
        return acc;
      },
      []
    );
  }, [
    formInfo[FormFieldNames.Nationality],
    formInfo[FormFieldNames.WorkAuthorisation],
    myProfilePermissions[userInfo],
    userProfilePermissions[userInfo],
  ]);

  let personalDetailsFields = UserInfoFormFields[
    UserOnboardingTabs.personal
  ].map((item) => {
    if (!item.isDisabled) {
      return { ...item, isDisabled: personalDetailsEditDisabled };
    }
    return item;
  });

  let workDetailsFields = UserInfoFormFields[
    UserOnboardingTabs.workDetails
  ].reduce((acc, item) => {
    let temp = item;
    if (!item.isDisabled) {
      switch (item.fieldRefName) {
        case FormFieldNames.ProbationConfirmation: {
          temp = {
            ...item,
            isDisabled: workDetailsEditDisabled,
            minDate: getDate(formInfo?.[FormFieldNames.DateOfJoining]),
          };
          break;
        }
        case FormFieldNames.ContractEndDate:
        case FormFieldNames.ContractStartDate: {
          temp = {
            ...item,
            isDisabled: workDetailsEditDisabled,
            isHidden:
              formInfo[FormFieldNames.EmploymentTypes]?.id !==
                EmpType.Contract ?? true,
            isRequired: true,
          };
          break;
        }
        case FormFieldNames.EmploymentTypes: {
          temp = {
            ...temp,
            isDisabled: workDetailsEditDisabled,
            onChange: handleChangeEmploymentType,
          };
          break;
        }
        case FormFieldNames.EmploymentConvertedDate: {
          temp = {
            ...temp,
            isRequired:
              formData?.[FormFieldNames.EmploymentConvertedDate] !== null ||
              (formData?.[FormFieldNames.EmploymentTypes]?.id !==
                formInfo?.[FormFieldNames.EmploymentTypes]?.id &&
                formData?.[FormFieldNames.EmploymentTypes] !== null)
                ? true
                : false,
            isHidden:
              formData?.[FormFieldNames.EmploymentConvertedDate] !== null ||
              (formData?.[FormFieldNames.EmploymentTypes]?.id !==
                formInfo[FormFieldNames.EmploymentTypes]?.id &&
                formData?.[FormFieldNames.EmploymentTypes] !== null)
                ? false
                : true,
          };
          break;
        }
        case FormFieldNames.ReportingTo: {
          temp = { 
            ...temp,
            fieldLabel: `${logoImage?.jobFunctionObject?.RO}` || 'Reporting To',
            isDisabled: workDetailsEditDisabled
          };
          break;
        }
        case FormFieldNames.SecondaryReportingTo: {
          temp = { 
            ...temp,
            fieldLabel: `${logoImage?.jobFunctionObject?.CSO}` || 'Secondary Reporting To',
            isDisabled: workDetailsEditDisabled
          };
          break;
        }
        default: {
          temp = { ...item, isDisabled: workDetailsEditDisabled };
          break;
        }
      }
    }
    if (
      item.fieldRefName === FormFieldNames.EmployeeId &&
      !(formInfo?.[FormFieldNames.EmploymentTypes]?.[autoGenerateEmpId] ?? true)
    ) {
      temp = {
        ...item,
        isDisabled: false,
        isRequired: true,
        placeholderText: 'Enter pre-allocated ID',
      };
    }
    if (!temp.isHidden) {
      acc.push(temp);
    }
    return acc;
  }, []);

  let bankFields = useMemo(() => {
    return bankInfoFields.reduce((acc, item) => {
      let temp = item;
      if (!item.isDisabled) {
        temp = {
          ...item,
          isDisabled: !id ? true : false || userDetailsEditDisabled,
        };
      }
      if (!temp.isHidden) {
        acc.push(temp);
      }
      return acc;
    }, []);
  }, [myProfilePermissions[userInfo], userProfilePermissions[userInfo], id]);

  const educationHistoryFields = UserInfoFormFields[
    UserOnboardingTabs.educationHistory
  ].map((item) => {
    if (!item.isDisabled) {
      return { ...item, isDisabled: educationHistoryEditDisabled };
    }
    return item;
  });

  const employeeHistoryFields = UserInfoFormFields[
    UserOnboardingTabs.employeeHistory
  ].map((item) => {
    if (!item.isDisabled) {
      return { ...item, isDisabled: employeeHistoryEditDisabled };
    }
    return item;
  });

  const payrollTabFields = UserInfoFormFields[
    UserOnboardingTabs.payrollAssignment
  ].map((item) => {
    if (!item.isDisabled) {
      return { ...item, isDisabled: payrollEditDisabled };
    }
    return item;
  });

  const updateFieldsFromUserLogs = (fields) => {
    const changeUserLog = getLatestUserChangeDetails(userLog);
    return fields.map(field => {
      if (changeUserLog[field.fieldRefName] && changeUserLog[field.fieldRefName].value) {
        return {
          ...field,
          tooltipText: 
          <>
          <Typography color={'neutral.dark60'} fontSize={14}>Last Value: {changeUserLog[field.fieldRefName].value} </Typography>
          <Typography color={'neutral.dark60'} fontSize={14}>Changed On: {utcToLocal(changeUserLog[field.fieldRefName].updatedAt,'YYYY-MM-DD HH:mm',
            'YYYY-MM-DD hh:mm a')} </Typography>
          </>
        };
      }
      return field;
    });
  };
  
  userOnboardingFields = updateFieldsFromUserLogs(userOnboardingFields);
  personalDetailsFields = updateFieldsFromUserLogs(personalDetailsFields);
  workDetailsFields = updateFieldsFromUserLogs(workDetailsFields);
  bankFields = updateFieldsFromUserLogs(bankFields);

  const requiredFields = useMemo(() => {
    return [
      ...getMandatoryFields(userOnboardingFields),
      ...getMandatoryFields(personalDetailsFields),
      ...getMandatoryFields(workDetailsFields),
    ];
  }, [
    formInfo[FormFieldNames.EmploymentTypes],
    formInfo[FormFieldNames.WorkAuthorisation],
    formInfo[FormFieldNames.Nationality],
  ]);

  const empRequiredFields = useMemo(() => {
    return getMandatoryFields(employeeFormConfig[0]);
  }, [employeeFormConfig]);

  const eduRequiredFields = useMemo(() => {
    return getMandatoryFields(eduFormConfig[0]);
  }, [eduFormConfig]);

  useDidMountEffect(() => {
    if (formInfo[FormFieldNames.Departments]) {
      handleDropdownClick(FormFieldNames.SubDepartments);
    }
  }, [formInfo[FormFieldNames.Departments]]);

  useDidMountEffect(() => {
    if (formInfo[FormFieldNames.SubDepartments]) {
      handleDropdownClick(FormFieldNames.Departments);
    }
  }, [formInfo[FormFieldNames.SubDepartments]]);

  const getEmpErrors = () => {
    const empErrors = [];
    empData.forEach((formData) => {
      const errors = getValidationErrors(formData, empRequiredFields);
      empErrors.push(errors);
    });
    return empErrors;
  };
  const getEduErrors = () => {
    const eduErrors = [];
    eduData.forEach((formData) => {
      const errors = getValidationErrors(formData, eduRequiredFields);
      eduErrors.push(errors);
    });
    return eduErrors;
  };

  const {
    [APIRequestStatus.POST_USER_INFO]: postLoadingStatus,
    [APIRequestStatus.UPDATE_USER_INFO]: updateLoadingStatus,
    [APIRequestStatus.PUBLISH_USER]: publishLoadingStatus,
    [APIRequestStatus.GET_USER_DETAILS]: userDetailsLoading,
  } = requestProcessing;

  useEffect(() => {
    getUserInfoToast(
      publishLoadingStatus,
      toastMessage.publish,
      toastMessage.publishError
    );
  }, [publishLoadingStatus]);

  useEffect(() => {
    setFormInfo(formData);
  }, [formData]);

  useEffect(() => {
    setErorTabNamesAndFocusedTab();
  }, [validationErrors]);

  const totalAdditions = totalForComponents(additionComponents);
  const totalDeductions = totalForComponents(deductionComponents);

  const getAdditionsErrors = () => {
    const additionErrors = [];
    additionComponents?.forEach((formData) => {
      if (
        formData.inputSourceCode === ComponentInputType.Onetime &&
        (formData.value === null ||
          formData.value === undefined ||
          formData.value === '')
      ) {
        const errors = {
          id: formData.id,
          error: `${formData.name} can't be empty`,
        };
        additionErrors.push(errors);
      }
    });
    return additionErrors;
  };

  const getDeductionsErrors = () => {
    const deductionErrors = [];
    deductionComponents?.forEach((formData) => {
      if (
        formData.inputSourceCode === ComponentInputType.Onetime &&
        (formData.value === null ||
          formData.value === undefined ||
          formData.value === '')
      ) {
        const errors = {
          id: formData.id,
          error: `${formData.name} can't be empty`,
        };
        deductionErrors.push(errors);
      }
    });
    return deductionErrors;
  };

  useEffect(() => {
    setErrorTabNames([]);
  }, [basicComponents, additionComponents, deductionComponents]);

  const handleAllTabsSave = () => {
    if (viewMode) {
      setViewMode(false);
    } else {
      if (isPayrollChange) {
        setshowModal(true);
        setPayStrInfo((prev) => ({
          ...prev,
          effectiveDate: firstDayOfMonth,
        }));
      } else {
        handleSave();
      }
    }
  };

  const handleFinalSave = () => {
    if (viewMode) {
      setViewMode(false);
    } else {
      const additionErrors = getAdditionsErrors();
      const deductionErrors = getDeductionsErrors();
      const basicErrors =
        basicComponents?.salaryTypeRateAmt === null ||
        basicComponents?.salaryTypeRateAmt === undefined ||
        basicComponents?.salaryTypeRateAmt === ''
          ? true
          : false;
      if (
        additionErrors.length > 0 ||
        deductionErrors.length > 0 ||
        basicErrors
      ) {
        showToast('Component value can\'t be empty', ToastThemes.error);
        setPayrollError({ basicErrors, additionErrors, deductionErrors });
        setErrorTabNames((prevErrorTabNames) => {
          return [...prevErrorTabNames, UserOnboardingTabs.payrollAssignment];
        });
      } else if (
        basicComponents?.code !== SalaryType.Hourly &&
        totalDeductions >
          totalAdditions + Number(basicComponents?.salaryTypeRateAmt)
      ) {
        showToast(
          'Deductions cannot be more than additions',
          ToastThemes.error
        );
      } else {
        if (isEmpty(payStrData) && id && isEmptyData) {
          setPostLoading(LoadingStatus.Loading);
          dispatch(
            postComponentsInfo(
              additionComponents,
              deductionComponents,
              basicComponents,
              payStrInfo,
              id
            )
          )
            .then(() => {
              dispatch(getComponentsDetails(payStrInfo.id, idForUserDetails));
              handleSave();
              setPostLoading(LoadingStatus.Success);
            })
            .catch((err) => {
              showToast(err, ToastThemes.error);
              setPostLoading(LoadingStatus.Failure);
            });
        } else {
          if (id) {
            setPostLoading(LoadingStatus.Loading);
            dispatch(
              updateComponentsInfo(
                additionComponents,
                deductionComponents,
                basicComponents,
                payStrInfo,
                id
              )
            )
              .then((success) => {
                if (success) {
                  handleSave();
                  setPostLoading(LoadingStatus.Success);
                  dispatch(
                    getComponentsDetails(payStrInfo.id, idForUserDetails)
                  );
                }
              })
              .catch((err) => {
                showToast(err, ToastThemes.error);
                setPostLoading(LoadingStatus.Failure);
              });
          } else {
            handleSave();
          }
        }
      }
    }
  };

  const handleSave = () => {
    if (viewMode) {
      setViewMode(false);
    } else {
      if (isPublished) {
        const empErrors = getEmpErrors();
        const empErrorIndex = empErrors.findIndex((error) => !isEmpty(error));
        if (empErrorIndex === -1 && id) {
          setPostLoading(LoadingStatus.Loading);
          dispatch(postEmployeeInfo(idForUserDetails, empData))
            .then((success) => {
              if (success) {
                const eduErrors = getEduErrors();
                setPostLoading(LoadingStatus.Success);
                const eduErrorIndex = eduErrors.findIndex(
                  (error) => !isEmpty(error)
                );
                if (eduErrorIndex === -1) {
                  setPostLoading(LoadingStatus.Loading);
                  dispatch(postEducationInfo(idForUserDetails, eduData))
                    .then((success) => {
                      if (success) {
                        saveDraft();
                        setPostLoading(LoadingStatus.Success);
                      }
                    })
                    .catch((err) => {
                      showToast(err, ToastThemes.error);
                      setViewMode(false);
                      setPostLoading(LoadingStatus.Failure);
                    });
                }
                setEduValidationErrors(eduErrors);
              }
            })
            .catch((err) => {
              showToast(err, ToastThemes.error);
              setViewMode(false);
              setPostLoading(LoadingStatus.Failure);
            });
        } else {
          saveDraft();
        }
        setEmpValidationErrors(empErrors);
      } else {
        if (id) {
          setPostLoading(LoadingStatus.Loading);
          dispatch(postEmployeeInfo(idForUserDetails, empData))
            .then((success) => {
              if (success) {
                setPostLoading(LoadingStatus.Success);
                setPostLoading(LoadingStatus.Loading);
                dispatch(postEducationInfo(idForUserDetails, eduData))
                  .then((success) => {
                    if (success) {
                      saveDraft();
                      setPostLoading(LoadingStatus.Success);
                    }
                  })
                  .catch((err) => {
                    showToast(err, ToastThemes.error);
                    setViewMode(false);
                    setPostLoading(LoadingStatus.Failure);
                  });
              }
            })
            .catch((err) => {
              showToast(err, ToastThemes.error);
              setViewMode(false);
              setPostLoading(LoadingStatus.Failure);
            });
        } else {
          saveDraft();
        }
      }
    }
  };

  const handlePublish = useCallback(() => {
    let errors = {};
    if (isEqual(formData, formInfo)) {
      errors = getValidationErrors(formData, requiredFields);
      if (!isEmpty(errors)) {
        showToast(
          ToastMessages.failed.replace(
            stringSubstitute,
            toastMessage.publishError
          ),
          ToastThemes.error
        );
        setValidationErrors(errors);
        if (viewMode) {
          setViewMode(false);
        }
      } else {
        dispatch(publishUser(id)).then((success) => {
          if (success) {
            setViewMode(true);
          }
        });
      }
    } else {
      errors = getValidationErrors(formInfo, requiredFields);
      if (!isEmpty(errors)) {
        showToast(
          ToastMessages.failed.replace(
            stringSubstitute,
            toastMessage.publishError
          ),
          ToastThemes.error
        );
        setValidationErrors(errors);
        if (viewMode) {
          setViewMode(false);
        }
      } else {
        if (isEmpty(formData)) {
          dispatch(postUserInfo(formInfo))
            .then((userId) => {
              showToast(
                ToastMessages.success.replace(
                  stringSubstitute,
                  toastMessage.success
                ),
                ToastThemes.success
              );
              if (userId) {
                navigate(`/${userOnboarding}/${userId}`);
                setFileRes({});
                dispatch(publishUser(userId)).then((success) => {
                  if (success) {
                    setViewMode(true);
                  }
                });
              }
            })
            .catch((err) => {
              showToast(err, ToastThemes.error);
            });
        } else {
          new OnboardingServices().postBankDetails({
            ...bankDetails,
            fkUser: Number(idForUserDetails),
          });
          dispatch(updateUserInfo(id, formInfo))
            .then((success) => {
              showToast(
                ToastMessages.success.replace(
                  stringSubstitute,
                  toastMessage.success
                ),
                ToastThemes.success
              );
              if (success) {
                dispatch(publishUser(id)).then((success) => {
                  if (success) {
                    setViewMode(true);
                    dispatch(getUserDocumentsList(id));
                    setFileRes({});
                  }
                });
              }
            })
            .catch((err) => {
              showToast(err, ToastThemes.error);
            });
        }
      }
    }
  }, [requiredFields, formInfo, formData]);

  const saveDraft = () => {
    if (viewMode) {
      setViewMode(false);
    } else {
      if (isPublished) {
        let errors = {};
        errors = getValidationErrors(formInfo, requiredFields);
        if (!isEmpty(errors)) {
          showToast(
            ToastMessages.failed.replace(
              stringSubstitute,
              toastMessage.publishError
            ),
            ToastThemes.error
          );
          setValidationErrors(errors);
        } else {
          setPostLoading(LoadingStatus.Loading);
          dispatch(updateUserInfo(id, formInfo))
            .then((success) => {
              new OnboardingServices().postBankDetails({
                ...bankDetails,
                fkUser: Number(idForUserDetails),
              });
              showToast(
                ToastMessages.success.replace(
                  stringSubstitute,
                  toastMessage.success
                ),
                ToastThemes.success
              );
              if (success) {
                setViewMode(true);
                setFileRes({});
                setPostLoading(LoadingStatus.Success);
              }
            })
            .catch((err) => {
              showToast(err, ToastThemes.error);
              setPostLoading(LoadingStatus.Failure);
            });
        }
      } else {
        if (isEmpty(formData)) {
          if (isNotEmptyString(formInfo[FormFieldNames.FirstName])) {
            setPostLoading(LoadingStatus.Loading);
            dispatch(postUserInfo(formInfo))
              .then((userId) => {
                showToast(
                  ToastMessages.success.replace(
                    stringSubstitute,
                    toastMessage.success
                  ),
                  ToastThemes.success
                );
                if (userId) {
                  navigate(`/${userOnboarding}/${userId}`);
                  setViewMode(true);
                  setFileRes({});
                  setPostLoading(LoadingStatus.Success);
                }
              })
              .catch((err) => {
                showToast(err, ToastThemes.error);
                setPostLoading(LoadingStatus.Failure);
              });
          } else {
            const label = UserInfoFormFields[UserOnboardingTabs.userInfo].find(
              (item) => item.fieldRefName === FormFieldNames.FirstName
            ).fieldLabel;
            setValidationErrors({
              [FormFieldNames.FirstName]: `${label} can't be empty`,
            });
          }
        } else {
          setPostLoading(LoadingStatus.Loading);
          dispatch(updateUserInfo(id, formInfo))
            .then((success) => {
              new OnboardingServices().postBankDetails({
                ...bankDetails,
                fkUser: Number(idForUserDetails),
              });
              showToast(
                ToastMessages.success.replace(
                  stringSubstitute,
                  toastMessage.success
                ),
                ToastThemes.success
              );
              if (success) {
                setViewMode(true);
                setFileRes({});
                setPostLoading(LoadingStatus.Success);
              }
            })
            .catch((err) => {
              showToast(err, ToastThemes.error);
              setPostLoading(LoadingStatus.Failure);
            });
        }
      }
    }
  };

  const handleDiscard = () => {
    setFormInfo(formData);
    setEmpData(empFormData);
    setEduData(eduFormData);
    setBasicComponents(salaryType);
    setAdditionComponents(additions);
    setDeductionComponents(deductions);
    setPayStrInfo(payStrData);
    if (isEmpty(formData) && isEmpty(empFormData) && isEmpty(eduFormData)) {
      navigate('../');
    } else {
      setViewMode(true);
    }
  };

  const handleDropdownClick = (name) => {
    switch (name) {
      case FormFieldNames.Roles: {
        if (isEmpty(rolesList)) return dispatch(getRolesList());
        break;
      }

      case FormFieldNames.Departments: {
        return new OnboardingServices()
          .getDepartmentDropdownOptions()
          .then((res) => {
            const filtered = res.filter(
              (ent) => !formInfo[FormFieldNames.SubDepartments].some((lst) => lst.id === ent.id)
            );
            setDeptDropDownOption(filtered);
            return true;
          });
      }

      case FormFieldNames.SubDepartments: {
        return new OnboardingServices()
          .getDepartmentDropdownOptions()
          .then((res) => {
            setsubDeptDropDownOption(
              res.filter(
                (item) => item.id !== formInfo[FormFieldNames.Departments].id
              )
            );
            return true;
          });
      }

      case FormFieldNames.Locations: {
        if (isEmpty(locations)) return dispatch(getLocationInfo());
        break;
      }

      case FormFieldNames.Gender: {
        if (isEmpty(userGender)) return dispatch(getUserGender());
        break;
      }

      case FormFieldNames.BloodGroup: {
        if (isEmpty(userBloodGroup)) return dispatch(getUserBloodGroup());
        break;
      }

      case FormFieldNames.hrStatus: {
        if (isEmpty(userWorkStatus)) return dispatch(getUserWorkStatus());
        break;
      }

      case FormFieldNames.Designation: {
        if (isEmpty(userDesignation)) return dispatch(getUserDesignation());
        break;
      }

      case FormFieldNames.CostCentre: {
        if (isEmpty(userCostCentre)) return dispatch(getUserCostCentre());
        break;
      }

      case FormFieldNames.ReportingTo:
      case FormFieldNames.SecondaryReportingTo:
      case FormFieldNames.AddedBy: {
        return new OnboardingServices().getActivatedUserOption().then((res) => {
          setActivatedUserDropDown([
            { id: null, name: 'Select a manager' },
            ...res,
          ]);
          return true;
        });
      }

      case FormFieldNames.OrgDivisions: {
        if (isEmpty(orgDivisions)) return dispatch(getUserOrgDivisions());
        break;
      }

      case FormFieldNames.EmploymentTypes: {
        if (isEmpty(employmentTypes)) return dispatch(getUserEmploymentTypes());
        break;
      }

      case FormFieldNames.Nationality: {
        if (isEmpty(userNationality)) return dispatch(getUserNationality());
        break;
      }

      case FormFieldNames.Religion: {
        if (isEmpty(userReligion)) return dispatch(getUserReligion());
        break;
      }

      case FormFieldNames.Race: {
        if (isEmpty(userRace)) return dispatch(getUserRace());
        break;
      }

      case FormFieldNames.Function: {
        if (isEmpty(userFunction)) return dispatch(getUserFunction());
        break;
      }
      case FormFieldNames.Category: {
        if (isEmpty(userCategory)) return dispatch(getUserCategory());
        break;
      }
      case FormFieldNames.WorkAuthorisation: {
        if (isEmpty(userWorkAuthorization))
          return dispatch(
            getUserWorkAuthorization(formInfo[FormFieldNames.Nationality].id)
          );
        break;
      }
      case FormFieldNames.AssignTag: {
        return new OnboardingServices().getAssignUserTagOption().then((res) => {
          setAssignUserTagDropDown(res);
          return true;
        });
      }
      case FormFieldNames.EmergencyMobileNumberRelation: {
        return new OnboardingServices()
          .getEmergencyRelationOption()
          .then((res) => {
            setEmergencyRelationOption(res);
            return true;
          });
      }
    }
  };

  const getListLoadingStatus = (name) => {
    switch (name) {
      case FormFieldNames.Roles:
        return requestProcessingOnboarding[APIRequestStatus.GET_ROLES_LIST];
      case FormFieldNames.Locations:
        return requestProcessingOnboarding[APIRequestStatus.GET_LOCATION_INFO];
      case FormFieldNames.Gender:
        return requestProcessingUserOnboarding[
          APIRequestStatus.GET_USER_GENDER
        ];
      case FormFieldNames.BloodGroup:
        return requestProcessingUserOnboarding[
          APIRequestStatus.GET_USER_BLOOD_GROUP
        ];
      case FormFieldNames.hrStatus:
        return requestProcessingUserOnboarding[
          APIRequestStatus.GET_USER_WORK_STATUS
        ];
      case FormFieldNames.Designation:
        return requestProcessingUserOnboarding[
          APIRequestStatus.GET_USER_DESIGNATION
        ];
      case FormFieldNames.CostCentre:
        return requestProcessingUserOnboarding[
          APIRequestStatus.GET_USER_COST_CENTRE
        ];
      case FormFieldNames.OrgDivisions:
        return requestProcessingUserOnboarding[
          APIRequestStatus.GET_ORG_DIVISIONS
        ];
      case FormFieldNames.EmploymentTypes:
        return requestProcessingUserOnboarding[
          APIRequestStatus.GET_EMPLOYMENT_TYPES
        ];
      case FormFieldNames.Nationality:
        return requestProcessingUserOnboarding[
          APIRequestStatus.GET_USER_NATIONALITY
        ];
      case FormFieldNames.Religion:
        return requestProcessingUserOnboarding[
          APIRequestStatus.GET_USER_RELIGION
        ];
      case FormFieldNames.Race:
        return requestProcessingUserOnboarding[APIRequestStatus.GET_USER_RACE];
      case FormFieldNames.Function:
        return requestProcessingUserOnboarding[
          APIRequestStatus.GET_USER_FUNCTION
        ];
      case FormFieldNames.Category:
        return requestProcessingUserOnboarding[
          APIRequestStatus.GET_USER_CATEGORY
        ];
      case FormFieldNames.EmployeeAuthorisation:
        return requestProcessingUserOnboarding[
          APIRequestStatus.GET_USER_WORK_AUTHORIZATION
        ];
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case FormFieldNames.Roles: {
        return rolesList.map((role) => {
          const { id, role_name } = role;
          return getDropdownListValue(id, role_name);
        });
      }
      case FormFieldNames.Departments: {
        return deptDropDownOption;
      }
      case FormFieldNames.SubDepartments: {
        return subDeptDropDownOption;
      }
      case FormFieldNames.Locations: {
        return locations;
      }
      case FormFieldNames.ReportingTo:
      case FormFieldNames.SecondaryReportingTo:
      case FormFieldNames.AddedBy: {
        return activatedUserDropDown;
      }

      case FormFieldNames.Gender: {
        return userGender;
      }
      case FormFieldNames.BloodGroup: {
        return userBloodGroup;
      }
      case FormFieldNames.hrStatus: {
        return userWorkStatus;
      }
      case FormFieldNames.Designation: {
        return userDesignation;
      }
      case FormFieldNames.CostCentre: {
        return userCostCentre;
      }
      case FormFieldNames.OrgDivisions: {
        return orgDivisions;
      }
      case FormFieldNames.EmploymentTypes: {
        return employmentTypes;
      }
      case FormFieldNames.Nationality: {
        return userNationality.map((item) => {
          return { ...item, name: item.nationality };
        });
      }
      case FormFieldNames.Religion: {
        return userReligion;
      }
      case FormFieldNames.Race: {
        return userRace;
      }
      case FormFieldNames.Function: {
        return userFunction;
      }
      case FormFieldNames.Category: {
        return userCategory;
      }
      case FormFieldNames.WorkAuthorisation: {
        return userWorkAuthorization;
      }
      case FormFieldNames.AssignTag: {
        return assignUserTagDropDown;
      }
      case FormFieldNames.EmergencyMobileNumberRelation: {
        return emergencyRelationOption;
      }
      default:
        return null;
    }
  };

  const getTabPanels = (list) => {
    if (!viewMode) {
      return (
        <FormEdit
          list={list}
          formInfo={formInfo}
          setFormData={setFormInfo}
          errors={validationErrors}
          setErrors={setValidationErrors}
          setDisabledSave={setDisabledSave}
          getDropdownOptions={getDropdownOptions}
          getListLoadingStatus={getListLoadingStatus}
          handleDropdownClick={handleDropdownClick}
          hideFields={false}
        />
      );
    }
    return (
      <FormView
        list={list}
        info={formInfo}
        documentsEditDisabled={documentsEditDisabled}
        hideFields={false}
      />
    );
  };

  const getBankPanel = (list) => {
    if (!viewMode) {
      return (
        <Box mt={2}>
          <FormEdit
            list={list}
            formInfo={bankDetails}
            setFormData={setBankDetails}
            errors={{}}
            setDisabledSave={setDisabledSave}
            hideFields={false}
          />
        </Box>
      );
    }
    return (
      <Box mt={2}>
        <FormView
          list={list}
          info={bankDetails}
          documentsEditDisabled={documentsEditDisabled}
          hideFields={false}
        />
      </Box>
    );
  };

  const renderPanels = (list, isEditDisabled) => {
    return (
      <Box>
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            editButtonText={getSaveButtonText(viewMode, isPublished)}
            onEditClick={() => handleAllTabsSave()}
            showPublish={!isPublished && !isEditDisabled}
            onPublishClick={handlePublish}
            showDiscard={!viewMode && !isEditDisabled}
            onClickDiscard={handleDiscard}
            disabledSave={disabledSave}
            labelText={formData[FormFieldNames.DisplayName] || 'New User'}
            showSave={!isEditDisabled}
          />
        </Box>
        {getTabPanels(list)}
      </Box>
    );
  };

  const documentRenderPanels = (isEditDisabled) => {
    return (
      <Box>
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            editButtonText={getSaveButtonText(viewMode, isPublished)}
            onEditClick={() => handleAllTabsSave()}
            showPublish={!isPublished && !isEditDisabled}
            onPublishClick={handlePublish}
            showDiscard={!viewMode && !isEditDisabled}
            onClickDiscard={handleDiscard}
            disabledSave={disabledSave}
            labelText={formData[FormFieldNames.DisplayName] || 'New User'}
            showSave={!isEditDisabled}
          />
        </Box>
        <UserDocumentsList
          setFormData={setFormInfo}
          viewMode={viewMode}
          isEditDisabled={isEditDisabled}
          fileRes={fileRes}
          setFileRes={setFileRes}
        />
      </Box>
    );
  };
  const EmployeeRenderPanels = (isEditDisabled) => {
    return (
      <Box>
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            editButtonText={getSaveButtonText(viewMode, isPublished)}
            onEditClick={() => handleAllTabsSave()}
            showPublish={!isPublished && !isEditDisabled}
            onPublishClick={handlePublish}
            showDiscard={!viewMode && !isEditDisabled}
            onClickDiscard={handleDiscard}
            disabledSave={disabledSave}
            labelText={formData[FormFieldNames.DisplayName] || 'New User'}
            showSave={!isEditDisabled}
          />
        </Box>
        <EmployeeTab
          employeeFormConfig={employeeFormConfig}
          viewMode={viewMode}
          empValidationErrors={empValidationErrors}
          setEmpValidationErrors={setEmpValidationErrors}
          setEmpData={setEmpData}
          empData={empData}
        />
      </Box>
    );
  };

  const UserEntitlementRenderPanel = (isEditDisabled) => {
    return (
      <Box>
        <UserEntitlement
          empId={id}
          userName={formData[FormFieldNames.DisplayName]}
        />
      </Box>
    );
  };

  const EducationRenderPanels = (isEditDisabled) => {
    return (
      <Box>
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            editButtonText={getSaveButtonText(viewMode, isPublished)}
            onEditClick={() => handleAllTabsSave()}
            showPublish={!isPublished && !isEditDisabled}
            onPublishClick={handlePublish}
            showDiscard={!viewMode && !isEditDisabled}
            onClickDiscard={handleDiscard}
            disabledSave={disabledSave}
            labelText={formData[FormFieldNames.DisplayName] || 'New User'}
            showSave={!isEditDisabled}
          />
        </Box>
        <EducationTab
          eduFormConfig={eduFormConfig}
          viewMode={viewMode}
          eduValidationErrors={eduValidationErrors}
          setEduValidationErrors={setEduValidationErrors}
          setEduData={setEduData}
          eduData={eduData}
        />
      </Box>
    );
  };

  const PayrollRenderPanel = (isEditDisabled) => {
    return (
      <Box>
        <ManagePayroll
          viewMode={viewMode}
          isPublished={isPublished}
          onEditClick={() => handleAllTabsSave()}
          onPublishClick={handlePublish}
          isEditDisabled={isEditDisabled}
          handleDiscard={handleDiscard}
          disabledSave={disabledSave}
          labelText={formData[FormFieldNames.DisplayName] || 'New User'}
          formConfig={payrollTabFields}
          payStrInfo={payStrInfo}
          setPayStrInfo={setPayStrInfo}
          additionComponents={additionComponents}
          setAdditionComponents={setAdditionComponents}
          deductionComponents={deductionComponents}
          setDeductionComponents={setDeductionComponents}
          basicComponents={basicComponents}
          setBasicComponents={setBasicComponents}
          userId={idForUserDetails}
          totalAdditions={totalAdditions}
          totalDeductions={totalDeductions}
          payrollEditDisabled={payrollEditDisabled}
          payrollError={payrollError}
          setPayrollError={setPayrollError}
          setIsPayrollChange={setIsPayrollChange}
          employeeBenifits={employeeBenifits}
        />
      </Box>
    );
  };

  const userOnboardingTabs = [
    {
      name: UserOnboardingTabs.userInfo,
      label: 'User info',
      title: 'User info',
      hide:
        (Number(idForUserDetails) === loggedInUserDetails?.id
          ? !myProfilePermissions[userInfo]?.[permissionsObj.allowView]
          : !userProfilePermissions[userInfo]?.allowView) ?? true,
      panel: (
        <>
          {renderPanels(userOnboardingFields, userDetailsEditDisabled)}{' '}
          {getBankPanel(bankFields)}
        </>
      ),
    },
    {
      name: UserOnboardingTabs.personal,
      label: 'Personal',
      title: 'Personal',
      hide:
        (Number(idForUserDetails) === loggedInUserDetails?.id
          ? !myProfilePermissions[personalDetails]?.[permissionsObj.allowView]
          : !userProfilePermissions[personalDetails]?.allowView) ?? true,
      panel: renderPanels(personalDetailsFields, personalDetailsEditDisabled),
      disabled: id ? false : true,
    },
    {
      name: UserOnboardingTabs.workDetails,
      label: 'Work details',
      title: 'Work details',
      hide:
        (Number(idForUserDetails) === loggedInUserDetails?.id
          ? !myProfilePermissions[workDetails]?.[permissionsObj.allowView]
          : !userProfilePermissions[workDetails]?.allowView) ?? true,
      panel: renderPanels(workDetailsFields, workDetailsEditDisabled),
      disabled: id ? false : true,
    },
    {
      name: UserOnboardingTabs.payrollAssignment,
      label: 'Payroll assignment',
      title: 'Payroll assignment',
      hide:
        (Number(idForUserDetails) === loggedInUserDetails?.id
          ? !myProfilePermissions[payrollAssignment]?.[permissionsObj.allowView]
          : !userProfilePermissions[payrollAssignment]?.allowView) ?? true,
      panel: PayrollRenderPanel(payrollEditDisabled),
      disabled: id ? false : true,
      padding: 0,
    },
    {
      name: UserOnboardingTabs.myJourney,
      label: 'My Journey',
      title: 'My Journey',
      panel: (
        <MyJourneyDashboard
          empId={idForUserDetails}
          userName={formData[FormFieldNames.DisplayName]}
          status={formData[FormFieldNames.EmployeeStatus]?.id}
          userLog={userLog}
        />
      ),
      hide:
        (Number(idForUserDetails) === loggedInUserDetails?.id
          ? !myProfilePermissions[seperation]?.[permissionsObj.allowView]
          : !userProfilePermissions[seperation]?.allowView) ?? true,
      disabled: id ? false : true,
    },
    {
      name: UserOnboardingTabs.documents,
      label: 'Documents',
      title: 'Documents',
      hide:
        (Number(idForUserDetails) === loggedInUserDetails?.id
          ? !myProfilePermissions[documents]?.[permissionsObj.allowView]
          : !userProfilePermissions[documents]?.allowView) ?? true,
      panel: documentRenderPanels(documentsEditDisabled),
      disabled: id ? false : true,
    },
    {
      name: UserOnboardingTabs.educationHistory,
      label: 'Educational History',
      title: 'Educational History',
      hide:
        (Number(idForUserDetails) === loggedInUserDetails?.id
          ? !myProfilePermissions[educationHistory]?.[permissionsObj.allowView]
          : !userProfilePermissions[educationHistory]?.allowView) ?? true,
      panel: EducationRenderPanels(educationHistoryEditDisabled),
      disabled: id ? false : true,
    },
    {
      name: UserOnboardingTabs.employeeHistory,
      label: 'Employment History',
      title: 'Employment History',
      hide:
        (Number(idForUserDetails) === loggedInUserDetails?.id
          ? !myProfilePermissions[employeeHistory]?.[permissionsObj.allowView]
          : !userProfilePermissions[employeeHistory]?.allowView) ?? true,
      panel: EmployeeRenderPanels(employeeHistoryEditDisabled),
      disabled: id ? false : true,
    },
    {
      name: UserOnboardingTabs.enitilement,
      label: 'User Entitlement',
      title: 'User Entitlement',
      hide:
        (Number(idForUserDetails) === loggedInUserDetails?.id
          ? !myProfilePermissions[enitilement]?.[permissionsObj.allowView]
          : !userProfilePermissions[enitilement]?.allowView) ?? true,
      panel: UserEntitlementRenderPanel(userEntitlementEditDisabled),
      disabled: id ? false : true,
    },
  ];

  const setErorTabNamesAndFocusedTab = () => {
    const allowedTabs = userOnboardingTabs.filter((item) => !item.hide);
    const errorTabs = allowedTabs.filter(
      (tab) =>
        !!UserInfoFormFields[tab.name]?.find(
          (field) => !!validationErrors[field.fieldRefName]
        )
    );
    setErrorTabNames(errorTabs.map((tab) => tab.name));
  };

  const onSearchChange = (e, searchTab) => {
    const index = userOnboardingTabs.findIndex(
      (tab) => tab.name === searchTab.name
    );
    if (id && index !== -1) setCurrentTab(index);
  };

  if (idForUserDetails && userDetailsLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  } else {
    return (
      <Box>
        <CircularLoader
          show={
            (!isNaN(idForUserDetails) &&
              userDetailsLoading === LoadingStatus.Loading) ||
            postLoading === LoadingStatus.Loading
          }
        />
        <TabBar
          tabPanels={userOnboardingTabs}
          errorTabNames={errorTabNames}
          searchTabs
          disableCreate
          onSearchChange={onSearchChange}
          currentTab={currentTab}
        />
        <ModalDialog
          title="Effective Date"
          description={
            <>
              <FormEdit
                list={[
                  {
                    fieldLabel: 'Effective Date',
                    placeholderText: 'dd/mm/yyyy',
                    fieldType: FormFieldTypes.Date,
                    isRequired: false,
                    fieldRefName: 'effectiveDate',
                    fieldSize: 13,
                    minDate: currentDate,
                    defaultValue: firstDayOfMonth,
                    tooltipText:
                      'Updated values will be effective from next pay cycle',
                  },
                ]}
                padding={0}
                setFormData={setPayStrInfo}
              />
            </>
          }
          primaryAction="Save"
          secondaryAction="Cancel"
          showModal={showModal}
          onClickClose={() => setshowModal(false)}
          onClickConfirm={() => handleFinalSave()}
          severity="success"
          maxWidth="lg"
          fullWidth
        />
      </Box>
    );
  }
}
