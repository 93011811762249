import API from './api';

export class RoleService extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL + '/roles-and-permissions/' || '';
  constructor() {
    super(RoleService.baseURL);
  }
  saveReports = async (data) => (
    this.responsify(await this.instance.post('save-reports', data))
  );

  getChildModules = async (moduleId) => (
    this.responsify(await this.instance.get('list-child-modules', {params:{moduleId}}))
  );

  getChildPermissions = async (roleId, moduleId) => (
    this.responsify(await this.instance.get('permissions', {params:{moduleId, roleId}}))
  );
}