import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CompBenTabs } from '../comp-ben-utlis/comp-ben-utils';
import { Box, Divider } from '@mui/material';
import SubHeader from '../sub-header/sub-header.component';
import DynamicBreadcrumb from '../../../atoms/Breadcrumbs/dynamicBreadcrumb';
import { useSelector } from 'react-redux';
import { permissionsObj } from '../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';

const CompBenHeader = () => {

  const { idPropertiesMap } = useSelector((state) => state.benefits);
  const { compBenPermission } = useSelector((state) => state.modulePermissions);

  const tabsData = CompBenTabs.map((item, index) => {
    const { id, module } = item;
    return {
      id: id,
      name: compBenPermission?.[module]?.displayName,
      path: `/comp-ben/${id}`,
      label: compBenPermission?.[module]?.displayName,
      title: compBenPermission?.[module]?.displayName,
      show: compBenPermission?.[module]?.[permissionsObj.allowView],
    };
  });

  const filteredTabsList = tabsData.filter((item) => item.show);

  return (
    <>
      <Box>
        <SubHeader tabsData={filteredTabsList} />
        <Box my={3}>
          <DynamicBreadcrumb idNameMap={idPropertiesMap} />
          <Box mt={1}>
            <Divider />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CompBenHeader;
