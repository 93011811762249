import React from 'react';
import { Paper, Box } from '@mui/material';
import InterviewForm from './InterviewForm/InterviewForm';
import InterviewFormView from './InterviewForm/InterviewFormView';

function InterviewSchedule(props) {
  const { selectedCandidateId, viewMode, setInterviewForm,
    interviewFormInfo, formConfig, error, setError = () => { },
  } = props;

  return (
    <Paper sx={{ mt: 3 }}>
      <Box sx={{ p: 5 }} >
        {
          !viewMode ?
            <InterviewForm
              formConfig={formConfig}
              selectedCandidateId={selectedCandidateId}
              setInterviewForm={setInterviewForm}
              interviewFormInfo={interviewFormInfo}
              error={error}
              setError={setError}
            /> :
            <InterviewFormView
              list={formConfig}
              info={interviewFormInfo}
            />
        }
      </Box>

    </Paper>
  );
}

export default React.memo(InterviewSchedule);

