export const access = {
    createAccess: 'addAccess',
    editAccess: 'editAccess',
    viewAccess: 'viewAccess',
    disabled: 'disabled',
};


export const configureRoleOptions = [
    { name: access.createAccess },
    { name: access.editAccess },
    { name: access.viewAccess },
    { name: access.disabled }
];

export const permissionsObj = {
    allowView: 'allowView',
    allowAdd: 'allowAdd',
    allowEdit: 'allowEdit',
    allowDelete: 'allowDelete'
};

export const defaultPermissions = {
    [permissionsObj.allowView]: false,
    [permissionsObj.allowAdd]: false,
    [permissionsObj.allowEdit]: false,
    [permissionsObj.allowDelete]: false
};

export const FormFieldNameForReports = {
    Reports: 'reports'
};

export const ReportsModuleId = 27;

export const ViewAccess = {
    NO_ACCESS: 0,
    MY_ACCESS: 1,
    APPROVER_ACCESS: 2,
    APPROVER_APPROVE_ACCESS: 3,
    REPORTEE_ACCESS: 4,
    DEPARTMENT_ACCESS: 5,
    ALL_APPROVED_ACCESS:6,
    ALL_ACCESS: 7,
};
