import React, { useEffect, useMemo, } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FormView from '../../../OnBoarding/common/Form/FormView';
import { calculateTotalScore } from '../../../../redux/goals/action';
import FormEdit from '../../../OnBoarding/common/Form/FormEdit';
import { TotalScoreFormFieldNames } from '../../Utlis/goals.util';

const TotalScore = (props) => {
  const { overallRemarks, setOverallRemarks, error, setError, viewMode, overallAppraisalData,managerAcess } = props;

  const { totalScoreConfig, totalScoreData, kraFormDataLocal } = useSelector((state) => state.goals);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(calculateTotalScore());
  }, [kraFormDataLocal]);
  const totalScoreConfigChanged = useMemo(() => {
    const config = totalScoreConfig.reduce((acc, item) => {
        let temp = item;
        switch (item.fieldRefName) {
          case TotalScoreFormFieldNames.OverallRemarks: {
              temp = {...item,isReadOnly:managerAcess};
              break;
          }
          default: {
            temp = item;
            break;
        }
        }
        acc.push(temp);
        return acc;
    }, []);
    return config;
}, [managerAcess]);

return (
    <>
      <Box position='relative'>
        {viewMode ?
          <FormView
            list={totalScoreConfigChanged}
            info={{...totalScoreData,...overallRemarks}}
          /> :
          <FormEdit
            list={totalScoreConfigChanged}
            formInfo={{...totalScoreData,...overallRemarks}}
            setFormData={setOverallRemarks}
            errors={error}
            setErrors={setError}
          />
        }
      </Box>
    </>
  );
};

export default React.memo(TotalScore);