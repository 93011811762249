import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FormEdit from '../../../../../../OnBoarding/common/Form/FormEdit';
import { KRAPerformanceFormFieldNames } from '../../../../../Utlis/goals.util';
import { getRatingValue } from '../../../utils/kra.util';
import { useDispatch } from 'react-redux';
import { useDebounce, useFetch } from 'usehooks-ts';
import { updateKraDataPerformance } from '../../../../../../../redux/goals/action';

const PerformanceFormEdit = (props) => {
  const { formConfig, targetPoints, kraIndex, formData, managerAcess } = props;
  const [formConfigState, setFormConfigState] = useState(formConfig);
  const dispatch = useDispatch();
  const [formInfo, setFormInfo] = useState(formData);

  const debouncedInfo = useDebounce(formInfo, 500);

  const handleAchievementScoreChange = useCallback(
    (event) => {
      const key = event.target.name;
      const value = event.target.value;
      setFormInfo((prev) => ({
        ...prev,
        [key]: value,
        [KRAPerformanceFormFieldNames.Rating]: getRatingValue(
          value,
          targetPoints
        ),
      }));
    },
    [formInfo[KRAPerformanceFormFieldNames.Achievement]]
  );

  useEffect(() => {
    dispatch(updateKraDataPerformance({ kraIndex, formData: formInfo }));
  }, [debouncedInfo]);

  useEffect(() => {
    const config = formConfig.reduce((acc, item) => {
      let temp = item;
      switch (item.fieldRefName) {
        case KRAPerformanceFormFieldNames.Achievement: {
          temp = {
            ...temp,
            onChange: handleAchievementScoreChange,
            isReadOnly: managerAcess,
          };
          break;
        }
        case KRAPerformanceFormFieldNames.AppraiserRemarks: {
          temp = { ...temp, isReadOnly: !managerAcess };
          break;
        }
        default: {
          temp = { ...item, isReadOnly: managerAcess };
          break;
        }
      }
      acc.push(temp);
      return acc;
    }, []);
    setFormConfigState(config);
  }, [formConfig, managerAcess]);

  return (
    <FormEdit
      list={formConfigState}
      errors={{}}
      formInfo={formInfo}
      setFormData={setFormInfo}
    />
  );
};

export default React.memo(PerformanceFormEdit);
