import { Box, FormControl, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { StyledSelect } from './SubHeader.style';
import SearchBar from '../../../atoms/SearchBar/SearchBar';
import { getPayrollTab } from '../PayrollOutlet/Utils/PayrollUtils';
import { useSelector } from 'react-redux';

const SubHeader = (props) => {
  const { handleSearch } = props;
  const [value, setValue] = useState([]);
  const navigate = useNavigate();
  const { payrollPermission } = useSelector((state) => state.modulePermissions);
  const handleChange = (event) => {
    navigate(`${event.target.value}`);
  };

  return (
    <Box sx={{ display: 'flex', gap: 7 }}>
      <FormControl variant="standard" sx={{ width: 150, border: 0, mt: 1 }}>
        <StyledSelect
          displayEmpty
          value={value}
          onChange={handleChange}
          renderValue={(selected) => {
            if (selected) {
              return 'Payroll';
            }
          }}
        >
          {getPayrollTab(payrollPermission)
            .filter((item) => !item.hide)
            .map((item) => {
              const { name, label } = item;
              return (
                <MenuItem key={name} value={name}>
                  {label}
                </MenuItem>
              );
            })}
        </StyledSelect>
      </FormControl>

      <SearchBar handleSearch={handleSearch} />
    </Box>
  );
};

export default SubHeader;
