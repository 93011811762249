import React, { useEffect, useState } from 'react';
import TeamBuildingEdit from './claim-edit-team-building-form';
import { Box } from '@mui/system';
import { Grid, Paper } from '@mui/material';
import StyledButton from '../../../../OnBoarding/Organization/ActionHeader/ActionHeader.styled';
import { fieldNamesTeamBuilding } from '../../../../form-builder/utils/team-building-utils';
import { CommonSevice } from '../../../../../services/common';
import { BuilderServices } from '../../../../../services/builder';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import TeamBuildingView from './claim-view-team-building-form';
import { showToast } from '../../../../../utils/common.util';
import { ToastThemes } from '../../../../../constants/common';
import { ClaimServices } from '../../../../../services/claims';
import AttendeesModal from './attendees-modal/atteendees-modal.component';
import { getFormattedValues } from '../../../../../utils/formatter';
import { DateFormatters } from '../../../../../constants/dateConstants';
import FieldView from '../../../../molecules/FieldView/FieldView';
import ModalDialog from '../../../../../atoms/Modal/ModalDialog';

const DepartmentsTeam = (props) => {
  const {
    benifitId,
    setDepartementFomdata,
    teamBuildingData,
    departementFomdata,
    viewMode,
    departementDropDown,
    setDepartementDropDown,
    claimHeaderData,
    isNew,
    singleDeptAllowed,
    claimId
  } = props;
  const [departments, setDepartments] = useState([{}]);
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [atteendeesDistribution, setAtteendeesDistribution] = useState([]);
  const [blockDate, setBlockDate] = useState({});
  const departementSelected = departementFomdata
    .filter((item) => item?.department?.id)
    .map((item) => item?.department?.id);
  useEffect(() => {
    if (departementFomdata?.length) {
      setDepartments(Array(departementFomdata.length).fill({}));
    }
  }, [departementFomdata]);

  useEffect(() => {
    const claimService = new ClaimServices();
    if (claimHeaderData) {
      setEmployeeDetails(claimHeaderData);
    } else {
      claimService.getEmployeeDetails().then((res) => {
        if (res.data?.data) {
          setEmployeeDetails(res.data?.data);
        }
      });
    }
  }, [claimHeaderData]);
  useEffect(() => {
    if (!viewMode && isNew) {
      new CommonSevice().postDropDown('departments', benifitId).then((res) => {
        setDepartementDropDown(res);
        if (employeeDetails) {
          const { sumBalance, sumTotal } = res.find(
            (item) => employeeDetails.department.id === item.id
          );
          setDepartementFomdata((prev) => {
            let newConditions = [...prev];
            newConditions[0][fieldNamesTeamBuilding.Department] = res.find(
              (item) => employeeDetails.department.id === item.id
            );
            newConditions[0][fieldNamesTeamBuilding.Total_Balance] = sumBalance;
            newConditions[0][fieldNamesTeamBuilding.Total_Budget] = sumTotal;
            return newConditions;
          });
        }
      });
    }
  }, [employeeDetails]);

  useEffect(() => {
    if (departementDropDown.length && !viewMode && isNew) {
      setBlockDate({
        startDate: getFormattedValues(
          DateFormatters.ddmmyyyy,
          departementDropDown[0]?.blockStartDate
        ),
        endDate: getFormattedValues(
          DateFormatters.ddmmyyyy,
          departementDropDown[0]?.blockEndDate
        ),
      });
    }
  }, [departementDropDown]);

  const handleDropdownClick = (name) => {
    switch (name) {
      case fieldNamesTeamBuilding.Department: {
        if (!viewMode) {
          return new CommonSevice()
            .postDropDown('departments', benifitId)
            .then((res) => {
              setDepartementDropDown(res);
              return true;
            });
        }
        return departementDropDown;
      }
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case fieldNamesTeamBuilding.Department: {
        return departementDropDown.filter(
          (item) => !departementSelected.includes(item?.id)
        );
      }
    }
  };

  const calculate = () => {
    const { receiptAmount, attendees } = teamBuildingData;
    if (!receiptAmount || !attendees) {
      showToast('Fill in reciept amount / select attendees', ToastThemes.error);
      return;
    }
    new BuilderServices()
      .teamBuildingDeductions(receiptAmount, attendees, benifitId)
      .then((res) => {
        const { sumByDepartment, attendeesBalance } = res;
        setAtteendeesDistribution(attendeesBalance);
        setDepartementFomdata((prev) => {
          let newConditions = [...prev];
          newConditions = newConditions.map((item) => {
            if (item?.department?.id) {
              item[fieldNamesTeamBuilding.ComputedAmount] =
                sumByDepartment[item?.department?.id] || '0';
            }
            return item;
          });
          return newConditions;
        });
        setShowModal(true);
      }).catch((err) => {
        console.log(err);
        showToast(err.response.data.message, ToastThemes.error);
      });
  };

  const viewDistribution = () => {
    new BuilderServices()
      .teamBuildingDeductionsView(true, claimId)
      .then((res) => {
        setAtteendeesDistribution(res);
        setShowModal(true);
      }).catch((err) => {
        console.log(err);
        showToast(err.response.data.message, ToastThemes.error);
      });
  };

  return (
    <>
      {viewMode ? (
        <TeamBuildingView
          departementFomdata={departementFomdata}
          viewDistribution={viewDistribution}
        />
      ) : (
        <>
          <Paper sx={{ flexGrow: 1, p: 4, my: 1 }}>
            <Grid container rowSpacing={3} columnSpacing={8} mb={2}>
              <Grid item md={3.5}>
                <FieldView
                  labelText={'Start Date'}
                  labelValue={blockDate?.startDate}
                />
              </Grid>
              <Grid item md={2.5}>
                <FieldView
                  labelText={'End Date'}
                  labelValue={blockDate?.endDate}
                />
              </Grid>
              <Grid
                item
                md={6}
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <StyledButton
                  color="success"
                  sx={{ textTransform: 'capitalize' }}
                  onClick={calculate}
                  className="ve-action-btn"
                >
                  <CalculateOutlinedIcon
                    sx={{ width: '24px', height: '24px', mr: '5px' }}
                  />
                  Calculate
                </StyledButton>
              </Grid>
            </Grid>
            {departments.map((item, index) => {
              return (
                <TeamBuildingEdit
                  key={index}
                  index={index}
                  noOfDept={departments.length}
                  setDepartments={setDepartments}
                  handleDropdownClick={handleDropdownClick}
                  getDropdownOptions={getDropdownOptions}
                  setDepartementFomdata={setDepartementFomdata}
                  viewMode={viewMode}
                  departementFomdata={
                    departementFomdata[index] ? departementFomdata[index] : {}
                  }
                  singleDeptAllowed={singleDeptAllowed}
                />
              );
            })}
          </Paper>
        </>
      )}
      <ModalDialog
        title={'Attendees Distribution'}
        showModal={showModal}
        onClickClose={() => setShowModal(false)}
        maxWidth="fit-content"
        fullWidth={true}
        listComponent={<AttendeesModal list={atteendeesDistribution} />}
        severity="success"
      />
    </>
  );
};

export default DepartmentsTeam;
