import { Box, Divider, IconButton } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { AddOutlined, DeleteOutline } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import FormEdit from '../../../OnBoarding/common/Form/FormEdit';
import {
  approvalAccessTypesDropDown,
  approvalConditionInitialVal,
  approvalConditionaFields,
  getApprovalConditionsFormFields,
} from '../../utils/approval.utils';
import { showToast } from '../../../../utils/common.util';
import { ToastThemes } from '../../../../constants/common';
import {
  approverTypes,
  approverTypesIds,
} from '../../../OnBoarding/Organization/ApprovalFlow/ApprovalFlowForm/AppWorkFlowFlowFormEdit/AppWorkConstants';
import { range } from 'lodash';
import { fileSizes } from '../../utils/approval.utils';

const ApprovalConditions = (props) => {
  const { index, setConditions, condition, noOfConditions, setFormState } =
    props;
  const { approvalWorkflowFormDataSteps } = useSelector(
    (state) => state.approvalWorkflow
  );

  const [conditionFormState, setConditionFormState] = useState(condition);

  useEffect(() => {
    setFormState((prev) => {
      const newConditions = [...prev];
      newConditions[index] = conditionFormState;
      return newConditions;
    });
  }, [conditionFormState]);

  const getDropdownOptions = (name) => {
    switch (name) {
      case approvalConditionaFields.stepNumber: {
        const stepNumbers = [];
        stepNumbers.push({ id: 1, name: 'Step 0' });
        for (const [index,item] of approvalWorkflowFormDataSteps.entries()) {
          if (Object.keys(item).length>0){
            stepNumbers.push({
              id: index + 2,
              name: `Step${index + 1} ${item?.approverType?.name}`,
              approverType: item?.approverType?.name,
            });
          }
        }
        return stepNumbers;
      }
      case approvalConditionaFields.access:
        return approvalAccessTypesDropDown;
      case approvalConditionaFields.reportingLevels: {
        const reportingLevels = [];
        for (const item of approvalWorkflowFormDataSteps) {
          if (item?.approverType?.name === approverTypes.reportingManger) {
            for (const levelNum in range(item?.reportingLevel?.id)) {
              reportingLevels.push({ id: Number(levelNum) + 1, name: Number(levelNum) + 1 });
            }
          }
        }
        return reportingLevels;
      }
    }
  };

  const handleDelete = (index) => {
    if (noOfConditions === 1) {
      setConditionFormState(approvalConditionInitialVal);
      setFormState([approvalConditionInitialVal]);
    } else {
      setConditions((prev) => {
        const newConditions = [...prev];
        newConditions.splice(index, 1);
        setConditionFormState(newConditions[index]);
        return newConditions;
      });
    }
  };

  const formFields = useMemo(() => {
    return getApprovalConditionsFormFields.reduce((acc, item) => {
      const { fieldRefName } = item;
      let temp = { ...item };
      switch (fieldRefName) {
        case approvalConditionaFields.access:
          temp = {
            ...temp,
            fieldSize:
            approvalWorkflowFormDataSteps[Number(conditionFormState?.[approvalConditionaFields.stepNumber])-2]?.approverType.id===approverTypesIds.reportingManger ||
            approvalWorkflowFormDataSteps[Number(conditionFormState?.[approvalConditionaFields.stepNumber]?.id) -2]?.approverType.id === approverTypesIds.reportingManger
                ? fileSizes.three
                : fileSizes.six,
          };
          break;
        case approvalConditionaFields.reportingLevels:
          temp = {
            ...temp,
            isHidden:
            approvalWorkflowFormDataSteps[Number(conditionFormState?.[approvalConditionaFields.stepNumber])-2]?.approverType.id === approverTypesIds.reportingManger ||
            approvalWorkflowFormDataSteps[Number(conditionFormState?.[approvalConditionaFields.stepNumber]?.id)-2]?.approverType.id === approverTypesIds.reportingManger
                ? false
                : true,
          };
          break;
        default: {
          break;
        }
      }
      if (!temp.isHidden) {
        acc.push(temp);
      }
      if (temp.isHidden) {
        delete conditionFormState[temp?.fieldRefName];
      }
      return acc;
    }, []);
  }, [conditionFormState]);

  return (
    <>
      <Box display="flex" alignItems="center" flex="1 1 0">
        <Box flexGrow={3} width={800}>
          <FormEdit
            list={formFields}
            formInfo={conditionFormState}
            setFormData={setConditionFormState}
            getDropdownOptions={getDropdownOptions}
            padding={0}
            modal={false}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
          <IconButton
            color="success"
            sx={{ borderColor: 'white' }}
            onClick={() =>
              setConditions((prev) => {
                if (prev.length < 10) {
                  return [...prev, { ...approvalConditionInitialVal }];
                } else {
                  showToast(
                    'Approval conditions Cant`t be more than 10',
                    ToastThemes.error
                  );
                  return [...prev];
                }
              })
            }
          >
            <AddOutlined sx={{ width: '24px', height: '24px', mr: '5px' }} />
          </IconButton>
          <IconButton
            color="error"
            sx={{ borderColor: 'white' }}
            onClick={() => handleDelete(index)}
          >
            <DeleteOutline sx={{ width: '24px', height: '24px', mr: '5px' }} />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ margin: 2 }} />
    </>
  );
};

export default ApprovalConditions;
