import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import ActionHeader from '../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import ComponentFormView from '../../../OnBoarding/UserProfile/PayrollTab/ComponentForm/ComponentFormView';
import BasePayroll from './ManagePayroll/BasePayroll';
import { totalForAllComponents } from '../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import ComponentHeader from './ManagePayroll/ComponentHeader';
import { permissionsObj } from '../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import {
  PayrollPermissions,
  PayrollStatus,
  reasonsForPayrollReject,
} from '../../Constant/Constant';
import { useDispatch, useSelector } from 'react-redux';
import Total from './ManagePayroll/Total';
import { getFormattedDate } from '../../../../utils/formatter';
import { DownloadAPIResponse } from '../../../../services/downloadAPIService';
import {
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../../../constants/common';
import { showToast } from '../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../OnBoarding/Organization/constants/onboarding.constants';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import Currency from './ManagePayroll/Currency';
import ApprovalWorkflowAuditLog from '../../../OnBoarding/Organization/ApprovalFlow/ApprovalWorkflowUtilsCommon/ApprovalWorkflowAuditTrail';
import ApprovalWorkflowFooterCommon from '../../../OnBoarding/Organization/ApprovalFlow/Footer/ApprovalWorkflowFooterCommon';
import { Payroll } from '../../../../services/payroll';
import { getPayrollDetails } from '../../../../redux/payroll/action';
import { isEmpty } from 'lodash';
import OtSection from './ManagePayroll/OtSection';

const PayrollView = (props) => {
  const {
    setViewMode,
    additionComponents,
    deductionComponents,
    base,
    stdHrs,
    period,
    cpfEmployeeAmt,
    cpfEmployerAmt,
    prlRegisterId,
    currencySymbol,
    employee,
    sdf,
    directFunds,
    cpfApplicable,
    payDate,
    otApplicable,
    overtimeInfo,
  } = props;

  const [fileLoading, setFileLoading] = useState(-1);
  const [isAuthorized, setisAuthorized] = useState(false);

  const { payrollPermission } = useSelector((state) => state.modulePermissions);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const { prlStatusCode, auditLogs } = useSelector((state) => state.payroll);

  const dispatch = useDispatch();

  const totalAdditions = totalForAllComponents(additionComponents);
  const totalDeductions = totalForAllComponents(deductionComponents);
  const formattedPeriod = getFormattedDate(period, 'YYYYMM', 'YYYY-MM');
  const refrenceId = `${employee?.employeeId} ${formattedPeriod}`;
  const totalContributions = totalForAllComponents(directFunds);

  const handleDownload = () => {
    setFileLoading(LoadingStatus.Loading);
    new DownloadAPIResponse()
      .paySlipDownload(
        currentUserRoleId,
        prlRegisterId,
        employee?.employeeId,
        formattedPeriod
      )
      .then(() => {
        showToast(
          ToastMessages.success.replace(
            stringSubstitute,
            toastMessage.download
          ),
          ToastThemes.success
        );
        setFileLoading(LoadingStatus.Success);
      })
      .catch((err) => {
        setFileLoading(LoadingStatus.Failure);
        showToast(err, ToastThemes.error);
      });
  };

  const handleSendBackClick = (statusCode) => {
    setFileLoading(LoadingStatus.Loading);
    new Payroll()
      .updateStatus({
        payrollInfo: [{ id: prlRegisterId, refId: refrenceId }],
        roleId: currentUserRoleId,
        moduleId:
          payrollPermission?.[PayrollPermissions.ACCESS_ALL_PAYROLL]?.id,
        statusCode,
      })
      .then(() => {
        setFileLoading(LoadingStatus.Success);
      })
      .catch((err) => {
        showToast(err?.response?.data?.message, ToastThemes.error);
        setFileLoading(LoadingStatus.Failure);
      });
  };

  const getDetails = () => {
    dispatch(
      getPayrollDetails(
        currentUserRoleId,
        prlRegisterId,
        payrollPermission?.[PayrollPermissions.ACCESS_ALL_PAYROLL]?.id,
        payrollPermission?.[PayrollPermissions.APPROVER_DASHBOARD]?.[
          permissionsObj.allowView
        ]
      )
    );
  };
  return (
    <Box>
      <CircularLoader show={fileLoading === LoadingStatus.Loading} />
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={employee?.firstName + ' ' + employee?.lastName}
          editButtonText="Edit"
          onEditClick={() => setViewMode(false)}
          showSave={
            (payrollPermission?.[PayrollPermissions.ACCESS_ALL_PAYROLL]?.[
              permissionsObj.allowEdit
            ] &&
              (prlStatusCode?.payrollStatusCode === PayrollStatus.Save ||
                prlStatusCode?.payrollStatusCode === PayrollStatus.SendBack ||
                prlStatusCode === null)) ||
            false
          }
          labelCode={formattedPeriod}
          showDownload={
            prlStatusCode?.payrollStatusCode === PayrollStatus.Pay || false
          }
          onDownloadClick={handleDownload}
          empId={employee?.employeeId}
        />
      </Box>
      <Grid container rowSpacing={3} columnSpacing={3}>
        <Grid item md={9}>
          {payDate && (
            <>
              <Grid container rowSpacing={3} columnSpacing={8} pr={8}>
                <Grid item md={6} xs={12}>
                  <ComponentHeader label="Payment Date:" total={payDate} />
                </Grid>
              </Grid>
              <Divider sx={{ padding: 1 }} />
            </>
          )}
          <Currency currencySymbol={currencySymbol} />
          <BasePayroll
            base={base}
            stdHrs={stdHrs}
            currencySymbol={currencySymbol}
          />
          <Grid container rowSpacing={3} columnSpacing={8} pr={8}>
            <Grid item md={6} xs={12}>
              <ComponentHeader
                label="B. Addition Components"
                total={totalAdditions}
              />
              {additionComponents?.map((item, index) => {
                return (
                  <ComponentFormView
                    formInfo={additionComponents[index]}
                    key={index}
                  />
                );
              })}
            </Grid>
            <Grid item md={6} xs={12}>
              <ComponentHeader
                label="C. Deduction Components"
                total={totalDeductions}
              />
              {deductionComponents?.map((item, index) => {
                return (
                  <ComponentFormView
                    formInfo={deductionComponents[index]}
                    key={index}
                  />
                );
              })}
            </Grid>
          </Grid>
          {otApplicable && (
            <>
              <Divider sx={{ padding: 1 }} />
              <OtSection
                overtimeInfo={overtimeInfo}
                currencySymbol={currencySymbol}
              />
            </>
          )}
          <Total
            label={
              otApplicable
                ? 'E. Total Gross (A+B+D-C)'
                : 'D. Total Gross (A+B-C)'
            }
            total={
              totalAdditions +
              (base?.code === 'HRL' ? Number(base?.value) * Number(stdHrs) : Number(base?.value)) +
              (overtimeInfo?.value || 0) -
              totalDeductions
            }
          />
          <Divider sx={{ padding: 1 }} />
          <Grid container rowSpacing={3} columnSpacing={8} pr={8}>
            <Grid item md={6} xs={12}>
              <ComponentHeader
                label={otApplicable ? 'F. Contributions' : 'E. Contributions'}
                total={totalContributions || 0}
              />
              {directFunds?.map((item, index) => {
                return (
                  <ComponentFormView
                    formInfo={directFunds[index]}
                    key={index}
                  />
                );
              })}
            </Grid>
          </Grid>
          <Total
            label={otApplicable ? 'G. Net Pay (E-F)' : 'F. Net Pay (D-E)'}
            total={
              totalAdditions +
              (base?.code === 'HRL' ? Number(base?.value) * Number(stdHrs) : Number(base?.value)) +
              (overtimeInfo?.value || 0) -
              totalDeductions -
              (totalContributions || 0)
            }
          />
          {cpfApplicable && (
            <Total label="Employer CPF" total={cpfEmployerAmt || 0} toolTip='CPF Based on AW/OW' />
          )}
          {!isEmpty(sdf) && <Total label={sdf?.name} total={sdf?.value} />}
        </Grid>
        <Grid item md={3}>
          <Box
            ml={6}
            pl={6}
            sx={{ borderLeft: 'solid 1px', borderColor: 'neutral.dark40' }}
            mb={3}
          >
            <Typography variant="subtitle2" color={'neutral.dark80'} mb={3}>
              Audit Trail
            </Typography>

            <Box>
              <ApprovalWorkflowAuditLog auditTrail={auditLogs} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ApprovalWorkflowFooterCommon
        recordId={prlRegisterId}
        moduleId={
          payrollPermission?.[PayrollPermissions.ACCESS_ALL_PAYROLL]?.id
        }
        handleStatusUpdate={handleSendBackClick}
        auditLogs={auditLogs}
        getDetails={getDetails}
        reasons={reasonsForPayrollReject}
        refId={refrenceId}
        isAuthorized={isAuthorized}
        setisAuthorized={setisAuthorized}
      />
    </Box>
  );
};

export default PayrollView;
