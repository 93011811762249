import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import FieldView from '../../../molecules/FieldView/FieldView';
import moment from 'moment';
import { DateFormatters } from '../../../../constants/dateConstants';
import DocumentView from '../../UserProfile/DocumentView';
import { FormFieldTypes, ToastThemes } from '../../../../constants/common';
import ChipTag from '../../../../atoms/ChipTag/ChipTag';
import { readConditions } from './util/form.util';
import { formatCurrency, showToast } from '../../../../utils/common.util';
import MultiFileUploadView from '../../../../atoms/FileUpload/MultiFileUploadView';
import { getFormattedDate } from '../../../../utils/formatter';
import { useDispatch } from 'react-redux';
import { getTRFClaimForm } from '../../../../redux/form-builder/action';

export default function FormView(props) {
  const {
    list,
    info,
    documentsEditDisabled,
    hideFields = true,
    publicHoliday,
  } = props;
  const [formItems, setFormItems] = useState(list);
  const [isTRFFetched, setIsTRFFetched] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setFormItems(list);
  }, [list]);

  useEffect(() => {
    const newList = list.map((item) => {
      const { conditional, fieldType } = item;
      if (conditional) {
        const { rules, action } = conditional;
        const isValid = rules.some((rule) => {
          const { conjunction, conditions } = rule;
          if (readConditions(conditions, conjunction, info, publicHoliday)) {
            return true;
          }
        });
        if (action)
          return {
            ...item,
            [action]: !isValid,
          };
      }
      return item;
    });
    setFormItems(newList);
  }, [info]);

  useEffect(() => {
    let isMounted = true;
    if (!isTRFFetched && info?.multiTRF?.length) {
      const ids =
        info?.multiTRF?.length && info.multiTRF.map((item) => Number(item.id));
      dispatch(getTRFClaimForm(ids))
        .then(() => {
          if (isMounted) setIsTRFFetched(true);
        })
        .catch((err) => {
          if (isMounted) showToast(err, ToastThemes.error);
        });
    }

    return () => {
      isMounted = false;
    };
  }, [info.multiTRF, isTRFFetched]);

  const getFormattedValue = (value, percisionNumber, isCurrencyFormater) => {
    let formattedValue = value || 0;
    if (percisionNumber) {
      formattedValue = Number(
        parseFloat(formattedValue)?.toFixed(percisionNumber)
      );
    }
    if (isCurrencyFormater) {
      formattedValue = formatCurrency(formattedValue, percisionNumber);
    }
    return isCurrencyFormater
      ? formattedValue
      : percisionNumber
      ? Number(formattedValue).toFixed(percisionNumber)
      : formattedValue;
  };

  const getValue = (
    type,
    subtype,
    name,
    dataInputFormat,
    percisionNumber,
    isCurrencyFormater,
    dateFormat
  ) => {
    switch (type) {
      case FormFieldTypes.TRF:
      case FormFieldTypes.MutliSelect: {
        return <ChipTag values={info?.[name]} />;
      }
      case FormFieldTypes.CurrencyExchangeField:
      case FormFieldTypes.UserBasedSSL:
      case FormFieldTypes.SingleSelect: {
        return info?.[name]?.name;
      }
      case FormFieldTypes.File: {
        return (
          <DocumentView
            name={name}
            info={info || {}}
            type={subtype}
            documentsEditDisabled={documentsEditDisabled}
          />
        );
      }
      case FormFieldTypes.Date: {
        return moment(info?.[name], 'YYYY-MM-DD', true).isValid()
          ? moment(info?.[name], 'YYYY-MM-DD').format(
              dateFormat || DateFormatters.ddmmyyyy
            )
          : info?.[name];
      }
      case FormFieldTypes.Time: {
        return info?.[name]
          ? moment(info?.[name], 'HH:mm').format('hh:mm a')
          : '';
      }
      case FormFieldTypes.DateTime: {
        return info?.[name]
          ? moment(info?.[name], 'YYYY-MM-DD HH:mm')
              .utc(info?.[name], 'YYYY-MM-DD HH:mm')
              .local()
              .format(dataInputFormat || 'DD/MM/YYYY hh:mm a')
          : '';
      }
      case FormFieldTypes.Month: {
        return info?.[name]
          ? moment(info?.[name], 'YYYY-MM').format('MMM/YYYY')
          : '';
      }
      case FormFieldTypes.Year: {
        return info?.[name] ? moment(info?.[name], 'YYYY').format('YYYY') : '';
      }
      case FormFieldTypes.CheckBox: {
        return String(info?.[name] ?? '');
      }
      case FormFieldTypes.MultiFileUpload: {
        return info?.[name] ? (
          <Box
            style={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto' }}
          >
            <MultiFileUploadView fileRes={info?.[name]} />
          </Box>
        ) : (
          ''
        );
      }
      case FormFieldTypes.Formula: {
        return getFormattedValue(
          info?.[name],
          percisionNumber,
          isCurrencyFormater
        );
      }
      case FormFieldTypes.EntitlementAutoPopulate: {
        return isNaN(info?.[name])
          ? info?.[name]
          : getFormattedValue(
              info?.[name],
              percisionNumber,
              isCurrencyFormater
            );
      }
      case FormFieldTypes.DateRange: {
        return info?.[name]?.length > 0
          ? `${getFormattedDate(
              info?.[name]?.[0],
              'YYYY-MM-DD',
              'DD/MM/YYYY'
            )} - 
                ${getFormattedDate(
                  info?.[name]?.[1],
                  'YYYY-MM-DD',
                  'DD/MM/YYYY'
                )}`
          : [null, null];
      }
      case FormFieldTypes.AutoPopulate:
      case FormFieldTypes.ProRateField: {
        return moment(info?.[name], 'YYYY-MM-DD', true).isValid()
          ? moment(info?.[name], 'YYYY-MM-DD').format(
              dateFormat || DateFormatters.ddmmyyyy
            )
          : info?.[name];
      }
      default: {
        return subtype === FormFieldTypes.Number
          ? info?.[name] !== 0
            ? getFormattedValue(
                info?.[name],
                percisionNumber,
                isCurrencyFormater
              )
            : 0
          : info?.[name];
      }
    }
  };
  const formList = hideFields
    ? formItems.filter((item) => !item.isHidden)
    : formItems;
  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container rowSpacing={3} columnSpacing={8} pr={8}>
          {formList.map((item) => {
            const {
              fieldLabel,
              fieldRefName,
              isRequired,
              fieldType,
              dataType,
              textTransform,
              fieldSize = 6,
              dataInputFormat,
              percisionNumber,
              isCurrencyFormater,
              dateFormat,
              tooltipText
            } = item;
            return (
              <Grid item md={fieldSize} xs={12} key={fieldRefName}>
                <FieldView
                  labelText={fieldLabel}
                  labelValue={getValue(
                    fieldType,
                    dataType,
                    fieldRefName,
                    dataInputFormat,
                    percisionNumber,
                    isCurrencyFormater,
                    dateFormat
                  )}
                  required={isRequired}
                  textTransform={textTransform}
                  tooltipText={tooltipText}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}