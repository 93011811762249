import { Box, Grid } from '@mui/material';
import React from 'react';
import CardUI from '../ui-elements/CardUI';
import './UserDetails.css';

const UserProfile = (props) => {
  const data = [
    { title: 'Name Of Claimant', value: props.name },
    { title: 'Employee ID', value: props.id },
    { title: 'Designation', value: props.designation },
    { title: 'Department', value: props.department },
  ];
  return (
    <React.Fragment>
      <Grid xs={12} sm={10} style={{ marginBottom: '1em' }}>
      <CardUI>
        <Grid
          container
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {data &&
            data.map((each, i) => {
              return (
                <Grid
                  key={i}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={6}
                  className='user-detail-card'
                >
                  <Box>
                    <div style={{ fontSize: '1em' }}>{each.title}</div>
                    <span style={{ fontSize: '0.8em' }}>{each.value}</span>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </CardUI>
      </Grid>
    </React.Fragment>
  );
};
export default UserProfile;
