import React, { useState } from 'react';
import { Box, Button, Typography, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ModalDialog from '../../../../../atoms/Modal/ModalDialog';
import OfferApproval from '../OfferApproval/OfferApproval';
import BtnDropDown from '../../../../../atoms/Buttons/BtnDropDown';
import ListView from '../../../../../atoms/DataList/ListView';

import {
  ArchiveOutlined,
  DeleteOutlineOutlined,
  EditOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  SwipeLeftOutlined,
  TaskAltOutlined,
  StopCircleOutlined,
} from '@mui/icons-material';
import DateRange from '../../../../molecules/DateRange/DateRange';

export default function AllOffers() {
  const [showModal, setshowModal] = useState(true);

  const handleClick = () => {
    setshowModal(true);
  };
  return (
    <>
      <Box
        my={-3}
        pt={3}
        pb={3}
        display={'flex'}
        justifyContent={'flex-end'}
        backgroundColor={'white'}
      >
        <Box display='flex' justifyContent='flex-end' gap={2} mb={2} pr={2}>
          <DateRange />
          <Box>
            <BtnDropDown BtnOptions={BtnOptions} selectedOption={0} />
          </Box>
        </Box>
      </Box>
      <ListView headCells={headCells} rowsData={rows} showCheckbox />

      {/* <ModalDialog
        title='Interview Scheduled'
        description={<InterviewSchedule />}
        showModal={showModal}
        maxWidth='md'
        severity='success'
        primaryAction='Save'
        secondaryAction='Cancel'
        fullWidth
      /> */}

      {/* <ModalDialog
        title='Offer Approval'
        description={<OfferApproval />}
        showModal={showModal}
        maxWidth='md'
        severity='success'
        primaryAction='Send for Approval'
        secondaryAction='Cancel'
        fullWidth
      /> */}
    </>
  );
}

const BtnOptions = [
  {
    title: 'Approve',
    icon: <TaskAltOutlined sx={{ width: 20, height: 20 }} />,
  },
  {
    title: 'Reject',
    icon: <SwipeLeftOutlined sx={{ width: 20, height: 20 }} />,
  },
  {
    title: 'Hold',
    icon: <StopCircleOutlined sx={{ width: 20, height: 20 }} />,
  },
  {
    title: 'Download',
    icon: <FileDownloadOutlined sx={{ width: 20, height: 20 }} />,
  },
  {
    title: 'Archive',
    icon: <ArchiveOutlined sx={{ width: 20, height: 20 }} />,
  },
];

const dropDownList = [
  { id: 1, name: '1.Resume Shortlisted' }, //
  { id: 2, name: '2.Resume Rejected' }, //
  { id: 3, name: '3.Interview Scheduled' }, // Modal with History + Actions
  { id: 4, name: '4.On Hold' }, //
  { id: 5, name: '5.Candidate Selected' }, //
  { id: 6, name: '6.Offer Released ' }, // Modal
  { id: 7, name: '7.Not Qualified' }, //
  { id: 8, name: '8.Joining Pending' }, //
];

const headCells = [
  {
    id: 'cName',
    numeric: false,
    disablePadding: false,
    label: 'Candidate Name',
  },
  {
    id: 'rName',
    numeric: false,
    disablePadding: false,
    label: 'Recruiter Name',
  },
  {
    id: 'position',
    numeric: false,
    disablePadding: false,
    label: 'Position',
  },

  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'rating',
    numeric: false,
    disablePadding: false,
    label: 'Final Rating',
  },

  {
    id: 'ePay',
    numeric: false,
    disablePadding: false,
    label: 'Expected Pay',
  },

  {
    id: 'pPay',
    numeric: false,
    disablePadding: false,
    label: 'Purposed Pay',
  },

  {
    id: 'cv',
    numeric: false,
    disablePadding: false,
    label: 'CV',
  },

  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: <AddIcon />,
  },
];

function createData(
  cName,
  rName,
  position,
  status,
  finalRating,
  ePay,
  pPay,
  cv,
  action
) {
  return {
    cName,
    rName,
    position,
    status,
    finalRating,
    ePay,
    pPay,
    cv,
    action,
  };
}

const rows = [
  createData(
    'Neha Singh',
    'Aditya ',
    'Manager',
    'Hold',
    '5',
    '10000',
    '15000',
    'resume.pdf',
    <>
      <InfoOutlinedIcon sx={{ color: 'primary.main80' }} />{' '}
    </>
  ),
  createData(
    'Neha Singh',
    'Aditya ',
    'Manager',
    'Hold',
    '5',
    '10000',
    '15000',
    'resume.pdf',
    <>
      <InfoOutlinedIcon sx={{ color: 'primary.main80' }} />{' '}
    </>
  ),
  createData(
    'Neha Singh',
    'Aditya ',
    'Manager',
    'Hold',
    '5',
    '10000',
    '15000',
    'resume.pdf',
    <>
      <InfoOutlinedIcon sx={{ color: 'primary.main80' }} />{' '}
    </>
  ),
  createData(
    'Neha Singh',
    'Aditya ',
    'Manager',
    'Hold',
    '5',
    '10000',
    '15000',
    'resume.pdf',
    <>
      <InfoOutlinedIcon sx={{ color: 'primary.main80' }} />{' '}
    </>
  ),
  createData(
    'Neha Singh',
    'Aditya ',
    'Manager',
    'Hold',
    '5',
    '10000',
    '15000',
    'resume.pdf',
    <>
      <InfoOutlinedIcon sx={{ color: 'primary.main80' }} />{' '}
    </>
  ),
  createData(
    'Neha Singh',
    'Aditya ',
    'Manager',
    'Hold',
    '5',
    '10000',
    '15000',
    'resume.pdf',
    <>
      <InfoOutlinedIcon sx={{ color: 'primary.main80' }} />{' '}
    </>
  ),
  createData(
    'Neha Singh',
    'Aditya ',
    'Manager',
    'Hold',
    '5',
    '10000',
    '15000',
    'resume.pdf',
    <>
      <InfoOutlinedIcon sx={{ color: 'primary.main80' }} />{' '}
    </>
  ),
];
