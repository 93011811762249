import React, { useEffect, useState } from 'react';
import Conditional from './conditional.component';
import { AddOutlined } from '@mui/icons-material';
import { Box, IconButton, Grid, Divider, Typography } from '@mui/material';
import SelectTag from '../../../../atoms/Select/SelectTag';
import { conditionalActionTypes } from '../../utils/conditional.util';
import { isEmpty } from 'lodash';
import AccordionTabs from '../../../../atoms/Accordian/Accordian';

const MultipleConditional = (props) => {
  const { setFormInfo, conditional } = props;
  const { rules, ...rest } = conditional || {};

  const [conditionalRules, setConditionalRules] = useState(rules || [{}]);
  const [form, setForm] = useState(rest || {});

  useEffect(() => {
    if (isEmpty(form)) {
      setFormInfo((prev) => ({
        ...prev,
        isHidden: false,
        isDisabled: false,
        conditional: null,
      }));
    } else {
      setFormInfo((prev) => ({
        ...prev,
        conditional: {
          ...form,
          rules: conditionalRules,
        },
      }));
    }
  }, [conditionalRules, form]);

  const handleDropDown = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case 'action': {
        if (value === 'isHidden') {
          setFormInfo((prev) => ({
            ...prev,
            isHidden: true,
            isDisabled: false,
          }));
        } else {
          setFormInfo((prev) => ({
            ...prev,
            isHidden: false,
            isDisabled: true,
          }));
        }
        break;
      }
      default:
        break;
    }
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={8}>
        <Grid item xs={12} md={12}>
          <SelectTag
            labelText="Select Action"
            name="action"
            dropDownList={conditionalActionTypes}
            size="small"
            fullWidth
            value={form.action}
            onchange={handleDropDown}
            placeholder='Select'
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      {conditionalRules.map((item, index) => {
        const { conditions: storedConditions, ...rest } = item || {};
        return (
          <Box key={index} pl={15} position={'relative'}>
            <Box
              position={'absolute'}
              left={0}
              top={'5px'}
              border={'1px solid #00806E'}
              borderRadius={4}
              padding={'5px 17px 3px'}
              width="max-content"
            >
              {index === 0 ? (
                <Typography>if</Typography>
              ) : (
                <Typography>Else if</Typography>
              )}
            </Box>
            <AccordionTabs
              title={`Rule ${index + 1}`}
              bgColorSummary="rgba(167, 236, 226, 0.3)"
              tabDetails={
                <Box key={index} mb={2}>
                  <Conditional
                    index={index}
                    conditionalFormState={rest}
                    setConditionalRules={setConditionalRules}
                    storedConditions={storedConditions}
                    noOfConditions={conditionalRules.length}
                    setFormInfo={setFormInfo}
                    setOuterForm={setForm}
                  />
                </Box>
              }
            />
          </Box>
        );
      })}
      <IconButton
        onClick={() =>
          setConditionalRules((prev) => {
            return [...prev, {}];
          })
        }
        sx={{
          width: 'max-content',
        }}
      >
        <AddOutlined />
      </IconButton>
    </>
  );
};

export default MultipleConditional;
