import React from 'react';
import BtnDropDown from '../../../../atoms/Buttons/BtnDropDown';
import { AccountBalanceOutlined, PaidOutlined, TaskAltOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { AllowBankTransfer, AllowPay, AllowPublish, PayrollStatus } from '../../Constant/Constant';
import { permissionsObj } from '../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';

const DropDownActionForPayroll = (props) => {
  const { checkedItems, handlePublish, handlePaid, handleBankTransfer } = props;
  const { payrollIdPropertiesMap } = useSelector((state) => state.payroll);
  const { payrollPermission } = useSelector((state) => state.modulePermissions);

  const publishDisabled = !!checkedItems.find(
    (item) => payrollIdPropertiesMap?.[item.id]?.status !== PayrollStatus.Save
  );

  const payDisabled = !!checkedItems.find(
    (item) => payrollIdPropertiesMap?.[item.id]?.status !== PayrollStatus.BankTransfer
  );

  const bankDisabled = !!checkedItems.find(
    (item) => payrollIdPropertiesMap?.[item.id]?.status !== PayrollStatus.Approved
  );

  const payrollBtnOption = [
    {
      id: 1,
      title: 'Publish',
      icon: <TaskAltOutlined sx={{ width: 20, height: 20 }} />,
      handleClick: handlePublish,
      disabled: !checkedItems.length || publishDisabled,
      hide: !payrollPermission?.[AllowPublish]?.[permissionsObj.allowView],
    },
    {
      id: 1,
      title: 'Pay Salary',
      icon: <PaidOutlined sx={{ width: 20, height: 20 }} />,
      handleClick: handlePaid,
      disabled: !checkedItems.length || payDisabled,
      hide: !payrollPermission?.[AllowPay]?.[permissionsObj.allowView],
    },
    {
      id: 1,
      title: 'Bank Transfer',
      icon: <AccountBalanceOutlined sx={{ width: 20, height: 20 }} />,
      handleClick: handleBankTransfer,
      disabled: !checkedItems.length || bankDisabled,
      hide: !payrollPermission?.[AllowBankTransfer]?.[permissionsObj.allowView],
    }
  ];
  if (payrollBtnOption.filter((item) => !item.hide).length > 0) {
    return (
      <BtnDropDown
        BtnOptions={payrollBtnOption.filter((item) => !item.hide)}
        selectedOption={0}
      />
    );
  }
  return true;
};
export default DropDownActionForPayroll;
