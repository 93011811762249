import {
  Box,
  Checkbox,
  FormControlLabel,
  Select,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import SelectTag from '../Select/SelectTag';
import { LightTooltip } from '../Tooltip/LightTooltip';
import { InfoOutlined } from '@mui/icons-material';
import FieldView from '../../components/molecules/FieldView/FieldView';

const CheckBoxInput = (props) => {
  const {
    name,
    value,
    onChange,
    label,
    required,
    precision = 1,
    size = 'large',
    max = 5,
    disabled,
    isReadOnly,
    align = 'horizontal',
    marginTop = 0,
    infoIcon,
  } = props;

  if (isReadOnly) {
    return value ? String(value) : '';
  }
  return (
    <Box
      display={align === 'horizontal' ? 'flex' : 'block'}
      justifyContent={'space-between'}
      alignItems="center"
    >
      <FormControlLabel
        value={value ? true : false}
        control={
          <Checkbox
            checked={value ? true : false}
            onChange={onChange}
            name={name}
            color="primary"
            disabled={disabled}
          />
        }
        label={label}
        disabled={disabled}
      />
      {required && (
        <Box color="accentSecondary.main" sx={{ display: 'inline-block' }}>
          *
        </Box>
      )}
    </Box>
  );
};

export default CheckBoxInput;
