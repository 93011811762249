import { getFormBody } from '../../components/OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { CurrencyConversionService } from '../../services/currencyConversionService';
import {
  GET_ALL_CURRENCY_CONVERSION_LIST_FAILURE,
  GET_ALL_CURRENCY_CONVERSION_LIST_REQUEST,
  GET_ALL_CURRENCY_CONVERSION_LIST_SUCCESS,
  GET_CURRENCY_CONVERSION_FORM_DATA_FAILURE,
  GET_CURRENCY_CONVERSION_FORM_DATA_REQUEST,
  GET_CURRENCY_CONVERSION_FORM_DATA_SUCCESS,
  POST_CURRENCY_CONVERSION_FORM_DATA_FAILURE,
  POST_CURRENCY_CONVERSION_FORM_DATA_REQUEST,
  POST_CURRENCY_CONVERSION_FORM_DATA_SUCCESS,
  UPDATE_CURRENCY_CONVERSION_FORM_DATA_FAILURE,
  UPDATE_CURRENCY_CONVERSION_FORM_DATA_REQUEST,
  UPDATE_CURRENCY_CONVERSION_FORM_DATA_SUCCESS,
} from './actionTypes';

const getAllCurrencyCoversionListRequest = () => ({
  type: GET_ALL_CURRENCY_CONVERSION_LIST_REQUEST,
});

const getAllCurrencyCoversionListSuccess = (payload) => ({
  type: GET_ALL_CURRENCY_CONVERSION_LIST_SUCCESS,
  payload,
});

const getAllCurrencyCoversionListFailure = () => ({
  type: GET_ALL_CURRENCY_CONVERSION_LIST_FAILURE,
});

const getCurrencyFormDataRequest = () => ({
  type: GET_CURRENCY_CONVERSION_FORM_DATA_REQUEST,
});

const getCurrencyFormDataSucess = (payload) => ({
  type: GET_CURRENCY_CONVERSION_FORM_DATA_SUCCESS,
  payload,
});

const getCurrencyFormDataFailure = () => ({
  type: GET_CURRENCY_CONVERSION_FORM_DATA_FAILURE,
});


const postCurrencyFormDataRequest = () => ({
  type: POST_CURRENCY_CONVERSION_FORM_DATA_REQUEST,
});

const postCurrencyFormDataSucess = (payload) => ({
  type: POST_CURRENCY_CONVERSION_FORM_DATA_SUCCESS,
  payload,
});

const postCurrencyFormDataFailure = () => ({
  type: POST_CURRENCY_CONVERSION_FORM_DATA_FAILURE,
});

const updateCurrencyFormDataRequest = () => ({
  type: UPDATE_CURRENCY_CONVERSION_FORM_DATA_REQUEST,
});

const updateCurrencyFormDataSucess = () => ({
  type: UPDATE_CURRENCY_CONVERSION_FORM_DATA_SUCCESS,
});

const updateCurrencyFormDataFailure = () => ({
  type: UPDATE_CURRENCY_CONVERSION_FORM_DATA_FAILURE,
});

export const getAllCurrencyConversionList =
  (currentUserRoleId,startMonth,startYear,endMonth,endYear) => async (dispatch) => {
    dispatch(getAllCurrencyCoversionListRequest());
    new CurrencyConversionService()
      .getAllCurrencyConversionList(currentUserRoleId,startMonth,startYear,endMonth,endYear)
      .then((res) => {
        dispatch(getAllCurrencyCoversionListSuccess(res));
      })
      .catch((err) => {
        dispatch(getAllCurrencyCoversionListFailure());
      });
  };

  export const getCurrencyConversionDetails =
  (currentUserRoleId,currencyId) => async (dispatch) => {
    dispatch(getCurrencyFormDataRequest());
    new CurrencyConversionService()
      .getCurrencyConversionDetails(currentUserRoleId,currencyId)
      .then((res) => {
        dispatch(getCurrencyFormDataSucess(res));
      })
      .catch((err) => {
        dispatch(getCurrencyFormDataFailure());
      });
  };

  export const postCurrencyConversionInfo = (currentUserRoleId, body) => async (dispatch) => {
    dispatch(postCurrencyFormDataRequest());
    return new CurrencyConversionService().postCurrencyInfo(currentUserRoleId,body).then((res) => {
      dispatch(postCurrencyFormDataSucess());
      return res.id;
    }).catch((err) => {
      dispatch(postCurrencyFormDataFailure());
      throw err?.response?.data?.message || 'Error Saving';
    });
  };

  export const updateCurrencyConversionInfo = (currentUserRoleId, body,currencyId) => async (dispatch) => {
    dispatch(updateCurrencyFormDataRequest());
    return new CurrencyConversionService().updateCurrencyInfo(currentUserRoleId,body,currencyId).then((res) => {
      dispatch(updateCurrencyFormDataSucess());
    }).catch((err) => {
      dispatch(updateCurrencyFormDataFailure());
      throw err?.response?.data?.message || 'Error Saving';
    });
  };