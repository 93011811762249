import {
  GroupsOutlined,
  HowToRegOutlined,
  PeopleAltOutlined,
  PersonAddOutlined,
  PersonOffOutlined,
  PersonOutlineOutlined,
  SupervisorAccountOutlined,
} from '@mui/icons-material';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import { Box } from '@mui/material';
import React from 'react';
import StyledIconBox from './permission-type.styled';
import { LightTooltip } from '../../../../../../atoms/Tooltip/LightTooltip';
import theme from '../../../../../../foundation/theme';

const PermissionsType = (props) => {
  const { formInfo, setFormInfo } = props;

  const permissionTypeData = [
    {
      title: 'No Data',
      icon: <PersonOffOutlined sx={{ color: '#717a79', fontSize: 40 }} />,
    },
    {
      title: 'My Data',
      icon: <PersonOutlineOutlined sx={{ color: '#717a79', fontSize: 40 }} />,
    },
    {
      title: 'Approver Data',
      icon: <HowToRegOutlined sx={{ color: '#717a79', fontSize: 40 }} />,
    },
    {
      title: 'Approver Data With Dashboard Approve Access ',
      icon: <PersonAddOutlined sx={{ color: '#717a79', fontSize: 40 }} />,
    },
    {
      title: 'Reportees Data',
      icon: <PeopleAltOutlined sx={{ color: '#717a79', fontSize: 40 }} />,
    },
    {
      title: 'Department Data',
      icon: (
        <SupervisorAccountOutlined sx={{ color: '#717a79', fontSize: 40 }} />
      ),
    },
    {
      title: 'All Approved Data',
      icon: (
        <PersonSearchOutlinedIcon sx={{ color: '#717a79', fontSize: 40 }} />
      ),
    },
    {
      title: 'All Data',
      icon: <GroupsOutlined sx={{ color: '#717a79', fontSize: 40 }} />,
    },
  ];

  const handleTypeClick = (id) => {
    if (id !== 0) {
      setFormInfo((prev) => ({
        ...prev,
        viewAccess: id,
        allowView: true,
      }));
    } else {
      setFormInfo((prev) => ({
        ...prev,
        viewAccess: id,
        allowView: false,
        allowEdit: false,
        allowAdd: false,
      }));
  }
};

  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="repeat(8, 80px)"
        gridTemplateRows="80px"
        gap="50px 50px"
        padding={1}
      >
        {permissionTypeData.map((item, index) => (
          <>
            <LightTooltip
              title={item?.title}
              placement="bottom-end"
              arrow
              bgColor="#212B36"
              color={theme.palette.neutral.light60}
              sx={{ margin: 2 }}
            >
              <StyledIconBox
                onClick={() => handleTypeClick(index)}
                color={formInfo?.viewAccess === index ? '#00806E' : ''}
              >
                {React.cloneElement(item?.icon, {
                  sx: {
                    color:
                      formInfo?.viewAccess === index ? '#00806E' : '#717a79',
                    fontSize: 40,
                  },
                })}
              </StyledIconBox>
            </LightTooltip>
          </>
        ))}
      </Box>
    </>
  );
};

export default PermissionsType;
