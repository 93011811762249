import HealthIcon from '../assets/health.svg';
import TransportIcon from '../assets/OvertimeClaims.svg';
import OvertimeIcon from '../assets/OT.svg';
import ComissionIcon from '../assets/comission.svg';
import StudyIcon from '../assets/study.svg';

export const CLAIM_LINKS = [
  {
    id: '1',
    linkName: 'Flexible Benefit / Maternity Benefit / Health Screening',
    icon: HealthIcon,
    path: 'flexible-benefit',
    key: 'BFS',
  },
  {
    id: '2',
    linkName: 'Adhoc Transport Claim',
    icon: TransportIcon,
    path: 'transport-claim',
    key: 'EXP',
  },

  {
    id: '3',
    linkName: 'Overtime Claim',
    icon: OvertimeIcon,
    path: 'overtime-claim',
    key: 'OVT',
  },
  {
    id: '4',
    linkName: 'Commission Claim',
    icon: ComissionIcon,
    path: 'commission-claim',
    key: 'CMS',
  },
  {
    id: '8',
    linkName: 'Department/Union Gathering Claim',
    icon: StudyIcon,
    path: 'union-gathering',
    key: 'BFS',
  },
  {
    id: '9',
    linkName: 'Unergizer Claim',
    icon: StudyIcon,
    path: 'unergizer-claim',
    key: 'BFS',
  },
  {
    id: '6',
    linkName: 'Staff Training',
    icon: StudyIcon,
    path: 'staff-training',
    key: 'BFS',
  },
  {
    id: '7',
    linkName: 'Meeting And Refreshments',
    icon: StudyIcon,
    path: 'meeting-refreshment',
    key: 'EXP',
  },
  {
    id: '22',
    linkName: 'Printing And Stationary',
    icon: StudyIcon,
    path: 'printing-and-stationary',
    key: 'EXP',
  },
  {
    id: '10',
    linkName: 'General Expenses',
    icon: StudyIcon,
    path: 'general-expenses',
    key: 'EXP',
  },
  {
    id: '11',
    linkName: 'Postage And Courier',
    icon: StudyIcon,
    path: 'postage-and-courier',
    key: 'EXP',
  },
  {
    id: '12',
    linkName: 'Acq Of Material-Book',
    icon: StudyIcon,
    path: 'acq-of-material-book',
    key: 'EXP',
  },
  {
    id: '13',
    linkName: 'Consumables (SA)',
    icon: StudyIcon,
    path: 'consumables',
    key: 'EXP',
  },
  {
    id: '14',
    linkName: 'Activity Expenses',
    icon: StudyIcon,
    path: 'activity-expenses',
    key: 'EXP',
  },
  {
    id: '15',
    linkName: 'Study Trip',
    icon: StudyIcon,
    path: 'study-trip',
    key: 'EXP',
  },
  {
    id: '16',
    linkName: 'May Day Main Comm',
    icon: StudyIcon,
    path: 'may-day-main-comm',
    key: 'EXP',
  },
  {
    id: '17',
    linkName: 'May Day Rally',
    icon: StudyIcon,
    path: 'may-day-rally',
    key: 'EXP',
  },
  {
    id: '18',
    linkName: 'May Day Awards And Dinner',
    icon: StudyIcon,
    path: 'may-day-awards-and-dinner',
    key: 'EXP',
  },
  {
    id: '19',
    linkName: 'May Day Fiesta',
    icon: StudyIcon,
    path: 'may-day-fiesta',
    key: 'EXP',
  },
  {
    id: '20',
    linkName: 'May Day Publicity',
    icon: StudyIcon,
    path: 'may-day-publicity',
    key: 'EXP',
  },
  {
    id: '21',
    linkName: 'May Day Outreach',
    icon: StudyIcon,
    path: 'may-day-outreach',
    key: 'EXP',
  },
  {
    id: '24',
    linkName: 'Marriage Benefit',
    icon: StudyIcon,
    path: 'marriage',
    key: 'BFS',
  },
  {
    id: '25',
    linkName: 'Child Birth Benefit',
    icon: StudyIcon,
    path: 'child-birth',
    key: 'BFS',
  },
  {
    id: '26',
    linkName: 'Retirement Gift',
    icon: StudyIcon,
    path: 'retirement',
    key: 'BFS',
  },
  {
    id: '23',
    linkName: 'Funeral Assistance',
    icon: TransportIcon,
    path: 'funeral-assistance',
    key: 'BFS',
  },
  {
    id: '31',
    linkName: 'Study Loan',
    icon: TransportIcon,
    path: 'study-loan',
    key: 'BFS',
  },
  {
    id: '28',
    linkName: 'Computer Loan',
    icon: TransportIcon,
    path: 'computer-loan',
    key: 'BFS',
  },
];

export const MAIN_CLAIMS = [
  {
    id: '1',
    linkName: 'Expenses',
    icon: HealthIcon,
    key: 'EXP',
  },
  {
    id: '2',
    linkName: 'Benefits',
    icon: TransportIcon,
    key: 'BFS',
  },
  {
    id: '4',
    linkName: 'Commission',
    icon: ComissionIcon,
    path: 'commission-claim',
    key: 'CMS',
  },
  {
    id: '3',
    linkName: 'Overtime',
    icon: OvertimeIcon,
    path: 'overtime-claim',
    key: 'OVT',
  },
];

export const PROFILE = {
  MARKETPLACE_USER: 'MARKETPLACE_USER',
  EMPLOYEE: 'Employee',
};

export const FORM = {
  DATE: 'Date',
  DATE_FORMAT: 'DD-MM-YYYY'
};

export const MARKETPLACE_USER_TOKEN = 'MARKETPLACE_TOKEN';

export   const FIELD_TYPES = {
  NUMBER: 'number',
  TEXT: 'text',
  SELECT: 'select',
  DATE: 'date',
  TIME: 'time',
  FILES: 'files',
  BOOLEAN: 'boolean'
};

export const Save = 'Save';

export const searchQuery = 'q';