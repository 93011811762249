import React from 'react';
import { Grid, Paper, Box } from '@mui/material';
import FormView from '../../../../OnBoarding/common/Form/FormView';

const UnergizerView = (props) => {
  const { formData, config } = props;
  return (
    <>
      <Paper sx={{ flexGrow: 1, p: 4, my: 1 }}>
        {formData.map((item, index) => {
          return (
            <Box
              key={index}
              mb={2}
            >
              <FormView list={config} info={item} hideFields={false} />
            </Box>
          );
        })}
      </Paper>
    </>
  );
};

export default UnergizerView;
