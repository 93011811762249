import { DeleteOutlineOutlined } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React from 'react';
import StyledDelete from '../StyledDelete/StyledDelete';

const MultiFileUploadView = (props) => {
  const { fileRes = [], handleRemove = () => {}, remove } = props;
  return fileRes?.map((file, index) => {
    const type = file?.mimeType?.split('/');
    return (
      <Box key={index}>
        <a href={file.url} download={file.fileName}>
          <Button
            variant="text"
            sx={{
              maxWidth: 100,
              justifyContent: 'flex-end',
              overflow: 'hidden',
            }}
            title={file.fileName}
          >
            {type?.[0] === 'image' ? (
              <img src={file.url} height={30} alt={file.fileName} />
            ) : (
              <Box
                sx={{
                  maxWidth: 100,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {file.fileName || ''}
              </Box>
            )}
          </Button>
        </a>
        {remove && (
          <>
            <StyledDelete onClick={() => handleRemove(index)}>
              <DeleteOutlineOutlined />
            </StyledDelete>
          </>
        )}
      </Box>
    );
  });
};

export default MultiFileUploadView;
