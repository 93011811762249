import React, { useEffect, useState } from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { notAllowedStatus } from '../../../config/payment-date-config';

const PaymentDate = ({
  payDate,
  onChange,
  fromDashboard,
  claimsData,
  currentUserType,
  disabledPicker = false,
  style={}
}) => {
  const [disabled, setIsDisabled] = useState(disabledPicker);
  const [showDatePicker, setShowDatePicker] = useState(true);

  const shouldDisableDate = (date) => {
    const travelDate = new Date(claimsData.travelDate);
    const currentDate = new Date(date);
    const providedDateShouldBeLess = travelDate > currentDate;
    return providedDateShouldBeLess;
  };
  
  useEffect(() => {
    // if (claimsData && currentUserType && !disableCheckOnRole) {
    //   useEffect(() => {
    //     getMultiLevelConfigDataID();
    //   }, [claimsData]);

    //   // Gets the config data
    //   const getMultiLevelConfigDataID = async () => {
    //     let response = await getMultiLevelConfigData(claimsData?.type?.id);
    //     if (claimsData.currentLevel == response?.numberOfSteps) {
    //       setIsDisabled(false);
    //     }
    //   };
    // }
    if (claimsData && currentUserType && !disabledPicker) {
      const roleAllowed = [
        'Fin-Officer',
        'Fin-Reviewer',
        'HR-D',
        'HR-DD',
        'HRBP',
        'HRBP_OPS',
      ];
      const notAllowed = roleAllowed.includes(currentUserType);
      setIsDisabled(!notAllowed);
      if(!notAllowed) {
        setShowDatePicker(!notAllowedStatus.includes(claimsData.status));
      }
    }

  }, [currentUserType, claimsData]);
  return (
    <React.Fragment>
      {currentUserType !== 'Employee' && showDatePicker &&
        (fromDashboard ? (
          <div style={style}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              style={style}
              disabled={disabled}
              value={payDate}
              disablePast
              onChange={onChange}
              inputFormat="dd-MM-yyyy"
              renderInput={(params) => (
                <TextField size="small" variant="outlined" {...params} />
              )}
            />
          </LocalizationProvider>
          </div>
        ) : (
          <Grid container spacing={2} style={{ marginTop: '15px' }}>
            <Grid xs={3} sm={6} md={9} lg={12} className="data-display">
              {showDatePicker ? (<Grid xs={12} sm={9} lg={3} style={style} >
                <Typography className="data-title">Payment Date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled={disabled}
                    value={payDate}
                    // disablePast
                    onChange={onChange}
                    inputFormat="dd-MM-yyyy"
                    shouldDisableDate={
                      claimsData.travelDate ? shouldDisableDate : () => false
                    }
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        fullWidth
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>) : ''}
            </Grid>
          </Grid>
        ))}
    </React.Fragment>
  );
};

export default PaymentDate;
