import React from 'react';
import FieldView from '../../../../../molecules/FieldView/FieldView';
import { Box } from '@mui/material';

const CompetencyAppraisalFormView = (props) => {
    const { rating, weightageValue, remarks } = props;
    return (
        <>
            <Box display='flex' justifyContent='space-between' mb={2}>
                <FieldView
                    labelText={'Rating'}
                    labelValue={rating}
                />
                <FieldView
                    labelText={'Weightage'}
                    labelValue={weightageValue}
                />
            </Box>
            <Box>
                <FieldView
                    labelText={'Remarks'}
                    labelValue={remarks}
                />
            </Box>
        </>
    );
};

export default React.memo(CompetencyAppraisalFormView);