import { Box } from '@mui/material';
import React, { useState } from 'react';
import TabBar from '../../../../../atoms/Tabs/TabBar';
import AddPayroll from './AddPayroll';

const ManagePayroll = () => {
    const [viewMode, setViewMode] = useState(false);

    const payrollTabs = [
      { 
        name: 'add-payroll',
        label: 'Payroll',
        title: 'Payroll',
        hide: false,
        panel: <AddPayroll viewMode={viewMode} setViewMode={setViewMode} />
      }
  ];
  
    return (
      <Box>
        <TabBar
            tabPanels={payrollTabs}
            searchTabs
            disableCreate />
      </Box>
    );
};

export default ManagePayroll;