import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CardUI from '../../../shared/components/ui-elements/CardUI';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Footer from '../../footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { IconButton, Stack } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Modal from '../../../shared/components/ui-elements/Modal';
import ButtonUI from '../../../shared/components/ui-elements/ButtonUI';
import UserProfile from '../../../shared/components/claim-forms/UserProfile';
import Alert from '@mui/material/Alert';
import {
  generateCurrentTimeStamp,
  generateFormData,
  validateNumber,
  checkFilesCountHandler,
  disablePrevDateForThreeMonth,
} from '../common-services/functions';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { postFilesData, putFormData } from '../common-services/services';
import ReportingDetails from '../../../shared/components/claim-forms/ReportingDetails';
import TimePickerComponent from '../../TimePicker/TimePicker';
const changeDateAndKeepTime = ( specificTime = new Date() ) => {
  const specificDate = new Date();
  const sTime = new Date(specificTime);
  const hours = sTime.getHours();
  const minutes = sTime.getMinutes();
  const seconds = sTime.getSeconds();

  specificDate.setHours(hours);
  specificDate.setMinutes(minutes);
  specificDate.setSeconds(seconds);
  return specificDate;
};
const TransportClaim = () => {
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const navigate = useNavigate();
  const params = useLocation();
  const [type] = useState(params.state.type);
  // const uniqueNum = Math.floor(Math.random() * 10000000000) + 10000000000;
  /* eslint-disable */
  const [claimsData, setClaimsData] = useState(params.state.claimsData);
  const [errorMessage, setErrorMessage] = useState('');
  const [travleRequest, setTravelRequest] = useState('Travel Request');
  const [successMessage, setSuccessMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');
  const [inputs, setInputs] = useState({
    reportingOfficer: 'Kelvin Lionel',
    hod: 'Benjamin Koh',
    costCenter: 'Singapore Labour',
    claimReferenceNumber: claimsData?.referenceNumber,
    submissionDate: generateCurrentTimeStamp(),
    type: claimsData?.modeOfTransport,
    toFromHome: claimsData?.toFromHome ? 'Yes' : 'No',
    travelDate: claimsData?.travelDate,
    homeOfficeAmt: claimsData?.homeToOfficeAmount,
    homeToOfficeDist: claimsData?.distance,
    receiptNumber: claimsData?.receiptNumber,
    locationFrom: claimsData?.fromDestination,
    toDest: claimsData?.toDestination,
    reasonJourney: claimsData?.reasonForJourney,
    carParkFees: claimsData?.parkingFee,
    erp: claimsData?.tollFee,
    mileage: claimsData?.mileage,
    compAmount: claimsData?.computedAmount,
    finalAmount: claimsData?.amount,
    receiptAmount: claimsData?.receiptAmount,
    totalAmt: claimsData?.amount,
    paymentDate: claimsData?.paymentDate,
    departureTime: moment(changeDateAndKeepTime(claimsData?.departureTime)),
    otherReasons: claimsData?.otherReasons,
  });
  const format = 'hh:mm a';
  let userData = JSON.parse(localStorage.getItem('userData'));
  let asProfile = localStorage.getItem('asProfile');

  const validateFields = () => {
    if (inputs.type == undefined || inputs.type == null || inputs.type == '') {
      window.alert('Please Enter Mode Of Transport !');
      return;
    }
    if (
      inputs.toFromHome == undefined ||
      inputs.toFromHome == null ||
      inputs.toFromHome == ''
    ) {
      window.alert('Please Select To From Home!');
      return;
    }
    if (
      new Date(inputs.departureTime) == undefined ||
      new Date(inputs.departureTime) == null ||
      new Date(inputs.departureTime) == ''
    ) {
      window.alert('Please Select To Departure Time!');
      return;
    }
    if (
      inputs.travelDate == undefined ||
      inputs.travelDate == null ||
      inputs.travelDate == ''
    ) {
      window.alert('Please Enter Travel Date!');
      return;
    }

    if (
      inputs.locationFrom == undefined ||
      inputs.locationFrom == null ||
      inputs.locationFrom == ''
    ) {
      window.alert('Please Enter From Destination!');
      return;
    }
    if (
      inputs.toDest == undefined ||
      inputs.toDest == null ||
      inputs.toDest == ''
    ) {
      window.alert('Please Enter To Destination!');
      return;
    }

    if (
      inputs.reasonJourney == undefined ||
      inputs.reasonJourney == null ||
      inputs.reasonJourney == ''
    ) {
      window.alert('Please Enter Reason of Journey!');
      return;
    }
    if (inputs.reasonJourney === 'Others') {
      if (
        inputs.otherReasons == undefined ||
        inputs.otherReasons == null ||
        inputs.otherReasons == ''
      ) {
        window.alert('Please Enter Reason!');
        return;
      }
    }
    if (inputs.type === 'BUS/MRT/LRT' || inputs.type === 'Taxi') {
      if (inputs.toFromHome == 'Yes') {
        if (
          inputs.homeOfficeAmt == undefined ||
          inputs.homeOfficeAmt == null ||
          inputs.homeOfficeAmt == ''
        ) {
          window.alert('Please Enter Home Office Amount!');
          return;
        }
      }
      if (inputs.type === 'Taxi') {
        if (
          inputs.receiptNumber == undefined ||
          inputs.receiptNumber == null ||
          inputs.receiptNumber == ''
        ) {
          window.alert('Please Enter Receipt Number!');
          return;
        }
      }
      if (
        inputs.receiptAmount == undefined ||
        inputs.receiptAmount == null ||
        inputs.receiptAmount == ''
      ) {
        window.alert('Please Enter Receipt Amount!');
        return;
      }
      if (inputs.totalAmt <= 0) {
        window.alert('You can not submit claim with 0 amount!');
        return;
      }
    }

    if (inputs.type === 'Car' || inputs.type === 'Motorcycle') {
      if (
        inputs.carParkFees == undefined ||
        inputs.carParkFees == null ||
        inputs.carParkFees == ''
      ) {
        window.alert('Please Enter Car Park Fees!');
        return;
      }
      if (inputs.erp == undefined || inputs.erp == null || inputs.erp == '') {
        window.alert('Please Enter Toll Fee!');
        return;
      }
      if (
        inputs.mileage == undefined ||
        inputs.mileage == null ||
        inputs.mileage == ''
      ) {
        window.alert('Please Enter Mileage!');
        return;
      }
      if (inputs.toFromHome == 'Yes') {
        if (
          inputs.homeToOfficeDist == undefined ||
          inputs.homeToOfficeDist == null ||
          inputs.homeToOfficeDist == ''
        ) {
          window.alert('Please Enter Home To Office Distance!');
          return;
        }
      }
      if (inputs.finalAmount <= 0) {
        window.alert('You can not submit claim with 0 amount!');
        return;
      }
    }

    if (inputs.type === 'Taxi') {
      if (claimsData.attachment.length === 0) {
        window.alert('Attachment is necessary!');
        return false;
      }
    }
    return true;
  };

  // On file select (from the pop up)
  const onFileChange = async (e, refNumber, typeId = 2) => {
    if (!checkFilesCountHandler(e, claimsData)) {
      return;
    }
    setUploading(true);

    // Generates form data for file upload.
    let formData = await generateFormData(e, refNumber, typeId);

    // Send form data for file upload.
    let updatedPaths = await postFilesData(formData).catch(()=>setUploading(false));

    // Sets updated paths received from POST api call.
    const newArr = { ...claimsData };

    for (let paths of updatedPaths) {
      newArr.attachment.push(paths);
    }
    setClaimsData(newArr);
    setUploading(false);
  };

  useEffect(() => {
    // console.log(claimsData, claimsData.attachment.length);
  }, [claimsData]);

  const cardData = [
    {
      post: 'Reporting Officer',
      name:
        userData.userDetails.reportingTo.firstName +
        ' ' +
        userData.userDetails.reportingTo.lastName,
    },
    {
      post: 'HOD',
      name: userData.userDetails.HOD,
    },
    {
      post: 'Cost Center',
      name: userData.userDetails.costCenter,
    },
  ];

  let modeOfTransport = ['Car', 'BUS/MRT/LRT', 'Motorcycle', 'Taxi'];

  const handleChangeInput = (e, key = null, key1) => {
    // console.log('testlog');
    const values = { ...inputs };
    if (key) {
      if (key === 'departureTime') {
        values[key] = e;
        let travelDate = values['travelDate'];
        if(travelDate && moment(travelDate).isBefore(moment(new Date()).subtract(1,'day'))){
          values[key] = e;
        } else {
          const isAfter = moment(e).isAfter(moment(new Date()));
          if(isAfter){
            values[key] = null;
          } else {
            values[key] = e;
          }
        }
      } else {
        values[key] = e;
      }
    } else {
      if (key1) {
        const validate = validateNumber(e.target.name, e.target.value);
        if (!validate) {
          return false;
        }
      }
      values[e.target.name] = e.target.value;
      switch (values[e.target.name]) {
        case 'Car':
        case 'Motorcycle':
          setTollAndParkZero(values, false);
          break;
        case 'BUS/MRT/LRT':
        case 'Taxi':
          setTollAndParkZero(values, true);
          break;
        default:
          break;
      }
      switch (e.target.name) {
        case 'homeOfficeAmt':
        case 'mileage':
        case 'homeToOfficeDist':
        case 'carParkFees':
        case 'erp':
        case 'type':
        case 'toFromHome':
          amountCalculation(values);
          break;
        default:
          break;
      }
      switch (e.target.name) {
        case 'receiptAmount':
        case 'toFromHome':
        case 'homeOfficeAmt':
        case 'type':
          publicTransportCalc(values);
          break;
        default:
          break;
      }
    }
    if(key === 'travelDate'){
      values['departureTime'] = null
    }
    setInputs(values);
    if(key === 'departureTime'){
      amountCalculation(values);
      publicTransportCalc(values);
    }
  };

  // set park and toll fees to zero
  const setTollAndParkZero = (values, isPublicTranspoart) => {
    if(isPublicTranspoart){
      values.carParkFees = 0;
      values.erp = 0;
      values.homeToOfficeDist = 0;
      values.compAmount = 0;
      values.homeOfficeAmt = claimsData?.homeToOfficeAmount;
    } else {
      values.carParkFees = claimsData?.parkingFee;
      values.erp = claimsData?.tollFee;
      values.homeToOfficeDist = claimsData?.distance;
      values.compAmount = claimsData?.computedAmount;
      values.homeOfficeAmt = 0;
    }
    setInputs(values);
  };
  // set park and toll fees to zero
  const amountCalculation = (each) => {
    const {
      homeToOfficeDist,
      mileage,
      type,
      carParkFees,
      erp,
      toFromHome,
      receiptAmount,
      homeOfficeAmt,
    } = each;
    if (Number(mileage) > 0) {
      if (toFromHome === 'Yes') {
        console.log('amountCalculation called', moment(each.departureTime).toDate());
        const isBefore6_30AM = each.departureTime ? moment(each.departureTime).isBefore(moment('06:30:00 AM','hh:mm:ss a')): false;
        const isAfter9PM = each.departureTime ? moment(each.departureTime).isAfter(moment('09:00:00 PM','hh:mm:ss a')): false;
        if (type === 'Car') {
          let difference = mileage - !(isBefore6_30AM || isAfter9PM) ? homeToOfficeDist : 0 ;
          let cal = difference > 0 ? difference * 0.6 : 0;
          let totalamt = Number(carParkFees) + Number(erp) + Number(cal);
          // console.log(totalamt);
          each.compAmount =
            Number(cal).toFixed(2) < 0 ? 0 : Number(cal).toFixed(2);
          each.finalAmount = totalamt.toFixed(2) < 0 ? 0 : totalamt.toFixed(2);
          each.totalAmt = null;
        } else if (type === 'Motorcycle') {
          let difference = mileage - !(isBefore6_30AM || isAfter9PM) ? homeToOfficeDist : 0 ;
          let cal = difference > 0 ? difference * 0.25 : 0;
          let totalamt = Number(carParkFees) + Number(erp) + Number(cal);
          each.compAmount =
            Number(cal).toFixed(2) < 0 ? 0 : Number(cal).toFixed(2);
          each.finalAmount = totalamt.toFixed(2) < 0 ? 0 : totalamt.toFixed(2);
          each.totalAmt = null;
          // console.log(each);
        } else {
          let difference = receiptAmount - !(isBefore6_30AM || isAfter9PM) ? homeOfficeAmt : 0 ;
          each.totalAmt = difference.toFixed(2);
        }
      } else {
        if (type === 'Car') {
          let cal = mileage * 0.6;
          let totalamt = Number(carParkFees) + Number(erp) + Number(cal);
          each.compAmount = totalamt.toFixed(2);
          each.finalAmount = totalamt.toFixed(2);
          each.homeToOfficeDist = 0;
          each.totalAmt = null;
        } else if (type === 'Motorcycle') {
          let cal = mileage * 0.25;
          let totalamt = Number(carParkFees) + Number(erp) + Number(cal);
          each.compAmount = totalamt.toFixed(2);
          each.finalAmount = totalamt.toFixed(2);
          each.homeToOfficeDist = 0;
          each.totalAmt = null;
        }
      }
    }
    setInputs(each)
    return each;
  };

  const publicTransportCalc = (each) => {
    const { receiptAmount, homeOfficeAmt, toFromHome, type } = each;
    if (Number(receiptAmount) > 0)
    if (toFromHome === 'Yes') {
      const isAfter9PM = each.departureTime ? moment(each.departureTime).isAfter(moment('09:00:00 PM','hh:mm:ss a')): false;
      const isBefore6_30AM = each.departureTime ? moment(each.departureTime).isBefore(moment('06:30:00 AM','hh:mm:ss a')): false;
      console.log('publicTransportCalc called',isBefore6_30AM , isAfter9PM, moment(each.departureTime).toDate());
        if (type === 'BUS/MRT/LRT' || type === 'Taxi') {
          let difference = (
            Number(receiptAmount) - Number(!(isBefore6_30AM || isAfter9PM) ? homeOfficeAmt : 0 )
          ).toFixed(2);

          if (Number(difference).toFixed(2) <= 0) {
            each.totalAmt = 0;
            return;
          }
          each.totalAmt = Number(difference).toFixed(2);
        }
      } else {
        if (type === 'BUS/MRT/LRT' || type === 'Taxi') {
          each.homeOfficeAmt = 0;
          each.totalAmt = Number(receiptAmount).toFixed(2);
        }
      }
      setInputs(each);
  };

  const travelRequestfun = (type) => {
    switch (type) {
      case 'BUS/MRT/LRT':
      case 'Taxi':
        setTravelRequest('Bus Travel Request');
        break;
      case 'Motorcycle':
      case 'Car':
        setTravelRequest('Travel Request');
        break;
      default:
        break;
    }
  };

  const breadcrumbs = ['MUI', 'Core', 'Breadcrumb'];

  const generateDataForClaimRequest = (
    inputs,
    userData,
    dateTime,
    callType
  ) => {
    let finalFormData = [];
    let updateFormObject = {};
    let callForPost = callType;

    // console.log('updateFormObject', updateFormObject, finalFormData);
    if (inputs) {
      return (updateFormObject = {
        typeId: 2,
        id: claimsData.id,
        employee: userData && userData.userDetails.id,
        claimReferenceNumber: inputs.claimReferenceNumber.toString(),
        modeOfTransport: inputs.type,
        toFromHome:
          inputs.toFromHome && inputs.toFromHome === 'Yes' ? true : false,
        travelDate: inputs.travelDate,
        homeToOfficeAmount: Number(inputs.homeOfficeAmt),
        distance: Number(inputs.homeToOfficeDist),
        to: inputs.toDest,
        receiptDate: inputs.receiptDate,
        receiptNumber: inputs.receiptNumber,
        from: inputs.locationFrom,
        reasonForJourney: inputs.reasonJourney,
        otherReasons: inputs.otherReasons,
        parkingFee: Number(inputs.carParkFees),
        tollFee: Number(inputs.erp),
        mileage: Number(inputs.mileage),
        computedAmount: Number(inputs.compAmount),
        receiptAmount: Number(inputs.receiptAmount),
        amount: Number(inputs.totalAmt || inputs.finalAmount),
        filePaths: [...claimsData.attachment],
        status: callForPost,
        departureTime: inputs.departureTime,
      });
    }
  };

  const submitData = async (callType) => {
    const dateTime = generateCurrentTimeStamp();
    setFormSubmitted(true);
    setButtonClicked(callType);
    let validate = validateFields();

    if (!validate) {
      setFormSubmitted(false);
      return;
    }

    let finalFormData = generateDataForClaimRequest(
      inputs,
      userData,
      dateTime,
      callType
    );
    // console.log(finalFormData);
    // POST call for submit form data.
    let businessTravelsResponse =  await putFormData(finalFormData);
      // console.log('finalFormData', finalFormData)
    if (businessTravelsResponse === 201) {
      let successMessage = '';
      if (callType === 'PENDING') {
        successMessage = 'Claim request submitted successfully !';
      } else {
        successMessage = 'Draft saved successfully !';
      }
      setFormSubmitted(false);
      openSuccessPopUp(successMessage);
    } else {
      if (businessTravelsResponse === 400) {
        setFormSubmitted(false);
        setErrorMessage('Duplicate record!');
      }
    }
  };

  // remove attachment from claims data start
  const removeAttachement = (i) => {
    const newArr = { ...claimsData };
    newArr.attachment.splice(i, 1);
    setClaimsData(newArr);
  };
  // remove attachment from claims data end

  /** Close the modal */
  const closeBackdropHandler = () => {
    setIsBackdropOpen(false);
    navigate('/dashboard', {
      state: {
        claimType: '2',
      },
    });
  };

  // Open Modal When api response succesfully for(Draft/Submit)
  const openSuccessPopUp = (successMessage) => {
    setSuccessMessage(successMessage);
    setIsBackdropOpen(true);
  };
  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }} className="mar-top">
        <Container>
          <Grid container>
            <ReportingDetails cardData={cardData} />

            <UserProfile
              name={userData && userData.userDetails.firstName}
              id={userData && userData.userDetails.id}
              designation={userData.userDetails?.jobTitle?.name}
              department={userData.userDetails?.department?.name}
            />

            <Grid xs={11}>
              <Box>
                <Box
                  className="form-main"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '1em',
                    marginBottom: '4em',
                  }}
                >
                  <Grid xs={11}>
                    {errorMessage && (
                      <Alert severity="error">{errorMessage}</Alert>
                    )}
                    <CardUI className="form-heading-wrap">
                      <Grid
                        xs={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Grid xs={6}>
                          <h4>Adhoc Transport Claim</h4>
                        </Grid>
                        <Grid
                          xs={6}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <h4>
                            Claim Reference Number :
                            {inputs.claimReferenceNumber}
                          </h4>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      ></Grid>
                    </CardUI>
                    <CardUI className="form-content-wrap">
                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        {/* <Grid item xs={12} lg={4} md={4} sm={4}>
                                <label htmlFor=''>Claim Reference Number *</label>
                                <TextField
                                  type='number'
                                  required
                                  variant='outlined'
                                  color='primary'
                                  size='small'
                                  value={inputs.claimReferenceNumber}
                                  name='claimReferenceNumber'
                                  fullWidth
                                  disabled
                                  onChange={(e) => handleChangeInput(i, e)}
                                />
                              </Grid> */}
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor="">Mode of Transport *</label>
                          <FormControl
                            sx={{ m: 1, minWidth: 120 }}
                            fullWidth
                            size="small"
                          >
                            <Select
                              value={inputs.type}
                              onChange={(e) => {
                                handleChangeInput(e);
                                travelRequestfun(inputs.type);
                              }}
                              name="type"
                              inputProps={{ 'aria-label': 'Without label' }}
                              variant="outlined"
                              autoWidth
                            >
                              <MenuItem value="">Select</MenuItem>

                              {modeOfTransport.map((element) => {
                                return (
                                  <MenuItem key={element} value={element}>
                                    {element}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor="">To/From Home *</label>
                          <FormControl
                            sx={{ minWidth: 120 }}
                            fullWidth
                            size="small"
                          >
                            <Select
                              value={inputs.toFromHome}
                              onChange={(e) => handleChangeInput(e)}
                              name="toFromHome"
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              variant="outlined"
                              autoWidth
                            >
                              {['Yes', 'No'].map((each, i) => (
                                <MenuItem key={i} value={each}>
                                  {each}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor="">Travel Date *</label>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              inputFormat="dd-MM-yyyy"
                              value={inputs.travelDate}
                              onChange={(e) =>
                                handleChangeInput(e, 'travelDate')
                              }
                              shouldDisableDate={disablePrevDateForThreeMonth()}
                              disableFuture
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  fullWidth
                                  variant="outlined"
                                  {...params}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor="">Departure Time *</label>
                          <TimePickerComponent
                            showSecond={false}
                            value={inputs.departureTime}
                            className="xxx"
                            onChange={(e) =>
                              handleChangeInput(e, 'departureTime')
                            }
                            format={format}
                            use12Hours
                            inputReadOnly
                            selectedDate={inputs.travelDate}
                          />
                        </Grid>
                        {inputs.type === 'BUS/MRT/LRT' ||
                        inputs.type === 'Taxi' ? (
                          <Grid item xs={12} lg={4} md={4} sm={4}>
                            <label htmlFor="">Home to office Amount($)</label>
                            <TextField
                              disabled={
                                !inputs.toFromHome || inputs.toFromHome == 'No'
                              }
                              required
                              variant="outlined"
                              color="primary"
                              size="small"
                              value={inputs.homeOfficeAmt}
                              name="homeOfficeAmt"
                              fullWidth
                              type="number"
                              onChange={(e) =>
                                handleChangeInput(e, null, 'number')
                              }
                            />
                          </Grid>
                        ) : (
                          ''
                        )}
                        {inputs.type === 'Car' ||
                        inputs.type === 'Motorcycle' ? (
                          <Grid item xs={12} lg={4} md={4} sm={4}>
                            <label htmlFor="">
                              Home To Office Distance(KM) *
                            </label>
                            <TextField
                              disabled={
                                !inputs.toFromHome || inputs.toFromHome == 'No'
                              }
                              required
                              variant="outlined"
                              color="primary"
                              size="small"
                              value={inputs.homeToOfficeDist}
                              name="homeToOfficeDist"
                              fullWidth
                              type="number"
                              onChange={(e) => {
                                handleChangeInput(e, null, 'number');
                              }}
                            />
                          </Grid>
                        ) : (
                          ''
                        )}
                        {inputs.type === 'Taxi' ? (
                          <Grid item xs={12} lg={4} md={4} sm={4}>
                            <label htmlFor="">Receipt Number *</label>
                            <TextField
                              required
                              variant="outlined"
                              color="primary"
                              size="small"
                              value={inputs.receiptNumber}
                              name="receiptNumber"
                              fullWidth
                              onChange={(e) => handleChangeInput(e)}
                            />
                          </Grid>
                        ) : (
                          ''
                        )}
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor="">From *</label>
                          <TextField
                            required
                            id="outlined-required"
                            color="primary"
                            value={inputs.locationFrom}
                            name="locationFrom"
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={(e) => handleChangeInput(e)}
                          />
                        </Grid>
                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor="">To *</label>
                          <TextField
                            required
                            id="outlined-required"
                            color="primary"
                            value={inputs.toDest}
                            name="toDest"
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={(e) => handleChangeInput(e)}
                          />
                        </Grid>

                        <Grid item xs={12} lg={4} md={4} sm={4}>
                          <label htmlFor="">Reasons For Journey *</label>
                          <FormControl
                            sx={{ m: 1, minWidth: 120 }}
                            fullWidth
                            size="small"
                          >
                            <Select
                              value={inputs.reasonJourney}
                              onChange={(e) => handleChangeInput(e)}
                              name="reasonJourney"
                              inputProps={{ 'aria-label': 'Without label' }}
                              variant="outlined"
                              autoWidth
                            >
                              <MenuItem value="">Select</MenuItem>

                              {['Overtime', 'Government Work', 'Others'].map(
                                (element) => {
                                  return (
                                    <MenuItem key={element} value={element}>
                                      {element}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        {inputs.reasonJourney === 'Others' && (
                          <Grid item xs={12} lg={4} md={4} sm={4}>
                            <label htmlFor="">Reason *</label>
                            <TextField
                              required
                              id="outlined-required"
                              color="primary"
                              value={inputs.otherReasons}
                              name="otherReasons"
                              variant="outlined"
                              size="small"
                              fullWidth
                              onChange={(e) => handleChangeInput(e)}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} lg={4} md={4} sm={4}></Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <h5 style={{ fontSize: '18px' }}>Claim Details</h5>
                        <Grid
                          container
                          spacing={2}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          {inputs.type === 'Car' ||
                          inputs.type === 'Motorcycle' ? (
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor="">Parking Fee ($) *</label>
                              <TextField
                                required
                                variant="outlined"
                                color="primary"
                                size="small"
                                value={inputs.carParkFees}
                                name="carParkFees"
                                fullWidth
                                type="number"
                                onChange={(e) =>
                                  handleChangeInput(e, null, 'number')
                                }
                              />
                            </Grid>
                          ) : (
                            ''
                          )}
                          {inputs.type === 'Car' ||
                          inputs.type === 'Motorcycle' ? (
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor="">Toll Fee ($) *</label>
                              <TextField
                                required
                                variant="outlined"
                                color="primary"
                                size="small"
                                value={inputs.erp}
                                name="erp"
                                fullWidth
                                type="number"
                                onChange={(e) =>
                                  handleChangeInput(e, null, 'number')
                                }
                              />
                            </Grid>
                          ) : (
                            ''
                          )}
                          {inputs.type === 'Car' ||
                          inputs.type === 'Motorcycle' ? (
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor="">Mileage (KM) *</label>
                              <TextField
                                disabled={!inputs.toFromHome}
                                required
                                variant="outlined"
                                color="primary"
                                size="small"
                                value={inputs.mileage}
                                name="mileage"
                                fullWidth
                                type="number"
                                onChange={(e) => {
                                  handleChangeInput(e, null, 'number');
                                }}
                              />
                            </Grid>
                          ) : (
                            ''
                          )}
                          {inputs.type === 'Car' ||
                          inputs.type === 'Motorcycle' ? (
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor="">Computed Amount *</label>
                              <TextField
                                disabled
                                required
                                variant="outlined"
                                color="primary"
                                size="small"
                                value={inputs.compAmount}
                                name="compAmount"
                                fullWidth
                                type="number"
                                onChange={(e) => handleChangeInput(e)}
                              />
                            </Grid>
                          ) : (
                            ''
                          )}
                          {inputs.type === 'Car' ||
                          inputs.type === 'Motorcycle' ? (
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor="">Final Amount *</label>
                              <TextField
                                disabled
                                required
                                variant="outlined"
                                color="primary"
                                size="small"
                                value={inputs.finalAmount}
                                name="finalAmount"
                                fullWidth
                                type="number"
                                onChange={(e) => handleChangeInput(e)}
                              />
                            </Grid>
                          ) : (
                            ''
                          )}
                          {inputs.type === 'BUS/MRT/LRT' ||
                          inputs.type === 'Taxi' ? (
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor="">Receipt Amount($) *</label>
                              <TextField
                                disabled={!inputs.toFromHome}
                                type="number"
                                required
                                id="outlined-required"
                                color="primary"
                                value={inputs.receiptAmount}
                                name="receiptAmount"
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={(e) =>
                                  handleChangeInput(e, null, 'number')
                                }
                              />
                            </Grid>
                          ) : (
                            ''
                          )}
                          {inputs.type === 'BUS/MRT/LRT' ||
                          inputs.type === 'Taxi' ? (
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor="">Final Amount($) *</label>
                              <TextField
                                required
                                type="number"
                                id="outlined-required"
                                color="primary"
                                value={inputs.totalAmt}
                                name="totalAmt"
                                variant="outlined"
                                size="small"
                                fullWidth
                                disabled
                                onChange={(e) => handleChangeInput(e)}
                              />
                            </Grid>
                          ) : (
                            ''
                          )}

                          <Grid item xs={12} lg={4} md={4} sm={4}>
                            <Grid item xs={12}>
                              <label htmlFor="icon-button-file">
                                Attachment
                                {inputs.type === 'Taxi' && '*'}
                              </label>
                              {!uploading ?
                              <input
                                multiple
                                accept="image/pdf*"
                                type="file"
                                onChange={(e) =>
                                  onFileChange(e, inputs.claimReferenceNumber)
                                }
                              />
                              :
                              <p style={{paddingLeft:'25px'}}>
                              <CircularProgress color="primary" size={20} />
                            </p>
                              }
                            </Grid>
                            {claimsData.attachment &&
                              claimsData.attachment.map((each, i) => {
                                return (
                                  <div key={i} className="file-path-wrap">
                                    {/* <h6>{each.split('/')[4]}</h6> */}
                                    <h6>{inputs.claimReferenceNumber}_{i+1}</h6>
                                    <IconButton
                                      onClick={() => removeAttachement(i)}
                                      disableTouchRipple
                                    >
                                      <CancelRoundedIcon />
                                    </IconButton>
                                  </div>
                                );
                              })}
                          </Grid>

                          <Grid xs={4}></Grid>
                        </Grid>
                      </Grid>
                    </CardUI>
                  </Grid>
                </Box>
                <Grid style={{ position: 'relative' }}>
                  <Footer
                    type={type}
                    fromEdit={true}
                    disabledSubmit={formSubmitted || uploading}
                    onclick={() => submitData('PENDING')}
                    onSaveDraft={() => submitData('DRAFT')}
                    buttonClicked = {buttonClicked}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Modal
        show={isBackdropOpen}
        onCancel={closeBackdropHandler}
        header={<h1>Success</h1>}
        contentClass="modal-content"
        footerClass="footer-content"
        submitButton={
          <ButtonUI
            backgroundColor="#00AC94"
            color="#ffffff"
            size="small"
            padding="3px 20px"
            onclick={closeBackdropHandler}
          >
            Ok
          </ButtonUI>
        }
      >
        <h4>{successMessage}</h4>
      </Modal>
    </React.Fragment>
  );
};
export default TransportClaim;
