import React from 'react';
import TimePickerComponent from '../../components/TimePicker/TimePicker';
import { Box, FormHelperText, Typography } from '@mui/material';

export default function TimePicker({ label, required, handleChange, dependent_on = null, format = '', value, all_hours = false, error, helperText = '', ...rest }) {

  return (
    <>
      <Typography variant="body2" color={'neutral.dark80'} mb={1}>{label} {required && <Box color="accentSecondary.main" sx={{ display: 'inline-block' }}>*</Box>}</Typography>
      <TimePickerComponent
        showSecond={false}
        value={value}
        onChange={handleChange}
        format={format}
        use12Hours
        inputReadOnly
        required={required}
        selectedDate={dependent_on}
        all_hours={all_hours}
        error={error}
        className={error ? 'error-input' : 'xxx'}
        {...rest}
      />
      {error && <FormHelperText className='text-danger'>{helperText}</FormHelperText>}
    </>
  );
}
