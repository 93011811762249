import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import CurrencyFormatter from '../../../../../atoms/CurrencyFormater/CurrencyFormater';

const BasePayroll = (props) => {
  const { base, stdHrs, currencySymbol } = props;
  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={8} pr={8}>
        <Grid item md={6} xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle2">
              A. {base?.name}
              <br />
              {base?.code === 'HRL'
                ? `(${currencySymbol} ${base?.value} X ${stdHrs} Hrs)`
                : ''}
            </Typography>
            <Typography variant="subtitle2">
              <CurrencyFormatter
                value={
                  base?.code === 'HRL' ? Number(base?.value) * Number(stdHrs) : Number(base?.value)
                }
              />
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ padding: 1 }} />
    </>
  );
};

export default BasePayroll;
