import { Box, Typography, Button, Link, Divider } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import CompanyInfo from './CompanyInfo';
import { citiesAndCountriesSample, getDropdownSelectedValue } from './utils/onboarding.util';
import { publishCompanyInfo } from '../../../redux/onboarding/action';
import { companyInfo as companyInfoConst } from './constants/onboarding.constants';
import ActionHeader from './ActionHeader/ActionHeader';
import moment from 'moment';
import { DateFormatters } from '../../../constants/dateConstants';


export default function CompanyInfoView(props) {

  const { companyInfoEdit, setCompanyInfoEdit} = props;

  const {companyInfo, permissions} = useSelector((state) => state.onBoarding);
  const { currentUserRoleId, fileData } = useSelector((state) => state.main);

  const {  companyName, companyLogo, companyDescription, companyEmail, companyWebsite, callingCode,
          phoneNumber, altPhoneNumber, altCallingCode, address,  addressCity,  addressState, addressCountry,
          addressPostalCode, billingAddress, billingAddressCity,  billingAddressState, billingAddressCountry,
          billingAddressPostalCode, gstNumber, id , isPublished, establishedOn} = companyInfo;

  const dispatch = useDispatch();

  return (
    <Box>
    <>
    <Box mb={3} mx={-3} mt={-3}>
      <ActionHeader 
        labelText='Entity' 
        onEditClick={() => setCompanyInfoEdit(false)} 
        showPublish={!isPublished}
        editButtonText='Edit'
        onPublishClick={() => dispatch(publishCompanyInfo(currentUserRoleId))}
        showDiscard={false}
        disabledSave={!permissions[companyInfoConst]?.allowEdit}
        showSave={permissions[companyInfoConst]?.allowEdit}
      />
    </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container rowSpacing={3} columnSpacing={8} pr={8}>
          <Grid item md={6}>
            <Typography variant="body2" color={'neutral.dark60'}>
              Company Name
            </Typography>
            <Typography variant="subtitle2" color={'neutral.dark80'} mt={1}>
              {companyName}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="body2" color={'neutral.dark60'} mb={1}>
              Company logo
            </Typography>
            <Box  p={1} display='inline-block' >
            {companyLogo?.url?<img src={companyLogo?.url} height={150} />:null}
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="body2" color={'neutral.dark80'} mb={1}>
              Company Description
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Typography variant="body2" color={'neutral.dark100'} mb={1}>
              {companyDescription}
            </Typography>
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography variant="body2" color={'neutral.dark80'} mb={1}>
              Contact details
            </Typography>
            <Divider sx={{ mb: 1 }} />
            
          </Grid>
          <Grid item md={6}>
              <Typography variant="body2" color={'neutral.dark60'}>
                Company email<Box color="accentSecondary.main" sx={{display: 'inline-block'}}>*</Box>
              </Typography>
              <Typography variant="body2" color={'neutral.dark100'} mt={1}>
                {companyEmail}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="body2" color={'neutral.dark60'}>
                Established on
              </Typography>
              <Typography variant="body2" color={'neutral.dark100'} mt={1}>
                {(moment(establishedOn, 'YYYY-MM-DD', true).isValid())?moment(establishedOn, 'YYYY-MM-DD').format(DateFormatters.ddmmyyyy):establishedOn}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="body2" color={'neutral.dark60'}>
                Phone number<Box color="accentSecondary.main" sx={{display: 'inline-block'}}>*</Box>
              </Typography>
              <Typography variant="body2" color={'neutral.dark100'} mt={1}>
              {phoneNumber}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="body2" color={'neutral.dark60'}>
                Alternate phone number
              </Typography>
              <Typography variant="body2" color={'neutral.dark100'} mt={1}>
                {altPhoneNumber}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="body2" color={'neutral.dark60'}>
                Address<Box color="accentSecondary.main" sx={{display: 'inline-block'}}>*</Box>
              </Typography>
              <Typography variant="body2" color={'neutral.dark100'} mt={1}>
                {`${address || ''} 
                ${ addressCity?.cityName || ''} 
                ${addressState?.stateName || ''} 
                ${addressCountry?.countryName || ''} 
                ${addressPostalCode || ''}`}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="body2" color={'neutral.dark60'}>
                Billing Address
              </Typography>
              <Typography variant="body2" color={'neutral.dark100'} mt={1}>
                {`${billingAddress || ''} 
                ${billingAddressCity?.cityName || ''} 
                ${billingAddressState?.stateName || ''} 
                ${billingAddressCountry?.countryName || ''} 
                ${billingAddressPostalCode || ''}`}
              </Typography>
            </Grid>
            <Grid item md={6}></Grid>
        </Grid>
      </Box>
      </>
  </Box>
    
  );
}
