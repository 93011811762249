import { Box, Typography } from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import ActionHeader from '../../../../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import FormView from '../../../../../../OnBoarding/common/Form/FormView';
import { AllCandidate, CandidateDetails, CandidateFormFieldNames, NewCandidate } from '../../../../../Constant/constant';
import { permissionsObj } from '../../../../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';

export default function ViewCandidate(props) {
  const { setViewMode, candidateConfig } = props;
  const { candidateFormData
  } = useSelector((state) => state.candidate);
  const { recrutingPermissions } = useSelector((state) => state.modulePermissions);


  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            labelText={candidateFormData?.[CandidateFormFieldNames.Name] || NewCandidate}
            editButtonText='Edit'
            onEditClick={() => setViewMode(false)}
            labelCode={candidateFormData?.[CandidateFormFieldNames.Code] ? candidateFormData?.[CandidateFormFieldNames.Code] : ''}
            showSave= { recrutingPermissions?.[CandidateDetails]?.[permissionsObj.allowEdit] || recrutingPermissions?.[AllCandidate]?.[permissionsObj.allowEdit]}
          />
        </Box>
        <Grid container rowSpacing={3} columnSpacing={3}>
          <Grid item md={7}>
            <FormView
              list={candidateConfig}
              info={candidateFormData}
            />
          </Grid>
          <Grid item md={5}>
            <Box
              ml={6}
              pl={6}
              sx={{ borderLeft: 'solid 1px', borderColor: 'neutral.dark40' }}
              mb={3}
            >
              {/* <Typography variant='subtitle2' color={'neutral.dark80'} mb={3}>
                Audit Trail
              </Typography>
              <Box>
                <EventCard />
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

