import { Grid } from '@mui/material';
import React from 'react';
import ComponentHeader from './ComponentHeader';

const Currency = (props) => {
  const { currencySymbol = 'S$' } = props;
  return (
    <Grid container rowSpacing={3} columnSpacing={8} pr={8} pb={2}>
      <Grid item md={6} xs={12}>
        <ComponentHeader total={`Amount ${currencySymbol}`} />
      </Grid>
      <Grid item md={6} xs={12}>
        <ComponentHeader total={`Amount ${currencySymbol}`} />
      </Grid>
    </Grid>
  );
};

export default Currency;
