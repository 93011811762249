import { Box, Button, FormControl, Grid, Paper, Typography } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import CardUI from '../../shared/components/ui-elements/CardUI';
import { FIELD_TYPES } from '../../constants/constants';
import Footer from '../footer/Footer';
import Joi, { number } from 'joi';
import InputField from '../../atoms/Input/Input';
import FormDate from '../../atoms/Date/Date';
import TimePicker from '../../atoms/time/timepicker';
import FilesUploader from '../../atoms/FileUpload/FilesUploader';
import FormSelect from '../../atoms/Select/Select';
import { postCreateFormData, postEditFormData } from '../forms/common-services/services';
import moment from 'moment';
import { useParams, useSearchParams } from 'react-router-dom';
import { ClaimServices } from '../../services/claims';
import ClaimedEmployeeDetails from '../claim-view/claimed-employee-details';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import ModalDialog from '../../atoms/Modal/ModalDialog';
import Loading from '../../atoms/Loading/loading';
import { CLAIM_STATUS } from '../claim-view/claim-audit-log';
import { useSelector } from 'react-redux';
import { FORM_STATE } from '../claims/constants/claimsConstants';

const DEFAULT_VALUES = {
  [FIELD_TYPES.NUMBER]: null,
  [FIELD_TYPES.BOOLEAN]: null,
  [FIELD_TYPES.TEXT]: '',
  [FIELD_TYPES.DATE]: null,
  [FIELD_TYPES.TIME]: null,
  [FIELD_TYPES.FILES]: [],
  [FIELD_TYPES.SELECT]: null,
};
// eslint-disable-next-line react/display-name
const FormContainer = memo((props) => (
  <Grid item xs={12} lg={4} md={4} sm={4}>
    <FormControl fullWidth>{props.children}</FormControl>
  </Grid>
));

function DynamicEditClaim() {
  const { claimId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const claimTypeId = searchParams.get('ctId');
  const { currentUserRoleId } = useSelector((state) => state.main);
  const [inputs, setInputs] = useState([{ claimType: 1 }]);
  const [initialSetupDone, setInitialSetupDone] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formSchema, setFormSchema] = useState({});
  const [claimDetails, setClaimDetails] = useState({});
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentEntitlement, setCurrentEntitlement] = useState({});
  const [firstUpdateQue, setFirstUpdateQue] = useState([]);
  const [currencyExchangeRate, setCurrencyExchangeRate] = useState([]);

  const claimService = new ClaimServices();

  const resetFormFields = () => {
    const fields = {};
    formSchema.form_meta.forEach((field) => {
      if (field.type !== FIELD_TYPES.TIME) {
        fields[field.name] = DEFAULT_VALUES[field.type];
      }
      // if(field.type === FIELD_TYPES.FILES){
      //     fields[field.name]= ['https://vepeopleplus.blob.core.windows.net/dev-vexecution/83281697-5946-4af7-a4a8-f3f28636eb34.png'];
      // }
    });
    return fields;
  };
  const getFieldIsRequiredOrNot = (input, field) => {

    let disabled = field.disabled || false;
    let required = field.is_required;
    if (field.dependent_on) {
      const parent = field.dependent_on;
      const parentValue = input[parent];
      const matchParentValue = field.renderOnParentValueEqual;
      if (matchParentValue && !matchParentValue.includes(parentValue)) {
        if (field.is_visble) {
          disabled = true;
        } else {
          return <span></span>;
        }
      }
    }
    if (field.required_on) {
      required = false;
      field.required_on?.forEach(condition => {
        const key = condition.key;
        const listValue = condition.values;

        const dependency_value = input[key];
        if (listValue.includes(dependency_value)) {
          required = true;
          disabled = false;
        }
      });
      if (field.disableOnNotRequired && !required) {
        disabled = true;
      }
    }
    return { required, disabled };
  };
  const validatorFn = {
    [FIELD_TYPES.NUMBER]: (value, field, helperText) => {
      const { required, disabled } = getFieldIsRequiredOrNot(value, field);
      if (disabled ? false : required) {
        const min = field.min || null;
        const max = field.max || null;
        if (value === null || value === undefined)
          return helperText.error(`${field.label} is required`);
        if ((min || min === 0) && value < min) {
          return helperText.error(
            `${field.label} must have at least ${min} charactors`
          );
        } else if (max !== null && value > max) {
          return helperText.error(
            `${field.label} can't be greater than ${max}`
          );
        }
      }
      return value;
    },
    [FIELD_TYPES.BOOLEAN]: (value, field, helperText) => {
      const { required, disabled } = getFieldIsRequiredOrNot(value, field);
      if (disabled ? false : required) {
        const min = field.min || null;
        const max = field.max || null;
        if (value === undefined || value === null)
          return helperText.error(`${field.label} is required`);
        if (value != false && value != true) {
          helperText.message(`Please select valid value for ${field.label}`);
          return helperText.error('any.invalid');
        }
      }
      return value;
    },
    [FIELD_TYPES.TEXT]: (value, field, helperText) => {
      const { required, disabled } = getFieldIsRequiredOrNot(value, field);
      let fn = Joi.string().label(field.label);
      if (disabled ? false : required) {
          if (field.min) fn = fn.min(field.min);
          if (field.max) fn = fn.max(field.max);
          fn = fn.required();
      }
      return value;
    },
    [FIELD_TYPES.SELECT]: (value, field, helperText) => {
      return validatorFn[field.data_type]?.(
        value,
        { ...field, type: field.data_type },
        helperText
      );
      // if (field.is_required) {
      //     if (!value) return helperText.error(`${field.label} is required`);

      //     if (field?.options?.includes(value)) {
      //         return helperText.error(`Please select the valid value for ${field.label}`);
      //     }
      // }
      // return value;
    },
    [FIELD_TYPES.DATE]: (value, field, helperText) => {
      const { required, disabled } = getFieldIsRequiredOrNot(value, field);
      if (disabled ? false : required) {
        if (value === null || value === undefined || !value) {
          return helperText.error(`${field.label} is required`);
        }
        const min = field.min || null;
        const max = field.max || null;
        if (min && new Date(value) < new Date(min)) {
          return helperText.error(
            `${field.label} must have at least ${min} charactors`
          );
        } else if (max && new Date(value) < new Date(max)) {
          return helperText.error(
            `${field.label} can't be greater than ${max}`
          );
        }
      }
      return value;
    },
    [FIELD_TYPES.TIME]: (value, field, helperText) => {
      const { required, disabled } = getFieldIsRequiredOrNot(value, field);
      if (disabled ? false : required) {
        if (value === null || value === undefined || !value) {
          return helperText.error(`${field.label} is required`);
        }
        const min = field.min || null;
        const max = field.max || null;
        if (min && new Date(value) < new Date(min)) {
          return helperText.error(
            `${field.label} must have at least ${min} charactors`
          );
        } else if (max && new Date(value) < new Date(max)) {
          return helperText.error(
            `${field.label} can't be greater than ${max}`
          );
        }
      }
      return value;
    },
    [FIELD_TYPES.FILES]: (value, field, helperText) => {
      const { required, disabled } = getFieldIsRequiredOrNot(value, field);
      if (disabled ? false : required) {
        if (!value || !value?.length)
          return helperText.error(`${field.label} is required`);
        if (field.max_files && field.max_files < value?.length) {
          return helperText.error(
            `Number of ${field.label} can't exceed the limit`
          );
        }
        if (field?.options?.includes(value)) {
          return helperText.error(
            `Please select the valid value for ${field.label}`
          );
        }
      }
      return value;
    },
  };
  const wrapParentCondition = (value, field, helperText, formIndex) => {
    let validation = null;
    if (field.dependent_on) {
      const parentValue = inputs[formIndex][field.dependent_on];
      const conditionValue = field.renderOnParentValueEqual;
      if (conditionValue && conditionValue.includes(parentValue)) {
        validation =
          validatorFn[field.type] &&
          validatorFn[field.type](value, field, helperText);
        // return helperText.error(`Please enter valid value for ${field.label}`);
      }
    } else {
      validation =
        validatorFn[field.type] &&
        validatorFn[field.type](value, field, helperText);
    }
    return validation;
  };
  const customValidator = (field, formIndex) => {
    return (value, helperText) => {
      return wrapParentCondition(value, field, helperText, formIndex);
    };
  };
  const getValidatorForField = (field, formIndex) => {
    const JoiValidators = {
      [FIELD_TYPES.NUMBER]: Joi.number()
        .label(field.label)
        .custom(customValidator(field, formIndex)),
      [FIELD_TYPES.BOOLEAN]: Joi.boolean()
        .label(field.label)
        .custom(customValidator(field, formIndex)),
      [FIELD_TYPES.TEXT]: Joi.string()
        .label(field.label)
        .custom(customValidator(field, formIndex)),
      [FIELD_TYPES.DATE]: Joi.date()
        .label(field.label)
        .custom(customValidator(field, formIndex)),
      [FIELD_TYPES.TIME]: Joi.date()
        .label(field.label)
        .custom(customValidator(field, formIndex)),
      [FIELD_TYPES.FILES]: Joi.array()
        .label(field.label)
        .default([])
        .custom(customValidator(field, formIndex)),
    };
    switch (field.type) {
      case FIELD_TYPES.NUMBER:
        return JoiValidators[field.type];
      case FIELD_TYPES.BOOLEAN:
        return JoiValidators[field.type];
      case FIELD_TYPES.TEXT:
        return JoiValidators[field.type];
      case FIELD_TYPES.DATE:
        return JoiValidators[field.type];
      case FIELD_TYPES.TIME:
        return JoiValidators[field.type];
      case FIELD_TYPES.FILES:
        return JoiValidators[field.type];
      case FIELD_TYPES.SELECT:
        return JoiValidators[field.data_type];
    }
  };
  const handleSubmit = async (status = 'PENDING') => {
    const errors = [];
    const values = [{}];
    setIsSubmitted(true);
    setLoading(true);
    // generate validation schema.
    const validationSchema = inputs.map((inpt, i) => {
      const validator = {};
      let input = {};
      if (values[i]) {
        input = values[i];
      }
      formSchema.form_meta.forEach((field) => {
        if (field.dependent_on) {
          const renderOnParentValueEqual = inputs[i][field.dependent_on];
          if (field.renderOnParentValueEqual) {
            if (
              field.renderOnParentValueEqual.includes(renderOnParentValueEqual)
            ) {
              validator[field.name] = getValidatorForField(field, i);
              input[field.name] = inputs[i][field.name];
            }
          } else {
            validator[field.name] = getValidatorForField(field, i);
            input[field.name] = inputs[i][field.name];
          }
        } else {
          validator[field.name] = getValidatorForField(field, i);
          input[field.name] = inputs[i][field.name];
        }
      });
      values[i] = input;
      console.log(validator);
      return validator;
      // (
      //   formSchema.form_meta.reduce((previous={},field)=>(
      //   previous[field.name]= getValidatorForField(field,i)
      //   ),{})
      // )
    });
    console.log(validationSchema);
    validationSchema.forEach((formValidationSchema, i) => {
      const { value, error } = Joi.object({ ...formValidationSchema }).validate(
        values[i],
        { abortEarly: false }
      );
      const err = {};
      if (error) {
        error?.details?.forEach((item) => {
          err[item.context.key] = item.message;
        });

        errors.push(err);
      }
    });
    setErrorMessage(errors);
    console.log(errors);
    if (!errors.length) {
      // submit the form.
      const claimType = claimDetails?.claimType?.id;
      const formDatas = values.map((claim) => ({
        ...claim,
        claimType,
        status,
        id: claimDetails.id
      }));
      const finalData = await claimService.postEditFormData(formDatas[0], currentUserRoleId);
      if (finalData === 201 || finalData === 200) {
        let successMSG = '';
        if (status === 'PENDING') {
          successMSG = 'Claim request submitted successfully !';
        } else {
          successMSG = 'Draft saved successfully !';
        }
        setIsSubmitted(true);
        setSuccessMessage(() => successMSG);
      }
    } else {
      setIsSubmitted(false);
    }
    setLoading(false);
  };
  const getSelectValue = (type, val) => {
    let value = '';
    switch (type) {
      case FIELD_TYPES.NUMBER:
        value = +val;
        break;
      case FIELD_TYPES.TEXT:
        value = String(val).toString();
        break;
      case FIELD_TYPES.BOOLEAN:
        value = Boolean(val);
        break;
      default:
        value = val ? val : '';
    }
    return value;
  };
  const renderFields = (field, formIndex, inputIndex) => {
    let disabled = field.disabled || false;
    if (field.dependent_on) {
      const parent = field.dependent_on;
      const parentValue = inputs[formIndex][parent];
      const matchParentValue = field.renderOnParentValueEqual;
      if (matchParentValue && !matchParentValue.includes(parentValue)) {
        if (field.is_visble) {
          disabled = true;
        } else {
          return <span key={inputIndex}></span>;
        }
      }
    }
    switch (field.type) {
      case FIELD_TYPES.NUMBER:
        return (
          <FormContainer key={inputIndex}>
            <InputField
              name={field.name}
              labelText={field.label}
              onChange={(e) => handleChangeInput(formIndex, e, field)}
              required={disabled ? false : field.is_required}
              value={inputs[formIndex][field.name]}
              error={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
                  ? true
                  : false
              }
              onBlur={(event) => handleInputBlur(field, event, formIndex)}
              disabled={disabled}
              helperText={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
              }
            />
          </FormContainer>
        );

      case FIELD_TYPES.TEXT:
        return (
          <FormContainer key={inputIndex}>
            <InputField
              name={field.name}
              labelText={field.label}
              onChange={(e) => handleChangeInput(formIndex, e, field)}
              required={disabled ? false : field.is_required}
              value={inputs[formIndex][field.name]}
              error={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
                  ? true
                  : false
              }
              helperText={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
              }
              disabled={disabled}
            />
          </FormContainer>
        );

      case FIELD_TYPES.SELECT:
        return (
          <FormContainer key={inputIndex}>
            <FormSelect
              name={field.name}
              options={field.options}
              labelId={field.name}
              label={field.label}
              disabled={disabled}
              handleChange={(e) => handleChangeInput(formIndex, e, field)}
              required={field.is_required}
              value={getSelectValue(
                field.data_type,
                inputs[formIndex][field.name]
              )}
              error={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
                  ? true
                  : false
              }
              helperText={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
              }
            />
          </FormContainer>
        );
      case FIELD_TYPES.DATE:
        return (
          <FormContainer key={inputIndex}>
            <FormDate
              name={field.name}
              labelId={field.name}
              label={field.label}
              handleChange={(e) => {
                handleChangeInput(
                  formIndex,
                  {
                    target: {
                      name: field.name,
                      value: moment(e).format().split('T')[0],
                    },
                  },
                  field
                );
              }}
              disabled={disabled}
              value={
                inputs[formIndex][field.name] &&
                moment(inputs[formIndex][field.name])
              }
              required={field.is_required}
              error={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
                  ? true
                  : false
              }
              helperText={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
              }
            />
          </FormContainer>
        );
      case FIELD_TYPES.TIME:
        return (
          <FormContainer key={inputIndex}>
            <TimePicker
              name={field.name}
              required={field.is_required}
              dependent_on={inputs[formIndex][field.dependent_on_value]}
              label={field.label}
              value={
                inputs[formIndex][field.name]
                  ? moment(inputs[formIndex][field.name])
                  : inputs[formIndex][field.name]
              }
              handleChange={(e) =>
                handleChangeInput(
                  formIndex,
                  { target: { name: field.name, value: moment(e).toDate() } },
                  field
                )
              }
              disabled={disabled}
              error={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
                  ? true
                  : false
              }
              helperText={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
              }
            />
          </FormContainer>
        );
      case FIELD_TYPES.FILES:
        return (
          <FormContainer key={inputIndex}>
            <FilesUploader
              name={field.name}
              label={field.label}
              required={field.is_required}
              value={inputs[formIndex][field.name]}
              disabled={disabled}
              callback={(values) =>
                handleChangeInput(
                  formIndex,
                  { target: { name: field.name, value: values } },
                  field
                )
              }
              error={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
                  ? true
                  : false
              }
              helperText={
                errorMessage[formIndex] && errorMessage[formIndex][field.name]
              }
            />
          </FormContainer>
        );
    }
  };

  const formTrigger = (formIndex, input, field, formInputsValue) => {
    // Write Trigger
    let updatedValue = formInputsValue;
    formSchema.trigger?.forEach((trigger) => {
      const fireTrigger = trigger.fields.includes(field.name);
      if (fireTrigger && trigger.fn) {
        const evelFn = eval(`(${trigger.fn})`);
        updatedValue = evelFn(updatedValue, {
          moment: moment,
          resetFormFields,
          currencyExchangeRate
        });
      }
    });
    return updatedValue;
  };
  const handleInputBlur = (field, event, formIndex) => {
    if ('dataInputFormat'in field){
        const val = event.target.value;
        const name = event.target.name;
        const preinputs = [...inputs];
        preinputs[formIndex][name] = parseFloat(val).toFixed(field.dataInputFormat);
        setInputs(preinputs);
    }
  };
  const handleChangeInput = (formIndex, event, field) => {
    console.log('event----', event);
    const preinputs = [...inputs];
    const input = inputs[formIndex];

    const name = event.target.name;
    const value = event.target.value;

    input[name] = value;
    preinputs[formIndex] = input;
    console.log('value', value);
    // setInputs(preinputs);
    const schema = getValidatorForField(field, formIndex);
    const { error } = schema.validate(value);
    const errorsM = [...errorMessage];
    if (error) {
      errorsM[formIndex] = {
        ...errorsM[formIndex],
        [field.name]: error.message,
      };
      setErrorMessage(errorsM);
    } else {
      if (errorsM[formIndex] && errorsM[formIndex][field.name]) {
        delete errorsM[formIndex][field.name];
        setErrorMessage(errorsM);
      }
    }

    // Trigger call here.
    preinputs[formIndex] = formTrigger(
      formIndex,
      input,
      field,
      preinputs[formIndex]
    );
    setInputs(preinputs);
  };

  // Value parser;
  const valueParser = (value, field) => {
    switch (field.type) {
      case FIELD_TYPES.NUMBER: return Number(value || 0);
      case FIELD_TYPES.BOOLEAN: return Boolean(value);
      case FIELD_TYPES.TEXT: return value ? String(value) : '';
      case FIELD_TYPES.DATE: return value;
      case FIELD_TYPES.TIME: return value;
      case FIELD_TYPES.FILES: return [...value];
      case FIELD_TYPES.SELECT: return valueParser(value, { type: field.data_type });
      default: return value;
    }
  };

  // Get Details of the claim.

  const GetFormConfigWithClaims = async () => {
    claimService.getCurrencyExchangeRate().then(res => {
      setCurrencyExchangeRate(() => res.data?.data);
    }).catch(error => {
      console.log(error?.response?.data || error.message);
    });
    setInputs(() => [{}]);
    setLoading(true);
    const res = await claimService.getClaimDetails(claimId, claimTypeId, currentUserRoleId,FORM_STATE.EDIT);
    if (res.data?.data) {
      const data = res.data.data;
      if (data.status !== CLAIM_STATUS.RE_SUBMIT && data.status !== CLAIM_STATUS.DRAFT) {
        navigate(`/claims/edit/${data.id}?claimTypeId=${claimTypeId}`);
      }
      if (data?.other) {
        data?.other?.forEach((otherItem) => {
          data[`other__${otherItem.key}`] = otherItem.value;
        });
      }
      setEmployeeDetails(data.employee || {});
      // get schema by claimType
      const schemaReq = await claimService.getClaimConfigurationSchema(
        data.claimType.id
      );
      const schemaData = schemaReq?.data?.data || null;
      if (schemaData) {
        setFormSchema(() => schemaData);
        setClaimDetails(() => data);

        const inputData = {};
        // const inputData = resetFormFields();
        const updateQue = [];
        schemaData.form_meta.forEach((field) => {
          inputData[field.name] = valueParser(data[field.name], field);
        });

        setInputs(() => [inputData]);
        setFormSchema(() => schemaData);
        setInitialSetupDone(true);
      }
    }


    setLoading(false);
  };

  // setTimeout(()=>{
  //   console.log('set time out called');
  //     setFormSchema((v)=>v);
  // },[2000]);

  // useEffect(()=>{
  //   if(firstUpdateQue.length){
  //     const [input,...rest] = firstUpdateQue;
  //     handleChangeInput(input.fieldIndex,input.event, input.field);
  //     setFirstUpdateQue([...rest]);
  //   }
  // },[firstUpdateQue]);
  // handle Delete.
  const handleDelete = async () => {
    navigate(-1);
  };

  // Effects
  useEffect(() => {
    if (!initialSetupDone) {
      // const fields = resetFormFields();
      // setInitialSetupDone(true);
      // setInputs(() => [fields]);
      GetFormConfigWithClaims();
      setCurrentEntitlement(formSchema.entitlements ? formSchema.entitlements[0] : {});
    }
  }, [formSchema]);

  return (
    <>
      <Loading loading={loading}>
        {(
          <>
            <Grid
              container
              justifyContent={'center'}
              style={{ marginBottom: '100px' }}
            >
              <Grid xs={11}>
                <ClaimedEmployeeDetails employee={employeeDetails} />
                <Box>
                  <CardUI className="form-heading-wrap">
                    <Grid
                      xs={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Grid xs={6}>
                        <h4>{formSchema.name}</h4>
                      </Grid>
                    </Grid>
                  </CardUI>
                </Box>
                <Paper>
                  {formSchema.has_entitlement && (
                    <Box p={3}>
                      <Grid container spacing={1} >
                        <Grid item xs={3}>
                          <Typography variant='body1' m={1}> <b>Start Date</b> </Typography>
                          <Typography variant='body2' mx={1}> {moment(currentEntitlement.start_date).format('YYYY-MM-DD')} </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant='body1' m={1}> <b>End Date</b> </Typography>
                          <Typography variant='body2' mx={1}> {moment(currentEntitlement.end_date).format('YYYY-MM-DD')} </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant='body1' m={1}> <b>Entitlement</b> </Typography>
                          <Typography variant='body2' mx={1}> $ {currentEntitlement.entitlement_amt} </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant='body1' m={1}> <b>Balance</b> </Typography>
                          <Typography variant='body2' mx={1}>$ {currentEntitlement.entitlement_balance_amt} </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  <Box p={3}>
                    <Grid container spacing={1}>
                      {formSchema.form_meta?.map((feild, inputIndex) =>
                        renderFields(feild, 0, inputIndex)
                      )}
                    </Grid>
                  </Box>
                </Paper>
                {/* <Footer
                    type={'Expenses'}
                    disabledSubmit={isSubmitted}
                    onclick={() => handleSubmit()}
                    onSaveDraft={() => { handleSubmit('DRAFT'); } }
                    buttonClicked={() => { } } /> */}

                <Paper sx={{ p: 2, mt: 2, mb: 4 }}>
                  <Box display="flex" gap="10px" justifyContent="flex-end">
                    {
                      <>
                        <Button
                          size="medium"
                          sx={{
                            bgcolor: 'neutral.light80',
                            color: 'neutral.dark80',
                            px: 2,
                            textTransform: 'capitalize',
                          }}
                          disabled={loading}
                          onClick={() => navigate(ROUTES.CLAIMS)}
                        >
                          Back
                        </Button>
                        <Button disabled={loading} variant="contained" size="medium" onClick={() => handleSubmit()}>
                          Submit
                        </Button>
                      </>
                    }
                  </Box>
                </Paper>
              </Grid>
              <ModalDialog showModal={isSubmitted} primaryAction={'Close'} onClickClose={() => navigate(ROUTES.CLAIMS)} onClickConfirm={() => navigate(ROUTES.CLAIMS)} title={successMessage ? 'Success' : 'Error'} description={successMessage ? successMessage : errorMessage} />
            </Grid>
          </>
        )}
      </Loading>
    </>
  );
}

export default memo(DynamicEditClaim);
