import React, { useState } from 'react';
import { DateRangeProFields } from './DateRangePicker';
import { Box } from '@mui/material';
import { FilterAltOutlined } from '@mui/icons-material';
import './DateRange.css';

export const DateRangePro = (props) => {
  const { selectedDate, setSelectedDate, mt='6px' } = props;
  const [showRangeFields, setshowRangeFields] = useState(true);

  const handleClick = () => {
    setshowRangeFields(!showRangeFields);
  };
  return (
    <>
      <div className={`ve-date-range-cn ${showRangeFields ? 'open' : ''}`}>
        <DateRangeProFields
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate} />
      </div>
      <Box>
        <FilterAltOutlined
          onClick={handleClick}
          sx={{ color: 'neutral.dark80', cursor: 'pointer', mt: mt }}
        />
      </Box>
    </>
  );
};

