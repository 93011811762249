import React from 'react';
import { Box } from '@mui/material';
import TabBar from '../../atoms/Tabs/TabBar';
import AllGoals from './Dashboard/GoalsDashboard/AllGoals';
import { useNavigate } from 'react-router-dom';
import AllAppraisalList from './Dashboard/AppraisalDashboard/AllAppraisalList';

export default function ManagePerformanceList(props) {
  const {currentTab=0} = props;
  const navigate = useNavigate();


  const dataTabs = [
    { 
      name: 'goals',
      label: 'Goals',
      title: 'Goals',
      hide: false,
      panel: <Box mx={-3}><AllGoals/> </Box>,
    },
    {
      name: 'appraisal',
      label: 'Appraisal',
      title: 'Appraisal',
      hide: false,
      panel: <Box mx={-3}><AllAppraisalList /></Box>,
    },
];

const handleChange = (tabIndex) => {
  navigate(`/performance/${dataTabs[tabIndex].name}`);
};
const onSearchChange = (e,tabIndex) => {
  navigate(`/performance/${tabIndex.name}`);
};

  return (
    <Box>
      <TabBar
        tabPanels={dataTabs}
        searchTabs
        disableCreate
        currentTab={currentTab}
        onTabChange={handleChange}
        onSearchChange={onSearchChange}
      />
    </Box>
  );
}
