import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Paper,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import InputField from '../../../../atoms/Input/Input';
import { ClearOutlined, LibraryBooksOutlined } from '@mui/icons-material';
import FieldPicker from '../conditional-expression/expression-accordian/expression-editor/field-picker.component';
import SelectTag from '../../../../atoms/Select/SelectTag';
import { operatorTypesForNumber } from '../../utils/conditional.util';

const NumberValidation = (props) => {
  const { setFormInfo, range } = props;
  const [form, setForm] = useState(range || {});
  const [showMinFields, setshowMinFields] = useState(false);
  const [showMaxFields, setshowMaxFields] = useState(false);
  const [showInputField, setShowInputField] = useState({
    min: true,
    max: true,
  });

  useEffect(() => {
    if (range) {
      if (form?.min && isNaN(form?.min)) {
        setShowInputField((prev) => ({
          ...prev,
          min: false,
        }));
      }
      if (form?.max && isNaN(form?.max)) {
        setShowInputField((prev) => ({
          ...prev,
          max: false,
        }));
      }
    }
  }, [form]);

  useEffect(() => {
    setFormInfo((prev) => ({
      ...prev,
      range: form,
    }));
  }, [form]);

  const handleInputChange = (event) => {
    const key = event.target.name;
    const val = Number(event.target.value);
    setForm((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const handleDropDown = (event) => {
    const { value, name } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFieldClick = (field, key) => {
    const { fieldRefName } = field;
    setForm((prev) => ({
      ...prev,
      [key]: fieldRefName,
    }));
    setShowInputField((prev) => ({
      ...prev,
      [key]: false,
    }));
  };

  const handleClear = (key) => {
    setShowInputField((prev) => ({
      ...prev,
      [key]: true,
    }));
    setForm((prev) => ({
      ...prev,
      [key]: null,
    }));
  };

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={8}>
        <Grid item xs={12} md={4}>
          <SelectTag
            labelText="Type"
            name="minType"
            dropDownList={operatorTypesForNumber}
            size="small"
            fullWidth
            value={form?.minType}
            onchange={handleDropDown}
            placeholder="Select"
          />
        </Grid>
        <Grid item xs={12} md={5}>
          {showInputField?.min ? (
            <InputField
              labelText="Minimum"
              placeholder="Type Here"
              fullWidth
              size="small"
              onChange={handleInputChange}
              name="min"
              value={form?.min}
              type="number"
            />
          ) : (
            <>
              <Typography variant="body2" color={'neutral.dark80'} mb={1}>
                Minimum
              </Typography>
              <Box
                sx={{
                  border: '1px solid #D8D8D8',
                  p: 1,
                  borderRadius: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {form?.min}
                <IconButton onClick={() => handleClear('min')}>
                  <ClearOutlined sx={{ fontSize: 16 }} />
                </IconButton>
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={3} mt={3.5}>
          <IconButton onClick={() => setshowMinFields(!showMinFields)}>
            <LibraryBooksOutlined />
          </IconButton>
        </Grid>
        {showMinFields && (
          <Grid item xs={12} md={9}>
            <FieldPicker
              height="100px"
              handleClick={(item) => handleFieldClick(item, 'min')}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <SelectTag
            labelText="Type"
            name="maxType"
            dropDownList={operatorTypesForNumber}
            size="small"
            fullWidth
            value={form?.maxType}
            onchange={handleDropDown}
            placeholder="Select"
          />
        </Grid>
        <Grid item xs={12} md={5}>
          {showInputField?.max ? (
            <InputField
              labelText="Maximum"
              placeholder="Type Here"
              fullWidth
              size="small"
              onChange={handleInputChange}
              name="max"
              value={form?.max}
              type="number"
            />
          ) : (
            <>
              <Typography variant="body2" color={'neutral.dark80'} mb={1}>
                Maximum
              </Typography>
              <Box
                sx={{
                  border: '1px solid #D8D8D8',
                  p: 1,
                  borderRadius: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {form?.max}
                <IconButton onClick={() => handleClear('max')}>
                  <ClearOutlined sx={{ fontSize: 16 }} />
                </IconButton>
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={3} mt={3.5}>
          <IconButton onClick={() => setshowMaxFields(!showMaxFields)}>
            <LibraryBooksOutlined />
          </IconButton>
        </Grid>
        {showMaxFields && (
          <Grid item xs={12} md={9}>
            <FieldPicker
              height="100px"
              handleClick={(item) => handleFieldClick(item, 'max')}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default NumberValidation;
