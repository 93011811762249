export const DateFormatters = {
    ddmmyyyy: 'DD/MM/YYYY',
    ddmmyy: 'DD/MM/YY',
    yyyymmdd: 'YYYY/MM/DD',
    DateTime24Hour:'DD-MM-YYYY HH:mm',
    YYYYMMDD: 'YYYY-MM-DD',
    YYYYMMDD24Hr: 'YYYY-MM-DD HH:mm',
    ddmmyyyyhhmm:'DD/MM/YYYY HH:mm',
    ddmmyyyyhhmmutc: 'DD/MM/YYYY HH:mm [UTC]'

};