import API from './api';

export class CurrencyConversionService extends API {
  static baseURL =
    API.env.REACT_APP_BASE_API_LOCAL + '/currency-conversion/' || '';
  constructor() {
    super(CurrencyConversionService.baseURL);
  }

  getAllCurrencyConversionList = async (
    roleId,
    startMonth,
    startYear,
    endMonth,
    endYear
  ) =>
    this.responsify(
      await this.instance.get('list', {
        params: { roleId, startMonth, startYear, endMonth, endYear },
      })
    );

  getCurrencyConversionDetails = async (roleId, currencyId) =>
    this.responsify(
      await this.instance.get('details', { params: { roleId, currencyId } })
    );

  getCurrencyList = async (roleId) =>
    this.responsify(await this.instance.get('currency',{ params: { roleId} }));
  
  postCurrencyInfo = async (roleId,body) =>
    this.responsify(await this.instance.post('create-currency-info', {roleId,body}));

  updateCurrencyInfo = async (roleId,body,currencyId) =>
    this.responsify(await this.instance.post('udpate-currency-info', {roleId,body,currencyId}));
}
