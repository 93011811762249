import {
  Box,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import CardUI from '../../../shared/components/ui-elements/CardUI';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import Footer from '../../footer/Footer';
import PersonIcon from '@mui/icons-material/Person';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useLocation } from 'react-router-dom';
import Modal from '../../../shared/components/ui-elements/Modal';
import ButtonUI from '../../../shared/components/ui-elements/ButtonUI';
import { useNavigate } from 'react-router-dom';
import {
  generateCurrentTimeStamp,
  generateFormData,
  validateNumber,
} from '../common-services/functions';
import { postFilesData, postFormData } from '../common-services/services';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import UserProfile from '../../../shared/components/claim-forms/UserProfile';
import ReportingDetails from '../../../shared/components/claim-forms/ReportingDetails';
let mappedArr = new Map();
const CommissionClaim = () => {
  const uniqueNum = Math.floor(Math.random() * 10000000000) + 10000000000;
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [selectedFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showImgName, setShowImgName] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');

  const navigate = useNavigate();
  const [inputs, setInputs] = useState([
    {
      claimReferenceNumber: uniqueNum,
      nameOfClaimant: '',
      personnelNo: '',
      designation: '',
      department: '',
      claimsMonth: null,
      totalPaymentAmount: '',
      description: '',
      cardsApprove: '',
      commissionPayble: '',
    },
  ]);

  const params = useLocation();
  const [type] = useState(params.state.type);
  const [mutliplyNumber] = useState(9);

  const commissionPaybleOptions = ['Monthly', 'Quarterly', 'Yearly'];
  let userData = JSON.parse(localStorage.getItem('userData'));
  let asProfile = localStorage.getItem('asProfile');

  const handleChangeInput = (i, e, key = null, key1) => {
    const values = [...inputs];
    if (key) values[i][key] = e;
    else {
      if (key1) {
        const validate = validateNumber(e.target.name, e.target.value);
        if (!validate) {
          return false;
        }
      }
      values[i][e.target.name] = e.target.value;
    }
    setInputs(values);
  };

  // no of cards approve multiply logic start
  const mutliplyNoOfCardApprove = (i, value) => {
    const newArr = [...inputs];
    if (!value) {
      newArr[i].totalPaymentAmount = '';
    } else if (value && mutliplyNumber && value > 0) {
      let multiply = value * mutliplyNumber;
      newArr[i].totalPaymentAmount = multiply.toFixed(2);
    }
    setInputs(newArr);
  };
  // no of cards approve multiply logic end

  let addFormFields = (claimReferenceNumber) => {
    setInputs([
      ...inputs,
      {
        reportingOfficer: 'Kelvin Lionel',
        hod: 'Benjamin Koh',
        costCenter: 'Singapore Labour',
        claimReferenceNumber: claimReferenceNumber + 1,
        nameOfClaimant: '',
        personnelNo: '',
        designation: '',
        department: '',
        claimsMonth: null,
        totalPaymentAmount: '',
        description: '',
        cardsApprove: '',
        commissionPayble: '',
      },
    ]);
  };

  const copyForm = (i, input, claimReferenceNumber) => {
    let inputFinals = {
      ...input,
      claimReferenceNumber: claimReferenceNumber + 1,
    };

    setInputs([...inputs, inputFinals]);
  };

  const cardData = [
    {
      post: 'Reporting Officer',
      name:
        userData.userDetails.reportingTo.firstName +
        ' ' +
        userData.userDetails.reportingTo.lastName,
    },
    {
      post: 'HOD',
      name: userData.userDetails.HOD,
    },
    {
      post: 'Cost Center',
      name: userData.userDetails.costCenter,
    },
  ];
  const deleteForm = (i) => {
    if (inputs.length > 1) {
      const newArray = [...inputs];
      newArray.splice(i, 1);
      setInputs(newArray);
    }
  };

  const validateFields = () => {
    for (let i = 0; i <= inputs.length - 1; i++) {
      if (
        inputs[i].claimsMonth == undefined ||
        inputs[i].claimsMonth == null ||
        inputs[i].claimsMonth == ''
      ) {
        window.alert('Please Enter Claims Month!');
        return;
      }

      if (
        inputs[i].cardsApprove == undefined ||
        inputs[i].cardsApprove == null ||
        inputs[i].cardsApprove == ''
      ) {
        window.alert('Please Enter A Number Of Approved Cards !');
        return;
      }
      if (
        inputs[i].commissionPayble == undefined ||
        inputs[i].commissionPayble == null ||
        inputs[i].commissionPayble == ''
      ) {
        window.alert('Please Enter A Payble Commission !');
        return;
      }
      if (
        inputs[i].description == undefined ||
        inputs[i].description == null ||
        inputs[i].description == ''
      ) {
        window.alert('Please Enter Description !');
        return;
      }
    }
    return true;
  };

  // create object for send to the api start.
  const generateDataForClaimRequest = (
    inputs,
    userData,
    dateTime,
    callType
  ) => {
    let finalFormData = [];
    let updateFormObject = {};
    let callForPost = callType;

    if (inputs) {
      return (finalFormData = inputs.map((input) => {
        return (updateFormObject = {
          typeId: 4,
          employee: userData && userData.userDetails.id,
          claimReferenceNumber: input.claimReferenceNumber,
          cardsApproved: input.cardsApprove,
          commissionPayable: input.commissionPayble,
          claimMonth: input.claimsMonth,
          amount: input.totalPaymentAmount,
          description: input.description,
          submissionDate: dateTime,
          filePaths: mappedArr.get(input.claimReferenceNumber),
          status: callForPost,
        });
      }));
    }
  };
  // create object for send to the api end.
  const submitData = async (callType) => {
    const dateTime = generateCurrentTimeStamp();
    setFormSubmitted(true);
    setButtonClicked(callType);
    let validate = validateFields();

    if (!validate) {
      setFormSubmitted(false);
      return;
    }
    let finalFormData = generateDataForClaimRequest(
      inputs,
      userData,
      dateTime,
      callType
    );

    // POST call for submit form data.
    let businessTravelsResponse = await postFormData(finalFormData);

    if (businessTravelsResponse === 201) {
      let successMessage = '';
      if (callType === 'PENDING') {
        successMessage = 'Claim request submitted successfully !';
      } else {
        successMessage = 'Draft saved successfully !';
      }
      setFormSubmitted(false);
      openSuccessPopUp(successMessage);

    } else {
      setFormSubmitted(false);
      window.alert('Something went wrong');
    }
  };

  // On file select (from the pop up)
  const onFileChange = async (e, refNumber, typeId = 4) => {
    // check size for mapped array start
    if (e.target.files.length > 2) {
      alert('Only Two Attachements Are Allowed');
      return;
    }
    let res = mappedArr.get(refNumber);
    if (res && res.length) {
      if (res.length === 2) {
        alert('Only Two Attachements Are Allowed');
        return false;
      }
      if (res.length === 1 && e.target.files.length >= 2) {
        alert('Only Two Attachements Are Allowed');
        return false;
      }
    }
    // check size for mapped array end

    // Generates form data for file upload.
    let formData = await generateFormData(e, refNumber, typeId);

    // Send form data for file upload.
    if (formData) {
      let updatedPaths = await postFilesData(formData);
      // Sets updated paths received from POST api call.
      if (!Array.isArray(mappedArr.get(refNumber))) {
        mappedArr.set(refNumber, updatedPaths);
      } else {
        let temp = mappedArr.get(refNumber);
        temp = [...temp, ...updatedPaths];
        mappedArr.set(refNumber, temp);
      }
      setShowImgName(showImgName + 1);
    }
  };

  // remove attachment from claims data start
  const removeAttachement = (i, refNumber) => {
    const res = mappedArr.get(refNumber);
    if (Array.isArray(res)) {
      res.splice(i, 1);
    }
    setShowImgName(showImgName + 1);
  };
  // remove attachment from claims data end

  /** Close the modal */
  const closeBackdropHandler = () => {
    setIsBackdropOpen(false);
    navigate('/dashboard', {
      state: {
        claimType: '4',
      },
    });
  };

  // Open Modal When api response succesfully for(Draft/Submit)
  const openSuccessPopUp = (successMessage) => {
    setSuccessMessage(successMessage);
    setIsBackdropOpen(true);
  };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }} className='mar-top'>
        <Container>
          <Grid container>
              <ReportingDetails cardData={cardData} />
            
              <UserProfile
                name={userData && userData.userDetails.firstName}
                id={userData && userData.userDetails.id}
                designation={userData.userDetails?.jobTitle?.name}
                department={userData.userDetails?.department?.name}
              />
            <Grid xs={11}>
              <Box>
                {inputs &&
                  inputs.map((input, i) => (
                    <Box
                      key={i}
                      className='form-main'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '1em',
                        marginBottom: '4em',
                      }}
                    >
                      <Grid xs={11}>
                        <CardUI className='form-heading-wrap'>
                          <Grid
                            xs={12}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Grid xs={6}>
                              <h4>Commission Claims</h4>
                            </Grid>
                            <Grid
                              xs={6}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <h4>
                                Claim Reference Number :
                                {input.claimReferenceNumber}
                              </h4>
                            </Grid>
                          </Grid>
                        </CardUI>
                        <CardUI className='form-content-wrap'>
                          <Grid
                            container
                            spacing={2}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Claim Month *</label>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  variant='inline'
                                  inputFormat='MM/yyyy'
                                  maxDate={new Date()}
                                  minDate={
                                    new Date(
                                      userData &&
                                        userData.userDetails.dateOfJoining
                                    )
                                  }
                                  views={['year', 'month']}
                                  margin='normal'
                                  id='date-picker-inline'
                                  value={input.claimsMonth}
                                  onChange={(e) =>
                                    handleChangeInput(i, e, 'claimsMonth')
                                  }
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      size='small'
                                      fullWidth
                                      variant='outlined'
                                      {...params}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>No of Cards Approve *</label>
                              <TextField
                                type='number'
                                required
                                variant='outlined'
                                color='primary'
                                size='small'
                                value={input.cardsApprove}
                                name='cardsApprove'
                                fullWidth
                                onChange={(e) => {
                                  handleChangeInput(i, e, null, 'number');
                                  mutliplyNoOfCardApprove(i, e.target.value);
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Commission Payable *</label>
                              <FormControl
                                sx={{ minWidth: 120 }}
                                fullWidth
                                size='small'
                              >
                                <Select
                                  value={input.commissionPayble}
                                  onChange={(e) => handleChangeInput(i, e)}
                                  name='commissionPayble'
                                  displayEmpty
                                  inputProps={{
                                    'aria-label': 'Without label',
                                  }}
                                  variant='outlined'
                                  autoWidth
                                >
                                  {commissionPaybleOptions &&
                                    commissionPaybleOptions.map((each, i) => (
                                      <MenuItem key={i} value={each}>
                                        {each}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Description *</label>
                              <TextField
                                required
                                variant='outlined'
                                color='primary'
                                size='small'
                                value={input.description}
                                name='description'
                                fullWidth
                                onChange={(e) => handleChangeInput(i, e)}
                              />
                            </Grid>
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor=''>Total Payment Amount *</label>
                              <TextField
                                type='number'
                                required
                                disabled
                                variant='outlined'
                                color='primary'
                                size='small'
                                value={input.totalPaymentAmount}
                                name='totalPaymentAmount'
                                fullWidth
                                onChange={(e) => handleChangeInput(i, e)}
                              />
                            </Grid>
                            <Grid item xs={12} lg={4} md={4} sm={4}>
                              <label htmlFor='icon-button-file'>
                                {' '}
                                Attachment{' '}
                              </label>
                              <input
                                multiple
                                accept='image/pdf*'
                                type='file'
                                onChange={(e) =>
                                  onFileChange(e, input.claimReferenceNumber)
                                }
                              />
                              {showImgName > 0 &&
                                [...mappedArr].map(([key, value]) => {
                                  return (
                                    <span key={key}>
                                      {input.claimReferenceNumber === key &&
                                        value &&
                                        value.map((each, i) => (
                                          <div
                                            key={i}
                                            className='file-path-wrap'
                                          >
                                            <h6>{input.claimReferenceNumber}_{i+1}</h6>
                                            <IconButton
                                              onClick={() =>
                                                removeAttachement(
                                                  i,
                                                  input.claimReferenceNumber
                                                )
                                              }
                                            >
                                              <CancelRoundedIcon />
                                            </IconButton>
                                          </div>
                                        ))}
                                    </span>
                                  );
                                })}
                            </Grid>
                            <Grid item xs={12} lg={4} md={4} sm={4}></Grid>
                          </Grid>
                        </CardUI>
                      </Grid>
                      <Grid
                        xs={1}
                        className='form-main-actions'
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <Grid>
                          <IconButton onClick={() => deleteForm(i)}>
                            <DeleteIcon
                              style={{
                                border: '1px solid #7db77d',
                                padding: '0.3em',
                                borderRadius: '8px',
                                color: '#7db77d',
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid>
                          <IconButton
                            onClick={() =>
                              copyForm(i, input, input.claimReferenceNumber)
                            }
                          >
                            <ContentCopyIcon
                              style={{
                                border: '1px solid #7db77d',
                                padding: '0.3em',
                                borderRadius: '8px',
                                color: '#7db77d',
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid>
                          <IconButton>
                            <AddIcon
                              onClick={() =>
                                addFormFields(input.claimReferenceNumber)
                              }
                              style={{
                                border: '1px solid #7db77d',
                                padding: '0.3em',
                                borderRadius: '8px',
                                color: '#7db77d',
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                <Grid style={{ position: 'relative' }}>
                  <Footer
                    type={type}
                    onclick={() => submitData('PENDING')}
                    onSaveDraft={() => submitData('DRAFT')}
                    disabledSubmit={formSubmitted}
                    buttonClicked = {buttonClicked}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Modal
        show={isBackdropOpen}
        onCancel={closeBackdropHandler}
        header={<h1>Success</h1>}
        contentClass='modal-content'
        footerClass='footer-content'
        submitButton={
          <ButtonUI
            backgroundColor='#00AC94'
            color='#ffffff'
            size='small'
            padding='3px 20px'
            onclick={closeBackdropHandler}
          >
            Ok
          </ButtonUI>
        }
      >
        <h4>{successMessage}</h4>
      </Modal>
    </React.Fragment>
  );
};
export default CommissionClaim;
