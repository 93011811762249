import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppLevelPermissions } from '../OnBoarding/Organization/constants/onboarding.constants';
import { fetchCompBenPermissions } from '../../redux/modulePermissions/action';
import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import TryAgain from '../molecules/ErrorState/error.component';
import CircularLoader from '../../atoms/CircularLoader/circular-loader';

const CompBen = () => {
  const [viewMode, setViewMode] = useState(false);

  const { currentUserRoleId, appPermissions } = useSelector(
    (state) => state.main
  );
  const {
    requestProcessing: {
      [APIRequestStatus.FETCH_COMP_BEN_PERMISSIONS]: permissionsLoading,
    },
  } = useSelector((state) => state.modulePermissions);

  const dispatch = useDispatch();

  useEffect(() => {
    if (appPermissions && currentUserRoleId) {
      if (appPermissions[AppLevelPermissions.CompAndBen]) {
        dispatch(
          fetchCompBenPermissions(
            currentUserRoleId,
            appPermissions[AppLevelPermissions.CompAndBen].id
          )
        );
      }
    }
  }, [currentUserRoleId, appPermissions]);

  if (permissionsLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }

  return (
    <Box m={4} pl={4}>
      <CircularLoader show={permissionsLoading === LoadingStatus.Loading} />
      {permissionsLoading === LoadingStatus.Success && (
        <Outlet context={[viewMode, setViewMode]} />
      )}
    </Box>
  );
};

export default CompBen;
