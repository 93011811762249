import './Navigation.css';

import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import React, { useState } from 'react';
import { Container } from '@material-ui/core';
import { IconButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { navOptionsList } from './navigation.util';
import { isEmpty } from 'lodash';
import {
  myProfile,
  userId,
} from '../../components/OnBoarding/Organization/constants/onboarding.constants';

const Navigation = () => {
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const navigate = useNavigate();

  const { appPermissions, userInfo } = useSelector((state) => state.main);

  const navOptions = navOptionsList.map((item) => {
    const { name } = item;
    if (name === 'home') {
      return { ...item, id: 0 };
    }

    if (appPermissions) {
      if (name === myProfile) {
        return {
          ...item,
          label: appPermissions[name]?.displayName,
          id: appPermissions[name]?.id,
          show: appPermissions[name]?.allowView,
          to: myProfile
        };
      }
      return {
        ...item,
        label: appPermissions[name]?.displayName,
        id: appPermissions[name]?.id,
        show: appPermissions[name]?.allowView
      };
    }
    return item;
  });

  const allowedOptions = navOptions.filter((item) => item.show);

  const handleListItemClick = (path, id) => {
    if (path) {
      navigate(path, { state: { imoduleId: id } });
    } else {
      setOpenSubMenu(!openSubMenu);
    }
  };

  const renderOptions = () =>
    allowedOptions.map((item) => {
      const { id, to, label, name, subList } = item;
      return (
        <li
          key={id}
          onClick={() => handleListItemClick(to, id)}
          style={{ cursor: 'pointer' }}
        >
          <IconButton className="menu-icon">
            <ArrowRightRoundedIcon fontSize="medium" />
          </IconButton>
          {label}
          {subList && (
            <ul
              className={`ve-nav-sublist ${openSubMenu ? 'showSublist' : ''}`}
            >
              {subList.map((item) => {
                const { label, to, name } = item;
                return (
                  <li
                    key={name}
                    onClick={() => navigate(to, { state: { moduleId: id } })}
                  >
                    {label}
                  </li>
                );
              })}
            </ul>
          )}
        </li>
      );
    });

  return (
    <Container maxWidth="lg">
      <div className="menu-list-wrap">
        <ul className="main-menu-list">{renderOptions()}</ul>
      </div>
    </Container>
  );
};

export default Navigation;
