import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Typography, TextField, FormHelperText } from '@mui/material';
import {
  getCalendarDay,
  getDate,
  getFormattedValues,
} from '../../utils/formatter';
import { DateFormatters } from '../../constants/dateConstants';
import FieldView from '../../components/molecules/FieldView/FieldView';
import moment from 'moment';
import { LightTooltip } from '../Tooltip/LightTooltip';
import { InfoOutlined } from '@mui/icons-material';
import { FormFieldTypes } from '../../constants/common';
import { useSelector } from 'react-redux';

export default function DateField(props) {
  const {
    id,
    defaultValue,
    placeholder,
    labelText,
    size,
    hiddenLabel,
    fullWidth,
    required,
    onChange,
    name,
    value,
    errorMessage,
    maxDate,
    minDate,
    disabled,
    copyTo,
    isReadOnly,
    infoIcon,
    disablePast,
    disableFuture,
    fieldType,
    shouldDisable,
  } = props;

  const { publicHoliday } = useSelector((state) => state && state.onBoarding);

  const shouldDisableDate = (date) => {
    switch (shouldDisable?.trim()) {
      case 'showHoliday': {
        const currentDate = getFormattedValues('YYYY-MM-DD', date);
        return !publicHoliday.includes(currentDate);
      }
      case 'showSunday': {
        const isSunday = getCalendarDay(date, 0);
        return !isSunday;
      }
      case 'showSaturday': {
        const isSaturday = getCalendarDay(date, 6);
        return !isSaturday;
      }
      default: {
        break;
      }
    }
  };

  const keyDown = (event) => {
    switch (shouldDisable?.trim()) {
      case 'showHoliday':
      case 'showSunday':
      case 'showSaturday': {
        return event.preventDefault();
      }
      default: {
        break;
      }
    }
  };

  if (isReadOnly) {
    return (
      <FieldView
        labelText={labelText}
        labelValue={
          moment(value, 'YYYY-MM-DD', true).isValid()
            ? moment(value, 'YYYY-MM-DD').format(DateFormatters.ddmmyyyy)
            : value
        }
      />
    );
  }
  return (
    <>
      {labelText && (
        <Typography
          variant="body2"
          color={`${disabled ? 'neutral.light40' : 'neutral.dark80'}`}
          mb={1}
        >
          {labelText}
          {required && (
            <Box color="accentSecondary.main" sx={{ display: 'inline-block' }}>
              *
            </Box>
          )}
          {infoIcon && (
            <LightTooltip title={infoIcon} placement="top" arrow>
              <Box color="primary.main" sx={{ display: 'inline-block' }}>
                <InfoOutlined
                  sx={{ width: 14, height: 14, ml: '4px', cursor: 'pointer' }}
                />
              </Box>
            </LightTooltip>
          )}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
        <DatePicker
          format="DD/MM/YYYY"
          onChange={(value) =>
            onChange(
              value,
              name,
              copyTo,
              minDate,
              maxDate,
              disableFuture,
              disablePast
            )
          }
          value={
            value
              ? getDate(value, DateFormatters.ddmmyyyy)
              : (defaultValue &&
                  getDate(defaultValue, DateFormatters.ddmmyyyy)) ||
                null
          }
          maxDate={maxDate}
          minDate={minDate}
          disabled={disabled}
          disableFuture={disableFuture}
          disablePast={disablePast}
          shouldDisableDate={shouldDisableDate}
          slotProps={{
            textField: {
              fullWidth: fullWidth,
              size: 'small',
              variant: 'outlined',
              error: errorMessage,
              helperText: errorMessage,
              onKeyDown: keyDown,
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
}
