import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React from 'react';

export default function FormSelect({ options, label, labelId = label, handleChange, error, helperText = '', required = false, ...rest }) {

    return (
        <>
            <Typography variant="body2" color={'neutral.dark80'} mb={1}>{label} {required && <Box color="accentSecondary.main" sx={{ display: 'inline-block' }}>*</Box>}</Typography>
            <FormControl fullWidth>
                <Select
                    onChange={handleChange}
                    error={error}
                    {...rest}
                >
                    <MenuItem >{'Select'}</MenuItem>
                    {options && options.map((option, i) => (<MenuItem key={i} value={option.value}>{option.label}</MenuItem>))}
                </Select>
                {error && <FormHelperText className='text-danger'>{helperText}</FormHelperText>}
            </FormControl>
        </>
    );
}
