import React from 'react';
import FormView from '../../../../../../OnBoarding/common/Form/FormView';

const CandidateAssessmentView = (props) => {
const {formConfig, formData} = props;

  return (
    <FormView list={formConfig} info={formData} />
  );
};

export default CandidateAssessmentView;