import { Box, CircularProgress, Container, Grid } from '@material-ui/core';
import React from 'react';
import ButtonUI from '../../shared/components/ui-elements/ButtonUI';
import { useNavigate } from 'react-router-dom';
import './Footer.css';

const Footer = (props) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <footer
        className="footer-main"
        style={{
          marginLeft: props.marginLeft,
        }}
      >
        <Box>
          <Container className="footer-container">
            <Grid container>
              <Grid item xs={10} lg={11} sm={11} className="footer-outer">
                <Grid xs={8} sm={4} md={8} item className="footer-form-name">
                  <span
                    style={{ fontSize: '0.8em', textTransform: 'capitalize' }}
                  >
                    {props.type}
                  </span>
                </Grid>
                <Grid className="footer-buttons" xs={12} sm={8} md={4}>
                  {!props.fromEdit && (
                    <Grid item>
                      <ButtonUI
                        className="delete-button"
                        variant="text"
                        size="small"
                        color="#777B81"
                        margin="0 0 0 10px"
                        onclick={() => navigate('/claims')}
                      >
                        Delete
                      </ButtonUI>
                    </Grid>
                  )}
                  {!props.fromEdit && (
                    <Grid item xs={12} sm={5}>
                      <ButtonUI
                        variant="outlined"
                        size="small"
                        border="1px solid #00AC94"
                        color="#00AC94"
                        onclick={props.onSaveDraft}
                        disabled={
                          props.disabledSubmit ? props.disabledSubmit : false
                        }
                      >
                       {props.buttonClicked === 'DRAFT' && props.disabledSubmit ? (
                        <CircularProgress color="white" size={20} />
                      ) : (
                        
                        'Save as Draft'
                      )}
                      </ButtonUI>
                    </Grid>
                  )}
                  {props.fromEdit && (
                    <Grid item xs={12} sm={5}>
                      <ButtonUI
                        variant="contained"
                        size="small"
                        width="100%"
                        backgroundColor="#00AC94"
                        color="#ffffff"
                        onclick={() => navigate(-1)}
                        margin="0 0 0 -15px"
                      >
                        Go Back
                      </ButtonUI>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={5}>
                    <ButtonUI
                      variant="contained"
                      size="small"
                      width="100%"
                      backgroundColor="#00AC94"
                      color="#ffffff"
                      onclick={props.onclick}
                      disabled={
                        props.disabledSubmit ? props.disabledSubmit : false
                      }
                    >
                      {props.buttonClicked === 'PENDING' && props.disabledSubmit ? (
                        <CircularProgress color="white" size={20} />
                      ) : (
                        'submit'
                      )}
                    </ButtonUI>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </footer>
    </React.Fragment>
  );
};
export default Footer;
