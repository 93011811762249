import { Box, Button, Checkbox, FormControlLabel, Select, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import SelectTag from '../Select/SelectTag';
import { LightTooltip } from '../Tooltip/LightTooltip';
import { InfoOutlined } from '@mui/icons-material';
import FieldView from '../../components/molecules/FieldView/FieldView';

const ButtonField = (props) => {
  const {
    name,
    value,
    onChange,
    label,
    required,
    precision = 1,
    size = 'large',
    max = 5,
    disabled,
    isReadOnly,
    align = 'horizontal',
    marginTop = 0,
    infoIcon,
    onClick
  } = props;
  return (
    <Box
      display={align === 'horizontal' ? 'flex' : 'block'}
      justifyContent={'space-between'}
      alignItems='center'
    >
      <Button
        variant="text"
        color="primary"
        sx={{textTransform:'none'}}
        onClick={onClick}
        disabled={disabled}
    >
        {label}
    </Button>
    </Box>
  );
};

export default ButtonField;
