import React from 'react';

const CurrencyFormatter = ({ value }) => {
  const formattedCurrency = value?.toLocaleString('en-SG', {
    style: 'decimal',
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2,
  });

  return (
    <div>
      <span>{formattedCurrency}</span>
    </div>
  );
};

export default CurrencyFormatter;
