export const payroll = 'payroll';
export const payrollLabel = 'Payroll';
export const giroFile = 'giro_file';
export const giroFileLabel = 'Bank GIRO Files';
export const PayrollPermissions= {
  ACCESS_ALL_PAYROLL: 'access-all-payroll',
  MY_PAYROLL: 'my-payroll',
  PAYROLL_MANAGER: 'payroll-manager',
  APPROVER_DASHBOARD: 'approver-dashboard',
  ALLOW_GIRO_FILE:'allow-giro_file'
};

export const AllowPublish = 'allow-payroll-publish';
export const AllowPay = 'allow-payroll-pay';
export const AllowBankTransfer = 'allow-bank-transfer';

export const payrollDashboard = 'payroll-dashboard';
export const giroFileDashboard = 'giro-file';

export const SalaryType = {
  Hourly: 'HRL',
  Monthly: 'MTH',
};

export const PayrollStatus = {
  Publish: 'PU',
  Save: 'SV',
  Rejected: 'RE',
  Approved: 'AP',
  SendBack: 'SB',
  Pay: 'PA',
  Pending:'PE',
  BankTransfer: 'BT'
};

export const PayrollStatusCode = {
  'PU':'Publish',
  'SV':'Saved',
  'RE':'Rejected',
  'AP':'Approved',
  'SB':'SendBack',
  'PA':'Paid',
  'PE':'Pending'
};

export const reasonsForPayrollReject = [
  'Incorrect Calculation',
  'Salary to be On Hold',
  'Missing Compensation',
  'Incorrect Employment Status',
  'Other',
];
