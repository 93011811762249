import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

const GoalsOutlet = () => {
  const [viewMode, setViewMode] = useState(false);

  return (
    <Outlet context={[viewMode, setViewMode]}/>
  );
};

export default GoalsOutlet;