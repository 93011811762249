import React, { useEffect, useState } from 'react';
import { OnboardingServices } from '../../../../../services/onboarding';
import { showToast } from '../../../../../utils/common.util';
import { ToastMessages, stringSubstitute } from '../../constants/onboarding.constants';
import { ToastThemes, toastMessage } from '../../../../../constants/common';
import { Box, Button } from '@mui/material';
import ModalDialog from '../../../../../atoms/Modal/ModalDialog';
import ClaimParentModel from '../ClaimConfig/PermissionType/ClaimConfigModel/claim-parent-model.component';

const PerformanceConfigModal = (props) => {
    const { roleId, moduleId, roleName, content } = props;

    const [formInfo, setFormInfo] = useState({});
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [confirmClose, setConfirmClose] = useState(false);
  
    useEffect(() => {
      if (showModal === true) {
        setConfirmClose(false);
      }
    }, [showModal]);
  
    const handleSaveClick = () => {
      if (!formInfo?.moduleId) {
        setError('Select Form can\'t be empty');
      } else {
        new OnboardingServices()
          .updateRolePermissions({ roleId, permissions: [formInfo] })
          .then(() => {
            showToast(
              ToastMessages.success.replace(
                stringSubstitute,
                toastMessage.update
              ),
              ToastThemes.success
            );
            setConfirmClose(true);
            setShowModal(false);
            setFormInfo({});
          })
          .catch(() => {
            setConfirmClose(false);
            setShowModal(true);
          });
      }
    };
  
    const buttonClick = async () => {
      setShowModal(true);
    };
  
    return (
      <>
        <Box display="flex" gap={2}>
          <Button onClick={buttonClick}>Config Performance</Button>
          <ModalDialog
            severity="success"
            title="Config Performance"
            listComponent={
              <ClaimParentModel
                formInfo={formInfo}
                setFormInfo={setFormInfo}
                moduleId={moduleId}
                roleId={roleId}
                roleName={roleName}
                error={error}
                setError={setError}
                showDropDown={false}
              />
            }
            primaryAction="save"
            secondaryAction="Cancel"
            showModal={showModal}
            onClickClose={() => {
              setShowModal(false);
              setConfirmClose(false);
              setFormInfo({});
            }}
            onClickConfirm={handleSaveClick}
            maxWidth="fit-content"
            fullWidth
            confirmClose={confirmClose}
            primaryButtonDisabled={error}
          />
        </Box>
      </>
    );
  };

export default PerformanceConfigModal;