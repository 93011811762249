import API from './api';

export class fileUploadServices extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL || '';
  header = {
    'Content-Type': 'multipart/form-data'
  };

  constructor() {
    super(fileUploadServices.baseURL, fileUploadServices.header);
  }

  fileUpload = async (formData) => (
    this.responsify(await this.instance.post('files/upload', formData))
  );
}