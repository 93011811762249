import { FormFieldTypes } from '../../../constants/common';

export const CurrencyConfigsFields = [
  {
    id: 1,
    fieldLabel: 'Filter Date Field',
    placeholderText: 'Select',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: false,
    fieldRefName: 'filterDate',
    size: 'small',
    fullWidth: true,
    fieldSize: 6,
  },
  {
    id: 2,
    fieldLabel: 'Target Field',
    placeholderText: 'Select',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: false,
    fieldRefName: 'target',
    size: 'small',
    fullWidth: true,
    fieldSize: 6,
  },
];


export const CurrencyExFields = {
  FilterDate:'filterDate',
  Target:'target'
};

export const CurrencyExConfig = 'currenyExchangeConfig';
