export const GET_CANDIDATE_CONFIG_REQUEST = 'GET_CANDIDATE_CONFIG_REQUEST';
export const GET_CANDIDATE_CONFIG_SUCCESS = 'GET_CANDIDATE_CONFIG_SUCCESS';
export const GET_CANDIDATE_CONFIG_FAILURE = 'GET_CANDIDATE_CONFIG_FAILURE';

export const POST_CANDIDATE_INFO_REQUEST = 'POST_CANDIDATE_INFO_REQUEST';
export const POST_CANDIDATE_INFO_SUCCESS = 'POST_CANDIDATE_INFO_SUCCESS';
export const POST_CANDIDATE_INFO_FAILURE = 'POST_CANDIDATE_INFO_FAILURE';

export const GET_ALL_CANDIDATE_LIST_REQUEST = 'GET_ALL_CANDIDATE_LIST_REQUEST';
export const GET_ALL_CANDIDATE_LIST_SUCCESS = 'GET_ALL_CANDIDATE_LIST_SUCCESS';
export const GET_ALL_CANDIDATE_LIST_FAILURE = 'GET_ALL_CANDIDATE_LIST_FAILURE';

export const RESET_CANDIDATE = 'RESET_CANDIDATE';

export const POST_CANDIDATE_DRAFT_REQUEST = 'POST_CANDIDATE_DRAFT_REQUEST';
export const POST_CANDIDATE_DRAFT_SUCCESS = 'POST_CANDIDATE_DRAFT_SUCCESS';
export const POST_CANDIDATE_DRAFT_FAILURE = 'POST_CANDIDATE_DRAFT_FAILURE';

export const GET_CANDIDATE_ASSESSMENT_REQUEST = 'GET_CANDIDATE_ASSESSMENT_REQUEST';
export const GET_CANDIDATE_ASSESSMENT_SUCCESS = 'GET_CANDIDATE_ASSESSMENT_SUCCESS';
export const GET_CANDIDATE_ASSESSMENT_FALIURE = 'GET_CANDIDATE_ASSESSMENT_FALIURE';