import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Box, FormHelperText, Select, Typography } from '@mui/material';

export default function SelectTag(props) {

  const { id,
    defaultValue,
    placeholder,
    labelText,
    size,
    hiddenLabel,
    fullWidth,
    widthAuto,
    required,
    dropDownList,
    name,
    value,
    onchange,
    errorMessage,
    disabled,
    ...rest } = props;

  return (
    <Box>
      <FormControl error={errorMessage}
        sx={{ width: fullWidth ? '100%' : widthAuto ? 'auto' : 150 }}
      >
        {labelText && (
          <Typography variant='body2' color={'neutral.dark80'} mb={1}>
            {labelText}
            {required && (
              <Box
                color='accentSecondary.main'
                sx={{ display: 'inline-block' }}
              >
                *
              </Box>
            )}
          </Typography>
        )}
        <Select
          value={value}
          onChange={onchange}
          name={name}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          size={size}
          fullWidth={fullWidth}
          renderValue={(selected) => selected ? dropDownList.find((item) => item.id === value)?.name : defaultValue || <Box sx={{ color: 'neutral.dark40' }}>{placeholder}</Box>}
          placeholder={placeholder}
          disabled={disabled}
        >
          {dropDownList?.map((item) => {
            const { id, name } = item;
            return <MenuItem key={id} value={id}>{name}</MenuItem>;
          })}
        </Select>
        {errorMessage &&
          <FormHelperText style={{ color: '#d32f2f' }}>{errorMessage}</FormHelperText>}
      </FormControl>
    </Box>
  );
}