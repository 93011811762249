import React, { useEffect, useState } from 'react';
import { Typography, Grid, Button } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { downloadWithCustomName as downloadFile } from '../../../components/download/Downloader';

const Attachments = ({ paths }) => {
  const [showSkelton, setShowSkelton] = useState(true);
  const downloadWithCustomName = (url,name)=>downloadFile(url,name);
  useEffect(() => {
    setTimeout(() => {
      setShowSkelton(false);
    }, 1000);
  }, []);

  let finalDownloadableLinks =
    paths.attachment &&
    paths.attachment.map((each, i) => {
      return (<Button key={i} variant="text" onClick={()=> downloadWithCustomName(each,paths.referenceNumber+'-A'+(i+1))}>{paths.referenceNumber}-A{i + 1} </Button>);
      // return (
      //   <a
      //     style={{ display: 'flex', flexDirection: 'column' }}
      //     key={i}
      //     href={`${process.env.REACT_APP_FILE_BASE_URL}${each}`}
      //     target="_blank"
      //     rel="noreferrer"
      //   >
      //     File {i + 1}
      //   </a>
      // );
    });

  if (
    finalDownloadableLinks == undefined ||
    finalDownloadableLinks.length == 0
  ) {
    finalDownloadableLinks = showSkelton ? (
      <Skeleton />
    ) : (
      <span>No Files Available</span>
    );
  }
  return (
    <Grid container spacing={2} className="claims-data">
      <Grid xs={3} sm={6} md={9} lg={12} className="data-display">
        <Grid xs={12} sm={9} lg={4} className="data-file">
          <Typography
            className="data-title"
            style={{ marginBottom: '10px', cursor: 'pointer' }}
          >
            Uploaded Files
          </Typography>
          {finalDownloadableLinks}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Attachments;
