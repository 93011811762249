import { LoadingStatus, ToastThemes } from '../../../../constants/common';
import { showToast } from '../../../../utils/common.util';
import { permissionsObj } from '../RolesAndPermissions/constants/roles-permissions.constants';
import { ToastMessages, companyInfo, departments, designations, documents, educationHistory, employeeHistory, locations, nameValidationErrorMessage, onboarding, payStructure, payrollAssignment, personalDetails, rolesAndPermission, seperation, stringSubstitute, userInfo, userProfile, workDetails, approvalWorkflow, currencyConversion, externalIntegration } from '../constants/onboarding.constants';

export const citiesAndCountriesSample = [
  { id: 1, name: 'Singapore' },
  // { id: 2, name: 'jind' },
  // { id: 3, name: 'gurugram' }
];


export const getDropdownSelectedValue = (list, value) => {
  return list.find((item) => item.id === value)?.name;
};

export const getNonEmptyObject = (list) => {
  return list.some(obj => Object.keys(obj).length > 0);
};
export const companyInfoLabels = {
  companyName: 'Company Name',
  companyLogo: 'Company Logo',
  companyDescription: 'Company Description',
  companyEmail: 'Company Email',
  companyWebsite: 'Company Website',
  callingCode: 'Calling Code',
  phoneNumber: 'Phone Number',
  altPhoneNumber: 'Alternate Phone Number',
  altCallingCode: 'Alternate Calling Code',
  address: 'Address',
  addressCity: 'City',
  addressState: 'State',
  addressCountry: 'Country',
  addressPostalCode: 'Postal Code',
  billingAddress: 'Billing Address',
  billingAddressCity: 'Billing City',
  billingAddressState: 'Billing State',
  billingAddressCountry: 'Billing Country',
  billingAddressPostalCode: 'Billing Postal Code',
  gstNumber: 'GST Number',
};

export const isNotEmptyString = (str) => {
  return !!str?.toString().trim();
};

export const roleListHeader = [
  {
    id: 'name',
    label: 'Role'
  },
  {
    id: 'userCount',
    label: 'User Count',
    dataType: 'number'
  }
];

export const departmentsListHeader = [
  {
    id: 'name',
    label: 'Department',
  },
  {
    id: 'managerName',
    label: 'Manager',
  },
  {
    id: 'supervisorName',
    label: 'HR-OPS',
  }
];

export const locationListHeader = [
  {
    id: 'name',
    label: 'Location'
  },
];

export const designationListHeader = [
  {
    id: 'shortName',
    label: 'Short Name'
  },
  {
    id: 'longName',
    label: 'Long Name'
  },
  {
    id: 'gradeLevel',
    label: 'Grade Level'
  }
];

export const doesNameAlreadyExists = (list, name) => {
  return !!list.find(list => list.name === name);
};

export const getHelperTextOnInputInvalid = (allowAdd) => {
  if (!allowAdd) {
    return nameValidationErrorMessage;
  }
};

export const scrollToNewRow = (tableRef) => {
  if (tableRef?.current) {
    tableRef?.current.lastElementChild.scrollIntoView({ behavior: 'smooth' }
    );
  }
};

export const getUserInfoToast = (apiLoadingStatus, successMessage, failureMessage) => {
  if (apiLoadingStatus === LoadingStatus.Success) {
    showToast(ToastMessages.success.replace(stringSubstitute, successMessage), ToastThemes.success);
  } else if (apiLoadingStatus === LoadingStatus.Failure) {
    showToast(ToastMessages.failed.replace(stringSubstitute, failureMessage), ToastThemes.error);
  }
};

export const getSaveButtonText = (viewMode, isPublished) => {
  if (viewMode) {
    return 'Edit';
  } else {
    if (isPublished) {
      return 'Save';
    }
    return 'Draft';
  }
};
export const getOrgOnboardingTabs = (permissions) => {
  return [
    {
      name: companyInfo,
      path: `/${onboarding}/${companyInfo}`,
      label: 'Company Info',
      title: 'Company Info',
      show: permissions?.[companyInfo]?.allowView
    },
    {
      name: rolesAndPermission,
      path: `/onboarding/${rolesAndPermission}`,
      label: 'Roles & Permissions',
      title: 'Roles & Permissions',
      show: permissions?.[rolesAndPermission]?.allowView
    },
    {
      name: departments,
      path: `/onboarding/${departments}`,
      label: 'Departments',
      title: 'Departments',
      show: permissions?.[departments]?.allowView
    },
    {
      name: locations,
      path: `/onboarding/${locations}`,
      label: 'Locations',
      title: 'Locations',
      show: permissions?.[locations]?.allowView
    },
    {
      name: designations,
      path: `/onboarding/${designations}`,
      label: 'Designations',
      title: 'Designations',
      show: permissions?.[designations]?.allowView
    },
    {
      name: payStructure,
      path: `/onboarding/${payStructure}`,
      label: 'Salary Structure',
      title: 'Salary Structure',
      show: permissions?.[payStructure]?.allowView
    },
    { 
      name: approvalWorkflow,
      path: `/onboarding/${approvalWorkflow}`,
      label: 'Approval Flow',
      title: 'Approval Flow',
      show: permissions?.[approvalWorkflow]?.allowView
    },
    {
      name: currencyConversion,
      path: `/onboarding/${currencyConversion}`,
      label: 'Currency Conversion Rates',
      title: 'Currency Conversion Rates',
      show: permissions?.[currencyConversion]?.allowView
    },
    {
      name: externalIntegration,
      path: `/onboarding/${externalIntegration}`,
      label: 'External Integration',
      title: 'External Integration',
      show: permissions?.[externalIntegration]?.allowView
    }
  ];
};

export const getUserOnboardingTab = (appPermissions, userProfilePermissions) => {
  return [
      {
        name: 'all-users',
        label: '   All users',
        path: '/user-onboarding/',
        show: appPermissions?.[userProfile]?.[permissionsObj.allowView]
      },
      {
        name: 'add-users',
        label: '   Add users',
        path: '/user-onboarding/add-user',
        show: userProfilePermissions?.[userInfo]?.[permissionsObj.allowAdd] || userProfilePermissions?.[personalDetails]?.[permissionsObj.allowAdd]
          || userProfilePermissions?.[workDetails]?.[permissionsObj.allowAdd] || userProfilePermissions?.[documents]?.[permissionsObj.allowAdd]
          || userProfilePermissions?.[employeeHistory]?.[permissionsObj.allowAdd] || userProfilePermissions?.[educationHistory]?.[permissionsObj.allowAdd]
          || userProfilePermissions?.[seperation]?.[permissionsObj.allowAdd] || userProfilePermissions?.[payrollAssignment]?.[permissionsObj.allowAdd]
      }
  ];
};

export const getOnboardingNavigationList = (orgPermissions, appPermissions, userProfilePermissions) => {
  const allowedOrgOnboardingTabs = getOrgOnboardingTabs(orgPermissions).filter((item) => item.show);
  const allowedUserOnboardingTabs = getUserOnboardingTab(appPermissions, userProfilePermissions).filter((item) => item.show);
  return [
    {
      name: 'user-onboarding',
      label: 'Users & Profiles',
      subList: allowedUserOnboardingTabs
    },
    {
      name: onboarding,
      label: 'Organization',
      subList: allowedOrgOnboardingTabs
    }
  ];
};


export const getOnboardingDropdownList = (orgPermissions, appPermissions, userProfilePermissions) => {
  const orgOnboardingTabs = getOrgOnboardingTabs(orgPermissions);
  const userOnboardingTabs = getUserOnboardingTab(appPermissions, userProfilePermissions);
  const menu = [
    {
      name: 'user-onboarding',
      label: 'Users & Profiles',
      show: true
    },
    ...userOnboardingTabs,
    {
      name: onboarding,
      label: 'Organization',
      show: true
    },
    ...orgOnboardingTabs];
  return menu;
};

export const payStructureListHeader = [
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'base',
    label: 'Base'
  },
  {
    id: 'assignee',
    label: 'Assign To'
  },
  {
    id: 'add',
    label: 'Addition Components'
  },
  {
    id: 'sub',
    label: 'Deduction Components'
  },
  {
    id: 'status',
    label: 'Status'
  }
];
export const approvalFlowListHeader = [
  {
    id: 'name',
    label: 'Library Name'
  },

  {
    id: 'steps',
    label: 'Steps'
  },
  {
    id: 'createdOn',
    label: 'Created on'
  },
  {
    id: 'module',
    label: 'Form Attached'
  },
  {
    id: 'status',
    label: 'Status'
  }
];