import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import { Outlet, useLocation } from 'react-router';
import { AppLevelPermissions } from '../OnBoarding/Organization/constants/onboarding.constants';
import TryAgain from '../molecules/ErrorState/error.component';
import { Box, Divider } from '@mui/material';
import CircularLoader from '../../atoms/CircularLoader/circular-loader';
import DynamicBreadcrumb from '../../atoms/Breadcrumbs/dynamicBreadcrumb';
import SubHeader from './SubHeader/SubHeader';
import { fetchPayrollPermissions } from '../../redux/modulePermissions/action';

const Payroll = () => {
    const { currentUserRoleId, appPermissions } = useSelector((state) => state.main);
    const dispatch = useDispatch();
    const { requestProcessing: { [APIRequestStatus.FETCH_PAYROLL_PERMISSIONS]: permissionsLoading,
    } } = useSelector((state) => state.modulePermissions);
    const [viewMode, setViewMode] = useState(false);


    useEffect(() => {
        if (appPermissions && currentUserRoleId) {
            if (appPermissions[AppLevelPermissions.Payroll]) {
                dispatch(fetchPayrollPermissions(currentUserRoleId, appPermissions[AppLevelPermissions.Payroll].id));
            }
        }
    }, [currentUserRoleId, appPermissions]);

    if (permissionsLoading === LoadingStatus.Failure) {
        return <TryAgain />;
    }
    return (
        <Box m={4} pl={4}>
            <CircularLoader show={permissionsLoading === LoadingStatus.Loading} />
            <Box>
                <SubHeader />
                <Box my={3}>
                    <DynamicBreadcrumb />
                    <Box mt={1}>
                        <Divider />
                    </Box>
                </Box>
            </Box>
            <Outlet context={[viewMode, setViewMode]} />
        </Box>
    );
};

export default Payroll;