import { Box } from '@mui/material';
import React from 'react';

const DocumentView = (props) => {
    const {type, info, documentsEditDisabled, name} = props;
    const fileType = info?.[name]?.mimeType?.split('/');
    return (
        type === 'image' ? 
        <Box>
        <a href={info[name]?.url} download={info[name]?.fileName} style={{ pointerEvents: documentsEditDisabled ? 'none':'auto' }}>
            {(info[name]?.url) ? <img src={(info[name]?.url)} height={80} /> : ''}
        </a>
    </Box> : 
    <Box>
    <a href={info[name]?.url} download={info[name]?.fileName} style={{ pointerEvents: documentsEditDisabled ? 'none':'auto' }}>
     {fileType?.[0] === 'image'? <img src={info[name]?.url} height={80} /> : info[name]?.fileName}
    </a>
</Box>
  );
};

export default DocumentView;