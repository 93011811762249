import { Box, Button, Typography, styled } from '@mui/material';
import React from 'react';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { LightTooltip } from '../Tooltip/LightTooltip';
import { InfoOutlined } from '@mui/icons-material';
import DocumentView from '../../components/OnBoarding/UserProfile/DocumentView';
import FieldView from '../../components/molecules/FieldView/FieldView';

export default function FileUPload(props) {
  const {
    onUpload,
    name,
    placeholder,
    labelText,
    required,
    allowedImageType,
    disabled,
    fileRes,
    infoIcon,
    isReadOnly,
    value,
  } = props;
  const type = fileRes?.mimeType?.split('/');
  if (isReadOnly) {
    return (
      <FieldView
        labelText={labelText}
        labelValue={<DocumentView name={name} info={value} />}
      />
    );
  }
  return (
    <>
      {labelText && (
        <Typography
          variant="body2"
          color={`${disabled ? 'neutral.light40' : 'neutral.dark80'}`}
          mb={1}
        >
          {labelText}
          {required && (
            <Box color="accentSecondary.main" sx={{ display: 'inline-block' }}>
              *
            </Box>
          )}
          {infoIcon && (
            <LightTooltip title={infoIcon} placement="top" arrow>
              <Box color="primary.main" sx={{ display: 'inline-block' }}>
                <InfoOutlined
                  sx={{ width: 14, height: 14, ml: '4px', cursor: 'pointer' }}
                />
              </Box>
            </LightTooltip>
          )}
        </Typography>
      )}
      <Box display="flex" gap={2} alignItems="center">
        <UploadBox>
          <button disabled={disabled}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2" color={'neutral.dark40'}>
                {placeholder}
              </Typography>
              <FileUploadOutlinedIcon
                sx={{
                  height: '18px',
                  mt: '3px',
                  color: disabled ? 'neutral.dark40' : 'neutral.dark80',
                }}
              />
            </Box>
          </button>
          <input
            type="file"
            onChange={onUpload}
            name={name}
            accept={allowedImageType}
            disabled={disabled}
          />
        </UploadBox>
        {fileRes && (
          <Box>
            <a href={fileRes?.url} download={fileRes?.fileName}>
              <Button
                variant="text"
                sx={{
                  maxWidth: 100,
                  justifyContent: 'flex-end',
                  overflow: 'hidden',
                }}
                title={fileRes?.fileName}
              >
                {type?.[0] === 'image' ? (
                  <img src={fileRes?.url} height={30} alt={fileRes?.fileName} />
                ) : (
                  fileRes?.fileName
                )}
              </Button>
            </a>
          </Box>
        )}
      </Box>
    </>
  );
}

const UploadBox = styled(Box)({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',

  '& input[type=file]': {
    position: 'absolute',
    left: '0',
    top: '0',
    right: '0',
    bottom: '0',
    width: '100%',
    opacity: '0',
    cursor: 'pointer',
  },
  '& button': {
    border: '1px solid #95A09E',
    borderRadius: '4px',
    background: 'none',
    padding: '8px 12px',
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer',
  },
});
