const ReasonIssueList = [
  'Typo Mistake',
  'Wrong Amount',
  'Clarify Reasons',
  'Wrong Attachment',
  'Wrong Receipt',
  'Wrong Claim',
  'Missing Approved Proposal',
  'Others'
];

export const AppraisalReasonList = [
  'Wrong Achievement(s)',
  'Wrong Rating Scale',
  'Competency Misinterpretation',
  'Wrong Competency Scale',
  'Missing explaination'
];

export const RequisitionReasonList = [
  'Wrong Approver',
  'Not Budgeted',
  'Incorrect Salary',
  'Wrong JD',
  'Incorrect Requisition',
];

export default ReasonIssueList;
