import { Box, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNewRole, getRolesList } from '../../../../redux/onboarding/action';
import { isEmpty } from 'lodash';
import CardView from '../../../../atoms/DataList/CardView';
import ListView from '../../../../atoms/DataList/ListView';
import { FormHeader } from '../../common/formHeader';
import { EditSettings } from './ConfigureRole/EditSettings';
import { APIRequestStatus, LoadingStatus, ToastThemes } from '../../../../constants/common';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { isNotEmptyString, roleListHeader, scrollToNewRow } from '../utils/onboarding.util';
import { ToastMessages, nameValidationErrorMessage, stringSubstitute } from '../constants/onboarding.constants';
import { showToast } from '../../../../utils/common.util';
import { rolesAndPermission as rolesAndPermissionConst } from '../constants/onboarding.constants';
import ModalDialog from '../../../../atoms/Modal/ModalDialog';
import TryAgain from '../../../molecules/ErrorState/error.component';


export default function RolesPermissions() {

  const dispatch = useDispatch();

  const [showConfigure, setShowConfigure] = useState(false);
  const [roleConfig, setRoleConfig] = useState({});
  const [newValue, setNewValue] = useState('');
  const [allowAdd, setAllowAdd] = useState(true);
  const [showModal, setshowModal] = useState(false);
  const [context, setContext] = useState();
  const [tableRef, setTableRef] = useState();

  const rolesList = useSelector((state) => state.onBoarding.rolesList) || [];
  const { permissions, requestProcessing: { [APIRequestStatus.GET_ROLES_LIST]: rolesListLoading,
    [APIRequestStatus.CREATE_ROLE]: createRoleLoading } } = useSelector((state) => state.onBoarding);

  useEffect(() => {
    if (isEmpty(rolesList))
      dispatch(getRolesList());
  }, []);

  useEffect(() => {
    if (createRoleLoading === LoadingStatus.Success) {
      scrollToNewRow(tableRef);
    }
  }, [createRoleLoading]);


  const handleConfigureClick = (name, id) => {
    setShowConfigure(true);
    setRoleConfig({ roleName: name, id });
  };

  const handleInputChange = (event) => {
    const name = event.target.value;
    if (!rolesList.find((item) => item.role_name === name) && (isNotEmptyString(name))) {
      setAllowAdd(true);
    }
    else {
      setAllowAdd(false);
    }
    setNewValue(name);
  };

  const handleAddClick = () => {
    dispatch(addNewRole(newValue)).then((newRoleId) => {
      setRoleConfig({ roleName: newValue, id: newRoleId });
      setShowConfigure(true);
      setNewValue('');
    }).catch((err) => {
      showToast(err, ToastThemes.error);
    });
  };

  const handleDiscardClick = () => {
    setShowConfigure(false);
    setNewValue('');
  };
  const onClickAddIcon = () => {
    setshowModal(true);
    setContext('add');
  };
  const onModalClose = () => {
    setshowModal(false);
    setAllowAdd(true);
    setNewValue('');
  };

  const renderLayout = () => {
    if (showConfigure) {
      return (
        <EditSettings
          roleConfig={roleConfig}
          setShowConfigure={setShowConfigure}
          onClickDiscard={handleDiscardClick}
        />
      );
    }
    return (
      <Box>
        <FormHeader title='Roles' />
        {rolesList?.length < 9 ?
          <Grid container rowSpacing={3} columnSpacing={10}>
            {rolesList.map((item) => {
              const { id, role_name, userCount } = item;
              return (
                <Grid key={id} item xs={6} md={4}>
                  <CardView
                    id={id}
                    title={role_name}
                    buttonText='Configure'
                    onClick={() => handleConfigureClick(role_name, id)}
                    count={userCount}
                    countBox={true}
                    primaryButtonDisabled={!permissions[rolesAndPermissionConst]?.allowEdit}
                    deleteButtonDisabled={!permissions[rolesAndPermissionConst]?.allowEdit}
                  />
                </Grid>
              );
            })}
            {permissions[rolesAndPermissionConst]?.allowAdd && (
              <Grid item xs={6} md={4}>
                <CardView
                  id='new_input_role'
                  placeholder='Add_role'
                  buttonText='Configure'
                  onClick={handleAddClick}
                  count={0}
                  editable={true}
                  onChange={handleInputChange}
                  value={newValue}
                  primaryButtonDisabled={!allowAdd || !newValue}
                  helperText={allowAdd ? '' : nameValidationErrorMessage}
                  countBox={true}
                />
              </Grid>
            )}
          </Grid> :
          <ListView
            headCells={roleListHeader}
            rowsData={roleList}
            onAddClick={() => onClickAddIcon()}
            showSerialNo={true}
            setTableRef={setTableRef}
            showAddIcon={permissions[rolesAndPermissionConst]?.allowAdd}
            addDisabled={!permissions[rolesAndPermissionConst]?.allowAdd}
          />
        }
      </Box>
    );
  };
  const roleList = rolesList?.map((role, index) => {
    const { id, role_name, userCount } = role;
    if (!permissions[rolesAndPermissionConst]?.allowEdit) {
      return {
        name: role_name || '',
        userCount: userCount || '',
      };
    }
    return {
      name: role_name || '',
      userCount: userCount || '',
      actions: <>
        <IconButton onClick={() => handleConfigureClick(role_name, id)}
          disabled={!permissions[rolesAndPermissionConst]?.allowEdit}>
          <EditOutlinedIcon /></IconButton>
      </>
    };
  });
  const modalMap = {
    add: {
      title: 'Add Role',
      componentProps: {
        onInputChange: handleInputChange,
        inputValue: newValue,
        inputPlaceHolder: 'Add_role',
        helperText: allowAdd ? '' : nameValidationErrorMessage,
        countBox: true
      },
      primaryAction: 'Configure',
      onClickConfirm: () => handleAddClick(),
      severity: 'success'
    }
  };
  if (rolesListLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  } 
    return (
      <Box>
        <CircularLoader show={rolesListLoading === LoadingStatus.Loading} />
        {renderLayout()}
        <ModalDialog
          severity={modalMap[context]?.severity}
          title={modalMap[context]?.title}
          description={modalMap[context]?.description}
          primaryAction={modalMap[context]?.primaryAction}
          secondaryAction="Cancel"
          showModal={showModal}
          onClickClose={() => onModalClose()}
          onClickConfirm={modalMap[context]?.onClickConfirm}
          primaryButtonDisabled={!allowAdd}
          component={context !== 'delete'}
          componentProps={modalMap[context]?.componentProps}
        />
      </Box>
    );
}