import React, { useState } from 'react';
import ModalDialog from '../../../../../../atoms/Modal/ModalDialog';
import DropDownOptionForPayStr from './DropDownOptionForPayStr';
import { SalaryStructureServices } from '../../../../../../services/salaryServices';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../../../../../utils/common.util';
import { ToastMessages, stringSubstitute } from '../../../constants/onboarding.constants';
import { LoadingStatus, ToastThemes, toastMessage } from '../../../../../../constants/common';
import { getAllSalaryStructureList } from '../../../../../../redux/salaryStructure/action';
import CircularLoader from '../../../../../../atoms/CircularLoader/circular-loader';

const ModalMapForPayStr = (props) => {

	const { checkedItems, searchInput } = props;
	const [showModal, setshowModal] = useState(false);
	const [context, setContext] = useState('');
	const dispatch = useDispatch();
	const { currentUserRoleId } = useSelector((state) => state.main);
	const [publishLoading, setPublishLoading] = useState(-1);

	const handlePublish = () => {
		setContext('publish');
		setshowModal(true);
	};

	const handlePublishClick = () => {
		setPublishLoading(LoadingStatus.Loading);
		new SalaryStructureServices().postPublishIdIRequest({ payStrToBePublished: checkedItems.map(Number) }).then(() => {
			dispatch(getAllSalaryStructureList(currentUserRoleId, searchInput));
			showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.update), ToastThemes.success);
			setPublishLoading(LoadingStatus.Success);
		}).catch(() => {
			setPublishLoading(LoadingStatus.Failure);
			showToast(ToastMessages.failed.replace(stringSubstitute, toastMessage.errorupdate), ToastThemes.error);
		});
	};

	const modalMap = {
		publish: {
			title: 'Publish',
			description: <div>This will publish the selected pay structure.<br /><br /> Contiune?</div>,
			primaryAction: 'Publish',
			onClickConfirm: () => handlePublishClick(),
			severity: 'success',
		}
	};
	return (
		<>
			<CircularLoader show={publishLoading === LoadingStatus.Loading} />
			<DropDownOptionForPayStr handlePublish={handlePublish} checkedItems={checkedItems} />
			<ModalDialog
				severity={modalMap[context]?.severity}
				title={modalMap[context]?.title}
				listComponent={modalMap[context]?.listComponent}
				description={modalMap[context]?.description}
				primaryAction={modalMap[context]?.primaryAction}
				secondaryAction="Cancel"
				showModal={showModal}
				onClickClose={() => setshowModal(false)}
				onClickConfirm={modalMap[context]?.onClickConfirm}
				maxWidth={modalMap[context]?.maxWidth}
				fullWidth={modalMap[context]?.fullWidth}
				actionButtonDisabled={modalMap[context]?.actionButtonDisabled}
			/>
		</>
	);
};

export default ModalMapForPayStr;