import React, { useEffect, useState } from 'react';
import StatusLabel from '../molecules/StatusLabel/StatusLabel';
import { Box, Paper, Typography } from '@mui/material';
import { STATUS_ICON } from '../claim-view/claim-audit-log';
import moment from 'moment';

const approvalFlow = {
    steps: [
        {
            stepNumber: 0,
            approver: 83,
            approver_role: 3,
        },
        {
            stepNumber: 1,
            approver: { name: 'Shubham' },
            approver_role: 3,
        },
        {
            stepNumber: 2,
            approver: { name: 'Rajiv' },
            approver_role: 3,
        },
        {
            stepNumber: 3,
            approver: { name: 'Jay' },
            approver_role: 3,
        },
    ],
    total_steps: 4,

};

const apprasalLogs = [
    {
        created_by: {
            firstName: 'Rajiv',
            lastName: 'S'
        },
        createdUtc: Date.now(),
        remarkss: '',
        comment: 'Activated',
        status: 'ACTIVE',
        icon: '',
    },
    {
        created_by: {
            firstName: 'Shubham',
            lastName: 'Lal'
        },
        createdUtc: Date.now(),
        remarkss: '',
        comment: 'Form Submited',
        status: 'Submited',
        icon: '',
    },
    {
        created_by: {
            firstName: 'Rajiv',
            lastName: 'S'
        },
        createdUtc: Date.now(),
        remarkss: '',
        comment: 'Activated',
        status: 'APPROVED',
        remarks: 'Worked On New Module',
        icon: '',
    },
];

export default function AuditLogs({ flows = approvalFlow, logs = apprasalLogs, currentStep = 1, }) {

    const [allLogs, setAllLogs] = useState([]);

    useEffect(() => {
        const remaningFlows = flows?.steps?.filter(step => step.stepNumber >= currentStep).sort((a, b) => a.stepNumber - b.stepNumber);
        const trail = logs.sort((a, b) => (new Date(a.createdUtc) - new Date(b.createdUtc))).map(log => {
            const lg = { ...log };
            if (log.createdBy) {
                lg.created_by = log.createdBy;
            }
            if (log.actionComment) {
                lg.comment = log.actionComment;
            }
            if (log.fkApprovalActionId) {
                lg.status = log.fkApprovalActionId?.name;
            }
            return lg;
        }) || [];
        if (remaningFlows) {
            remaningFlows.forEach(flow => {
                trail.push({
                    created_by: {
                        firstName: flow.approver.name,
                        lastName: ''
                    },
                    role: flow.approver.role,
                    remarkss: '',
                    comment: '',
                    status: 'PENDING',
                    icon: '',
                });
            });
        }

        setAllLogs(trail);
    }, [flows, logs, currentStep]);

    return (
        <>
            {allLogs.map((log, i) => (
                <Box key={i} maxWidth={360} mb={2}>
                    <Paper>
                        <Box p={1} position='relative'>
                            <StatusLabel value={log.status} type={String(log.status).toLowerCase()} />
                            <Paper
                                sx={{
                                    position: 'absolute',
                                    left: -68,
                                    top: 0,
                                    width: 40,
                                    height: 40,
                                    borderRadius: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {STATUS_ICON[log.status]}
                            </Paper>
                            <Typography variant='body2' color={'neutral.dark100'} mt={1} mb={0}>
                                <b>Name: </b>{log.created_by ? `${log.created_by?.firstName} ${log.created_by?.lastName}` : log.name}
                            </Typography>
                            {log.role &&
                                <Typography variant='body2' color={'neutral.dark100'} mt={1} mb={0}>
                                    <b>Role: </b>{log.role}
                                </Typography>
                            }
                            <Typography variant='body3' color={'neutral.dark70'} mt={1} mb={0}>
                                <b>Comment: </b>{log.comment || ''}
                            </Typography>
                            {log.createdUtc && (
                                <>
                                    {log.remarks && (
                                        <Box>
                                            <Typography variant='body3' color={'neutral.dark80'}>
                                                <b>Remarks: </b>{log.remarks}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box>
                                        <Typography variant='body3' color={'neutral.dark60'} mb={0}>
                                            <b>Date: </b> {moment(log.createdUtc).format('DD-MM-YYYY hh:mm a')}
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Paper>
                </Box>
            ))}
        </>
    );
}


export const AuditLogWithWrapper = (props) => {
    return (
        <Box
            ml={6}
            pl={6}
            sx={{ borderLeft: 'solid 1px', borderColor: 'neutral.dark40' }}
            mb={3}
            mt={2}
        >
            <Typography variant='subtitle2' color={'neutral.dark80'} mb={3}>
                Audit Trail
            </Typography>

            <Box>
                <AuditLogs {...props} />
            </Box>
        </Box>
    );
};
