import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './tab-head.css';
import { FilterAltOffOutlined } from '@mui/icons-material';

function TabPanel(props) {
  const { children, value, index, placeholder, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function TabHead(props) {
  const { filterDataRoles, placeholder, onClickHeader, onClickClear } = props;
  const [value, setValue] = useState(-1);
  const [values, setValues] = useState(null);
  const [inputValue, setInputValue] =useState('');
  
  const handleChange = (id, index) => {
    setValue(index);
    onClickHeader(id);
  };

  const handleClear = () => {
    setValue(-1);
    onClickClear();
    setInputValue('');
  };

  useEffect(() =>{
    if(values?.name?.length===undefined){
      handleClear();
    }
  }, [values]);

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'neutral.light100',
        boxShadow: '0px 2px 4px rgba(51, 51, 51, 0.15)',
      }}
    >
      <Box
        borderRadius={1}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bgcolor: 'neutral.light80',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: 'calc(100% - 160px)',
          }}
        >
          <Box width={200} sx={{ position: 'relative' }} mr={2}>
            <Autocomplete
              id='free-solo-demo'
              freeSolo
              options={filterDataRoles}
              getOptionLabel = {(option) => (option ? option.name : '')}
              value={values}
              onChange={(event, newValue) => {
                setValues(newValue);
                handleChange(newValue?.id,filterDataRoles.indexOf(newValue));
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);}}
              renderInput={(params) => (
                <StyledTextField
                  size='small'
                  {...params}
                  placeholder={placeholder}
                />
              )}
            />
            <ExpandMoreIcon
              sx={{
                position: 'absolute',
                top: '11px',
                right: '10px',
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', maxWidth: '80%', overflow: 'auto' }}>
            {filterDataRoles.map((item, index) => {
              const { id, name } = item;
              return (
                <Box
                  onClick={() => handleChange(id, index)}
                  key={id} py={2} px={3}
                  className={`ve-tab-head ${value === index ? 'selected' : ''}`}
                  sx={{
                    cursor: 'pointer',
                    borderRadius: '8px 8px 0 0'
                  }}>
                  <Typography
                    variant='body1'
                    color={'neutral.dark'}
                    sx={{ whiteSpace: 'nowrap' }}
                    className={`ve-tab-label ${value === index ? 'selected' : ''}`}
                  >
                    {name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box onClick={handleClear} py={2} px={3} sx={{ cursor: 'pointer' }}>
          <Button
            variant='link'
            color={'neutral.dark'}
            sx={{ whiteSpace: 'nowrap' }}
            startIcon={<FilterAltOffOutlined />}
          >
            Clear
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    paddingTop: '2px',
    paddingBottom: '0px',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0',
  },

  '& .MuiAutocomplete-endAdornment': {
    display: 'none',
  },
});
