import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Link, Divider, Button, IconButton } from '@mui/material';
import InputField from '../Input/Input';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import StyledDelete from '../StyledDelete/StyledDelete';


export default function CardView(props) {
  const { title, onChange, name, placeholder, editable, id, count, onClick, primaryButtonDisabled, 
    buttonText, value, helperText, countBox, onClickDelete, deleteButtonDisabled } = props;

  return (
    <Box
      sx={{
        bgcolor: 'neutral.light100',
        boxShadow: '0px 2px 4px rgba(51, 51, 51, 0.15)',
        borderRadius: 1,
      }}
      p={1}
    >
      <Box id={id} p={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {!editable ?
          <Typography variant="body2Bold" color={'neutral.dark80'} sx={{lineHeight:2}}>
            {title}
          </Typography> :
          <Box sx={{ width: '100%', marginBottom: '-8px', marginLeft: '-8px', }} >
            <InputField
              variant="standard"
              onChange={onChange}
              name={name}
              placeholder={placeholder}
              size='small' value={value}
              helperText={helperText}
              error={helperText}
              hiddenLabel
              fullWidth
              multiline
              required />
          </Box>
        }

        {countBox && <Box
          sx={{
            bgcolor: 'primary.main40',
            borderRadius: 3,
            padding: '0 10px',
            height: '23px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="caption" color={'primary.main'}>
            {count}
          </Typography>
        </Box>}
      </Box>
      <Divider />
      {/* buttonText=> Text which appears on the button in card view */}
      {buttonText &&
        <Box p={1} pt={5} sx={{display:'flex', justifyContent:'space-between'}}>
          <StyledButton onClick={onClick} disabled={primaryButtonDisabled}>{buttonText}</StyledButton>
          <StyledDelete onClick={onClickDelete} disabled={deleteButtonDisabled}>
            <DeleteOutlineOutlinedIcon />
          </StyledDelete>
        </Box>}
    </Box>
  );
}

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutral.dark80,
  backgroundColor: theme.palette.neutral.light60,
  textTransform: 'capitalize',
  minWidth: 140,
  '&:hover': {
    backgroundColor: theme.palette.neutral.light80,
  },
}));
