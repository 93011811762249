import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import { GET_ALL_SALARY_STRUCTURE_LIST_FAILURE, GET_ALL_SALARY_STRUCTURE_LIST_REQUEST, GET_ALL_SALARY_STRUCTURE_LIST_SUCCESS, GET_SALARY_STRUCTURE_CONFIG_FAILURE, GET_SALARY_STRUCTURE_CONFIG_REQUEST, GET_SALARY_STRUCTURE_CONFIG_SUCCESS, POST_SALARY_STRUCTURE_INFO_FAILURE, POST_SALARY_STRUCTURE_INFO_REQUEST, POST_SALARY_STRUCTURE_INFO_SUCCESS, RESET_SALARY_STRUCTURE } from './actionTypes';

const initialState = {
  requestProcessing: {
    [APIRequestStatus.GET_SALARY_STRUCTURE_CONFIG]: LoadingStatus.Loading,
    [APIRequestStatus.POST_SALARY_STRUCTURE_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.GET_ALL_SALARY_STRUCTURE_LIST]: LoadingStatus.Loading,
  },
  salaryStructureConfig: [],
  salaryStructureFormData: {},
  salaryStructureList: [],
};

export const salaryStructureReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_SALARY_STRUCTURE_CONFIG_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_SALARY_STRUCTURE_CONFIG]: LoadingStatus.Loading
        }
      };
    }

    case GET_SALARY_STRUCTURE_CONFIG_SUCCESS: {
      const { res, fromLib, newReq } = action.payload;
      const { formConfig, payStructureInfo } = res;
      const { id, createdUtc, ...rest } = payStructureInfo;
      return {
        ...state,
        salaryStructureConfig: formConfig,
        salaryStructureFormData: fromLib && newReq ? rest : payStructureInfo,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_SALARY_STRUCTURE_CONFIG]: LoadingStatus.Success
        }
      };
    }

    case GET_SALARY_STRUCTURE_CONFIG_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_SALARY_STRUCTURE_CONFIG]: LoadingStatus.Failure
        }
      };
    }

    case POST_SALARY_STRUCTURE_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_SALARY_STRUCTURE_INFO]: LoadingStatus.Loading
        }
      };
    }

    case POST_SALARY_STRUCTURE_INFO_SUCCESS: {
      const { salaryStructureFormInfo, id } = action.payload;
      id && Object.assign(salaryStructureFormInfo, { id });
      return {
        ...state,
        salaryStructureFormData: salaryStructureFormInfo,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_SALARY_STRUCTURE_INFO]: LoadingStatus.Success
        }
      };
    }

    case POST_SALARY_STRUCTURE_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_SALARY_STRUCTURE_INFO]: LoadingStatus.Failure
        }
      };
    }

    case GET_ALL_SALARY_STRUCTURE_LIST_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_SALARY_STRUCTURE_LIST]: LoadingStatus.Loading
        }
      };
    }

    case GET_ALL_SALARY_STRUCTURE_LIST_SUCCESS: {
      const salaryStructureList = action.payload;
      return {
        ...state,
        salaryStructureList: salaryStructureList,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_SALARY_STRUCTURE_LIST]: LoadingStatus.Success
        }
      };
    }

    case GET_ALL_SALARY_STRUCTURE_LIST_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_SALARY_STRUCTURE_LIST]: LoadingStatus.Failure
        }
      };
    }

    case RESET_SALARY_STRUCTURE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

