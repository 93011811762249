import React from 'react';
import Navbar from '../navigation/Navbar';
import Sidebar from '../../shared/navigation/Sidebar';
import Navigation from '../navigation/Navigation';

const Layout = (props) => {
  return (
    <React.Fragment>
      {props.isLoggedIn && (
        <React.Fragment>
          <Sidebar>
            <Navigation />
          </Sidebar>
          <Navbar isSidebarOpen={props.isSidebarOpen} setIsSidebarOpen={props.setIsSidebarOpen}/>

        </React.Fragment>
      )}
      {props.children}
    </React.Fragment>
  );
};

export default Layout;
