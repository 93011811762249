import React from 'react';
import FormView from '../../../../../../../OnBoarding/common/Form/FormView';

const InterviewFormView = (props) => {
    const { list, info } = props;
    return (
        <FormView
            list={list}
            info={info}
        />
    );
};

export default InterviewFormView;