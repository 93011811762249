import React, { useState } from 'react';
import ApprovalWorkflowRejectModal from '../../OnBoarding/Organization/ApprovalFlow/Modals/RejectModal';
import ApprovalWorkflowApproveModal from '../../OnBoarding/Organization/ApprovalFlow/Modals/ApproveModal';
import { Box, Grid } from '@mui/material';
import { isNotEmptyString } from '../../OnBoarding/Organization/utils/onboarding.util';
import ReasonIssueList from '../../../constants/Reasons';
import ClaimsActionHeader from '../../claims/ClaimsActionHeader';
import {
  CheckCircleOutlineOutlined,
  DownloadOutlined,
  SwipeLeftOutlined,
  HistoryOutlined,
} from '@mui/icons-material';
import { ViewAccess } from '../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import { useDispatch } from 'react-redux';
import { getClaimList } from '../../../redux/newClaims/action';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import { BuilderServices } from '../../../services/builder';
import { CLAIM_STATUS } from '../../claim-view/claim-audit-log';
import { showToast } from '../../../utils/common.util';
import { ToastThemes } from '../../../constants/common';
import { getFormattedValues } from '../../../utils/formatter';

const ApproveRejectModal = ({
  currentUserRoleId,
  checkedItems,
  moduleId,
  viewAccess,
  claimTypeId,
  searchQuery,
  selectedDate,
  approvalRemarksPermissions,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [approvalRemarks, setApprovalRemarks] = useState('');
  const dispatch = useDispatch();
  const updatedCheckedItem = checkedItems.map((item) => {
    const { attachments, ...itemWithoutAttachments } = item;
    return itemWithoutAttachments;
  });

  const submitApprovalRejection = async (body) => {
    const { remark, reasons, action } = body;
    try {
      setLoading(true);
      const response = await new BuilderServices()
        .bulkApproveOrRejectApprovalWorflow(
          updatedCheckedItem,
          moduleId,
          action,
          remark,
          reasons,
          currentUserRoleId,
          claimTypeId
        )
        .then(() => {
          dispatch(
            getClaimList(
              claimTypeId,
              currentUserRoleId,
              searchQuery,
              getFormattedValues('YYYY-MM-DD', selectedDate[0]),
              getFormattedValues('YYYY-MM-DD', selectedDate[1])
            )
          ).finally(() => {
            setLoading(false);
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setApprovalRemarks('');
        });
      return { success: response, error: null };
    } catch (error) {
      setModalTitle('Error');
      setModalDescription(error?.response?.data?.message || error.message);
      return {
        success: false,
        error: error?.response?.data?.message || error.message,
      };
    }
  };

  const handleSubmit = async (body) => {
    try {
      return await submitApprovalRejection(body);
    } catch (err) {
      console.error(err);
    }
  };

  const handleApproveRequest = () => {
    if (!approvalRemarksPermissions?.isApprovalRemarksRequired) {
      handleSubmit({
        updatedCheckedItem,
        moduleId,
        action: 'APPROVED',
        reasons: [],
      });
      return;
    }
    setModalTitle('Approve');
    setApproveModal(true);
  };

  const handleCloseModel = () => {
    setApproveModal(false);
    setModalTitle('');
    setModalDescription('');
  };

  const handleBulkStatusUpdate = async () => {
    setLoading(true);
    await new BuilderServices()
      .bulkStatusUpdate({
        records: updatedCheckedItem.map((item) => item?.id),
        status: CLAIM_STATUS.REVERSE,
        claimTypeId,
        moduleId,
      })
      .then(() => {
        dispatch(
          getClaimList(
            claimTypeId,
            currentUserRoleId,
            searchQuery,
            getFormattedValues('YYYY-MM-DD', selectedDate[0]),
            getFormattedValues('YYYY-MM-DD', selectedDate[1])
          )
        );
        showToast('Success', ToastThemes.success);
      })
      .catch(() => {
        showToast('Something Went Wrong', ToastThemes.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDownloadClick = async () => {
    for (const item of checkedItems) {
      const { attachments, referenceNumber } = item;
      if (attachments && attachments[0]?.url !== null && attachments.length) {
        for (let index = 0; index < attachments.length; index++) {
          const downloadLink = attachments[index]?.url;
          const fileExtension = attachments[index]?.mimeType;
          if (downloadLink) {
            try {
              const response = await fetch(downloadLink);
              if (!response.ok) {
                throw new Error(`Failed to download file: ${downloadLink}`);
              }
              const fullFileExtension = fileExtension?.split('/');
              const blob = await response.blob();
              const url = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = `${referenceNumber}-${index + 1}.${
                fullFileExtension[1]
              }`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
            } catch (error) {
              console.log(
                `Error downloading file: ${referenceNumber}-${index + 1}`,
                error
              );
            }
          }
        }
      } else {
        showToast('No File To Download', ToastThemes.error);
      }
    }
  };

  const buttonOptions = [
    {
      title: 'Approve',
      handleClick: handleApproveRequest,
      icon: <CheckCircleOutlineOutlined sx={{ width: 20, height: 20 }} />,
      hide:
        viewAccess !== ViewAccess.APPROVER_APPROVE_ACCESS ||
        checkedItems.some((item) => item.status !== CLAIM_STATUS.PENDING),
      disabled:
        !checkedItems.length ||
        checkedItems.some((item) => item.status !== CLAIM_STATUS.PENDING),
    },
    {
      title: 'Send Back',
      handleClick: () => setOpenModal(true),
      icon: <SwipeLeftOutlined sx={{ width: 20, height: 20 }} />,
      hide:
        viewAccess !== ViewAccess.APPROVER_APPROVE_ACCESS ||
        checkedItems.some((item) => item.status !== CLAIM_STATUS.PENDING),
      disabled:
        !checkedItems.length ||
        checkedItems.some((item) => item.status !== CLAIM_STATUS.PENDING),
    },
    {
      title: 'Download',
      handleClick: handleDownloadClick,
      icon: <DownloadOutlined sx={{ width: 20, height: 20 }} />,
      hide:
        (viewAccess !== ViewAccess.APPROVER_APPROVE_ACCESS &&
          viewAccess !== ViewAccess.ALL_APPROVED_ACCESS) ||
        checkedItems.some(
          (item) =>
            item.status !== CLAIM_STATUS.APPROVED &&
            item.status !== CLAIM_STATUS.SENT_TO_PAYROLL
        ),
      disabled:
        !checkedItems.length ||
        checkedItems.some((item) => item.status === CLAIM_STATUS.DRAFT),
    },
    {
      title: 'Reverse',
      handleClick: handleBulkStatusUpdate,
      icon: <HistoryOutlined sx={{ width: 20, height: 20 }} />,
      hide:
        (viewAccess !== ViewAccess.APPROVER_APPROVE_ACCESS &&
          viewAccess !== ViewAccess.ALL_APPROVED_ACCESS) ||
        checkedItems.some(
          (item) =>
            item.status !== CLAIM_STATUS.APPROVED &&
            item.status !== CLAIM_STATUS.SENT_TO_PAYROLL
        ),
      disabled:
        !checkedItems.length ||
        checkedItems.some((item) => item.status === CLAIM_STATUS.DRAFT),
    },
  ];

  return (
    <>
      <CircularLoader show={loading} />
      <ClaimsActionHeader
        buttonOptions={buttonOptions.filter((item) => !item.hide)}
      />
      <ApprovalWorkflowRejectModal
        handleSubmit={handleSubmit}
        open={openModal}
        setOpen={setOpenModal}
        reasonsList={ReasonIssueList}
        recordId={updatedCheckedItem}
        moduleId={moduleId}
        approvalRemarksPermissions={approvalRemarksPermissions}
      />
      <Grid item md={12}>
        <ApprovalWorkflowApproveModal
          showModal={approveModal}
          component
          componentProps={{
            onInputChange: (event) => setApprovalRemarks(event.target.value),
            inputValue: approvalRemarks,
            inputPlaceHolder: 'Add Remarks',
          }}
          severity={'success'}
          onClickClose={handleCloseModel}
          primaryAction={'Approve'}
          primaryButtonDisabled={!isNotEmptyString(approvalRemarks)}
          onClickConfirm={() =>
            handleSubmit({
              updatedCheckedItem,
              moduleId,
              action: 'APPROVED',
              remark: approvalRemarks,
              reasons: [],
            })
          }
          title={'Approve'}
          description={
            <>
              {modalDescription && (
                <>
                  {modalDescription}
                  <br />
                </>
              )}
            </>
          }
        />
      </Grid>
    </>
  );
};

export default ApproveRejectModal;
