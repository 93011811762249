import {Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import ModalDialog from '../../../../../../atoms/Modal/ModalDialog';
import { BuilderServices } from '../../../../../../services/builder';
import { EntitlementsService } from '../../../../../../services/entitlementsService';
import { benefitTypeConfigurationFields } from './benefit-type-configuration.utils';
import ApiSelectTag from '../../../../../../atoms/Select/ApiSelectTag';
import { clone } from 'lodash';
import CircularLoader from '../../../../../../atoms/CircularLoader/circular-loader';
import { showToast } from '../../../../../../utils/common.util';
import { ToastThemes } from '../../../../../../constants/common';

const BenefitTypesConfigration = (props) => {
  const { roleId } = props;
  const [formInfo, setFormInfo] = useState({});
  const [benefitTypes, setBenefitTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState({});
  const [loading,setLoading] = useState(false);
  const getDropdownOptions = () => {
    return benefitTypes;
  };

  const benefitBtnClick = () => {
    setShowModal(true);
    new EntitlementsService()
      .getBenfitsRole(roleId)
      .then((res) => {
        setFormInfo({[benefitTypeConfigurationFields.benefitTypes]:res});
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDropdownClick = async () => {
    return new BuilderServices().getDropDownBenifits().then((res) => {
      setBenefitTypes(res);
      return true;
    });
  };

  const handleSaveClick = async () => {
    setLoading(true);
    const benefitIds = formInfo?.[
      benefitTypeConfigurationFields.benefitTypes
    ]?.map((item) => Number(item.id));
    new EntitlementsService()
      .saveBenfitsRole(benefitIds, roleId)
      .then((res) => {
        showToast('Saved Successfully', ToastThemes.success);
      })
      .catch((err) => {
        showToast(err?.response?.data?.message, ToastThemes.error);
      }).finally(()=>{
        setLoading(false);
      });
  };

  const onChangeBenefit = (event) => {
    const key = event.target.name;
    const selected = event.target.value;
    const newVal = selected[selected.length - 1];
    const list = getDropdownOptions();
    if (newVal?.id === 'all' && isAllSelected?.[key]) {
      setFormInfo((prev) => ({
        ...prev,
        [key]: [],
      }));
      setIsAllSelected((prev) => ({
        ...prev,
        [key]: false,
      }));
    } else if (newVal?.id === 'all' && !isAllSelected?.[key]) {
      const newList = clone(list || []);
      const index = newList.findIndex((item) => item.id === newVal.id);
      if (index !== -1) {
        newList.splice(index, 1);
      }
      setFormInfo((prev) => ({
        ...prev,
        [key]: newList,
      }));
      setIsAllSelected((prev) => ({
        ...prev,
        [key]: newList.length > 0 && list?.length === newList.length,
      }));
    } else {
      const currentVal = clone(formInfo?.[key] || []);
      const index = currentVal.findIndex((item) => item.id === newVal.id);
      if (index !== -1) {
        currentVal.splice(index, 1);
      } else {
        currentVal.push(newVal);
      }
      setFormInfo((prev) => ({
        ...prev,
        [key]: currentVal,
      }));
      setIsAllSelected((prev) => ({
        ...prev,
        [key]: currentVal?.length > 0 && list?.length === currentVal?.length,
      }));
    }
  };

  return (
    <>
      <CircularLoader show={loading}/>
      <Button onClick={benefitBtnClick}>Configure Benefit Types</Button>
      <ModalDialog
        severity="success"
        title="Configure Benefit Types"
        listComponent={
          <>
            <br />
            <Grid container  columnSpacing={1} maxWidth={'100%'}>
              <Grid item md={12}>
                <ApiSelectTag
                  labelText={'Benefit Types'}
                  onOpen={handleDropdownClick}
                  value={
                    formInfo?.[benefitTypeConfigurationFields.benefitTypes]
                  }
                  dropDownList={benefitTypes}
                  onChangeMultiSelect={onChangeBenefit}
                  name={benefitTypeConfigurationFields.benefitTypes}
                  size="small"
                  fullWidth
                  isMulti={true}
                />
              </Grid>
            </Grid>
          </>
        }
        primaryAction="save"
        secondaryAction="Cancel"
        showModal={showModal}
        onClickClose={() => {
          setShowModal(false);
        }}
        onClickConfirm={handleSaveClick}
        maxWidth={'md'}
      />
    </>
  );
};

export default BenefitTypesConfigration;
