import React, { useEffect, useMemo, useState } from 'react';
import TabBar from '../../atoms/Tabs/TabBar';
import { ClaimServices } from '../../services/claims';
import ClaimList from './ClaimList';
import { errors } from 'joi-browser';
import Loading from '../../atoms/Loading/loading';

export default function ClaimTabs() {
    const [claimsTab, setClaimsTab] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [laoding, setLoading] = useState(true);
    const claimServices = new ClaimServices();
    const getAllClaimsType = async ()=>{
        const response = await claimServices.getAllClaimsType();
        if(response && response.data && response.data.data){
            const data = response.data?.data?.map((claimType)=>({
                id: claimType.id,
                name: claimType.name,
                title: claimType.name,
                label: claimType.name,
                hide: !claimType.is_active, 
                panel: <ClaimList key={claimType.id} claimTypeId={claimType.id} claimTypeName={claimType.name} />
            }));
            setClaimsTab(data);
        }
        return response;
    };

    useEffect(()=>{
        getAllClaimsType().catch(error=>console.log('error while fetching the details', error)).finally(()=>setLoading(false));
    },[]);

    const onTabChange = (tabId)=>{

    };
    const onSearchChange = (e, claimType)=>{
      let index = 0;
      claimsTab.forEach((claimtype, i)=> {
        if(claimtype.id === claimType.id){
            index =i;
        }
      });
      setCurrentTab(index);
    };
    return (
        <Loading loading={laoding} >
            <TabBar
                tabPanels={claimsTab}
                disableCreate={true}
                searchTabs={true}
                onTabChange={onTabChange}
                currentTab={currentTab}
                onSearchChange={onSearchChange}
                tabSearchList={claimsTab}
                // searchTabWidth={350}
                isScroll={true}
            />
        </Loading>
    );
}
