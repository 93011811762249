import { Button, styled } from '@mui/material';

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.neutral.dark,
    backgroundColor: theme.palette.accentTertiary.accent80,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.accentTertiary.accent80,
    },
  }));

export default StyledButton;