import { Box } from '@mui/material';
import React, { forwardRef, memo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AccordionTabs from '../../../../atoms/Accordian/Accordian';
import EducationForm from './EducationForm/EducationForm';
import useDidMountEffect from '../../../../customHooks/useDidMountEffect';
import { EducationFormFields } from '../../Organization/constants/onboarding.constants';
import { OnboardingServices } from '../../../../services/onboarding';
import { CandidateServices } from '../../../../services/candidateServices';
import { CandidateFormFieldNames } from '../../../Recruiting/Constant/constant';
import moment from 'moment';
import { isEmpty } from 'lodash';

const EducationTab = (props) => {
  const { viewMode, eduFormConfig,
    eduValidationErrors, setEduValidationErrors, setEduData, eduData } = props;
  const [locationDropDownOptions, setLocationDropDownOptions] = useState([]);
  const [higherEducationOptions, setHigherEducationOptions] = useState([]);

  const bottomRef = useRef(null);
  useDidMountEffect(() => {
    if (eduFormConfig.length) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [eduFormConfig.length]);

  const handleDropdownClick = (name) => {
    switch (name) {
      case EducationFormFields.Location: {
        return new OnboardingServices().getUserNationality().then((res) => {
          const countryNames = res.map((item) => {
            return { ...item, name: item.countryName };
          });
          setLocationDropDownOptions(countryNames);
          return true;
        });
      }
      case EducationFormFields.Qualification: {
        return new CandidateServices().getDropdownOptions(CandidateFormFieldNames.HigherEdu).then((res) => {
          setHigherEducationOptions(res);
          return true;
        });
      }
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case EducationFormFields.Location: {
        return locationDropDownOptions;
      }
      case EducationFormFields.Qualification: {
        return higherEducationOptions;
      }
    }
  };
  return (
    <Box mr={12} >
      {eduFormConfig.map((educationFormConfig, index) => (
        <div key={index}
          ref={index === eduFormConfig.length - 1 ? bottomRef : null}
        >
          <AccordionTabs
            key={index}
            title={!isEmpty(eduData[index]) ? (
              <div>
                {eduData[index]?.[EducationFormFields.StartDate] ? (
                  (moment(eduData[index]?.[EducationFormFields.StartDate], 'YYYY-MM-DD', true).isValid()) ?
                  moment(eduData[index]?.[EducationFormFields.StartDate], 'YYYY-MM-DD').format('YYYY') :
                  moment(eduData[index]?.[EducationFormFields.StartDate], 'DD/MM/YYYY').format('YYYY')
                ) : ''}-
                {eduData[index]?.[EducationFormFields.EndDate] ? (
                  (moment(eduData[index]?.[EducationFormFields.EndDate], 'YYYY-MM-DD', true).isValid()) ?
                  moment(eduData[index]?.[EducationFormFields.EndDate], 'YYYY-MM-DD').format('YYYY') :
                  moment(eduData[index]?.[EducationFormFields.EndDate], 'DD/MM/YYYY').format('YYYY')
                ) : ''}
                &nbsp;&nbsp;|&nbsp;&nbsp;
                {eduData[index]?.[EducationFormFields.Qualification]?.name}
              </div>
            ) : 'New Tab'}
            tabDetails={
              <Box>
                <EducationForm
                  formConfig={educationFormConfig}
                  tabIndex={index}
                  formData={eduData[index] || []}
                  setEduData={setEduData}
                  numberOfAccordion={eduFormConfig.length}
                  eduValidationErrors={eduValidationErrors[index]}
                  setEduValidationErrors={setEduValidationErrors}
                  viewMode={viewMode}
                  handleDropdownClick={handleDropdownClick}
                  getDropdownOptions={getDropdownOptions}
                />
              </Box>
            }
          />
        </div>
      ))}
    </Box>
  );
};
export default memo(forwardRef(EducationTab));