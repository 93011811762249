import { Box } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { APIRequestStatus, ToastThemes, toastMessage } from '../../../../../../../constants/common';
import { getMandatoryFields, getValidationErrors } from '../../../../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { isEmpty } from 'lodash';
import { showToast } from '../../../../../../../utils/common.util';
import { ToastMessages, stringSubstitute } from '../../../../../../OnBoarding/Organization/constants/onboarding.constants';
import { postCandidateInfo, postCandidateInfoDraft } from '../../../../../../../redux/candidateDetails/action';
import ActionHeader from '../../../../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import FormEdit from '../../../../../../OnBoarding/common/Form/FormEdit';
import { CandidateFormFieldNames, NewCandidate } from '../../../../../Constant/constant';
import { CandidateServices } from '../../../../../../../services/candidateServices';

const CreateCandidate = (props) => {
  const { setViewMode, candidateConfig, isNewCandidate } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { candidateFormData, isPublishedCandidate } =
    useSelector((state) => state.candidate);

  const [validationErrors, setValidationErrors] = useState({});
  const [candidateFormInfo, setCandidateFormInfo] = useState(candidateFormData);

  const [locationDropdownOptions, setLocationDropdownOptions] = useState([]);
  const [sourceDropDownOptions, setSourceDropDownOptions] = useState([]);
  const [referenceDropDownOptions, setReferenceDropDownOptions] = useState([]);
  const [areaOFInterestDropDownOptions, setAreaOFInterestDropDownOptions] = useState([]);
  const [higherEducationOptions, setHigherEducationOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);

  const handleDropdownClick = (name) => {
    switch (name) {
      case CandidateFormFieldNames.HomeLocation:
      case CandidateFormFieldNames.CurrentLocation: {
        return new CandidateServices().getDropdownOptions(name).then((res) => {
          const locations = res.map((item) => {
            return { ...item, name: item.cityName };
          });
          setLocationDropdownOptions(locations);
          return true;
        });
      }
      case CandidateFormFieldNames.HigherEdu: {
        return new CandidateServices().getDropdownOptions(name).then((res) => {
          setHigherEducationOptions(res);
          return true;
        });
      }
      case CandidateFormFieldNames.Language: {
        return new CandidateServices().getDropdownOptions(name).then((res) => {
          setLanguageOptions(res);
          return true;
        });
      }
      case CandidateFormFieldNames.Reference: {
        return new CandidateServices().getDropdownOptions(name).then((res) => {
          const name = res.map((item) => {
            return { ...item, name: item.firstName };
          });
          setReferenceDropDownOptions(name);
          return true;
        });
      }
      case CandidateFormFieldNames.Source: {
        return new CandidateServices().getDropdownOptions(name).then((res) => {
          setSourceDropDownOptions(res);
          return true;
        });
      }
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case CandidateFormFieldNames.HomeLocation:
      case CandidateFormFieldNames.CurrentLocation: {
        return locationDropdownOptions;
      }
      case CandidateFormFieldNames.HigherEdu: {
        return higherEducationOptions;
      }
      case CandidateFormFieldNames.Language: {
        return languageOptions;
      }
      case CandidateFormFieldNames.Reference: {
        return referenceDropDownOptions;
      }
      case CandidateFormFieldNames.Source: {
        return sourceDropDownOptions;
      }
    }
  };

  const requiredFields = useMemo(() => {
    return getMandatoryFields(candidateConfig);
  }, [candidateConfig]);

  const handleSave = () => {
    const errors = getValidationErrors(candidateFormInfo, requiredFields);
    if (isEmpty(errors)) {
      dispatch(postCandidateInfo(candidateFormInfo)).then((res) => {
        showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.success), ToastThemes.success);
        setViewMode(true);
        res.id && navigate(`/recruiting/candidate-details/${res.id}`, { replace: true });
      }).catch((err) => {
        showToast(err, ToastThemes.error);
      });
    } else {
      setValidationErrors(errors);
    }
  };

  const handleDraftClick = () => {
    dispatch(postCandidateInfoDraft(candidateFormInfo)).then((res) => {
      showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.success), ToastThemes.success);
      res.id && navigate(`/recruiting/candidate-details/${res.id}`, { replace: true });
      setViewMode(true);
    }).catch((err) => {
      showToast(err, ToastThemes.error);
    });
  };

  const handleDiscard = () => {
    if (isNewCandidate) {
      navigate('../');
    } else {
      setViewMode(true);
    }
  };

  return (
    <Box>
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={candidateFormData?.[CandidateFormFieldNames.Name] || NewCandidate}
          editButtonText='Draft'
          showDiscard
          showPublish
          showPublishModal={false}
          publishButtonText='Save'
          onPublishClick={handleSave}
          onEditClick={handleDraftClick}
          onClickDiscard={handleDiscard}
          labelCode={candidateFormData?.[CandidateFormFieldNames.Code] || ''}
          showSave={!isPublishedCandidate}
        />
      </Box>
      <FormEdit
        list={candidateConfig}
        formInfo={candidateFormInfo}
        setFormData={setCandidateFormInfo}
        errors={validationErrors}
        setErrors={setValidationErrors}
        handleDropdownClick={handleDropdownClick}
        getDropdownOptions={getDropdownOptions}
      />
    </Box>
  );
};

export default React.memo(CreateCandidate);
