import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import CompetencySkillsItem from './CompetencySkillsItem/CompetencySkillsItem';

const CompetencySkillsForm = (props) => {
  const {heading, values, setValues, competencyRatingScale} = props;
  
  return (
    <>
      <Typography style={{fontWeight: 800}} mt={2}>{heading}</Typography>
      <Box>
        {values.map((item, index) => {
          const { rating, name, id } = item;
          return (
            <CompetencySkillsItem
              key={id}
              label={name}
              id={id}
              rating={rating}
              itemIndex={index}
              setValues={setValues}
              competencyRatingScale={competencyRatingScale}
            />
          );
        })}
      </Box>
    </>

  );
};

export default React.memo(CompetencySkillsForm);