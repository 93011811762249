import { TimeManagement, TimeManagementLabel } from '../../Constant/Constant';

export const timeManagementHeaderCells = (empId, handleClearParams) => [
	{
		id: 'empId',
		label: 'Employee ID',
	},
	{
		id: 'empName',
		label: 'Employee Name',
		showFilterIcon: empId ? true : false,
		clear: (event) => handleClearParams(event)
	},
	{
		id: 'dpt',
		label: 'Department',
	},
	{
		id: 'month',
		label: 'Pay Month',
	},
	{
		id: 'twd',
		label: 'Total Working Days',
	},
	{
		id: 'tpd',
		label: 'Total Pay Days'
	},
	{
		id: 'leaves',
		label: 'Total Leave Days',
	},
	{
		id: 'ot',
		label: 'Overtime Hours',
	},
	{
		id: 'empstatus',
		label: 'Employee Status',
	},
	{
		id: 'mgrstatus',
		label: 'Manager Status',
	},
	{
		id: 'status',
		label: 'Status',
	}
];

export const getTimeManagementTab = [
	{
		name: TimeManagement,
		label: TimeManagementLabel,
		title: TimeManagementLabel,
		hide: false,
	}
];