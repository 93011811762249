import { getFormBody } from '../../components/OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { SalaryStructureServices } from '../../services/salaryServices';
import { GET_ALL_SALARY_STRUCTURE_LIST_FAILURE, GET_ALL_SALARY_STRUCTURE_LIST_REQUEST, GET_ALL_SALARY_STRUCTURE_LIST_SUCCESS, GET_SALARY_STRUCTURE_CONFIG_FAILURE, GET_SALARY_STRUCTURE_CONFIG_REQUEST, GET_SALARY_STRUCTURE_CONFIG_SUCCESS, POST_SALARY_STRUCTURE_INFO_FAILURE, POST_SALARY_STRUCTURE_INFO_REQUEST, POST_SALARY_STRUCTURE_INFO_SUCCESS, RESET_SALARY_STRUCTURE } from './actionTypes';


const getSalaryStructureFormConfigRequest = () => (
  {
    type: GET_SALARY_STRUCTURE_CONFIG_REQUEST
  }
);

const getSalaryStructureFormConfigSuccess = (payload) => (
  {
    type: GET_SALARY_STRUCTURE_CONFIG_SUCCESS,
    payload
  }
);

const getSalaryStructureFormConfigFailure = () => (
  {
    type: GET_SALARY_STRUCTURE_CONFIG_FAILURE
  }
);

const postSalaryStructureInfoRequest = () => (
  {
    type: POST_SALARY_STRUCTURE_INFO_REQUEST
  }
);

const postSalaryStructureInfoSuccess = (payload) => (
  {
    type: POST_SALARY_STRUCTURE_INFO_SUCCESS,
    payload
  }
);

const postSalaryStructureInfoFailure = () => (
  {
    type: POST_SALARY_STRUCTURE_INFO_FAILURE
  }
);

const getAllSalaryStructureListRequest = () => (
  {
    type: GET_ALL_SALARY_STRUCTURE_LIST_REQUEST
  }
);

const getAllSalaryStructureListSuccess = (payload) => (
  {
    type: GET_ALL_SALARY_STRUCTURE_LIST_SUCCESS,
    payload
  }
);

const getAllSalaryStructureListFailure = () => (
  {
    type: GET_ALL_SALARY_STRUCTURE_LIST_FAILURE
  }
);

export const resetSalaryStructure = () => (
  {
    type: RESET_SALARY_STRUCTURE
  }
);


export const getSalaryStructureConfig = (currentUserRoleId, salaryStructureId, fromLib, newReq) => async (dispatch) => {
  dispatch(getSalaryStructureFormConfigRequest());
  new SalaryStructureServices().getSalaryStructureDetails(currentUserRoleId, salaryStructureId).then((res) => {
    dispatch(getSalaryStructureFormConfigSuccess({ res, fromLib, newReq }));
  }).catch(() => {
    dispatch(getSalaryStructureFormConfigFailure());
  });
};

export const postSalaryStructureInfo = (currentUserRoleId, salaryStructureFormInfo) => async (dispatch) => {
  dispatch(postSalaryStructureInfoRequest());
  const salaryStructureFormData = getFormBody(salaryStructureFormInfo);
  const { createdUtc, ...rest } = salaryStructureFormData;
  return new SalaryStructureServices().postSalaryStructureInfo(currentUserRoleId, rest).then((res) => {
    dispatch(postSalaryStructureInfoSuccess({ salaryStructureFormInfo, id: res.id }));
    return res;
  }).catch((err) => {
    dispatch(postSalaryStructureInfoFailure());
    throw err?.response?.data?.message || 'Error Saving';
  });
};

export const getAllSalaryStructureList = (currentUserRoleId, searchInput) => async (dispatch) => {
  dispatch(getAllSalaryStructureListRequest());
  new SalaryStructureServices().getAllSalaryStructureList(currentUserRoleId, searchInput).then((res) => {
    dispatch(getAllSalaryStructureListSuccess(res));
  }).catch((err) => {
    dispatch(getAllSalaryStructureListFailure());
  });
};