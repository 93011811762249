import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, FormControl, MenuItem } from '@mui/material';
import SearchBar from '../../../atoms/SearchBar/SearchBar';
import { getTimeManagementTab } from '../TimeManagementOutlet/Utils/TimeManagementUtils';
import { StyledSelect } from './SubHeader.style';


const SubHeader = (props) => {
	const { handleSearch } = props;
	const [value, setValue] = useState([]);
	const navigate = useNavigate();

	const handleChange = (event) => {
		navigate(`/${event.target.value}`);
	};

	return (
		<Box sx={{ display: 'flex', gap: 7 }}>
			<FormControl variant='standard' sx={{ width: 150, border: 0, mt: 1 }}>
				<StyledSelect
					displayEmpty
					value={value}
					onChange={handleChange}
					renderValue={(selected) => {
						if (selected) {
							return 'Time Management';
						}
					}}
				>
					{getTimeManagementTab.map((item) => {
						const { name, label } = item;
						return (
							<MenuItem key={name} value={name}>{label}</MenuItem>
						);
					})}
				</StyledSelect>
			</FormControl>

			<SearchBar
				handleSearch={handleSearch}
			/>
		</Box>
	);
};

export default SubHeader;