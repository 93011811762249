import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material';

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, bgColor, color, fontSize, padding }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: `${bgColor || theme.palette.common.white}`,
        color:  color || 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: fontSize ||  11,
        padding: padding || '8px 12px'
    },
}));