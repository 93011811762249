import { FormFieldTypes } from '../../../constants/common';
const currencySymbol = JSON.parse(
  localStorage.getItem('orgInfo')
)?.currencySymbol;
export const getTeamBuildingFields = (data)=> {
  return[
    {
      id: 1,
      fieldLabel: 'Department',
      placeholderText: 'Select',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: false,
      fieldRefName: 'department',
      onChange:data?.[fieldNamesTeamBuilding.Department],
      isReadOnly:data?.singleDeptAllowed,
      size: 'small',
      fullWidth: true,
      fieldSize: 4,
    },
    {
      id: 2,
      fieldLabel: 'Total Budget',
      placeholderText: 'Total Budget',
      fieldType: FormFieldTypes.Number,
      isRequired: false,
      fieldRefName: 'totalBudget',
      isReadOnly:true,
      size: 'small',
      fullWidth: true,
      prefix:currencySymbol,
      fieldSize: 2,
    },
    {
      id: 3,
      fieldLabel: 'Total Balance',
      placeholderText: 'Total Balance',
      fieldType: FormFieldTypes.Number,
      isRequired: false,
      isHidden:false,
      isReadOnly:true,
      fieldRefName: 'balanceBudget',
      size: 'small',
      fullWidth: true,
      prefix:currencySymbol,
      fieldSize:2,
    },
    {
      id: 3,
      fieldLabel: 'Calculated Amount',
      placeholderText: 'Calculated Amount',
      fieldType: FormFieldTypes.Number,
      isRequired: false,
      isHidden:false,
      isReadOnly:true,
      fieldRefName: 'computedAmount',
      size: 'small',
      fullWidth: true,
      prefix:currencySymbol,
      fieldSize:3,
    },
  ];};

export const fieldNamesTeamBuilding = {
  Total_Balance:'balanceBudget',
  ComputedAmount:'computedAmount',
  Department:'department',
  Total_Budget:'totalBudget',
  Attendees_List:'attendees',
  formAmountField:'amount'
};

export const ClaimTypeCode = {
  DepartmentUnionAssociationGatheringClaim: 'DUA'
};