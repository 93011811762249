import React, { useEffect, useState } from 'react';
import PerformCalculations from './perform-calculation.component';

const ExpressionEditor = (props) => {
  const { setFormInfo, computeExpression } = props;
  const [value, setValue] = useState(computeExpression || '');

  useEffect(() => {
    setFormInfo((prev) => ({
      ...prev,
      computeExpression: value,
    }));
  }, [value]);

  const handleFieldClick = (field) => {
    const { fieldRefName } = field;
    setValue((prev) => {
      return `${prev || ''} data.${fieldRefName}`;
    });
  };

  const handleFunctionClick = (field) => {
    const { refName } = field;
    setValue((prev) => {
      return `${prev || ''} ${refName}`;
    });
  };

  const handleCalculatorClick = (digit) => {
    setValue((prev) => {
      const lastChar = prev.charAt(prev.length - 1);
      const allowSpace =
        (isNaN(lastChar) && lastChar !== '.') ||
        (isNaN(digit) && digit !== '.');
      const newExpr = `${prev || ''}${allowSpace ? ` ${digit}` : `${digit}`}`;
      return newExpr;
    });
  };

  const handleBackClick = () => {
    setValue('');
  };
  return (
    <>
      <PerformCalculations
        handleFieldClick={handleFieldClick}
        handleBackClick={handleBackClick}
        handleCalculatorClick={handleCalculatorClick}
        handleFunctionClick={handleFunctionClick}
        expressionVal={value}
      />
    </>
  );
};
export default React.memo(ExpressionEditor);
