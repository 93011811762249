import { Box, Grid, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ActionHeader from '../../../../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import SelectTag from '../../../../../../../atoms/Select/SelectTag';
import AccordionTabs from '../../../../../../../atoms/Accordian/Accordian';
import { AddOutlined } from '@mui/icons-material';
import BenefitExpression from '../benefit-expression/benefit-expression.component';
import BenefitCommonExpressionEditor from '../benefit-expression/benefit-common-expression-editor';
import { CompBenServices } from '../../../../../../../services/compBen';
import { isEmpty } from 'lodash';

const AmountButton = (props) => {
  const { setShowConfig, object, setBenInfo, name } = props;

  const { objects = [], ...rest } = object || {};
  const { conditionalExpression, defaultExpression } = objects[0] || {};
  const {
    conditionalExpression: conditionalExpression1,
    defaultExpression: defaultExpression1,
  } = objects[1] || {};

  const [formState, setFormState] = useState(rest || {});
  const [formulaRules1, setFormulaRules1] = useState(
    conditionalExpression || [{}]
  );
  const [formulaRules2, setFormulaRules2] = useState(
    conditionalExpression1 || [{}]
  );
  const [comp1, setComp1] = useState(defaultExpression || '');
  const [comp2, setComp2] = useState(defaultExpression1 || '');
  const [list, setList] = useState([]);

  useEffect(async () => {
    if (list.length === 0) {
      await new CompBenServices().getBenefitSystemField().then((res) => {
        setList(res);
      });
    }
  }, [list]);

  const handleDropDown = (event) => {
    const { value, name } = event.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormulaRules1([{}]);
    setComp1('');
    setFormulaRules2([{}]);
    setComp2('');
  };

  const handleSave = () => {
    let body = {};
    body = { ...body, ...formState };
    let object = [];
    if (formState.operation !== 'ONE') {
      object[0] = {
        conditionalExpression: formulaRules1,
        defaultExpression: comp1,
      };
      object[1] = {
        conditionalExpression: formulaRules2,
        defaultExpression: comp2,
      };
    } else {
      object[0] = {
        conditionalExpression: formulaRules1,
        defaultExpression: comp1,
      };
    }
    body = { ...body, objects: object };
    setBenInfo((prev) => ({
      ...prev,
      formulaExpression: body,
    }));
    setShowConfig('');
  };

  return (
    <Box>
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={`${name} -- Amount Config`}
          showPublish
          showBackButton
          showPublishModal={false}
          publishButtonText="Save"
          onBackClick={() => setShowConfig('')}
          onPublishClick={handleSave}
          showSave={false}
        />
      </Box>
      <Box>
        <Grid container rowSpacing={3} columnSpacing={8}>
          <Grid item xs={12} md={4}>
            <SelectTag
              labelText="Select Operation"
              name="operation"
              dropDownList={[
                {
                  id: 'MIN',
                  name: 'Minimum of Two',
                },
                {
                  id: 'MAX',
                  name: 'Maximum of Two',
                },
                {
                  id: 'ONE',
                  name: 'Single Expression',
                },
              ]}
              size="small"
              fullWidth
              value={formState?.operation}
              onchange={handleDropDown}
              placeholder="Select"
            />
          </Grid>
        </Grid>
        {!isEmpty(formState) && (
          <Grid container rowSpacing={3} columnSpacing={8} mt={2}>
            <Grid item xs={12} md={formState.operation !== 'ONE' ? 6 : 12}>
              {formulaRules1.map((item, index) => {
                return (
                  <Box key={index}>
                    <AccordionTabs
                      title={`Rule ${index + 1}`}
                      bgColorSummary="rgba(167, 236, 226, 0.3)"
                      tabDetails={
                        <Box>
                          <BenefitExpression
                            setFormulaRules={setFormulaRules1}
                            index={index}
                            data={item}
                            noOfRules={formulaRules1.length}
                            fieldList={list}
                          />
                        </Box>
                      }
                    />
                  </Box>
                );
              })}
              <IconButton
                onClick={() =>
                  setFormulaRules1((prev) => {
                    return [...prev, {}];
                  })
                }
                sx={{
                  width: 'max-content',
                }}
              >
                <AddOutlined />
              </IconButton>
              <Box>
                <BenefitCommonExpressionEditor
                  setFormInfo={setComp1}
                  computeExpression={comp1}
                  fieldList={list}
                />
              </Box>
            </Grid>
            {formState.operation !== 'ONE' && (
              <Grid item xs={12} md={6}>
                {formulaRules2.map((item, index) => {
                  return (
                    <Box key={index}>
                      <AccordionTabs
                        title={`Rule ${index + 1}`}
                        bgColorSummary="rgba(167, 236, 226, 0.3)"
                        tabDetails={
                          <Box>
                            <BenefitExpression
                              setFormulaRules={setFormulaRules2}
                              index={index}
                              data={item}
                              noOfRules={formulaRules2.length}
                              fieldList={list}
                            />
                          </Box>
                        }
                      />
                    </Box>
                  );
                })}
                <IconButton
                  onClick={() =>
                    setFormulaRules2((prev) => {
                      return [...prev, {}];
                    })
                  }
                  sx={{
                    width: 'max-content',
                  }}
                >
                  <AddOutlined />
                </IconButton>
                <Box>
                  <BenefitCommonExpressionEditor
                    setFormInfo={setComp2}
                    computeExpression={comp2}
                    fieldList={list}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default AmountButton;
