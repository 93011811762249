import { Box, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SubHeader from '../../SubHeader';
import DynamicBreadcrumb from '../../../../atoms/Breadcrumbs/dynamicBreadcrumb';
import { useSelector } from 'react-redux';

const OnboardingHeader = (props) => {
  const { handleSearch} = props;
  const {userIdPropertiesMap}  = useSelector((state) => state.onBoarding);
  const [idNameMap, setIdNameMap] = useState({});

  useEffect(() => {
    const simplifiedMap = userIdPropertiesMap && Object.keys(userIdPropertiesMap).reduce((acc, id) => {
      acc[id] = userIdPropertiesMap[id].name;
      return acc;
    }, {});
    setIdNameMap(simplifiedMap);
  }, [userIdPropertiesMap]);

    return (
        <Box>
        <SubHeader  handleSearch={handleSearch} />
        <Box my={3}>
        <DynamicBreadcrumb idNameMap={idNameMap}/>
          <Box mt={1}>
            <Divider />
          </Box>
        </Box>
        </Box>
    );
};

export default OnboardingHeader;