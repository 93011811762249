import React, { useEffect, useMemo, useState } from 'react';
import { LoadingStatus } from '../../../../../../constants/common';
import CircularLoader from '../../../../../../atoms/CircularLoader/circular-loader';
import ModalDialog from '../../../../../../atoms/Modal/ModalDialog';
const ConditionalModal = (props) => {
    const {showModal,secondaryAction,primaryAction,title,setshowModal,listComponent} = props;
    const [loading, setLoading] = useState(false);
    
  return (
    <>
      <CircularLoader show={loading === LoadingStatus.Loading} />
      <ModalDialog
      title={title}
        showModal={showModal}
        secondaryAction={secondaryAction}
        onClickClose={() => setshowModal(false)}
        maxWidth={'md'}
        fullWidth={true}
        listComponent={listComponent}
        severity= 'success'
      />
    </>
  );
};

export default ConditionalModal;
