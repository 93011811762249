import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect} from 'react';
import { isEmpty } from 'lodash';
import { getFormBody } from '../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import ApprovalConditions from './approval-conditions.component';

const FormApproval = (props) => {
  const { setFormInfo, formState, setFormState, conditions, setConditions } =
    props;

  useEffect(() => {
    if (isEmpty(formState)) {
      setFormInfo((prev) => ({
        ...prev,
        approverFieldValidation: null,
      }));
    } else {
      setFormInfo((prev) => ({
        ...prev,
        approverFieldValidation: formState.map((item) => getFormBody(item)),
      }));
    }
  }, [conditions, formState]);

  return (
    <Grid container rowSpacing={3} columnSpacing={8}>
      <Grid item xs={12} md={12}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h2" fontSize={18} pb={2}>
            Conditions
          </Typography>
          {conditions.map((item, index) => {
            return (
              <ApprovalConditions
                key={index}
                index={index}
                setConditions={setConditions}
                condition={formState[index]?formState[index]:{}}
                noOfConditions={conditions.length}
                setFormState={setFormState}
              />
            );
          })}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default FormApproval;
