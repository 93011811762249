import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { FormHeader } from '../../../common/formHeader';
import ListView from '../../../../../atoms/DataList/ListView';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoadingStatus, ToastThemes } from '../../../../../constants/common';
import {
  Badge,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import TryAgain from '../../../../molecules/ErrorState/error.component';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import { searchQuery } from '../../../../../constants/constants';
import { externalIntegration } from '../../constants/onboarding.constants';
import { permissionsObj } from '../../RolesAndPermissions/constants/roles-permissions.constants';
import AddIntegration from '../IntegrationForm/AddIntegration';
import { IntegrationService } from '../../../../../services/externalIntegration';
import { showToast } from '../../../../../utils/common.util';

const AllIntegration = () => {
  const [integrationList, setIntegrationList] = useState([]);
  const [loading, setLoading] = useState(-1);
  const [viewMode, setViewMode] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [integrationId, setIntegrationId] = useState({});
  const [searchParams] = useSearchParams();
  const { currentUserRoleId } = useSelector((state) => state.main);
  const searchInput = searchParams.get(searchQuery);

  const { permissions } = useSelector((state) => state.onBoarding);

  const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
    height: 18,
    borderRadius: 5,
    position: 'relative',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.grey[800]
          : theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: value < 40 ? 'red' : 'green',
    },
  }));

  useEffect(async () => {
    if (!showForm) {
      try {
        setLoading(LoadingStatus.Loading);
        const response = await new IntegrationService().getListAll(
          currentUserRoleId
        );
        setIntegrationList(response);
        setLoading(LoadingStatus.Success);
      } catch (err) {
        setLoading(LoadingStatus.Failure);
        showToast(err?.response?.data?.message, ToastThemes.error);
        throw err?.response?.data?.message;
      }
    }
  }, [showForm]);

  const onClickAddIcon = () => {
    setShowForm(true);
    setIntegrationId('');
  };

  const onDetailsClick = (id) => {
    setShowForm(true);
    setViewMode(true);
    setIntegrationId(id);
  };

  const rowData = integrationList?.map((items) => {
    const {
      id,
      entityName,
      columnNameCount,
      fkDataDictionaryCount,
      mandatoryColumnsCount,
    } = items;
    const value = (fkDataDictionaryCount / columnNameCount) * 100;
    return {
      name: entityName,
      fkDataDictionaryCount: `${fkDataDictionaryCount} (${mandatoryColumnsCount}*)`,
      columnNameCount: columnNameCount,
      integration: (
        <Box position="relative" display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <BorderLinearProgress variant="determinate" value={value} />
          </Box>
          <Box
            position="absolute"
            left="50%"
            top="50%"
            style={{ transform: 'translate(-50%, -50%)' }}
          >
            <Typography variant="body2" color={value < 40? '#434B4A' : '#FCFCFC' } >{`${Math.round(
              value
            )}%`}</Typography>
          </Box>
        </Box>
      ),
      action: !permissions?.[externalIntegration]?.[
        permissionsObj.allowEdit
      ] ? (
        { hide: true }
      ) : (
        <IconButton
          onClick={() => onDetailsClick(id)}
          disabled={
            !permissions?.[externalIntegration]?.[permissionsObj.allowEdit]
          }
        >
          <InfoOutlined sx={{ color: 'primary.main80' }} />
        </IconButton>
      ),
      id: {
        hide: true,
        value: id,
      },
    };
  });
  if (loading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={loading === LoadingStatus.Loading} />
      {showForm ? (
        <AddIntegration
          viewMode={viewMode}
          setViewMode={setViewMode}
          integrationId={integrationId}
          setShowForm={setShowForm}
          integrationList={integrationList}
        />
      ) : (
        <Box>
          <Box display="flex" justifyContent="space-between">
            <FormHeader title="External Integration" />
          </Box>
          <ListView
            headCells={[
              { id: 'name', label: 'Entity Name' },
              { id: 'fkDataDictionaryCount', label: 'Total Mapped Columns' },
              { id: 'columnNameCount', label: 'Total Columns' },
              { id: 'integration', label: 'Integration' },
            ]}
            rowsData={rowData || []}
            showSerialNo={true}
            showCheckbox={false}
            showAddIcon={
              permissions?.[externalIntegration]?.[permissionsObj.allowAdd]
            }
            onAddClick={() => onClickAddIcon()}
          />
        </Box>
      )}
    </>
  );
};

export default AllIntegration;
