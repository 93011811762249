import React, { useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import ActionHeader from '../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import ComponentFormConfig from '../../../OnBoarding/UserProfile/PayrollTab/ComponentForm/ComponentFormConfig';
import {
  ComponentInputType,
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../../../constants/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPayrollDetails,
  updatePayrollInfo,
} from '../../../../redux/payroll/action';
import { showToast } from '../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../OnBoarding/Organization/constants/onboarding.constants';
import BasePayroll from './ManagePayroll/BasePayroll';
import ComponentHeader from './ManagePayroll/ComponentHeader';
import { totalForAllComponents } from '../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import Total from './ManagePayroll/Total';
import { getFormattedDate } from '../../../../utils/formatter';
import Currency from './ManagePayroll/Currency';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import { PayrollPermissions } from '../../Constant/Constant';
import ComponentFormView from '../../../OnBoarding/UserProfile/PayrollTab/ComponentForm/ComponentFormView';
import { permissionsObj } from '../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import { isEmpty } from 'lodash';
import OtSection from './ManagePayroll/OtSection';

const PayrollEdit = (props) => {
  const {
    setViewMode,
    additionComponents: additions,
    deductionComponents: deductions,
    base,
    stdHrs,
    period,
    cpfEmployeeAmt,
    cpfEmployerAmt,
    prlRegisterId,
    currencySymbol,
    employee,
    sdf,
    directFunds,
    cpfApplicable,
    otApplicable,
    overtimeInfo,
  } = props;
  const [additionComponents, setAdditionComponents] = useState(additions);
  const [deductionComponents, setDeductionComponents] = useState(deductions);
  const dispatch = useDispatch();
  const { currentUserRoleId } = useSelector((state) => state.main);
  const [loadingStatus, setLoadingStatus] = useState(-1);

  const totalAdditions = totalForAllComponents(additionComponents);
  const totalDeductions = totalForAllComponents(deductionComponents);
  const totalContributions = totalForAllComponents(directFunds);

  const { auditLogs } = useSelector((state) => state.payroll);
  const { payrollPermission } = useSelector((state) => state.modulePermissions);
  const formattedPeriod = getFormattedDate(period, 'YYYYMM', 'YYYY-MM');

  const handleDiscard = () => {
    setViewMode(true);
    setAdditionComponents(additions);
    setDeductionComponents(deductions);
  };

  const handleSave = () => {
    if (
      totalDeductions >
      totalAdditions +
        (base?.code === 'HRL' ? Number(base?.value) * Number(stdHrs) : Number(base?.value))
    ) {
      showToast('Deductions cannot be more than additions', ToastThemes.error);
    } else {
      setLoadingStatus(LoadingStatus.Loading);
      dispatch(
        updatePayrollInfo(
          additionComponents,
          deductionComponents,
          prlRegisterId,
          auditLogs?.length > 0 ? true : false,
          payrollPermission?.[PayrollPermissions.ACCESS_ALL_PAYROLL]?.id,
          employee?.id,
          `${employee.empId} ${formattedPeriod}`
        )
      )
        .then(() => {
          showToast(
            ToastMessages.success.replace(
              stringSubstitute,
              toastMessage.success
            ),
            ToastThemes.success
          );
          dispatch(
            getPayrollDetails(
              currentUserRoleId,
              prlRegisterId,
              payrollPermission?.[PayrollPermissions.ACCESS_ALL_PAYROLL]?.id,
              payrollPermission?.[PayrollPermissions.APPROVER_DASHBOARD]?.[
                permissionsObj.allowView
              ]
            )
          );
          setViewMode(true);
          setLoadingStatus(LoadingStatus.Success);
        })
        .catch((err) => {
          showToast(err, ToastThemes.error);
          setLoadingStatus(LoadingStatus.Failure);
        });
    }
  };

  return (
    <Box>
      <CircularLoader show={loadingStatus === LoadingStatus.Loading} />
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={employee?.firstName + ' ' + employee?.lastName}
          editButtonText="Save"
          showDiscard
          onEditClick={handleSave}
          onClickDiscard={handleDiscard}
          labelCode={formattedPeriod}
          empId={employee?.employeeId}
        />
      </Box>
      <Currency currencySymbol={currencySymbol} />
      <BasePayroll
        base={base}
        stdHrs={stdHrs}
        currencySymbol={currencySymbol}
      />
      <Grid
        container
        rowSpacing={3}
        columnSpacing={8}
        pr={8}
        justifyContent="center"
      >
        <Grid item md={6} xs={12}>
          <ComponentHeader
            label="B. Addition Components"
            total={totalAdditions}
          />

          {additionComponents?.map((item, index) => {
            return (
              <ComponentFormConfig
                setFormInfo={setAdditionComponents}
                formInfo={additionComponents[index]}
                key={index}
                index={index}
                readOnly={item?.inputSourceCode !== ComponentInputType.Monthly}
              />
            );
          })}
        </Grid>
        <Grid item md={6} xs={12}>
          <ComponentHeader
            label="C. Deduction Components"
            total={totalDeductions}
          />
          {deductionComponents?.map((item, index) => {
            return (
              <ComponentFormConfig
                setFormInfo={setDeductionComponents}
                formInfo={deductionComponents[index]}
                key={index}
                index={index}
                readOnly={item?.inputSourceCode !== ComponentInputType.Monthly}
              />
            );
          })}
        </Grid>
      </Grid>
      {otApplicable && (
        <>
          <Divider sx={{ padding: 1 }} />
          <OtSection
            overtimeInfo={overtimeInfo}
            currencySymbol={currencySymbol}
          />
        </>
      )}
      <Total
        label={
          otApplicable ? 'E. Total Gross (A+B+D-C)' : 'D. Total Gross (A+B-C)'
        }
        total={
          totalAdditions +
          (base?.code === 'HRL' ? Number(base?.value) * Number(stdHrs) : Number(base?.value)) +
          (overtimeInfo?.value || 0) -
          totalDeductions
        }
      />
      <Divider sx={{ padding: 1 }} />
      <Grid container rowSpacing={3} columnSpacing={8} pr={8}>
        <Grid item md={6} xs={12}>
          <ComponentHeader
            label={otApplicable ? 'F. Contributions' : 'E. Contributions'}
            total={totalContributions || 0}
          />
          {directFunds?.map((item, index) => {
            return (
              <ComponentFormView formInfo={directFunds[index]} key={index} />
            );
          })}
        </Grid>
      </Grid>
      <Total
        label={otApplicable ? 'G. Net Pay (E-F)' : 'F. Net Pay (D-E)'}
        total={
          totalAdditions +
          (base?.code === 'HRL' ? Number(base?.value) * Number(stdHrs) : Number(base?.value)) +
          (overtimeInfo?.value || 0) -
          totalDeductions -
          (totalContributions || 0)
        }
      />
      {cpfApplicable && (
        <Total label="Employer CPF" total={cpfEmployerAmt || 0} toolTip='CPF Based on AW/OW'/>
      )}
      {!isEmpty(sdf) && <Total label={sdf?.name} total={sdf?.value} />}
    </Box>
  );
};

export default PayrollEdit;
