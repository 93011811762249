import { Box, Grid } from '@mui/material';
import React from 'react';
import CardUI from '../../../shared/components/ui-elements/CardUI';
import PersonIcon from '@mui/icons-material/Person';

const ReportingDetails = (props) => {
  return (
    <React.Fragment>
      <Grid xs={12} sm={10} className='mar-top' style={{ marginBottom: '1em' }}>
      <CardUI>
        <Grid container>
          {props.cardData &&
            props.cardData.map((each, i) => (
              <Grid
                key={i}
                lg={4}
                md={4}
                sm={4}
                className='card-data-type'
                container
              >
                <Grid xs={1}>
                  <PersonIcon />
                </Grid>
                <Box>
                  <div style={{ fontSize: '1em' }}>{each.post}</div>
                  <span style={{ fontSize: '0.8em' }}>{each.name}</span>
                </Box>
              </Grid>
            ))}
        </Grid>
      </CardUI>
      </Grid>
    </React.Fragment>
  );
};
export default ReportingDetails;
